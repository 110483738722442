import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { AccountBalance, PersonAdd, GroupAdd, People } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Message from '../../../components/Message/Message'
import Loader from '../../../components/Loader/Loader'
import CenterInfo from '../../../components/Centers/CenterInfo/CenterInfo'
import CustomTabs from '../../../components/CustomTabs/CustomTabs'
import UserListCard from '../../../components/Users/UserListCard/UserListCard'
import StudentListCard from './components/StudentListCard'
import TeacherRegisterForm from './components/TeacherRegisterForm'
import GroupRegisterForm from '../../../components/Centers/GroupRegisterForm/GroupRegisterForm'
import CustomTabGroup from '../../../components/CustomTabGroup/CustomTabGroup'
import StudentRegisterForm from './components/StudentRegisterForm'
import TeacherEmptyTabContent from './components/TeacherEmptyTabContent'
import { getCenterById } from '../../../redux/actions/centerActions'
import { getTeachersByCenter } from '../../../redux/actions/teacherActions'
import { getGroupsByCenter } from '../../../redux/actions/groupActions'
import { GROUP_DELETE_RESET, GROUP_UPDATE_RESET } from '../../../redux/constants/groupConstants'
import { CENTER_DETAILS_RESET } from '../../../redux/constants/centerConstants'
import styles from './styles/centerManageScreen'

const useStyles = makeStyles(styles)

const CenterManageScreen = ({ match, history }) => {
  const centerId = match.params.id
  const dispatch = useDispatch()
  const classes = useStyles()

  const { loadingCenterDetails, errorCenterDetails, center } = useSelector((state) => state.centerDetails)
  const { loadingTeachersByCenter, errorTeacherByCenter, teachers } = useSelector((state) => state.teachersByCenter)
  const { groups } = useSelector((state) => state.groupListByCenter)
  const { successGroupUpdate } = useSelector((state) => state.groupUpdate)
  const { successGroupDelete } = useSelector((state) => state.groupDelete)
  const { successStudentRegister } = useSelector((state) => state.studentRegister)
  const { successStudentDelete } = useSelector((state) => state.studentDelete)
  const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)

  useEffect(() => {
    dispatch(getCenterById(centerId))
    dispatch(getTeachersByCenter(centerId))
    dispatch(getGroupsByCenter(centerId))
  }, [dispatch, centerId])
  useEffect(() => {
    if (successGroupUpdate) {
      dispatch({ type: GROUP_UPDATE_RESET })
      dispatch(getGroupsByCenter(centerId))
    }
    if (successGroupDelete) {
      dispatch({ type: GROUP_DELETE_RESET })
      dispatch(getGroupsByCenter(centerId))
    }
    if (successStudentRegister) {
      dispatch(getGroupsByCenter(centerId))
    }
    if (successStudentDelete) {
      dispatch(getGroupsByCenter(centerId))
    }
    if (successGroupTeacherDelete) {
      dispatch(getGroupsByCenter(centerId))
    }
  }, [
    dispatch,
    centerId,
    successGroupUpdate,
    successGroupDelete,
    successStudentRegister,
    successStudentDelete,
    successGroupTeacherDelete,
  ])
  useEffect(() => {
    return () => {
      dispatch({ type: CENTER_DETAILS_RESET })
    }
  }, [dispatch])

  const manageTeacherHandler = (id) => {
    history.push(`/admin/teachers/manage/${id}`)
  }

  return (
    <>
      <Meta title={'Center - Planet Fraternity'} />
      {loadingCenterDetails ? (
        <Loader />
      ) : errorCenterDetails ? (
        <Message>{errorCenterDetails}</Message>
      ) : (
        <>
          <Grid container spacing={8} className={classes.root}>
            <Grid item xs={12} sm={12} md={9} className={classes.centerInfoCard}>
              {center && (
                <CustomTabs
                  headerColor='danger'
                  className={classes.customTabsRoot}
                  tabs={[
                    {
                      tabName: 'Center Info',
                      tabIcon: AccountBalance,
                      tabContent: <CenterInfo center={center} teachers={teachers} />,
                    },
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              {center && (
                <CustomTabs
                  headerColor='primary'
                  className={classes.customTabsRoot}
                  tabs={[
                    {
                      tabName: 'Teachers',
                      tabIcon: People,
                      tabContent: (
                        <>
                          {loadingTeachersByCenter ? (
                            <Loader />
                          ) : errorTeacherByCenter ? (
                            <span>{errorTeacherByCenter}</span>
                          ) : teachers && teachers.length === 0 ? (
                            <TeacherEmptyTabContent />
                          ) : (
                            teachers &&
                            teachers.length > 0 &&
                            teachers.map((teacher) => (
                              <UserListCard
                                key={teacher._id}
                                user={teacher}
                                draggable
                                dropdownList={[<div onClick={() => manageTeacherHandler(teacher._id)}>Manage</div>]}
                              />
                            ))
                          )}
                        </>
                      ),
                    },
                    {
                      tabName: 'Add Teacher',
                      tabIcon: PersonAdd,
                      tabContent: <TeacherRegisterForm center={center} />,
                    },
                    {
                      tabName: 'Students',
                      tabIcon: People,
                      tabContent: (
                        <div>
                          <StudentListCard center={center} groups={groups} />
                        </div>
                      ),
                    },
                    {
                      tabName: 'Add Student',
                      tabIcon: PersonAdd,
                      tabContent: <StudentRegisterForm center={center} groups={groups} />,
                    },
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              {center && (
                <CustomTabs
                  headerColor='success'
                  className={classes.customTabsRoot}
                  tabs={[
                    {
                      tabName: 'Groups',
                      tabIcon: People,
                      tabContent: <CustomTabGroup center={center} teachers={teachers} />,
                    },
                    {
                      tabName: 'Add Group',
                      tabIcon: GroupAdd,
                      tabContent: <GroupRegisterForm center={center} />,
                    },
                  ]}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default CenterManageScreen

const styles = {
    root: {
        marginTop: 15,
        marginBottom: 15,
    },
    formControl: {
        margin: 0,
    },
}
export default styles

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Badge,
  Link,
  Fab,
} from '@material-ui/core'
import { Close, Add } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import Loader from '../../../../components/Loader/Loader'
import { registerInformation } from '../../../../redux/actions/informationActions'
import styles from '../styles/resourceRegisterFormStyles'

const useStyles = makeStyles(styles)

const ResourceRegisterForm = ({ newCategory, categories, setNewCategory, open, setOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [title, setTitle] = useState('')
  const [link, setLink] = useState([])
  const [category, setCategory] = useState('')

  const { successInformationRegister, loadingInformationRegister } = useSelector((state) => state.informationRegister)

  useEffect(() => {
    if (successInformationRegister) {
      setTitle('')
      setLink([])
      setCategory('')
      setNewCategory(false)
      setOpen(false)
    }
  }, [successInformationRegister, setOpen, setNewCategory])

  useEffect(() => {
    if (!open) {
      setTitle('')
      setLink([])
      setCategory('')
      setNewCategory(false)
    }
  }, [open, setNewCategory])

  const handleFileChange = (e) => {
    setLink([...e.target.files])
  }

  const handleFileRemove = () => {
    setLink([])
  }

  const submitResourceHandle = (e) => {
    e.preventDefault()
    const resource = {
      title,
      link,
      category,
    }
    dispatch(registerInformation(resource))
  }

  return (
    <form onSubmit={submitResourceHandle} autoComplete='off'>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <CustomInput
            labelText='Title'
            id='newtitle'
            formControlProps={{
              fullWidth: true,
              className: classes.formControl,
            }}
            inputProps={{
              type: 'text',
              value: title,
              placeholder: 'Enter complete title',
              onChange: (e) => setTitle(e.target.value),
              required: true,
            }}
          />
        </Grid>
        {!newCategory && (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id='monthstart' className={classes.selectLabel}>
                  Category
                </InputLabel>
                <Select
                  labelId='monthstart'
                  id='centerstart'
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  inputProps={{ required: true }}
                  className={classes.selectInput}
                >
                  <MenuItem value='' disabled>
                    Select a Category
                  </MenuItem>
                  {categories &&
                    categories.map((category, index) => (
                      <MenuItem key={index} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <p>
                Can't find the right category? add a new category giving{' '}
                <Link className={classes.textHelper} onClick={() => setNewCategory(true)}>
                  Click here
                </Link>
              </p>
            </Grid>
          </>
        )}

        {newCategory && (
          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.categorySection}>
              <CustomInput
                labelText='Category'
                id='newtitle'
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputProps={{
                  type: 'text',
                  value: category,
                  placeholder: 'Enter complete category',
                  onChange: (e) => setCategory(e.target.value),
                  required: true,
                }}
              />
              <IconButton aria-label='close' className={classes.closeIcon} onClick={() => setNewCategory(false)}>
                <Close fontSize='small' />
              </IconButton>
            </div>
          </Grid>
        )}
        {link && link.length < 1 && (
          <Grid item md={12} sm={12} xs={12} className={classes.btnResource}>
            <p>Add Resource</p>
            <label htmlFor='file-input'>
              <input
                type='file'
                name='fileInput'
                capture='camera'
                hidden
                id='file-input'
                accept='.png, .jpg, .jpeg, .pdf, .xls, .pptx, .mp4'
                onChange={handleFileChange}
              />
              <Fab color='primary' size='small' component='span' aria-label='add'>
                <Add />
              </Fab>
            </label>
          </Grid>
        )}
        {link[0] && (
          <Grid item xs={12} md={12}>
            <Badge
              badgeContent={
                <Button size='sm' color='danger' onClick={handleFileRemove} round justIcon>
                  <Close />
                </Button>
              }
              className={classes.fileBadge}
            >
              <div className={classes.fileDiv}>
                {link[0].name.length > 20
                  ? link[0].name.slice(0, 20) + '...' + link[0].name.slice(link[0].name.length - 6, link[0].name.length)
                  : link[0].name}
              </div>
            </Badge>
          </Grid>
        )}

        <Grid item xs={12} className={classes.btnSection}>
          <Button disabled={!title || link.length === 0 || !category} type='submit' color='success' block>
            {loadingInformationRegister ? <Loader size={'sm'} /> : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ResourceRegisterForm

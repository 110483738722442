import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import classNames from 'classnames'
import { Grid, Hidden, Badge, IconButton, makeStyles } from '@material-ui/core'
import { Mic, MicOff, Videocam, VideocamOff, ScreenShare, CallEnd, People, Chat, Close } from '@material-ui/icons'
import { useSocket } from '../../../hooks/useSocket/useSocket'
import { ICE_CONFIG } from '../../../config/webRTCConfig'
import {
	camHandler,
	getMyVideo,
	handleDisconnect,
	handleEndCall,
	handleMessages,
	handleSidebar,
	micHandler,
	screenShareHandler,
	setMyStreamToHTML,
} from '../../../utils/meetroomFunctions'
import styles from './styles/meetRoomScreenStyles'

const useStyles = makeStyles(styles)

const MeetRoomScreen = ({ match }) => {
	const roomID = match.params.id
	const { socket } = useSocket()
	const classes = useStyles()

	const userStream = useRef()
	const myVideoTag = useRef()
	const peersVideoContainer = useRef()
	const peersRef = useRef({})
	const sendersRef = useRef([])
	const scrollRef = useRef(null)

	const [userSharingStream, setUserSharingStream] = useState(null)
	const [peersInChat, setPeersInChat] = useState([])
	const [peers, setPeers] = useState([])
	const [messages, setMessages] = useState([])
	const [newMessage, setNewMessage] = useState('')
	const [micEnabled, setMicEnabled] = useState(true)
	const [camEnabled, setCamEnabled] = useState(true)
	const [isSharing, setIsSharing] = useState(false)
	const [sidebarValue, setSidebarValue] = useState(0)

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (userInfo) {
			const ownUser = {
				_id: userInfo._id,
				username: userInfo.username,
				name: userInfo.name,
				avatar: userInfo.avatar,
				roomID,
			}
			function callMyPeers(myPeers, myStream) {
				const peersArray = []
				myPeers.forEach((user) => {
					const peer = createPeer(user)
					const peerObj = { peer, user }
					peersRef.current[user.socketId] = peerObj
					peersArray.push(peerObj)
					myStream.getTracks().forEach((track) => sendersRef.current.push(peer.addTrack(track, myStream)))
				})
				setPeers(peersArray)
			}
			function createPeer(user) {
				const peerVideoTag = document.createElement('video')
				const peerDivContainer = document.createElement('div')
				const peerSpanToName = document.createElement('span')
				const peer = new RTCPeerConnection(ICE_CONFIG)
				peer.onnegotiationneeded = () => handleNegotiationNeededEvent(peer, user)
				peer.onicecandidate = handleICECandidateEvent
				peer.ontrack = ({ streams: [stream] }) => {
					const alreadyExists = document.getElementById(user.socketId.toString())
					if (alreadyExists === null) {
						peerVideoTag.srcObject = stream
						peerVideoTag.autoplay = true
						peerVideoTag.playsInline = true
						peerDivContainer.id = user.socketId
						peerSpanToName.innerText = user.name
						peerDivContainer.appendChild(peerVideoTag)
						peerDivContainer.appendChild(peerSpanToName)
						peersVideoContainer.current.appendChild(peerDivContainer)
					}
				}

				return peer
			}
			async function handleNegotiationNeededEvent(peer, user) {
				const offer = await peer.createOffer()
				await peer.setLocalDescription(offer)
				const payload = {
					sdp: peer.localDescription,
					user,
				}
				socket.emit('peer-connection-request', payload)
			}
			async function handleReceiveOffer({ sdp, caller }, stream) {
				const peer = createPeer(caller)
				const peerObj = { peer, user: caller }
				peersRef.current[caller.socketId] = peerObj
				setPeers((users) => {
					const userExist = users.find((item) => item.user.socketId === peerObj.user.socketId)
					if (!userExist) {
						return [...users, peerObj]
					}
					return [...users]
				})
				const desc = new RTCSessionDescription(sdp)
				await peer.setRemoteDescription(desc)

				stream.getTracks().forEach((track) => sendersRef.current.push(peer.addTrack(track, stream)))

				const answer = await peer.createAnswer()
				await peer.setLocalDescription(answer)

				const payload = {
					userToAnswerTo: caller.socketId,
					sdp: peer.localDescription,
				}

				socket.emit('connection-answer', payload)
			}
			function handleAnswer({ sdp, answererUser }) {
				const desc = new RTCSessionDescription(sdp)
				peersRef.current[answererUser].peer.setRemoteDescription(desc).catch((e) => {})
			}
			function handleICECandidateEvent(e) {
				if (e.candidate) {
					Object.keys(peersRef.current).forEach((id) => {
						const payload = {
							target: id,
							candidate: e.candidate,
						}
						socket.emit('ice-candidate', payload)
					})
				}
			}
			function handleReceiveIce({ candidate, from }) {
				const inComingCandidate = new RTCIceCandidate(candidate)
				peersRef.current[from].peer.addIceCandidate(inComingCandidate)
			}
			socket.on('connect', async () => {
				userStream.current = await getMyVideo()
				setMyStreamToHTML(myVideoTag.current, userStream.current)
				socket.emit('join-chat', ownUser, (myPeers) => callMyPeers(myPeers, userStream.current))
				socket.on('connection-offer', (payload) => handleReceiveOffer(payload, userStream.current))
				socket.on('connection-answer', handleAnswer)
				socket.on('ice-candidate', handleReceiveIce)
				socket.on('user-disconnected', (payload) =>
					handleDisconnect(payload, peersRef.current, setPeers, setPeersInChat),
				)
			})
		}
	}, [userInfo, socket, roomID])

	useEffect(() => {
		socket.on('users-in-room', (usersInRoom) => setPeersInChat(usersInRoom))
	}, [socket])

	useEffect(() => {
		socket.on('receive-message', (msg) => setMessages((prevMessages) => [...prevMessages, msg]))
		return () => socket.off('receive-message')
	}, [socket])

	useEffect(() => {
		scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
	})

	const mainScreen = classNames({
		[classes.screen]: sidebarValue < 1,
		[classes.screenShared]: sidebarValue > 0,
	})
	const peersRootScreen = classNames({
		[classes.peersRoot]: true,
		[classes.peersRootFull]: sidebarValue < 1,
		[classes.peersRootSide]: sidebarValue > 0,
		[classes.peerScreen]: true,
		[classes.peerScreenOne]: peers.length === 1,
		[classes.peerScreenTwo]: peers.length === 2,
		[classes.peerScreenFour]: peers.length > 2 && peers.length < 5,
		[classes.peerScreenSix]: peers.length > 4 && peers.length < 7,
		[classes.peerScreenEight]: peers.length > 6 && peers.length < 9,
		[classes.peerScreenTen]: peers.length > 8 && peers.length < 15,
	})
	const ownCam = classNames({
		[classes.ownVideo]: true,
	})

	return (
		<div className={classes.root}>
			<div className={classes.screenRoot}>
				<div className={mainScreen}>
					<div className={peers.length < 1 ? classes.ownVideoAlone : ownCam}>
						<video autoPlay muted height='100%' ref={myVideoTag} playsInline />
					</div>
					<div className={peersRootScreen} ref={peersVideoContainer}></div>
				</div>
				{sidebarValue > 0 && (
					<div className={classes.sidebarRoot}>
						{sidebarValue === 1 && (
							<div>
								<div className={classes.sidebarHeader}>
									<span className={classes.sidebarTitle}>My Group</span>
									<IconButton className={classes.btnClose} onClick={() => setSidebarValue(0)} aria-label='close'>
										<Close />
									</IconButton>
								</div>
								<p>People online</p>
								<div>
									{peersInChat.map((user) => (
										<div key={user.userId} className={classes.peerActive}>
											<img src={`${axios.defaults.baseURL}${user.avatar}`} alt={user.name} />
											<span>{user.name}</span>
										</div>
									))}
								</div>
							</div>
						)}
						{sidebarValue === 2 && (
							<div className={classes.sidebarChatRoot}>
								<div className={classes.sidebarHeader}>
									<span className={classes.sidebarTitle}>Chat in Call</span>
									<IconButton className={classes.btnClose} onClick={() => setSidebarValue(0)} aria-label='close'>
										<Close />
									</IconButton>
								</div>

								<div className={classes.sidebarMsgArea}>
									{messages.map((msg, index) => (
										<div key={index} ref={scrollRef} className={classes.sidebarMsgBox}>
											<div className={msg._id !== userInfo._id ? classes.peersBubble : classes.myBubble}>
												<div>
													<span className={classes.peerName}>{msg.username}</span>
												</div>
												<div>
													<span>{msg.message}</span>
												</div>
											</div>
										</div>
									))}
								</div>

								<div className={classes.sidebarChatInput}>
									<input
										type='text'
										placeholder='Send a message to everyone'
										value={newMessage}
										onChange={(e) => setNewMessage(e.target.value)}
										onKeyDown={(e) => handleMessages(e, newMessage, userInfo, roomID, socket, setNewMessage)}
									/>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
			<div className={classes.footer}>
				<Grid container>
					<Grid item xs={12} sm={3} className={classes.footerSection}>
						<h5>Planet Fraternity</h5>
					</Grid>
					<Grid item xs={8} sm={6} className={classes.footerSection}>
						<IconButton
							color='primary'
							className={classes.btnControls}
							aria-label='mic'
							onClick={() => micHandler(userStream.current, micEnabled, setMicEnabled)}
						>
							{micEnabled ? <Mic /> : <MicOff />}
						</IconButton>
						<IconButton
							color='primary'
							className={classes.btnControls}
							aria-label='videocam'
							onClick={() => camHandler(userStream.current, camEnabled, setCamEnabled)}
							disabled={isSharing}
						>
							{camEnabled ? <Videocam /> : <VideocamOff />}
						</IconButton>
						<Hidden only={['xs', 'sm']}>
							<IconButton
								color='primary'
								className={classes.btnControls}
								style={isSharing ? { backgroundColor: 'green' } : {}}
								aria-label='sharescreen'
								onClick={() =>
									screenShareHandler(
										isSharing,
										setIsSharing,
										userSharingStream,
										setUserSharingStream,
										myVideoTag.current,
										sendersRef.current,
										userStream.current,
									)
								}
							>
								<ScreenShare />
							</IconButton>
						</Hidden>
						<IconButton
							color='primary'
							className={classes.btnControls}
							aria-label='callend'
							onClick={() => handleEndCall(userInfo.role, userInfo.isAdmin)}
						>
							<CallEnd />
						</IconButton>
					</Grid>
					<Grid item xs={4} sm={3} className={classes.footerSection}>
						<Badge color='secondary' overlap='circular' badgeContent={peersInChat.length}>
							<IconButton
								color='primary'
								className={classes.btnControls}
								onClick={() => handleSidebar(1, sidebarValue, setSidebarValue)}
								aria-label='people'
							>
								<People />
							</IconButton>
						</Badge>
						<IconButton
							color='primary'
							className={classes.btnControls}
							onClick={() => handleSidebar(2, sidebarValue, setSidebarValue)}
							aria-label='chat'
						>
							<Chat />
						</IconButton>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default MeetRoomScreen

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import CustomModal from '../../../components/Modal/CustomModal'
import UserCard from './components/UserCard'
import UserListCard from '../../../components/Users/UserListCard/UserListCard'
import UserRegisterFormModal from './components/UserRegisterFormModal'
import { getUsers } from '../../../redux/actions/userActions'
import { USER_DELETE_RESET, USER_REGISTER_RESET, USER_UPDATE_RESET } from '../../../redux/constants/userConstants'
import styles from './styles/userListScreenStyles'
import UserButtons from './components/UserButtons'
import UserRightSection from './components/UserRightSection'

const useStyles = makeStyles(styles)

const UserListScreen = ({ match, history }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const classes = useStyles()

  const [matchKeyword, setMatchKeyword] = useState('')
  const [userArray, setUserArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)
  const [open, setOpen] = useState(false)
  const [userDetails, setUserDetails] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { successUserRegister } = useSelector((state) => state.userRegister)
  const userUpdate = useSelector((state) => state.userUpdate)
  const { successUserUpdate, userUpdated } = userUpdate
  const { successUserDelete } = useSelector((state) => state.userDelete)
  const { thatsIt, users } = useSelector((state) => state.userList)

  useEffect(() => {
    if (successUserUpdate) {
      setUserDetails(userUpdated)
    }
  }, [successUserUpdate, userUpdated])
  useEffect(() => {
    if (userInfo) {
      if (successUserDelete || successUserRegister || successUserUpdate) {
        if (successUserDelete) {
          dispatch({ type: USER_DELETE_RESET })
        } else if (successUserRegister) {
          dispatch({ type: USER_REGISTER_RESET })
          setUserDetails(null)
        } else if (successUserUpdate) {
          dispatch({ type: USER_UPDATE_RESET })
        }
        setUserArray([])
        setStart(1)
        setInitialHasMore(true)
        setHasMore(true)
        return true
      }
      if (keyword !== undefined) {
        if (keyword !== matchKeyword) {
          setUserArray([])
          setInitialHasMore(true)
          setMatchKeyword(keyword)
          setStart(1)
        } else {
          if (initalHasMore) {
            setInitialHasMore(false)
            dispatch(getUsers(keyword, count, 0))
          } else if (users && users.length > 0) {
            setUserArray((prev) => [...prev, ...users])
          }
        }
      } else {
        if (initalHasMore) {
          dispatch(getUsers(keyword, count, 0))
          setInitialHasMore(false)
        } else if (users && users.length > 0) {
          setUserArray((prev) => [...prev, ...users])
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [
    userInfo,
    history,
    dispatch,
    keyword,
    count,
    users,
    initalHasMore,
    matchKeyword,
    successUserDelete,
    successUserUpdate,
    successUserRegister,
  ])
  useEffect(() => {
    if (thatsIt) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getUsers(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'User List - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h1>User List</h1>
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <UserButtons keyword={keyword} history={history} setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfiniteScroll
            dataLength={userArray.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            height={'60vh'}
            className={classes.infiniteScroll}
            endMessage={
              <p className={classes.lastLine}>
                <b>This is the last line.</b>
              </p>
            }
          >
            {userArray.map((user) => (
              <UserListCard
                key={user._id}
                user={user}
                dropdownList={[<div onClick={() => setUserDetails(user)}>View Profile</div>]}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} md={8}>
          {userDetails !== null ? (
            <UserCard user={userDetails} setUserDetails={setUserDetails} />
          ) : (
            <UserRightSection />
          )}
        </Grid>
      </Grid>
      <CustomModal title='Register User' open={open} setOpen={setOpen} actions={false}>
        <UserRegisterFormModal setOpen={setOpen} />
      </CustomModal>
    </div>
  )
}

export default UserListScreen

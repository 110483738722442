export const SURVEY_REGISTER_REQUEST = 'SURVEY_REGISTER_REQUEST'
export const SURVEY_REGISTER_SUCCESS = 'SURVEY_REGISTER_SUCCESS'
export const SURVEY_REGISTER_FAIL = 'SURVEY_REGISTER_FAIL'
export const SURVEY_REGISTER_RESET = 'SURVEY_REGISTER_RESET'

export const SURVEY_LIST_REQUEST = 'SURVEY_LIST_REQUEST'
export const SURVEY_LIST_SUCCESS = 'SURVEY_LIST_SUCCESS'
export const SURVEY_LIST_FAIL = 'SURVEY_LIST_FAIL'
export const SURVEY_LIST_RESET = 'SURVEY_LIST_RESET'

export const SURVEY_DETAILS_REQUEST = 'SURVEY_DETAILS_REQUEST'
export const SURVEY_DETAILS_SUCCESS = 'SURVEY_DETAILS_SUCCESS'
export const SURVEY_DETAILS_FAIL = 'SURVEY_DETAILS_FAIL'
export const SURVEY_DETAILS_RESET = 'SURVEY_DETAILS_RESET'

export const SURVEY_UPDATE_REQUEST = 'SURVEY_UPDATE_REQUEST'
export const SURVEY_UPDATE_SUCCESS = 'SURVEY_UPDATE_SUCCESS'
export const SURVEY_UPDATE_FAIL = 'SURVEY_UPDATE_FAIL'
export const SURVEY_UPDATE_RESET = 'SURVEY_UPDATE_RESET'

export const SURVEY_DELETE_REQUEST = 'SURVEY_DELETE_REQUEST'
export const SURVEY_DELETE_SUCCESS = 'SURVEY_DELETE_SUCCESS'
export const SURVEY_DELETE_FAIL = 'SURVEY_DELETE_FAIL'
export const SURVEY_DELETE_RESET = 'SURVEY_DELETE_RESET'

export const SURVEY_ANSWER_REQUEST = 'SURVEY_ANSWER_REQUEST'
export const SURVEY_ANSWER_SUCCESS = 'SURVEY_ANSWER_SUCCESS'
export const SURVEY_ANSWER_FAIL = 'SURVEY_ANSWER_FAIL'
export const SURVEY_ANSWER_RESET = 'SURVEY_ANSWER_RESET'

export const ANSWER_REQUEST = 'ANSWER_REQUEST'
export const ANSWER_SUCCESS = 'ANSWER_SUCCESS'
export const ANSWER_FAIL = 'ANSWER_FAIL'
export const ANSWER_RESET = 'ANSWER_RESET'

export const ANSWER_SURVEY_REQUEST = 'ANSWER_SURVEY_REQUEST'
export const ANSWER_SURVEY_SUCCESS = 'ANSWER_SURVEY_SUCCESS'
export const ANSWER_SURVEY_FAIL = 'ANSWER_SURVEY_FAIL'
export const ANSWER_SURVEY_RESET = 'ANSWER_SURVEY_RESET'

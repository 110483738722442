import axios from 'axios'
import {
	ACTIVE_TESTIMONIAL_FAIL,
	ACTIVE_TESTIMONIAL_REQUEST,
	ACTIVE_TESTIMONIAL_SUCCESS,
	TESTIMONIAL_BY_TEACHER_FAIL,
	TESTIMONIAL_BY_TEACHER_REQUEST,
	TESTIMONIAL_BY_TEACHER_SUCCESS,
	TESTIMONIAL_DELETE_FAIL,
	TESTIMONIAL_DELETE_REQUEST,
	TESTIMONIAL_DELETE_SUCCESS,
	TESTIMONIAL_LIST_FAIL,
	TESTIMONIAL_LIST_REQUEST,
	TESTIMONIAL_LIST_SUCCESS,
	TESTIMONIAL_REGISTER_FAIL,
	TESTIMONIAL_REGISTER_REQUEST,
	TESTIMONIAL_REGISTER_SUCCESS,
	TESTIMONIAL_UPDATE_FAIL,
	TESTIMONIAL_UPDATE_REQUEST,
	TESTIMONIAL_UPDATE_SUCCESS,
} from '../constants/testimonialConstants'

export const registerTestimonial = (testimonial) => async (dispatch, getState) => {
	try {
		dispatch({ type: TESTIMONIAL_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/testimonials', testimonial, config)

		dispatch({ type: TESTIMONIAL_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TESTIMONIAL_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTestimonials =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: TESTIMONIAL_LIST_REQUEST })

			let dataResponse = []

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			if (keyword !== '') {
				const { data } = await axios.get(`/api/testimonials?count=${count}&start=${start}&keyword=${keyword}`, config)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/testimonials?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: TESTIMONIAL_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: TESTIMONIAL_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const activeTestimonials = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ACTIVE_TESTIMONIAL_REQUEST })

		const { data } = await axios.get('/api/testimonials/active')

		dispatch({ type: ACTIVE_TESTIMONIAL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: ACTIVE_TESTIMONIAL_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTestimonialByTeacher = (id) => async (dispatch) => {
	try {
		dispatch({ type: TESTIMONIAL_BY_TEACHER_REQUEST })

		const { data } = await axios.get(`/api/testimonials/teacher/${id}`)
		dispatch({ type: TESTIMONIAL_BY_TEACHER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TESTIMONIAL_BY_TEACHER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const testimonialUpdate = (testimonial) => async (dispatch, getState) => {
	try {
		dispatch({ type: TESTIMONIAL_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/testimonials/${testimonial._id}`, testimonial, config)

		dispatch({ type: TESTIMONIAL_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TESTIMONIAL_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const deleteTestimonial = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TESTIMONIAL_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/testimonials/${id}`, config)

		dispatch({ type: TESTIMONIAL_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: TESTIMONIAL_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

import Meta from '../../../components/Meta/Meta'

const ListeningScreen = () => {
	return (
		<div>
			<Meta title={'Listening - Planet Fraternity'} />
			<h1>ListeningScreen</h1>
		</div>
	)
}

export default ListeningScreen

import * as types from '../constants/testimonialConstants'

export const testimonialRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TESTIMONIAL_REGISTER_REQUEST:
      return { loadingTestimonialRegister: true }
    case types.TESTIMONIAL_REGISTER_SUCCESS:
      return {
        loadingTestimonialRegister: false,
        successTestimonialRegister: true,
        testimonial: action.payload,
      }
    case types.TESTIMONIAL_REGISTER_FAIL:
      return {
        loadingTestimonialRegister: false,
        errorTestimonialRegister: action.payload,
      }
    case types.TESTIMONIAL_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const testimonialListReducer = (state = { testimonials: [] }, action) => {
  switch (action.type) {
    case types.TESTIMONIAL_LIST_REQUEST:
      return { loadingTestimonialList: true }
    case types.TESTIMONIAL_LIST_SUCCESS:
      return {
        loadingTestimonialList: false,
        successTestimonialList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        testimonials: action.payload,
      }
    case types.TESTIMONIAL_LIST_FAIL:
      return {
        loadingTestimonialList: false,
        errorTestimonialList: action.payload,
      }
    case types.TESTIMONIAL_LIST_RESET:
      return { testimonials: [] }
    default:
      return state
  }
}

export const activeTestimonialsReducer = (state = { testimonials: [] }, action) => {
  switch (action.type) {
    case types.ACTIVE_TESTIMONIAL_REQUEST:
      return { loadingActiveTestimonials: true }
    case types.ACTIVE_TESTIMONIAL_SUCCESS:
      return {
        loadingActiveTestimonials: false,
        successActiveTestimonials: true,
        testimonials: action.payload,
      }
    case types.ACTIVE_TESTIMONIAL_FAIL:
      return {
        loadingActiveTestimonials: false,
        errorActiveTestimonials: action.payload,
      }
    case types.ACTIVE_TESTIMONIAL_RESET:
      return { testimonials: [] }
    default:
      return state
  }
}

export const testimonialTeacherReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TESTIMONIAL_BY_TEACHER_REQUEST:
      return { loadingTestimonialTeacher: true }
    case types.TESTIMONIAL_BY_TEACHER_SUCCESS:
      return {
        loadingTestimonialTeacher: false,
        testimonial: action.payload,
      }
    case types.TESTIMONIAL_BY_TEACHER_FAIL:
      return {
        loadingTestimonialTeacher: false,
        errorTestimonialTeacher: action.payload,
      }
    case types.TESTIMONIAL_BY_TEACHER_RESET:
      return {}
    default:
      return state
  }
}

export const testimonialUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TESTIMONIAL_UPDATE_REQUEST:
      return { loadingTestimonialUpdate: true }
    case types.TESTIMONIAL_UPDATE_SUCCESS:
      return {
        loadingTestimonialUpdate: false,
        successTestimonialUpdate: true,
        testimonialUpdate: action.payload,
      }
    case types.TESTIMONIAL_UPDATE_FAIL:
      return {
        loadingTestimonialUpdate: false,
        errorTestimonialUpdate: action.payload,
      }
    case types.TESTIMONIAL_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const testimonialDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TESTIMONIAL_DELETE_REQUEST:
      return { loadingTestimonialDelete: true }
    case types.TESTIMONIAL_DELETE_SUCCESS:
      return {
        loadingTestimonialDelete: false,
        successTestimonialDelete: true,
      }
    case types.TESTIMONIAL_DELETE_FAIL:
      return {
        loadingTestimonialDelete: false,
        errorTestimonialDelete: action.payload,
      }
    case types.TESTIMONIAL_DELETE_RESET:
      return {}
    default:
      return state
  }
}

const styles = {
    magazineCover: {
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
        height: 'auto',
        boxShadow:
            '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
    },
    magazineInfoSection: {
        border: '2px solid #e5e5e5',
        borderRadius: '16px',
        minHeight: '130px',
        height: '100%',
        padding: '20px',
        color: '#65676b',
        fontSize: 17,
    },
}

export default styles

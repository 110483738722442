const styles = {
  root: {
    padding: 20,
    '& h5': {
      textAlign: 'center',
      marginTop: '20px',
    },
  },
  modalRoot: {
    '& h2': {
      textAlign: 'center',
      margin: 'auto',
      color: '#1f7a2e',
    },
  },
  closeIcon: {
    padding: 5,
    marginTop: '30px',
  },
  projectsRoot: {
    margin: 'auto',
    padding: '30px',
    alignItems: 'center',
  },
  customTabsRoot: {
    minHeight: '200px',
    height: '100% !important',
  },
  countryFlagProfile: {
    width: '35px',
    borderRadius: '5px',
  },
  btnResource: {
    textAlign: 'end',
    '& p': {
      fontSize: '0.75rem',
      color: '#aaaaaa',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontWeight: 600,
    },
  },
  groupCardsArea: {
    padding: '5px',
    backgroundColor: '#f3f3f3',
    height: '55vh',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
      borderRadius: '10px',
    },
  },
  emptyMsg: {
    textAlign: 'center',
    marginTop: 10,
  },
}

export default styles

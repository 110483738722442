import { makeStyles } from '@material-ui/core'
import { Group } from '@material-ui/icons'
import styles from '../styles/teacherListRightSectionStyles'

const useStyles = makeStyles(styles)

const TeacherListRightSection = () => {
  const classes = useStyles(styles)

  return (
    <div className={classes.rightSection}>
      <Group className={classes.peoplesIcon} />
      <h2>Select people's names to preview their profile.</h2>
    </div>
  )
}

export default TeacherListRightSection

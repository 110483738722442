import { Grid, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/deleteFinalProjectModalStyles.js'

const useStyles = makeStyles(styles)

const DeleteFinalProjectModal = ({ setOpenDelete, handleDelete }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item md={12} xs={12}>
        <p>Are you sure you want to delete this final project?</p>
        <p>
          Once a final project is permanently deleted, it cannot be recovered. Permanently deleting this final project
          will immediately delete its all resources (video and images) related .
        </p>
      </Grid>
      <Grid container className={classes.deleteRoot}>
        <Grid md={6} sm={6} xs={6} item>
          <Button color='info' onClick={() => setOpenDelete(false)}>
            Cancel
          </Button>
        </Grid>
        <Grid md={6} sm={6} xs={6} item>
          <Button color='success' onClick={() => handleDelete()}>
            Delete
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default DeleteFinalProjectModal

export const PRICE_PER_COUNTRY_LIST_REQUEST = 'PRICE_PER_COUNTRY_LIST_REQUEST'
export const PRICE_PER_COUNTRY_LIST_SUCCESS = 'PRICE_PER_COUNTRY_LIST_SUCCESS'
export const PRICE_PER_COUNTRY_LIST_FAIL = 'PRICE_PER_COUNTRY_LIST_FAIL'
export const PRICE_PER_COUNTRY_LIST_RESET = 'PRICE_PER_COUNTRY_LIST_RESET'

export const PRICE_PER_COUNTRY_DETAILS_REQUEST = 'PRICE_PER_COUNTRY_DETAILS_REQUEST'
export const PRICE_PER_COUNTRY_DETAILS_SUCCESS = 'PRICE_PER_COUNTRY_DETAILS_SUCCESS'
export const PRICE_PER_COUNTRY_DETAILS_FAIL = 'PRICE_PER_COUNTRY_DETAILS_FAIL'
export const PRICE_PER_COUNTRY_DETAILS_RESET = 'PRICE_PER_COUNTRY_DETAILS_RESET'

export const PRICE_PER_COUNTRY_UPDATE_REQUEST = 'PRICE_PER_COUNTRY_UPDATE_REQUEST'
export const PRICE_PER_COUNTRY_UPDATE_SUCCESS = 'PRICE_PER_COUNTRY_UPDATE_SUCCESS'
export const PRICE_PER_COUNTRY_UPDATE_FAIL = 'PRICE_PER_COUNTRY_UPDATE_FAIL'
export const PRICE_PER_COUNTRY_UPDATE_RESET = 'PRICE_PER_COUNTRY_UPDATE_RESET'

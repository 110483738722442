import { GoogleOAuthProvider } from '@react-oauth/google'
import GoogleLogin from './GoogleLogin'

const UserLogin = ({ onSubmit }) => {
	return (
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
			<GoogleLogin credentials={onSubmit} />
		</GoogleOAuthProvider>
	)
}

export default UserLogin

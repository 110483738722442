import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../../components/Loader/Loader'
import Meta from '../../../components/Meta/Meta'
import CustomModal from '../../../components/Modal/CustomModal'
import TestimonialListCard from './components/TestimonialListCard'
import TestimonialCard from './components/TestimonialCard'
import TestimonialRightSection from './components/TestimonialRightSection'
import TestimonialButton from './components/TestimonialButton'
import TestimonialRegisterFormModal from './components/TestimonialRegisterFormModal'
import { getTestimonials } from '../../../redux/actions/testimonialActions'
import { getAllTeachers } from '../../../redux/actions/teacherActions'
import { TESTIMONIAL_LIST_RESET } from '../../../redux/constants/testimonialConstants'
import styles from './styles/testimonialsListScreenStyles'

const useStyles = makeStyles(styles)

const TestimonialsListScreen = ({ match }) => {
  const keyword = match.params.keyword
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const [testimonialDetails, setTestimonialDetails] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [start, setStart] = useState(1)
  const [count] = useState(15)
  const [testimonialArray, setTestimonialArray] = useState([])

  const { thatsIt, errorTestimonialList } = useSelector((state) => state.testimonialList)
  const { successTestimonialRegister } = useSelector((state) => state.testimonialRegister)
  const { successTestimonialUpdate, testimonialUpdate } = useSelector((state) => state.testimonialUpdate)

  useEffect(() => {
    dispatch(getAllTeachers())
  }, [dispatch, successTestimonialRegister])

  useEffect(() => {
    if (successTestimonialUpdate) {
      setTestimonialDetails(testimonialUpdate)
    }
  }, [successTestimonialUpdate, testimonialUpdate])

  useEffect(() => {
    if (thatsIt || errorTestimonialList === 'Testimonials not found.') {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorTestimonialList])

  useEffect(() => {
    return () => {
      dispatch({ type: TESTIMONIAL_LIST_RESET })
    }
  }, [dispatch])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getTestimonials(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Testimonial List - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h1>Testimonials List</h1>
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <TestimonialButton setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfiniteScroll
            dataLength={testimonialArray.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            height={'60vh'}
            className={classes.infiniteScroll}
            endMessage={
              <p className={classes.lastLine}>
                <b>This is the last line.</b>
              </p>
            }
          >
            {testimonialArray.map((testimonial, index) => (
              <TestimonialListCard
                key={testimonial._id}
                index={index}
                testimonial={testimonial}
                setTestimonialDetails={setTestimonialDetails}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} md={8}>
          {testimonialDetails !== null && <TestimonialCard testimonial={testimonialDetails} />}
          {testimonialDetails === null && <TestimonialRightSection />}
        </Grid>
      </Grid>
      <CustomModal title='Register Testimonial' open={open} setOpen={setOpen} actions={false}>
        <TestimonialRegisterFormModal
          keyword={keyword}
          setOpen={setOpen}
          setStart={setStart}
          setHasMore={setHasMore}
          setTestimonialArray={setTestimonialArray}
          setTestimonialDetails={setTestimonialDetails}
        />
      </CustomModal>
    </div>
  )
}

export default TestimonialsListScreen

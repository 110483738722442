const styles = (theme) => ({
  root: {
    width: '100%',
    marginBottom: 10,
  },
  btnwrapper: {
    padding: 10,
    '& svg': {
      margin: 0,
    },
  },
  navLink: {
    color: '#757575 !important',
    position: 'relative',
    padding: '0.9375rem',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'uppercase',
    borderRadius: '33px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0 7px',
    display: 'inline-flex',
    '& svg': {
      width: 22,
      height: 22,
    },
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  img: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  imageDropdownButton: {
    padding: '0px',
    borderRadius: '50%',
    marginLeft: '25px',
    backgroundColor: '#f3f3f3',
  },
  dropdownLink: {
    '&,&:hover,&:focus': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      padding: '10px 20px',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: '500px',
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '20px',
  },
})

export default styles

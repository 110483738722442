import { useEffect, useState, useMemo } from 'react'
import { io } from 'socket.io-client'

export const useSocket = () => {
  // const socket = useMemo(() => io.connect(process.env.REACT_APP_SOCKET, { transports: ['websocket', 'polling', 'flashsocket'] }), [])
  const socket = useMemo(() => io.connect(process.env.REACT_APP_SOCKET), [])

  const [online, setOnline] = useState(false)

  useEffect(() => {
    setOnline(socket.connected)
  }, [socket])

  useEffect(() => {
    socket.on('connect', () => {
      setOnline(true)
    })
  }, [socket])

  useEffect(() => {
    socket.on('disconnect', () => {
      setOnline(false)
    })
  }, [socket])

  return {
    socket,
    online,
  }
}

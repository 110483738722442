import { makeStyles } from '@material-ui/core'
import { VideoLabel } from '@material-ui/icons'
import styles from '../styles/miniBlogRightSectionStyles.js'

const useStyles = makeStyles(styles)

const MiniBlogRightSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.rightSection}>
      <VideoLabel className={classes.itemIcon} />
      <h2>Select post from the list to edit.</h2>
    </div>
  )
}

export default MiniBlogRightSection

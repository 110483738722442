const styles = {
    root: {
        width: '100%',
        backgroundColor: '#fff',
    },
    sections: {
        maxWidth: '1440px',
        margin: 'auto',
        padding: '50px',
        textAlign: 'center',
    },
    messageHeader: {
        '& img': {
            width: '200px',
            margin: 'auto',
        },
    },
    sectionRootReverse: {
        padding: 60,
    },
    onTopCardRoot: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    onTopCardLeft: {
        position: 'absolute',
        right: '0px',
        zIndex: 100,
        boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    },
    descRoot: {
        padding: 40,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            padding: '10px 0',
            margin: 0,
        },
    },
    contactLogo: {
        textAlign: 'center',
        padding: '20px',
    },
    '@media screen and (max-width: 1200px)': {
        descRoot: {
            padding: 0,
        },
    },
    '@media screen and (max-width: 980px)': {
        onTopCardLeft: {
            marginTop: 30,
            position: 'inherit',
            right: 0,
        },
    },
    '@media screen and (max-width: 580px)': {
        sectionRootReverse: {
            padding: '20px 10px',
        },
        descRoot: {
            padding: '30px',
        },
    },
}

export default styles

const styles = (theme) => ({
	root: {
		width: '100%',
		background: '#f1f1f1',
		// maxHeight: '750px',
		marginBottom: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	customerCarrousel: {
		minHeight: '300px',
		marginTop: '10px',
	},
	carouselImages: {
		width: '100%',
		background: '#fefefe',
		marginBottom: '10px',
		objectFit: 'contain',
		maxHeight: '460px',
		minHeight: '350px',
		'&:hover': {
			filter: 'brightness(70%)',
			cursor: 'pointer',
		},
		'&:active': {
			filter: 'brightness(120%)',
			cursor: 'pointer',
		},
	},
	zoomedImage: {
		width: '100%',
		maxHeight: '100%',
		objectFit: 'contain',
	},
	previewOptions: {
		paddingLeft: 10,
		paddingBottom: 10,
		marginTop: 5,
	},
	formControlLabel: {
		width: '22.5%',
		margin: '0 !important',
	},
	title: {
		width: 'fit-content',
		marginBottom: 5,
		fontWeight: 'bold',
		fontSize: 18,
		margin: '15px 15px 0 20px',
	},
	text: {
		width: 'fit-content',
		margin: '0 15px 0 15px',
	},
	labelOwner: {
		alignSelf: 'flex-end',
		margin: '0 15px 10px 15px',
		fontSize: '12px',
	},
	showMoreButton: {
		marginLeft: '5px',
		cursor: 'pointer',
	},
	accordion: {
		background: 'transparent',
		padding: '0',
		width: '100%',
		marginTop: '7px',
		boxShadow: 'none',
		borderTop: 'none',
		'::before': {
			display: 'none',
		},
	},
})

export default styles

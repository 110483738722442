const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        textAlign: 'center',
        padding: 15,
    },
    btnLink: {
        fontSize: 12,
        fontWeight: '500',
        color: '#483d3d',
        margin: '15px 0',
        padding: 0,
        textTransform: 'none',
        '& span': {
            fontSize: 14,
        },
    },
}

export default styles

import axios from 'axios'
import * as types from '../constants/miniBlogConstans'

export const registerMiniBlog = (item) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.MINIBLOG_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const fieldsFiles = ['blogImage', 'blog', 'blogVideo']

		let formData = new FormData()

		for (let key in item) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, item[key])
			}
		}

		if (item.coverImage) {
			formData.append('blogImage', item.coverImage[0])
		}
		if (item.coverVideo) {
			formData.append('blogVideo', item.coverVideo[0])
		}
		if (item.applicationFile) {
			formData.append('blog', item.applicationFile[0])
		}

		const { data } = await axios.post('/api/miniblogs', formData, config)

		dispatch({ type: types.MINIBLOG_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.MINIBLOG_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAllMiniBlogs = async (dispatch) => {
	try {
		dispatch({ type: types.MINIBLOG_LIST_REQUEST })

		const { data } = await axios.get('/api/miniblogs')

		dispatch({ type: types.MINIBLOG_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.MINIBLOG_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getActiveMiniBlogs = async (dispatch) => {
	try {
		dispatch({ type: types.MINIBLOG_ACTIVE_LIST_REQUEST })

		const { data } = await axios.get('/api/miniblogs/isActive')

		dispatch({ type: types.MINIBLOG_ACTIVE_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.MINIBLOG_ACTIVE_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteMiniBlogById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.MINIBLOG_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/miniblogs/${id}`, config)

		dispatch({ type: types.MINIBLOG_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: types.MINIBLOG_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateMiniBlog = (item) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.MINIBLOG_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const fieldsFiles = ['blogImage', 'blogVideo', 'blog']

		let formData = new FormData()

		for (let key in item) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, item[key])
			}
		}

		if (item.blogImage) {
			formData.append('blogImage', typeof item.blogImage === 'string' ? item.blogImage : item.blogImage[0])
		}
		if (item.blogVideo) {
			formData.append('blogVideo', typeof item.blogVideo === 'string' ? item.blogVideo : item.blogVideo[0])
		}
		if (item.blog) {
			formData.append('blog', typeof item.blog === 'string' ? item.blog : item.blog[0])
		}

		const { data } = await axios.put(`/api/miniblogs/${item._id}`, formData, config)

		dispatch({ type: types.MINIBLOG_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.MINIBLOG_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

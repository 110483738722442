import { useEffect, useRef } from 'react'
import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import CardAvatar from '../../../../components/Card/CardAvatar'
import UserCardForm from './UserCardForm'
import styles from '../styles/userCardstyles'

const useStyles = makeStyles(styles)

const UserCard = ({ user, setUserDetails }) => {
    const classes = useStyles()
    const edituserform = useRef(null)

    const scrollToRef = () => {
        edituserform.current?.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {
        scrollToRef()
    }, [user])

    return (
        <Grid container ref={edituserform}>
            <Grid item xs={12} sm={12} md={12}>
                <Card profile>
                    <CardAvatar profile>
                        <img src={`${axios.defaults.baseURL}${user.avatar}`} alt={user.name} />
                    </CardAvatar>
                    <CardBody profile>
                        <h4 className={classes.cardTitle}>{user.name}</h4>
                        <UserCardForm user={user} setUserDetails={setUserDetails} />
                    </CardBody>
                </Card>
            </Grid>
        </Grid>
    )
}

export default UserCard

const styles = {
    prevScreen: {
        width: '900px',
        margin: 'auto',
        position: 'relative',
        '& video': {
            borderRadius: '10px',
            boxShadow: '0 4px 18px 0px rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%)',
        },
    },
    prevControls: {
        width: '100%',
        height: '73px',
        position: 'absolute',
        bottom: 5,
        padding: 10,
        textAlign: 'center',
    },
    btnControls: {
        marginLeft: '5px',
        marginRight: '5px',
        backgroundColor: '#3e3939',
        '& svg': {
            color: '#fff',
        },
    },
    readyToJoin: {
        width: '500px',
        margin: 'auto',
        padding: '50px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
}

export default styles

const styles = {
	dialogRoot: {
		minWidth: '250px',
		maxWidth: '800px',
	},
	transparent: {
		background: 'transparent',
		boxShadow: 'none',
		minWidth: '500px',
		width: '100%',
		maxWidth: '800px',
		maxHeight: '90vh',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: 10,
		'& h4': {
			margin: 0,
		},
	},
	closeIcon: {
		backgroundColor: '#f9f0f0',
	},
	content: {
		padding: '8px 24px 18px',
	},
	transparentContent: {
		padding: '0 !important',
		width: '100%',
		height: '1000px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	actions: {
		padding: 20,
	},
	content: {
		'&::webkit-scroll-bar': {
			display: 'none',
		},
		scrollbarWidth: '0',
	},
}

export default styles

import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { Mic, MicOff, Videocam, VideocamOff } from '@material-ui/icons'
import Button from '../../../components/Buttons/Button'
import Meta from '../../../components/Meta/Meta'
import styles from './styles/prevMeetingScreenStyles'

const useStyles = makeStyles(styles)

const PrevMeetingScreen = ({ match, history }) => {
	const groupId = match.params.id
	const classes = useStyles()

	const ownVideo = useRef()
	const ownStream = useRef()

	const [micEnabled, setMicEnabled] = useState(true)
	const [camEnabled, setCamEnabled] = useState(true)

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (userInfo) {
			navigator.mediaDevices
				.getUserMedia({ audio: true, video: { width: 1280, height: 720 } })
				.then((stream) => {
					ownVideo.current.srcObject = stream
					ownVideo.current.muted = true
					ownStream.current = stream
				})
				.catch((err) => {})
		}
	}, [userInfo])

	return (
		<>
			<Meta title={'Prev Meeting Room - Planet Fraternity'} />
			<Grid container>
				<Grid item xs={12}>
					<div className={classes.prevScreen}>
						<video autoPlay ref={ownVideo} />
						<div className={classes.prevControls}>
							<IconButton
								color='primary'
								className={classes.btnControls}
								aria-label='mic'
								onClick={() => setMicEnabled(!micEnabled)}
							>
								{micEnabled ? <Mic /> : <MicOff />}
							</IconButton>
							<IconButton
								color='primary'
								className={classes.btnControls}
								aria-label='videocam'
								onClick={() => setCamEnabled(!camEnabled)}
							>
								{camEnabled ? <Videocam /> : <VideocamOff />}
							</IconButton>
						</div>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.readyToJoin}>
						<span>¿Ready to Join Room?</span>
						<Button color='primary' onClick={() => history.push(`/meet/classroom/${groupId}`)}>
							Join now
						</Button>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default PrevMeetingScreen

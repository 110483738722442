import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerUser } from '../../../../redux/actions/userActions'
import { getTeachersByCenter } from '../../../../redux/actions/teacherActions'
import { USER_REGISTER_RESET } from '../../../../redux/constants/userConstants'
import styles from '../styles/teacherRegisterFormStyles'

const useStyles = makeStyles(styles)

const TeacherRegisterForm = ({ center }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')

    const teachersByCenter = useSelector((state) => state.teachersByCenter)
    const { teachers } = teachersByCenter
    const userRegister = useSelector((state) => state.userRegister)
    const { loadingUserRegister, errorUserRegister, successUserRegister } = userRegister

    const submitFormHandler = (e) => {
        e.preventDefault()
        const createUser = {
            name,
            username,
            email,
            password: '123456',
            center: center._id,
            country: center.country,
        }
        dispatch(registerUser(createUser))
    }

    const createdSuccessHandler = () => {
        dispatch(getTeachersByCenter(center._id))
        dispatch({ type: USER_REGISTER_RESET })
        setName('')
        setUsername('')
        setEmail('')
    }

    return (
        <>
            {teachers && teachers.length >= center.license.teachersQty ? (
                <Grid container>
                    <Grid item md={12} className={classes.textCenter}>
                        <h2>You have reached the maximum amount of teachers of this school license.</h2>
                        <p>If you need more teachers please consider contact the system manager to upgrade the license.</p>
                    </Grid>
                </Grid>
            ) : successUserRegister ? (
                <Grid container>
                    <Grid item md={12} className={classes.textCenter}>
                        <h2>¡Great!</h2>
                        <p>User created successfully.</p>
                    </Grid>
                    <Grid item md={12}>
                        <Button color='primary' block onClick={createdSuccessHandler}>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <h2 className={classes.formTitle}>New Teacher</h2>
                        <form onSubmit={submitFormHandler} autoComplete='off'>
                            <CustomInput
                                labelText='Name'
                                id='newname'
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl,
                                }}
                                inputProps={{
                                    type: 'text',
                                    value: name,
                                    placeholder: 'Enter complete name',
                                    onChange: (e) => setName(e.target.value),
                                    required: true,
                                }}
                            />
                            <CustomInput
                                labelText='Username'
                                id='newusername'
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl,
                                }}
                                inputProps={{
                                    type: 'text',
                                    value: username,
                                    placeholder: 'Enter username (lowercase is better)',
                                    onChange: (e) => setUsername(e.target.value),
                                    required: true,
                                }}
                            />
                            <CustomInput
                                labelText='Email'
                                id='newemail'
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl,
                                }}
                                inputProps={{
                                    type: 'email',
                                    value: email,
                                    placeholder: 'Enter a valid email',
                                    onChange: (e) => setEmail(e.target.value),
                                    required: true,
                                }}
                            />
                            <Grid container>
                                <Grid item md={12}>
                                    <p className={classes.defPassMsg}>The default password is going to be 1 through 6</p>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button type='submit' color='primary' block>
                                        {loadingUserRegister ? <Loader size={'sm'} /> : 'Submit'}
                                    </Button>
                                </Grid>
                            </Grid>
                            {errorUserRegister && <Message>{errorUserRegister}</Message>}
                        </form>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default TeacherRegisterForm

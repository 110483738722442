import axios from 'axios'
import {
  INFORMATION_DELETE_FAIL,
  INFORMATION_DELETE_REQUEST,
  INFORMATION_DELETE_SUCCESS,
  INFORMATION_LIST_FAIL,
  INFORMATION_LIST_REQUEST,
  INFORMATION_LIST_SUCCESS,
  INFORMATION_REGISTER_FAIL,
  INFORMATION_REGISTER_REQUEST,
  INFORMATION_REGISTER_SUCCESS,
} from '../constants/informationConstants'

export const registerInformation = (resourceInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: INFORMATION_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const formData = new FormData()
    formData.append('title', resourceInfo.title)
    formData.append('application', resourceInfo.link[0])
    formData.append('category', resourceInfo.category)

    const { data } = await axios.post('/api/informations', formData, config)

    dispatch({ type: INFORMATION_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: INFORMATION_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getInformations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: INFORMATION_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/informations', config)

    dispatch({ type: INFORMATION_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: INFORMATION_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteInformation = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INFORMATION_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`/api/informations/${id}`, config)

    dispatch({ type: INFORMATION_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: INFORMATION_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

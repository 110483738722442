export const TEAM_GROUP_REGISTER_REQUEST = 'TEAM_GROUP_REGISTER_REQUEST'
export const TEAM_GROUP_REGISTER_SUCCESS = 'TEAM_GROUP_REGISTER_SUCCESS'
export const TEAM_GROUP_REGISTER_FAIL = 'TEAM_GROUP_REGISTER_FAIL'
export const TEAM_GROUP_REGISTER_RESET = 'TEAM_GROUP_REGISTER_RESET'

export const TEAM_GROUP_AUTOREGISTER_REQUEST = 'TEAM_GROUP_AUTOREGISTER_REQUEST'
export const TEAM_GROUP_AUTOREGISTER_SUCCESS = 'TEAM_GROUP_AUTOREGISTER_SUCCESS'
export const TEAM_GROUP_AUTOREGISTER_FAIL = 'TEAM_GROUP_AUTOREGISTER_FAIL'
export const TEAM_GROUP_AUTOREGISTER_RESET = 'TEAM_GROUP_AUTOREGISTER_RESET'

export const TEAM_GROUP_LIST_REQUEST = 'TEAM_GROUP_LIST_REQUEST'
export const TEAM_GROUP_LIST_SUCCESS = 'TEAM_GROUP_LIST_SUCCESS'
export const TEAM_GROUP_LIST_FAIL = 'TEAM_GROUP_LIST_FAIL'
export const TEAM_GROUP_LIST_RESET = 'TEAM_GROUP_LIST_RESET'

export const TEAM_GROUP_DETAILS_REQUEST = 'TEAM_GROUP_DETAILS_REQUEST'
export const TEAM_GROUP_DETAILS_SUCCESS = 'TEAM_GROUP_DETAILS_SUCCESS'
export const TEAM_GROUP_DETAILS_FAIL = 'TEAM_GROUP_DETAILS_FAIL'
export const TEAM_GROUP_DETAILS_RESET = 'TEAM_GROUP_DETAILS_RESET'

export const TEAM_GROUP_MY_DETAILS_REQUEST = 'TEAM_GROUP_MY_DETAILS_REQUEST'
export const TEAM_GROUP_MY_DETAILS_SUCCESS = 'TEAM_GROUP_MY_DETAILS_SUCCESS'
export const TEAM_GROUP_MY_DETAILS_FAIL = 'TEAM_GROUP_MY_DETAILS_FAIL'
export const TEAM_GROUP_MY_DETAILS_RESET = 'TEAM_GROUP_MY_DETAILS_RESET'

export const TEAM_GROUP_UPDATE_REQUEST = 'TEAM_GROUP_UPDATE_REQUEST'
export const TEAM_GROUP_UPDATE_SUCCESS = 'TEAM_GROUP_UPDATE_SUCCESS'
export const TEAM_GROUP_UPDATE_FAIL = 'TEAM_GROUP_UPDATE_FAIL'
export const TEAM_GROUP_UPDATE_RESET = 'TEAM_GROUP_UPDATE_RESET'

export const TEAM_GROUP_DELETE_REQUEST = 'TEAM_GROUP_DELETE_REQUEST'
export const TEAM_GROUP_DELETE_SUCCESS = 'TEAM_GROUP_DELETE_SUCCESS'
export const TEAM_GROUP_DELETE_FAIL = 'TEAM_GROUP_DELETE_FAIL'
export const TEAM_GROUP_DELETE_RESET = 'TEAM_GROUP_DELETE_RESET'

export const TEAM_GROUP_BYAGE_REQUEST = 'TEAM_GROUP_BYAGE_REQUEST'
export const TEAM_GROUP_BYAGE_SUCCESS = 'TEAM_GROUP_BYAGE_SUCCESS'
export const TEAM_GROUP_BYAGE_FAIL = 'TEAM_GROUP_BYAGE_FAIL'
export const TEAM_GROUP_BYAGE_RESET = 'TEAM_GROUP_BYAGE_RESET'

export const TEAM_GROUP_BYAGE_PREV_REQUEST = 'TEAM_GROUP_BYAGE_PREV_REQUEST'
export const TEAM_GROUP_BYAGE_PREV_SUCCESS = 'TEAM_GROUP_BYAGE_PREV_SUCCESS'
export const TEAM_GROUP_BYAGE_PREV_FAIL = 'TEAM_GROUP_BYAGE_PREV_FAIL'
export const TEAM_GROUP_BYAGE_PREV_RESET = 'TEAM_GROUP_BYAGE_PREV_RESET'

export const TEAM_GROUP_ACTUALLY_REQUEST = 'TEAM_GROUP_ACTUALLY_REQUEST'
export const TEAM_GROUP_ACTUALLY_SUCCESS = 'TEAM_GROUP_ACTUALLY_SUCCESS'
export const TEAM_GROUP_ACTUALLY_FAIL = 'TEAM_GROUP_ACTUALLY_FAIL'
export const TEAM_GROUP_ACTUALLY_RESET = 'TEAM_GROUP_ACTUALLY_RESET'

export const TEAM_GROUP_AUTOMATE_REQUEST = 'TEAM_GROUP_AUTOMATE_REQUEST'
export const TEAM_GROUP_AUTOMATE_SUCCESS = 'TEAM_GROUP_AUTOMATE_SUCCESS'
export const TEAM_GROUP_AUTOMATE_FAIL = 'TEAM_GROUP_AUTOMATE_FAIL'
export const TEAM_GROUP_AUTOMATE_RESET = 'TEAM_GROUP_AUTOMATE_RESET'

export const TEAM_BYAGE_NIN_TOPIC_REQUEST = 'TEAM_BYAGE_NIN_TOPIC_REQUEST'
export const TEAM_BYAGE_NIN_TOPIC_SUCCESS = 'TEAM_BYAGE_NIN_TOPIC_SUCCESS'
export const TEAM_BYAGE_NIN_TOPIC_FAIL = 'TEAM_BYAGE_NIN_TOPIC_FAIL'
export const TEAM_BYAGE_NIN_TOPIC_RESET = 'TEAM_BYAGE_NIN_TOPIC_RESET'

export const TEAM_BYAGE_STUDENTS_LITTLE_REQUEST = 'TEAM_BYAGE_STUDENTS_LITTLE_REQUEST'
export const TEAM_BYAGE_STUDENTS_LITTLE_SUCCESS = 'TEAM_BYAGE_STUDENTS_LITTLE_SUCCESS'
export const TEAM_BYAGE_STUDENTS_LITTLE_FAIL = 'TEAM_BYAGE_STUDENTS_LITTLE_FAIL'
export const TEAM_BYAGE_STUDENTS_LITTLE_RESET = 'TEAM_BYAGE_STUDENTS_LITTLE_RESET'

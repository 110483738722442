import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Box, Card, CardActions, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import styles from '../styles/miniBlogItemListStyles'

const useStyles = makeStyles(styles)

const MiniBlogItemList = ({ articles }) => {
	const { t } = useTranslation('global')
	const classes = useStyles()

	const { loadingMiniBlogList } = useSelector((state) => state.miniBlogList)
	return (
		<>
			{loadingMiniBlogList ? (
				<Loader color='white' />
			) : (
				<Grid container className={classes.root}>
					{articles &&
						articles.length > 0 &&
						articles.map((item) => (
							<Grid key={item._id} item md={8} sm={8} xs={11} className={classes.articleItem}>
								<Card sx={{ display: 'flex' }}>
									<Grid container>
										{item.image && (
											<Grid item md={6} sm={6}>
												<CardMedia
													component='img'
													className={classes.cardMedia}
													image={`${axios.defaults.baseURL}/${item.image}`}
													alt={item.title}
												/>
											</Grid>
										)}
										{item.video && (
											<Grid item md={6} sm={6}>
												<CardMedia
													component='video'
													className={classes.cardMedia}
													src={`${axios.defaults.baseURL}/${item.video}`}
													controls
													style={{ height: '100%', width: '100%', maxHeight: '60vh' }}
													alt={item.title}
												/>
											</Grid>
										)}
										<Grid item md={item.image || item.video ? 6 : 12} sm={item.image || item.video ? 6 : 12} xs={12}>
											<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
												<CardContent sx={{ flex: '1 0 auto' }}>
													<Typography component='div' variant='h5'>
														{item.title}
													</Typography>
													<hr />
													<Typography className={classes.textContent} variant='body2' color='textSecondary'>
														{item.text}
													</Typography>
													{item?.file && (
														<CardActions>
															<Button
																block
																onClick={() => window.open(`${axios.defaults.baseURL}/${item.file}`)}
																color='primary'
															>
																{t('layout.buttons.t_more-info')}
															</Button>
														</CardActions>
													)}
												</CardContent>
											</Box>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						))}
				</Grid>
			)}
		</>
	)
}

export default MiniBlogItemList

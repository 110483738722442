import Button from '../../../../components/Buttons/Button'

const SurveyButton = ({ setOpen }) => {
  return (
    <Button color='success' onClick={() => setOpen(true)}>
      + Create Survey
    </Button>
  )
}

export default SurveyButton

const styles = (theme) => ({
    root: {
        width: '100%',
        marginBottom: 10,
    },
    btnwrapper: {
        padding: 10,
        '& svg': {
            margin: 0,
        },
    },
    navLink: {
        color: '#757575 !important',
        position: 'relative',
        padding: '0.9375rem',
        fontWeight: '600',
        fontSize: '14px',
        textTransform: 'uppercase',
        borderRadius: '33px',
        lineHeight: '20px',
        textDecoration: 'none',
        margin: '0 7px',
        display: 'inline-flex',
        '& svg': {
            width: 22,
            height: 22,
        },
        '&:hover,&:focus': {
            color: 'inherit',
            background: 'rgba(200, 200, 200, 0.2)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
            marginLeft: '15px',
            marginBottom: '8px',
            marginTop: '8px',
            textAlign: 'left',
            '& > span:first-child': {
                justifyContent: 'flex-start',
            },
        },
    },
    imageDropdownButton: {
        padding: '0px',
        borderRadius: '50%',
        marginLeft: '25px',
        backgroundColor: '#f3f3f3',
    },
})

export default styles

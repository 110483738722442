import { Grid, Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import Button from '../Buttons/Button'
import styles from './styles/cardMediaStyles.js'

const useStyles = makeStyles(styles)

const CardMedia = ({ srcImg, altImg, widthImg, title, titleVariant, children, cardLink, btnText }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Card className={classes.cardRoot}>
            <div className={classes.imgMask}>
                <img src={srcImg} alt={altImg} width={widthImg} />
            </div>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={titleVariant} align='center'>
                            {title}
                        </Typography>
                    </Grid>
                    {children && (
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    )}
                    {cardLink && (
                        <Grid item xs={12}>
                            <Button color='primary' block onClick={() => history.push(cardLink)}>
                                {btnText}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

CardMedia.defaultProps = {
    srcImg: '/assets/img/public-site/img-feel.png',
    altImg: 'Planet Fraternity',
    widthImg: '100%',
    title: 'Card Title',
    titleVariant: 'h4',
    btnText: 'More Info',
}

CardMedia.propTypes = {
    srcImg: PropTypes.string.isRequired,
    altImg: PropTypes.string.isRequired,
    widthImg: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleVariant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
        'srOnly',
        'inherit',
    ]),
    cardLink: PropTypes.string,
    btnText: PropTypes.string,
    children: PropTypes.node,
}

export default CardMedia

import axios from 'axios'
import {
  IMAGE_DELETE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_LIST_FAIL,
  IMAGE_LIST_REQUEST,
  IMAGE_LIST_SUCCESS,
  IMAGE_REGISTER_FAIL,
  IMAGE_REGISTER_REQUEST,
  IMAGE_REGISTER_SUCCESS,
  IMAGE_UPDATE_FAIL,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
} from '../constants/galleryConstants'

export const registerImage = (gallery) => async (dispatch, getState) => {
  try {
    dispatch({ type: IMAGE_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    let formData = new FormData()

    for (let key in gallery) {
      if (key) {
        formData.append(key, gallery[key])
      }
    }

    const { data } = await axios.post('/api/gallery/image', formData, config)

    dispatch({ type: IMAGE_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: IMAGE_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getImages =
  (keyword = '', count, start) =>
  async (dispatch) => {
    try {
      dispatch({ type: IMAGE_LIST_REQUEST })

      let dataResponse = []

      if (keyword !== '') {
        const { data } = await axios.get(`/api/gallery?count=${count}&start=${start}&keyword=${keyword}`)
        dataResponse = data
      } else {
        const { data } = await axios.get(`/api/gallery?count=${count}&start=${start}`)
        dataResponse = data
      }

      dispatch({ type: IMAGE_LIST_SUCCESS, payload: dataResponse })
    } catch (error) {
      dispatch({
        type: IMAGE_LIST_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const deleteImage = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: IMAGE_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/gallery/image/${id}`, config)

    dispatch({ type: IMAGE_DELETE_SUCCESS })
  } catch (error) {
    dispatch({
      type: IMAGE_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateImage = (gallery) => async (dispatch, getState) => {
  try {
    dispatch({ type: IMAGE_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    let formData = new FormData()

    for (let key in gallery) {
      if (key) {
        formData.append(key, gallery[key])
      }
    }
    const { data } = await axios.put(`/api/gallery/image/${gallery._id}`, formData, config)

    dispatch({ type: IMAGE_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: IMAGE_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

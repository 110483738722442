export const TEACHERS_LIST_REQUEST = 'TEACHERS_LIST_REQUEST'
export const TEACHERS_LIST_SUCCESS = 'TEACHERS_LIST_SUCCESS'
export const TEACHERS_LIST_FAIL = 'TEACHERS_LIST_FAIL'
export const TEACHERS_LIST_RESET = 'TEACHERS_LIST_RESET'

export const TEACHERS_LIST_ALL_REQUEST = 'TEACHERS_LIST_ALL_REQUEST'
export const TEACHERS_LIST_ALL_SUCCESS = 'TEACHERS_LIST_ALL_SUCCESS'
export const TEACHERS_LIST_ALL_FAIL = 'TEACHERS_LIST_ALL_FAIL'
export const TEACHERS_LIST_ALL_RESET = 'TEACHERS_LIST_ALL_RESET'

export const TEACHERS_BY_EMAIL_REQUEST = 'TEACHERS_BY_EMAIL_REQUEST'
export const TEACHERS_BY_EMAIL_SUCCESS = 'TEACHERS_BY_EMAIL_SUCCESS'
export const TEACHERS_BY_EMAIL_FAIL = 'TEACHERS_BY_EMAIL_FAIL'
export const TEACHERS_BY_EMAIL_RESET = 'TEACHERS_BY_EMAIL_RESET'

export const TEACHERS_BY_CENTER_REQUEST = 'TEACHERS_BY_CENTER_REQUEST'
export const TEACHERS_BY_CENTER_SUCCESS = 'TEACHERS_BY_CENTER_SUCCESS'
export const TEACHERS_BY_CENTER_FAIL = 'TEACHERS_BY_CENTER_FAIL'
export const TEACHERS_BY_CENTER_RESET = 'TEACHERS_BY_CENTER_RESET'

export const TEACHERS_DETAILS_REQUEST = 'TEACHERS_DETAILS_REQUEST'
export const TEACHERS_DETAILS_SUCCESS = 'TEACHERS_DETAILS_SUCCESS'
export const TEACHERS_DETAILS_FAIL = 'TEACHERS_DETAILS_FAIL'

export const TEACHERS_GROUPS_REQUEST = 'TEACHERS_GROUPS_REQUEST'
export const TEACHERS_GROUPS_SUCCESS = 'TEACHERS_GROUPS_SUCCESS'
export const TEACHERS_GROUPS_FAIL = 'TEACHERS_GROUPS_FAIL'
export const TEACHERS_GROUPS_RESET = 'TEACHERS_GROUPS_RESET'

export const TEACHER_DELETE_REQUEST = 'TEACHER_DELETE_REQUEST'
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS'
export const TEACHER_DELETE_FAIL = 'TEACHER_DELETE_FAIL'
export const TEACHER_DELETE_RESET = 'TEACHER_DELETE_RESET'

import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'
const styles = {
  contentItemsEnd,
  root: {
    padding: '30px',
    '& h1': {
      lineHeight: 'normal',
    },
  },
  btnResetSearch: {
    marginRight: '15px',
  },

  contentLine: {
    textAlign: 'center',
  },
  containerItems: {
    ...infiniteScroll,
    maxHeight: '40vh',
    overflowY: 'scroll',
  },
  '@media screen and (max-width: 580px)': {
    root: {
      padding: '10px',
    },
  },
}
export default styles

import { makeStyles } from '@material-ui/styles'
import { PersonAdd } from '@material-ui/icons'
import styles from '../styles/teacherEmptyTabContentStyles.js'

const useStyles = makeStyles(styles)

const TeacherEmptyTabContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.emptyMsg}>
      <h2>There are no teachers registered in this center yet.</h2>
      <PersonAdd />
      <p>Please add the first teacher.</p>
    </div>
  )
}

export default TeacherEmptyTabContent

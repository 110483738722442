import axios from 'axios'
import {
  PRICE_PER_COUNTRY_LIST_REQUEST,
  PRICE_PER_COUNTRY_LIST_SUCCESS,
  PRICE_PER_COUNTRY_LIST_FAIL,
  PRICE_PER_COUNTRY_DETAILS_REQUEST,
  PRICE_PER_COUNTRY_DETAILS_SUCCESS,
  PRICE_PER_COUNTRY_DETAILS_FAIL,
  PRICE_PER_COUNTRY_UPDATE_REQUEST,
  PRICE_PER_COUNTRY_UPDATE_SUCCESS,
  PRICE_PER_COUNTRY_UPDATE_FAIL,
} from '../constants/pricePerCountryConstants'

export const getPricePerCountryList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRICE_PER_COUNTRY_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/price-per-countries', config)

    dispatch({ type: PRICE_PER_COUNTRY_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRICE_PER_COUNTRY_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPricePerCountryId = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRICE_PER_COUNTRY_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/price-per-countries/${id}`)

    dispatch({ type: PRICE_PER_COUNTRY_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRICE_PER_COUNTRY_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateCountryPrice = (pricePerCountry) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRICE_PER_COUNTRY_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/price-per-countries/${pricePerCountry._id}`, pricePerCountry, config)

    dispatch({ type: PRICE_PER_COUNTRY_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRICE_PER_COUNTRY_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  makeStyles,
} from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import { userUpdateInfo, deleteUser } from '../../../../redux/actions/userActions'
import { getAllCountries } from '../../../../redux/actions/countryActions'
import styles from '../styles/userCardFormStyle'
import { USER_UPDATE_RESET } from '../../../../redux/constants/userConstants'

const useStyles = makeStyles(styles)

const UserCardForm = ({ user, setUserDetails }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [isCollaborator, setIsCollaborator] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isSuper, setIsSuper] = useState(false)
  const [password, setPassword] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [successUpdated, setSuccessUpdate] = useState(false)
  const [usernameError, setUsernameError] = useState(false)
  const [countryId, setCountryId] = useState('')
  const [countryName, setCountryName] = useState('')
  const [countryFlag, setCountryFlag] = useState('')

  const { loadingUserUpdate, errorUserUpdate, successUserUpdate } = useSelector((state) => state.userUpdate)
  const { loadingUserDelete, errorUserDelete } = useSelector((state) => state.userDelete)
  const { loadingCountryAll, errorCountryAll, allCountries } = useSelector((state) => state.countryAll)

  useEffect(() => {
    dispatch(getAllCountries())
  }, [dispatch])
  useEffect(() => {
    if (user) {
      setName(user.name)
      setUsername(user.username)
      setEmail(user.email)
      setPhone(user.phone || '')
      setAddress(user.address || '')
      setCountryId(user.country?._id || '')
      setCountryName(user.country?.name || '')
      setCountryFlag(user.country?.flag || '')
      setIsCollaborator(user.isCollaborator)
      setIsAdmin(user.isAdmin)
      setIsSuper(user.isSuper)
    }
  }, [user])
  useEffect(() => {
    if (successUserUpdate) {
      setSuccessUpdate(true)
      dispatch({ type: USER_UPDATE_RESET })
      setTimeout(() => {
        setSuccessUpdate(false)
      }, 2000)
    }
  }, [successUserUpdate, dispatch])

  useEffect(() => {
    if (errorUserUpdate) {
      setUsernameError(true)
    }
  }, [errorUserUpdate])

  useEffect(() => {
    setUsernameError(false)
  }, [username])

  const submitEditFormHandler = (e) => {
    e.preventDefault()
    const updateUser = {
      _id: user._id,
      name,
      username,
      email,
      password,
      phone,
      address,
      isCollaborator,
      isAdmin,
      isSuper,
    }
    if (countryId.length > 0) {
      updateUser.country = {
        _id: countryId,
        name: countryName,
        flag: countryFlag,
      }
    }
    dispatch(userUpdateInfo(updateUser))
    if (password) {
      setPassword('')
    }
  }
  const deleteHandler = () => {
    dispatch(deleteUser(user._id))
    setUserDetails(null)
    setOpenDelete(false)
  }

  const countryHandler = (id) => {
    setCountryId(id)
    const thisCountry = allCountries.find((country) => country._id === id)
    setCountryName(thisCountry.name)
    setCountryFlag(thisCountry.flag)
  }

  return (
    <>
      <form onSubmit={submitEditFormHandler} autoComplete='off'>
        <CustomInput
          labelText='Name'
          id='newname'
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            type: 'text',
            value: name,
            placeholder: 'Enter complete name',
            onChange: (e) => setName(e.target.value),
            required: true,
          }}
        />
        <CustomInput
          labelText={usernameError ? 'Username not available' : 'Username'}
          id='newusername'
          error={usernameError ? true : false}
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            type: 'text',
            value: username,
            placeholder: 'Enter username',
            onChange: (e) => setUsername(e.target.value),
            required: true,
          }}
        />
        <CustomInput
          labelText='Email'
          id='newemail'
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            type: 'email',
            value: email,
            disabled: true,
            placeholder: 'Enter a valid email',
            onChange: (e) => setEmail(e.target.value),
          }}
        />
        <CustomInput
          labelText='Phone'
          id='newphone'
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            type: 'text',
            value: phone,
            placeholder: 'E.g. +34 000 000000',
            onChange: (e) => setPhone(e.target.value),
          }}
        />
        <CustomInput
          labelText='Address'
          id='newaddress'
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            type: 'text',
            value: address,
            placeholder: 'Enter address (Optional)',
            onChange: (e) => setAddress(e.target.value),
          }}
        />
        <CustomInput
          labelText='Password'
          id='password'
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          inputProps={{
            type: 'password',
            value: password,
            placeholder: 'Enter password',
            onChange: (e) => setPassword(e.target.value),
          }}
        />
        <Grid item xs={12}>
          {loadingCountryAll ? (
            <Loader />
          ) : errorCountryAll ? (
            <Message message={<span>{errorCountryAll}</span>} color='info' />
          ) : (
            <FormControl className={classes.formControl}>
              <InputLabel id='countryid' className={classes.selectLabel}>
                Country
              </InputLabel>
              <Select
                labelId='countryid'
                id='countryvalue'
                value={countryId}
                onChange={(e) => countryHandler(e.target.value)}
              >
                <MenuItem value='' disabled>
                  Select a Country
                </MenuItem>
                {!loadingCountryAll &&
                  allCountries.map((country) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12} md={4} className={classes.switchItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isCollaborator}
                  onChange={(e) => setIsCollaborator(e.target.checked)}
                  name='isCollaborator'
                  value='isCollaborator'
                  color='primary'
                />
              }
              label='Is Collaborator'
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.switchItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                  name='isAdmin'
                  value='isAdmin'
                  color='primary'
                />
              }
              label='Is Admin'
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.switchItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSuper}
                  onChange={(e) => setIsSuper(e.target.checked)}
                  name='isSuper'
                  value='isSuper'
                />
              }
              label='Is Super'
            />
          </Grid>
          <Grid item xs={8} md={9}>
            <Button
              type='submit'
              block
              color={successUpdated ? 'success' : 'primary'}
              disabled={successUpdated && true}
            >
              {loadingUserUpdate ? <Loader size={'sm'} /> : successUpdated ? 'Updated Successfully' : 'Update User'}
            </Button>
          </Grid>
          <Grid item xs={4} md={3}>
            <Button color='danger' block onClick={() => setOpenDelete(true)}>
              {loadingUserDelete ? <Loader size={'sm'} /> : 'Delete'}
            </Button>
          </Grid>
        </Grid>
        {errorUserDelete && <Message message={<span>{errorUserDelete}</span>} color='info' />}
      </form>
      <CustomModal
        title='Delete User?'
        open={openDelete}
        setOpen={setOpenDelete}
        acceptText='Delete'
        acceptHandler={deleteHandler}
      >
        Are you sure you want to delete this user?
      </CustomModal>
    </>
  )
}

export default UserCardForm

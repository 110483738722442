import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, makeStyles } from '@material-ui/core'
import CustomModal from '../../../components/Modal/CustomModal'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import ImageRegisterModal from './components/ImageRegisterModal'
import ImageItemList from './components/ImageItemList'
import ImageCardForm from './components/ImageCardForm'
import GallerySolidaryRightSection from './components/GallerySolidaryRightSection'
import GallerySolidaryButton from './components/GallerySolidaryButton'
import { getImages } from '../../../redux/actions/galleryActions'
import { IMAGE_DELETE_RESET, IMAGE_REGISTER_RESET, IMAGE_UPDATE_RESET } from '../../../redux/constants/galleryConstants'
import styles from './styles/gallerySolidarityScreenStyles'

const useStyles = makeStyles(styles)

const GallerySolidarityScreen = ({ match, history }) => {
	const keyword = match.params.keyword
	const classes = useStyles()
	const dispatch = useDispatch()

	const [open, setOpen] = useState(false)
	const [imageItemDetail, setImageItemDetail] = useState(null)
	const [imageArray, setImageArray] = useState([])
	const [count] = useState(15)
	const [start, setStart] = useState(1)
	const [hasMore, setHasMore] = useState(true)
	const [initalHasMore, setInitialHasMore] = useState(true)
	const [matchKeyword, setMatchKeyword] = useState('')

	const { userInfo } = useSelector((state) => state.userLogin)
	const { images, thatsIt, errorImageList } = useSelector((state) => state.imageGalleryList)
	const { successImageRegister } = useSelector((state) => state.imageGalleryRegister)
	const { successImageDelete } = useSelector((state) => state.imageGalleryDelete)
	const { successImageUpdate, imageUpdated } = useSelector((state) => state.imageGalleryUpdate)

	useEffect(() => {
		if (successImageUpdate) {
			setImageItemDetail(imageUpdated)
		}
	}, [successImageUpdate, imageUpdated])

	useEffect(() => {
		if (userInfo) {
			if (successImageDelete || successImageRegister || successImageUpdate) {
				if (successImageRegister) {
					dispatch({ type: IMAGE_REGISTER_RESET })
				} else if (successImageDelete) {
					dispatch({ type: IMAGE_DELETE_RESET })
					setImageItemDetail(null)
				} else if (successImageUpdate) {
					dispatch({ type: IMAGE_UPDATE_RESET })
				}
				setImageArray([])
				setStart(1)
				setInitialHasMore(true)
				setHasMore(true)
				return true
			}
			if (keyword !== undefined) {
				if (keyword !== matchKeyword) {
					setImageArray([])
					setInitialHasMore(true)
					setMatchKeyword(keyword)
					setStart(1)
				} else {
					if (initalHasMore) {
						setInitialHasMore(false)
						dispatch(getImages(keyword, count, 0))
					} else if (images && images.length > 0) {
						setImageArray((prev) => [...prev, ...images])
					}
				}
			} else {
				if (initalHasMore) {
					dispatch(getImages(keyword, count, 0))
					setInitialHasMore(false)
				} else if (images && images.length > 0) {
					setImageArray((prev) => [...prev, ...images])
				}
			}
		} else {
			history.push('/auth/login')
		}
	}, [
		userInfo,
		history,
		dispatch,
		keyword,
		count,
		images,
		initalHasMore,
		matchKeyword,
		successImageRegister,
		successImageDelete,
		successImageUpdate,
	])

	useEffect(() => {
		if (thatsIt || errorImageList === 'Images not found.') {
			setHasMore(false)
		} else {
			setHasMore(true)
		}
	}, [thatsIt, errorImageList])

	const fetchData = () => {
		if (hasMore) {
			setStart(start + 1)
			dispatch(getImages(keyword, count, start))
		}
	}

	return (
		<div className={classes.root}>
			<Meta title={'Gallery Solidarity - Planet Fraternity'} />
			<Grid container spacing={3}>
				<Grid item xs={12} md={3}>
					<h1>Gallery Solidarity</h1>
				</Grid>
				<Grid item xs={12} md={9} className={classes.contentItemsEnd}>
					<GallerySolidaryButton setOpen={setOpen} />
				</Grid>
				<Grid item xs={12} md={4}>
					<InfiniteScroll
						dataLength={imageArray.length}
						next={fetchData}
						hasMore={hasMore}
						loader={<Loader />}
						height={'60vh'}
						className={classes.infiniteScroll}
						endMessage={
							<p className={classes.contentLine}>
								<b>Thats it.</b>
							</p>
						}
					>
						{imageArray &&
							imageArray.map((image, index) => (
								<ImageItemList
									setImageItemDetail={setImageItemDetail}
									item={image}
									key={image?._id}
									numberItem={index}
								/>
							))}
					</InfiniteScroll>
				</Grid>
				<Grid item xs={12} md={8}>
					{imageItemDetail !== null ? (
						<ImageCardForm imageItemDetail={imageItemDetail} />
					) : (
						<GallerySolidaryRightSection />
					)}
				</Grid>
			</Grid>
			<CustomModal title='Register Image' open={open} setOpen={setOpen} actions={false}>
				<ImageRegisterModal setOpen={setOpen} />
			</CustomModal>
		</div>
	)
}

export default GallerySolidarityScreen

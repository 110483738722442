const styles = {
  videoCarouselRoot: {
    margin: 'auto',
    borderRadius: '10px',
    '& video': {
      borderRadius: '10px',
      width: '100%',
      height: '100%',
    },
  },
  sectionRootWReverse: {
    padding: 60,
  },
}

export default styles

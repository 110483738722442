import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import { Card, CardHeader, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Message from '../../../../components/Message/Message'
import CustomModal from '../../../../components/Modal/CustomModal'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import { deleteWritingC } from '../../../../redux/actions/writingContestActions'
import styles from '../styles/writingContestListCardStyles'

const useStyles = makeStyles(styles)

const WritingContestListCard = ({ writing, setWritingDetails }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [openDelete, setOpenDelete] = useState(false)

  const { successWritingCDelete, errorWritingCDelete } = useSelector((state) => state.writingCDelete)

  useEffect(() => {
    if (successWritingCDelete) {
      setOpenDelete(false)
    }
  }, [successWritingCDelete])

  const deleteWritingCHandler = () => {
    dispatch(deleteWritingC(writing._id))
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        action={
          <CustomDropdown
            left
            caret={false}
            buttonText={
              <div className={classes.btnwrapper}>
                <MoreVert />
              </div>
            }
            buttonProps={{
              className: classes.navLink + ' ' + classes.imageDropdownButton,
              color: 'transparent',
            }}
            dropdownList={[
              <div onClick={() => setWritingDetails(writing)}>View Info</div>,
              <div onClick={() => setOpenDelete(true)}>Delete</div>,
            ]}
          />
        }
        title={writing.title}
        subheader={format(new Date(writing.contestDate), 'MMMM yyyy')}
      />
      <CustomModal
        title='Delete Writing Contest?'
        open={openDelete}
        setOpen={setOpenDelete}
        acceptText='Delete'
        acceptHandler={deleteWritingCHandler}
      >
        <p>Are you sure you want to delete this Writing Contest and its related resources?</p>
        <p>
          Once a Writing Contest is permanently deleted, it cannot be recovered. Permanently deleting this Writing
          Contest will immediately delete its related resources (if there are any).
        </p>
        <p>Resources that will be deleted:</p>
        <ul>
          <li>Writing Contest Participations</li>
          <li>Student's Points</li>
        </ul>
        {errorWritingCDelete && <Message message={<span>{errorWritingCDelete}</span>} color='info' />}
      </CustomModal>
    </Card>
  )
}

export default WritingContestListCard

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import Button from '../../../../components/Buttons/Button'
import CustomModal from '../../../../components/Modal/CustomModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import StudentListCard from './StudentListCard'
import StudentRegisterFormModal from './StudentRegisterFormModal'
import { getStudentsByGroup } from '../../../../redux/actions/studentActions'
import {
  STUDENT_REGISTER_RESET,
  STUDENT_DELETE_RESET,
  STUDENT_UPDATE_RESET,
} from '../../../../redux/constants/studentConstants'
import styles from '../styles/studentListStyles'

const useStyles = makeStyles(styles)

const StudentList = ({ match, viewGroup, setGroupInfo, setStudentsQty }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const [matchKeyword, setMatchKeyword] = useState('')
  const [groupId, setGroupId] = useState(null)
  const [groupData, setGroupData] = useState(null)
  const [studentArray, setStudentArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)
  const [openAddStudent, setOpenAddStudent] = useState(false)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { successStudentRegister } = useSelector((state) => state.studentRegister)
  const { successStudentUpdate } = useSelector((state) => state.studentUpdate)
  const { successStudentDelete } = useSelector((state) => state.studentDelete)
  const { thatsIt, totalStudents, students } = useSelector((state) => state.studentsByGroup)

  useEffect(() => {
    if (userInfo) {
      if (groupId !== null) {
        if (successStudentDelete || successStudentUpdate || successStudentRegister) {
          if (successStudentDelete) {
            dispatch({ type: STUDENT_DELETE_RESET })
          } else if (successStudentRegister) {
            dispatch({ type: STUDENT_REGISTER_RESET })
          } else {
            dispatch({ type: STUDENT_UPDATE_RESET })
          }
          setStudentArray([])
          setStart(1)
          setInitialHasMore(true)
          setHasMore(true)
          return true
        }
        if (keyword !== undefined) {
          if (keyword !== matchKeyword) {
            setStudentArray([])
            setInitialHasMore(true)
            setMatchKeyword(keyword)
            setStart(1)
          } else {
            if (initalHasMore) {
              setInitialHasMore(false)
              dispatch(getStudentsByGroup(keyword, count, 0, groupId))
            } else if (students && students.length > 0) {
              setStudentArray((prev) => [...prev, ...students])
            }
          }
        } else {
          if (initalHasMore) {
            dispatch(getStudentsByGroup(keyword, count, 0, groupId))
            setInitialHasMore(false)
          } else if (students && students.length > 0) {
            setStudentArray((prev) => [...prev, ...students])
          }
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [
    userInfo,
    history,
    dispatch,
    keyword,
    count,
    students,
    groupId,
    initalHasMore,
    matchKeyword,
    successStudentRegister,
    successStudentDelete,
    successStudentUpdate,
  ])
  useEffect(() => {
    if (totalStudents || totalStudents === 0) {
      setStudentsQty(totalStudents)
    }
  }, [totalStudents, setStudentsQty])
  useEffect(() => {
    if (thatsIt || thatsIt === undefined) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt])
  useEffect(() => {
    if (viewGroup) {
      setStudentArray([])
      setInitialHasMore(true)
      setMatchKeyword(keyword)
      setStart(1)
      setGroupData(viewGroup)
      setGroupId(viewGroup._id)
    }
  }, [viewGroup, keyword])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getStudentsByGroup(keyword, count, start, groupId))
    }
  }
  const dragOver = (e) => {
    e.preventDefault()
  }
  const dragEnter = (e) => {
    e.preventDefault()
    e.target.className += ' fillover'
  }
  const onDragLeave = (e) => {
    e.target.classList.remove('fillover')
  }
  const onDrop = (e) => {
    setStudentArray([])
    setInitialHasMore(true)
    setMatchKeyword(keyword)
    setStart(1)
    e.target.classList.remove('fillover')
    const groupInfo = JSON.parse(e.dataTransfer.getData('text'))
    setGroupData(groupInfo)
    setGroupId(groupInfo._id)
    setGroupInfo(groupInfo)
  }
  const addStudentHandler = () => {
    setOpenAddStudent(true)
  }

  return (
    <>
      <div
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        className={classes.teacherDropArea}
      >
        Drag and drop here the group you want to work with or click on group's setting icon.
      </div>
      <InfiniteScroll
        dataLength={studentArray.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<Loader />}
        height={'60vh'}
        className={classes.infiniteScroll}
        endMessage={
          thatsIt === undefined ? null : (
            <p className={classes.lastLine}>
              <b>This is the last line.</b>
            </p>
          )
        }
      >
        {studentArray.map((student) => (
          <StudentListCard key={student._id} student={student} groupId={groupId} />
        ))}
      </InfiniteScroll>
      {groupData !== null && (
        <>
          <div className={classes.listActions}>
            <Button color='info' block onClick={addStudentHandler}>
              Add Student to {groupData.name}
            </Button>
          </div>
          <CustomModal title='Add Student to Group' open={openAddStudent} setOpen={setOpenAddStudent} actions={false}>
            <StudentRegisterFormModal open={openAddStudent} setOpen={setOpenAddStudent} groupId={groupId} />
          </CustomModal>
        </>
      )}
    </>
  )
}

export default StudentList

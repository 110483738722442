import { useEffect, useState } from 'react'
import { Card, CardHeader, Grid } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import CustomModal from '../../../../components/Modal/CustomModal'
import Button from '../../../../components/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTeacherGroup } from '../../../../redux/actions/groupActions'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '1px solid grey',
    borderRadius: '5px',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '20px',
  },
}))

const GroupListCard = ({ group, teacher }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [openDeleteGroup, setOpenDeleteGroup] = useState(false)

  const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)

  useEffect(() => {
    if (successGroupTeacherDelete) {
      setOpenDeleteGroup(false)
    }
  }, [successGroupTeacherDelete])

  const deleteTeacherGroupHandler = () => {
    dispatch(deleteTeacherGroup(group._id, teacher._id))
  }

  return (
    <div className='group-card'>
      <Card className={classes.root}>
        <CardHeader
          action={
            <CustomDropdown
              left
              caret={false}
              buttonText={
                <div className={classes.btnwrapper}>
                  <MoreVert />
                </div>
              }
              buttonProps={{
                className: classes.navLink + ' ' + classes.imageDropdownButton,
                color: 'transparent',
              }}
              dropdownList={[<div onClick={() => setOpenDeleteGroup(true)}>Leave a group</div>]}
            />
          }
          title={group.name}
        />
      </Card>
      <CustomModal title='Leave Group?' open={openDeleteGroup} setOpen={setOpenDeleteGroup} actions={false}>
        <Grid container>
          <Grid item xs={12}>
            <p>
              Are you sure to leave the group <b>{group.name}</b>.?
            </p>
            <p>If you decide to leave the group, you will lose all the actions you had with the group.</p>
          </Grid>
          <Grid item xs={12}>
            <Button color='primary' block onClick={deleteTeacherGroupHandler}>
              Leave Group
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  )
}

export default GroupListCard

import { useState, useMemo } from 'react'
import { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import classnames from 'classnames'
import { matchSorter } from 'match-sorter'
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core'
import GridItem from '../Grid/GridItem'
import GridContainer from '../Grid/GridContainer'
import CustomInput from '../CustomInput/CustomInput'
import styles from '../../assets/jss/customSelectStyle'

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: '3px 0 !important',
  },
  gridContainer: {
    justifyContent: 'center',
  },
}

const useStyles = makeStyles(newStyles)

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || '',
        onChange: (e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`,
      }}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = (val) => !val

const ReactTable = ({ columns, data }) => {
  const classes = useStyles()

  const [numberOfRows, setNumberOfRows] = useState(10)
  const [pageSelect, handlePageSelect] = useState(0)
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // state,
    // visibleColumns,
    nextPage,
    pageOptions,
    // pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  )

  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map((item) => item)
  let numberOfRowsData = [5, 10, 20, 25, 50, 100]

  return (
    <>
      <div className='ReactTable -striped -highlight'>
        <div className='pagination-top'>
          <div className='-pagination'>
            <div className='-previous'>
              <button type='button' onClick={() => previousPage()} disabled={!canPreviousPage} className='-btn'>
                Prev
              </button>
            </div>
            <div className='-center'>
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl + ' ' + classes.formControlMargins}>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={pageSelect}
                      onChange={(event) => {
                        gotoPage(event.target.value)
                        handlePageSelect(event.target.value)
                      }}
                      inputProps={{
                        name: 'pageSelect',
                        id: 'page-select',
                      }}
                    >
                      {pageSelectData.map((prop, key) => {
                        return (
                          <MenuItem
                            key={key}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={key}
                          >
                            Page {key + 1}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl + ' ' + classes.formControlMargins}>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={numberOfRows}
                      onChange={(event) => {
                        setPageSize(event.target.value)
                        setNumberOfRows(event.target.value)
                      }}
                      inputProps={{
                        name: 'numberOfRows',
                        id: 'number-of-rows',
                      }}
                    >
                      {numberOfRowsData.map((prop) => {
                        return (
                          <MenuItem
                            key={prop}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={prop}
                          >
                            {prop} lines
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>
            <div className='-next'>
              <button type='button' onClick={() => nextPage()} disabled={!canNextPage} className='-btn'>
                Next
              </button>
            </div>
          </div>
        </div>
        <table {...getTableProps()} className='rt-table'>
          <thead className='rt-thead -header'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='rt-tr'>
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames('rt-th rt-resizable-header', {
                      '-cursor-pointer': headerGroup.headers.length - 1 !== key,
                      '-sort-asc': column.isSorted && !column.isSortedDesc,
                      '-sort-desc': column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div className='rt-resizable-header-content'>{column.render('Header')}</div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render('Filter')
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className='rt-tbody'>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className='rt-td'>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='pagination-bottom'></div>
      </div>
    </>
  )
}
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

export default ReactTable

const styles = {
  formControl: {
    marginTop: '11px',
    marginBottom: '11px',
    minWidth: 120,
    width: '100%',
    textAlign: 'left',
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: 'unset',
  },
  selectInput: {
    marginTop: '6px !important',
    '&:before': {
      borderBottom: '1px solid rgb(210 210 210)',
    },
  },
  submitButton: {
    marginTop: '6px !important',
  },
}

export default styles

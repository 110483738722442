import Meta from '../../../components/Meta/Meta'

const CalendarScreen = () => {
    return (
        <>
            <Meta title={'Calendar - Planet Fraternity'} />
            <h1>Calendar</h1>
        </>
    )
}

export default CalendarScreen

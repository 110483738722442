import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Grid,
	FormControlLabel,
	FormControl,
	Paper,
	Switch,
	Select,
	InputLabel,
	MenuItem,
	makeStyles,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	IconButton,
	TableBody,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import DeleteIcon from '@material-ui/icons/Delete'
import months from '../../../../assets/data/months'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import GroupInfoFormModal from './GroupInfoModal'
import { registerCenter } from '../../../../redux/actions/centerActions'
import { getCountriesList } from '../../../../redux/actions/countryActions'
import { getPricePerCountryId } from '../../../../redux/actions/pricePerCountryActions'
import { CENTER_REGISTER_RESET } from '../../../../redux/constants/centerConstants'
import styles from '../styles/centerCardFormStyles'

const useStyles = makeStyles(styles)

const UserRegisterFormModal = ({ setOpen }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [address, setAddress] = useState('')
	const [principalName, setPrincipalName] = useState('')
	const [principalEmail, setPrincipalEmail] = useState('')
	const [principalPhone, setPrincipalPhone] = useState('')
	// const [plan, setPlan] = useState('')
	const [schoolYearStart, setSchoolYearStart] = useState('')
	const [schoolYearFinish, setSchoolYearFinish] = useState('')
	const [teachersQty, setTeachersQty] = useState('')
	const [studentsQty, setStudentsQty] = useState('')
	const [licenseExp, setLicenseExp] = useState(null)
	const [countryId, setCountryId] = useState('')
	const [countryName, setCountryName] = useState('')
	const [countryFlag, setCountryFlag] = useState('')
	const [isCatholic, setIsCatholic] = useState(false)
	const [isForTest, setIsForTest] = useState(false)
	const [isInternal, setIsInternal] = useState(false)
	const [openAddGroupModal, setOpenAddGroupModal] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [groupsInfo, setGroupInfo] = useState([])
	const [countryPrice, setCountryPrice] = useState(0)
	const [totalDonation, setTotalDonation] = useState(0)

	const { loadingCenterRegister, errorCenterRegister, successCenterRegister } = useSelector(
		(state) => state.centerRegister,
	)
	const { loadingCountryList, errorCountryList, countries } = useSelector((state) => state.countryList)
	const { successPricePerCountryDetails, pricePerCountry } = useSelector((state) => state.pricePerCountryDetails)

	useEffect(() => {
		if (successCenterRegister) {
			setOpen(false)
			setName('')
			setEmail('')
			setPhone('')
			setAddress('')
			setPrincipalName('')
			setPrincipalEmail('')
			setPrincipalPhone('')
			// setPlan('')
			setSchoolYearStart('')
			setSchoolYearFinish('')
			setTeachersQty('')
			setStudentsQty('')
			setLicenseExp(null)
			setCountryId('')
			setCountryName('')
			setCountryFlag('')
			setIsCatholic(false)
			setIsForTest(false)
			setIsInternal(false)
			setTotalDonation(0)
			dispatch({ type: CENTER_REGISTER_RESET })
		} else if (countries?.length < 1) {
			dispatch(getCountriesList())
		}
	}, [dispatch, successCenterRegister, setOpen, countries])

	useEffect(() => {
		if (successPricePerCountryDetails) {
			if (pricePerCountry) {
				setCountryPrice(pricePerCountry?.price)
			} else {
				// For missing pricesPerCountry temp
				setCountryPrice(3)
			}
		}
	}, [successPricePerCountryDetails, pricePerCountry])

	const removeGroupHandler = (index) => {
		setGroupInfo(groupsInfo.filter((group) => groupsInfo.indexOf(group) !== index))
	}

	const submitDonateHandler = (e) => {
		e.preventDefault()
		let totalStudents = 0
		groupsInfo.forEach((group) => {
			totalStudents = totalStudents + parseInt(group.noOfStudents)
		})
		let totalDonationSuggested = totalStudents * countryPrice
		setTotalDonation(totalDonationSuggested)
		setOpenConfirmModal(true)
	}

	const submitHandler = () => {
		dispatch(
			registerCenter({
				name,
				email,
				phone,
				address,
				schoolPrincipal: {
					name: principalName,
					email: principalEmail,
					phone: principalPhone,
				},
				schoolYear: {
					start: schoolYearStart,
					finish: schoolYearFinish,
				},
				plan: 'basic',
				license: {
					teachersQty,
					studentsQty,
					expiration: licenseExp,
				},
				isCatholic,
				country: {
					_id: countryId,
					name: countryName,
					flag: countryFlag,
				},
				groupsInfo,
				isForTest,
				isActive: false,
				isInternal,
				totalDonation,
			}),
		)
		setOpenConfirmModal(false)
	}

	const countryHandler = (id) => {
		setCountryId(id)
		const thisCountry = countries.find((country) => country._id === id)
		setCountryName(thisCountry.name)
		setCountryFlag(thisCountry.flag)
		dispatch(getPricePerCountryId(thisCountry._id))
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<form onSubmit={submitDonateHandler}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='Center Name'
								id='centername'
								formControlProps={{
									fullWidth: true,
									className: 'm-0',
								}}
								inputProps={{
									type: 'text',
									value: name,
									placeholder: 'Enter center name',
									onChange: (e) => setName(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='Email'
								id='centeremail'
								formControlProps={{
									fullWidth: true,
									className: 'm-0',
								}}
								inputProps={{
									type: 'email',
									value: email,
									placeholder: 'Enter a valid email',
									onChange: (e) => setEmail(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='Phone'
								id='centerphone'
								formControlProps={{
									fullWidth: true,
									className: 'm-0',
								}}
								inputProps={{
									type: 'text',
									value: phone,
									placeholder: 'E.g. +34 000 000000',
									onChange: (e) => setPhone(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='Address'
								id='centeraddress'
								formControlProps={{
									fullWidth: true,
									className: 'm-0',
								}}
								inputProps={{
									type: 'text',
									value: address,
									placeholder: 'Enter address',
									onChange: (e) => setAddress(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						{/* <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='planid' className={classes.selectLabel}>
                                    Plan
                                </InputLabel>
                                <Select labelId='plan' id='planid' value={plan} onChange={(e) => setPlan(e.target.value)} inputProps={{ required: true }}>
                                    <MenuItem value='' disabled>
                                        Select a Plan
                                    </MenuItem>
                                    <MenuItem value='basic'>Basic</MenuItem>
                                    <MenuItem value='pro'>Pro</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
						<Grid item xs={12} md={6}>
							<FormControl className={classes.formControl}>
								<InputLabel id='monthstart' className={classes.selectLabel}>
									School Year Start Month
								</InputLabel>
								<Select
									labelId='monthstart'
									id='centerstart'
									value={schoolYearStart}
									onChange={(e) => setSchoolYearStart(e.target.value)}
									inputProps={{ required: true }}
									className={classes.selectInput}
								>
									<MenuItem value='' disabled></MenuItem>
									{months.map((month) => (
										<MenuItem key={month._id} value={month.name}>
											{month.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormControl className={classes.formControl}>
								<InputLabel id='monthfinish' className={classes.selectLabel}>
									School Year Finish Month
								</InputLabel>
								<Select
									labelId='monthfinish'
									id='centerfinish'
									value={schoolYearFinish}
									onChange={(e) => setSchoolYearFinish(e.target.value)}
									inputProps={{ required: true }}
									className={classes.selectInput}
								>
									<MenuItem value='' disabled></MenuItem>
									{months.map((month) => (
										<MenuItem key={month._id} value={month.name}>
											{month.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<CustomInput
								labelText='Teachers Qty'
								id='teachers-qty'
								formControlProps={{
									fullWidth: true,
									className: 'm-0',
								}}
								inputProps={{
									type: 'number',
									value: teachersQty,
									placeholder: 'License teachers qty',
									onChange: (e) => setTeachersQty(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<CustomInput
								labelText='Students Qty'
								id='students-qty'
								formControlProps={{
									fullWidth: true,
									className: 'm-0',
								}}
								inputProps={{
									type: 'number',
									value: studentsQty,
									placeholder: 'License students qty',
									onChange: (e) => setStudentsQty(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker
								label='License Expiration'
								id='license-exp'
								value={licenseExp}
								onChange={setLicenseExp}
								format='dd/MM/yyyy'
								className={classes.textField}
								minDate={new Date()}
								InputLabelProps={{
									shrink: true,
									className: classes.selectLabel,
								}}
								InputProps={{
									classes: {
										input: classes.resize,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							{loadingCountryList ? (
								<Loader />
							) : errorCountryList ? (
								<Message variant='danger'>{errorCountryList}</Message>
							) : (
								<FormControl className={classes.formControl}>
									<InputLabel id='country' className={classes.selectLabel}>
										Country
									</InputLabel>
									<Select
										labelId='country'
										id='centercountry'
										value={countryId}
										onChange={(e) => countryHandler(e.target.value)}
										inputProps={{ required: true }}
									>
										<MenuItem value='' disabled>
											Select a Country
										</MenuItem>
										{!loadingCountryList &&
											countries.map((country) => (
												<MenuItem key={country._id} value={country._id}>
													{country.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						</Grid>

						<Grid item md={4} xs={12}>
							<CustomInput
								labelText='Principal Name'
								id='principal-name'
								formControlProps={{
									fullWidth: true,
									className: classes.formControlItem,
								}}
								inputProps={{
									type: 'text',
									value: principalName,
									placeholder: 'Complete principal name',
									onChange: (e) => setPrincipalName(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<CustomInput
								labelText='Principal Email'
								id='principal-email'
								formControlProps={{
									fullWidth: true,
									className: classes.formControlItem,
								}}
								inputProps={{
									type: 'email',
									value: principalEmail,
									placeholder: 'Enter valid Email',
									onChange: (e) => setPrincipalEmail(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<CustomInput
								labelText='Principal Phone'
								id='principal-phone'
								formControlProps={{
									fullWidth: true,
									className: classes.formControlItem,
								}}
								inputProps={{
									type: 'text',
									value: principalPhone,
									placeholder: 'E.g. +34 000 000000',
									onChange: (e) => setPrincipalPhone(e.target.value),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<FormControlLabel
								control={
									<Switch
										checked={isForTest}
										onChange={(e) => setIsForTest(e.target.checked)}
										name='isForTest'
										color='primary'
									/>
								}
								label='Is For Test'
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<FormControlLabel
								control={
									<Switch
										checked={isCatholic}
										onChange={(e) => setIsCatholic(e.target.checked)}
										name='isCatholic'
										color='secondary'
									/>
								}
								label='Is Catholic'
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<FormControlLabel
								control={
									<Switch
										checked={isInternal}
										onChange={(e) => setIsInternal(e.target.checked)}
										name='isInternal'
										color='secondary'
									/>
								}
								label='Is Internal'
							/>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={4}>
								<Button
									type='button'
									className={classes.btnMarginTop}
									color='warning'
									block
									onClick={() => setOpenAddGroupModal(true)}
								>
									+ ADD GROUP
								</Button>
							</Grid>
							<Grid item xs={12} className={classes.groupsInfo}>
								{groupsInfo.length > 0 ? (
									<>
										<TableContainer component={Paper}>
											<Table size='small' aria-label='a dense table'>
												<TableHead className={classes.tableBackground}>
													<TableRow>
														<TableCell>Grade Name</TableCell>
														<TableCell>No. of students</TableCell>
														<TableCell>Ages Range</TableCell>
														<TableCell>Teacher Name</TableCell>
														<TableCell>Teacher Email</TableCell>
														<TableCell>Teacher Phone</TableCell>
														<TableCell></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{groupsInfo.map((group, index) => (
														<TableRow key={index}>
															<TableCell component='th' scope='row'>
																{group.groupName}
															</TableCell>
															<TableCell>{group.noOfStudents}</TableCell>
															<TableCell>{group.agesRange}</TableCell>
															<TableCell>{group.teacherName}</TableCell>
															<TableCell>{group.teacherEmail}</TableCell>
															<TableCell>{group.teacherPhone}</TableCell>
															<TableCell>
																<IconButton aria-label='delete' onClick={() => removeGroupHandler(index)}>
																	<DeleteIcon fontSize='small' />
																</IconButton>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</>
								) : (
									<h2>Add one group</h2>
								)}
							</Grid>
							{errorCenterRegister && (
								<Grid item xs={12}>
									<Message message={<span>{errorCenterRegister}</span>} color='info' />
								</Grid>
							)}
						</Grid>
						{errorCenterRegister && (
							<Grid item xs={12}>
								<Message message={<span>{errorCenterRegister}</span>} color='info' />
							</Grid>
						)}
						<Grid item xs={12}>
							<Button type='submit' color='primary' block disabled={groupsInfo.length < 1}>
								{loadingCenterRegister ? <Loader size={'sm'} /> : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
			<CustomModal title='Register Group' open={openAddGroupModal} setOpen={setOpenAddGroupModal} actions={false}>
				<GroupInfoFormModal setOpenModal={setOpenAddGroupModal} setGroupInfo={setGroupInfo} groupsInfo={groupsInfo} />
			</CustomModal>
			<CustomModal
				title='Suggested Donation'
				acceptText='Ok'
				open={openConfirmModal}
				setOpen={setOpenConfirmModal}
				acceptHandler={submitHandler}
			>
				<Grid item xs={12}>
					<h3>Suggested amount of donation will be {totalDonation}€</h3>
				</Grid>
			</CustomModal>
		</Grid>
	)
}

export default UserRegisterFormModal

import * as types from '../constants/informationConstants'

export const informationRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.INFORMATION_REGISTER_REQUEST:
      return { loadingInformationRegister: true }
    case types.INFORMATION_REGISTER_SUCCESS:
      return { loadingInformationRegister: false, successInformationRegister: true }
    case types.INFORMATION_REGISTER_FAIL:
      return { loadingInformationRegister: false, errorInformationRegister: action.payload }
    case types.INFORMATION_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const informationListReducer = (state = {}, action) => {
  switch (action.type) {
    case types.INFORMATION_LIST_REQUEST:
      return { loadingInformationList: true }
    case types.INFORMATION_LIST_SUCCESS:
      return { loadingInformationList: false, successInformationList: true, resources: action.payload }
    case types.INFORMATION_LIST_FAIL:
      return { loadingInformationList: false, errorInformationList: action.payload }
    case types.INFORMATION_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const informationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.INFORMATION_DELETE_REQUEST:
      return { loadingInformationDelete: true }
    case types.INFORMATION_DELETE_SUCCESS:
      return { loadingInformationDelete: false, successInformationDelete: true }
    case types.INFORMATION_DELETE_FAIL:
      return { loadingInformationDelete: false, errorInformationDelete: action.payload }
    case types.INFORMATION_DELETE_RESET:
      return {}
    default:
      return state
  }
}

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControlLabel, Switch, makeStyles } from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerUser } from '../../../../redux/actions/userActions'
import { getTeachersByCenter } from '../../../../redux/actions/teacherActions'
import { USER_REGISTER_RESET } from '../../../../redux/constants/userConstants'
import styles from '../styles/teacherRegisterFormStyles'

const useStyles = makeStyles(styles)

const TeacherRegisterForm = ({ center }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [name, setName] = useState('')
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true)
	const [confirmationSent, setConfirmationSent] = useState(false)
	const [confirmationError, setConfirmationError] = useState(false)

	const teachersByCenter = useSelector((state) => state.teachersByCenter)
	const { teachers } = teachersByCenter
	const userRegister = useSelector((state) => state.userRegister)
	const { loadingUserRegister, errorUserRegister, successUserRegister, user } = userRegister

	useEffect(() => {
		if (user && user.mailSent) {
			setConfirmationSent(true)
		}
		if (user && user.mailSent === false) {
			setConfirmationError(true)
		}
	}, [user])

	const submitFormHandler = (e) => {
		e.preventDefault()
		const createUser = {
			name,
			email,
			password: '12345678',
			center: center._id,
			country: center.country,
			sendEmailConfirmation,
		}
		dispatch(registerUser(createUser))
	}

	const createdSuccessHandler = () => {
		dispatch(getTeachersByCenter(center._id))
		dispatch({ type: USER_REGISTER_RESET })
		setName('')
		setUsername('')
		setEmail('')
		setConfirmationSent(false)
		setConfirmationError(false)
	}

	return (
		<Grid container>
			{teachers && teachers.length >= center.license.teachersQty ? (
				<Grid item xs={12} className={classes.textCenter}>
					<h2>You have reached the maximum amount of teachers of this school license.</h2>
					<p>Consider contact the center to upgrade the license.</p>
				</Grid>
			) : successUserRegister ? (
				<>
					<Grid item xs={12} className={classes.textCenter}>
						<h2>¡Great!</h2>
						<p>User created successfully.</p>
						{confirmationSent && (
							<p>
								Confirmation email sent to <strong>{email}</strong>
							</p>
						)}
						{confirmationError && (
							<p>
								User created success but the system couldn't sent the email confirmation.
								<strong>{email}</strong>
							</p>
						)}
					</Grid>
					<Grid item xs={12}>
						<Button color='primary' block onClick={createdSuccessHandler}>
							OK
						</Button>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<h2 className={classes.formTitle}>New Teacher</h2>
					<form onSubmit={submitFormHandler} autoComplete='off'>
						<CustomInput
							labelText='Name'
							id='newname'
							formControlProps={{
								fullWidth: true,
								className: classes.formControl,
							}}
							inputProps={{
								type: 'text',
								value: name,
								placeholder: 'Enter complete name',
								onChange: (e) => setName(e.target.value),
								required: true,
							}}
						/>
						<CustomInput
							labelText='Email'
							id='newemail'
							formControlProps={{
								fullWidth: true,
								className: classes.formControl,
							}}
							inputProps={{
								type: 'email',
								value: email,
								placeholder: 'Enter a valid email',
								onChange: (e) => setEmail(e.target.value),
								required: true,
							}}
						/>
						<Grid container>
							<Grid item xs={12}>
								<p className={classes.defPassMsg}>The default password is going to be 1 through 8</p>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormControlLabel
									control={
										<Switch
											checked={sendEmailConfirmation}
											onChange={(e) => setSendEmailConfirmation(e.target.checked)}
											name='sendEmailConfirmation'
											color='primary'
										/>
									}
									className='mb-5'
									label='Send Email Confirmation'
								/>
							</Grid>
							<Grid item xs={12}>
								<Button type='submit' color='primary' block>
									{loadingUserRegister ? <Loader size={'sm'} /> : 'Submit'}
								</Button>
							</Grid>
						</Grid>
						{errorUserRegister && <Message>{errorUserRegister}</Message>}
					</form>
				</Grid>
			)}
		</Grid>
	)
}

export default TeacherRegisterForm

const styles = {
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    selectLabel: {
        color: '#AAAAAA !important',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.42857',
        letterSpacing: 'unset',
    },
    textField: {
        marginTop: '15px',
        width: '100%',
    },
    resize: {
        fontSize: 14,
    },
}

export default styles

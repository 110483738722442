const styles = {
	shadowEffect: {
		background: '#fff',
		padding: '30px',
		borderRadius: '4px',
		textAlign: 'start',
		border: '1px solid #ececec',
		boxShadow: '0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02)',
		position: 'relative',
		display: 'inline-block',
		'& p': {
			fontSize: '19px',
			lineHeight: '1.5',
			margin: '0 0 17px 0',
			fontWeight: 300,
		},
	},
	infoQuote: {
		position: 'absolute',
		top: '-10px',
		left: '-5px',
		opacity: '0.15',

		'& svg': {
			fontSize: '5rem',
		},
	},
	sliderRoot: {
		minHeight: '50vh',
		padding: 30,
		'& h4': {
			marginTop: '45px',
			textAlign: 'center',
			fontSize: '25px',
			color: '#1f7a2e',
		},
	},
	customerTestimonoals: {
		marginTop: '4%',
	},
	testimonialName: {
		margin: '-17px auto 0',
		display: 'table',
		width: 'auto',
		background: 'linear-gradient(100deg, #1f7a2e, #1f5a2e)',
		padding: '9px 35px',
		borderRadius: '12px',
		textAlign: 'center',
		color: '#fff',
		fontSize: '24px',
		boxShadow: '0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5)',
		position: 'relative',
		'& h5': {
			marginBottom: '10px',
			color: '#fff',
		},
	},
	testimonialItem: {
		textAlign: 'center',
		paddingBottom: '14px',
		marginBottom: '0px',
		opacity: '0.2',
		transform: 'scale3d(0.8, 0.8, 1)',
		transition: 'all 0.3s ease-in-out',
	},
	testimonialSection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	mainImgSection: {
		width: '40px',
	},
	countryFlag: {
		objectFit: 'cover',
		border: '1px solid white',
		borderRadius: '5px',
		height: '30px',
		margin: '0px 10px',
	},
}

export default styles

import Button from '../../../../components/Buttons/Button'

const TeamsManageButton = ({ teamgroups, setOpenAutomateGroup, setOpenCreateGroup }) => {
  return (
    <>
      {teamgroups && teamgroups.length === 0 ? (
        <Button color='primary' onClick={() => setOpenAutomateGroup(true)}>
          + Automate groups
        </Button>
      ) : (
        <Button color='primary' onClick={() => setOpenCreateGroup(true)}>
          + Create group
        </Button>
      )}
    </>
  )
}

export default TeamsManageButton

import { main, mainRaised } from '../../../../assets/jss/material-ui-styles'

const styles = {
  main,
  mainRaised,
  cardBodyRoot: {
    width: '100%',
    marginTop: '10px',
    '& h4': {
      marginBottom: 0,
    },
    '& hr': {
      width: '100%',
    },
  },
  mainSection: {
    width: '100%',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& hr': {
      width: '100%',
      marginTop: '5px',
    },
    '& svg': {
      fontSize: '36px',
      color: '#65676b',
    },
    '& h3': {
      margin: 'auto 0',
      fontSize: '18px',
    },
  },
  notificationRoot: {
    display: 'flex',
    padding: '5px 0px',
    marginBottom: '5px',
    '& button': {
      paddingLeft: 10,
      textAlign: 'start',
    },
  },
  '@media screen and (max-width: 980px)': {
    testimonialRoot: {
      width: '100%',
    },
    cardBodyRoot: {
      '& h4': {
        width: '100%',
      },
    },
  },
}

export default styles

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { Grid, Fab, makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import Button from '../../../../../components/Buttons/Button'
import Loader from '../../../../../components/Loader/Loader'
import Message from '../../../../../components/Message/Message'
import CustomInput from '../../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../../components/Modal/CustomModal'
import { dataURItoBlob } from '../../../../../utils/getDataUriToBlob'
import { deleteTopicPanel, topicPanelUpdateInfo } from '../../../../../redux/actions/topicActions'
import { TOPIC_UPDATE_RESET } from '../../../../../redux/constants/topicConstants'
import styles from '../../styles/topicCardFormStyles'

const useStyles = makeStyles(styles)

const TopicCardForm = ({ topic, setTopicDetails }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [order, setOrder] = useState('')
	const [name, setName] = useState('')
	const [imageUrl, setImageUrl] = useState(null)
	const [openDelete, setOpenDelete] = useState(false)

	const { loadingTopicUpdate, errorTopicUpdate, successTopicUpdate } = useSelector((state) => state.topicUpdate)
	const { loadingTopicDelete, errorTopicDelete } = useSelector((state) => state.topicDelete)

	useEffect(() => {
		dispatch({ type: TOPIC_UPDATE_RESET })
	}, [dispatch, successTopicUpdate])

	useEffect(() => {
		setName(topic.name)
		setOrder(topic.order)
		setImageUrl(topic.image)
	}, [topic])

	const submitEditFormHandler = (e) => {
		e.preventDefault()
		const topicSubmit = {
			_id: topic._id,
			name,
			order,
			imageUrl,
		}

		dispatch(topicPanelUpdateInfo(topicSubmit))
	}

	const deleteHandler = () => {
		dispatch(deleteTopicPanel(topic._id))
		setTopicDetails('')
		setOpenDelete(false)
	}

	const handleImageChange = (e) => {
		const coverImgFile = e.target.files[0]
		if (coverImgFile) {
			setImageUrl(null)
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const reader = new FileReader()
			const img = new Image()
			img.onload = () => {
				let maxWidth = 450
				let maxHeight = Math.round((img.height *= maxWidth / img.width))
				ctx.canvas.width = maxWidth
				ctx.canvas.height = maxHeight
				ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
				const imgData = canvas.toDataURL('image/jpeg', 0.7)
				const imgBlob = dataURItoBlob(imgData)
				const outputfile = new File([imgBlob], coverImgFile.name, {
					type: 'image/jpeg',
				})
				setImageUrl(outputfile)
				const previewImg = document.querySelector('.preview-magazine-cover img')
				previewImg.src = imgData
			}
			reader.onloadend = () => {
				img.src = reader.result
			}
			reader.readAsDataURL(coverImgFile)
		}
	}

	return (
		<>
			<form onSubmit={submitEditFormHandler} autoComplete='off'>
				<CustomInput
					labelText={errorTopicUpdate ? 'Topic name already exists.' : 'Name'}
					formControlProps={{
						fullWidth: true,
						className: classes.formControl,
					}}
					error={errorTopicUpdate === 'Topic name already exists.'}
					inputProps={{
						name: 'name',
						type: 'text',
						value: name,
						placeholder: 'Enter topic name',
						onChange: (e) => setName(e.target.value),
						required: true,
					}}
				/>
				<CustomInput
					labelText={errorTopicUpdate ? 'Topic order already exists.' : 'Order'}
					error={errorTopicUpdate === 'Topic order already exists.'}
					formControlProps={{
						fullWidth: true,
						className: classes.formControl,
					}}
					inputProps={{
						name: 'order',
						type: 'number',
						value: order,
						placeholder: 'Enter topic order',
						onChange: (e) => {
							setOrder(e.target.value)
						},
						required: true,
					}}
				/>

				<Grid item xs={12} sm={12}>
					{topic && (
						<div className='preview-magazine-cover'>
							<img src={`${axios.defaults.baseURL}${topic.image}`} alt='Topic' className={classes.imgSize} />
						</div>
					)}
					{!topic.image && (
						<Grid item xs={12} md={12} sm={12} className={classes.btnResourceStart}>
							<p>Add New Image</p>
							<label htmlFor='image-input'>
								<input
									type='file'
									name='imageInput'
									capture='camera'
									hidden
									id='image-input'
									accept='.png, .jpg, .jpeg'
									onChange={(e) => handleImageChange(e)}
								/>
								<Fab color='primary' size='small' component='span' aria-label='add'>
									<Add />
								</Fab>
							</label>
						</Grid>
					)}
				</Grid>

				<Grid container spacing={2} className={classes.root}>
					<Grid item xs={8} md={9}>
						<Button
							type='submit'
							block
							color={successTopicUpdate ? 'success' : 'primary'}
							disabled={successTopicUpdate && true}
						>
							{loadingTopicUpdate ? (
								<Loader size={'sm'} />
							) : successTopicUpdate ? (
								'Updated Successfully'
							) : (
								'Update topic'
							)}
						</Button>
					</Grid>
					<Grid item xs={4} md={3}>
						<Button color='danger' block onClick={() => setOpenDelete(true)}>
							{loadingTopicDelete ? <Loader size={'sm'} /> : 'Delete'}
						</Button>
					</Grid>
				</Grid>
				{errorTopicDelete && <Message message={<span>{errorTopicDelete}</span>} color='info' />}
			</form>
			<CustomModal
				title='Delete topic?'
				open={openDelete}
				setOpen={setOpenDelete}
				acceptText='Delete'
				acceptHandler={deleteHandler}
			>
				Are you sure you want to delete this topic?
			</CustomModal>
		</>
	)
}
export default TopicCardForm

import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'

const styles = {
  contentItemsEnd,
  infiniteScroll,
  root: {
    padding: 30,
    '& h1': {
      padding: 0,
      margin: 0,
    },
  },
  btnResetSearch: {
    marginRight: '15px',
  },

  contentLine: {
    textAlign: 'center',
  },
}

export default styles

import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import TeamGroupCard from '../../../../components/Teams/TeamGroupCard/TeamGroupCard'
import styles from '../styles/teamsManageRightGroupAreaStyles'

const useStyles = makeStyles(styles)

const TeamsManageRightGroupArea = () => {
  const classes = useStyles()

  const { loadingTeamGroupList, errorTeamGroupList, teamgroups } = useSelector((state) => state.teamGroupList)

  return (
    <Card>
      <CardBody>
        <h2>Groups</h2>
        {teamgroups && teamgroups.length > 0 && <p>Drag and drop a student in the colored area.</p>}
        <div className={classes.groupCardsArea}>
          {loadingTeamGroupList ? (
            <Loader />
          ) : errorTeamGroupList ? (
            <Message message={<span>{errorTeamGroupList}</span>} />
          ) : teamgroups && teamgroups.length > 0 ? (
            teamgroups.map((group) => <TeamGroupCard key={group._id} group={group} droppable />)
          ) : (
            <div className={classes.emptyMsg}>
              <p>There are no groups yet</p>
              <p>Please automate the creation of the suggested groups quantity.</p>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default TeamsManageRightGroupArea

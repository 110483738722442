import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import classNames from 'classnames'
import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import { Edit, Flag, Person } from '@material-ui/icons'
import Loader from '../../../../components/Loader/Loader'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CardBody from '../../../../components/Card/CardBody'
import Card from '../../../../components/Card/Card'
import CustomTabs from '../../../../components/CustomTabs/CustomTabs'
import UserCardForm from './UserCardForm'
import CountryPost from './CountryPost'
import { getCenterById } from '../../../../redux/actions/centerActions'
import styles from '../styles/userCardstyles'
import { findCountryPostByUser } from '../../../../redux/actions/countryPostsActions'

const useStyles = makeStyles(styles)

const UserCard = ({ user }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingCenterDetails, center } = useSelector((state) => state.centerDetails)
	const { posted } = useSelector((s) => s.countryPostVerifyPosted)

	useEffect(() => {
		if (user.center) {
			dispatch(getCenterById(user.center))
		}
		dispatch(findCountryPostByUser(user._id))
	}, [dispatch, user])

	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={12}>
				<Card className={classNames(classes.main, classes.mainRaised, classes.cardRoot)}>
					<CardAvatar profile>
						<img src={`${axios.defaults.baseURL}${user.avatar}`} alt={user.name} />
					</CardAvatar>
					<CardBody profile className={classes.cardBodyRoot}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<h1 className={classes.cardTitle}>{user.name}</h1>
							</Grid>
							<Grid item xs={12}>
								<CustomTabs
									headerColor='primary'
									className={classes.customTabsRoot}
									tabs={[
										{
											tabName: 'Profile',
											tabIcon: Person,
											tabContent: (
												<Grid
													container
													spacing={3}
													style={{
														paddingTop: '10px',
													}}
												>
													<Grid item xs={12} sm={6}>
														<div className={classes.userInfoSection}>
															<h5>Basic Info.</h5>
															<div>
																<span>Google Email · </span>
																<span>{user.googleMail}</span>
															</div>
															<div>
																<span>Role · </span>
																<span className={classes.subtitleCapital}>{user.role}</span>
															</div>
															<div>
																<span>Registered since · </span>
																<span>{format(new Date(user.createdAt), 'MMMM yyyy')}</span>
															</div>
															<div>
																<span>Country · </span>
																<span>{user.country.name} · </span>
																<span>
																	<img
																		src={`${axios.defaults.baseURL}${user.country.flag}`}
																		width='30px'
																		alt={user.country.name}
																		className='rounded'
																	/>
																</span>
															</div>
														</div>
													</Grid>
													<Grid item xs={12} sm={6}>
														<div className={classes.userInfoSection}>
															<h5>Center Info.</h5>
															{loadingCenterDetails ? (
																<Loader />
															) : center?.name ? (
																<>
																	<div>
																		<span>{center?.name}</span>
																	</div>
																	<div>
																		<span>Email · </span>
																		<span>{center?.email ? center?.email : 'Not Registered'}</span>
																	</div>
																	<div>
																		<span>Address · </span>
																		<span>{center.address ? center.address : 'Not Registered'}</span>
																	</div>
																	<div>
																		<span>Country · </span>
																		<span>{center.country.name} · </span>
																		<span>
																			<img
																				src={`${axios.defaults.baseURL}${center.country.flag}`}
																				width='30px'
																				alt={center.country.name}
																				className='rounded'
																			/>
																		</span>
																	</div>
																</>
															) : (
																'Not registered in a Center'
															)}
														</div>
													</Grid>
												</Grid>
											),
										},
										{
											tabName: 'Edit Profile',
											tabIcon: Edit,
											tabContent: <UserCardForm user={user} />,
										},
										userInfo.role === 'student' && !posted && !userInfo.isDefaultName
											? {
													tabName: 'Post About your country',
													tabIcon: Flag,
													tabContent: <CountryPost user={user} />,
											  }
											: undefined,
									]}
								/>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	)
}

export default UserCard

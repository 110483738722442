import * as types from '../constants/studentConstants'

export const studentRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.STUDENT_REGISTER_REQUEST:
      return { loadingStudentRegister: true }
    case types.STUDENT_REGISTER_SUCCESS:
      return {
        loadingStudentRegister: false,
        successStudentRegister: true,
        student: action.payload,
      }
    case types.STUDENT_REGISTER_FAIL:
      return {
        loadingStudentRegister: false,
        errorStudentRegister: action.payload,
      }
    case types.STUDENT_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const studentRegisterMultipleReducer = (state = {}, action) => {
  switch (action.type) {
    case types.STUDENTS_REGISTER_MULTIPLE_REQUEST:
      return { loadingRegisterMultiple: true }
    case types.STUDENTS_REGISTER_MULTIPLE_SUCCESS:
      return {
        loadingRegisterMultiple: false,
        successRegisterMultiple: true,
        data: action.payload,
      }
    case types.STUDENTS_REGISTER_MULTIPLE_FAIL:
      return {
        loadingRegisterMultiple: false,
        errorRegisterMultiple: action.payload,
      }
    case types.STUDENTS_REGISTER_MULTIPLE_RESET:
      return {}
    default:
      return state
  }
}

export const studentListReducer = (state = { students: [] }, action) => {
  switch (action.type) {
    case types.STUDENT_LIST_REQUEST:
      return { loadingStudentList: true }
    case types.STUDENT_LIST_SUCCESS:
      return {
        loadingStudentList: false,
        userLength: action.payload.userLength,
        thatsIt: action.payload.students.length === 0 ? true : false,
        students: action.payload.students,
      }
    case types.STUDENT_LIST_FAIL:
      return {
        loadingStudentList: false,
        errorStudentList: action.payload,
      }
    case types.STUDENT_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const studentsByCenterReducer = (state = { students: [] }, action) => {
  switch (action.type) {
    case types.STUDENTS_BY_CENTER_REQUEST:
      return { loadingStudentsByCenter: true }
    case types.STUDENTS_BY_CENTER_SUCCESS:
      return {
        loadingStudentsByCenter: false,
        studentsByCenterQty: action.payload.userLength,
        thatsIt: action.payload.users.length === 0 ? true : false,
        students: action.payload,
      }
    case types.STUDENTS_BY_CENTER_FAIL:
      return {
        loadingStudentsByCenter: false,
        errorStudentsByCenter: action.payload,
      }
    case types.STUDENTS_BY_CENTER_RESET:
      return {}
    default:
      return state
  }
}

export const studentsByGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case types.STUDENT_BY_GROUP_REQUEST:
      return { loadingStudentsByGroup: true }
    case types.STUDENT_BY_GROUP_SUCCESS:
      return {
        loadingStudentsByGroup: false,
        thatsIt: action.payload.students.length === 0 ? true : false,
        totalStudents: action.payload.userLength,
        students: action.payload.students,
      }
    case types.STUDENT_BY_GROUP_FAIL:
      return {
        loadingStudentsByGroup: false,
        errorStudentsByGroup: action.payload,
      }
    case types.STUDENT_BY_GROUP_RESET:
      return {}
    default:
      return state
  }
}

export const studentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.STUDENT_UPDATE_REQUEST:
      return { loadingStudentUpdate: true }
    case types.STUDENT_UPDATE_SUCCESS:
      return { loadingStudentUpdate: false, successStudentUpdate: true }
    case types.STUDENT_UPDATE_FAIL:
      return {
        loadingStudentUpdate: false,
        errorStudentUpdate: action.payload,
      }
    case types.STUDENT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const studentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.STUDENT_DELETE_REQUEST:
      return { loadingStudentDelete: true }
    case types.STUDENT_DELETE_SUCCESS:
      return { loadingStudentDelete: false, successStudentDelete: true }
    case types.STUDENT_DELETE_FAIL:
      return {
        loadingStudentDelete: false,
        errorStudentDelete: action.payload,
      }
    case types.STUDENT_DELETE_RESET:
      return {}
    default:
      return state
  }
}

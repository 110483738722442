import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles/cardAvatarStyle'

const useStyles = makeStyles(styles)

const CardAvatar = ({ children, className, plain, center, profile, ...rest }) => {
    const classes = useStyles()

    const cardAvatarClasses = classNames({
        [classes.cardAvatar]: true,
        [classes.cardAvatarProfile]: profile,
        [classes.cardAvatarCenter]: center,
        [classes.cardAvatarPlain]: plain,
        [className]: className !== undefined,
    })
    return (
        <div className={cardAvatarClasses} {...rest}>
            {children}
        </div>
    )
}

CardAvatar.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    profile: PropTypes.bool,
    plain: PropTypes.bool,
}

export default CardAvatar

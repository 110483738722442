const styles = {
	formControl: {
		marginTop: '11px',
		minWidth: 120,
		width: '100%',
		textAlign: 'left',
	},
	inputItem: {
		margin: 0,
		paddingTop: '12px',
		'& input': {
			padding: '22px 0 0',
		},
		'& .MuiSelect-root': {
			padding: '5px 0',
		},
	},
	selectLabel: {
		color: '#AAAAAA !important',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '1.42857',
		letterSpacing: 'unset',
		marginButton: '10px',
	},
	textField: {
		marginTop: '15px',
		width: '100%',
	},
	resize: {
		fontSize: 14,
	},
	deletePhrase: {
		backgroundColor: '#e4e1e1',
		width: 'fit-content',
		padding: '3px 5px',
		borderRadius: '5px',
	},
	tableHeadBackground: {
		backgroundColor: '#ececec',
	},
	selectInput: {
		marginTop: '7px',
	},
	categorySection: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	btnSection: {
		marginTop: '20px',
	},
	btnResource: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		'& p': {
			fontSize: '0.75rem',
			marginRight: '1rem',
			color: '#aaaaaa',
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontWeight: 600,
		},
	},
	closeIcon: {
		padding: 5,
		marginTop: '30px',
	},
	fileBadge: {
		margin: '20px 0',
		width: '100%',
	},
	fileDiv: {
		textAlign: 'center',
		backgroundColor: '#3f51b5',
		color: '#fff',
		borderRadius: '5px',
		padding: '5px',
		width: '100%',
	},
	textHelper: {
		cursor: 'pointer',
		textDecoration: 'none',
	},
	areaField: {
		width: '100%',
	},
}

export default styles

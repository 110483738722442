import * as types from '../constants/teamGroupConstants'

export const teamGroupRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_REGISTER_REQUEST:
			return { loadingTeamGroupRegister: true }
		case types.TEAM_GROUP_REGISTER_SUCCESS:
			return {
				loadingTeamGroupRegister: false,
				successTeamGroupRegister: true,
				teamgroup: action.payload,
			}
		case types.TEAM_GROUP_REGISTER_FAIL:
			return {
				loadingTeamGroupRegister: false,
				errorTeamGroupRegister: action.payload,
			}
		case types.TEAM_GROUP_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const teamGroupAutoRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_AUTOREGISTER_REQUEST:
			return { loadingTeamGroupAutoRegister: true }
		case types.TEAM_GROUP_AUTOREGISTER_SUCCESS:
			return {
				loadingTeamGroupAutoRegister: false,
				successTeamGroupAutoRegister: true,
			}
		case types.TEAM_GROUP_AUTOREGISTER_FAIL:
			return {
				loadingTeamGroupAutoRegister: false,
				errorTeamGroupAutoRegister: action.payload,
			}
		case types.TEAM_GROUP_AUTOREGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const teamGroupListReducer = (state = { teamgroups: [] }, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_LIST_REQUEST:
			return { loadingTeamGroupList: true }
		case types.TEAM_GROUP_LIST_SUCCESS:
			return {
				loadingTeamGroupList: false,
				teamgroups: action.payload,
			}
		case types.TEAM_GROUP_LIST_FAIL:
			return {
				loadingTeamGroupList: false,
				errorTeamGroupList: action.payload,
			}
		case types.TEAM_GROUP_LIST_RESET:
			return { teamgroups: [] }
		default:
			return state
	}
}

export const teamGroupDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_DETAILS_REQUEST:
			return { loadingTeamGroupDetails: true }
		case types.TEAM_GROUP_DETAILS_SUCCESS:
			return {
				loadingTeamGroupDetails: false,
				teamgroup: action.payload,
			}
		case types.TEAM_GROUP_DETAILS_FAIL:
			return {
				loadingTeamGroupDetails: false,
				errorTeamGroupDetails: action.payload,
			}
		case types.TEAM_GROUP_DETAILS_RESET:
			return {}
		default:
			return state
	}
}

export const teamGroupMyDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_MY_DETAILS_REQUEST:
			return { loadingTeamGroupMyDetails: true }
		case types.TEAM_GROUP_MY_DETAILS_SUCCESS:
			return {
				loadingTeamGroupMyDetails: false,
				myteamgroup: action.payload,
			}
		case types.TEAM_GROUP_MY_DETAILS_FAIL:
			return {
				loadingTeamGroupMyDetails: false,
				errorTeamGroupMyDetails: action.payload,
			}
		case types.TEAM_GROUP_MY_DETAILS_RESET:
			return {}
		default:
			return state
	}
}

export const teamGroupUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_UPDATE_REQUEST:
			return { loadingTeamGroupUpdate: true }
		case types.TEAM_GROUP_UPDATE_SUCCESS:
			return {
				loadingTeamGroupUpdate: false,
				successTeamGroupUpdate: true,
				teamGroupUpdated: action.payload,
			}
		case types.TEAM_GROUP_UPDATE_FAIL:
			return {
				loadingTeamGroupUpdate: false,
				errorTeamGroupUpdate: action.payload,
			}
		case types.TEAM_GROUP_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const teamGroupDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_DELETE_REQUEST:
			return { loadingTeamGroupDelete: true }
		case types.TEAM_GROUP_DELETE_SUCCESS:
			return {
				loadingTeamGroupDelete: false,
				successTeamGroupDelete: true,
			}
		case types.TEAM_GROUP_DELETE_FAIL:
			return {
				loadingTeamGroupDelete: false,
				errorTeamGroupDelete: action.payload,
			}
		case types.TEAM_GROUP_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const teamsByAgeReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_BYAGE_REQUEST:
			return { loadingTeamsByAge: true }
		case types.TEAM_GROUP_BYAGE_SUCCESS:
			return {
				loadingTeamsByAge: false,
				teams: action.payload,
			}
		case types.TEAM_GROUP_BYAGE_FAIL:
			return {
				loadingTeamsByAge: false,
				errorTeamsByAge: action.payload,
			}
		case types.TEAM_GROUP_BYAGE_RESET:
			return {}
		default:
			return state
	}
}

export const teamsByAgeNinTopicReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_BYAGE_NIN_TOPIC_REQUEST:
			return { loadingTeamsByAgeNinTopic: true }
		case types.TEAM_BYAGE_NIN_TOPIC_SUCCESS:
			return {
				loadingTeamsByAgeNinTopic: false,
				teams: action.payload,
			}
		case types.TEAM_BYAGE_NIN_TOPIC_FAIL:
			return {
				loadingTeamsByAgeNinTopic: false,
				errorTeamsByAgeNinTopic: action.payload,
			}
		case types.TEAM_BYAGE_NIN_TOPIC_RESET:
			return {}
		default:
			return state
	}
}

export const teamsByAgeStudentsLittleMergeReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_BYAGE_STUDENTS_LITTLE_REQUEST:
			return { loadingTeamsByAgeLittleMerge: true }
		case types.TEAM_BYAGE_STUDENTS_LITTLE_SUCCESS:
			return {
				loadingTeamsByAgeLittleMerge: false,
				teams: action.payload,
			}
		case types.TEAM_BYAGE_STUDENTS_LITTLE_FAIL:
			return {
				loadingTeamsByAgeLittleMerge: false,
				errorTeamsByAgeLittleMerge: action.payload,
			}
		case types.TEAM_BYAGE_STUDENTS_LITTLE_RESET:
			return {}
		default:
			return state
	}
}

export const teamsByAgePrevReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_BYAGE_PREV_REQUEST:
			return { loadingTeamsByAgePrev: true }
		case types.TEAM_GROUP_BYAGE_PREV_SUCCESS:
			return {
				loadingTeamsByAgePrev: false,
				teamsPrev: action.payload,
			}
		case types.TEAM_GROUP_BYAGE_PREV_FAIL:
			return {
				loadingTeamsByAgePrev: false,
				errorTeamsByAgePrev: action.payload,
			}
		case types.TEAM_GROUP_BYAGE_PREV_RESET:
			return {}
		default:
			return state
	}
}

export const teamsActuallyReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_ACTUALLY_REQUEST:
			return { loadingTeamsActually: true }
		case types.TEAM_GROUP_ACTUALLY_SUCCESS:
			return {
				loadingTeamsActually: false,
				teamActually: action.payload,
			}
		case types.TEAM_GROUP_ACTUALLY_FAIL:
			return {
				loadingTeamsActually: false,
				errorTeamsActually: action.payload,
			}
		case types.TEAM_GROUP_BYAGE_PREV_RESET:
			return {}
		default:
			return state
	}
}

export const teamsGroupsAutomationReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TEAM_GROUP_AUTOMATE_REQUEST:
			return { loadingTeamsGroupsAutomation: true }
		case types.TEAM_GROUP_AUTOMATE_SUCCESS:
			return {
				loadingTeamsGroupsAutomation: false,
				successTeamsGroupsAutomation: true,
				teamGroups: action.payload,
			}
		case types.TEAM_GROUP_AUTOMATE_FAIL:
			return {
				loadingTeamsGroupsAutomation: false,
				errorTeamsGroupsAutomation: action.payload,
			}
		case types.TEAM_GROUP_AUTOMATE_RESET:
			return {}
		default:
			return state
	}
}

const styles = {
  formControl: {
    marginTop: '11px',
    minWidth: 120,
    width: '100%',
    textAlign: 'left',
  },
  formControlItem: {
    margin: 0,
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  textField: {
    marginTop: '15px',
    width: '100%',
  },
  resize: {
    fontSize: 14,
  },
  deletePhrase: {
    backgroundColor: '#e4e1e1',
    width: 'fit-content',
    padding: '3px 5px',
    borderRadius: '5px',
  },
  topicInfo: {
    marginTop: '10px',
    textAlign: 'center',
    '& h4': {
      textAlign: 'center',
    },
    '& h5': {
      color: '#79a94d',
    },
    '& img': {
      width: '100px',
      borderRadius: '6px',
    },
  },
}

export default styles

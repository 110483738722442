import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	makeStyles,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Button from '../../Buttons/Button'
import Loader from '../../Loader/Loader'
import Message from '../../Message/Message'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import CustomModal from '../../Modal/CustomModal'
import CustomInput from '../../CustomInput/CustomInput'
import GroupEditModal from '../../Centers/GroupEditModal/GroupEditModal'
import GroupDeleteModal from '../../Centers/GroupDeleteModal/GroupDeleteModal'
import { registerMultipleStudents } from '../../../redux/actions/studentActions'
import { upadteGroupInfo, getGroupsByCenter, deleteTeacherGroup } from '../../../redux/actions/groupActions'
import { GROUP_UPDATE_RESET } from '../../../redux/constants/groupConstants'
import { STUDENTS_REGISTER_MULTIPLE_RESET } from '../../../redux/constants/studentConstants'
import styles from './styles/groupListCardStyles'

const useStyles = makeStyles(styles)

const GroupListCard = ({ group, center, teachers, setGroupId }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [isTeacher, setIsTeacher] = useState(false)
	const [noMoreStudents, setNoMoreStudents] = useState(false)
	const [remainingLicenses, setRemainingLicenses] = useState(null)
	const [openAutomate, setOpenAutomate] = useState(false)
	const [automateBtnDisabled, setAutomateBtnDisabled] = useState(false)
	const [openAssign, setOpenAssign] = useState(false)
	const [openListToAssign, setOpenListToAssign] = useState(false)
	const [openDeleteTeacher, setOpenDeleteTeacher] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [teacherInfo, setTeacherInfo] = useState('')
	const [studentsQuantity, setStudentsQuantity] = useState('')
	const [radioValue, setRadioValue] = useState('')
	const [radioDeleteValue, setRadioDeleteValue] = useState('')
	const [availableTeachers, setAvailableTeachers] = useState([])

	const studentsByCenter = useSelector((state) => state.studentsByCenter)
	const { studentsByCenterQty } = studentsByCenter

	const groupUpdate = useSelector((state) => state.groupUpdate)
	const { loadingGroupUpdate, errorGroupUpdate, successGroupUpdate } = groupUpdate
	const studentRegisterMultiple = useSelector((state) => state.studentRegisterMultiple)
	const { loadingRegisterMultiple, errorRegisterMultiple, successRegisterMultiple } = studentRegisterMultiple
	const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (studentsByCenterQty >= center.license.studentsQty) {
			setNoMoreStudents(true)
		} else {
			setRemainingLicenses(center.license.studentsQty - studentsByCenterQty)
		}
	}, [studentsByCenterQty, center])
	useEffect(() => {
		if (studentsQuantity > remainingLicenses) {
			setAutomateBtnDisabled(true)
		} else {
			setAutomateBtnDisabled(false)
		}
	}, [studentsQuantity, remainingLicenses])
	useEffect(() => {
		if (successGroupUpdate) {
			setTeacherInfo('')
			setOpenAssign(false)
		} else if (successGroupTeacherDelete) {
			setTeacherInfo('')
			setOpenDeleteTeacher(false)
		}
	}, [successGroupUpdate, successGroupTeacherDelete])

	useEffect(() => {
		if (userInfo) {
			const isTeacher = userInfo.role === 'teacher' && !(userInfo.isAdmin || userInfo.isSuper)
			setIsTeacher(isTeacher)
		}
	}, [userInfo])

	useEffect(() => {
		if (teachers) {
			const avaTeachers = teachers.filter((teacher) => {
				return !group.teachers.find((item) => {
					return item._id === teacher._id
				})
			})
			setAvailableTeachers(avaTeachers)
			if (openDeleteTeacher) {
				const avaTeachers = teachers.filter((teacher) => {
					return group.teachers.find((item) => {
						return item._id === teacher._id
					})
				})
				setAvailableTeachers(avaTeachers)
			}
		}
	}, [group, teachers, successGroupUpdate, openDeleteTeacher, successGroupTeacherDelete])

	const automateStudentsHandler = () => {
		setOpenAutomate(true)
	}
	const studentsAutomatizationHandler = (e) => {
		e.preventDefault()
		const multipleOptions = {
			centerId: center._id,
			centerName: center.name,
			centerCountry: center.country,
			groupId: group._id,
			studentsQty: studentsQuantity,
		}
		setGroupId(group._id)
		dispatch(registerMultipleStudents(multipleOptions))
	}
	const dragOver = (e) => {
		e.preventDefault()
	}
	const dragEnter = (e) => {
		e.preventDefault()
		e.target.className += ' fillover'
	}
	const onDragLeave = (e) => {
		e.target.classList.remove('fillover')
	}
	const onDrop = (e) => {
		setTeacherInfo(JSON.parse(e.dataTransfer.getData('text')))
		setOpenAssign(true)
		e.target.classList.remove('fillover')
	}
	const assignTeacherHandler = () => {
		const editGroup = {
			_id: group._id,
			teacher: {
				_id: teacherInfo._id,
				username: teacherInfo.username,
			},
		}
		setGroupId(group._id)
		dispatch(upadteGroupInfo(editGroup))
	}

	const deleteTeacherGroupHandler = () => {
		setGroupId(group._id)
		dispatch(deleteTeacherGroup(group._id, teacherInfo._id))
	}
	const errorHandler = () => {
		setOpenAssign(false)
		setOpenAutomate(false)
		setTeacherInfo('')
		dispatch({ type: GROUP_UPDATE_RESET })
		dispatch({ type: STUDENTS_REGISTER_MULTIPLE_RESET })
	}
	const successRegisterMultipleHandler = () => {
		setOpenAutomate(false)
		dispatch(getGroupsByCenter(center._id))
		dispatch({ type: STUDENTS_REGISTER_MULTIPLE_RESET })
	}
	const handleRadioChange = (e) => {
		setRadioValue(e.target.value)
		const teacherObject = teachers.filter((item) => item._id === e.target.value)[0]
		setTeacherInfo(teacherObject)
	}

	const handleDeleteRadioChange = (e) => {
		setRadioDeleteValue(e.target.value)
		const teacherObject = teachers.filter((item) => item._id === e.target.value)[0]
		setTeacherInfo(teacherObject)
	}

	return (
		<div className='group-card'>
			<Card className={classes.root}>
				<CardHeader
					action={
						<CustomDropdown
							left
							caret={false}
							buttonText={
								<div className={classes.btnwrapper}>
									<MoreVert />
								</div>
							}
							buttonProps={{
								className: classes.navLink + ' ' + classes.imageDropdownButton,
								color: 'transparent',
							}}
							dropdownList={[
								group && group.students.length === 0 && !isTeacher && (
									<div onClick={() => automateStudentsHandler()}>Automate student creation</div>
								),
								availableTeachers.length > 0 && <div onClick={() => setOpenListToAssign(true)}>Assign teacher</div>,
								<div onClick={() => setOpenEdit(true)}>Edit Group Info</div>,
								{ divider: true },
								group.teachers.length > 0 && (
									<div onClick={() => setOpenDeleteTeacher(true)}>Remove teacher from group</div>
								),
								<div onClick={() => setOpenDelete(true)}>Delete</div>,
							]}
						/>
					}
					titleTypographyProps={{ variant: 'h6' }}
					subheaderTypographyProps={{ variant: 'caption' }}
					title={group.name}
					subheader={
						<div className={classes.subtitle}>
							{`Age Range - ${group.ageRange}`}
							{group.isLittleCategory && <small className={classes.badgeNew}>Little PlanetFraternity</small>}
						</div>
					}
				/>

				<CardContent className={classes.contentRoot}>
					<Grid container>
						<Grid item xs={6}>
							<p>Students: {group.students.length}</p>
						</Grid>
						<Grid item xs={6}>
							<p
								className={classes.teacherDropArea}
								onDragOver={(e) => dragOver(e)}
								onDragEnter={(e) => dragEnter(e)}
								onDrop={(e) => onDrop(e)}
								onDragLeave={(e) => onDragLeave(e)}
							>
								Teachers: {group.teachers.length}
							</p>
						</Grid>
						{group.teachers.length === 0 && (
							<Grid item xs={12}>
								<h5 className={classes.noTeacherAssigned}>No teachers assigned</h5>
								<p
									className={classes.teacherDropArea}
									onDragOver={(e) => dragOver(e)}
									onDragEnter={(e) => dragEnter(e)}
									onDrop={(e) => onDrop(e)}
									onDragLeave={(e) => onDragLeave(e)}
								>
									Drag the main teacher and Drop it here.
								</p>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
			<CustomModal title='Automate Students' open={openAutomate} setOpen={setOpenAutomate} actions={false}>
				<Grid container spacing={4}>
					{errorRegisterMultiple ? (
						<>
							<Grid item xs={12}>
								<span>{errorRegisterMultiple}</span>
							</Grid>
							<Grid item xs={12}>
								<Button type='submit' color='danger' block onClick={errorHandler}>
									OK
								</Button>
							</Grid>
						</>
					) : successRegisterMultiple ? (
						<>
							<Grid item xs={12} className='text-center'>
								<h4>¡Great!</h4>
								<p>Automation ended successfully.</p>
								<p>Now click "OK" to finish.</p>
							</Grid>
							<Grid item xs={12}>
								<Button type='submit' color='primary' block onClick={successRegisterMultipleHandler}>
									OK
								</Button>
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							{noMoreStudents ? (
								<>
									<p>No more students allowed for this center license.</p>
									<p>
										If you need more students consider to contact the system administrator to upgrade your license plan.
									</p>
									<Button block color='danger' onClick={errorHandler}>
										OK
									</Button>
								</>
							) : (
								<form onSubmit={studentsAutomatizationHandler} autoComplete='off'>
									<h6>Remaining Licenses: {remainingLicenses}</h6>
									<p>
										Please type the quantity of students that will belong to group <b>{group.name}</b>.
									</p>
									<p>
										The system will create all the accounts with a standard password 1 through 6, then the teacher could
										edit each one in Student List section.
									</p>
									<CustomInput
										labelText={
											automateBtnDisabled ? `Only ${remainingLicenses} remaining licenses.` : 'Students Quantity'
										}
										id={'studentquantity' + group._id}
										className={classes.contentInput}
										error={automateBtnDisabled}
										formControlProps={{
											fullWidth: true,
											className: classes.formControl,
										}}
										inputProps={{
											type: 'text',
											value: studentsQuantity,
											placeholder: 'Number of students you need in this group',
											onChange: (e) => setStudentsQuantity(e.target.value),
											required: true,
										}}
									/>
									<Button type='submit' color='primary' block disabled={automateBtnDisabled}>
										{loadingRegisterMultiple ? <Loader size='sm' /> : 'Start'}
									</Button>
								</form>
							)}
						</Grid>
					)}
				</Grid>
			</CustomModal>
			<CustomModal title='Assign Teacher' open={openAssign} setOpen={setOpenAssign} actions={false}>
				<Grid container spacing={4}>
					{errorGroupUpdate ? (
						<>
							<Grid item xs={12}>
								<Message message={<span>{errorGroupUpdate}</span>} color='info' />
							</Grid>
							<Grid item xs={12}>
								<Button color='primary' block onClick={errorHandler}>
									OK
								</Button>
							</Grid>
						</>
					) : (
						<>
							<Grid item xs={12}>
								<p>
									The teacher <b>{teacherInfo.name}</b> will be assigned to the group <b>{group.name}</b>.
								</p>
								<p>
									If this is the <b>first</b> teacher assigned to the group this will become the main teacher
								</p>
							</Grid>
							<Grid item xs={12}>
								<Button color='primary' block onClick={assignTeacherHandler}>
									{loadingGroupUpdate ? <Loader size='sm' /> : 'Assign Teacher'}
								</Button>
							</Grid>
						</>
					)}
				</Grid>
			</CustomModal>
			<CustomModal title='Delete Teacher?' open={openDeleteTeacher} setOpen={setOpenDeleteTeacher} actions={false}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<p>
							Are you sure you want to remove the teacher from the group <b>{group.name}</b>.?
						</p>
						<p>If you decide to remove the teacher from the group, all related actions will be lost.</p>
					</Grid>
					<Grid item xs={12}>
						<FormControl component='fieldset'>
							<RadioGroup
								aria-label='delete teacher'
								name='teacher2'
								value={radioDeleteValue}
								onChange={handleDeleteRadioChange}
							>
								{availableTeachers &&
									availableTeachers.map((teacher) => (
										<FormControlLabel key={teacher._id} value={teacher._id} control={<Radio />} label={teacher.name} />
									))}
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button
							color='primary'
							disabled={radioDeleteValue.length > 0 ? false : true}
							block
							onClick={deleteTeacherGroupHandler}
						>
							Delete teacher
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
			<CustomModal
				title='Choose a Teacher to assign'
				open={openListToAssign}
				setOpen={setOpenListToAssign}
				actions={false}
			>
				<Grid container>
					<Grid item xs={12}>
						{availableTeachers && availableTeachers.length === 1 ? (
							<p>
								This teacher does not manage the group <b>{group.name}</b> yet.
							</p>
						) : (
							<p>
								These teachers don't manage group <b>{group.name}</b> yet.
							</p>
						)}
						<p>
							If the teacher you choose is the <b>first</b> teacher assigned to the group this will become the main
							teacher.
						</p>
					</Grid>
					<Grid item xs={12}>
						<FormControl component='fieldset'>
							<RadioGroup aria-label='teacher' name='teacher1' value={radioValue} onChange={handleRadioChange}>
								{availableTeachers &&
									availableTeachers.map((teacher) => (
										<FormControlLabel key={teacher._id} value={teacher._id} control={<Radio />} label={teacher.name} />
									))}
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Button color='primary' block onClick={assignTeacherHandler}>
							Assign Teacher
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
			<CustomModal title='Edit Group' open={openEdit} setOpen={setOpenEdit} actions={false}>
				<GroupEditModal setOpenEdit={setOpenEdit} group={group} />
			</CustomModal>
			<CustomModal title='Delete Group?' open={openDelete} setOpen={setOpenDelete} actions={false}>
				<GroupDeleteModal setOpenDelete={setOpenDelete} group={group} setGroupId={setGroupId} />
			</CustomModal>
		</div>
	)
}

export default GroupListCard

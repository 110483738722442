import axios from 'axios'
import { Card, Grid, IconButton, makeStyles } from '@material-ui/core'
import { LocationCity, Group, Delete } from '@material-ui/icons'
import styles from '../styles/centerCardStyles'

const useStyles = makeStyles(styles)

const CenterCard = ({ group, deleteCenterFromTeam }) => {
  const classes = useStyles()

  const dragStart = (e) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(group))
  }

  return (
    <Card className={classes.groupCard} draggable={true} onDragStart={dragStart}>
      <Grid container>
        <Grid item xs={12}>
          <h5>
            <LocationCity color='primary' /> {group.name}
          </h5>
        </Grid>
        <Grid item xs={12}>
          <p>
            <img
              src={`${axios.defaults.baseURL}${group.country.flag}`}
              className={classes.countryFlagProfile}
              alt={'Flag of ' + group.country.name}
            />{' '}
            {group.country.name}
          </p>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <p>
            <Group color='secondary' /> Students qty: {group.qty}
          </p>
          <IconButton aria-label='delete' className={classes.margin} onClick={() => deleteCenterFromTeam(group)}>
            <Delete fontSize='small' color='error' />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CenterCard

import React from 'react'
import axios from 'axios'
import { MoreVert } from '@material-ui/icons'
import { Card, CardHeader, makeStyles } from '@material-ui/core'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/countryPostsCardStyles'

const useStyles = makeStyles(styles)

const CountryPostCards = ({ countryPost, setSelectedPost }) => {
	const classes = useStyles()

	return (
		<Card className={classes.root}>
			<div className={classes.imagePreview}>
				{countryPost.images.length > 0 && (
					<img
						src={`${axios.defaults.baseURL}/${countryPost.images[0].url ?? ''}`}
						className={classes.img}
						alt={'country-post-preview'}
					/>
				)}
			</div>
			<CardHeader
				className={classes.cardHeaderRoot}
				action={
					<CustomDropdown
						left
						caret={false}
						buttonText={
							<div className={classes.btnwrapper}>
								<MoreVert />
							</div>
						}
						buttonProps={{
							className: classes.navLink + ' ' + classes.imageDropdownButton,
							color: 'transparent',
						}}
						dropdownList={[<div onClick={() => setSelectedPost(countryPost)}>Manage</div>]}
					/>
				}
				title={
					<p className={classes.title}>
						{countryPost.title?.length > 20 ? countryPost.title.slice(0, 20) + '...' : countryPost.title}
					</p>
				}
				subheader={
					<p className={classes.desc}>
						{countryPost.body?.length > 21 ? countryPost.body.slice(0, 21) + '...' : countryPost.body}
					</p>
				}
			/>
		</Card>
	)
}

export default CountryPostCards

import axios from 'axios'
import { Card, makeStyles } from '@material-ui/core'
import { LocationCity, Group } from '@material-ui/icons'
import styles from '../styles/centerGroupListCardStyles.js'

const useStyles = makeStyles(styles)

const CenterGroupListCard = ({ center }) => {
  const classes = useStyles()

  const dragStart = (e) => {
    e.target.classList.add('fillover')
    e.dataTransfer.setData('text/plain', JSON.stringify(center))
  }
  const dragEnd = (e) => {
    e.target.classList.remove('fillover')
  }

  return (
    <Card className={classes.root} draggable={true} onDragStart={dragStart} onDragEnd={dragEnd}>
      <h3>
        <LocationCity color='primary' /> {center.name}
      </h3>
      <p>
        <img
          src={`${axios.defaults.baseURL}${center.country?.flag}`}
          className={classes.countryFlagProfile}
          alt={'Flag of ' + center.country?.name}
        />{' '}
        {center.country?.name}
      </p>
      <p>
        <Group color='secondary' /> Students: {center.qty}
      </p>
    </Card>
  )
}

export default CenterGroupListCard

import * as types from '../constants/teacherConstants'

export const teacherListReducer = (state = { teachers: [] }, action) => {
  switch (action.type) {
    case types.TEACHERS_LIST_REQUEST:
      return { loadingTeacherList: true }
    case types.TEACHERS_LIST_SUCCESS:
      return {
        loadingTeacherList: false,
        userLength: action.payload.userLength,
        thatsIt: action.payload.teachers.length === 0 ? true : false,
        teachers: action.payload.teachers,
      }
    case types.TEACHERS_LIST_FAIL:
      return {
        loadingTeacherList: false,
        errorTeacherList: action.payload,
      }
    case types.TEACHERS_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const teachersByCenterReducer = (state = { teachers: [] }, action) => {
  switch (action.type) {
    case types.TEACHERS_BY_CENTER_REQUEST:
      return { loadingTeachersByCenter: true }
    case types.TEACHERS_BY_CENTER_SUCCESS:
      return {
        loadingTeachersByCenter: false,
        teachersByCenterQty: action.payload.length,
        teachers: action.payload,
      }
    case types.TEACHERS_BY_CENTER_FAIL:
      return {
        loadingTeachersByCenter: false,
        errorTeacherByCenter: action.payload,
      }
    case types.TEACHERS_BY_CENTER_RESET:
      return {}
    default:
      return state
  }
}

export const teachersByEmailReducer = (state = { teachers: [] }, action) => {
  switch (action.type) {
    case types.TEACHERS_BY_EMAIL_REQUEST:
      return { loadingTeachersByEmail: true }
    case types.TEACHERS_BY_EMAIL_SUCCESS:
      return {
        loadingTeachersByEmail: false,
        successTeachersByEmail: true,
        teachers: action.payload,
      }
    case types.TEACHERS_BY_EMAIL_FAIL:
      return {
        loadingTeachersByEmail: false,
        errorTeacherByEmail: action.payload,
      }
    case types.TEACHERS_BY_EMAIL_RESET:
      return {}
    default:
      return state
  }
}

export const teacherDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEACHERS_DETAILS_REQUEST:
      return { loading: true }
    case types.TEACHERS_DETAILS_SUCCESS:
      return { loading: false, teacher: action.payload }
    case types.TEACHERS_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const teacherGroupsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEACHERS_GROUPS_REQUEST:
      return { loadingTeacherGroups: true }
    case types.TEACHERS_GROUPS_SUCCESS:
      return { loadingTeacherGroups: false, groups: action.payload }
    case types.TEACHERS_GROUPS_FAIL:
      return {
        loadingTeacherGroups: false,
        errorTeacherGroups: action.payload,
      }
    case types.TEACHERS_GROUPS_RESET:
      return {}
    default:
      return state
  }
}

export const teacherAllListReducer = (state = { teachers: [] }, action) => {
  switch (action.type) {
    case types.TEACHERS_LIST_ALL_REQUEST:
      return { loadingTeacherAllList: true }
    case types.TEACHERS_LIST_ALL_SUCCESS:
      return {
        loadingTeacherAllList: false,
        teachers: action.payload,
      }
    case types.TEACHERS_LIST_ALL_FAIL:
      return {
        loadingTeacherAllList: false,
        errorTeacherAllList: action.payload,
      }
    case types.TEACHERS_LIST_ALL_RESET:
      return {}
    default:
      return state
  }
}

export const teacherDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEACHER_DELETE_REQUEST:
      return { loadingTeacherDelete: true }
    case types.TEACHER_DELETE_SUCCESS:
      return { loadingTeacherDelete: false, successTeacherDelete: true }
    case types.TEACHER_DELETE_FAIL:
      return {
        loadingTeacherDelete: false,
        errorTeacherDelete: action.payload,
      }
    case types.TEACHER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

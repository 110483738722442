const styles = {
    root: {
        height: '100%',
    },
    formTitle: {
        margin: 0,
        padding: '10px 0 0 0',
        textAlign: 'center',
    },
    formSubtitle: {
        padding: '15px',
        margin: 0,
        textAlign: 'center',
    },
    formControl: {
        marginTop: '11px',
        minWidth: 120,
        width: '100%',
        textAlign: 'left',
    },
    selectLabel: {
        color: '#AAAAAA !important',
        fontWeight: '400',
        fontSize: '14px',
        letterSpacing: 'unset',
    },
    selectInput: {
        marginTop: '7px'
    }
}

export default styles

import axios from 'axios'
import * as types from '../constants/talentsConstants'

export const registerTalent = (payload) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TALENT_CREATE_REQUEST })

		const {
			userLogin: { userInfo },
			talentList: { talents, thatsIt },
		} = getState()

		const configs = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const epPayload = {
			...payload,
			owner: userInfo._id,
		}

		const fieldsFiles = ['selectedVideo', 'videoThumbnail']

		let formData = new FormData()

		for (let key in epPayload) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, epPayload[key])
			}
		}

		if (epPayload.selectedVideo.length > 0) {
			epPayload.selectedVideo.forEach((e) => {
				formData.append('talentVideo', e)
			})
		}

		if (epPayload.videoThumbnail) formData.append('videoThumbnail', epPayload.videoThumbnail)

		const response = await axios.post('/api/talents', formData, configs)

		dispatch({
			type: types.TALENT_LIST_SUCCESS,
			payload: { data: [response.data].concat(talents), thatsIt: thatsIt ? 0 : 1 },
		})
		dispatch({ type: types.TALENT_CREATE_SUCCESS, payload: true })
		setTimeout(() => {
			dispatch({ type: types.TALENT_CREATE_RESET })
		}, 4000)

		return response
	} catch (error) {
		dispatch({
			type: types.TALENT_CREATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTalents = (count, start) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TALENT_LIST_REQUEST })

		const { talents } = getState().talentList

		const { data } = await axios.get(`/api/talents?count=${count}&start=${start}`)

		dispatch({
			type: types.TALENT_LIST_SUCCESS,
			payload: { data: (talents ?? []).concat(data), thatsIt: data.length },
		})
	} catch (error) {
		dispatch({
			type: types.TALENT_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTalentsByStatus = (status, count, start) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.statusFilterInterface(status)?.request })

		const { talents } = getState()[status ? 'talentActiveList' : 'talentInactiveList']

		const { data } = await axios.get(`/api/talents/filter?status=${status}&count=${count}&start=${start}`)

		dispatch({
			type: types.statusFilterInterface(status)?.success,
			payload: {
				data: (talents ?? []).concat(data),
				thatsIt: data.length,
			},
		})
	} catch (error) {
		dispatch({
			type: types.statusFilterInterface(status)?.fail,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getSingleTalent = (id) => async (dispatch) => {
	try {
		dispatch({ type: types.TALENT_SINGLE_REQUEST })

		const response = await axios.get(`/api/talents/${id}`)

		dispatch({
			type: types.TALENT_SINGLE_SUCCESS,
			payload: response.data,
		})

		return response.status
	} catch (error) {
		dispatch({
			type: types.TALENT_SINGLE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateTalent = (id, newData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TALENT_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
			talentList: { talents, thatsIt },
		} = getState()

		const headers = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const response = await axios.put('/api/talents/' + id, newData, headers)

		const copy = [...talents]
		copy.splice(
			copy.findIndex((d) => d._id === response?.data.talentUpdated._id),
			1,
			{ ...response?.data.talentUpdated },
		)

		dispatch({ type: types.TALENT_LIST_SUCCESS, payload: { data: copy, thatsIt: thatsIt ? 0 : 1 } })
		dispatch({
			type: types.TALENT_UPDATE_SUCCESS,
			payload: true,
		})
		setTimeout(() => {
			dispatch({
				type: types.TALENT_UPDATE_RESET,
			})
		}, 3000)

		return response
	} catch (error) {
		dispatch({
			type: types.TALENT_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const findTalentByUser = (student) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TALENT_VERIFY_POSTED_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const headers = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/talents/find-talents-by-user/' + student, headers)

		dispatch({ type: types.TALENT_VERIFY_POSTED_SUCCESS, payload: Boolean(data?._id) })

		return Boolean(data?._id)
	} catch (error) {
		dispatch({
			type: types.TALENT_VERIFY_POSTED_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		return false
	}
}

export const deleteTalent = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TALENT_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
			talentList: { talents, thatsIt },
		} = getState()

		const headers = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete('/api/talents/' + id, headers)

		const currentData = [...talents].filter((d) => d._id !== id)
		dispatch({ type: types.TALENT_LIST_SUCCESS, payload: { data: currentData, thatsIt: thatsIt ? 0 : 1 } })
		dispatch({
			type: types.TALENT_DELETE_SUCCESS,
			payload: true,
		})
		setTimeout(() => {
			dispatch({
				type: types.TALENT_DELETE_RESET,
			})
		}, 3000)
	} catch (error) {
		dispatch({
			type: types.TALENT_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, makeStyles } from '@material-ui/core'
import { Flag } from '@material-ui/icons'
import Loader from '../../../components/Loader/Loader'
import Meta from '../../../components/Meta/Meta'
import CountryPostComponent from '../../../components/CountryPostComponent/CountryPostComponent'
import CountryPostCards from './components/CountryPostsCard'
import { getCountryPosts } from '../../../redux/actions/countryPostsActions'
import styles from './styles/studentsCountryPostsScreenStyles'
import { useCallback } from 'react'

const useStyles = makeStyles(styles)

const StudentCountryPostControlPanel = () => {
	const [selectedPost, setSelectedPost] = useState(null)
	const classes = useStyles()
	const [start, setStart] = useState(0)
	const dispatch = useDispatch()
	const allCountryPosts = useSelector((d) => d.countryPostList.countryPosts ?? [])
	const [data, setData] = useState([])

	const fetchData = () => {
		if (hasMore) {
			setStart(start + 1)
			dispatch(getCountryPosts())
		}
	}

	useEffect(() => {
		if (allCountryPosts.length) {
			setData([...allCountryPosts])
		}
	}, [allCountryPosts])
	useEffect(() => {
		dispatch(getCountryPosts())
	}, [])

	const deleteObject = useCallback(
		(countryPost) => {
			const currentData = [...data].filter((d) => d._id !== countryPost)
			setData(currentData)
		},
		[data],
	)

	const updateObject = useCallback(
		(countryPost) => {
			const currentData = [...data]
			const indexOfNew = currentData.findIndex((d) => d._id === countryPost._id)
			currentData.splice(indexOfNew, 1, countryPost)
			setData(currentData)
		},
		[data],
	)

	return (
		<div className={classes.root}>
			<Meta title={'Students country posts - Planet Fraternity'} />
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<h1>Students country posts</h1>
				</Grid>
				<Grid item xs={12} md={4}>
					<InfiniteScroll
						dataLength={allCountryPosts?.all?.length ?? 0}
						next={fetchData}
						loader={<Loader />}
						height={'60vh'}
						className={classes.infiniteScroll}
						endMessage={
							<p className={classes.contentLine}>
								<b>Thats it.</b>
							</p>
						}
					>
						{data?.length > 0 && (
							<>
								{data?.map((countryPost) => (
									<CountryPostCards key={countryPost._id} countryPost={countryPost} setSelectedPost={setSelectedPost} />
								))}
							</>
						)}
					</InfiniteScroll>
				</Grid>
				<Grid item xs={12} md={8}>
					{selectedPost !== null ? (
						<CountryPostComponent
							updateSelection={setSelectedPost}
							deleteObject={deleteObject}
							updateObject={updateObject}
							previewMode
							countryPost={selectedPost}
						/>
					) : (
						<div className={classes.rightSection}>
							<Flag className={classes.schoolIcon} />
							<h2>Select student country post to preview their profile.</h2>
						</div>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default StudentCountryPostControlPanel

import { makeStyles } from '@material-ui/core'
import { Spellcheck } from '@material-ui/icons'
import styles from '../styles/writingContestRightSectionStyles'

const useStyles = makeStyles(styles)

const WritingContestRightSection = () => {
  const classes = useStyles()

  return (
    <div className={classes.rightSection}>
      <Spellcheck className={classes.magazineIcon} />
      <h2>Select writing contest to preview the info.</h2>
    </div>
  )
}

export default WritingContestRightSection

export const STUDENTS_REGISTER_MULTIPLE_REQUEST =
    'STUDENTS_REGISTER_MULTIPLE_REQUEST'
export const STUDENTS_REGISTER_MULTIPLE_SUCCESS =
    'STUDENTS_REGISTER_MULTIPLE_SUCCESS'
export const STUDENTS_REGISTER_MULTIPLE_FAIL = 'STUDENTS_REGISTER_MULTIPLE_FAIL'
export const STUDENTS_REGISTER_MULTIPLE_RESET =
    'STUDENTS_REGISTER_MULTIPLE_RESET'

export const STUDENT_REGISTER_REQUEST = 'STUDENT_REGISTER_REQUEST'
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS'
export const STUDENT_REGISTER_FAIL = 'STUDENT_REGISTER_FAIL'
export const STUDENT_REGISTER_RESET = 'STUDENT_REGISTER_RESET'

export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST'
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS'
export const STUDENT_LIST_FAIL = 'STUDENT_LIST_FAIL'
export const STUDENT_LIST_RESET = 'STUDENT_LIST_RESET'

export const STUDENTS_BY_CENTER_REQUEST = 'STUDENTS_BY_CENTER_REQUEST'
export const STUDENTS_BY_CENTER_SUCCESS = 'STUDENTS_BY_CENTER_SUCCESS'
export const STUDENTS_BY_CENTER_FAIL = 'STUDENTS_BY_CENTER_FAIL'
export const STUDENTS_BY_CENTER_RESET = 'STUDENTS_BY_CENTER_RESET'

export const STUDENT_BY_GROUP_REQUEST = 'STUDENTS_BY_GROUP_REQUEST'
export const STUDENT_BY_GROUP_SUCCESS = 'STUDENTS_BY_GROUP_SUCCESS'
export const STUDENT_BY_GROUP_FAIL = 'STUDENTS_BY_GROUP_FAIL'
export const STUDENT_BY_GROUP_RESET = 'STUDENTS_BY_GROUP_RESET'

export const STUDENT_UPDATE_REQUEST = 'STUDENTS_UPDATE_REQUEST'
export const STUDENT_UPDATE_SUCCESS = 'STUDENTS_UPDATE_SUCCESS'
export const STUDENT_UPDATE_FAIL = 'STUDENTS_UPDATE_FAIL'
export const STUDENT_UPDATE_RESET = 'STUDENTS_UPDATE_RESET'

export const STUDENT_DELETE_REQUEST = 'STUDENTS_DELETE_REQUEST'
export const STUDENT_DELETE_SUCCESS = 'STUDENTS_DELETE_SUCCESS'
export const STUDENT_DELETE_FAIL = 'STUDENTS_DELETE_FAIL'
export const STUDENT_DELETE_RESET = 'STUDENTS_DELETE_RESET'

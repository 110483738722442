const styles = {
  root: {
    marginTop: 15,
    marginBottom: 15,
  },
  imgSize: {
    width: '40%',
  },
  switchItem: {
    textAlign: 'left',
  },
  formControl: {
    marginTop: 0,
    width: '100%',
    textAlign: 'left',
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  '@media screen and (max-width: 912px)': {
    imgSize: {
      width: '100%',
    },
  },
}
export default styles

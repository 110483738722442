import { useState, useEffect, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, Badge, Fab, TextField } from '@material-ui/core'
import { Close, Add, VideoCall, InsertPhoto, FileCopy } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import { registerMiniBlog } from '../../../../redux/actions/miniBlogActions'
import styles from '../styles/blogItemRegisterFormModalStyles'

const useStyles = makeStyles(styles)

const BlogItemRegisterFormModal = ({ setOpen }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const fileInputRegister = createRef()

	const [text, setText] = useState('')
	const [title, setTitle] = useState('')
	const [imageUrl, setImageUrl] = useState('')
	const [videoUrl, setVideoUrl] = useState('')
	const [coverImage, setCoverImage] = useState([])
	const [coverVideo, setCoverVideo] = useState([])
	const [applicationFile, setApplicationFile] = useState([])

	const { loadingMiniBlogRegister, errorMiniBlogRegister, successMiniBlogRegister } = useSelector(
		(state) => state.miniBlogRegister,
	)

	useEffect(() => {
		setOpen(false)
		setImageUrl('')
		setVideoUrl('')
		setText('')
		setTitle('')
		setCoverImage('')
		setCoverVideo('')
		setApplicationFile('')
	}, [dispatch, successMiniBlogRegister, setOpen])

	const handleFileChange = (e) => {
		e.preventDefault()
		setApplicationFile([...e.target.files])
	}
	const handleFileRemove = () => {
		setApplicationFile([])
	}
	const handleImageChange = (e) => {
		e.preventDefault()

		let imageFile = e.target.files

		const imageInfo = {
			name: imageFile[0].name,
			url: URL.createObjectURL(imageFile[0]),
		}
		setCoverImage(imageFile)
		setImageUrl(imageInfo)
	}
	const handleImageRemove = () => {
		setCoverImage([])
		setImageUrl('')
	}
	const handleVideoChange = (e) => {
		e.preventDefault()

		let videoFile = e.target.files

		const videoURL = {
			name: videoFile[0].name,
			url: URL.createObjectURL(videoFile[0]),
		}
		setCoverVideo(videoFile)
		setVideoUrl(videoURL)
	}
	const handleVideoRemove = () => {
		setCoverVideo([])
		setVideoUrl('')
	}
	const submitHandler = (e) => {
		e.preventDefault()

		const submitData = {
			title,
			text,
			coverImage,
			coverVideo,
			applicationFile,
		}
		dispatch(registerMiniBlog(submitData))
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<form onSubmit={submitHandler}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<CustomInput
								labelText='Title'
								id={'title' + title}
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'title',
									value: title,
									placeholder: 'Enter the title to be seen in the blog',
									onChange: (e) => setTitle(e.target.value),
								}}
							/>
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<TextField
								className={classes.areaField}
								id='outlined-textarea'
								label='Text'
								multiline
								rows={4}
								value={text}
								onChange={(e) => setText(e.target.value)}
							/>
						</Grid>
						{errorMiniBlogRegister && (
							<Grid item xs={12}>
								<Message message={<span>{errorMiniBlogRegister}</span>} color='info' />
							</Grid>
						)}
						{!imageUrl && !videoUrl && (
							<>
								<Grid item xs={12} className={classes.btnResource}>
									<p>Add Image</p>
									<label htmlFor='image-input'>
										<input
											type='file'
											name='imageInput'
											capture='camera'
											hidden
											id='image-input'
											accept='.png, .jpg, .jpeg'
											onChange={handleImageChange}
											ref={fileInputRegister}
										/>
										<Fab color='primary' size='small' component='span' aria-label='add'>
											<InsertPhoto />
										</Fab>
									</label>
								</Grid>
								<Grid item xs={12} className={classes.btnResource}>
									<p>Add Video</p>
									<label htmlFor='video-input'>
										<input
											type='file'
											name='videoInput'
											capture='camera'
											hidden
											id='video-input'
											accept='.mp4'
											onChange={handleVideoChange}
											ref={fileInputRegister}
										/>
										<Fab color='primary' size='small' component='span' aria-label='add'>
											<VideoCall />
										</Fab>
									</label>
								</Grid>
							</>
						)}
						{applicationFile.length < 1 && (
							<Grid item xs={12} className={classes.btnResource}>
								<p>Add File</p>
								<label htmlFor='fileUrl-input'>
									<input
										type='file'
										name='fileUrlInput'
										capture='camera'
										hidden
										id='fileUrl-input'
										accept='.pdf, .ppt, .pptm, .pptx, .xlsx'
										onChange={handleFileChange}
									/>
									<Fab color='primary' size='small' component='span' aria-label='add'>
										<FileCopy />
									</Fab>
								</label>
							</Grid>
						)}
						{imageUrl && (
							<Grid item xs={12} md={12}>
								<Badge
									badgeContent={
										<Button size='sm' color='danger' onClick={handleImageRemove} round justIcon>
											<Close />
										</Button>
									}
									className={classes.fileBadge}
								>
									<div className={classes.fileDiv}>
										{imageUrl.name.length > 20
											? imageUrl.name.slice(0, 20) +
											  '...' +
											  imageUrl.name.slice(imageUrl.name.length - 6, imageUrl.name.length)
											: imageUrl.name}
									</div>
								</Badge>
							</Grid>
						)}
						{videoUrl && (
							<Grid item xs={12} md={12}>
								<Badge
									badgeContent={
										<Button size='sm' color='danger' onClick={handleVideoRemove} round justIcon>
											<Close />
										</Button>
									}
									className={classes.fileBadge}
								>
									<div className={classes.fileDiv}>
										{videoUrl.name.length > 20
											? videoUrl.name.slice(0, 20) +
											  '...' +
											  videoUrl.name.slice(videoUrl.name.length - 6, videoUrl.name.length)
											: videoUrl.name}
									</div>
								</Badge>
							</Grid>
						)}
						{applicationFile[0] && (
							<Grid item xs={12} md={12}>
								<Badge
									badgeContent={
										<Button size='sm' color='danger' onClick={handleFileRemove} round justIcon>
											<Close />
										</Button>
									}
									className={classes.fileBadge}
								>
									<div className={classes.fileDiv}>
										{applicationFile[0].name.length > 20
											? applicationFile[0].name.slice(0, 20) +
											  '...' +
											  applicationFile[0].name.slice(
													applicationFile[0].name.length - 6,
													applicationFile[0].name.length,
											  )
											: applicationFile[0].name}
									</div>
								</Badge>
							</Grid>
						)}
						<Grid item xs={12}>
							<Button disabled={!title} type='submit' color='primary' block>
								{loadingMiniBlogRegister ? <Loader size={'sm'} /> : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	)
}

export default BlogItemRegisterFormModal

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Loader from '../../../components/Loader/Loader'
import Meta from '../../../components/Meta/Meta'
import CustomModal from '../../../components/Modal/CustomModal'
import ResourceRegisterForm from './components/ResourceRegisterForm'
import ResourceDeleteModal from './components/ResourceDeleteModal'
import ResourceEmptyCard from './components/ResourceEmptyCard'
import ResourceCard from './components/ResourceCard'
import ResourceButton from './components/ResourceButton'
import { getInformations, deleteInformation } from '../../../redux/actions/informationActions'
import styles from './styles/teacherInformationScreenStyles'

const useStyles = makeStyles(styles)

const TeacherInformationScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [newCategory, setNewCategory] = useState(false)
  const [resource, setResource] = useState(null)
  const [categories, setCategories] = useState([])
  const [informations, setInformations] = useState([])

  const { userInfo } = useSelector((state) => state.userLogin)
  const { successInformationRegister } = useSelector((state) => state.informationRegister)
  const { loadingInformationList, resources } = useSelector((state) => state.informationList)
  const { successInformationDelete } = useSelector((state) => state.informationDelete)

  useEffect(() => {
    dispatch(getInformations())
  }, [dispatch])

  useEffect(() => {
    if (successInformationRegister) {
      dispatch(getInformations())
    } else if (successInformationDelete) {
      dispatch(getInformations())
      setOpenDelete(false)
    }
  }, [dispatch, successInformationRegister, successInformationDelete])

  useEffect(() => {
    if (resources) {
      setInformations(resources)
    }
  }, [resources])

  useEffect(() => {
    if (resources) {
      const categories = resources.map((resource) => resource.category)
      setCategories(Array.from(new Set(categories)))
    }
  }, [resources])

  const deleteResourceHandle = (id) => {
    dispatch(deleteInformation(id))
    setOpenDelete(false)
  }

  return (
    <div className={classes.root}>
      <Meta title={'Information Resources - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <h1>Resources List by Category</h1>
        </Grid>
        {userInfo && userInfo.role === 'teacher' && (userInfo.isAdmin || userInfo.isSuper) && (
          <Grid className={classes.contentItemsEnd} item xs={12} md={12}>
            <ResourceButton setOpen={setOpen} />
          </Grid>
        )}
        {categories && categories.length > 0 ? (
          categories.map((category, index) => (
            <Grid key={index} item md={4} sm={6} xs={12}>
              <ResourceCard
                informations={informations}
                setResource={setResource}
                setOpenDelete={setOpenDelete}
                userInfo={userInfo}
                category={category}
              />
            </Grid>
          ))
        ) : loadingInformationList ? (
          <Loader />
        ) : (
          <ResourceEmptyCard />
        )}
      </Grid>
      <CustomModal
        className={classes.rootSection}
        title='Register Resource'
        open={open}
        setOpen={setOpen}
        actions={false}
      >
        <ResourceRegisterForm
          newCategory={newCategory}
          categories={categories}
          setNewCategory={setNewCategory}
          open={open}
          setOpen={setOpen}
        />
      </CustomModal>
      <CustomModal title='Delete resource?' open={openDelete} setOpen={setOpenDelete} actions={false}>
        <ResourceDeleteModal resource={resource} deleteResourceHandle={deleteResourceHandle} />
      </CustomModal>
    </div>
  )
}

export default TeacherInformationScreen

import { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { Card, CardHeader, Grid, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import CustomModal from '../../../../components/Modal/CustomModal'
import GroupDeleteModal from '../../../../components/Centers/GroupDeleteModal/GroupDeleteModal'
import GroupEditModal from '../../../../components/Centers/GroupEditModal/GroupEditModal'
import { deleteTeacherGroup } from '../../../../redux/actions/groupActions'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles/groupListCardStyles'

const useStyles = makeStyles(styles)

const GroupListCard = ({ group, setViewGroup, main, userInfo }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const history = useHistory()

  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openDeleteGroup, setOpenDeleteGroup] = useState(false)

  const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)

  useEffect(() => {
    if (successGroupTeacherDelete) {
      setOpenDeleteGroup(false)
    }
  }, [successGroupTeacherDelete])

  const dragStart = (e) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(group))
  }
  const viewGroupHandler = () => {
    setViewGroup(group)
  }
  const deleteTeacherGroupHandler = () => {
    dispatch(deleteTeacherGroup(group._id, userInfo._id))
  }

  return (
    <div className='group-card' draggable onDragStart={dragStart}>
      <Card className={classes.root}>
        <CardHeader
          action={
            <CustomDropdown
              left
              caret={false}
              buttonText={
                <div className={classes.btnwrapper}>
                  <MoreVert />
                </div>
              }
              buttonProps={{
                className: classes.navLink + ' ' + classes.imageDropdownButton,
                color: 'transparent',
              }}
              dropdownList={[
                <div onClick={viewGroupHandler}>View</div>,
                // <div onClick={() => history.push(`/classroom/${group._id}`)}>Go to Classroom</div>,
                <div onClick={() => setOpenEdit(true)}>Edit Info</div>,
                <div onClick={() => setOpenDeleteGroup(true)}>Leave a group</div>,
                <div onClick={() => setOpenDelete(true)}>Delete</div>,
              ]}
            />
          }
          title={group.name}
        />
        <CustomModal title='Edit Group' open={openEdit} setOpen={setOpenEdit} actions={false}>
          <GroupEditModal setOpenEdit={setOpenEdit} group={group} />
        </CustomModal>
        <CustomModal title='Delete Group?' open={openDelete} setOpen={setOpenDelete} actions={false}>
          <GroupDeleteModal setOpenDelete={setOpenDelete} group={group} />
        </CustomModal>
        <CustomModal title='Leave Group?' open={openDeleteGroup} setOpen={setOpenDeleteGroup} actions={false}>
          <Grid container>
            <Grid item xs={12}>
              <p>
                Are you sure to leave the group <b>{group.name}</b>.?
              </p>
              <p>If you decide to leave the group, you will lose all the actions you had with the group.</p>
            </Grid>
            <Grid item xs={12}>
              <Button color='primary' block onClick={deleteTeacherGroupHandler}>
                Leave Group
              </Button>
            </Grid>
          </Grid>
        </CustomModal>
      </Card>
    </div>
  )
}

export default GroupListCard

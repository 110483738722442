import { Grid, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core'
import { ExpandMore, SupervisorAccount } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import CenterGroupListCard from './CenterGroupListCard'
import styles from '../styles/ageAccordionCardStyles'

const useStyles = makeStyles(styles)

const AgeAccordionCard = ({ team, getTeamsPreviewHandler }) => {
  const classes = useStyles()

  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <h3>
          <SupervisorAccount color='secondary' /> Ages Range {team.ageRange}
        </h3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            {team.centers.map((center, index) => (
              <CenterGroupListCard key={index} center={center} />
            ))}
          </Grid>
          {team.centers.length < 2 && <p>Please partner this center with other ages</p>}
          <Grid item xs={12}>
            <Button color='success' block onClick={() => getTeamsPreviewHandler(team)}>
              Get Teams Preview
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default AgeAccordionCard

const StudentHeader = ({ studentsLength }) => {
  return (
    <>
      <h1>Students List</h1>
      <p>Total students {studentsLength !== null && '- ' + studentsLength}</p>
    </>
  )
}

export default StudentHeader

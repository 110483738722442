export const TEAM_REGISTER_REQUEST = 'TEAM_REGISTER_REQUEST'
export const TEAM_REGISTER_SUCCESS = 'TEAM_REGISTER_SUCCESS'
export const TEAM_REGISTER_FAIL = 'TEAM_REGISTER_FAIL'
export const TEAM_REGISTER_RESET = 'TEAM_REGISTER_RESET'

export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST'
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS'
export const TEAM_LIST_FAIL = 'TEAM_LIST_FAIL'
export const TEAM_LIST_RESET = 'TEAM_LIST_RESET'

export const TEAM_DETAILS_REQUEST = 'TEAM_DETAILS_REQUEST'
export const TEAM_DETAILS_SUCCESS = 'TEAM_DETAILS_SUCCESS'
export const TEAM_DETAILS_FAIL = 'TEAM_DETAILS_FAIL'
export const TEAM_DETAILS_RESET = 'TEAM_DETAILS_RESET'

export const TEAM_MY_DETAILS_REQUEST = 'TEAM_MY_DETAILS_REQUEST'
export const TEAM_MY_DETAILS_SUCCESS = 'TEAM_MY_DETAILS_SUCCESS'
export const TEAM_MY_DETAILS_FAIL = 'TEAM_MY_DETAILS_FAIL'
export const TEAM_MY_DETAILS_RESET = 'TEAM_MY_DETAILS_RESET'

export const TEAM_UPDATE_REQUEST = 'TEAM_UPDATE_REQUEST'
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS'
export const TEAM_UPDATE_FAIL = 'TEAM_UPDATE_FAIL'
export const TEAM_UPDATE_RESET = 'TEAM_UPDATE_RESET'

export const TEAM_PROJECT_REQUEST = 'TEAM_PROJECT_REQUEST'
export const TEAM_PROJECT_SUCCESS = 'TEAM_PROJECT_SUCCESS'
export const TEAM_PROJECT_FAIL = 'TEAM_PROJECT_FAIL'
export const TEAM_PROJECT_RESET = 'TEAM_PROJECT_RESET'

export const TEAM_PROJECT_UPDATE_REQUEST = 'TEAM_PROJECT_UPDATE_REQUEST'
export const TEAM_PROJECT_UPDATE_SUCCESS = 'TEAM_PROJECT_UPDATE_SUCCESS'
export const TEAM_PROJECT_UPDATE_FAIL = 'TEAM_PROJECT_UPDATE_FAIL'
export const TEAM_PROJECT_UPDATE_RESET = 'TEAM_PROJECT_UPDATE_RESET'

export const TEAM_PROJECT_UPDATE_WINNER_REQUEST = 'TEAM_PROJECT_UPDATE_WINNER_REQUEST'
export const TEAM_PROJECT_UPDATE_WINNER_SUCCESS = 'TEAM_PROJECT_UPDATE_WINNER_SUCCESS'
export const TEAM_PROJECT_UPDATE_WINNER_FAIL = 'TEAM_PROJECT_UPDATE_WINNER_FAIL'
export const TEAM_PROJECT_UPDATE_WINNER_RESET = 'TEAM_PROJECT_UPDATE_WINNER_RESET'

export const TEAM_PROJECT_DELETE_REQUEST = 'TEAM_PROJECT_DELETE_REQUEST'
export const TEAM_PROJECT_DELETE_SUCCESS = 'TEAM_PROJECT_DELETE_SUCCESS'
export const TEAM_PROJECT_DELETE_FAIL = 'TEAM_PROJECT_DELETE_FAIL'
export const TEAM_PROJECT_DELETE_RESET = 'TEAM_PROJECT_DELETE_RESET'

export const TEAM_PROJECT_WINNER_REQUEST = 'TEAM_PROJECT_WINNER_REQUEST'
export const TEAM_PROJECT_WINNER_SUCCESS = 'TEAM_PROJECT_WINNER_SUCCESS'
export const TEAM_PROJECT_WINNER_FAIL = 'TEAM_PROJECT_WINNER_FAIL'
export const TEAM_PROJECT_WINNER_RESET = 'TEAM_PROJECT_WINNER_RESET'

export const TEAM_DELETE_REQUEST = 'TEAM_DELETE_REQUEST'
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS'
export const TEAM_DELETE_FAIL = 'TEAM_DELETE_FAIL'
export const TEAM_DELETE_RESET = 'TEAM_DELETE_RESET'

import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import classNames from 'classnames'
import { Badge, Grid, makeStyles } from '@material-ui/core'
import { Close, FileCopy, PermMedia, VideoLibrary } from '@material-ui/icons'
import { dataURItoBlob } from '../../../../utils/getDataUriToBlob'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import { getGroupsByTeamId } from '../../../../redux/actions/teamGroupActions'
import { teamUpdateFinalProject } from '../../../../redux/actions/teamsActions'
import styles from '../styles/updateProjectModalStyles.js'

const useStyles = makeStyles(styles)
const UpdateProjectModal = ({ project, setUpdate }) => {
  const classes = useStyles()
  const fileInput = createRef()
  const dispatch = useDispatch()

  const [error, setError] = useState('')
  const [videoFile, setVideoFile] = useState(null)
  const [applicationFile, setApplicationFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [images, setImages] = useState([])
  const [imagesUrl, setImagesUrl] = useState([])

  const { teamgroups } = useSelector((state) => state.teamGroupList)
  const { successTeamProjectUpdate, errorTeamProjectUpdate, loadingTeamProjectUpdate } = useSelector(
    (state) => state.teamUpdateProject
  )

  const videoButtonClasses = classNames({
    [classes.infoLabel]: classes.infoLabel,
    [classes.infoLabel2]: videoFile && classes.infoLabel2,
  })

  const imageButtonClasses = classNames({
    [classes.infoLabel]: classes.infoLabel,
    [classes.infoLabel2]: imagesUrl.length >= 10 && classes.infoLabel2,
  })

  const fileButtonClasses = classNames({
    [classes.infoLabel]: classes.infoLabel,
    [classes.infoLabel2]: applicationFile && classes.infoLabel2,
  })

  useEffect(() => {
    if (project) {
      dispatch(getGroupsByTeamId(project.idTeam))
    }
  }, [dispatch, project])
  useEffect(() => {
    setVideoFile(project?.project?.video?.url || '')
    setApplicationFile(project?.project?.file?.url || '')
    setFileName(project?.project?.file?.url?.split('/')?.reverse()[0])
    setImagesUrl(project.project.images)
  }, [project])

  useEffect(() => {
    if (successTeamProjectUpdate) {
      setVideoFile(null)
      setImages([])
      setImagesUrl([])
      setUpdate(false)
      setError('')
    }
  }, [successTeamProjectUpdate, setUpdate])

  const handleNewVideo = (e) => {
    setVideoFile(e.target.files[0])
    setError('')
  }

  const handleFileChange = (e) => {
    setApplicationFile(e.target.files[0])
    setFileName(e.target.files[0].name)
    setError('')
  }

  const handleFileRemove = (e) => {
    setApplicationFile(null)
    setFileName('')
  }

  const selectImageHandler = () => {
    fileInput.current.click()
  }

  const handleImageChange = (e) => {
    ;[...e.target.files].map((image) => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const reader = new FileReader()
      const img = new Image()
      img.onload = () => {
        let maxWidth = 1280
        let maxHeight = Math.round((img.height *= maxWidth / img.width))
        ctx.canvas.width = img.width > maxWidth ? maxWidth : img.width
        ctx.canvas.height = img.width > maxWidth ? maxHeight : img.height
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
        const imgData = canvas.toDataURL('image/jpeg', 0.7)
        const imgBlob = dataURItoBlob(imgData)
        const outputfile = new File([imgBlob], image.name, {
          type: 'image/jpeg',
        })
        setImages((prev) => [...prev, outputfile])
      }
      reader.onloadend = () => {
        img.src = reader.result
      }
      reader.readAsDataURL(image)
      return image
    })

    const imagesURLs = [...e.target.files].map((item) => {
      return {
        name: item.name,
        url: URL.createObjectURL(item),
      }
    })
    setImagesUrl((prev) => [...prev, ...imagesURLs])
    setError('')
  }

  const handleRemoveImage = (imageFile) => {
    let newImages = images.filter((item) => item.name !== imageFile.name)
    let newImagesUrl = imagesUrl.filter((item) =>
      item.name ? item.name !== imageFile.name : item.url !== imageFile.url
    )
    setImages(newImages)
    setImagesUrl(newImagesUrl)
  }

  const handleVideoRemove = () => {
    setVideoFile(null)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const imagesSavedInServerRaw = imagesUrl?.filter((image) => image.url.indexOf('blob') === -1)
    const imagesSavesinServer = []
    for (let key in imagesSavedInServerRaw) {
      imagesSavesinServer.push(imagesSavedInServerRaw[key].url)
    }
    if (project) {
      let imageSize
      images.forEach((image, index) => {
        if (image.size > 512000) {
          imageSize = {
            imageSize: image.size,
            imageIndex: index + 1,
          }
        }
      })

      if (
        ((videoFile === null || videoFile.length < 1) &&
          (applicationFile === null || applicationFile.length < 1) &&
          images.length < 1 &&
          imagesUrl.length < 1) ||
        ((videoFile === null || videoFile.length < 1) &&
          (applicationFile === null || applicationFile.length < 1) &&
          images.length < 1 &&
          imagesUrl.length < 1)
      ) {
        setError('You have to select a valid, video, file "PDF or PowerPoint" or image!')
      } else if (videoFile?.size > 25600000) {
        setError('Video size cannot exceed more than 25MB')
      } else if (applicationFile?.size > 25600000) {
        setError('File size cannot exceed more than 25MB')
      } else if (imageSize?.imageSize > 512000) {
        setError(`Image ${imageSize?.imageIndex} size cannot exceed more than 500 KB`)
      } else {
        const id = project.idTeam
        const newProject = {
          group: project.project.group._id,
          images: [...imagesSavesinServer, ...images],
          video: videoFile,
          file: applicationFile,
        }
        dispatch(teamUpdateFinalProject(id, project.project._id, newProject))
      }
    }
  }

  return (
    <Grid container spacing={3} className={classes.modalRoot}>
      <Grid item sm={12} xs={12}>
        {teamgroups && project && (
          <form onSubmit={handleSubmit} autoComplete='off' className={classes.formRoot}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={4}>
                <label htmlFor='images2' className={imageButtonClasses}>
                  Images
                  <span className={classes.SubInfoLabel}>(Up to 10 images.)</span>
                  <span>
                    <PermMedia />
                  </span>
                </label>
                <input
                  type='file'
                  ref={fileInput}
                  onClick={() => selectImageHandler()}
                  multiple
                  onChange={(e) => handleImageChange(e)}
                  hidden
                  id='images2'
                  name='images2'
                  accept='image/png, image/jpeg'
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <label htmlFor='video2' className={videoButtonClasses}>
                  Video
                  <span className={classes.SubInfoLabel}>(Only a video.)</span>
                  <span>
                    <VideoLibrary />
                  </span>
                </label>
                <input
                  type='file'
                  onChange={(e) => handleNewVideo(e)}
                  hidden
                  id='video2'
                  name='video2'
                  accept='video/mp4'
                />
              </Grid>

              <Grid item xs={6} sm={4} md={4}>
                <label htmlFor='File2' className={fileButtonClasses}>
                  File
                  <span className={classes.SubInfoLabel}>(Only a file.)</span>
                  <span>
                    <FileCopy />
                  </span>
                </label>
                <input
                  disabled={applicationFile}
                  type='file'
                  onChange={handleFileChange}
                  hidden
                  id='File2'
                  name='File2'
                  value=''
                  accept='.pdf, .ppt, .pptm, .pptx'
                />
              </Grid>
              {fileName && (
                <Grid item xs={12} md={12}>
                  <div className={classes.previewRoot}>
                    <Badge
                      badgeContent={
                        <Button size='sm' color='danger' onClick={handleFileRemove} round justIcon>
                          <Close />
                        </Button>
                      }
                      className={classes.fileBadge}
                    >
                      <div className={classes.previewContainer}>
                        <FileCopy />
                        <div className={classes.fileDiv}>
                          {fileName.length > 20
                            ? fileName.slice(0, 20) + '...' + fileName.slice(fileName.length - 6, fileName.length)
                            : fileName}
                        </div>
                      </div>
                    </Badge>
                  </div>
                </Grid>
              )}
              {videoFile && (
                <Grid item xs={12} className={classes.fileRoot}>
                  <div className={classes.previewRoot}>
                    <Badge
                      badgeContent={
                        <Button size='sm' color='danger' round justIcon onClick={() => handleVideoRemove()}>
                          <Close />
                        </Button>
                      }
                      className={classes.fileBadge}
                    >
                      <div className={classes.previewContainer}>
                        <VideoLibrary />
                        <div className={classes.videoDiv}>
                          {videoFile?.name
                            ? videoFile.name.slice(0, 20) +
                              '...' +
                              videoFile.name.slice(videoFile.name.length - 6, videoFile.name.length)
                            : videoFile.split('/')?.reverse()[0].slice(0, 20) +
                              '...' +
                              videoFile.slice(videoFile.length - 6, videoFile.length)}
                        </div>
                      </div>
                    </Badge>
                  </div>
                </Grid>
              )}

              {imagesUrl.length > 0 && (
                <Grid item xs={12} sm={12}>
                  <div className={classes.infoGrid}>
                    {imagesUrl.map((item, i) => (
                      <div key={i}>
                        <Badge
                          badgeContent={
                            <Button size='sm' color='danger' round justIcon onClick={() => handleRemoveImage(item)}>
                              <Close />
                            </Button>
                          }
                        >
                          <img src={!item.name ? `${axios.defaults.baseURL}/${item.url}` : item.url} alt={item.url} />
                        </Badge>
                      </div>
                    ))}
                  </div>
                </Grid>
              )}
            </Grid>
            {error && <Message message={<span>{error}</span>} color='danger' />}
            {errorTeamProjectUpdate && <Message message={<span>{errorTeamProjectUpdate}</span>} color='info' />}
            <Button disabled={imagesUrl.length < 1 || loadingTeamProjectUpdate} block color='success' type='submit'>
              Update project
            </Button>
          </form>
        )}
      </Grid>
    </Grid>
  )
}

export default UpdateProjectModal

import axios from 'axios'
import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_REFRESH_TOKEN_REQUEST,
	USER_REFRESH_TOKEN_SUCCESS,
	USER_REFRESH_TOKEN_FAIL,
	USER_LOGIN_FAIL_CLEAR,
} from '../constants/userConstants'
import parseJwt from '../../middlewares/parseJwt'

export const login = (userCredentials, credentials) => async (dispatch) => {
	const { access_token } = userCredentials

	try {
		dispatch({ type: USER_LOGIN_REQUEST })

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const payload = !access_token
			? { loginWithCredentials: { email: credentials?.email, password: credentials?.password } }
			: { token: access_token }

		const { data } = await axios.post('/api/users/login', payload, config)

		const decoded = parseJwt(data.token)

		const userSession = {
			...decoded,
			token: data.token,
		}

		dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })

		localStorage.setItem('setPlanet', JSON.stringify(data.token))
	} catch (error) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		setTimeout(() => {
			dispatch({
				type: USER_LOGIN_FAIL_CLEAR,
			})
		}, 3000)
	}
}

export const registerUser = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/users', user, config)

		dispatch({ type: USER_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getUserById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/users/${id}`, config)

		dispatch({ type: USER_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: USER_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getUsers =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: USER_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(`/api/users?count=${count}&start=${start}&keyword=${keyword}`, config)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/users?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: USER_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: USER_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const userUpdateMyInfo = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/users/${user._id}`, user, config)

		const decoded = parseJwt(data.token)
		const userSession = {
			...decoded,
			token: data.token,
		}

		dispatch({ type: USER_UPDATE_SUCCESS, payload: userSession })
		dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
		localStorage.setItem('setPlanet', JSON.stringify(data.token))
	} catch (error) {
		dispatch({
			type: USER_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const userUpdateInfo = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/users/${user._id}`, user, config)

		const decoded = parseJwt(data.token)
		const userUpdated = {
			...decoded,
		}
		dispatch({ type: USER_UPDATE_SUCCESS, payload: userUpdated })

		if (userInfo._id === decoded._id) {
			localStorage.setItem('setPlanet', JSON.stringify(data.token))

			const userSession = {
				...decoded,
				token: data.token,
			}

			dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
		}
	} catch (error) {
		dispatch({
			type: USER_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteUser = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/users/${id}`, config)

		dispatch({ type: USER_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: USER_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const logout = () => async (dispatch) => {
	localStorage.removeItem('setPlanet')
	dispatch({ type: USER_LOGOUT })
}

export const refreshToken = () => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_REFRESH_TOKEN_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.get('/api/users/refreshtoken', config)

		dispatch({ type: USER_REFRESH_TOKEN_SUCCESS })
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message
		if (error?.response?.status === 401 || error?.response?.status === 403) {
			dispatch({
				type: USER_LOGOUT,
			})
		}
		dispatch({
			type: USER_REFRESH_TOKEN_FAIL,
			payload: message,
		})
	}
}

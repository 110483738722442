const styles = {
  root: {
    marginTop: '25px',
    padding: '35px',
  },
  cardHeader: {
    height: '100%',
  },
  customtab: {
    height: '100%',
  },
  groupNotFound: {
    textAlign: 'center',
    fontSize: '16px',
  },
  '@media screen and (max-width: 620px)': {
    root: {
      padding: '0',
    },
  },
}

export default styles

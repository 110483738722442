const styles = {
	parallaxContact: {
		backgroundImage: 'url("/assets/img/public-site/bg-contact.png")',
		minHeight: '500px',
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		padding: '10px',
	},
	parallaxContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& h3': {
			color: '#fff',
			fontSize: '45px',
		},
	},
}

export default styles

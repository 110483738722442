export const IMAGE_REGISTER_REQUEST = 'IMAGE_REGISTER_REQUEST'
export const IMAGE_REGISTER_SUCCESS = 'IMAGE_REGISTER_SUCCESS'
export const IMAGE_REGISTER_FAIL = 'IMAGE_REGISTER_FAIL'
export const IMAGE_REGISTER_RESET = 'IMAGE_REGISTER_RESET'

export const IMAGE_LIST_REQUEST = 'IMAGE_LIST_REQUEST'
export const IMAGE_LIST_SUCCESS = 'IMAGE_LIST_SUCCESS'
export const IMAGE_LIST_FAIL = 'IMAGE_LIST_FAIL'
export const IMAGE_LIST_RESET = 'IMAGE_LIST_RESET'

export const IMAGE_DELETE_REQUEST = 'IMAGE_DELETE_REQUEST'
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS'
export const IMAGE_DELETE_FAIL = 'IMAGE_DELETE_FAIL'
export const IMAGE_DELETE_RESET = 'IMAGE_DELETE_RESET'

export const IMAGE_UPDATE_REQUEST = 'IMAGE_UPDATE_REQUEST'
export const IMAGE_UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS'
export const IMAGE_UPDATE_FAIL = 'IMAGE_UPDATE_FAIL'
export const IMAGE_UPDATE_RESET = 'IMAGE_UPDATE_RESET'

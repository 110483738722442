import Carousel from 'react-slick'
import { Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/carrouselVideosStyles'
import '../../../../assets/css/slick-style.css'

const useStyles = makeStyles(styles)

const CarouselVideos = () => {
	const classes = useStyles()

	return (
		<Grid container className={classes.sectionRootWReverse}>
			<Grid item md={8} sm={12} xs={12} className={classes.videoCarouselRoot}>
				<Carousel
					dots={true}
					autoplay={true}
					infinite={true}
					autoplaySpeed={15000}
					pauseOnHover={true}
					slidesToShow={1}
					slidesToScroll={1}
				>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-planet-06.mp4'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-2-2024-institutes.mp4'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/planetfraternity-le-film.mov'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-planet-01.mp4'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-planet-02.mp4'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-planet-03.mp4'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-planet-04.mp4'} type='video/mp4' />
						</video>
					</div>
					<div>
						<video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
							<source src={'/assets/video/carousel/video-planet-05.mp4'} type='video/mp4' />
						</video>
					</div>
				</Carousel>
			</Grid>
		</Grid>
	)
}

export default CarouselVideos

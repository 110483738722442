import classNames from 'classnames'
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core'
import { Flare } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import Parallax from '../../../components/Parallax/Parallax'
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import { useTranslation } from 'react-i18next'
import styles from './styles/catholicSchoolsScreenStyles'

const useStyles = makeStyles(styles)

const CatholicSchoolsScreen = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation('global')

  return (
    <>
      <Meta title={'I Can - Planet Fraternity'} />
      <Parallax image={'/assets/img/public-site/bg-catholic-schools.png'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.titleBig}>{t('layout.t_pastoral')}</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.root}>
        <div className={classes.sections}>
          <Grid container className={classes.sectionRoot}>
            <Grid item xs={12} md={6} className={classes.imgRoot}>
              <img src='/assets/img/public-site/pastoral.jpg' alt='Pastoral' width='100%' />
            </Grid>
            <Grid item xs={12} md={6} className={classes.onTopCardRoot}>
              <Card className={classes.onTopCardRight}>
                <CardContent>
                  <div className={classes.descRoot}>
                    <Flare className={classes.catholicIcon} />
                    <p>{t('page.t_pastoral_text_one')}</p>
                    <p>{t('page.t_pastoral_text_two')}</p>
                    <div className={classes.descActions}>
                      <Button link onClick={() => history.push('/contact')}>
                        {t('layout.buttons.t_contact-us')}
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container className={classes.rootTable}>
            <Grid item xs={12}>
              <h2 className={classes.titleTable}>{t('page.t_pastoral_table_title')}</h2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size='small' aria-label='a dense table'>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>{t('layout.t_month')}</TableCell>
                      <TableCell>{t('layout.t_topic')}</TableCell>
                      <TableCell>{t('layout.t_explanation')}</TableCell>
                      <TableCell>{t('layout.t_activity')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInLeftBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        1
                      </TableCell>
                      <TableCell>{t('layout.t_october')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_one')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_one')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_one')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInRightBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        2
                      </TableCell>
                      <TableCell>{t('layout.t_november')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_two')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_two')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_two')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInLeftBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        3
                      </TableCell>
                      <TableCell>{t('layout.t_december')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_three')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_three')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_three')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInRightBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        4
                      </TableCell>
                      <TableCell>{t('layout.t_january')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_four')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_four')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_four')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInLeftBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        5
                      </TableCell>
                      <TableCell>{t('layout.t_february')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_five')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_five')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_five')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInRightBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        6
                      </TableCell>
                      <TableCell>{t('layout.t_march')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_six')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_six')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_six')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInLeftBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        7
                      </TableCell>
                      <TableCell>{t('layout.t_april')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_seven')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_seven')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_seven')}</TableCell>
                    </TableRow>
                    <TableRow
                      className={classNames(
                        classes.tableRows,
                        'wow animate__animated animate__fadeInRightBig animate__faster'
                      )}
                    >
                      <TableCell component='th' scope='row'>
                        8
                      </TableCell>
                      <TableCell>{t('layout.t_may')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_topic_eight')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_explanation_eight')}</TableCell>
                      <TableCell>{t('page.t_pastoral_table_activity_eight')}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default CatholicSchoolsScreen

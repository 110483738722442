import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import Button from '../Buttons/Button'
import styles from './styles/acceptCookiesStyles'

const useStyles = makeStyles(styles)

const AcceptCookies = ({ setAcceptCookies, history }) => {
  const classes = useStyles()
  const { t } = useTranslation('global')

  const setCookie = (cname, cvalue, exdays) => {
    const date = new Date()
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + date.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }
  const cookiesHandler = () => {
    setCookie('cookie-concent', Math.random(), 365)
    setAcceptCookies(true)
  }
  return (
    <Grid container className={classNames(classes.root, 'wow animate__animated animate__fadeInLeftBig')}>
      <Grid item xs={12} md={9}>
        <Grid container>
          <Grid item xs={12}>
            <h1>{t('layout.t_cookies-title')}</h1>
            {/* </Grid>
                    <Grid item xs={12}> */}
            <span>
              {t('layout.t_cookies-desc')}{' '}
              <Button link className={classes.btnLink} onClick={() => history.push('/terms-conditions')}>
                <span>{t('layout.t_cookies-policy')}</span>
              </Button>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} className={classes.cookiesActions}>
        <Button color='success' onClick={cookiesHandler}>
          {t('layout.buttons.t_confirm-cookies')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default AcceptCookies

import { useState } from 'react'
import { Grid, FormControl, Select, InputLabel, MenuItem, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { groupAgesRanges } from '../../../../utils/groupAgesRanges'
import styles from '../styles/groupInfoModalStyles'

const useStyles = makeStyles(styles)

const GroupInfoFormModal = ({ setGroupInfo, groupsInfo }) => {
	const classes = useStyles()
	const [savedGroup, setSavedGroup] = useState(false)
	const [groupName, setGroupName] = useState('')
	const [noOfStudents, setNoOfStudents] = useState('')
	const [agesRange, setAgesRange] = useState('')
	const [teacherName, setTeacherName] = useState('')
	const [teacherEmail, setTeacherEmail] = useState('')
	const [teacherPhone, setTeacherPhone] = useState('')
	const [errorMessage, setErrorMessage] = useState(null)

	const saveGroupInfoHandler = (e) => {
		e.preventDefault()
		setErrorMessage(null)
		if (
			groupName === '' ||
			noOfStudents === '' ||
			agesRange === '' ||
			teacherName === '' ||
			teacherEmail === '' ||
			teacherPhone === ''
		) {
			return false
		}

		if (noOfStudents < 3) {
			setErrorMessage('You must add a valid number of students')
			return false
		}

		const groupExists = groupsInfo.find((group) => group.groupName.trim() === groupName.trim())
		if (groupExists) {
			setErrorMessage(`You already have a grade with this name: ${groupName}`)
			return false
		}

		const groupInfo = {
			groupName,
			noOfStudents,
			agesRange,
			teacherName,
			teacherEmail,
			teacherPhone,
		}
		setGroupInfo((prev) => [...prev, groupInfo])
		cleanGroupInfo()
		setSavedGroup(true)
		setTimeout(() => {
			setSavedGroup(false)
		}, 5000)
	}
	const cleanGroupInfo = () => {
		setGroupName('')
		setNoOfStudents('')
		setAgesRange('')
		setTeacherName('')
		setTeacherEmail('')
		setTeacherPhone('')
	}

	return (
		<form onSubmit={saveGroupInfoHandler}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={4}>
					<CustomInput
						labelText='Grade Name'
						id='gradename'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'text',
							value: groupName,
							placeholder: 'E.g. 1A',
							onChange: (e) => setGroupName(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CustomInput
						labelText='No. of students'
						id='noofstudents'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'number',
							value: noOfStudents,
							placeholder: 'Numbers only',
							onChange: (e) => setNoOfStudents(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<FormControl className={classes.formControl}>
						<InputLabel id='ages-range' className={classes.selectLabel}>
							Ages Range
						</InputLabel>
						<Select
							labelId='ages-range'
							id='agesrange'
							value={agesRange}
							onChange={(e) => setAgesRange(e.target.value)}
							inputProps={{ required: true }}
							className={classes.selectInput}
						>
							<MenuItem value='' disabled>
								Select Age Range
							</MenuItem>
							{groupAgesRanges.map((age) => (
								<MenuItem key={age} value={age}>
									{age}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<CustomInput
						labelText='Teacher Name'
						id='teachername'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'text',
							value: teacherName,
							placeholder: 'Complete Teacher name',
							onChange: (e) => setTeacherName(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<CustomInput
						labelText='Teacher Email'
						id='teacheremail'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'email',
							value: teacherEmail,
							placeholder: 'Valid Teacher email',
							onChange: (e) => setTeacherEmail(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<CustomInput
						labelText='Teacher Phone'
						id='teacherphone'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'text',
							value: teacherPhone,
							placeholder: 'E.g. +00 000 000000',
							onChange: (e) => setTeacherPhone(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button type='submit' className={classes.btnMarginTop} color={savedGroup ? 'success' : 'primary'} block>
						{savedGroup ? 'Group Info Saved...' : 'Save Group Info'}
					</Button>
				</Grid>
				{errorMessage && (
					<Grid item xs={12}>
						<Message message={<span>{errorMessage}</span>} color='info' />
					</Grid>
				)}
			</Grid>
		</form>
	)
}

export default GroupInfoFormModal

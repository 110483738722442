import * as types from '../constants/galleryConstants'

export const imageGalleryRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.IMAGE_REGISTER_REQUEST:
      return { loadingImageRegister: true }
    case types.IMAGE_REGISTER_SUCCESS:
      return {
        loadingImageRegister: false,
        successImageRegister: true,
        image: action.payload,
      }
    case types.IMAGE_REGISTER_FAIL:
      return {
        loadingImageRegister: false,
        errorImageRegister: action.payload,
      }
    case types.IMAGE_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const imagesListReducer = (state = { images: [] }, action) => {
  switch (action.type) {
    case types.IMAGE_LIST_REQUEST:
      return { loadingImageList: true }
    case types.IMAGE_LIST_SUCCESS:
      return {
        loadingImageList: false,
        successImageList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        images: action.payload,
      }
    case types.IMAGE_LIST_FAIL:
      return {
        loadingImageList: false,
        errorImageList: action.payload,
      }
    case types.IMAGE_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const imageGalleryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.IMAGE_DELETE_REQUEST:
      return { loadingImageDelete: true }
    case types.IMAGE_DELETE_SUCCESS:
      return { loadingImageDelete: false, successImageDelete: true }
    case types.IMAGE_DELETE_FAIL:
      return {
        loadingImageDelete: false,
        errorImageDelete: action.payload,
      }
    case types.IMAGE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const imageGalleryUpdateReducer = (state = { imageUpdated: {} }, action) => {
  switch (action.type) {
    case types.IMAGE_UPDATE_REQUEST:
      return { loadingImageUpdate: true }
    case types.IMAGE_UPDATE_SUCCESS:
      return {
        loadingImageUpdate: false,
        successImageUpdate: true,
        imageUpdated: action.payload,
      }
    case types.IMAGE_UPDATE_FAIL:
      return {
        loadingImageUpdate: false,
        errorImageUpdate: action.payload,
      }
    case types.IMAGE_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Accordion, AccordionDetails, makeStyles } from '@material-ui/core'
import GridContainer from '../../../../components/Grid/GridContainer'
import styles from '../styles/talentVideoCardStyles'
import { useDispatch } from 'react-redux'
import { TALENT_SINGLE_RESET } from '../../../../redux/constants/talentsConstants'

const useStyles = makeStyles(styles)

const TalentVideoCard = ({ talent, getVideo }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	const [showMore, setShowMore] = useState(false)
	const { t } = useTranslation('global')
	const [Hovering, setHovering] = useState(false)

	const goVideo = () => {
		dispatch({ type: TALENT_SINGLE_RESET })
		history.push('/lets-share-talents/' + talent._id)
		getVideo(talent._id)
	}

	return (
		<GridContainer className={classes.root}>
			<div className={classes.thumbnail} onClick={goVideo}>
				<img
					src={`${axios.defaults.baseURL}/${talent.videoThumbNail ?? ''}`}
					className={classes.img}
					alt={'country-post-preview'}
				/>
				<div
					onMouseOver={() => setHovering(true)}
					onMouseLeave={() => setHovering(false)}
					className={classes.playButtonContainer}
				>
					{Hovering && <div className={classes.playButton}></div>}
				</div>
			</div>
			<div className={classes.descRoot}>
				<h4>{talent.title.length > 35 ? `${talent.title}...` : talent.title}</h4>
				<Accordion expanded={showMore} onChange={() => {}} className={classes.accordion}>
					<AccordionDetails>
						<p className={classes.text}>
							{talent?.body?.length > 200 ? (
								<>
									{showMore ? talent?.body : talent?.body.substring(0, 100)}
									{showMore ? '' : '... '}
									<b className={classes.showMoreButton} onClick={() => setShowMore(!showMore)}>
										{t(showMore ? 'countries.countryPosts.showLess' : 'countries.countryPosts.showMore')}
									</b>
								</>
							) : (
								talent?.body
							)}
						</p>
					</AccordionDetails>
				</Accordion>
			</div>
		</GridContainer>
	)
}

export default TalentVideoCard

import axios from 'axios'
import {
	TEACHERS_LIST_REQUEST,
	TEACHERS_LIST_SUCCESS,
	TEACHERS_LIST_FAIL,
	TEACHERS_BY_CENTER_REQUEST,
	TEACHERS_BY_CENTER_SUCCESS,
	TEACHERS_BY_CENTER_FAIL,
	TEACHERS_BY_EMAIL_REQUEST,
	TEACHERS_BY_EMAIL_SUCCESS,
	TEACHERS_BY_EMAIL_FAIL,
	TEACHERS_DETAILS_REQUEST,
	TEACHERS_DETAILS_SUCCESS,
	TEACHERS_DETAILS_FAIL,
	TEACHERS_GROUPS_REQUEST,
	TEACHERS_GROUPS_SUCCESS,
	TEACHERS_GROUPS_FAIL,
	TEACHERS_LIST_ALL_REQUEST,
	TEACHERS_LIST_ALL_SUCCESS,
	TEACHERS_LIST_ALL_FAIL,
	TEACHER_DELETE_REQUEST,
	TEACHER_DELETE_SUCCESS,
	TEACHER_DELETE_FAIL,
} from '../constants/teacherConstants'

export const getTeachers =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: TEACHERS_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(`/api/teachers?count=${count}&start=${start}&keyword=${keyword}`, config)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/teachers?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: TEACHERS_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: TEACHERS_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getTeachersByCenter = (centerId) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEACHERS_BY_CENTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teachers/center/${centerId}`, config)

		dispatch({ type: TEACHERS_BY_CENTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEACHERS_BY_CENTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeachersByEmail = (email) => async (dispatch) => {
	try {
		dispatch({ type: TEACHERS_BY_EMAIL_REQUEST })

		const { data } = await axios.get(`/api/teachers/email/${email}`)

		dispatch({ type: TEACHERS_BY_EMAIL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEACHERS_BY_EMAIL_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeachersById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEACHERS_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teachers/${id}`, config)

		dispatch({ type: TEACHERS_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEACHERS_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeacherGroups = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TEACHERS_GROUPS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teachers/groups/${userInfo._id}`, config)

		dispatch({ type: TEACHERS_GROUPS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEACHERS_GROUPS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAllTeachers = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TEACHERS_LIST_ALL_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.get('/api/teachers/all', config)
		dispatch({ type: TEACHERS_LIST_ALL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEACHERS_LIST_ALL_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteTeacher = (teacher) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEACHER_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/teachers/${teacher}`, config)

		dispatch({ type: TEACHER_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: TEACHER_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

const GET_MEDIA_CONSTRAINTS = {
  video: {
    width: { min: 1280 },
    height: { min: 720 },
  },
  audio: true,
}

const PEER_CONFIG = {
  iceServers: [
    { urls: ['stun:stun.l.google.com:19302'] },
    { urls: ['stun:stun1.l.google.com:19302'] },
    {
      urls: ['turn:numb.viagenie.ca'],
      credential: 'WebRTC2021',
      username: 'informatico@theimproving.es',
    },
    {
      urls: ['turn:numb.viagenie.ca'],
      credential: 'muazkh',
      username: 'webrtc@live.com',
    },
  ],
}

const ICE_CONFIG = {
  iceServers: [
    {
      urls: 'stun:turn01.brie.fi:5349',
    },
    {
      urls: 'turn:turn01.brie.fi:5349',
      username: 'brie',
      credential: 'fi',
    },
  ],
}

const ICE_CONFIG_PLANET = {
  iceTransportPolicy: 'all',
  reconnectTimer: 3000,
  iceServers: [
    {
      urls: process.env.REACT_APP_STUN,
    },
    {
      urls: process.env.REACT_APP_TURN,
      username: process.env.REACT_APP_TURN_USER,
      credential: process.env.REACT_APP_TURN_PASS,
    },
  ],
}

const ICE_CONFIG_PLANET_FRATERNITY = {
  iceTransportPolicy: 'all',
  reconnectTimer: 3000,
  iceServers: [
    {
      urls: 'stun:turn01.brie.fi:5349',
    },
    {
      urls: 'stun:stun.planetfraternity.com',
    },
    {
      urls: 'turn:turn01.brie.fi:5349',
      username: 'brie',
      credential: 'fi',
    },
    {
      urls: 'turn:turn.planetfraternity.com',
      username: 'guest',
      credential: 'somepassword',
    },
  ],
}

export { GET_MEDIA_CONSTRAINTS, PEER_CONFIG, ICE_CONFIG, ICE_CONFIG_PLANET, ICE_CONFIG_PLANET_FRATERNITY }

import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import OwlCarousel from 'react-owl-carousel-rtl'
import { owlOptions } from '../config/owl-config'
import styles from '../styles/homeScreenStyles'

const useStyles = makeStyles(styles)

const CongressCarousel = () => {
	const classes = useStyles()
	return (
		<Grid container>
			<Grid item md={12} xs={12}>
				<OwlCarousel
					autoplayTimeout={8000}
					id='customer-testimonoals'
					className={classNames(classes.customerCarousels, 'owl-carousel owl-theme')}
					{...owlOptions}
				>
					<div className={classNames(classes.carouselItem, 'item')}>
						<div className={classes.shadowEffectCongress}>
							<img alt='oneOne' src={'/assets/img/carrousel/congress1.png'} />
						</div>
					</div>
					<div className={classNames(classes.carouselItem, 'item')}>
						<div className={classes.shadowEffectCongress}>
							<img alt='oneOne' src={'/assets/img/carrousel/congress2.png'} />
						</div>
					</div>
					<div className={classNames(classes.carouselItem, 'item')}>
						<div className={classes.shadowEffectCongress}>
							<img alt='oneOne' src={'/assets/img/carrousel/congress3.png'} />
						</div>
					</div>
					<div className={classNames(classes.carouselItem, 'item')}>
						<div className={classes.shadowEffectCongress}>
							<img alt='oneOne' src={'/assets/img/carrousel/congress4.png'} />
						</div>
					</div>
				</OwlCarousel>
			</Grid>
		</Grid>
	)
}

export default CongressCarousel

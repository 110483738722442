import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import { updateStudent } from '../../../../redux/actions/studentActions'
import styles from '../styles/studentEditFormModalStyles'

const useStyles = makeStyles(styles)

const StudentEditFormModal = ({ setOpen, student }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const studentUpdate = useSelector((state) => state.studentUpdate)
  const { loadingStudentUpdate, errorStudentUpdate, successStudentUpdate } = studentUpdate

  useEffect(() => {
    setName(student.name)
    setUsername(student.googleMail)
  }, [student])

  useEffect(() => {
    if (successStudentUpdate) {
      setOpen(false)
    }
  }, [successStudentUpdate, setOpen])

  const submitHandler = (e) => {
    e.preventDefault()
    const studentUpdateInfo = {
      _id: student._id,
      name,
      username,
      password,
    }
    dispatch(updateStudent(studentUpdateInfo))
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <CustomInput
                labelText='Name'
                id={'student-name' + student.name}
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputProps={{
                  type: 'text',
                  value: name,
                  placeholder: 'Enter student name',
                  onChange: (e) => setName(e.target.value),
                  required: true,
                }}
              />
              <CustomInput
                labelText='Username'
                id={'student-usernmae' + student.name}
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputProps={{
                  type: 'text',
                  value: username,
                  placeholder: 'Enter Username',
                  onChange: (e) => setUsername(e.target.value),
                  required: true,
                  disabled: true,
                }}
              />
              <CustomInput
                labelText='Password'
                id={'student-password' + student.name}
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputProps={{
                  type: 'password',
                  value: password,
                  placeholder: 'Enter password',
                  onChange: (e) => setPassword(e.target.value),
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Button type='submit' color='primary' block>
                {loadingStudentUpdate ? <Loader size={'sm'} /> : 'Submit'}
              </Button>
            </Grid>
          </Grid>

          {errorStudentUpdate && <Message>{errorStudentUpdate}</Message>}
        </form>
      </Grid>
    </Grid>
  )
}

export default StudentEditFormModal

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, FormControlLabel, Switch, TextField } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import CustomModal from '../../../../components/Modal/CustomModal'
import Loader from '../../../../components/Loader/Loader'
import { deleteTestimonial, testimonialUpdate } from '../../../../redux/actions/testimonialActions'
import styles from '../styles/testimonialsListScreenStyles'

const useStyles = makeStyles(styles)

const TestimonialCardForm = ({ testimonial }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [testimonialText, setTestimonialText] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [active, setActive] = useState(true)
  const [successUpdate, setSuccessUpdate] = useState(false)

  const { loadingTestimonialUpdate, successTestimonialUpdate } = useSelector((state) => state.testimonialUpdate)
  const { successTestimonialDelete } = useSelector((state) => state.testimonialDelete)

  useEffect(() => {
    if (testimonial) {
      setTestimonialText(testimonial.testimonial)
      setActive(testimonial.isActive)
    }
  }, [testimonial])

  useEffect(() => {
    if (successTestimonialUpdate) {
      setSuccessUpdate(true)
      setTimeout(() => {
        setSuccessUpdate(false)
      }, 2000)
    }
  }, [successTestimonialUpdate, dispatch])

  useEffect(() => {
    if (successTestimonialDelete) {
      setOpenDelete(false)
    }
  }, [successTestimonialDelete])

  const submitEditFormHandler = (e) => {
    e.preventDefault()
    const updateTestimonial = {
      _id: testimonial._id,
      testimonial: testimonialText,
      isActive: active,
    }
    dispatch(testimonialUpdate(updateTestimonial))
  }

  const deleteHandler = () => {
    dispatch(deleteTestimonial(testimonial._id))
  }

  return (
    <>
      <form onSubmit={submitEditFormHandler} autoComplete='off'>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              className={classes.areaField}
              id='outlined-textarea'
              label='Testimonial'
              placeholder='Testimonial'
              multiline
              value={testimonialText}
              onChange={(e) => setTestimonialText(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.switchItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  name='isActive'
                  value='active'
                  color='primary'
                />
              }
              label='Is Active'
            />
          </Grid>
          <Grid item xs={8} md={9}>
            <Button type='submit' block color={successUpdate ? 'success' : 'primary'} disabled={successUpdate && true}>
              {loadingTestimonialUpdate ? (
                <Loader size={'sm'} />
              ) : successUpdate ? (
                'Updated Successfully'
              ) : (
                'Update Testimonial'
              )}
            </Button>
          </Grid>
          <Grid item xs={4} md={3}>
            <Button onClick={() => setOpenDelete(true)} color='danger' block>
              Delete
            </Button>
          </Grid>
        </Grid>
      </form>
      <CustomModal
        title='Delete Testimonial?'
        open={openDelete}
        setOpen={setOpenDelete}
        acceptText='Delete'
        acceptHandler={deleteHandler}
      >
        Are you sure you want to delete this testimonial?
      </CustomModal>
    </>
  )
}

export default TestimonialCardForm

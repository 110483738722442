import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styles from './styles/termsConditionsScreenStyles'

const useStyles = makeStyles(styles)

const TermsConditionsScreen = () => {
    const classes = useStyles()
    const { t } = useTranslation('global')

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <h1>{t('page.t_terms-text-1')}</h1>
                <div className={classes.termsSection}>
                    <p>{t('page.t_terms-text-2')}</p>
                    <p>{t('page.t_terms-text-3')}</p>
                    <p>{t('page.t_terms-text-4')}</p>
                    <ul>
                        <li>{t('page.t_terms-text-5')}</li>
                        <li>{t('page.t_terms-text-6')}</li>
                        <li>{t('page.t_terms-text-7')}</li>
                        <li>{t('page.t_terms-text-8')}</li>
                        <li>{t('page.t_terms-text-9')}</li>
                        <li>{t('page.t_terms-text-10')}</li>
                        <li>{t('page.t_terms-text-11')}</li>
                        <li>{t('page.t_terms-text-12')}</li>
                        <li>{t('page.t_terms-text-13')}</li>
                        <li>{t('page.t_terms-text-14')}</li>
                        <li>{t('page.t_terms-text-15')}</li>
                        <li>{t('page.t_terms-text-16')}</li>
                    </ul>
                    <p>{t('page.t_terms-text-17')}</p>
                </div>
                <div className={classes.termsSection}>
                    <h2>{t('page.t_terms-text-18')}</h2>
                    <p>{t('page.t_terms-text-19')}</p>
                    <p>{t('page.t_terms-text-20')}</p>
                    <p>{t('page.t_terms-text-21')}</p>
                </div>
                <div className={classes.termsSection}>
                    <h2>{t('page.t_terms-text-22')}</h2>
                    <p>{t('page.t_terms-text-23')}</p>
                </div>
                <div className={classes.termsSection}>
                    <h2>{t('page.t_terms-text-24')}</h2>
                    <p>{t('page.t_terms-text-25')}</p>
                </div>
                <div className={classes.termsSection}>
                    <h2>{t('page.t_terms-text-26')}</h2>
                    <p>{t('page.t_terms-text-27')}</p>
                    <p>{t('page.t_terms-text-28')}</p>
                    <p>{t('page.t_terms-text-29')}</p>
                    <ul>
                        <li>{t('page.t_terms-text-30')}</li>
                        <li>{t('page.t_terms-text-31')}</li>
                        <li>
                            <div>
                                {t('page.t_terms-text-32')}
                                <ul>
                                    <li>{t('page.t_terms-text-33')}</li>
                                    <li>{t('page.t_terms-text-34')}</li>
                                    <li>{t('page.t_terms-text-35')}</li>
                                    <li>{t('page.t_terms-text-36')}</li>
                                    <li>{t('page.t_terms-text-37')}</li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <p>{t('page.t_terms-text-38')}</p>
                    <p>{t('page.t_terms-text-39')}</p>
                </div>
                <div className={classes.termsSection}>
                    <h2>{t('page.t_terms-text-40')}</h2>
                    <p>{t('page.t_terms-text-41')}</p>
                    <p>{t('page.t_terms-text-42')}</p>
                    <ul>
                        <li>{t('page.t_terms-text-43')}</li>
                        <li>{t('page.t_terms-text-44')}</li>
                    </ul>
                </div>
                <div className={classes.termsSection}>
                    <h2>{t('page.t_terms-text-45')}</h2>
                    <p>{t('page.t_terms-text-46')}</p>
                    <p>{t('page.t_terms-text-47')}</p>
                </div>
            </Grid>
        </Grid>
    )
}

export default TermsConditionsScreen

export const dataURItoBlob = (dataURI) => {
	let byteString
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1])
	} else {
		byteString = unescape(dataURI.split(',')[1])
	}
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	const ia = new Uint8Array(byteString.length)
	for (let index = 0; index < byteString.length; index++) {
		ia[index] = byteString.charCodeAt(index)
	}
	return new Blob([ia], { type: mimeString })
}

import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/pendingSubsButtonStyles.js'

const useStyles = makeStyles(styles)

const PendingSubsButton = ({ keyword }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      {keyword && (
        <Button color='info' className={classes.btnResetSearch} onClick={() => history.push('/admin/centers')}>
          Reset Search
        </Button>
      )}
    </>
  )
}

export default PendingSubsButton

import { format } from 'date-fns'
import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/answerTeacherModalStyle'

const useStyles = makeStyles(styles)

const AnswersTeacherModal = ({ infoTeacher }) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item className={classes.basicInfo}>
        <h5>Basic Info.</h5>
        <div>
          Date Answer · <span>{infoTeacher.dateAnswer && format(new Date(infoTeacher?.dateAnswer), 'dd-MM-yyyy')}</span>
        </div>
        <div>
          Center Name · <span>{infoTeacher?.center?.name ? infoTeacher?.center?.name : 'Not registered'}</span>
        </div>
        <div>
          Center Email · <span>{infoTeacher?.center?.email ? infoTeacher?.center?.email : 'Not registered'}</span>
        </div>
        <div>
          Center Address · <span>{infoTeacher?.center?.address ? infoTeacher?.center?.address : 'Not registered'}</span>
        </div>
        <div>
          <span>
            Center Country · {infoTeacher?.center?.country?.name} ·{' '}
            <img
              src={`${axios.defaults.baseURL}${infoTeacher?.center?.country?.flag}`}
              className={classes.countryFlagProfile}
              alt={'Flag of ' + infoTeacher?.center?.country?.name}
            />
          </span>
        </div>
        <div>
          Teacher name: <span>{infoTeacher?.teacher?.name ? infoTeacher?.teacher?.name : 'Not registered'}</span>
        </div>
        <div>
          Teacher phone: <span>{infoTeacher?.teacher?.phone ? infoTeacher?.teacher?.phone : 'Not registered'}</span>
        </div>
        <div>
          Teacher email: <span>{infoTeacher?.teacher?.email ? infoTeacher?.teacher?.email : 'Not registered'}</span>
        </div>
      </Grid>
      <Grid item className={classes.surveySection}>
        <h5>Survey Info.</h5>
        {infoTeacher.answers?.map((info, i) => {
          const index = i + 1
          return (
            <Grid item xs={12} key={index}>
              {info.answer?.length > 0 && (
                <>
                  <p>
                    Question {index}.- {info.question} - <span className={classes.titleQuestion}>Paragraph</span>
                  </p>
                  <p>
                    Answer: <strong>{info.answer}</strong>
                  </p>
                </>
              )}
              {info.radioAnwser?.length > 0 && (
                <>
                  <p>
                    Question {index}.- {info.question} - <span className={classes.titleQuestion}>Options</span>
                  </p>
                  <p>
                    Chosen option: <strong>{info.radioAnwser}</strong>
                  </p>
                </>
              )}
              {info.answersCheckbox?.length > 0 && (
                <>
                  <p>
                    Question {index}.- {info.question} - <span className={classes.titleQuestion}>Multiple Choice</span>
                  </p>
                  <div>
                    {info.answersCheckbox.map((select) => (
                      <p key={select._id}>
                        Chosen option: <strong>{select.answer}</strong>
                      </p>
                    ))}
                  </div>
                </>
              )}
              <hr />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default AnswersTeacherModal

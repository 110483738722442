const styles = {
    formRoot: {
        marginLeft: '20px',
        position: 'relative',
        '& input': {
            border: '1px solid #f7f7f7',
            fontSize: '15px',
            width: '530px',
            borderRadius: '0.25rem',
            height: '34px',
            padding: '0.375rem 2rem 0.375rem 0.75rem',
            backgroundColor: '#f0f2f5 !important',
            color: '#483d3d !important',
            '&::placeholder': {
                color: '#65676b !important',
                fontSize: '13px',
                opacity: '0.5 !important',
            },
            '&:focus': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                border: '0px solid #f1f1f1',
                boxShadow: '0 0 0 0.2rem rgba(255, 255, 255, 0.45)',
            },
        },
    },
    searchIcon: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        color: '#483d3d',
        cursor: 'pointer',
    },

    '@media screen and (max-width: 1024px)': {
        formRoot: {
            marginLeft: '10px',
            '& input': {
                width: '100%',
                height: '30px',
            },
        },
    },
    '@media screen and (max-width: 480px)': {
        formRoot: {
            marginLeft: '5px',
            '& input': {
                width: '100%',
            },
        },
    },
}
export default styles

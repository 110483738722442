import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { Edit, MenuBook } from '@material-ui/icons'
import CustomTabs from '../../../../components/CustomTabs/CustomTabs'
import WritingContestInfo from './WritingContestInfo'
import WritingContestEditForm from './WritingContestEditForm'
import { WRITING_C_REGISTER_RESET, WRITING_C_UPDATE_RESET } from '../../../../redux/constants/writingContestConstants'
import styles from '../styles/magazineCardStyles'

const useStyles = makeStyles(styles)

const WritingContestCard = ({ writing }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [refreshMagazine, setRefreshMagazine] = useState(false)

    useEffect(() => {
        if (refreshMagazine) {
            dispatch({ type: WRITING_C_UPDATE_RESET })
            dispatch({ type: WRITING_C_REGISTER_RESET })
            setRefreshMagazine(false)
        }
    }, [dispatch, writing, refreshMagazine])

    return (
        <div>
            {writing && (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} className={classes.cardRoot}>
                        <CustomTabs
                            headerColor='primary'
                            className={classes.tabsRoot}
                            tabs={[
                                {
                                    tabName: 'Writing Contest',
                                    tabIcon: MenuBook,
                                    tabContent: <WritingContestInfo writing={writing} />,
                                },
                                {
                                    tabName: 'Edit Info',
                                    tabIcon: Edit,
                                    tabContent: <WritingContestEditForm writing={writing} />,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export default WritingContestCard

const styles = {
  mainSection: {
    border: '2px solid #e5e5e5',
    padding: '20px',
    marginBottom: '10px',
    borderRadius: '16px',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    textAlign: 'left',
    padding: 0,
    '& input': {
      padding: '0px 0px 7px',
    },
  },
  sectionSelects: {
    padding: '0px 0px 5px 5px',
    margin: 0,
  },
}

export default styles

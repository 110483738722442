import axios from 'axios'
import PropTypes from 'prop-types'
import { Card, CardHeader, Avatar, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../../components/CustomDropdown/CustomDropdown'
import styles from '../../styles/topicListCardStyles'

const useStyles = makeStyles(styles)

const TopicsListCard = ({ topic, dropdownList, draggable }) => {
  const classes = useStyles()

  const dragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(id))
  }

  return (
    <div className='user-card' draggable={draggable} onDragStart={(e) => dragStart(e, topic)}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeaderRoot}
          avatar={
            <Avatar variant='rounded' aria-label='topic' src={`${axios.defaults.baseURL}${topic.image}`}></Avatar>
          }
          action={
            dropdownList ? (
              <CustomDropdown
                left
                caret={false}
                buttonText={
                  <div className={classes.btnwrapper}>
                    <MoreVert />
                  </div>
                }
                buttonProps={{
                  className: classes.navLink + ' ' + classes.imageDropdownButton,
                  color: 'transparent',
                }}
                dropdownList={[...dropdownList]}
              />
            ) : null
          }
          title={topic.name}
        />
      </Card>
    </div>
  )
}

TopicsListCard.defaultProps = {
  draggable: false,
}

TopicsListCard.propTypes = {
  topic: PropTypes.object.isRequired,
  dropdownList: PropTypes.array,
  draggable: PropTypes.bool,
}

export default TopicsListCard

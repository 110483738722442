import Button from '../../../../../components/Buttons/Button'

const TopicButton = ({ setOpen }) => {
  return (
    <Button color='success' onClick={() => setOpen(true)}>
      + Create Topic
    </Button>
  )
}

export default TopicButton

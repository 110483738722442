import * as types from '../constants/centerConstants'

export const centerRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.CENTER_REGISTER_REQUEST:
			return { loadingCenterRegister: true }
		case types.CENTER_REGISTER_SUCCESS:
			return {
				loadingCenterRegister: false,
				successCenterRegister: true,
				center: action.payload,
			}
		case types.CENTER_REGISTER_FAIL:
			return {
				loadingCenterRegister: false,
				errorCenterRegister: action.payload,
			}
		case types.CENTER_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const centerGroupsListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.CENTER_GROUPS_REQUEST:
			return { loadingCenterGroupsList: true }
		case types.CENTER_GROUPS_SUCCESS:
			return {
				loadingCenterGroupsList: false,
				successCenterGroupsList: true,
				centerGroupsList: action.payload,
			}
		case types.CENTER_GROUPS_FAIL:
			return {
				loadingCenterGroupsList: false,
				errorCenterGroupsList: action.payload,
			}
		case types.CENTER_GROUPS_RESET:
			return {}
		default:
			return state
	}
}

export const centerActivateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.CENTER_ACTIVATE_REQUEST:
			return { loadingCenterActivate: true }
		case types.CENTER_ACTIVATE_SUCCESS:
			return {
				loadingCenterActivate: false,
				successCenterActivate: true,
				centerActivated: action.payload,
			}
		case types.CENTER_ACTIVATE_FAIL:
			return {
				loadingCenterActivate: false,
				errorCenterActivate: action.payload,
			}
		case types.CENTER_ACTIVATE_RESET:
			return {}
		default:
			return state
	}
}

export const centerListReducer = (state = { centers: [] }, action) => {
	switch (action.type) {
		case types.CENTER_LIST_REQUEST:
			return { loading: true }
		case types.CENTER_LIST_SUCCESS:
			return {
				loading: false,
				thatsIt: action.payload.length === 0 ? true : false,
				centers: action.payload,
			}
		case types.CENTER_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const centerNotPartneredReducer = (state = {}, action) => {
	switch (action.type) {
		case types.CENTER_NOTPARTNERED_REQUEST:
			return { loadingNotPartnered: true }
		case types.CENTER_NOTPARTNERED_SUCCESS:
			return {
				loadingNotPartnered: false,
				thatsIt: action.payload.length === 0 ? true : false,
				centersNotPartnered: action.payload,
			}
		case types.CENTER_NOTPARTNERED_FAIL:
			return {
				loadingNotPartnered: false,
				errorNotPartnered: action.payload,
			}
		case types.CENTER_NOTPARTNERED_RESET:
			return {}
		default:
			return state
	}
}

export const centerPartnersReducer = (state = { centersPartners: [] }, action) => {
	switch (action.type) {
		case types.CENTER_PARTNERS_REQUEST:
			return { loadingPartners: true }
		case types.CENTER_PARTNERS_SUCCESS:
			return {
				loadingPartners: false,
				centersPartners: action.payload,
			}
		case types.CENTER_PARTNERS_FAIL:
			return {
				loadingPartners: false,
				errorPartners: action.payload,
			}
		case types.CENTER_PARTNERS_RESET:
			return { centersPartners: [] }
		default:
			return state
	}
}

export const centerDetailsReducer = (state = { center: { license: {}, country: {} } }, action) => {
	switch (action.type) {
		case types.CENTER_DETAILS_REQUEST:
			return { loadingCenterDetails: true }
		case types.CENTER_DETAILS_SUCCESS:
			return { loadingCenterDetails: false, center: action.payload }
		case types.CENTER_DETAILS_FAIL:
			return {
				loadingCenterDetails: false,
				errorCenterDetails: action.payload,
			}
		case types.CENTER_DETAILS_RESET:
			return { center: { license: {}, country: {} } }
		default:
			return state
	}
}

export const centersByCountryReducer = (state = { centers: [] }, action) => {
	switch (action.type) {
		case types.CENTER_BY_COUNTRY_REQUEST:
			return { loadingCentersByCountry: true }
		case types.CENTER_BY_COUNTRY_SUCCESS:
			return {
				loadingCentersByCountry: false,
				successCentersByCountry: true,
				centers: action.payload,
			}
		case types.CENTER_BY_COUNTRY_FAIL:
			return {
				loadingCentersByCountry: false,
				errorCentersByCountry: action.payload,
			}
		case types.CENTER_BY_COUNTRY_RESET:
			return {}
		default:
			return state
	}
}

export const centerUpdateReducer = (state = { centerUpdated: {} }, action) => {
	switch (action.type) {
		case types.CENTER_UPDATE_REQUEST:
			return { loadingCenterUpdate: true }
		case types.CENTER_UPDATE_SUCCESS:
			return {
				loadingCenterUpdate: false,
				successCenterUpdate: true,
				centerUpdated: action.payload,
			}
		case types.CENTER_UPDATE_FAIL:
			return {
				loadingCenterUpdate: false,
				errorCenterUpdate: action.payload,
			}
		case types.CENTER_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const centerDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.CENTER_DELETE_REQUEST:
			return { loadingCenterDelete: true }
		case types.CENTER_DELETE_SUCCESS:
			return { loadingCenterDelete: false, successCenterDelete: true }
		case types.CENTER_DELETE_FAIL:
			return {
				loadingCenterDelete: false,
				errorCenterDelete: action.payload,
			}
		case types.CENTER_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const centerSendFriendReqReducer = (state = {}, action) => {
	switch (action.type) {
		case types.CENTER_SEND_FRIENDREQ_REQUEST:
			return { loading: true }
		case types.CENTER_SEND_FRIENDREQ_SUCCESS:
			return { loading: false, success: true }
		case types.CENTER_SEND_FRIENDREQ_FAIL:
			return { loading: false, error: action.payload }
		case types.CENTER_SEND_FRIENDREQ_RESET:
			return {}
		default:
			return state
	}
}

import axios from 'axios'
import {
	SURVEY_REGISTER_REQUEST,
	SURVEY_REGISTER_SUCCESS,
	SURVEY_REGISTER_FAIL,
	SURVEY_LIST_REQUEST,
	SURVEY_LIST_SUCCESS,
	SURVEY_LIST_FAIL,
	SURVEY_DETAILS_REQUEST,
	SURVEY_DETAILS_SUCCESS,
	SURVEY_DETAILS_FAIL,
	SURVEY_UPDATE_REQUEST,
	SURVEY_UPDATE_SUCCESS,
	SURVEY_UPDATE_FAIL,
	SURVEY_DELETE_REQUEST,
	SURVEY_DELETE_SUCCESS,
	SURVEY_DELETE_FAIL,
	SURVEY_ANSWER_REQUEST,
	SURVEY_ANSWER_SUCCESS,
	SURVEY_ANSWER_FAIL,
	ANSWER_REQUEST,
	ANSWER_FAIL,
	ANSWER_SUCCESS,
	ANSWER_SURVEY_REQUEST,
	ANSWER_SURVEY_SUCCESS,
	ANSWER_SURVEY_FAIL,
} from '../constants/surveyConstants'

export const registerSurvey = (surveyInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: SURVEY_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/surveys', surveyInfo, config)

		dispatch({ type: SURVEY_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: SURVEY_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const answerSurvey = (surveyInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: SURVEY_ANSWER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const send = {
			...surveyInfo,
			userId: userInfo._id,
		}

		const { data } = await axios.post(`/api/survey-answers/${surveyInfo._id}`, send, config)

		dispatch({ type: SURVEY_ANSWER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: SURVEY_ANSWER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getSurveys = () => async (dispatch, getState) => {
	try {
		dispatch({ type: SURVEY_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/surveys', config)

		dispatch({ type: SURVEY_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: SURVEY_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getSurveyById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: SURVEY_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/surveys/${id}`, config)

		dispatch({ type: SURVEY_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: SURVEY_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const surveyUpdateInfo = (survey) => async (dispatch, getState) => {
	try {
		dispatch({ type: SURVEY_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/surveys/${survey._id}`, survey, config)

		dispatch({ type: SURVEY_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: SURVEY_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteSurvey = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: SURVEY_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/surveys/${id}`, config)

		dispatch({ type: SURVEY_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: SURVEY_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAnswersByTeacher = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ANSWER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/survey-answers/teacher/${userInfo._id}`, config)

		dispatch({ type: ANSWER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: ANSWER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getAnswersBySurveyId = (surveyId) => async (dispatch, getState) => {
	try {
		dispatch({ type: ANSWER_SURVEY_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/survey-answers/survey/${surveyId}`, config)

		dispatch({ type: ANSWER_SURVEY_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: ANSWER_SURVEY_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

import { contentItemsEnd } from '../../../../assets/jss/material-ui-styles'

const styles = {
  contentItemsEnd,
  root: {
    padding: '30px',
    '& h1': {
      lineHeight: 'normal',
    },
  },
  rootSection: {
    width: '800px',
  },
}

export default styles

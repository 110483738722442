import axios from 'axios'
import {
	TEAM_GROUP_REGISTER_REQUEST,
	TEAM_GROUP_REGISTER_SUCCESS,
	TEAM_GROUP_REGISTER_FAIL,
	TEAM_GROUP_AUTOREGISTER_REQUEST,
	TEAM_GROUP_AUTOREGISTER_SUCCESS,
	TEAM_GROUP_AUTOREGISTER_FAIL,
	TEAM_GROUP_LIST_REQUEST,
	TEAM_GROUP_LIST_SUCCESS,
	TEAM_GROUP_LIST_FAIL,
	TEAM_GROUP_DETAILS_REQUEST,
	TEAM_GROUP_DETAILS_SUCCESS,
	TEAM_GROUP_DETAILS_FAIL,
	TEAM_GROUP_MY_DETAILS_REQUEST,
	TEAM_GROUP_MY_DETAILS_SUCCESS,
	TEAM_GROUP_MY_DETAILS_FAIL,
	TEAM_GROUP_UPDATE_REQUEST,
	TEAM_GROUP_UPDATE_SUCCESS,
	TEAM_GROUP_UPDATE_FAIL,
	TEAM_GROUP_DELETE_REQUEST,
	TEAM_GROUP_DELETE_SUCCESS,
	TEAM_GROUP_DELETE_FAIL,
	TEAM_GROUP_BYAGE_REQUEST,
	TEAM_GROUP_BYAGE_SUCCESS,
	TEAM_GROUP_BYAGE_FAIL,
	TEAM_GROUP_BYAGE_PREV_REQUEST,
	TEAM_GROUP_BYAGE_PREV_SUCCESS,
	TEAM_GROUP_BYAGE_PREV_FAIL,
	TEAM_GROUP_AUTOMATE_FAIL,
	TEAM_GROUP_AUTOMATE_SUCCESS,
	TEAM_GROUP_AUTOMATE_REQUEST,
	TEAM_BYAGE_NIN_TOPIC_REQUEST,
	TEAM_BYAGE_NIN_TOPIC_SUCCESS,
	TEAM_BYAGE_NIN_TOPIC_FAIL,
	TEAM_GROUP_ACTUALLY_FAIL,
	TEAM_GROUP_ACTUALLY_SUCCESS,
	TEAM_GROUP_ACTUALLY_REQUEST,
	TEAM_BYAGE_STUDENTS_LITTLE_SUCCESS,
	TEAM_BYAGE_STUDENTS_LITTLE_FAIL,
	TEAM_BYAGE_STUDENTS_LITTLE_REQUEST,
} from '../constants/teamGroupConstants'
import { logout } from './userActions'

export const registerTeamGroup = (groupInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/teamgroups', groupInfo, config)

		dispatch({ type: TEAM_GROUP_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const automateRegisterTeamGroups = (groupsInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_AUTOREGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/teamgroups/automate', groupsInfo, config)

		dispatch({
			type: TEAM_GROUP_AUTOREGISTER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_AUTOREGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getGroupsByTeamId = (teamId) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		// /team/ added to routes
		const { data } = await axios.get(`/api/teamgroups/team/${teamId}`, config)

		dispatch({
			type: TEAM_GROUP_LIST_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeamGroupById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teamgroups/${id}`, config)

		dispatch({ type: TEAM_GROUP_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getMyTeamGroup = (studentId) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_MY_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teamgroups/my/${studentId}`, config)

		dispatch({ type: TEAM_GROUP_MY_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
			window.location = '/auth/login'
		}
		dispatch({
			type: TEAM_GROUP_MY_DETAILS_FAIL,
			payload: message,
		})
	}
}

export const teamGroupUpdateInfo = (groupInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/teamgroups/${groupInfo._id}`, groupInfo, config)

		dispatch({ type: TEAM_GROUP_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteTeamGroup = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/teamgroups/${id}`, config)

		dispatch({ type: TEAM_GROUP_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeamsByAge = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_BYAGE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/teamgroups/students-age-qty', config)

		dispatch({ type: TEAM_GROUP_BYAGE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_BYAGE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeamsByAgeNinTopic = (topicId) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_BYAGE_NIN_TOPIC_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teamgroups/students-age-nin-topic/${topicId}`, config)

		dispatch({ type: TEAM_BYAGE_NIN_TOPIC_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_BYAGE_NIN_TOPIC_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeamsByAgeLittleMerge = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_BYAGE_STUDENTS_LITTLE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/teamgroups/students-by-age-little-merge', config)

		dispatch({ type: TEAM_BYAGE_STUDENTS_LITTLE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_BYAGE_STUDENTS_LITTLE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const prevTeamsByAge = (ageRange) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_BYAGE_PREV_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/teamgroups/students-age-qty-prev', ageRange, config)

		dispatch({ type: TEAM_GROUP_BYAGE_PREV_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_BYAGE_PREV_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const teamsActually = (groups, range) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_ACTUALLY_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(
			'/api/teamgroups/students-age-actually',
			{ teams: groups, ageRange: range },
			config,
		)

		dispatch({ type: TEAM_GROUP_ACTUALLY_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_ACTUALLY_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const automateTeamGroups = (groupsData) => async (dispatch, getState) => {
	try {
		dispatch({ type: TEAM_GROUP_AUTOMATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/teamgroups/automation', groupsData, config)
		dispatch({ type: TEAM_GROUP_AUTOMATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: TEAM_GROUP_AUTOMATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

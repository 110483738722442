import { BrowserRouter as Router, Route } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import AppRoute from './AppRoute'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import routesPublicSite from './routes/publicSite'
import ControlPanelLayout from './layouts/ControlPanelLayout/ControlPanelLayout'
import TeacherPanelLayout from './layouts/TeacherPanelLayout/TeacherPanelLayout'
import StudentLayout from './layouts/StudentLayout/StudentLayout'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
function App() {
  return (
    <>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        {routesPublicSite.map((route, i) => (
          <AppRoute key={i} path={route.path} layout={route.layout} component={route.comp} exact={route.exact} />
        ))}
        <Route path='/student' component={StudentLayout} />
        <Route path='/teacher' component={TeacherPanelLayout} />
        <Route path='/admin' component={ControlPanelLayout} />
        <Route path='/meet' component={SimpleLayout} />
        <Route path='/auth' component={SimpleLayout} />
      </Router>
    </>
  )
}

export default App

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Grid, makeStyles } from '@material-ui/core'
import Map from './Map'
import styles from '../styles/homeScreenStyles'

const useStyles = makeStyles(styles)

const CommunityMap = () => {
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<Grid container className={classes.sectionMapBlog}>
			<Grid item md={12} sm={12} xs={12}>
				<h3>{t('layout.t_home_section_mapBlog')}</h3>
			</Grid>
			<Grid item xs={12} sm={12} md={10} className={classNames(classes.marginMapAuto, classes.sectionMap)}>
				<Map />
			</Grid>
		</Grid>
	)
}

export default CommunityMap

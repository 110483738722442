import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerTeamPanel } from '../../../../redux/actions/teamsActions'
import styles from '../styles/teamCardFormStyles'

const useStyles = makeStyles(styles)

const TeamRegisterModal = ({ setOpen, setPanelDetail }) => {
    const [panelName, setPanelName] = useState('')
    const [partnersEndDate, setPartnersEndDate] = useState(null)

    const dispatch = useDispatch()
    const classes = useStyles()

    const { loadingTeamRegister, errorTeamRegister, successTeamRegister, team } = useSelector((state) => state.teamRegister)

    useEffect(() => {
        if (successTeamRegister) {
            setPanelDetail(team)
            setOpen(false)
            setPanelName('')
            setPartnersEndDate(null)
        }
    }, [successTeamRegister, setOpen, setPanelDetail, team])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(
            registerTeamPanel({
                panelName,
                partnersEndDate,
            })
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <p>In a Team Panel you can join at least two centers so that they can work together.</p>
                <p>The panel name must refer to the centers involved.</p>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={submitHandler}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomInput
                                labelText='Team Panel Name'
                                id='panelname'
                                formControlProps={{
                                    fullWidth: true,
                                    className: 'm-0',
                                }}
                                inputProps={{
                                    type: 'text',
                                    value: panelName,
                                    placeholder: 'Example: Salesianos - Instituto MX - Jan 2022',
                                    onChange: (e) => setPanelName(e.target.value),
                                    required: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label='Partner End Date'
                                id='partners-end-date'
                                value={partnersEndDate}
                                onChange={setPartnersEndDate}
                                format='dd/MM/yyyy'
                                className={classes.textField}
                                minDate={new Date()}
                                InputLabelProps={{
                                    shrink: true,
                                    className: classes.selectLabel,
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                            />
                        </Grid>
                        {errorTeamRegister && (
                            <Grid item xs={12}>
                                <Message message={<span>{errorTeamRegister}</span>} color='info' />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button type='submit' color='primary' block>
                                {loadingTeamRegister ? <Loader size={'sm'} /> : 'Create Team Panel'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}

export default TeamRegisterModal

import { main, mainRaised } from '../../../../assets/jss/material-ui-styles'

const styles = {
    main,
    mainRaised,
    userCardRoot: {
        margin: ' 0 auto 40px',
    },
}

export default styles

import Meta from '../../../components/Meta/Meta'

const SpeakingScreen = () => {
	return (
		<div>
			<Meta title={'Speaking - Planet Fraternity'} />
			<h1>SpeakingScreen</h1>
		</div>
	)
}

export default SpeakingScreen

import axios from 'axios'
import { ACTIVE_SURVEY_REQUEST, ACTIVE_SURVEY_SUCCESS, ACTIVE_SURVEY_FAIL } from '../constants/activeSurveyConstants'

export const activeSurvey = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTIVE_SURVEY_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/surveys/active', config)

    dispatch({ type: ACTIVE_SURVEY_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ACTIVE_SURVEY_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

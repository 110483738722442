import { useDispatch } from 'react-redux'
import { Card, CardHeader, makeStyles, Tooltip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import { getAnswersBySurveyId } from '../../../redux/actions/surveyActions'
import styles from './styles/surveyListCardStyles'

const useStyles = makeStyles(styles)

const SurveyListCard = ({ survey, setSurveyDetails, setAnswerDetails }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleAnswerSurveyDetails = (survey) => {
    dispatch(getAnswersBySurveyId(survey._id))
    setSurveyDetails(null)
  }

  return (
    <div className='user-card'>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeaderRoot}
          action={
            <CustomDropdown
              left
              caret={false}
              buttonText={
                <div className={classes.btnwrapper}>
                  <MoreVert />
                </div>
              }
              buttonProps={{
                className: classes.navLink + ' ' + classes.imageDropdownButton,
                color: 'transparent',
              }}
              dropdownList={[
                <div onClick={() => [setSurveyDetails(survey), setAnswerDetails(null)]}>View Survey</div>,
                <div onClick={() => handleAnswerSurveyDetails(survey)}>View Answers</div>,
              ]}
            />
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheaderTypographyProps={{ variant: 'caption' }}
          title={
            <Tooltip placement='left-start' title={survey.title}>
              <span>{survey.title.length > 25 ? survey.title.slice(0, 25) + '...' : survey.title}</span>
            </Tooltip>
          }
          subheader={`Questions: ${survey.questions.length}`}
        />
      </Card>
    </div>
  )
}

export default SurveyListCard

import * as types from '../constants/groupConstants'

export const groupRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GROUP_REGISTER_REQUEST:
      return { loadingGroupRegister: true }
    case types.GROUP_REGISTER_SUCCESS:
      return {
        loadingGroupRegister: false,
        successGroupRegister: true,
        group: action.payload,
      }
    case types.GROUP_REGISTER_FAIL:
      return {
        loadingGroupRegister: false,
        errorGroupRegister: action.payload,
      }
    case types.GROUP_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const groupListByCenterReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case types.GROUP_LIST_BY_CENTER_REQUEST:
      return { loadingGroupListByCenter: true }
    case types.GROUP_LIST_BY_CENTER_SUCCESS:
      return {
        loadingGroupListByCenter: false,
        groupListByCenterQty: action.payload.length,
        groups: action.payload,
      }
    case types.GROUP_LIST_BY_CENTER_FAIL:
      return {
        loadingGroupListByCenter: false,
        errorGroupListByCenter: action.payload,
      }
    case types.GROUP_LIST_BY_CENTER_RESET:
      return {}
    default:
      return state
  }
}

export const groupDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GROUP_DETAILS_REQUEST:
      return { loading: true }
    case types.GROUP_DETAILS_SUCCESS:
      return { loading: false, group: action.payload }
    case types.GROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case types.GROUP_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const groupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GROUP_UPDATE_REQUEST:
      return { loadingGroupUpdate: true }
    case types.GROUP_UPDATE_SUCCESS:
      return {
        loadingGroupUpdate: false,
        successGroupUpdate: true,
        group: action.payload,
      }
    case types.GROUP_UPDATE_FAIL:
      return {
        loadingGroupUpdate: false,
        errorGroupUpdate: action.payload,
      }
    case types.GROUP_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const groupTeacherDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GROUP_TEACHER_DELETE_REQUEST:
      return { loadingGroupTeacherDelete: true }
    case types.GROUP_TEACHER_DELETE_SUCCESS:
      return { loadingGroupTeacherDelete: false, successGroupTeacherDelete: true }
    case types.GROUP_TEACHER_DELETE_FAIL:
      return {
        loadingGroupTeacherDelete: false,
        errorGroupTeacherDelete: action.payload,
      }
    case types.GROUP_TEACHER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const groupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GROUP_DELETE_REQUEST:
      return { loadingGroupDelete: true }
    case types.GROUP_DELETE_SUCCESS:
      return { loadingGroupDelete: false, successGroupDelete: true }
    case types.GROUP_DELETE_FAIL:
      return {
        loadingGroupDelete: false,
        errorGroupDelete: action.payload,
      }
    case types.GROUP_DELETE_RESET:
      return {}
    default:
      return state
  }
}

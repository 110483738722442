import { Grid, Card, CardContent, makeStyles } from '@material-ui/core'
import { FormatListNumbered } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import Parallax from '../../../components/Parallax/Parallax'
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import MethodologyPhases from '../../../components/MethodologyPhases/MethodologyPhases'
import { useTranslation } from 'react-i18next'
import styles from './styles/actScreenStyles'

const useStyles = makeStyles(styles)

const ActScreen = ({ history }) => {
    const classes = useStyles()
    const { t } = useTranslation('global')

    return (
        <>
            <Meta title={'Act - Planet Fraternity'} />
            <Parallax image={'/assets/img/public-site/bg-act.png'}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                                <h1 className={classes.titleBig}>{t('layout.t_act')}</h1>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.sections}>
                <Grid container className={classes.sectionRoot}>
                    <Grid item xs={12} md={6} className={classes.imgRoot}>
                        <img src='/assets/img/public-site/img-act-2.png' alt='Start Action' width='100%' />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.onTopCardRoot}>
                        <Card className={classes.onTopCardRight}>
                            <CardContent>
                                <div className={classes.descRoot}>
                                    <FormatListNumbered className={classes.descIcon} />
                                    <p>{t('page.t_act_text_one')}</p>
                                    <p>{t('page.t_act_text_two')}</p>
                                    <p>{t('page.t_act_text_three')}</p>
                                    <p>{t('page.t_act_text_four')}</p>
                                    <div className={classes.descActions}>
                                        <Button link onClick={() => history.push('/contact')}>
                                            {t('layout.buttons.t_contact-us')}
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container className={classes.sectionRootWReverse}>
                    <Grid item xs={12} md={6} className={classes.onTopCardRoot}>
                        <Card className={classes.onTopCardLeft}>
                            <CardContent>
                                <div className={classes.descRoot}>
                                    <FormatListNumbered className={classes.descIcon} />
                                    <p>{t('page.t_btc_text_one')}</p>
                                    <p>{t('page.t_btc_text_two')}</p>
                                    <div className={classes.descActions}>
                                        <Button link onClick={() => history.push('/how-it-works')}>
                                            {t('layout.buttons.t_more-info')}
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.imgRoot}>
                        <img src='/assets/img/public-site/img-act.png' alt='Actions' width='100%' />
                    </Grid>
                </Grid>
            </div>
            <MethodologyPhases />
        </>
    )
}

export default ActScreen

export const COUNTRY_POST_LIST_REQUEST = 'COUNTRY_POST_LIST_REQUEST'
export const COUNTRY_POST_LIST_SUCCESS = 'COUNTRY_POST_LIST_SUCCESS'
export const COUNTRY_POST_LIST_FAIL = 'COUNTRY_POST_LIST_FAIL'
export const COUNTRY_POST_LIST_RESET = 'COUNTRY_POST_LIST_RESET'

export const COUNTRY_POST_ACTIVE_LIST_REQUEST = 'COUNTRY_POST_ACTIVE_LIST_REQUEST'
export const COUNTRY_POST_ACTIVE_LIST_SUCCESS = 'COUNTRY_POST_ACTIVE_LIST_SUCCESS'
export const COUNTRY_POST_ACTIVE_LIST_FAIL = 'COUNTRY_POST_ACTIVE_LIST_FAIL'
export const COUNTRY_POST_ACTIVE_LIST_RESET = 'COUNTRY_POST_ACTIVE_LIST_RESET'

export const COUNTRY_POST_INACTIVE_LIST_REQUEST = 'COUNTRY_POST_INACTIVE_LIST_REQUEST'
export const COUNTRY_POST_INACTIVE_LIST_SUCCESS = 'COUNTRY_POST_INACTIVE_LIST_SUCCESS'
export const COUNTRY_POST_INACTIVE_LIST_FAIL = 'COUNTRY_POST_INACTIVE_LIST_FAIL'
export const COUNTRY_POST_INACTIVE_LIST_RESET = 'COUNTRY_POST_INACTIVE_LIST_RESET'

export const COUNTRY_POST_DELETE_REQUEST = 'COUNTRY_POST_DELETE_REQUEST'
export const COUNTRY_POST_DELETE_SUCCESS = 'COUNTRY_POST_DELETE_SUCCESS'
export const COUNTRY_POST_DELETE_FAIL = 'COUNTRY_POST_DELETE_FAIL'
export const COUNTRY_POST_DELETE_RESET = 'COUNTRY_POST_DELETE_RESET'

export const COUNTRY_POST_CREATE_REQUEST = 'COUNTRY_POST_CREATE_REQUEST'
export const COUNTRY_POST_CREATE_SUCCESS = 'COUNTRY_POST_CREATE_SUCCESS'
export const COUNTRY_POST_CREATE_FAIL = 'COUNTRY_POST_CREATE_FAIL'
export const COUNTRY_POST_CREATE_RESET = 'COUNTRY_POST_CREATE_RESET'

export const COUNTRY_POST_UPDATE_REQUEST = 'COUNTRY_POST_UPDATE_REQUEST'
export const COUNTRY_POST_UPDATE_SUCCESS = 'COUNTRY_POST_UPDATE_SUCCESS'
export const COUNTRY_POST_UPDATE_FAIL = 'COUNTRY_POST_UPDATE_FAIL'
export const COUNTRY_POST_UPDATE_RESET = 'COUNTRY_POST_UPDATE_RESET'

export const COUNTRY_POST_VERIFY_POSTED_REQUEST = 'COUNTRY_POST_VERIFY_POSTED_REQUEST'
export const COUNTRY_POST_VERIFY_POSTED_SUCCESS = 'COUNTRY_POST_VERIFY_POSTED_SUCCESS'
export const COUNTRY_POST_VERIFY_POSTED_FAIL = 'COUNTRY_POST_VERIFY_POSTED_FAIL'
export const COUNTRY_POST_VERIFY_POSTED_RESET = 'COUNTRY_POST_VERIFY_POSTED_RESET'

export const statusFilterInterface = (status) => {
	return {
		request: status ? COUNTRY_POST_ACTIVE_LIST_REQUEST : COUNTRY_POST_INACTIVE_LIST_REQUEST,
		success: status ? COUNTRY_POST_ACTIVE_LIST_SUCCESS : COUNTRY_POST_INACTIVE_LIST_SUCCESS,
		fail: status ? COUNTRY_POST_ACTIVE_LIST_FAIL : COUNTRY_POST_INACTIVE_LIST_FAIL,
		reset: status ? COUNTRY_POST_ACTIVE_LIST_RESET : COUNTRY_POST_INACTIVE_LIST_RESET,
	}
}

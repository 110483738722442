const styles = {
  root: {
    padding: '10px 15px 5px',
    marginBottom: 10,
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 1px rgb(0 0 0 / 12%)',
  },
  countryFlagProfile: {
    width: '26px',
    borderRadius: '5px',
  },
}
export default styles

import { CardHeader, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown.js'
import styles from '../styles/finalProjectCardStyles.js'

const useStyles = makeStyles(styles)

const FinalProjectsCard = ({ project, panel, setUpdate, setProjectUpdate, setDeleteProject, setOpenDelete }) => {
  const classes = useStyles()

  return (
    <CardHeader
      action={
        <div className={classes.panelRoot}>
          <CustomDropdown
            left
            caret={false}
            buttonText={
              <div className={classes.btnwrapper}>
                <MoreVert />
              </div>
            }
            buttonProps={{
              className: classes.navLink + ' ' + classes.imageDropdownButton,
              color: 'transparent',
            }}
            dropdownList={[
              <div onClick={() => [setUpdate(true), setProjectUpdate({ project, idTeam: panel._id })]}>
                Update Info
              </div>,
              <div onClick={() => [setDeleteProject({ project: project, id: panel._id }), setOpenDelete(true)]}>
                Delete
              </div>,
            ]}
          />
        </div>
      }
      titleTypographyProps={{ variant: 'h6' }}
      title={`Project - ${project.group.name}`}
    />
  )
}

export default FinalProjectsCard

const styles = {
	root: {
		justifyContent: 'center',
		width: '100%',
		height: '500px',
	},
	sectionLittlePlanetFraternity: {
		backgroundColor: '#fff',
		marginTop: '90px',
		padding: '50px',
		textAlign: 'center',
		'& h3': {
			margin: '45px 0px',
			fontSize: '25px',
		},
	},
	little: {
		color: '#ff5280',
		fontSize: '6em',
		fontFamily: 'WindSong',
		position: 'absolute',
		zIndex: '1',
		transform: 'translateX(370%) translateY(-50%) rotate(-10deg)',
	},

	title: {
		color: 'blue',
		fontSize: '5em',
		textTransform: 'uppercase',
		fontFamily: 'Rammetto One',
	},
	team: {
		fontSize: '4em',
		color: 'black',
		fontFamily: 'Handlee',
	},
	text: {
		fontSize: '2em',
		color: '#ff9500',
		fontWeight: 'bold',
	},
	heart: {
		position: 'absolute',
		zIndex: '1',
		width: '30px',
		transform: 'translateX(1100%) translateY(-370%) rotate(30deg)',
	},
	image: {
		marginTop: '10px',
		width: '60%',
	},
	imageLittlePlanetFraternity: {
		width: '15%',
	},
	imageLittlePlanetFraternity: {
		width: '18%',
		borderRadius: '10px',
	},
}

const mediaQuery = '@media (min-width: 1200px)'
const mediaQueryTabletXl = '@media (max-width: 1199px)'
const mediaQueryTabletL = '@media (max-width: 1024px)'
const mediaQueryTablet = '@media (max-width: 768px)'
const mediaQueryMobileXl = '@media (max-width: 678px)'
const mediaQueryMobile = '@media (max-width: 480px)'

const desktopStyles = {
	image: {
		width: '80%',
		marginLeft: '10px',
	},
	title: {
		fontSize: '4em',
	},
	little: {
		fontSize: '5em',
		transform: 'translateX(300%) translateY(-50%) rotate(-10deg)',
	},
	heart: {
		position: 'absolute',
		zIndex: '1',
		width: '25px',
		transform: 'translateX(1040%) translateY(-380%) rotate(30deg)',
	},
	team: {
		fontSize: '3em',
	},
}
const tabletXlStyles = {
	heart: {
		width: '25px',
		transform: 'translateX(600%) translateY(-450%) rotate(30deg)',
	},
	little: {
		transform: 'translateX(150%) translateY(-50%) rotate(-10deg)',
	},
	image: {
		width: '90%',
	},
	imageLittlePlanetFraternity: {
		width: '38%',
	},
}
const tabletLStyles = {
	heart: {
		width: '25px',
		transform: 'translateX(600%) translateY(-450%) rotate(30deg)',
	},
	little: {
		transform: 'translateX(100%) translateY(-50%) rotate(-10deg)',
	},
	image: {
		width: '90%',
	},
	imageLittlePlanetFraternity: {
		width: '38%',
	},
}
const tabletStyles = {
	heart: {
		width: '20px',
		transform: 'translateX(970%) translateY(-370%) rotate(30deg)',
	},
	little: {
		fontSize: '3em',
		transform: 'translateX(100%) translateY(-50%) rotate(-10deg)',
	},
	title: {
		fontSize: '3em',
	},
	image: {
		width: '90%',
	},
	team: {
		fontSize: '2em',
	},
	text: {
		fontSize: '1.5em',
	},
	imageLittlePlanetFraternity: {
		width: '38%',
	},
}
const mobileXlStyles = {
	heart: {
		width: '20px',
		transform: 'translateX(400%) translateY(-370%) rotate(30deg)',
	},
	little: {
		fontSize: '3em',
		transform: 'translateX(100%) translateY(-50%) rotate(-10deg)',
	},
	imageLittlePlanetFraternity: {
		width: '38%',
	},
}
const mobileStyles = {
	heart: {
		width: '15px',
		transform: 'translateX(380%) translateY(-360%) rotate(30deg)',
	},
	image: {
		width: '100%',
	},
	imageLittlePlanetFraternity: {
		width: '38%',
	},
	little: {
		fontSize: '2em',
		transform: 'translateX(150%) translateY(-50%) rotate(-10deg)',
	},
	title: {
		fontSize: '2em',
	},
	team: {
		fontSize: '1.5em',
	},
	text: {
		fontSize: '1.5em',
	},
}
const mergedStyles = {
	...styles,
	[mediaQuery]: desktopStyles,
	[mediaQueryTabletXl]: tabletXlStyles,
	[mediaQueryTabletL]: tabletLStyles,
	[mediaQueryTablet]: tabletStyles,
	[mediaQueryMobileXl]: mobileXlStyles,
	[mediaQueryMobile]: mobileStyles,
}

export default mergedStyles

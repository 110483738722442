import { useState } from 'react'
import classNames from 'classnames'
import { Card, Grid, Link, makeStyles, Tooltip } from '@material-ui/core'
import { NotificationImportant, PlayCircleFilled } from '@material-ui/icons'
import CardBody from '../../../../components/Card/CardBody'
import CustomModal from '../../../../components/Modal/CustomModal'
import TestimonialModal from './TestimonialModal'
import styles from '../styles/notificationCardStyles'

const useStyles = makeStyles(styles)

const NotificationCard = ({ history, activeSurveys, isActiveSurveys, testimonial, isTestimonial }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleSurvey = (survey) => {
    history.push(`/teacher/profile/survey/${survey._id}`)
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classNames(classes.main, classes.mainRaised)}>
          <CardBody profile className={classes.cardBodyRoot}>
            <Grid container spacing={2}>
              <Grid item md={12} className={classes.mainSection}>
                <div className={classes.sectionTitle}>
                  <h3>Notifications</h3>
                  <NotificationImportant />
                </div>
              </Grid>
              {isActiveSurveys && (
                <>
                  <hr />
                  <h4>Answers Surveys</h4>
                  <Grid item md={12}>
                    {activeSurveys.map((survey) => (
                      <div key={survey._id} className={classes.notificationRoot}>
                        <PlayCircleFilled />
                        <Tooltip title={survey.title} placement='right'>
                          <Link
                            component='button'
                            variant='body2'
                            underline='always'
                            onClick={() => handleSurvey(survey)}
                          >
                            {survey.title.length > 35 ? survey.title.slice(0, 35) + '...' : survey.title}
                          </Link>
                        </Tooltip>
                      </div>
                    ))}
                  </Grid>
                </>
              )}
              {isTestimonial && (
                <>
                  <hr />
                  <h4>Add Testimonial</h4>
                  <Grid item md={12} sm={12} className={classes.testimonialRoot}>
                    <div className={classes.notificationRoot}>
                      <PlayCircleFilled />
                      <Link component='button' variant='body2' underline='always' onClick={() => setOpen(true)}>
                        Create Testimonial
                      </Link>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <CustomModal title='Create Testimonial' open={open} setOpen={setOpen} actions={false}>
        <TestimonialModal setOpen={setOpen} history={history} />
      </CustomModal>
    </Grid>
  )
}

export default NotificationCard

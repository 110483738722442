import { Group } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import styles from '../../styles/topicRightSectionStyles'

const useStyles = makeStyles(styles)

const TopicRightSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.rightSection}>
      <Group className={classes.peoplesIcon} />
      <h2>Select topic's names to preview their info.</h2>
    </div>
  )
}

export default TopicRightSection

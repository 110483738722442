const styles = {
	root: {
		height: '90vh',
	},
	containerUserLogin: {
		width: '100%',
		marginRight: '150px',
	},
	colLogo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: 30,
		'& img': {
			width: 400,
		},
		'& h1': {
			padding: 0,
			fontSize: '23px',
			margin: '0 85px',
			textAlign: 'center',
			lineHeight: 1.3,
		},
	},
	colLoginForm: {
		padding: 50,
		display: 'flex',
		alignItems: 'center',
	},
	btnLink: {
		fontSize: 12,
		fontWeight: '500',
		color: '#483d3d',
		margin: '15px 0',
		padding: 0,
		textTransform: 'none',
		'& span': {
			fontSize: 14,
		},
	},
	messageError: {
		textAlign: 'center',
		color: 'red',
		margin: '20px 0',
	},
	contactUs: {
		marginTop: 20,
		textAlign: 'center',
	},
	'@media screen and (max-width: 1030px)': {
		containerUserLogin: {
			margin: '0 30px',
		},
	},
	'@media screen and (max-width: 960px)': {
		colLoginForm: {
			justifyContent: 'center',
		},
		containerUserLogin: {
			margin: 'auto',
		},
		colLogo: {
			'& img': {
				width: 300,
			},
			'& h1': {
				fontSize: 23,
				margin: 0,
			},
		},
	},
	'@media screen and (max-width: 480px)': {
		colLogo: {
			padding: '10px 50px',
			'& img': {
				width: 100,
			},
			'& h1': {
				lineHeight: 'normal',
			},
		},
	},
	button: {
		width: '100%',
		boxShadow:
			'0 2px 2px 0 rgb(156 39 176 / 14%), 0 3px 1px -2px rgb(156 39 176 / 20%), 0 1px 5px 0 rgb(156 39 176 / 12%)',
		backgroundColor: '#9c27b0',
		minWidth: 'auto',
		minHeight: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		border: 'none',
		cursor: 'pointer',
		margin: ' 0.3125rem 1px',
		padding: '12px 30px',
		position: 'relative',
		fontSize: '12px',
		textAlign: 'center',
		transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
		fontWeight: 400,
		lineHeight: 1.42857143,
		whiteSpace: 'nowrap',
		willChange: 'box-shadow, transform',
		touchAction: 'manipulation',
		borderRadius: '3px',
		letterSpacing: 0,
		textTransform: 'uppercase',
		verticalAlign: 'middle',
		'&:hover,&:focus': {
			boxShadow: '0px 10px 20px -2px #9c27b1',
			backgroundColor: '#9c27b0',
		},
	},
	errorMessage: {
		color: 'red',
		fontSize: '12px',
		width: '100%',
		textAlign: 'center',
	},
}

export default styles

import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import OwlCarousel from 'react-owl-carousel-rtl'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import { owlOptions } from '../config/owl-config'
import styles from '../styles/homeScreenStyles'

const useStyles = makeStyles(styles)

const WhatHowWhenWhyCarousel = () => {
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<Grid container className={classes.mainCarouselSection}>
			<Grid className={classes.carouselSectionOne} item md={6} sm={6} xs={12}>
				<div className={classes.sliderRoot}>
					<section id='carousel1' className='testimonials pt-70 pb-70 px2'>
						<div className='container'>
							<Grid container>
								<Grid item md={12} xs={12}>
									<OwlCarousel
										autoplayTimeout={8000}
										id='customer-testimonoals'
										className={classNames(classes.customerCarousels, 'owl-carousel owl-theme')}
										{...owlOptions}
									>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/que1.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/que2.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/que3.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/que4.jpg'} />
											</div>
										</div>
									</OwlCarousel>
								</Grid>
							</Grid>
						</div>
					</section>
				</div>
			</Grid>
			<Grid item md={6} sm={6} xs={12} className={classNames(classes.carouselSectionOne, classes.paddingMax)}>
				<h4>{t('layout.t_home_carouselOne')}</h4>
				<p>{t('layout.t_home_carouselParagraphOne')}</p>
				<div style={{ textAlign: 'end' }}>
					<CustomDropdown
						noLiPadding
						buttonText={`${t('layout.buttons.t_more-info')}`}
						buttonProps={{
							className: classes.navLink,
							color: 'primary',
						}}
						dropdownList={[
							<div onClick={() => window.open('/assets/files/home/what-english2.pdf')} className={classes.dropdownLink}>
								English
							</div>,
							<div onClick={() => window.open('/assets/files/home/what-spanish2.pdf')} className={classes.dropdownLink}>
								Español
							</div>,
							<div onClick={() => window.open('/assets/files/home/what-french2.pdf')} className={classes.dropdownLink}>
								Français
							</div>,
							<div
								onClick={() => window.open('/assets/files/home/what-portuguese.pdf')}
								className={classes.dropdownLink}
							>
								Português
							</div>,
						]}
					/>
				</div>
			</Grid>
			<Grid className={classes.carouselSectionTwo} item md={6} sm={6} xs={12}>
				<div className={classes.sliderRoot}>
					<section id='carousel2' className='testimonials pt-70 pb-70'>
						<div className='container'>
							<Grid container>
								<Grid item md={12} xs={12}>
									<OwlCarousel
										autoplayTimeout={8000}
										rtl={true}
										id='customer-testimonoals'
										className={classNames(classes.customerCarousels, 'owl-carousel owl-theme')}
										{...owlOptions}
									>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/como1.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/como2.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/como3.jpg'} />
											</div>
										</div>
									</OwlCarousel>
								</Grid>
							</Grid>
						</div>
					</section>
				</div>
			</Grid>
			<Grid item md={6} sm={6} xs={12} className={classNames(classes.carouselSectionTwo, classes.paddingMax)}>
				<h4>{t('layout.t_home_carouselTwo')}</h4>
				<p>{t('layout.t_home_carouselParagraphTwo')}</p>
			</Grid>
			<Grid className={classes.carouselSectionThree} item md={6} sm={6} xs={12}>
				<div className={classes.sliderRoot}>
					<section id='carousel3' className='testimonials pt-70 pb-70'>
						<div className='container'>
							<Grid container>
								<Grid item md={12} xs={12}>
									<OwlCarousel
										autoplayTimeout={8000}
										id='customer-testimonoals'
										className={classNames(classes.customerCarousels, 'owl-carousel owl-theme')}
										{...owlOptions}
									>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/cuando1.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/cuando2.png'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/cuando3.png'} />
											</div>
										</div>
									</OwlCarousel>
								</Grid>
							</Grid>
						</div>
					</section>
				</div>
			</Grid>
			<Grid item md={6} sm={6} xs={12} className={classNames(classes.carouselSectionThree, classes.paddingMax)}>
				<h4>{t('layout.t_home_carouselThree')}</h4>
				<p>{t('layout.t_home_carouselParagraphThree')}</p>
			</Grid>
			<Grid className={classes.carouselSectionFour} item md={6} sm={6} xs={12}>
				<div className={classes.sliderRoot}>
					<section id='carousel3' className='testimonials pt-70 pb-70'>
						<div className='container'>
							<Grid container>
								<Grid item md={12} xs={12}>
									<OwlCarousel
										autoplayTimeout={8000}
										id='customer-testimonoals'
										className={classNames(classes.customerCarousels, 'owl-carousel owl-theme')}
										{...owlOptions}
									>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/why1.jpeg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/why2.jpg'} />
											</div>
										</div>
										<div className={classNames(classes.carouselItem, 'item')}>
											<div className={classes.shadowEffect}>
												<img alt='oneOne' src={'/assets/img/carrousel/why3.jpeg'} />
											</div>
										</div>
									</OwlCarousel>
								</Grid>
							</Grid>
						</div>
					</section>
				</div>
			</Grid>
			<Grid item md={6} sm={6} xs={12} className={classNames(classes.carouselSectionFour, classes.paddingMax)}>
				<h4>{t('layout.t_home_carouselFour')}</h4>
				<p>{t('layout.t_home_carouselParagraphFour')}</p>
			</Grid>
		</Grid>
	)
}

export default WhatHowWhenWhyCarousel

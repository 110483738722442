import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
	Grid,
	FormControlLabel,
	FormControl,
	Switch,
	Select,
	InputLabel,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	makeStyles,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import format from 'date-fns/format'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import {
	centerUpdateInfo,
	getCenterById,
	activateCenterSubscription,
	deleteCenter,
} from '../../../../redux/actions/centerActions'
import {
	CENTER_DELETE_RESET,
	CENTER_UPDATE_RESET,
	CENTER_DETAILS_RESET,
	CENTER_ACTIVATE_RESET,
} from '../../../../redux/constants/centerConstants'
import { getCountriesList } from '../../../../redux/actions/countryActions'
import { groupCategories } from '../../../../redux/constants/groupConstants'
import styles from '../styles/centerCardFormStyles'

const useStyles = makeStyles(styles)

const CenterCardForm = ({ center, setCenterDetail, centerGroupsList }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()

	const [name, setName] = useState('')
	const [schoolYearStart, setSchoolYearStart] = useState('')
	const [schoolYearFinish, setSchoolYearFinish] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [address, setAddress] = useState('')
	const [principalName, setPrincipalName] = useState('')
	const [principalEmail, setPrincipalEmail] = useState('')
	const [principalPhone, setPrincipalPhone] = useState('')
	const [studentsQty, setStudentsQty] = useState('')
	const [licenseExp, setLicenseExp] = useState('')
	const [countryId, setCountryId] = useState('')
	const [countryName, setCountryName] = useState('')
	const [countryFlag, setCountryFlag] = useState('')
	const [isCatholic, setIsCatholic] = useState(false)
	const [isForTest, setIsForTest] = useState(false)
	const [openActivate, setOpenActivate] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [successUpdated, setSuccessUpdate] = useState(false)
	const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(true)
	const [phrase, setPhrase] = useState('')

	const { loadingCenterUpdate, successCenterUpdate } = useSelector((state) => state.centerUpdate)
	const { loadingCenterDelete, successCenterDelete, errorCenterDelete } = useSelector((state) => state.centerDelete)
	const { loadingCountryList, errorCountryList, countries } = useSelector((state) => state.countryList)
	const { loadingCenterActivate, errorCenterActivate, successCenterActivate } = useSelector(
		(state) => state.centerActivate,
	)

	useEffect(() => {
		if (successCenterActivate) {
			setOpenActivate(false)
			dispatch({ type: CENTER_ACTIVATE_RESET })
			history.push(`/admin/centers/manage/${center._id}`)
		}
	}, [dispatch, history, center, successCenterActivate])
	useEffect(() => {
		if (countries?.length < 1) {
			dispatch(getCountriesList())
		}
	}, [dispatch, countries])
	useEffect(() => {
		if (center) {
			setName(center.name)
			setSchoolYearStart(center.schoolYear.start || '')
			setSchoolYearFinish(center.schoolYear.finish || '')
			setEmail(center.email || '')
			setPhone(center.phone || '')
			setAddress(center.address || '')
			setPrincipalName(center.schoolPrincipal.name || '')
			setPrincipalEmail(center.schoolPrincipal.email || '')
			setPrincipalPhone(center.schoolPrincipal.phone || '')
			setStudentsQty(center.license.studentsQty || '')
			setLicenseExp(format(new Date(center.license.expiration), 'yyyy-MM-dd') || '')
			setCountryId(center.country._id || '')
			setCountryName(center.country.name || '')
			setCountryFlag(center.country.flag || '')
			setIsCatholic(center.isCatholic)
			setIsForTest(center.isForTest)
		}
	}, [center])
	useEffect(() => {
		if (successCenterUpdate) {
			setSuccessUpdate(true)
			setTimeout(() => {
				setSuccessUpdate(false)
			}, 2000)
		}
	}, [successCenterUpdate])
	useEffect(() => {
		if (successCenterDelete) {
			setTimeout(() => {
				dispatch({ type: CENTER_DETAILS_RESET })
				dispatch({ type: CENTER_DELETE_RESET })
				setOpenDelete(false)
				setCenterDetail(null)
			}, 2000)
		}
	}, [successCenterDelete])
	useEffect(() => {
		if (phrase === 'absolutely sure') {
			setDeleteBtnDisabled(false)
		} else {
			setDeleteBtnDisabled(true)
		}
	}, [phrase])

	const submitEditFormHandler = (e) => {
		e.preventDefault()

		const updateCenter = {
			_id: center._id,
			name,
			schoolYear: {
				start: schoolYearStart,
				finish: schoolYearFinish,
			},
			email,
			phone,
			address,
			schoolPrincipal: {
				name: principalName,
				email: principalEmail,
				phone: principalPhone,
			},
			license: {
				studentsQty,
				expiration: licenseExp,
			},
			isCatholic,
			country: {
				_id: countryId,
				name: countryName,
				flag: countryFlag,
			},
			isForTest,
		}
		dispatch(centerUpdateInfo(updateCenter))

		setTimeout(() => {
			dispatch(getCenterById(center._id))
			dispatch({ type: CENTER_UPDATE_RESET })
		}, 3000)
	}
	const activateSubscriptionHandler = () => {
		dispatch(activateCenterSubscription(center._id, centerGroupsList))
	}
	const deleteCenterHandler = () => {
		dispatch(deleteCenter(center._id))
	}
	const countryHandler = (id) => {
		setCountryId(id)
		const thisCountry = countries.find((country) => country._id === id)
		setCountryName(thisCountry.name)
		setCountryFlag(thisCountry.flag)
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<form onSubmit={submitEditFormHandler} autoComplete='off'>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<CustomInput
								labelText='Name'
								id={'name' + center.name}
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: name,
									placeholder: 'Enter complete name',
									onChange: (e) => setName(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='School Year Start Month'
								id='school-start'
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: schoolYearStart,
									placeholder: 'School Year Start Month',
									onChange: (e) => setSchoolYearStart(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='School Year Finish Month'
								id='school-finish'
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: schoolYearFinish,
									placeholder: 'School Year Finish Month',
									onChange: (e) => setSchoolYearFinish(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='Email'
								id={'email' + center.name}
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'email',
									value: email,
									placeholder: 'Enter a valid email',
									onChange: (e) => setEmail(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								labelText='Phone'
								id={'phone' + center.name}
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: phone,
									placeholder: 'E.g. +00 000000000',
									onChange: (e) => setPhone(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<CustomInput
								labelText='Address'
								id={'address' + center.name}
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: address,
									placeholder: 'Enter address (Optional)',
									onChange: (e) => setAddress(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<CustomInput
								labelText='Students Qty'
								id={'students-qty' + center.name}
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'number',
									value: studentsQty,
									placeholder: 'License students qty',
									onChange: (e) => setStudentsQty(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<DatePicker
								label='License Expiration'
								value={licenseExp}
								onChange={setLicenseExp}
								format='dd/MM/yyyy'
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
									className: classes.selectLabel,
								}}
								InputProps={{
									classes: {
										input: classes.resize,
									},
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							{loadingCountryList ? (
								<Loader />
							) : errorCountryList ? (
								<Message message={<span>{errorCountryList}</span>} color='info' />
							) : (
								<FormControl className={classes.formControl}>
									<InputLabel id='country' className={classes.selectLabel}>
										Country
									</InputLabel>
									<Select
										labelId='country'
										id='country'
										value={countryId}
										onChange={(e) => countryHandler(e.target.value)}
									>
										<MenuItem value='' disabled>
											Select a Country
										</MenuItem>
										{!loadingCountryList &&
											countries.map((country) => (
												<MenuItem key={country._id} value={country._id}>
													{country.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
						</Grid>
						<Grid item xs={4}>
							<CustomInput
								labelText='Principal Name'
								id='principal-name'
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: principalName,
									placeholder: 'Complete principal name',
									onChange: (e) => setPrincipalName(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<CustomInput
								labelText='Principal Email'
								id='principal-email'
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'email',
									value: principalEmail,
									placeholder: 'Enter valid Email',
									onChange: (e) => setPrincipalEmail(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<CustomInput
								labelText='Principal Phone'
								id='principal-phone'
								formControlProps={{
									fullWidth: true,
									className: classes.inputItem,
								}}
								inputProps={{
									type: 'text',
									value: principalPhone,
									placeholder: 'E.g. +34 000 000000',
									onChange: (e) => setPrincipalPhone(e.target.value),
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormControlLabel
								control={
									<Switch
										checked={isForTest}
										onChange={(e) => setIsForTest(e.target.checked)}
										name='isForTest'
										color='primary'
									/>
								}
								label='Is For Test'
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormControlLabel
								control={
									<Switch
										checked={isCatholic}
										onChange={(e) => setIsCatholic(e.target.checked)}
										name='isCatholic'
										color='secondary'
									/>
								}
								label='Is Catholic'
							/>
						</Grid>
						{!center.isInternal && (
							<Grid item xs={12}>
								<h5>Total suggested Donation is {centerGroupsList.totalDonation} €</h5>
							</Grid>
						)}
						<Grid item xs={12} sm={12} md={5}>
							<Button type='button' color='warning' block onClick={() => setOpenActivate(true)}>
								Activate Subscription
							</Button>
						</Grid>
						<Grid item xs={8} sm={6} md={5}>
							<Button
								type='submit'
								color={successUpdated ? 'success' : 'primary'}
								block
								disabled={successUpdated && true}
							>
								{loadingCenterUpdate ? (
									<Loader size={'sm'} />
								) : successUpdated ? (
									'Updated Successfully'
								) : (
									'Update Center'
								)}
							</Button>
						</Grid>
						<Grid item xs={4} sm={6} md={2}>
							<Button color='danger' block onClick={() => setOpenDelete(true)}>
								{loadingCenterDelete ? <Loader size={'sm'} /> : 'Delete'}
							</Button>
						</Grid>
						{errorCenterDelete && (
							<Grid item xs={12}>
								<Message message={<span>{errorCenterDelete}</span>} color='info' />
							</Grid>
						)}
					</Grid>
				</form>
			</Grid>
			<CustomModal title='Activate Subscription' open={openActivate} setOpen={setOpenActivate} actions={false}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<p>
							You are about to activate the subscription of <strong>{center.name}</strong>
						</p>
						<p>The system will create the information below</p>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Paper}>
							<Table className={classes.table} size='small' aria-label='a dense table'>
								<TableHead className={classes.tableHeadBackground}>
									<TableRow>
										<TableCell>Group Name</TableCell>
										<TableCell>No. of Students</TableCell>
										<TableCell>Ages Range</TableCell>
										<TableCell>Teachers Name</TableCell>
										<TableCell>Teachers Email</TableCell>
										<TableCell>Group category</TableCell>
										<TableCell>Teachers Phone</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{centerGroupsList.groupsInfo.map((group, index) => (
										<TableRow key={index}>
											<TableCell component='th' scope='row'>
												{group.groupName}
											</TableCell>
											<TableCell>{group.noOfStudents}</TableCell>
											<TableCell>{group.agesRange}</TableCell>
											<TableCell>{group.teacherName}</TableCell>
											<TableCell>{group.teacherEmail}</TableCell>
											<TableCell>
												{groupCategories.find((d) => d.value === group.category)?.label ?? 'Planet Fraternity'}
											</TableCell>
											<TableCell>{group.teacherPhone}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12}>
						<p>Then you can find this center in the main Center List.</p>
					</Grid>
					<Grid item xs={5} sm={6} md={6}>
						<Button color='info' block onClick={() => setOpenActivate(false)}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={7} sm={6} md={6}>
						<Button color='primary' block onClick={activateSubscriptionHandler}>
							{loadingCenterActivate ? <Loader size='sm' /> : 'Activate Now'}
						</Button>
					</Grid>
					{errorCenterActivate && (
						<Grid item xs={12}>
							<Message message={<span>{errorCenterActivate}</span>} color='info' />
						</Grid>
					)}
				</Grid>
			</CustomModal>
			<CustomModal title='Danger Zone' open={openDelete} setOpen={setOpenDelete} actions={false}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<p>
							Are you sure you want to delete this center? <strong>{center.name}</strong>
						</p>
						<p>You are about to permanently delete this center</p>
						<p>
							Once a center is permanently deleted, it cannot be recovered. Permanently deleting this center will
							immediately <strong>delete its related users and resources</strong> (if there are any).
						</p>
						<p>Please type the phrase below to enable delete button.</p>
						<p className={classes.deletePhrase}>absolutely sure</p>
					</Grid>
					<Grid item xs={12}>
						<CustomInput
							id={'absolutelysure' + center.name}
							inputProps={{
								autoFocus: true,
								placeholder: 'Type the phrase here',
								type: 'text',
								autoComplete: 'off',
								onChange: (e) => setPhrase(e.target.value),
							}}
							formControlProps={{
								fullWidth: true,
							}}
						/>
					</Grid>
					<Grid item xs={5} sm={6} md={6}>
						<Button color='primary' block onClick={() => setOpenDelete(false)}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={7} sm={6} md={6}>
						<Button
							disabled={deleteBtnDisabled}
							color={successCenterDelete ? 'success' : 'danger'}
							block
							onClick={deleteCenterHandler}
						>
							{loadingCenterDelete ? <Loader size='sm' /> : successCenterDelete ? 'Center Deleted' : 'Delete Center'}
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
		</Grid>
	)
}

export default CenterCardForm

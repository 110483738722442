import { textCenter } from '../../../../assets/jss/material-ui-styles'

const styles = {
    textCenter,
    formTitle: {
        margin: 0,
        padding: '10px 0 0 0',
        textAlign: 'center',
    },
    formControl: {
        margin: 0,
    },
    defPassMsg: {
        padding: '20px 0',
        margin: 0,
    },
}
export default styles

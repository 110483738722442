import Meta from '../../../components/Meta/Meta'

const WritingWinnersScreen = () => {
	return (
		<div>
			<Meta title={'Writing Winner List - Planet Fraternity'} />
			<h1>WritingWinnersScreen</h1>
		</div>
	)
}

export default WritingWinnersScreen

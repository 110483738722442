const CPFooter = () => {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='text-center'>Copyright © {1900 + new Date().getYear()} Planet Fraternity</div>
			</div>
		</footer>
	)
}

export default CPFooter

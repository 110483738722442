import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { People, Book, Dashboard } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Message from '../../../components/Message/Message'
import Card from '../../../components/Card/Card'
import CustomTabs from '../../../components/CustomTabs/CustomTabs'
import CustomModal from '../../../components/Modal/CustomModal'
import Button from '../../../components/Buttons/Button'
import Loader from '../../../components/Loader/Loader'
import UpdateProjectModal from './components/UpdateProjectModal'
import SchoolTeamGroups from './components/SchoolTeamGroups'
import DeleteFinalProjectModal from './components/DeleteFinalProjectModal'
import CreateFinalProject from './components/CreateFinalProject'
import FinalProjectsCard from './components/FinalProjectsCard'
import TeamsInfoCard from './components/TeamsInfoCard'
import { getMyTeamPanel, teamDeleteFinalProject } from '../../../redux/actions/teamsActions'
import { TEAM_PROJECT_UPDATE_RESET, TEAM_PROJECT_DELETE_RESET } from '../../../redux/constants/teamConstants'
import styles from './styles/schoolTeamsScreenStyles'

const useStyles = makeStyles(styles)

const SchoolTeamsScreen = ({ history }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [group, setGroup] = useState('')
	const [project, setProject] = useState(null)
	const [idTeam, setIdTeam] = useState(null)
	const [deleteProject, setDeleteProject] = useState(null)
	const [projectUpdate, setProjectUpdate] = useState(null)
	const [notCenter, setNotCenter] = useState(false)
	const [open, setOpen] = useState(false)
	const [update, setUpdate] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [teamGroups, setTeamGroups] = useState([])
	const [finalProjectsComplete, setFinalProjectsComplete] = useState(false)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingMyTeamPanel, errorMyTeamPanel, myTeamPanelInfo } = useSelector((state) => state.teamMyDetails)
	const { successTeamProject } = useSelector((state) => state.teamProject)
	const { successTeamProjectDelete } = useSelector((state) => state.teamDeleteProject)
	const { successTeamProjectUpdate } = useSelector((state) => state.teamUpdateProject)

	useEffect(() => {
		if (project) {
			if (project.projects.length > 0) {
				const someTeamGroups = project.teamGroups.filter((team) =>
					project.projects.some((data) => team._id === data.group._id),
				)
				if (someTeamGroups) {
					const teamGroupsData = project.teamGroups.filter((team) => !someTeamGroups.includes(team))
					if (teamGroupsData.length < 1) {
						setFinalProjectsComplete(true)
					} else {
						setFinalProjectsComplete(false)
					}
					setTeamGroups(teamGroupsData)
				}
			} else {
				setTeamGroups(project.teamGroups)
			}
		}
	}, [project, setTeamGroups, open])
	useEffect(() => {
		if (userInfo) {
			if (successTeamProjectUpdate || successTeamProjectDelete || successTeamProject) {
				if (successTeamProjectUpdate) {
					dispatch({ type: TEAM_PROJECT_UPDATE_RESET })
				} else if (successTeamProjectDelete) {
					dispatch({ type: TEAM_PROJECT_DELETE_RESET })
				}
			}
			if (userInfo.center) {
				dispatch(getMyTeamPanel(userInfo.center))
			} else {
				setNotCenter(true)
			}
		} else {
			history.push('/auth/login')
		}
	}, [dispatch, history, userInfo, successTeamProjectDelete, successTeamProjectUpdate, successTeamProject])
	useEffect(() => {
		if (myTeamPanelInfo) {
			const dataFilter = myTeamPanelInfo.find((panel) => panel._id === idTeam)
			setProject(dataFilter)
		}
	}, [myTeamPanelInfo, idTeam])
	useEffect(() => {
		if (!open) {
			setGroup('')
		}
	}, [setOpen, dispatch, open])

	const handleAdd = (panel) => {
		setOpen(true)
		setIdTeam(panel._id)
	}
	const handleDelete = () => {
		if (deleteProject) {
			const { id, project } = deleteProject
			dispatch(teamDeleteFinalProject(id, project._id))
			setOpenDelete(false)
		}
	}

	return (
		<>
			<Meta title={'School Teams - Planet Fraternity'} />
			{notCenter ? (
				<Message message={<span>You are not from a registered center</span>} color='info' />
			) : loadingMyTeamPanel ? (
				<Loader />
			) : errorMyTeamPanel ? (
				<Message message={<span>{errorMyTeamPanel}</span>} color='info' />
			) : (
				<Grid container spacing={3} className={classes.root}>
					{myTeamPanelInfo &&
						myTeamPanelInfo.map((panel) => (
							<Grid item xs={12} key={panel._id}>
								{
									<Grid item md={12} className={classes.projectsRoot}>
										<CustomTabs
											headerColor='success'
											className={classes.customTabsRoot}
											tabs={[
												{
													tabName: 'Team Info',
													tabIcon: Dashboard,
													tabContent: <TeamsInfoCard panel={panel} handleAdd={handleAdd} />,
												},
												{
													tabName: 'Team Groups',
													tabIcon: People,
													tabContent: <SchoolTeamGroups teamGroups={panel.teamGroups} />,
												},
												{
													tabName: 'Final Projects',
													tabIcon: Book,
													tabContent: (
														<>
															{panel.projects.length ? (
																panel.projects.map((project, index) => (
																	<Card key={index}>
																		<FinalProjectsCard
																			project={project}
																			panel={panel}
																			setUpdate={setUpdate}
																			setProjectUpdate={setProjectUpdate}
																			setDeleteProject={setDeleteProject}
																			setOpenDelete={setOpenDelete}
																		/>
																	</Card>
																))
															) : (
																<h5>No final projects found for this team</h5>
															)}
														</>
													),
												},
											]}
										/>
									</Grid>
								}
							</Grid>
						))}
				</Grid>
			)}
			<CustomModal title={project && project.panelName} open={open} setOpen={setOpen} actions={false}>
				<Grid container spacing={3} className={classes.modalRoot}>
					{finalProjectsComplete ? (
						<Grid container>
							<Grid item xs={12} style={{ padding: '20px 0', textAlign: 'center' }}>
								<div>Final Project Completed</div>
								<div>
									Check them at <b>Final Projects</b> section
								</div>
							</Grid>
							<Grid item xs={12}>
								<Button block color={'primary'} onClick={() => [setOpen(false), setFinalProjectsComplete(false)]}>
									Cerrar
								</Button>
							</Grid>
						</Grid>
					) : (
						<>
							<h2>Select a group to add their final project</h2>
							<Grid item sm={12} xs={12}>
								<CreateFinalProject
									project={project}
									group={group}
									setGroup={setGroup}
									setOpen={setOpen}
									teamGroups={teamGroups}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</CustomModal>
			<CustomModal title='Update Final Project Info' open={update} setOpen={setUpdate} actions={false}>
				{projectUpdate && <UpdateProjectModal setUpdate={setUpdate} project={projectUpdate} />}
			</CustomModal>
			<CustomModal title='Delete Final Project?' open={openDelete} setOpen={setOpenDelete} actions={false}>
				<DeleteFinalProjectModal setOpenDelete={setOpenDelete} handleDelete={handleDelete} />
			</CustomModal>
		</>
	)
}

export default SchoolTeamsScreen

const styles = {
    root: {
        margin: '120px auto',
        maxWidth: '1000px',
        '& h1': {
            textAlign: 'center',
            marginBottom: '20px',
        },
        '& p': {
            textAlign: 'justify',
        },
        '& li': {
            listStyle: 'initial !important',
        },
    },
    termsSection: {
        margin: '35px 0',
    },
}

export default styles

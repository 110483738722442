import VideoSnapshot from 'video-snapshot'

export const createThumbnail = async (file) => {
	const snapshoter = new VideoSnapshot(file)
	const newBase64 = await snapshoter.takeSnapshot()
	const decodedString = window.atob(newBase64.split(',')[1])

	const uint8Array = new Uint8Array(decodedString.length)
	for (let i = 0; i < decodedString.length; i++) {
		uint8Array[i] = decodedString.charCodeAt(i)
	}

	const blob = new Blob([uint8Array], { type: 'image/png', name: file.name })

	return blob
}

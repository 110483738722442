import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import StudentHeader from '../../components/Headers/StudentHeader'
import StudentHeaderLinks from '../../components/Headers/StudentHeaderLinks'
import Footer from '../../components/Footers/Footer'
import styles from './styles/studentLayoutStyles'
import studentPanelRoutes from '../../routes/student-panel-routes'

const useStyles = makeStyles(styles)

const StudentLayout = ({ children, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    if (!userInfo) {
      history.push('/auth/login')
    } else if (userInfo && userInfo.role !== 'student') {
      history.push('/')
    }
  }, [userInfo, history])

  return (
    <main>
      <div className={classes.header}>
        <StudentHeader
          brand='Planet Fraternity'
          rightLinks={<StudentHeaderLinks />}
          fixed
          color='white'
          changeColorOnScroll={{
            height: 150,
            color: 'white',
          }}
          {...rest}
        />
      </div>
      <div>
        <Switch>
          {studentPanelRoutes.map((route, i) => (
            <Route key={i} path={`/student${route.path}`} component={route.comp} exact={route.exact} />
          ))}
        </Switch>
      </div>
      <Footer />
    </main>
  )
}

export default StudentLayout

import StudentLayout from '../layouts/StudentLayout/StudentLayout'
import StudentDashboardScreen from '../screens/student-panel/StudentDashboardScreen/StudentDashboardScreen'
import ListeningScreen from '../screens/student-panel/ListeningScreen/ListeningScreen'
import PrevMeetingScreen from '../screens/student-panel/MeetRoomScreen/PrevMeetingScreen'
import StudentProfileScreen from '../screens/student-panel/StudentProfileScreen/StudentProfileScreen'
import ReadingScreen from '../screens/student-panel/ReadingScreen/ReadingScreen'
import SpeakingScreen from '../screens/student-panel/SpeakingScreen/SpeakingScreen'
import WritingContestScreen from '../screens/student-panel/WritingContestScreen/WritingContestScreen'
import WritingScreen from '../screens/student-panel/WritingScreen/WritingScreen'

const studentPanelRoutes = [
  {
    path: '/dashboard',
    exact: true,
    layout: StudentLayout,
    comp: StudentDashboardScreen,
  },
  {
    path: '/listening',
    exact: true,
    layout: StudentLayout,
    comp: ListeningScreen,
  },
  {
    path: '/prevmeeting/:id',
    exact: true,
    layout: StudentLayout,
    comp: PrevMeetingScreen,
  },
  {
    path: '/profile',
    exact: true,
    layout: StudentLayout,
    comp: StudentProfileScreen,
  },
  {
    path: '/reading',
    exact: true,
    layout: StudentLayout,
    comp: ReadingScreen,
  },
  {
    path: '/speaking',
    exact: true,
    layout: StudentLayout,
    comp: SpeakingScreen,
  },
  {
    path: '/writing-contest',
    exact: true,
    layout: StudentLayout,
    comp: WritingContestScreen,
  },
  {
    path: '/writing',
    exact: true,
    layout: StudentLayout,
    comp: WritingScreen,
  },
]

export default studentPanelRoutes

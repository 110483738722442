import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import CustomModal from '../../../components/Modal/CustomModal'
import WritingContestCard from './components/WritingContestCard'
import WritingContestListCard from './components/WritingContestListCard'
import WritingContestRegisterForm from './components/WritingContestRegisterForm'
import WritingContestRightSection from './components/WritingContestRightSection'
import WritingContestButton from './components/WritingContestButton'
import { getWritingContests } from '../../../redux/actions/writingContestActions'
import {
  WRITING_C_REGISTER_RESET,
  WRITING_C_DELETE_RESET,
  WRITING_C_UPDATE_RESET,
} from '../../../redux/constants/writingContestConstants'
import styles from './styles/writingContestScreenStyles'

const useStyles = makeStyles(styles)

const WritingContestScreen = ({ match, history }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const classes = useStyles()

  const [matchKeyword, setMatchKeyword] = useState('')
  const [writingsArray, setwritingsArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)
  const [open, setOpen] = useState(false)
  const [writingDetails, setWritingDetails] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { thatsIt, writingContests, errorWritingCList } = useSelector((state) => state.writingCList)
  const { successWritingCRegister } = useSelector((state) => state.writingCRegister)
  const { successWritingCUpdate, writingContest } = useSelector((state) => state.writingCUpdate)
  const { successWritingCDelete } = useSelector((state) => state.writingCDelete)

  useEffect(() => {
    if (successWritingCUpdate) {
      setWritingDetails(writingContest)
    }
  }, [successWritingCUpdate, writingContest])

  useEffect(() => {
    if (userInfo) {
      if (successWritingCDelete || successWritingCRegister || successWritingCUpdate) {
        if (successWritingCDelete) {
          dispatch({ type: WRITING_C_DELETE_RESET })
          setWritingDetails(null)
        }
        if (successWritingCRegister) {
          dispatch({ type: WRITING_C_REGISTER_RESET })
        }
        if (successWritingCUpdate) {
          dispatch({ type: WRITING_C_UPDATE_RESET })
        }
        setwritingsArray([])
        setStart(1)
        setInitialHasMore(true)
        setHasMore(true)
        return true
      }
      if (keyword !== undefined) {
        if (keyword !== matchKeyword) {
          setwritingsArray([])
          setInitialHasMore(true)
          setMatchKeyword(keyword)
          setStart(1)
        } else {
          if (initalHasMore) {
            setInitialHasMore(false)
            dispatch(getWritingContests(keyword, count, 0))
          } else if (writingContests && writingContests.length > 0) {
            setwritingsArray((prev) => [...prev, ...writingContests])
          }
        }
      } else {
        if (initalHasMore) {
          dispatch(getWritingContests(keyword, count, 0))
          setInitialHasMore(false)
        } else if (writingContests && writingContests.length > 0) {
          setwritingsArray((prev) => [...prev, ...writingContests])
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [
    userInfo,
    history,
    dispatch,
    keyword,
    count,
    writingContests,
    initalHasMore,
    matchKeyword,
    successWritingCRegister,
    successWritingCDelete,
    successWritingCUpdate,
  ])
  useEffect(() => {
    if (thatsIt || errorWritingCList) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorWritingCList])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getWritingContests(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Writing Contest List - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h1>Writing Contest List</h1>
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <WritingContestButton setOpen={setOpen} keyword={keyword} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfiniteScroll
            dataLength={writingsArray.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            height={'60vh'}
            className={classes.infiniteScroll}
            endMessage={
              errorWritingCList ? (
                <p className={classes.lastLine}>
                  <b>{errorWritingCList}</b>
                </p>
              ) : (
                <p className={classes.lastLine}>
                  <b>This is the last line.</b>
                </p>
              )
            }
          >
            {writingsArray.map((writing) => (
              <WritingContestListCard writing={writing} key={writing._id} setWritingDetails={setWritingDetails} />
            ))}
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} md={8}>
          {writingDetails !== null ? (
            <WritingContestCard writing={writingDetails} setWritingDetails={setWritingDetails} />
          ) : (
            <WritingContestRightSection />
          )}
        </Grid>
      </Grid>
      <CustomModal title='Register Writing Contest' open={open} setOpen={setOpen} actions={false}>
        <WritingContestRegisterForm setOpen={setOpen} setWritingDetails={setWritingDetails} />
      </CustomModal>
    </div>
  )
}

export default WritingContestScreen

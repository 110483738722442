const styles = {
    formControl: {
        marginTop: '11px',
        minWidth: 120,
        width: '100%',
        textAlign: 'left',
    },
    inputItem: {
        margin: 0,
        paddingTop: '12px',
        '& input': {
            padding: '22px 0 0',
        },
        '& .MuiSelect-root': {
            padding: '5px 0',
        },
    },
    selectLabel: {
        color: '#AAAAAA !important',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.42857',
        letterSpacing: 'unset',
        marginButton: '10px',
    },
    textField: {
        marginTop: '15px',
        width: '100%',
    },
    resize: {
        fontSize: 14,
    },
    deletePhrase: {
        backgroundColor: '#e4e1e1',
        width: 'fit-content',
        padding: '3px 5px',
        borderRadius: '5px',
    },
    tableHeadBackground: {
        backgroundColor: '#ececec',
    }
}

export default styles

import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CardBody from '../../../../components/Card/CardBody'
import Loader from '../../../../components/Loader/Loader'
import CenterCardForm from './CenterCardForm'
import { getCenterGroups } from '../../../../redux/actions/centerActions'
import styles from '../styles/centerCardStyles'

const useStyles = makeStyles(styles)

const CenterCard = ({ center, setCenterDetail }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const editcenterform = useRef(null)

  const { loadingCenterGroupsList, centerGroupsList } = useSelector((state) => state.centerGroupsList)

  useEffect(() => {
    dispatch(getCenterGroups(center._id))
  }, [dispatch, center])
  useEffect(() => {
    scrollToRef()
  }, [center])
  const scrollToRef = () => {
    editcenterform.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Grid container ref={editcenterform} className={classes.root}>
      <Grid item xs={12} sm={12} md={12}>
        <Card profile>
          <CardAvatar center>
            <img src={`${axios.defaults.baseURL}${center.logo}`} alt={center.name} />
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{center.name}</h4>
            {loadingCenterGroupsList ? (
              <Loader />
            ) : (
              centerGroupsList && (
                <CenterCardForm center={center} setCenterDetail={setCenterDetail} centerGroupsList={centerGroupsList} />
              )
            )}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CenterCard

const styles = {
    teacherRoot: {
        padding: 30
    },
    tabSection: {
        paddingTop: '15px',
        minHeight: '500px',
    }
}

export default styles
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import TeacherCard from './components/TeacherCard'
import UserListCard from '../../../components/Users/UserListCard/UserListCard'
import TeacherListRightSection from './components/TeacherListRightSection'
import TeacherListButton from './components/TeacherListButton'
import TeacherListHeader from './components/TeacherListHeader'
import { getTeachers } from '../../../redux/actions/teacherActions'
import { USER_UPDATE_RESET } from '../../../redux/constants/userConstants'
import styles from './styles/teacherListScreenStyles'

const useStyles = makeStyles(styles)

const TeacherListScreen = ({ match, history }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const classes = useStyles()

  const [matchKeyword, setMatchKeyword] = useState('')
  const [userArray, setUserArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [teachersLength, setTeachersLength] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)
  const [teacherDetails, setTeacherDetails] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)
  const userUpdate = useSelector((state) => state.userUpdate)
  const { successUserUpdate, userUpdated } = userUpdate
  const { thatsIt, userLength, teachers, errorTeacherList } = useSelector((state) => state.teacherList)

  useEffect(() => {
    if (successUserUpdate) {
      setTeacherDetails(userUpdated)
    }
  }, [successUserUpdate, userUpdated])
  useEffect(() => {
    if (userInfo) {
      if (successUserUpdate) {
        if (successUserUpdate) {
          dispatch({ type: USER_UPDATE_RESET })
        }
        setUserArray([])
        setStart(1)
        setInitialHasMore(true)
        setHasMore(true)
        return true
      }
      if (keyword !== undefined) {
        if (keyword !== matchKeyword) {
          setUserArray([])
          setInitialHasMore(true)
          setMatchKeyword(keyword)
          setStart(1)
        } else {
          if (initalHasMore) {
            setInitialHasMore(false)
            dispatch(getTeachers(keyword, count, 0))
          } else if (teachers && teachers.length > 0) {
            setUserArray((prev) => [...prev, ...teachers])
          }
        }
      } else {
        if (initalHasMore) {
          dispatch(getTeachers(keyword, count, 0))
          setInitialHasMore(false)
        } else if (teachers && teachers.length > 0) {
          setUserArray((prev) => [...prev, ...teachers])
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [userInfo, history, dispatch, keyword, count, teachers, initalHasMore, matchKeyword, successUserUpdate])
  useEffect(() => {
    if (teachersLength === null && userLength) {
      setTeachersLength(userLength)
    }
  }, [userLength, teachersLength])
  useEffect(() => {
    if (thatsIt || errorTeacherList === 'Teachers not found.') {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorTeacherList])
  useEffect(() => {
    return () => {
      setTeachersLength(null)
    }
  }, [])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getTeachers(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Teacher List - Planet Fraternity'} />
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <TeacherListHeader teachersLength={teachersLength} />
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <TeacherListButton keyword={keyword} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfiniteScroll
            dataLength={userArray.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            height={'60vh'}
            className={classes.infiniteScroll}
            endMessage={
              <p className={classes.contentLine}>
                <b>Thats it.</b>
              </p>
            }
          >
            {userArray.map((user) => (
              <UserListCard
                key={user._id}
                user={user}
                dropdownList={[<div onClick={() => setTeacherDetails(user)}>View Profile</div>]}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} md={8}>
          {teacherDetails !== null ? (
            <TeacherCard teacher={teacherDetails} setTeacherDetails={setTeacherDetails} />
          ) : (
            <TeacherListRightSection />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default TeacherListScreen

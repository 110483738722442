import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Loader from '../../../components/Loader/Loader'
import Message from '../../../components/Message/Message'
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import Meta from '../../../components/Meta/Meta'
import TopicIconsList from './components/TopicIconsList'
import SelectedTopic from './components/SelectedTopic'
import { getTopics } from '../../../redux/actions/topicActions'
import styles from './styles/mergeCentersScreenStyles'

const useStyles = makeStyles(styles)

const MergeCentersScreen = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const search = useLocation().search
  const idQuery = new URLSearchParams(search).get('id')

  const [topic, setTopic] = useState({})

  const { loadingTopicList, errorTopicList, topics } = useSelector((state) => state.topicList)

  useEffect(() => {
    if (idQuery && topics) {
      const topic = topics.find((item) => item._id === idQuery)
      setTopic(topic)
    } else {
      setTopic({})
    }
  }, [idQuery, topics])

  useEffect(() => {
    dispatch(getTopics())
  }, [dispatch])

  return (
    <>
      <Meta title={'Merge Centers - Planet Fraternity'} />
      <div className={classes.root}>
        {topics && Object.keys(topic).length > 0 ? (
          <SelectedTopic topic={topic} />
        ) : (
          <GridContainer className={classes.rootContainer}>
            <GridItem>
              <h1>Select a Topic</h1>
            </GridItem>
            {loadingTopicList ? (
              <Loader />
            ) : errorTopicList ? (
              <span className={classes.spanMessage}>
                <Message message={<span>{errorTopicList}</span>} color='info' />
              </span>
            ) : (
              topics && <TopicIconsList topics={topics} history={history} />
            )}
          </GridContainer>
        )}
      </div>
    </>
  )
}

export default MergeCentersScreen

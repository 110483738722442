const styles = {
  formControl: {
    marginTop: '11px',
    marginBottom: '30px',
    minWidth: 120,
    width: '100%',
    textAlign: 'left',
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  selectInput: {
    marginTop: '7px',
  },
  categorySection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  btnSection: {
    marginTop: '20px',
  },
  btnResource: {
    textAlign: 'end',
    '& p': {
      fontSize: '0.75rem',
      color: '#aaaaaa',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontWeight: 600,
    },
  },
  closeIcon: {
    padding: 5,
    marginTop: '30px',
  },
  fileBadge: {
    margin: '20px 0',
    width: '100%',
  },
  fileDiv: {
    textAlign: 'center',
    backgroundColor: '#3f51b5',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    width: '100%',
  },
  textHelper: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
}

export default styles

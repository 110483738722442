const styles = {
	videoRoot: {
		margin: '100px auto',
		background: '#eeeeee',
		borderRadius: '6px',
		overflow: 'hidden',
		boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
		'& video': {
			width: '100%',
			height: '100%',
		},
	},
}

export default styles

import React from 'react'
import axios from 'axios'
import { MoreVert, VideoLibrary } from '@material-ui/icons'
import { Card, CardHeader, makeStyles } from '@material-ui/core'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/talentListCardStyles'

const useStyles = makeStyles(styles)

const TalentListCard = ({ talent, setSelectedPost }) => {
	const classes = useStyles()

	return (
		<Card className={classes.root}>
			<div className={classes.imagePreview}>
				{talent.videoThumbNail ? (
					<img
						src={`${axios.defaults.baseURL}/${talent.videoThumbNail ?? ''}`}
						className={classes.img}
						alt={'country-post-preview'}
					/>
				) : (
					<VideoLibrary />
				)}
			</div>
			<CardHeader
				className={classes.cardHeaderRoot}
				action={
					<CustomDropdown
						left
						caret={false}
						buttonText={
							<div className={classes.btnwrapper}>
								<MoreVert />
							</div>
						}
						buttonProps={{
							className: classes.navLink + ' ' + classes.imageDropdownButton,
							color: 'transparent',
						}}
						dropdownList={[<div onClick={() => setSelectedPost(talent)}>Manage</div>]}
					/>
				}
				title={
					<p className={classes.title}>
						{talent.title?.length > 20 ? talent?.title.slice(0, 20) + '...' : talent?.title}
						{talent.active && <small className={classes.badgeNew}></small>}
					</p>
				}
				subheader={
					<p className={classes.desc}>{talent?.body?.length > 21 ? talent.body.slice(0, 21) + '...' : talent?.body}</p>
				}
			/>
		</Card>
	)
}

export default TalentListCard

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, makeStyles } from '@material-ui/core'
import { Accessibility } from '@material-ui/icons'
import CustomModal from '../../../components/Modal/CustomModal'
import Button from '../../../components/Buttons/Button'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import TalentListCard from './components/TalentListCard'
import TalentRegistrationModal from './components/TalentRegistrationModal'
import SharedTalentComponent from '../../../components/SharedTalentComponent/SharedTalentComponent'
import { TALENT_LIST_RESET } from '../../../redux/constants/talentsConstants'
import { getTalents } from '../../../redux/actions/shareTalentsActions'
import styles from './styles/shareTalentsScreenStyles'

const useStyles = makeStyles(styles)

const ShareTalentsScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [open, setOpen] = useState(false)
	const [start, setStart] = useState(1)
	const [hasMore, setHasMore] = useState(true)
	const [data, setData] = useState([])
	const [selectedPost, setSelectedPost] = useState(null)

	const allTalents = useSelector((d) => d.talentList?.talents ?? [])
	const thatsIt = useSelector((d) => d.talentList?.thatsIt ?? [])
	const count = 10

	const fetchData = useCallback(
		(force) => {
			if (hasMore || force) {
				setStart(start + 1)
				dispatch(getTalents(count, start))
			}
		},
		[hasMore, count, start],
	)

	useEffect(() => {
		if (allTalents.length) {
			setData([...allTalents])
		} else {
			setData([])
		}
	}, [allTalents])
	useEffect(() => {
		if (!thatsIt) {
			setHasMore(true)
		} else {
			setHasMore(false)
		}
	}, [thatsIt])
	useEffect(() => {
		dispatch(getTalents(count, 0))

		return () => {
			dispatch({ type: TALENT_LIST_RESET })
		}
	}, [])

	const handleCloseModal = (openPost) => {
		setOpen(false)
		setSelectedPost(openPost)
	}

	return (
		<div className={classes.root}>
			<Meta title={'Let\'s share talents - Planet Fraternity'} />
			<Grid container spacing={3}>
				<Grid item xs={12} md={3}>
					<h1>Let's share talents</h1>
				</Grid>
				<Grid item xs={12} md={9} className={classes.contentItemsEnd}>
					<Button color='success' onClick={() => setOpen(true)}>
						+ Add post
					</Button>
				</Grid>
				<Grid item xs={12} md={4}>
					<InfiniteScroll
						dataLength={data.length}
						next={fetchData}
						hasMore={hasMore}
						loader={<Loader />}
						height={'60vh'}
						className={classes.infiniteScroll}
						endMessage={
							<p className={classes.contentLine}>
								<b>Thats it.</b>
							</p>
						}
					>
						{data &&
							data.map((post, index) => <TalentListCard talent={post} setSelectedPost={() => setSelectedPost(post)} />)}
					</InfiniteScroll>
				</Grid>
				<Grid item xs={12} md={8}>
					{selectedPost !== null ? (
						<SharedTalentComponent
							updateSelection={setSelectedPost}
							previewMode
							updateState={setData}
							talent={selectedPost}
						/>
					) : (
						<div className={classes.rightSection}>
							<Accessibility className={classes.schoolIcon} />
							<h2>Select talent to manage the status.</h2>
						</div>
					)}
				</Grid>
			</Grid>
			{open && (
				<CustomModal title='Share a new talent!' open={open} setOpen={setOpen} actions={false}>
					<TalentRegistrationModal close={handleCloseModal} />
				</CustomModal>
			)}
		</div>
	)
}

export default ShareTalentsScreen

const months = [
    {
        _id: '1',
        name: 'January',
        i18nText: 'layout.t_january',
    },
    {
        _id: '2',
        name: 'February',
        i18nText: 'layout.t_february',
    },
    {
        _id: '3',
        name: 'March',
        i18nText: 'layout.t_march',
    },
    {
        _id: '4',
        name: 'April',
        i18nText: 'layout.t_april',
    },
    {
        _id: '5',
        name: 'May',
        i18nText: 'layout.t_may',
    },
    {
        _id: '6',
        name: 'June',
        i18nText: 'layout.t_june',
    },
    {
        _id: '7',
        name: 'July',
        i18nText: 'layout.t_july',
    },
    {
        _id: '8',
        name: 'August',
        i18nText: 'layout.t_august',
    },
    {
        _id: '9',
        name: 'September',
        i18nText: 'layout.t_september',
    },
    {
        _id: '10',
        name: 'October',
        i18nText: 'layout.t_october',
    },
    {
        _id: '11',
        name: 'November',
        i18nText: 'layout.t_november',
    },
    {
        _id: '12',
        name: 'December',
        i18nText: 'layout.t_december',
    },
]

export default months

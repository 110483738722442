import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import format from 'date-fns/format'
import { Grid, Avatar, makeStyles } from '@material-ui/core'
import Loader from '../../Loader/Loader'
import { getStudentsByCenter } from '../../../redux/actions/studentActions'
import styles from './styles/centerInfoStyles'

const useStyles = makeStyles(styles)

const CenterInfo = ({ center, teachers }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingStudentsByCenter, errorStudentsByCenter, students } = useSelector((state) => state.studentsByCenter)
	const { successRegisterMultiple } = useSelector((state) => state.studentRegisterMultiple)
	const { successGroupDelete } = useSelector((state) => state.groupDelete)

	useEffect(() => {
		if (center._id) {
			dispatch(getStudentsByCenter('', null, null, center._id))
		}
	}, [dispatch, center, successRegisterMultiple, successGroupDelete])

	return (
		<>
			{center._id && (
				<Grid container spacing={2}>
					<Grid item xs={12} md={2} className={classes.centerLogoRoot}>
						<Avatar
							variant='rounded'
							src={`${axios.defaults.baseURL}${center.logo}`}
							alt={center.name + '-logo'}
							className={classes.centerLogo}
						/>
					</Grid>
					<Grid item xs={12} md={10}>
						<h1 className={classes.centerName}>{center.name}</h1>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.centerInfoSection}>
							<h5>Basic Info.</h5>
							<div>
								<span>Email · {center.email}</span>
							</div>
							<div>
								<span>Phone · {center.phone}</span>
							</div>
							<div>
								<span>Address · {center.address}</span>
							</div>
							<div>
								<span>
									Country · {center.country.name} ·{' '}
									<img
										src={`${axios.defaults.baseURL}${center.country.flag}`}
										className={classes.countryFlagProfile}
										alt={'Flag of ' + center.country.name}
									/>
								</span>
							</div>
							<div>
								<span>Registered At · {format(new Date(center.createdAt), 'dd MMMM yyyy')}</span>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.centerInfoSection}>
							<h5>License Info.</h5>
							<div>
								<span className={classes.planText}>Plan · {center.license.plan}</span>
							</div>
							{teachers && (
								<div>
									<span>
										Teachers · {teachers.length} / {center.license.teachersQty}
									</span>
								</div>
							)}
							{loadingStudentsByCenter ? (
								<Loader />
							) : errorStudentsByCenter ? (
								<span>{errorStudentsByCenter}</span>
							) : (
								students && (
									<div>
										<span>
											Students · {students.userLength} /{center.license.studentsQty}
										</span>
									</div>
								)
							)}
							<div>{center.isForTest && <span>License for test</span>}</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.centerInfoSection}>
							<h5>Principal Info.</h5>
							<div>
								<span>Name · {center.schoolPrincipal.name}</span>
							</div>
							<div>
								<span>Email · {center.schoolPrincipal.email}</span>
							</div>
							<div>
								<span>Phone · {center.schoolPrincipal.phone}</span>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.centerInfoSection}>
							<h5>School Year</h5>
							<div>
								<span>Start · {center.schoolYear.start}</span>
							</div>
							<div>
								<span>Finish · {center.schoolYear.finish}</span>
							</div>
						</div>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default CenterInfo

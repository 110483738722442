import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import { EmojiPeople } from '@material-ui/icons'
import Button from '../Buttons/Button'
import styles from './styles/icanStyles'

const useStyles = makeStyles(styles)

const Ican = () => {
  const classes = useStyles()
  const { t } = useTranslation('global')

  return (
    <div className={classes.sections}>
      <Grid container className={classes.sectionRoot}>
        <Grid item xs={12} md={6} className={classes.imgRoot}>
          <img src='/assets/img/public-site/i-can.png' alt='I Can' width='100%' />
        </Grid>
        <Grid item xs={12} md={6} className={classes.onTopCardRoot}>
          <Card className={classes.onTopCardRight}>
            <CardContent>
              <div className={classes.descRoot}>
                <EmojiPeople className={classes.iCanIcon} />
                <p>{t('page.t_ican_text_one')}</p>
                <p>{t('page.t_ican_text_two')}</p>
                <div className={classes.descActions}>
                  <Button link onClick={() => window.open('http://oiecinternational.com/es/yo-puedo')}>
                    {t('layout.buttons.t_more-info')}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Ican

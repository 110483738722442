import * as types from '../constants/countryConstants'

export const countryListReducer = (state = { countries: [] }, action) => {
  switch (action.type) {
    case types.COUNTRY_LIST_REQUEST:
      return { loadingCountryList: true }
    case types.COUNTRY_LIST_SUCCESS:
      return { loadingCountryList: false, countries: action.payload }
    case types.COUNTRY_LIST_FAIL:
      return {
        loadingCountryList: false,
        errorCountryList: action.payload,
      }
    case types.COUNTRY_LIST_RESET:
      return { countries: [] }
    default:
      return state
  }
}

export const countryAllReducer = (state = { allCountries: [] }, action) => {
  switch (action.type) {
    case types.COUNTRY_ALL_REQUEST:
      return { loadingCountryAll: true }
    case types.COUNTRY_ALL_SUCCESS:
      return { loadingCountryAll: false, allCountries: action.payload }
    case types.COUNTRY_ALL_FAIL:
      return {
        loadingCountryAll: false,
        errorCountryAll: action.payload,
      }
    case types.COUNTRY_ALL_RESET:
      return { allCountries: [] }
    default:
      return state
  }
}

export const countryDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.COUNTRY_DETAILS_REQUEST:
      return { loadingCountryDetails: true }
    case types.COUNTRY_DETAILS_SUCCESS:
      return { loadingCountryDetails: false, country: action.payload }
    case types.COUNTRY_DETAILS_FAIL:
      return {
        loadingCountryDetails: false,
        errorCountryDetails: action.payload,
      }
    case types.COUNTRY_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

import { Grid } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'

const ResourceDeleteModal = ({ resource, deleteResourceHandle }) => {
  return (
    <>
      {resource && (
        <Grid container>
          <Grid item xs={12}>
            <p>
              Are you sure you want to delete the resource <b>{resource.title}</b>?.
            </p>
            <p>If you delete the resource, you will lose all the actions that it performs.</p>
          </Grid>
          <Grid item xs={12}>
            <Button color='primary' block onClick={() => deleteResourceHandle(resource._id)}>
              Delete Resource
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ResourceDeleteModal

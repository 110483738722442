import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { format } from 'date-fns'
import { Grid, FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CardBody from '../../../../components/Card/CardBody'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomModal from '../../../../components/Modal/CustomModal'
import AssignPartnersModal from './AssignPartnersModal'
import { getCentersPartners } from '../../../../redux/actions/centerActions'
import { getTopics } from '../../../../redux/actions/topicActions'
import { teamPanelUpdateInfo } from '../../../../redux/actions/teamsActions'
import styles from '../styles/teamCardStyles'

const useStyles = makeStyles(styles)

const TeamCard = ({ panel }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()

	const [openCenters, setOpenCenters] = useState(false)
	const [openTopics, setOpenTopics] = useState(false)
	const [topicId, setTopicId] = useState('')
	const [topicName, setTopicName] = useState('')

	const { loadingPartners, errorPartners, centersPartners } = useSelector((state) => state.centerPartners)
	const { loadingTopicList, errorTopicList, topics } = useSelector((state) => state.topicList)
	const { loadingTeamUpdate, errorTeamUpdate, successTeamUpdate } = useSelector((state) => state.teamUpdate)

	useEffect(() => {
		if (successTeamUpdate) {
			setOpenCenters(false)
			setOpenTopics(false)
		} else {
			dispatch(getCentersPartners({ partners: panel.partners }))
		}
	}, [dispatch, panel, successTeamUpdate])
	useEffect(() => {
		if (!panel.topic) {
			dispatch(getTopics())
		}
	}, [dispatch, panel])

	const assignTopicValuesHandler = (id) => {
		const topicInfo = topics.find((item) => item._id === id)
		setTopicId(topicInfo._id)
		setTopicName(topicInfo.name)
	}
	const assignTopicHandler = () => {
		const assignTopicInfo = {
			_id: panel._id,
			topic: {
				_id: topicId,
				name: topicName,
			},
		}
		dispatch(teamPanelUpdateInfo(assignTopicInfo))
	}

	return (
		<Grid container spacing={2} className={classes.panelRoot}>
			<Grid item xs={12}>
				<h4 className={classes.cardTitle}>{panel.panelName}</h4>
				<h4 className={classes.cardTitle}>
					Start Date · {format(new Date(panel.partnersStartDate), 'dd-MM-yyyy')} - End Date ·{' '}
					{format(new Date(panel.partnersEndDate), 'dd-MM-yyyy')}
				</h4>
				{panel.topic && (
					<div className={classes.topicInfo}>
						<img src={`${axios.defaults.baseURL}${panel.topic.image}`} alt={panel.topic.name} />
						<h5>{panel.topic.name}</h5>
					</div>
				)}
			</Grid>
			{loadingPartners ? (
				<Loader />
			) : errorPartners ? (
				errorPartners === 'Centers partners not found.' ? (
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={6} className={classes.flexAlignItemsCenter}>
								<div>
									<h5>Link at least two centers</h5>
									<p className={classes.textMuted}>This will help the centers to organize their team activities.</p>
									<Button color='primary' onClick={() => setOpenCenters(true)}>
										Select Centers
									</Button>
								</div>
							</Grid>
							<Grid item xs={6}>
								<img src='/assets/img/link-centers.png' alt='Link Centers' width='100%' />
							</Grid>
						</Grid>
						<CustomModal title='Select Centers' open={openCenters} setOpen={setOpenCenters} actions={false}>
							<AssignPartnersModal panel={panel} setOpen={setOpenCenters} />
						</CustomModal>
					</Grid>
				) : (
					<Message message={<span>{errorPartners}</span>} color='info' />
				)
			) : (
				centersPartners.length > 1 && (
					<>
						{centersPartners.map((partner, index) => (
							<Grid item xs={12} md={6} key={index} className={classes.partnersCards}>
								<Card profile>
									<CardAvatar className={classes.centerLogo} center>
										<img src={`${axios.defaults.baseURL}${partner.logo}`} alt={partner.name} />
									</CardAvatar>
									<CardBody profile>
										<Grid container>
											<Grid item xs={12}>
												<h4 className={classes.cardTitle}>{partner.name}</h4>
											</Grid>
											<Grid item xs={12} className={classes.cardInfo}>
												<p>
													Email · <strong>{partner?.email}</strong>
												</p>
												<p>
													Phone · <strong>{partner.phone}</strong>
												</p>
												<p>
													Groups Qty · <strong>{partner.groups.length}</strong>
												</p>
												<p>
													Students Qty · <strong>{partner.students.length}</strong>
												</p>
												<p>
													Age Range · <strong>{partner?.groups[0]?.ageRange}</strong>
												</p>
												<span>
													{partner.country.name} ·{' '}
													<img
														src={`${axios.defaults.baseURL}${partner.country.flag}`}
														className={classes.countryFlagProfile}
														alt={'Flag of ' + partner.country.name}
													/>
												</span>
											</Grid>
										</Grid>
									</CardBody>
								</Card>
							</Grid>
						))}
						<Grid item xs={12}>
							<Button
								color='primary'
								style={{ margin: '0 5px' }}
								onClick={() => history.push(`/admin/teams/manage/${panel._id}`)}
							>
								Manage groups
							</Button>
							{panel.meetRoom && (
								<Button color='success' style={{ margin: '0 5px' }} onClick={() => window.open(panel.meetRoom)}>
									Go to Team Meet Room
								</Button>
							)}
							{/* {!panel.topic && (
								<Button color='success' style={{ margin: '0 5px' }} onClick={() => setOpenTopics(true)}>
									Assign Topic
								</Button>
							)} */}
						</Grid>
					</>
				)
			)}
			<CustomModal title='Assign Topic' open={openTopics} setOpen={setOpenTopics} actions={false}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<InputLabel id='topics' className={classes.selectLabel}>
								Topics
							</InputLabel>
							<Select
								labelId='topic-label'
								id='topic'
								value={topicId}
								onChange={(e) => assignTopicValuesHandler(e.target.value)}
							>
								<MenuItem value='' disabled>
									Select a Topic
								</MenuItem>

								{loadingTopicList ? (
									<Loader />
								) : errorTopicList ? (
									<Message message={<span>{errorTopicList}</span>} />
								) : (
									topics &&
									topics.map((topic) => (
										<MenuItem value={topic._id} key={topic._id}>
											{topic.name}
										</MenuItem>
									))
								)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						{errorTeamUpdate && <Message message={<span>{errorTeamUpdate}</span>} color='info' />}
						<Button block color='primary' onClick={assignTopicHandler}>
							{loadingTeamUpdate ? <Loader /> : 'Assign Topic'}
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
		</Grid>
	)
}

export default TeamCard

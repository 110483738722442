import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import StudentCard from './components/StudentCard'
import UserListCard from '../../../components/Users/UserListCard/UserListCard'
import StudentAllRightSection from './components/StudentAllRightSection'
import StudentButton from './components/StudentButton'
import StudentHeader from './components/StudentHeader'
import { getStudents } from '../../../redux/actions/studentActions'
import { USER_UPDATE_RESET } from '../../../redux/constants/userConstants'
import styles from './styles/studentAllScreenStyles'

const useStyles = makeStyles(styles)

const StudentAllScreen = ({ match, history }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const classes = useStyles()

  const [matchKeyword, setMatchKeyword] = useState('')
  const [userArray, setUserArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [studentsLength, setStudentsLength] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)
  const [studentDetails, setStudentDetails] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)
  const userUpdate = useSelector((state) => state.userUpdate)
  const { successUserUpdate, userUpdated } = userUpdate
  const { thatsIt, userLength, students, errorStudentList } = useSelector((state) => state.studentList)

  useEffect(() => {
    if (successUserUpdate) {
      setStudentDetails(userUpdated)
    }
  }, [successUserUpdate, userUpdated])
  useEffect(() => {
    if (userInfo) {
      if (successUserUpdate) {
        if (successUserUpdate) {
          dispatch({ type: USER_UPDATE_RESET })
        }
        setUserArray([])
        setStart(1)
        setInitialHasMore(true)
        setHasMore(true)
        return true
      }
      if (keyword !== undefined) {
        if (keyword !== matchKeyword) {
          setUserArray([])
          setInitialHasMore(true)
          setMatchKeyword(keyword)
          setStart(1)
        } else {
          if (initalHasMore) {
            setInitialHasMore(false)
            dispatch(getStudents(keyword, count, 0))
          } else if (students && students.length > 0) {
            setUserArray((prev) => [...prev, ...students])
          }
        }
      } else {
        if (initalHasMore) {
          dispatch(getStudents(keyword, count, 0))
          setInitialHasMore(false)
        } else if (students && students.length > 0) {
          setUserArray((prev) => [...prev, ...students])
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [userInfo, history, dispatch, keyword, count, students, initalHasMore, matchKeyword, successUserUpdate])
  useEffect(() => {
    if (studentsLength === null && userLength) {
      setStudentsLength(userLength)
    }
  }, [userLength, studentsLength])
  useEffect(() => {
    if (thatsIt || errorStudentList === 'Students not found.') {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorStudentList])
  useEffect(() => {
    return () => {
      setStudentsLength(null)
    }
  }, [])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getStudents(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Student List - Planet Fraternity'} />
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <StudentHeader studentsLength={studentsLength} />
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <StudentButton keyword={keyword} />
        </Grid>
        <Grid item xs={12} md={4}>
          {userArray.length === 0 && start === 1 ? (
            <div className={classes.infiniteScroll}>
              <h4 className={classes.emptyStudentCard}>There are no students registered yet.</h4>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={userArray.length}
              next={fetchData}
              hasMore={hasMore}
              loader={<Loader />}
              height={'60vh'}
              className={classes.infiniteScroll}
              endMessage={
                <p className={classes.contentLine}>
                  <b>This is the last line.</b>
                </p>
              }
            >
              {userArray.map((user) => (
                <UserListCard
                  key={user._id}
                  user={user}
                  dropdownList={[<div onClick={() => setStudentDetails(user)}>View Profile</div>]}
                />
              ))}
            </InfiniteScroll>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {studentDetails !== null ? (
            <StudentCard student={studentDetails} setStudentDetails={setStudentDetails} />
          ) : (
            <StudentAllRightSection />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default StudentAllScreen

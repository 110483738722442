import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { userUpdateInfo } from '../../../../redux/actions/userActions'
import styles from '../styles/teacherCardFormStyle'

const useStyles = makeStyles(styles)

const TeacherCardForm = ({ teacher }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [googleMail, setGoogleMail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [password, setPassword] = useState('')
    const [successUpdated, setSuccessUpdate] = useState(false)
    const [usernameError, setUsernameError] = useState(false)

    const userUpdate = useSelector((state) => state.userUpdate)
    const { loadingUserUpdate, errorUserUpdate, successUserUpdate } = userUpdate

    useEffect(() => {
        if (teacher) {
            setName(teacher.name)
            setUsername(teacher.username)
            setEmail(teacher.email)
            setGoogleMail(teacher.googleMail)
            setPhone(teacher.phone || '')
            setAddress(teacher.address || '')
        }
    }, [teacher])
    useEffect(() => {
        if (successUserUpdate) {
            setSuccessUpdate(true)
            setTimeout(() => {
                setSuccessUpdate(false)
            }, 2000)
        }
    }, [successUserUpdate])
    useEffect(() => {
        if (errorUserUpdate) {
            setUsernameError(true)
        }
    }, [errorUserUpdate])
    useEffect(() => {
        setUsernameError(false)
    }, [username])

    const submitEditFormHandler = (e) => {
        e.preventDefault()
        const updateUser = {
            _id: teacher._id,
            name,
            username,
            email,
            password,
            phone,
            address,
        }
        dispatch(userUpdateInfo(updateUser))
        if (password) {
            setPassword('')
        }
    }

    return (
        <>
            <form onSubmit={submitEditFormHandler} autoComplete='off'>
                <CustomInput
                    labelText='Name'
                    id='newname'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'text',
                        value: name,
                        placeholder: 'Enter complete name',
                        onChange: (e) => setName(e.target.value),
                        required: true,
                    }}
                />
                <CustomInput
                    labelText={usernameError ? 'Username not available' : 'Username'}
                    id='newusername'
                    error={usernameError ? true : false}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'text',
                        value: username,
                        placeholder: 'Enter username',
                        onChange: (e) => setUsername(e.target.value),
                        required: true,
                    }}
                />
                <CustomInput
                    labelText='Email'
                    id='newemail'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'email',
                        value: email,
                        placeholder: 'Enter a valid email',
                        onChange: (e) => setEmail(e.target.value),
                    }}
                />
                <CustomInput
                    labelText='Google Email'
                    id='googleMail'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'text',
                        value: googleMail,
                        disabled: true
                    }}
                />
                <CustomInput
                    labelText='Phone'
                    id='newphone'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'text',
                        value: phone,
                        placeholder: 'E.g. +34 000 000000',
                        onChange: (e) => setPhone(e.target.value),
                    }}
                />
                <CustomInput
                    labelText='Address'
                    id='newaddress'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'text',
                        value: address,
                        placeholder: 'Enter address (Optional)',
                        onChange: (e) => setAddress(e.target.value),
                    }}
                />
                <CustomInput
                    labelText='Password'
                    id='password'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'password',
                        value: password,
                        placeholder: 'Enter password',
                        onChange: (e) => setPassword(e.target.value),
                    }}
                />
                <Button type='submit' block color={successUpdated ? 'success' : 'primary'} disabled={successUpdated && true}>
                    {loadingUserUpdate ? <Loader size={'sm'} /> : successUpdated ? 'Updated Successfully' : 'Update Teacher'}
                </Button>
            </form>
        </>
    )
}

export default TeacherCardForm

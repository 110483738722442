import { useGoogleLogin } from '@react-oauth/google'
import { makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/googleLoginStyles'

const useStyles = makeStyles(styles)

const GoogleLogin = ({ credentials }) => {
	const classes = useStyles()

	const login = useGoogleLogin({
		onSuccess: credentials,
		onError: credentials,
	})

	return (
		<Button className={classes.button} onClick={login}>
			Login
		</Button>
	)
}

export default GoogleLogin

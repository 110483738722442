import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import format from 'date-fns/format'
import { Grid, Avatar, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import { userUpdateInfo } from '../../../../redux/actions/userActions'
import { deleteTeacher } from '../../../../redux/actions/teacherActions'
import { USER_UPDATE_RESET, USER_DETAILS_RESET, USER_DELETE_RESET } from '../../../../redux/constants/userConstants'
import styles from '../styles/teacherInfoFormStyles'

const useStyles = makeStyles(styles)

const TeacherInfoFrom = ({ teacher }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [googleMail, setGoogleMail] = useState('')
  const [password, setPassword] = useState('')
  const [teacherCenter, setTeacherCenter] = useState('')
  const [openDelete, setOpenDelete] = useState(false)

  const { loadingUserUpdate, errorUserUpdate, successUserUpdate } = useSelector((state) => state.userUpdate)
  const { loadingTeacherDelete, errorTeacherDelete, successTeacherDelete } = useSelector((state) => state.teacherDelete)

  useEffect(() => {
    setName(teacher.name)
    setEmail(teacher.email)
    setPhone(teacher.phone || '')
    setGoogleMail(teacher.googleMail)
    setTeacherCenter(teacher.center)
  }, [teacher])

  useEffect(() => {
    if (successTeacherDelete) {
      history.push(`/admin/centers/manage/${teacherCenter}`)
      dispatch({ type: USER_DELETE_RESET })
    }
  }, [successTeacherDelete, history, dispatch, teacherCenter])

  const submitHandler = (e) => {
    e.preventDefault()

    const updateUser = {
      _id: teacher._id,
      name,
      email,
      phone,
      password,
    }
    dispatch(userUpdateInfo(updateUser))
    if (password) {
      setPassword('')
    }
  }

  const resetUpdateHandler = () => {
    dispatch({ type: USER_UPDATE_RESET })
  }

  const deleteUserHandler = () => {
    dispatch(deleteTeacher(teacher._id))
    dispatch({ type: USER_DETAILS_RESET })

    // Verify if the teacher is assigned to center's groups first
  }

  return (
    <>
      {teacher && (
        <Grid container>
          <Grid item xs={12}>
            <div className='d-flex justify-content-md-between flex-column-reverse flex-lg-row align-items-center pb-2'>
              <div>
                <h1 className='m-0'>{teacher.name}</h1>
                <p>
                  <strong>Regsitered At:</strong> {format(new Date(teacher.createdAt), 'dd/MM/yyyy')}
                </p>
                <p>
                  <strong>Last Update:</strong> {format(new Date(teacher.updatedAt), 'dd/MM/yyyy')}
                </p>
              </div>

              <Avatar
                variant='circular'
                src={`${axios.defaults.baseURL}${teacher.avatar}`}
                alt={teacher.name + '-logo'}
                className={classes.teacherAvatar}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={submitHandler} autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText='Name'
                    id='name'
                    inputProps={{
                      value: name,
                      onChange: (e) => setName(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText='Email'
                    id='email'
                    inputProps={{
                      value: email,
                      onChange: (e) => setEmail(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText='Phone'
                    id='phone'
                    inputProps={{
                      value: phone,
                      onChange: (e) => setPhone(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomInput
                    labelText='Google Email'
                    id='googleMail'
                    inputProps={{
                      value: googleMail,
                      onChange: (e) => setGoogleMail(e.target.value),
                      disabled: true,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomInput
                    labelText='Password'
                    id='password'
                    inputProps={{
                      value: password,
                      onChange: (e) => setPassword(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                {!successUserUpdate && (
                  <>
                    <Grid item xs={7}>
                      <Button type='submit' color='primary' block>
                        {loadingUserUpdate ? <Loader size={'sm'} /> : 'Update User'}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button color='danger' block onClick={() => setOpenDelete(true)}>
                        {loadingTeacherDelete ? <Loader size={'sm'} /> : 'Delete'}
                      </Button>
                    </Grid>
                    <CustomModal
                      title='Delete Teacher?'
                      open={openDelete}
                      setOpen={setOpenDelete}
                      acceptText='Delete'
                      acceptHandler={deleteUserHandler}
                    >
                      Are you sure you want to delete this teacher?
                    </CustomModal>
                  </>
                )}
                {errorUserUpdate && (
                  <Grid item md={12}>
                    <Message message={<span>{errorUserUpdate}</span>} color='info' />
                  </Grid>
                )}
                {errorTeacherDelete && (
                  <Grid item md={12}>
                    <Message message={<span>{errorTeacherDelete}</span>} color='info' />
                  </Grid>
                )}
              </Grid>
            </form>
            {successUserUpdate && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button color='primary' block onClick={resetUpdateHandler}>
                    OK
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TeacherInfoFrom

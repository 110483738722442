import axios from 'axios'
import {
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAIL,
  COUNTRY_ALL_REQUEST,
  COUNTRY_ALL_SUCCESS,
  COUNTRY_ALL_FAIL,
  COUNTRY_DETAILS_REQUEST,
  COUNTRY_DETAILS_SUCCESS,
  COUNTRY_DETAILS_FAIL,
} from '../constants/countryConstants'

export const getCountriesList = () => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_LIST_REQUEST })

    const { data } = await axios.get('/api/countries')

    dispatch({ type: COUNTRY_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: COUNTRY_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAllCountries = () => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_ALL_REQUEST })

    const { data } = await axios.get('/api/countries/all')

    dispatch({ type: COUNTRY_ALL_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: COUNTRY_ALL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCountryById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRY_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/countries/${id}`)

    dispatch({ type: COUNTRY_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: COUNTRY_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

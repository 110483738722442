import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Tabs, Tab, Icon, makeStyles, IconButton, Snackbar } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'

import styles from './styles/customTabsStyle'

const useStyles = makeStyles(styles)

const CustomTabs = ({ headerColor, plainTabs, tabs, title, rtlActive, className }) => {
	const [value, setValue] = useState(0)
	const [defaultName, setDefaultName] = useState(false)

	const handleChange = (event, value) => {
		setValue(value)
	}
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)
	const { posted } = useSelector((s) => s.countryPostVerifyPosted)

	useEffect(() => {
		if (posted) {
			setValue(0)
		}
	}, [posted])
	useEffect(() => {
		if (!userInfo) {
			history.push('/auth/login')
		} else {
			if (userInfo.isDefaultName) {
				setDefaultName(true)
			}
		}
	}, [userInfo, history])

	const cardTitle = classNames({
		[classes.cardTitle]: true,
		[classes.cardTitleRTL]: rtlActive,
	})

	const handleChangeName = () => {
		setDefaultName(false)
		setValue(1)
	}

	return (
		<>
			<Card plain={plainTabs} className={classNames(classes.cardRoot, className)}>
				<CardHeader color={headerColor} plain={plainTabs}>
					{title !== undefined ? <div className={cardTitle}>{title}</div> : null}
					<Tabs
						value={value}
						onChange={handleChange}
						classes={{
							root: classes.tabsRoot,
							indicator: classes.displayNone,
						}}
					>
						{tabs
							.filter((d) => d !== undefined)
							.map((prop, key) => {
								let icon = {}
								if (prop.tabIcon) {
									icon = {
										icon: typeof prop.tabIcon === 'string' ? <Icon>{prop.tabIcon}</Icon> : <prop.tabIcon />,
									}
								}
								return (
									<Tab
										classes={{
											root: classes.tabRootButton,
											label: classes.tabLabel,
											selected: classes.tabSelected,
											wrapper: classes.tabWrapper,
										}}
										key={key}
										label={prop.tabName}
										{...icon}
									/>
								)
							})}
					</Tabs>
				</CardHeader>
				<CardBody>
					{tabs
						.filter((d) => d !== undefined)
						.map((prop, key) => {
							if (key === value) {
								return (
									<div key={key} className={classes.contentDiv}>
										{prop.tabContent}
									</div>
								)
							}
							return null
						})}
				</CardBody>
			</Card>
			<Snackbar
				open={defaultName}
				autoHideDuration={6000}
				onClose={() => setDefaultName(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				message='You have to change your default name and password.'
				action={
					<IconButton size='small' aria-label='close' color='inherit' onClick={handleChangeName}>
						<Close fontSize='small' />
					</IconButton>
				}
			/>
		</>
	)
}

CustomTabs.propTypes = {
	headerColor: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary', 'rose']),
	title: PropTypes.string,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			tabName: PropTypes.string.isRequired,
			tabIcon: PropTypes.object,
			tabContent: PropTypes.node.isRequired,
		}),
	),
	rtlActive: PropTypes.bool,
	plainTabs: PropTypes.bool,
}

export default CustomTabs

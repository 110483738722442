import { useEffect, useRef } from 'react'
import { Card, Grid, makeStyles } from '@material-ui/core'
import CardBody from '../../../../components/Card/CardBody'
import TestimonialCardForm from './TestimonialCardForm'
import styles from '../styles/testimonialCardStyles'

const useStyles = makeStyles(styles)

const TestimonialCard = ({ testimonial, setTestimonialDetails }) => {
    const editTestimonialForm = useRef(null)
    const classes = useStyles()

    const scrollToRef = () => {
        editTestimonialForm.current?.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {
        scrollToRef()
    }, [testimonial])

    return (
        <Grid container ref={editTestimonialForm}>
            <Grid item xs={12} sm={12} md={12}>
                <Card >
                    <CardBody profile>
                        <h4 className={classes.cardTitle}>Testimonial of {testimonial?.teacher?.name}</h4>
                        <TestimonialCardForm testimonial={testimonial} setTestimonialDetails={setTestimonialDetails} />
                    </CardBody>
                </Card>
            </Grid>
        </Grid>
    )
}

export default TestimonialCard

import { infiniteScroll } from '../../../../assets/jss/material-ui-styles'
const styles = {
  infiniteScroll,
  root: {
    width: '100%',
    marginBottom: 10,
  },
  studentInfoDiv: {
    border: '3px solid #e5e5e5',
    borderRadius: '6px',
    paddingTop: '6px',
    marginBottom: '7px',
    '& h5': {
      textAlign: 'center',
      margin: 0,
      color: '#1f7a2e',
    },
    '& p': {
      margin: 0,
    },
  },
  btnwrapper: {
    padding: 10,
    '& svg': {
      margin: 0,
    },
  },
  imageDropdownButton: {
    padding: '0px',
    borderRadius: '50%',
    marginLeft: '25px',
    backgroundColor: '#f3f3f3',
  },
  contentLine: {
    textAlign: 'center',
  },
  emptyStudentCard: {
    textAlign: 'center',
  },
}
export default styles

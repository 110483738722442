const styles = {
  customTabsRoot: {
    minHeight: '500px',
    height: '100% !important',
  },
  emptyMsg: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 10,
    '& svg': {
      fontSize: '120px',
      color: '#43a047',
    },
  },
  cardTextCenter: {
    textAlign: 'center',
    paddingTop: 10,
  },

  cardTopMsg: {
    margin: '15px 0',
  },
}

export default styles

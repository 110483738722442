import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/homeScreenStyles'

const useStyles = makeStyles(styles)

const SocialNetworks = () => {
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<Grid container className={classes.socialSection}>
			<Grid item md={12} sm={12} xs={12}>
				<h4>{t('layout.t_social-networks')}</h4>
			</Grid>
			<Grid item xs={6} md={3} sm={6}>
				<Button
					link
					onClick={() => window.open('https://www.facebook.com/planetfraternity/')}
					className={classes.iconNetworks}
				>
					<img src='/assets/img/facebook.png' alt='facebook' />
				</Button>
			</Grid>
			<Grid item xs={6} md={3} sm={6}>
				<Button
					link
					onClick={() => window.open('https://www.instagram.com/planetfraternity/')}
					className={classes.iconNetworks}
				>
					<img src='/assets/img/instagram.png' alt='instagram' />
				</Button>
			</Grid>
			<Grid item xs={6} md={3} sm={6}>
				<Button
					link
					onClick={() => window.open('https://www.linkedin.com/company/planet-fraternity/')}
					className={classes.iconNetworks}
				>
					<img src='/assets/img/linkedin.png' alt='linkedin' />
				</Button>
			</Grid>
			<Grid item xs={6} md={3} sm={6}>
				<Button
					link
					onClick={() => window.open('https://www.youtube.com/channel/UCp0OByfzxidehvmF8NHueTw/')}
					className={classes.iconNetworks}
				>
					<img src='/assets/img/youtube.png' alt='youtube' />
				</Button>
			</Grid>
		</Grid>
	)
}

export default SocialNetworks

import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import CardAvatar from '../../../../components/Card/CardAvatar'
import StudentCardForm from './StudentCardForm'
import { getCenterById } from '../../../../redux/actions/centerActions'
import styles from '../styles/studentCardstyles'

const useStyles = makeStyles(styles)

const StudentCard = ({ student, setStudentDetails }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const editstudentform = useRef(null)

    const { center } = useSelector((state) => state.centerDetails)

    const scrollToRef = () => {
        editstudentform.current?.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {
        scrollToRef()
    }, [student])

    useEffect(() => {
        dispatch(getCenterById(student.center))
    }, [dispatch, student])

    return (
        <Grid container ref={editstudentform}>
            <Grid item xs={12} sm={12} md={12}>
                <Card profile>
                    <CardAvatar profile>
                        <img src={`${axios.defaults.baseURL}${student.avatar}`} alt={student.name} />
                    </CardAvatar>
                    <CardBody profile>
                        <h4 className={classes.cardTitle}>{student.name}</h4>
                        <span className={classes.cardSubTitle}>Center · {center && center.name}</span>
                        <span className={classes.cardSubTitle}>
                            Country · {student && student.country.name} ·{' '}
                            <img className={classes.countryFlag} src={`${axios.defaults.baseURL}${student.country.flag}`} alt={student.country.name} />
                        </span>
                        <StudentCardForm student={student} setStudentDetails={setStudentDetails} />
                    </CardBody>
                </Card>
            </Grid>
        </Grid>
    )
}

export default StudentCard

import * as types from '../constants/writingContestConstants'

export const writingCRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_C_REGISTER_REQUEST:
      return { loadingWritingCRegister: true }
    case types.WRITING_C_REGISTER_SUCCESS:
      return {
        loadingWritingCRegister: false,
        successWritingCRegister: true,
        writingContest: action.payload,
      }
    case types.WRITING_C_REGISTER_FAIL:
      return {
        loadingWritingCRegister: false,
        errorWritingCRegister: action.payload,
      }
    case types.WRITING_C_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const writingParticipationRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_PARTICIPATION_REGISTER_REQUEST:
      return { loadingWritingParticipationRegister: true }
    case types.WRITING_PARTICIPATION_REGISTER_SUCCESS:
      return {
        loadingWritingParticipationRegister: false,
        successWritingParticipationRegister: true,
      }
    case types.WRITING_PARTICIPATION_REGISTER_FAIL:
      return {
        loadingWritingParticipationRegister: false,
        errorWritingParticipationRegister: action.payload,
      }
    case types.WRITING_PARTICIPATION_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const writingCListReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_C_LIST_REQUEST:
      return { loadingWritingCList: true }
    case types.WRITING_C_LIST_SUCCESS:
      return {
        loadingWritingCList: false,
        thatsIt: action.payload.length === 0 ? true : false,
        writingContests: action.payload,
      }
    case types.WRITING_C_LIST_FAIL:
      return {
        loadingWritingCList: false,
        errorWritingCList: action.payload,
      }
    case types.WRITING_C_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const writingCCurrentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_C_CURRENT_REQUEST:
      return { loadingWritingCCurrent: true }
    case types.WRITING_C_CURRENT_SUCCESS:
      return {
        loadingWritingCCurrent: false,
        writingContest: action.payload,
      }
    case types.WRITING_C_CURRENT_FAIL:
      return {
        loadingWritingCCurrent: false,
        errorWritingCCurrent: action.payload,
      }
    case types.WRITING_C_CURRENT_RESET:
      return {}
    default:
      return state
  }
}

export const writingCDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_C_DETAILS_REQUEST:
      return { loadingWritingCDetails: true }
    case types.WRITING_C_DETAILS_SUCCESS:
      return {
        loadingWritingCDetails: false,
        writingContest: action.payload,
      }
    case types.WRITING_C_DETAILS_FAIL:
      return {
        loadingWritingCDetails: false,
        errorWritingCDetails: action.payload,
      }
    case types.WRITING_C_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const writingCUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_C_UPDATE_REQUEST:
      return { loadingWritingCUpdate: true }
    case types.WRITING_C_UPDATE_SUCCESS:
      return {
        loadingWritingCUpdate: false,
        successWritingCUpdate: true,
        writingContest: action.payload,
      }
    case types.WRITING_C_UPDATE_FAIL:
      return {
        loadingWritingCUpdate: false,
        errorWritingCUpdate: action.payload,
      }
    case types.WRITING_C_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const writingCDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.WRITING_C_DELETE_REQUEST:
      return { loadingWritingCDelete: true }
    case types.WRITING_C_DELETE_SUCCESS:
      return {
        loadingWritingCDelete: false,
        successWritingCDelete: true,
      }
    case types.WRITING_C_DELETE_FAIL:
      return {
        loadingWritingCDelete: false,
        errorWritingCDelete: action.payload,
      }
    case types.WRITING_C_DELETE_RESET:
      return {}
    default:
      return state
  }
}

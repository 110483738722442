import { makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Parallax from '../../../components/Parallax/Parallax'
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import { useTranslation } from 'react-i18next'
import Ican from '../../../components/Ican/Ican'
import styles from './styles/icanScreenStyles'

const useStyles = makeStyles(styles)

const IcanScreen = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation('global')

  return (
    <>
      <Meta title={'I Can - Planet Fraternity'} />
      <Parallax image={'/assets/img/public-site/bg-i-can.png'}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.titleBig}>{t('layout.t_i-can')}</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <Ican />
    </>
  )
}

export default IcanScreen

import { container } from '../../../assets/jss/material-ui-styles'
const styles = {
  container,
  root: {
    padding: '40px 100px',
    borderTop: '5px solid #1db727',
    color: '#1f7a2f',
    backgroundColor: '#fff',
    zIndex: 1000,
  },
  footerItem: {
    padding: '0 0 50px',
    display: 'flex',
    justifyContent: 'center',
  },
  listTitle: {
    fontSize: '20px',
  },
  logoDiv: {
    textAlign: 'center',
    margin: '0 0 50px',
  },
  logoFooter: {
    height: '140px',
  },
  footerLinksList: {
    padding: 0,
    color: 'white',
  },
  footerIcon: {
    color: '#385898',
    height: '20px',
    width: '20px',
  },
  footerIconYoutube: {
    color: '#c4302b',
    height: '20px',
    width: '20px',
  },
  footerLink: {
    color: '#1f7a2f',
    margin: '0 3px',
    '& a': {
      color: '#1f7a2f',
    },
  },
  divider: {
    margin: '30px',
    backgroundColor: '#1db727',
  },

  '@media screen and (max-width: 590px)': {
    root: {
      padding: '15px',
    },
    footerLinksList: {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
    },
    logoFooter: {
      maxWidth: '100%',
      height: '80%',
    },
    logoDiv: {
      margin: '0px',
    },
  },
}

export default styles

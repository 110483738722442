import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import Button from '../../../../components/Buttons/Button'
import Message from '../../../../components/Message/Message'
import { teamPanelUpdateInfo } from '../../../../redux/actions/teamsActions'
import { getCentersNotPartnered } from '../../../../redux/actions/centerActions'
import styles from '../styles/assignPartnersModalStyles.js'

const useStyles = makeStyles(styles)

const AssignPartnersModal = ({ panel, setOpen }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const [partnersIds, setPartnersIds] = useState([])
  const [linkBtn, setlinkBtn] = useState(true)

  const { loadingNotPartnered, errorNotPartnered, centersNotPartnered } = useSelector(
    (state) => state.centerNotPartnered
  )
  const { loadingTeamUpdate, errorTeamUpdate } = useSelector((state) => state.teamUpdate)

  useEffect(() => {
    dispatch(getCentersNotPartnered())
  }, [dispatch])

  useEffect(() => {
    if (partnersIds.length > 1) {
      setlinkBtn(false)
    } else {
      setlinkBtn(true)
    }
  }, [partnersIds])

  const selectPartnerHandler = (id) => {
    let centerId = partnersIds.find((centeritem) => centeritem._id === id)
    if (centerId === undefined) {
      const centerPartneredId = {
        _id: id,
      }
      setPartnersIds((prev) => [...prev, centerPartneredId])
    } else {
      let newArray = partnersIds.filter((item) => item._id !== id)
      setPartnersIds(newArray)
    }
  }
  const linkCentersHandler = () => {
    let updatePanel = {
      _id: panel._id,
      partners: partnersIds,
    }
    dispatch(teamPanelUpdateInfo(updatePanel))
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          {centersNotPartnered && centersNotPartnered.length < 1 ? (
            <>
              <p>There are not unpartnered centers.</p>
              <p>It may be that there are no centers or that all centers already have partners.</p>
            </>
          ) : (
            <>
              <p>This is the list of center that are not partnered yet.</p>
              <p>You must select at least two centers.</p>
            </>
          )}
        </Grid>
        <Grid item xs={12} className={classes.notaPartneredList}>
          {loadingNotPartnered ? (
            <Loader />
          ) : errorNotPartnered ? (
            <Message message={<span>{errorNotPartnered}</span>} color='info' />
          ) : (
            centersNotPartnered &&
            centersNotPartnered.map((nPartnered, index) => {
              let center = partnersIds.find((centeritem) => centeritem._id === nPartnered._id)
              let checkedCenter = true
              if (center === undefined) {
                checkedCenter = false
              }
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={checkedCenter}
                      onChange={() => selectPartnerHandler(nPartnered._id)}
                      name={nPartnered.name}
                    />
                  }
                  label={nPartnered.name}
                />
              )
            })
          )}
        </Grid>
        {errorTeamUpdate && (
          <Grid item xs={12}>
            <Message message={<span>{errorTeamUpdate}</span>} color='info' />
          </Grid>
        )}
        <Grid item xs={12} className={classes.flexJustifyEnd}>
          {centersNotPartnered && centersNotPartnered.length < 1 ? (
            <>
              <Button link onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button color='primary' onClick={() => history.push('/admin/centers')}>
                Go to Centers
              </Button>
            </>
          ) : (
            <Button color='primary' onClick={linkCentersHandler} disabled={linkBtn}>
              {loadingTeamUpdate ? <Loader /> : 'Link centers'}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default AssignPartnersModal

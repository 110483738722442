import { makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import MergeLittleScreenDashboard from './components/MergeLittleScreenDashboard'
import styles from './styles/mergeCentersScreenStyles'

const useStyles = makeStyles(styles)

const LittleMergeCentersScreen = ({ history }) => {
	const classes = useStyles()

	return (
		<>
			<Meta title={'Merge Little - Planet Fraternity'} />
			<div className={classes.root}>
				<MergeLittleScreenDashboard topic={'all'} />
			</div>
		</>
	)
}

export default LittleMergeCentersScreen

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import { registerStudent } from '../../../../redux/actions/studentActions'
import styles from '../styles/studentRegisterFormModalStyles'

const useStyles = makeStyles(styles)

const StudentRegisterFormModal = ({ setOpen, open, groupId }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')

    const studentRegister = useSelector((state) => state.studentRegister)
    const {
        loadingStudentRegister,
        errorStudentRegister,
        successStudentRegister,
    } = studentRegister

    useEffect(() => {
        if (successStudentRegister) {
            setOpen(false)
            setName('')
            setUsername('')
        }
    }, [successStudentRegister, setOpen, open])

    const submitFormHandler = (e) => {
        e.preventDefault()
        const createStudent = {
            name,
            username,
            groupId,
        }
        dispatch(registerStudent(createStudent))
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <form onSubmit={submitFormHandler} autoComplete='off'>
                    <CustomInput
                        labelText='Name'
                        id='name-student'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl,
                        }}
                        inputProps={{
                            type: 'text',
                            value: name,
                            placeholder: 'Enter complete name',
                            onChange: (e) => setName(e.target.value),
                            required: true,
                        }}
                    />
                    <CustomInput
                        labelText='Username'
                        id='username-student'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl,
                        }}
                        inputProps={{
                            type: 'text',
                            value: username,
                            placeholder: 'example: jdoe',
                            onChange: (e) => setUsername(e.target.value),
                            required: true,
                        }}
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <p className='py-3'>
                                The default password is going to be 1 through 6
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='submit' color='primary' block>
                                {loadingStudentRegister ? (
                                    <Loader size={'sm'} />
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Grid>
                    </Grid>

                    {errorStudentRegister && (
                        <Message>{errorStudentRegister}</Message>
                    )}
                </form>
            </Grid>
        </Grid>
    )
}

export default StudentRegisterFormModal

import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import styles from './styles/cookiesPolicyScreenStyles'

const useStyles = makeStyles(styles)

const CookiesPolicyScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation('global')

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <h1>{t('page.t_cookies-policy-title-1')}</h1>
        <div className={classes.cookiesSection}>
          <p>{t('page.t_cookies-policy-text-1')}</p>
          <p>{t('page.t_cookies-policy-text-2')}</p>
        </div>
        <div className={classes.cookiesSection}>
          <h2>{t('page.t_cookies-policy-title-2')}</h2>
          <p>{t('page.t_cookies-policy-text-3')}</p>
          <p>{t('page.t_cookies-policy-text-4')}</p>
        </div>
        <div className={classes.cookiesSection}>
          <h2>{t('page.t_cookies-policy-title-3')}</h2>
          <p>{t('page.t_cookies-policy-text-5')}</p>
        </div>
        <div className={classes.cookiesSection}>
          <h2>{t('page.t_cookies-policy-title-4')}</h2>
          <p>{t('page.t_cookies-policy-text-6')}</p>
        </div>
        <div className={classes.cookiesSection}>
          <h2>{t('page.t_cookies-policy-title-5')}</h2>
          <p>{t('page.t_cookies-policy-text-7')}</p>
        </div>
        <div className={classes.cookiesSection}>
          <h2>{t('page.t_cookies-policy-title-6')}</h2>
          <p>{t('page.t_cookies-policy-text-8')}</p>
        </div>
      </Grid>
    </Grid>
  )
}

export default CookiesPolicyScreen

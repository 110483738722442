export const GROUP_REGISTER_REQUEST = 'GROUP_REGISTER_REQUEST'
export const GROUP_REGISTER_SUCCESS = 'GROUP_REGISTER_SUCCESS'
export const GROUP_REGISTER_FAIL = 'GROUP_REGISTER_FAIL'
export const GROUP_REGISTER_RESET = 'GROUP_REGISTER_RESET'

export const GROUP_LIST_BY_CENTER_REQUEST = 'GROUP_LIST_BY_CENTER_REQUEST'
export const GROUP_LIST_BY_CENTER_SUCCESS = 'GROUP_LIST_BY_CENTER_SUCCESS'
export const GROUP_LIST_BY_CENTER_FAIL = 'GROUP_LIST_BY_CENTER_FAIL'
export const GROUP_LIST_BY_CENTER_RESET = 'GROUP_LIST_BY_CENTER_RESET'

export const GROUP_DETAILS_REQUEST = 'GROUP_DETAILS_REQUEST'
export const GROUP_DETAILS_SUCCESS = 'GROUP_DETAILS_SUCCESS'
export const GROUP_DETAILS_FAIL = 'GROUP_DETAILS_FAIL'
export const GROUP_DETAILS_RESET = 'GROUP_DETAILS_RESET'

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST'
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS'
export const GROUP_UPDATE_FAIL = 'GROUP_UPDATE_FAIL'
export const GROUP_UPDATE_RESET = 'GROUP_UPDATE_RESET'

export const GROUP_TEACHER_DELETE_REQUEST = 'GROUP_TEACHER_DELETE_REQUEST'
export const GROUP_TEACHER_DELETE_SUCCESS = 'GROUP_TEACHER_DELETE_SUCCESS'
export const GROUP_TEACHER_DELETE_FAIL = 'GROUP_TEACHER_DELETE_FAIL'
export const GROUP_TEACHER_DELETE_RESET = 'GROUP_TEACHER_DELETE_RESET'

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_FAIL = 'GROUP_DELETE_FAIL'
export const GROUP_DELETE_RESET = 'GROUP_DELETE_RESET'

export const littlePFAgesRanges = ['8-9', '9-10', '10-11', '11-12']

export const groupCategories = [
	{
		value: 'common',
		label: 'Planet Fraternity',
	},
	{
		value: 'little',
		label: 'Little Planet Fraternity',
	},
]

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, makeStyles } from '@material-ui/core'
import CustomModal from '../../../components/Modal/CustomModal'
import Loader from '../../../components/Loader/Loader'
import Meta from '../../../components/Meta/Meta'
import TopicCard from './components/TopicCard/TopicCard'
import TopicRegisterFormModal from './components/TopicRegisterFormModal/TopicRegisterFormModal'
import TopicsListCard from './components/TopicListCard/TopicListCard'
import TopicRightSection from './components/TopicRightSection/TopicRightSection'
import TopicButton from './components/TopicButton/TopicButton'
import { getTopics } from '../../../redux/actions/topicActions'
import { TOPIC_DELETE_RESET, TOPIC_REGISTER_RESET, TOPIC_UPDATE_RESET } from '../../../redux/constants/topicConstants'
import styles from './styles/topicListScreenStyles'

const useStyles = makeStyles(styles)

const TopicListScreen = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [topicDetails, setTopicDetails] = useState('')
  const [topicsArray, setTopicsArray] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [initalHasMore, setInitialHasMore] = useState(true)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { topics, thatsIt, errorTopicList } = useSelector((state) => state.topicList)
  const { successTopicUpdate } = useSelector((state) => state.topicUpdate)
  const { successTopicDelete } = useSelector((state) => state.topicDelete)
  const { successTopicRegister } = useSelector((state) => state.topicRegister)

  useEffect(() => {
    if (userInfo?.isSuper) {
      if (successTopicDelete || successTopicUpdate || successTopicRegister) {
        if (successTopicDelete) {
          dispatch({ type: TOPIC_DELETE_RESET })
        }
        if (successTopicUpdate) {
          dispatch({ type: TOPIC_UPDATE_RESET })
        }
        if (successTopicRegister) {
          dispatch({ type: TOPIC_REGISTER_RESET })
        }
        setTopicsArray([])
        setStart(1)
        setInitialHasMore(true)
        setHasMore(true)
        return true
      } else {
        if (initalHasMore) {
          dispatch(getTopics(count, 0))
          setInitialHasMore(false)
        } else if (topics && topics.length > 0) {
          setTopicsArray((prev) => [...prev, ...topics])
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [
    history,
    dispatch,
    topics,
    userInfo,
    count,
    initalHasMore,
    successTopicUpdate,
    successTopicDelete,
    successTopicRegister,
  ])

  useEffect(() => {
    if (thatsIt || errorTopicList === 'Topics not found.') {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorTopicList])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getTopics(count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Topics List - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h1>Topics List</h1>
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <TopicButton setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfiniteScroll
            dataLength={topicsArray.length}
            hasMore={hasMore}
            next={fetchData}
            loader={<Loader />}
            height={'60vh'}
            className={classes.infiniteScroll}
            endMessage={
              <p className={classes.lastLine}>
                <b>Thats it.</b>
              </p>
            }
          >
            {topicsArray.map((topic) => (
              <TopicsListCard
                key={topic._id}
                topic={topic}
                dropdownList={[<div onClick={() => setTopicDetails(topic)}>View Topic</div>]}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} md={8}>
          {topicDetails !== '' ? (
            <TopicCard topic={topicDetails} setTopicDetails={setTopicDetails} />
          ) : (
            <TopicRightSection />
          )}
        </Grid>
      </Grid>
      <CustomModal title='Register Topic' open={open} setOpen={setOpen} actions={false}>
        <TopicRegisterFormModal open={open} setOpen={setOpen} />
      </CustomModal>
    </div>
  )
}

export default TopicListScreen

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { LocationCity } from '@material-ui/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import Button from '../../../components/Buttons/Button'
import CustomModal from '../../../components/Modal/CustomModal'
import CenterCard from './components/CenterCard'
import CenterListCard from './components/CenterListCard'
import CenterRegisterFormModal from './components/CenterRegisterFormModal'
import { getCenters } from '../../../redux/actions/centerActions'
import {
	CENTER_DELETE_RESET,
	CENTER_DETAILS_RESET,
	CENTER_REGISTER_RESET,
	CENTER_UPDATE_RESET,
} from '../../../redux/constants/centerConstants'
import styles from './styles/centerListScreenStyles'

const useStyles = makeStyles(styles)

const CenterListScreen = ({ match, history }) => {
	const keyword = match.params.keyword
	const dispatch = useDispatch()
	const classes = useStyles()

	const [matchKeyword, setMatchKeyword] = useState('')
	const [centerArray, setCenterArray] = useState([])
	const [count] = useState(15)
	const [start, setStart] = useState(1)
	const [hasMore, setHasMore] = useState(true)
	const [initalHasMore, setInitialHasMore] = useState(true)
	const [open, setOpen] = useState(false)
	const [centerDetail, setCenterDetail] = useState(null)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { successCenterRegister } = useSelector((state) => state.centerRegister)
	const { successCenterUpdate, centerUpdated } = useSelector((state) => state.centerUpdate)
	const { successCenterDelete } = useSelector((state) => state.centerDelete)
	const { thatsIt, centers, error } = useSelector((state) => state.centerList)

	useEffect(() => {
		if (successCenterUpdate) {
			setCenterDetail(centerUpdated)
		}
	}, [successCenterUpdate, centerUpdated])
	useEffect(() => {
		if (userInfo) {
			if (successCenterDelete || successCenterRegister || successCenterUpdate) {
				if (successCenterDelete) {
					dispatch({ type: CENTER_DELETE_RESET })
				} else if (successCenterRegister) {
					dispatch({ type: CENTER_REGISTER_RESET })
					setCenterDetail(null)
				} else if (successCenterUpdate) {
					dispatch({ type: CENTER_UPDATE_RESET })
				}
				setCenterArray([])
				setStart(1)
				setHasMore(true)
				setInitialHasMore(true)
				return true
			} else {
				if (keyword !== undefined) {
					if (keyword !== matchKeyword) {
						setCenterArray([])
						setInitialHasMore(true)
						setMatchKeyword(keyword)
						setStart(1)
					} else {
						if (initalHasMore) {
							setInitialHasMore(false)
							dispatch(getCenters(keyword, count, 0))
						} else if (centers && centers.length > 0) {
							setCenterArray((prev) => [...prev, ...centers])
						}
					}
				} else {
					if (initalHasMore) {
						dispatch(getCenters(keyword, count, 0))
						setInitialHasMore(false)
					} else if (centers && centers.length > 0) {
						setCenterArray((prev) => [...prev, ...centers])
					}
				}
			}
		} else {
			history.push('/auth/login')
		}
	}, [
		userInfo,
		history,
		dispatch,
		keyword,
		count,
		centers,
		initalHasMore,
		matchKeyword,
		successCenterDelete,
		successCenterRegister,
		successCenterUpdate,
	])
	useEffect(() => {
		if (thatsIt || error === 'Centers not found.') {
			setHasMore(false)
		} else {
			setHasMore(true)
		}
	}, [thatsIt, error])
	useEffect(() => {
		return () => {
			dispatch({ type: CENTER_DETAILS_RESET })
		}
	}, [dispatch])

	const fetchData = () => {
		if (hasMore) {
			setStart(start + 1)
			dispatch(getCenters(keyword, count, start))
		}
	}

	return (
		<div className={classes.root}>
			<Meta title={'Center List - Planet Fraternity'} />
			<Grid container spacing={5}>
				<Grid item xs={12} md={3}>
					<h1>Centers List</h1>
				</Grid>
				<Grid item xs={12} md={9} className={classes.contentItemsEnd}>
					{keyword && (
						<Button color='info' className={classes.btnResetSearch} onClick={() => history.push('/admin/centers')}>
							Reset Search
						</Button>
					)}
					<Button color='success' onClick={() => setOpen(true)}>
						+ Create Center
					</Button>
				</Grid>
				<Grid item xs={12} md={4}>
					<InfiniteScroll
						dataLength={centerArray.length}
						next={fetchData}
						hasMore={hasMore}
						loader={<Loader />}
						height={'60vh'}
						className={classes.infiniteScroll}
						endMessage={
							<p className={classes.contentLine}>
								<b>Thats it.</b>
							</p>
						}
					>
						{centerArray.map((center) => (
							<CenterListCard key={center._id} center={center} setCenterDetail={setCenterDetail} />
						))}
					</InfiniteScroll>
				</Grid>
				<Grid item xs={12} md={8}>
					{centerDetail !== null ? (
						<CenterCard center={centerDetail} setCenterDetail={setCenterDetail} />
					) : (
						<div className={classes.rightSection}>
							<LocationCity className={classes.schoolIcon} />
							<h2>Select center's names to preview their profile.</h2>
						</div>
					)}
				</Grid>
			</Grid>
			<CustomModal title='Register Center' open={open} setOpen={setOpen} actions={false}>
				<CenterRegisterFormModal setOpen={setOpen} />
			</CustomModal>
		</div>
	)
}

export default CenterListScreen

import { Grid, CardContent, makeStyles } from '@material-ui/core'
import {
	CropDin,
	StarBorder,
	PlaylistAddCheck,
	Favorite,
	Settings,
	Stars,
	EmojiObjects,
	Share,
} from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import Card from '../../../components/Card/Card'
import CardBody from '../../../components/Card/CardBody'
import Parallax from '../../../components/Parallax/Parallax'
import { useTranslation } from 'react-i18next'
import styles from './styles/howItWorksScreenStyles'

const useStyles = makeStyles(styles)

const HowItWorksScreen = ({ history }) => {
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<>
			<Meta title={'How it works - Planet Fraternity'} />
			<Parallax image={'/assets/img/public-site/bg-be-the-change.png'} small />
			<div className={classes.sections}>
				<Grid container className={classes.sectionRootWReverse}>
					<Grid item xs={12} md={6} className={classes.onTopCardRoot}>
						<Card className={classes.onTopCardLeft}>
							<CardContent>
								<div className={classes.descRoot}>
									<h1>{t('layout.t_how-it-works')}</h1>
									<p>{t('page.t_how-works-text-1')}</p>
									<p>{t('page.t_how-works-text-2')}</p>
									<p>{t('page.t_how-works-text-3')}</p>
								</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6} className={classes.imgRoot}>
						<img src='/assets/img/public-site/goals-planet.png' alt='Be The Change' width='100%' />
					</Grid>
				</Grid>
				<Grid container className={classes.sectionRoot}>
					<Grid item xs={12} md={6} className={classes.imgRoot}>
						<img src='/assets/img/public-site/how-it-works-1.png' alt='Be The Change' width='100%' />
					</Grid>
					<Grid item xs={12} md={6} className={classes.onTopCardRoot}>
						<Card className={classes.onTopCardRight}>
							<CardContent>
								<div className={classes.descRoot}>
									<p>{t('page.t_how-works-text-4')}</p>
									<ol>
										<li>{t('page.t_how-works-text-5')}</li>
										<li>{t('page.t_how-works-text-6')}</li>
										<li>{t('page.t_how-works-text-7')}</li>
										<li>{t('page.t_how-works-text-8')}</li>
									</ol>
									<p>{t('page.t_how-works-text-9')}</p>
									<p>{t('page.t_how-works-text-10')}</p>
								</div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container className={classes.sectionRootReverse}>
					<Grid item xs={12} md={6} className={classes.onTopCardRoot}>
						<Card className={classes.onTopCardLeftTwo}>
							<CardContent>
								<div className={classes.descMethodology}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<h2>{t('layout.t_methodology-phases')}</h2>
										</Grid>
										<Grid item xs={6}>
											<h3 onClick={() => history.push('/feel')}>
												<Favorite color='error' />
												1. {t('layout.t_feel')}
											</h3>
											<p>{t('layout.t_feel-desc')}</p>
										</Grid>
										<Grid item xs={6}>
											<h3 onClick={() => history.push('/imagine')}>
												<EmojiObjects className={classes.imagineIcon} />
												2. {t('layout.t_imagine')}
											</h3>
											<p>{t('layout.t_imagine-desc')}</p>
										</Grid>
										<Grid item xs={6}>
											<h3 onClick={() => history.push('/act')}>
												<Settings className={classes.actIcon} />
												3. {t('layout.t_act')}
											</h3>
											<p>{t('layout.t_act-desc')}</p>
										</Grid>
										<Grid item xs={6}>
											<h3 onClick={() => history.push('/share')}>
												<Share color='primary' />
												4. {t('layout.t_share')}
											</h3>
											<p>{t('layout.t_share-desc')}</p>
										</Grid>
									</Grid>
								</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6} className={classes.methodologyImg}>
						<img
							src='/assets/img/logo-planetfraternity500x500.png'
							width='100%'
							alt='logo Planet Fraternity'
							loading='lazy'
						/>
					</Grid>
				</Grid>
				<Grid container className={classes.sectionRoot}>
					<Grid item xs={12} md={6} className={classes.imgRoot}>
						<img src='/assets/img/public-site/be-the-change.png' alt='Be The Change' width='100%' />
					</Grid>
					<Grid item xs={12} md={6} className={classes.onTopCardRoot}>
						<Card className={classes.onTopCardRight}>
							<CardContent>
								<div className={classes.descRoot}>
									<div>
										<CropDin className={classes.nbtcIcon} />
										<CropDin className={classes.nbtcIcon} />
										<StarBorder className={classes.btcIcon} />
										<CropDin className={classes.nbtcIcon} />
										<CropDin className={classes.nbtcIcon} />
									</div>
									<p>{t('page.t_btc_text_one')}</p>
									<p>{t('page.t_btc_text_two')}</p>
									<div className={classes.descActions}>
										<Button link onClick={() => history.push('/contact')}>
											{t('layout.buttons.t_contact-us')}
										</Button>
									</div>
								</div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item md={6} className={classes.sectionRoot}>
						<Grid container className={classes.sectionHeader}>
							<Grid item xs={2}>
								<PlaylistAddCheck />
							</Grid>
							<Grid item xs={10}>
								<span>{t('layout.t_what-it-es')}</span>
							</Grid>
						</Grid>
						<p>{t('page.t_btc_text_three')}</p>
						<p>{t('page.t_btc_text_three2')}</p>
					</Grid>
					<Grid item md={6} className={classes.sectionRoot}>
						<Grid container className={classes.sectionHeader}>
							<Grid item xs={2}>
								<Stars />
							</Grid>
							<Grid item xs={10}>
								<span>{t('page.t_btc_text_four')}</span>
							</Grid>
						</Grid>

						<ol>
							<li>{t('page.t_btc_text_five')}</li>
							<li>{t('page.t_btc_text_six')}</li>
							<li>{t('page.t_btc_text_seven')}</li>
						</ol>
					</Grid>
				</Grid>
			</div>

			<Grid container className={classes.parallaxBTC}>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_eight')}</h3>
							<p>{t('page.t_btc_text_nine')}</p>
							<p>{t('page.t_btc_text_ten')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_eleven')}</h3>
							<p>{t('page.t_btc_text_twelve')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_thirteen')}</h3>
							<p>{t('page.t_btc_text_fourteen')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_fifteen')}</h3>
							<p>{t('page.t_btc_text_sixteen')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_seventeen')}</h3>
							<p>{t('page.t_btc_text_eighteen')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_nineteen')}</h3>
							<p>{t('page.t_btc_text_twenty')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_twentyone')}</h3>
							<p>{t('page.t_btc_text_twentytwo')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_twentythree')}</h3>
							<p>{t('page.t_btc_text_twentyfour')}</p>
						</CardBody>
					</Card>
				</Grid>
				<Grid item md={8} className={classes.cardItem}>
					<Card>
						<CardBody>
							<h3>{t('page.t_btc_text_twentyfive')}</h3>
							<p>{t('page.t_btc_text_twentysix')}</p>
						</CardBody>
					</Card>
				</Grid>
			</Grid>
		</>
	)
}

export default HowItWorksScreen

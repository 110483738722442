import axios from 'axios'
import { Avatar, Card, CardHeader, makeStyles, Tooltip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/imageItemListStyles'

const useStyles = makeStyles(styles)

const ImageItemList = ({ item, numberItem, setImageItemDetail }) => {
  const classes = useStyles()

  return item ? (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeaderRoot}
        avatar={<Avatar aria-label='recipe' src={`${axios.defaults.baseURL}/${item.image}`}></Avatar>}
        action={
          <CustomDropdown
            left
            caret={false}
            buttonText={
              <div className={classes.btnwrapper}>
                <MoreVert />
              </div>
            }
            buttonProps={{
              className: classes.navLink + ' ' + classes.imageDropdownButton,
              color: 'transparent',
            }}
            dropdownList={[<div onClick={() => setImageItemDetail(item)}>View Image</div>]}
          />
        }
        title={
          <Tooltip placement='left-start' title={`Image N°: ${numberItem + 1}`}>
            <span>{`Image N°: ${numberItem + 1}`}</span>
          </Tooltip>
        }
      />
    </Card>
  ) : null
}

export default ImageItemList

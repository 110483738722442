import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import styles from '../styles/resourceEmptyCardStyles'

const useStyles = makeStyles(styles)

const ResourceEmptyCard = () => {
  const classes = useStyles()

  const { userInfo } = useSelector((state) => state.userLogin)

  return (
    <div className={classes.notResources}>
      {userInfo && userInfo.role === 'teacher' && (userInfo.isAdmin || userInfo.isSuper) ? (
        <h3>Here yo can create resources for teachers (PDF, JPG, XLS Etc.)</h3>
      ) : (
        <>
          <h3>Soon new resources will be added to improve platform interaction</h3>
          <h4>Come back soon!</h4>
        </>
      )}
      <img src='/assets/img/logo-planetfraternity200.png' alt='planetfraternity' />
    </div>
  )
}

export default ResourceEmptyCard

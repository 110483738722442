import { useEffect, useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Edit, Dashboard, Book } from '@material-ui/icons'
import CustomTabs from '../../../../components/CustomTabs/CustomTabs'
import TeamCard from './TeamCard'
import TeamCardForm from './TeamCardForm'
import TeamCardProjects from './TeamCardProjects'
import styles from '../styles/teamCardStyles'

const useStyles = makeStyles(styles)

const TeamTabs = ({ panel }) => {
  const classes = useStyles()
  const editpanelform = useRef(null)

  useEffect(() => {
    scrollToRef()
  }, [panel])

  const scrollToRef = () => {
    editpanelform.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Grid container ref={editpanelform}>
      <Grid item xs={12} className={classes.cardRoot}>
        <CustomTabs
          headerColor='primary'
          className={classes.tabsRoot}
          tabs={[
            {
              tabName: 'Team Info',
              tabIcon: Dashboard,
              tabContent: <TeamCard panel={panel} />,
            },
            {
              tabName: 'Edit Info',
              tabIcon: Edit,
              tabContent: <TeamCardForm panel={panel} />,
            },
            {
              tabName: 'Final Projects',
              tabIcon: Book,
              tabContent: <TeamCardProjects panel={panel} />,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default TeamTabs

const styles = {
  root: {
    padding: '150px 20px',
    width: '100%',
    textAlign: 'center',
    '& h3': {
      margin: 'auto',
      fontSize: '32px',
      marginBottom: '10px',
      lineHeight: '50px',
    },
  },
  cardImage: {
    width: '20%',
    marginTop: '10px',
  },
  rootCard: {
    height: '100%',
    width: '100%',
    margin: 'auto',
  },
  finalCard: {
    width: '95%',
    margin: 'auto',
  },
  '@media screen and (max-width: 980px)': {
    cardImage: {
      width: '25%',
    },
  },
  '@media screen and (max-width: 580px)': {
    cardImage: {
      width: '50%',
    },
  },
}

export default styles

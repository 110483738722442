import { infiniteScroll } from '../../../../assets/jss/material-ui-styles'

const styles = {
    infiniteScroll,
    lastLine: { textAlign: 'center' },
    listActions: {
        paddingTop: 15,
    },
    teacherDropArea: {
        backgroundColor: '#ececec',
        minHeight: 40,
        padding: '10px 15px',
        borderRadius: 5,
        textAlign: 'center',
        marginBottom: 15,
        margin: 0,
        color: '#65676b',
        border: '2px solid transparent',
    },
}

export default styles

import { home } from '../../../components/Meta/MetaData'
import Meta from '../../../components/Meta/Meta'
import SkyHands from './components/SkyHands'
import VideoPFSong from './components/VideoPFSong'
import CongressCarousel from './components/CongressCarousel'
import WhatHowWhenWhyCarousel from './components/WhatHowWhenWhyCarousel'
import GalleryMasonry from './components/GalleryMasonry'
import TestimonialsCarousel from '../../../components/Slider/Slider'
import BecomeSponsor from './components/BecomeSponsor'
import CarouselVideos from './components/CarouselVideos'
import CommunityMap from './components/CommunityMap'
import MiniBlog from './components/MiniBlog'
import Sponsors from './components/Sponsors'
import SocialNetworks from './components/SocialNetworks'
import ParallaxContact from './components/ParallaxContact'

const HomeScreen = () => {
	return (
		<>
			<Meta title={home.title} description={home.description} />
			<SkyHands />
			<VideoPFSong />
			<TestimonialsCarousel />
			<CongressCarousel />
			<WhatHowWhenWhyCarousel />
			<GalleryMasonry />
			<BecomeSponsor />
			<CarouselVideos />
			<CommunityMap />
			<MiniBlog />
			<Sponsors />
			<SocialNetworks />
			<ParallaxContact />
		</>
	)
}

export default HomeScreen

import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Card, CardHeader, Avatar, makeStyles, Tooltip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/centerListCardStyles'

const useStyles = makeStyles(styles)

const CenterListCard = ({ center, setCenterDetail }) => {
  const classes = useStyles()
  const history = useHistory()

  const manageCenterHandler = (id) => {
    history.push(`/admin/centers/manage/${id}`)
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeaderRoot}
        avatar={<Avatar aria-label='recipe' src={`${axios.defaults.baseURL}${center.logo}`}></Avatar>}
        action={
          <CustomDropdown
            left
            caret={false}
            buttonText={
              <div className={classes.btnwrapper}>
                <MoreVert />
              </div>
            }
            buttonProps={{
              className: classes.navLink + ' ' + classes.imageDropdownButton,
              color: 'transparent',
            }}
            dropdownList={[
              <div onClick={() => manageCenterHandler(center._id)}>Manage</div>,
              <div onClick={() => setCenterDetail(center)}>View Profile</div>,
            ]}
          />
        }
        title={
          <Tooltip placement='left-start' title={center.name}>
            <span>{center.name.length > 25 ? center.name.slice(0, 25) + '...' : center.name}</span>
          </Tooltip>
        }
        subheader={
          <Tooltip placement='left-start' title={center.email}>
            <span>{center.email.length > 21 ? center.email.slice(0, 21) + '...' : center.email}</span>
          </Tooltip>
        }
      />
    </Card>
  )
}

export default CenterListCard

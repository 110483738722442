import { makeStyles } from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import styles from '../styles/testimonialRightSectionStyles'

const useStyles = makeStyles(styles)

const TestimonialRightSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.rightSection}>
      <Assignment className={classes.peoplesIcon} />
      <h2>Select testimonial's names to preview their testimonial.</h2>
    </div>
  )
}

export default TestimonialRightSection

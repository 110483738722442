const styles = {
    flexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    notaPartneredList: {
        display: 'flex',
        flexDirection: 'column',
    },
}
export default styles

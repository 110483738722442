import { useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Card, CardHeader, Avatar, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import StudentEditFormModal from './StudentEditFormModal'
import CustomModal from '../../../../components/Modal/CustomModal'
import { deleteStudent } from '../../../../redux/actions/studentActions'
import styles from '../styles/studentListCardStyles'

const useStyles = makeStyles(styles)

const StudentListCard = ({ student, groupId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const deleteHandler = () => {
    setOpenDelete(false)
    dispatch(deleteStudent(student._id, groupId))
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label={student.username}
            src={`${axios.defaults.baseURL}${student.avatar}`}
            alt={student.username}
          ></Avatar>
        }
        action={
          <CustomDropdown
            left
            caret={false}
            buttonText={
              <div className={classes.btnwrapper}>
                <MoreVert />
              </div>
            }
            buttonProps={{
              className: classes.navLink + ' ' + classes.imageDropdownButton,
              color: 'transparent',
            }}
            dropdownList={[
              <div onClick={() => setOpenEdit(true)} className={classes.dropdownLink}>
                Edit
              </div>,
              <div onClick={() => setOpenDelete(true)} className={classes.dropdownLink}>
                Delete
              </div>,
            ]}
          />
        }
        title={'Name: ' + student.name}
        subheader={'Username: ' + student.googleMail}
      />
      <CustomModal title='Edit Student' open={openEdit} setOpen={setOpenEdit} actions={false}>
        <StudentEditFormModal setOpen={setOpenEdit} student={student} />
      </CustomModal>
      <CustomModal
        title='Delete Student?'
        open={openDelete}
        setOpen={setOpenDelete}
        acceptText='Delete'
        acceptHandler={deleteHandler}
      >
        <p>
          Are you sure you want to delete this student? <strong>{student.name}</strong>
        </p>

        <p>
          Once a student is permanently deleted, it cannot be recovered. Permanently deleting this student will
          immediately delete its related resources (if there are any).
        </p>
      </CustomModal>
    </Card>
  )
}

export default StudentListCard

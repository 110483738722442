import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Card, CardContent, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import CenterInfoForm from './components/CenterInfoForm'
import { SEND_MAIL_RESET } from '../../../redux/constants/sendMailConstants'
import styles from './styles/centerRegsiterScreenStyles'

const useStyles = makeStyles(styles)

const CenterRegisterScreen = ({ history }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { successSendMail } = useSelector((state) => state.sendEMail)

    useEffect(() => {
        return () => {
            dispatch({ type: SEND_MAIL_RESET })
        }
    }, [dispatch])

    const goHomeHandler = () => {
        history.push('/')
    }

    return (
        <div className={classes.sections}>
            <Meta title={'Register your Center - Planet Fraternity'} />
            <Grid container>
                <Grid item xs={12}>
                    <Card className={classes.cardRegister}>
                        <CardContent>
                            {successSendMail ? (
                                <div className={classes.emailSent}>
                                    <img
                                        src='/assets/img/logo-planetfraternity500x500.png'
                                        alt='logo Planet Fraternity'
                                        loading='lazy'
                                    />
                                    <h1>¡Great! You have just successfully registered your center.</h1>
                                    <p>
                                        We just recieve your subscription, we will contact you for further information.
                                    </p>
                                    <p>
                                        Learn more about Planet Fraternity in our website{' '}
                                        <Button color='primary' onClick={goHomeHandler}>
                                            Home
                                        </Button>
                                    </p>
                                </div>
                            ) : (
                                <CenterInfoForm />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default CenterRegisterScreen

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  FormControlLabel,
  Switch,
  makeStyles,
  IconButton,
  FormGroup,
  Checkbox,
  FormControl,
  Radio,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import { deleteSurvey, surveyUpdateInfo } from '../../../../redux/actions/surveyActions'
import styles from '../styles/surveyCardFormStyle'

const useStyles = makeStyles(styles)

const SurveyCardForm = ({ survey }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [surveyInfo, setSurveyInfo] = useState({})
  const [questions, setQuestions] = useState([])
  const [active, setActive] = useState(true)
  const [openDelete, setOpenDelete] = useState(false)
  const [successUpdate, setSuccessUpdate] = useState(false)

  const { loadingSurveyUpdate, successSurveyUpdate } = useSelector((state) => state.surveyUpdate)
  const { loadingSurveyDelete, errorSurveyDelete, successSurveyDelete } = useSelector((state) => state.surveyDelete)

  useEffect(() => {
    if (survey) {
      setSurveyInfo(survey)
      setQuestions(survey.questions)
      setActive(survey.active)
    }
  }, [survey])

  useEffect(() => {
    if (successSurveyUpdate) {
      setSuccessUpdate(true)
      setTimeout(() => {
        setSuccessUpdate(false)
      }, 2000)
    }
  }, [successSurveyUpdate])

  useEffect(() => {
    if (successSurveyDelete) {
      setOpenDelete(false)
    }
  }, [successSurveyDelete])

  const handleSubmit = (e) => {
    e.preventDefault()
    const info = {
      _id: surveyInfo._id,
      title: surveyInfo.title,
      active,
      questions,
    }
    dispatch(surveyUpdateInfo(info))
  }

  const deleteHandler = () => {
    dispatch(deleteSurvey(survey._id))
  }

  const handleQuestion = (e, question) => {
    //setQ( {...q, q[e.target.id]: e.target,value } )
    const arr = questions.map((el) => {
      if (el._id === question._id) {
        el.question = e.target.value
      }
      return el
    })
    setQuestions(arr)
  }

  // const addQuestion = () => {
  //   const id = Date.now().toString()
  //   setQuestions(
  //     questions.concat([
  //       {
  //         _id: id,
  //         question: '',
  //       },
  //     ])
  //   )
  // }

  const deleteQuestion = (id) => {
    setQuestions((prev) => {
      return prev.filter((el) => el._id !== id)
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete='off'>
        {questions?.map((question, i) => {
          const index = i + 1
          return (
            <div className={classes.mainSection} key={question._id}>
              <div className={classes.rootQuestion} key={question._id}>
                <CustomInput
                  labelText={`${index}.- ${question.question}`}
                  id={question._id}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputProps={{
                    type: 'text',
                    value: question.question,
                    placeholder: 'Enter question',
                    onChange: (e) => handleQuestion(e, question),
                    required: true,
                  }}
                />
                <IconButton
                  aria-label='close'
                  className={classes.closeIcon}
                  onClick={() => deleteQuestion(question._id)}
                >
                  <Close fontSize='small' />
                </IconButton>
              </div>
              <Grid container>
                <Grid item className={classes.sectionSelects}>
                  {question.type === 'selectCheckbox' && (
                    <Grid item md={12}>
                      <FormGroup>
                        {question.checkboxQuestion &&
                          question.checkboxQuestion?.map((select, i) => {
                            return (
                              <div key={select._id}>
                                <FormControlLabel control={<Checkbox disabled />} label={select.question} />
                              </div>
                            )
                          })}
                      </FormGroup>
                    </Grid>
                  )}
                  {question.type === 'selectRadio' && (
                    <FormControl>
                      {question.radioQuestion &&
                        question.radioQuestion?.map((value) => {
                          return (
                            <Grid item md={12} key={value._id}>
                              <FormControlLabel control={<Radio disabled />} label={value.question} />
                            </Grid>
                          )
                        })}
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </div>
          )
        })}

        <Grid container spacing={2} className={classes.root}>
          {/* <Grid item xs={4} md={3}>
            <Button block color={'success'} onClick={addQuestion}>
              + Add Question
            </Button>
          </Grid> */}
          <Grid item xs={12} md={4} className={classes.switchItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  name='isActive'
                  value='active'
                  color='primary'
                />
              }
              label='Is Active'
            />
          </Grid>
          <Grid item xs={8} md={9}>
            <Button type='submit' block color={successUpdate ? 'success' : 'primary'} disabled={successUpdate && true}>
              {loadingSurveyUpdate ? <Loader size={'sm'} /> : successUpdate ? 'Updated Successfully' : 'Update Survey'}
            </Button>
          </Grid>
          <Grid item xs={4} md={3}>
            <Button color='danger' block onClick={() => setOpenDelete(true)}>
              {loadingSurveyDelete ? <Loader size={'sm'} /> : 'Delete'}
            </Button>
          </Grid>
        </Grid>
        {errorSurveyDelete && <Message message={<span>{errorSurveyDelete}</span>} color='info' />}
      </form>
      <CustomModal
        title='Delete Survey?'
        open={openDelete}
        setOpen={setOpenDelete}
        acceptText='Delete'
        acceptHandler={deleteHandler}
      >
        <Grid container>
          <Grid item xs={12}>
            <p>Are you sure you want to delete this survey?</p>
          </Grid>
          <Grid item xs={12}>
            This action will remove al related answers (If there are any).
          </Grid>
        </Grid>
      </CustomModal>
    </>
  )
}

export default SurveyCardForm

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, FormControl, Select, InputLabel, MenuItem, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { TEACHERS_BY_EMAIL_RESET } from '../../../../redux/constants/teacherConstants'
import { getTeachersByEmail } from '../../../../redux/actions/teacherActions'
import { groupAgesRanges } from '../../../../utils/groupAgesRanges'
import { groupCategories, littlePFAgesRanges } from '../../../../redux/constants/groupConstants'
import styles from '../styles/groupInfoFormModalStyles'

const useStyles = makeStyles(styles)

const GroupInfoFormModal = ({ setOpenModal, setGroupInfo, groupsInfo }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation('global')

	const [savedGroup, setSavedGroup] = useState(false)
	const [groupName, setGroupName] = useState('')
	const [noOfStudents, setNoOfStudents] = useState('')
	const [agesRange, setAgesRange] = useState('')
	const [category, setCategory] = useState('common')
	const [teacherName, setTeacherName] = useState('')
	const [teacherEmail, setTeacherEmail] = useState('')
	const [teacherPhone, setTeacherPhone] = useState('')
	const [errorMessage, setErrorMessage] = useState(null)
	const [errorGroup, setErrorGroup] = useState(false)

	const { teachers, loadingTeachersByEmail, successTeachersByEmail, errorTeacherByEmail } = useSelector(
		(state) => state.teachersByEmail,
	)

	const handleDispatcher = (e) => {
		e.preventDefault()
		const isNotValidTeacherEmail = groupsInfo.find(
			(teacher) => teacher.teacherName === teacherName && teacher.teacherEmail !== teacherEmail,
		)

		if (isNotValidTeacherEmail) {
			setErrorGroup(true)
			setErrorMessage(`This teacher name already exists with another email "${isNotValidTeacherEmail.teacherEmail}"`)
			return
		}
		const isNotValidTeacherName = groupsInfo.find(
			(teacher) => teacher.teacherName !== teacherName && teacher.teacherEmail === teacherEmail,
		)

		if (isNotValidTeacherName) {
			setErrorGroup(true)
			setErrorMessage(`This teacher email already exists with another name "${isNotValidTeacherName.teacherName}"`)
			return
		}
		dispatch(getTeachersByEmail(teacherEmail))
		setErrorGroup(false)
	}

	useEffect(() => {
		if (!errorGroup) {
			saveGroupInfoHandler()
		}
		// eslint-disable-next-line
	}, [successTeachersByEmail, errorTeacherByEmail, errorGroup])

	const saveGroupInfoHandler = () => {
		setErrorMessage(null)
		if (
			groupName === '' ||
			noOfStudents === '' ||
			agesRange === '' ||
			teacherName === '' ||
			teacherEmail === '' ||
			teacherPhone === ''
		) {
			return false
		}

		if (noOfStudents < 3) {
			setErrorMessage('You must add a valid number of students')
			return false
		}

		const groupExists = groupsInfo.find(
			(group) =>
				group.groupName.replace(/\s+/g, ' ').replace(/\s/g, '').toLowerCase() ===
				groupName.replace(/\s+/g, ' ').replace(/\s/g, '').toLowerCase(),
		)

		if (groupExists) {
			setErrorGroup(true)
			setErrorMessage(`You already have a grade with this name: ${groupName}`)
			return false
		}
		const groupInfo = {
			groupName,
			noOfStudents,
			agesRange,
			teacherName,
			teacherEmail,
			teacherPhone,
			category,
		}

		if (teachers?.length > 0) {
			setErrorMessage(`Your email already matches one previously created: ${teacherEmail}`)
		} else {
			setGroupInfo((prev) => [...prev, groupInfo])
			cleanGroupInfo()
			setSavedGroup(true)
			setTimeout(() => {
				dispatch({ type: TEACHERS_BY_EMAIL_RESET })
				setSavedGroup(false)
			}, 5000)
		}
	}
	const cleanGroupInfo = () => {
		setGroupName('')
		setNoOfStudents('')
		setAgesRange('')
		setTeacherName('')
		setTeacherEmail('')
		setTeacherPhone('')
	}

	return (
		<form onSubmit={handleDispatcher}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={4}>
					<CustomInput
						labelText={t('layout.t_grade-name')}
						id='gradename'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'text',
							value: groupName,
							placeholder: 'E.g. 1A',
							onChange: (e) => setGroupName(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CustomInput
						labelText={t('layout.t_no-students')}
						id='noofstudents'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'number',
							value: noOfStudents,
							placeholder: 'Numbers only',
							onChange: (e) => setNoOfStudents(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<FormControl className={classes.formControl}>
						<InputLabel id='ages-range' className={classes.selectLabel}>
							{t('layout.t_ages-range')}
						</InputLabel>
						<Select
							labelId='ages-range'
							id='agesrange'
							value={agesRange}
							onChange={(e) => setAgesRange(e.target.value)}
							inputProps={{ required: true }}
							className={classes.selectInput}
						>
							<MenuItem value='' disabled>
								{t('layout.t_select-age-range')}
							</MenuItem>

							{groupAgesRanges.map((age) => (
								<MenuItem key={age} value={age}>
									{age}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				{littlePFAgesRanges.includes(agesRange) && (
					<Grid item xs={12}>
						<FormControl className={classes.formControlCategory}>
							<InputLabel id='group-category' className={classes.selectLabel}>
								{t('layout.t_group_category')}
							</InputLabel>
							<Select
								labelId='group-category'
								id='groupcategory'
								value={category}
								onChange={(e) => setCategory(e.target.value)}
								inputProps={{ required: true }}
								className={classes.selectInput}
							>
								<MenuItem value='' disabled>
									{t('layout.t_select-group-category')}
								</MenuItem>

								{groupCategories.map((v) => (
									<MenuItem value={v.value}>{v.label}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				)}
				<Grid item xs={12}>
					<CustomInput
						labelText={t('layout.t_teacher-name')}
						id='teachername'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'text',
							value: teacherName,
							placeholder: 'Complete Teacher name',
							onChange: (e) => setTeacherName(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<CustomInput
						labelText={t('layout.t_teacher-email')}
						id='teacheremail'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'email',
							value: teacherEmail,
							placeholder: 'Valid Teacher email',
							onChange: (e) => setTeacherEmail(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<CustomInput
						labelText={t('layout.t_teacher-phone')}
						id='teacherphone'
						formControlProps={{
							fullWidth: true,
							className: classes.inputItem,
						}}
						inputProps={{
							type: 'text',
							value: teacherPhone,
							placeholder: 'E.g. +00 000 000000',
							onChange: (e) => setTeacherPhone(e.target.value),
							required: true,
						}}
					/>
				</Grid>
				{/* <Grid item xs={4}>
          <Button type='button' className={classes.btnMarginTop} color='info' block onClick={() => setOpenModal(false)}>
            {t('layout.buttons.t_done')}
          </Button>
        </Grid> */}
				<Grid item xs={12}>
					<Button type='submit' className={classes.btnMarginTop} color={savedGroup ? 'success' : 'primary'} block>
						{savedGroup
							? t('layout.buttons.t_group-info-saved') + '...'
							: loadingTeachersByEmail
							? t('layout.buttons.t_saving-group-info')
							: t('layout.buttons.t_save-group-info')}
					</Button>
				</Grid>
				{errorMessage && (
					<Grid item xs={12}>
						<div className={classes.errorMessage}>{errorMessage}</div>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

export default GroupInfoFormModal

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import Loader from '../../../components/Loader/Loader'
import Button from '../../../components/Buttons/Button'
import Meta from '../../../components/Meta/Meta'
import CustomModal from '../../../components/Modal/CustomModal'
import ReactTable from '../../../components/ReactTable/ReactTable'
import UpdatePricePerCountryModal from './components/UpdatePricePerCountryModal'
import { getPricePerCountryList } from '../../../redux/actions/pricePerCountryActions'
import { PRICE_PER_COUNTRY_UPDATE_RESET } from '../../../redux/constants/pricePerCountryConstants'
import styles from './styles/pricePerCountryScreenStyles'

const useStyles = makeStyles(styles)

const PricePerCountryScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [data, setData] = useState([])
  const [modal, showModal] = useState(false)
  const [priceForUpdate, setPriceForUpdate] = useState({ price: 0 })

  const { loadingPricePerCountryList, successPricePerCountryList, pricePerCountryList } = useSelector(
    (state) => state.pricePerCountryList
  )

  const { successPricePerCountryUpdate } = useSelector((state) => state.pricePerCountryUpdate)

  useEffect(() => {
    dispatch(getPricePerCountryList())
  }, [dispatch])

  useEffect(() => {
    if (successPricePerCountryList) {
      const pricesList = pricePerCountryList.map((item) => {
        return {
          id: item._id,
          name: item.name,
          price: item.price + ' €',
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                size='sm'
                onClick={() => editPriceHandler(item._id)}
                color='primary'
                className='edit'
              >
                <Edit />
              </Button>
            </div>
          ),
        }
      })
      setData(pricesList)
    }
    const editPriceHandler = (id) => {
      const thePrice = pricePerCountryList.find((item) => item._id === id)
      setPriceForUpdate(thePrice)
      showModal(true)
    }
  }, [pricePerCountryList, successPricePerCountryList])

  useEffect(() => {
    if (successPricePerCountryUpdate) {
      dispatch(getPricePerCountryList())
      setPriceForUpdate({ price: 0 })
      showModal(false)
      dispatch({ type: PRICE_PER_COUNTRY_UPDATE_RESET })
    }
  }, [dispatch, successPricePerCountryUpdate])

  return (
    <>
      <Meta title='Price per Country' />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <h1>Price per Country</h1>
        </Grid>
        <Grid item xs={12}>
          {loadingPricePerCountryList ? (
            <Loader />
          ) : (
            <ReactTable
              columns={[
                {
                  Header: 'Country',
                  accessor: 'name',
                },
                {
                  Header: 'Price',
                  accessor: 'price',
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                },
              ]}
              data={data}
            />
          )}
        </Grid>
      </Grid>
      <CustomModal title='Edit Price' open={modal} setOpen={showModal} actions={false}>
        <UpdatePricePerCountryModal priceForUpdate={priceForUpdate} setPriceForUpdate={setPriceForUpdate} />
      </CustomModal>
    </>
  )
}

export default PricePerCountryScreen

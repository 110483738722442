import { useEffect, useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../../components/Card/Card'
import CardBody from '../../../../../components/Card/CardBody'
import TopicCardForm from '../TopicCardForm/TopicCardForm'
import styles from '../../styles/topicCardStyles'

const useStyles = makeStyles(styles)

const TopicCard = ({ topic, setTopicDetails }) => {
  const classes = useStyles()
  const editTopicForm = useRef(null)

  const scrollToRef = () => {
    editTopicForm.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToRef()
  }, [topic])

  return (
    <Grid container ref={editTopicForm}>
      <Grid item xs={12} sm={12} md={12}>
        <Card profile>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{topic.name}</h4>
            <TopicCardForm topic={topic} setTopicDetails={setTopicDetails} />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TopicCard

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, ListItem, makeStyles } from '@material-ui/core'
import CustomDropdown from '../CustomDropdown/CustomDropdown'
import Button from '../Buttons/Button'
import { useTranslation } from 'react-i18next'
// import { logout } from '../../redux/actions/userActions'
import styles from './styles/headerLinksStyle'

const useStyles = makeStyles(styles)

const HeaderLinks = () => {
	// const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	const { t, i18n } = useTranslation('global')

	const { userInfo } = useSelector((state) => state.userLogin)

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang)
		localStorage.setItem('lang', lang)
	}
	// const logoutHandler = () => {
	//     dispatch(logout())
	// }
	return (
		<List className={classes.list}>
			<ListItem className={classes.listItem}>
				<Button component={Link} to='/' color='transparent' className={classes.navLink}>
					{t('layout.t_home')}
				</Button>
			</ListItem>
			<ListItem className={classes.listItem}>
				<Button component={Link} to='/how-it-works' color='transparent' className={classes.navLink}>
					{t('layout.t_how-it-works')}
				</Button>
			</ListItem>
			{/* <ListItem className={classes.listItem}>
                <Button component={Link} to='/the-magazine' color='transparent' className={classes.navLink}>
                    The Magazine
                </Button>
            </ListItem> */}

			<ListItem className={classes.listItem}>
				<CustomDropdown
					noLiPadding
					buttonText={t('layout.t_resources')}
					buttonProps={{
						className: classes.navLink,
						color: 'transparent',
					}}
					dropdownList={[
						<div onClick={() => history.push('/pastoral')} className={classes.dropdownLink}>
							{t('layout.t_pastoral')}
						</div>,
						<div onClick={() => history.push('/i-can')} className={classes.dropdownLink}>
							{t('layout.t_i-can')}
						</div>,
						<div onClick={() => window.open('https://www.themagazine.live/index.php')} className={classes.dropdownLink}>
							{t('layout.t_the-magazine')}
						</div>,
						<div
							onClick={() => window.open('https://mapetiteplanete.org/mpp-scolaire/')}
							className={classes.dropdownLink}
						>
							Ma Petite Planète
						</div>,
						<div onClick={() => history.push('/lets-share-talents')} className={classes.dropdownLink}>
							Let's Share Talents
						</div>,
						<>
							{userInfo && (
								<div onClick={() => history.push('/final-projects')} className={classes.dropdownLink}>
									{t('layout.t_final-projects')}
								</div>
							)}
						</>,
					]}
				/>
			</ListItem>
			<ListItem className={classes.listItem}>
				<Button component={Link} to='/contact' color='transparent' className={classes.navLink}>
					{t('layout.t_contact')}
				</Button>
			</ListItem>
			<ListItem className={classes.listItem}>
				<Button component={Link} to='/little-planet-fraternity' color='transparent' className={classes.navLink}>
					{t('layout.t_little-planet-fraternity')}
				</Button>
			</ListItem>
			<ListItem className={classes.listItem}>
				<Button component={Link} to='/how-to-subscribe' color='transparent' className={classes.navLink}>
					{t('layout.t_how-to-subscribe')}
				</Button>
			</ListItem>
			<ListItem className={classes.listItem}>
				<CustomDropdown
					noLiPadding
					buttonText={t('layout.t_get-started')}
					buttonProps={{
						className: classes.navLink,
						color: 'transparent',
					}}
					dropdownList={[
						<div onClick={() => history.push('/auth/login')} className={classes.dropdownLink}>
							{userInfo
								? userInfo.isAdmin || userInfo.isSuper
									? t('layout.t_panel_admin')
									: userInfo.role === 'teacher' && !(userInfo.isAdmin || userInfo.isSuper)
									? t('layout.t_panel_teacher')
									: userInfo.role === 'student' &&
									  !(userInfo.isAdmin || userInfo.isSuper) &&
									  t('layout.t_panel_student')
								: t('layout.t_login')}
						</div>,
						<div onClick={() => history.push('/center-register')} className={classes.dropdownLink}>
							{t('layout.t_register-center')}
						</div>,
					]}
				/>
			</ListItem>

			{/* <ListItem className={classes.listItem}>
                {userInfo ? (
                    <CustomDropdown
                        noLiPadding
                        buttonText={userInfo.name}
                        buttonProps={{
                            className: classes.navLink,
                            color: 'transparent',
                        }}
                        buttonIcon={Apps}
                        dropdownList={[
                            <Link to={userInfo.role === 'student' ? '/student/profile' : '/teacher/profile'} className={classes.dropdownLink}>
                                My Profile
                            </Link>,
                            <div onClick={logoutHandler} className={classes.dropdownLink}>
                                Logout
                            </div>,
                        ]}
                    />
                ) : (
                    <Button component={Link} to='/auth/login' color='transparent' className={classes.navLink}>
                        Sing In
                    </Button>
                )}
            </ListItem> */}
			<ListItem className={classes.listItem}>
				<CustomDropdown
					noLiPadding
					buttonText={`${t('layout.t_language')} - ${i18n.language}`}
					buttonProps={{
						className: classes.navLink,
						color: 'transparent',
					}}
					dropdownList={[
						<div onClick={() => changeLanguage('en')} className={classes.dropdownLink}>
							English
						</div>,
						<div onClick={() => changeLanguage('es')} className={classes.dropdownLink}>
							Español
						</div>,
						<div onClick={() => changeLanguage('fr')} className={classes.dropdownLink}>
							Français
						</div>,
						<div onClick={() => changeLanguage('it')} className={classes.dropdownLink}>
							Italiano
						</div>,
					]}
				/>
			</ListItem>
		</List>
	)
}

export default HeaderLinks

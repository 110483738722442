import ActScreen from '../screens/website/ActScreen/ActScreen'
import CatholicSchoolsScreen from '../screens/website/CatholicSchoolsScreen/CatholicSchoolsScreen'
import CenterRegisterScreen from '../screens/website/CenterRegisterScreen/CenterRegisterScreen'
import ContactScreen from '../screens/website/ContactScreen/ContactScreen'
import LittlePlanetFraternityScreen from '../screens/website/LittlePlanetFraternityScreen/LittlePlanetFraternityScreen'
import CookiesPolicyScreen from '../screens/website/CookiesPolicyScreen/CookiesPolicyScreen'
import FeelScreen from '../screens/website/FeelScreen/FeelScreen'
import FinalProjectsScreen from '../screens/website/FinalProjectsScreen/FinalProjectsScreen'
import HomeScreen from '../screens/website/HomeScreen/HomeScreen'
import HowItWorksScreen from '../screens/website/HowItWorksScreen/HowItWorksScreen'
import HowToSubscribeScreen from '../screens/website/HowToSubscribeScreen/HowToSubscribeScreen'
import IcanScreen from '../screens/website/IcanScreen/IcanScreen'
import ImagineScreen from '../screens/website/ImagineScreen/ImagineScreen'
import ShareScreen from '../screens/website/ShareScreen/ShareScreen'
import TeamsByTopic from '../screens/website/FinalProjectsScreen/components/TeamsByTopic'
import TermsConditionsScreen from '../screens/website/TermsConditionsScreen/TermsConditionsScreen'
import WebSiteLayout from '../layouts/WebSiteLayout/WebSiteLayout'
import LetsShareTalentsScreen from '../screens/website/LetsShareTalentsScreen/LetsShareTalentsScreen'

const routesPublicSite = [
	{
		path: '/',
		exact: true,
		layout: WebSiteLayout,
		comp: HomeScreen,
	},
	{
		path: '/center-register',
		exact: true,
		layout: WebSiteLayout,
		comp: CenterRegisterScreen,
	},
	{
		path: '/terms-conditions',
		exact: true,
		layout: WebSiteLayout,
		comp: TermsConditionsScreen,
	},
	{
		path: '/cookies-policy',
		exact: true,
		layout: WebSiteLayout,
		comp: CookiesPolicyScreen,
	},
	{
		path: '/act',
		exact: true,
		layout: WebSiteLayout,
		comp: ActScreen,
	},
	{
		path: '/how-it-works',
		exact: true,
		layout: WebSiteLayout,
		comp: HowItWorksScreen,
	},
	{
		path: '/pastoral',
		exact: true,
		layout: WebSiteLayout,
		comp: CatholicSchoolsScreen,
	},
	{
		path: '/little-planet-fraternity',
		exact: true,
		layout: WebSiteLayout,
		comp: LittlePlanetFraternityScreen,
	},
	{
		path: '/contact',
		exact: true,
		layout: WebSiteLayout,
		comp: ContactScreen,
	},
	{
		path: '/lets-share-talents',
		exact: false,
		layout: WebSiteLayout,
		comp: LetsShareTalentsScreen,
	},
	{
		path: '/become-a-sponsor',
		exact: true,
		layout: WebSiteLayout,
		comp: ContactScreen,
	},
	{
		path: '/how-to-subscribe',
		exact: true,
		layout: WebSiteLayout,
		comp: HowToSubscribeScreen,
	},
	{
		path: '/feel',
		exact: true,
		layout: WebSiteLayout,
		comp: FeelScreen,
	},
	{
		path: '/i-can',
		exact: true,
		layout: WebSiteLayout,
		comp: IcanScreen,
	},
	{
		path: '/final-projects',
		exact: true,
		layout: WebSiteLayout,
		comp: FinalProjectsScreen,
	},
	{
		path: '/final-projects/:topic',
		exact: true,
		layout: WebSiteLayout,
		comp: TeamsByTopic,
	},
	{
		path: '/imagine',
		exact: true,
		layout: WebSiteLayout,
		comp: ImagineScreen,
	},
	{
		path: '/share',
		exact: true,
		layout: WebSiteLayout,
		comp: ShareScreen,
	},
]

export default routesPublicSite

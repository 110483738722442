import { useCallback, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import { findCountryPostByUser, registerCountryPost } from '../../../../redux/actions/countryPostsActions'
import styles from '../styles/countryPostStyles'
import Loader from '../../../../components/Loader/Loader'

const useStyles = makeStyles(styles)

const CountryPost = ({ user }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [postBody, setPostBody] = useState('')
	const [title, setTitle] = useState('')
	const [selectedFiles, setSelectedFiles] = useState([])

	const creationStates = useSelector((d) => d.countryPostCreate)

	const submitPost = useCallback(async () => {
		const payload = {
			body: postBody,
			selectedFiles,
			title,
		}
		await dispatch(registerCountryPost(payload))
		setPostBody('')
		setTitle('')
		setSelectedFiles([])
		setTimeout(() => {
			dispatch(findCountryPostByUser(user._id))
		}, 2000)
	}, [postBody, selectedFiles])
	const onChangeInputFile = (e) => {
		const files = [...e.target.files]
		setSelectedFiles(files)
	}

	return (
		<Grid container spacing={2} className={classes.modalRoot}>
			<Grid item xs={12}>
				<Grid container style={{ textAlign: 'center' }}>
					<Grid item xs={12}>
						<span>{user.country.name} · </span>
						<span>
							<img
								src={`${axios.defaults.baseURL}${user.country.flag}`}
								width='30px'
								alt={user.country.name}
								className='rounded'
							/>
						</span>
					</Grid>
					<Grid item xs={12}>
						<h2>Post anything about your country!</h2>
					</Grid>
					<Grid item xs={12}>
						<p>
							<b>The post will be visible to everyone who visits our website. Cheer up!</b>
						</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TextField
					className={classes.areaField}
					id='outlined-textarea-title'
					label='Title'
					placeholder='title'
					multiline
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					className={classes.areaField}
					id='outlined-textarea'
					label='Text'
					placeholder='Write a curiosity of your country'
					multiline
					rows={4}
					value={postBody}
					onChange={(e) => setPostBody(e.target.value)}
				/>
			</Grid>

			<Grid container xs={12} className={classes.messageAndImageSelector}>
				<Grid item xs={12} md={6} lg={3}>
					{creationStates?.errorCreateCountryPosts && (
						<Typography className={classes.error}>{creationStates?.errorCreateCountryPosts}</Typography>
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					<input type='file' hidden='hidden' accept='image/*' multiple onChange={onChangeInputFile} id='input-images' />
					<Button round clickRef={'input-images'} type='button' icon color='primary' block>
						{selectedFiles.length > 0 ? selectedFiles.length + ' Selected image/s' : 'Select Image to upload'}
					</Button>
				</Grid>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<Button type='button' color={creationStates?.success ? 'success' : 'primary'} block onClick={submitPost}>
					{creationStates?.loadingCountryPostCreate ? (
						<Loader size='sm' />
					) : creationStates?.success ? (
						'UPLOADED SUCCESSFULLY'
					) : (
						'Submit'
					)}
				</Button>
			</Grid>
		</Grid>
	)
}

export default CountryPost

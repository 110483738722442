export const TALENT_LIST_REQUEST = 'TALENT_LIST_REQUEST'
export const TALENT_LIST_SUCCESS = 'TALENT_LIST_SUCCESS'
export const TALENT_LIST_FAIL = 'TALENT_LIST_FAIL'
export const TALENT_LIST_RESET = 'TALENT_LIST_RESET'

export const TALENT_ACTIVE_LIST_REQUEST = 'TALENT_ACTIVE_LIST_REQUEST'
export const TALENT_ACTIVE_LIST_SUCCESS = 'TALENT_ACTIVE_LIST_SUCCESS'
export const TALENT_ACTIVE_LIST_FAIL = 'TALENT_ACTIVE_LIST_FAIL'
export const TALENT_ACTIVE_LIST_RESET = 'TALENT_ACTIVE_LIST_RESET'

export const TALENT_INACTIVE_LIST_REQUEST = 'TALENT_INACTIVE_LIST_REQUEST'
export const TALENT_INACTIVE_LIST_SUCCESS = 'TALENT_INACTIVE_LIST_SUCCESS'
export const TALENT_INACTIVE_LIST_FAIL = 'TALENT_INACTIVE_LIST_FAIL'
export const TALENT_INACTIVE_LIST_RESET = 'TALENT_INACTIVE_LIST_RESET'

export const TALENT_DELETE_REQUEST = 'TALENT_DELETE_REQUEST'
export const TALENT_DELETE_SUCCESS = 'TALENT_DELETE_SUCCESS'
export const TALENT_DELETE_FAIL = 'TALENT_DELETE_FAIL'
export const TALENT_DELETE_RESET = 'TALENT_DELETE_RESET'

export const TALENT_CREATE_REQUEST = 'TALENT_CREATE_REQUEST'
export const TALENT_CREATE_SUCCESS = 'TALENT_CREATE_SUCCESS'
export const TALENT_CREATE_FAIL = 'TALENT_CREATE_FAIL'
export const TALENT_CREATE_RESET = 'TALENT_CREATE_RESET'

export const TALENT_UPDATE_REQUEST = 'TALENT_UPDATE_REQUEST'
export const TALENT_UPDATE_SUCCESS = 'TALENT_UPDATE_SUCCESS'
export const TALENT_UPDATE_FAIL = 'TALENT_UPDATE_FAIL'
export const TALENT_UPDATE_RESET = 'TALENT_UPDATE_RESET'

export const TALENT_VERIFY_POSTED_REQUEST = 'TALENT_VERIFY_POSTED_REQUEST'
export const TALENT_VERIFY_POSTED_SUCCESS = 'TALENT_VERIFY_POSTED_SUCCESS'
export const TALENT_VERIFY_POSTED_FAIL = 'TALENT_VERIFY_POSTED_FAIL'
export const TALENT_VERIFY_POSTED_RESET = 'TALENT_VERIFY_POSTED_RESET'

export const TALENT_SINGLE_REQUEST = 'TALENT_SINGLE_REQUEST'
export const TALENT_SINGLE_SUCCESS = 'TALENT_SINGLE_SUCCESS'
export const TALENT_SINGLE_FAIL = 'TALENT_SINGLE_FAIL'
export const TALENT_SINGLE_RESET = 'TALENT_SINGLE_RESET'

export const statusFilterInterface = (status) => {
	return {
		request: status ? TALENT_ACTIVE_LIST_REQUEST : TALENT_INACTIVE_LIST_REQUEST,
		success: status ? TALENT_ACTIVE_LIST_SUCCESS : TALENT_INACTIVE_LIST_SUCCESS,
		fail: status ? TALENT_ACTIVE_LIST_FAIL : TALENT_INACTIVE_LIST_FAIL,
		reset: status ? TALENT_ACTIVE_LIST_RESET : TALENT_INACTIVE_LIST_RESET,
	}
}

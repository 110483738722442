const styles = {
  deleteRoot: {
    display: 'flex',
    justifyContent: 'end',
    '& div': {
      flexBasis: '0',
    },
  },
}

export default styles

import * as types from '../constants/surveyConstants'

export const surveyRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SURVEY_REGISTER_REQUEST:
      return { loadingSurveyRegister: true }
    case types.SURVEY_REGISTER_SUCCESS:
      return {
        loadingSurveyRegister: false,
        successSurveyRegister: true,
        survey: action.payload,
      }
    case types.SURVEY_REGISTER_FAIL:
      return {
        loadingSurveyRegister: false,
        errorSurveyRegister: action.payload,
      }
    case types.SURVEY_REGISTER_RESET:
      return {}
    default:
      return state
  }
}
export const surveyAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SURVEY_ANSWER_REQUEST:
      return { loadingSurveyAnswer: true }
    case types.SURVEY_ANSWER_SUCCESS:
      return {
        loadingSurveyAnswer: false,
        successSurveyAnswer: true,
      }
    case types.SURVEY_ANSWER_FAIL:
      return {
        loadingSurveyAnswer: false,
        errorSurveyAnswer: action.payload,
      }
    case types.SURVEY_ANSWER_RESET:
      return {}
    default:
      return state
  }
}

export const answerSurveyReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ANSWER_SURVEY_REQUEST:
      return { loadingAnswerSurvey: true }
    case types.ANSWER_SURVEY_SUCCESS:
      return {
        loadingAnswerSurvey: false,
        successAnswerSurvey: true,
        answersSurvey: action.payload,
      }
    case types.ANSWER_SURVEY_FAIL:
      return {
        loadingAnswerSurvey: false,
        errorAnswerSurvey: action.payload,
      }
    case types.ANSWER_SURVEY_RESET:
      return {}
    default:
      return state
  }
}

export const surveyListReducer = (state = { surveys: [] }, action) => {
  switch (action.type) {
    case types.SURVEY_LIST_REQUEST:
      return { loadingSurveyList: true }
    case types.SURVEY_LIST_SUCCESS:
      return {
        loadingSurveyList: false,
        succesSurveyList: true,
        surveys: action.payload,
        thatsIt: action.payload.length === 0 ? true : false,
      }
    case types.SURVEY_LIST_FAIL:
      return {
        loadingSurveyList: false,
        errorSurveyList: action.payload,
      }
    case types.SURVEY_LIST_RESET:
      return { surveys: [] }
    default:
      return state
  }
}

export const surveyDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SURVEY_DETAILS_REQUEST:
      return { loadingSurveyDetails: true }
    case types.SURVEY_DETAILS_SUCCESS:
      return {
        loadingSurveyDetails: false,
        survey: action.payload,
      }
    case types.SURVEY_DETAILS_FAIL:
      return {
        loadingSurveyDetails: false,
        errorSurveyDetails: action.payload,
      }
    case types.SURVEY_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const surveyUpdateReducer = (
  state = { loadingSurveyUpdate: false, successSurveyUpdate: false, surveyUpdated: {} },
  action
) => {
  switch (action.type) {
    case types.SURVEY_UPDATE_REQUEST:
      return { loadingSurveyUpdate: true }
    case types.SURVEY_UPDATE_SUCCESS:
      return {
        loadingSurveyUpdate: false,
        successSurveyUpdate: true,
        surveyUpdated: action.payload,
      }
    case types.SURVEY_UPDATE_FAIL:
      return {
        loadingSurveyUpdate: false,
        errorSurveyUpdate: action.payload,
      }
    case types.SURVEY_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const surveyDeleteReducer = (
  state = { loadingSurveyDelete: false, errorSurveyDelete: false, successSurveyDelete: false },
  action
) => {
  switch (action.type) {
    case types.SURVEY_DELETE_REQUEST:
      return { loadingSurveyDelete: true }
    case types.SURVEY_DELETE_SUCCESS:
      return {
        loadingSurveyDelete: false,
        successSurveyDelete: true,
      }
    case types.SURVEY_DELETE_FAIL:
      return {
        loadingSurveyDelete: false,
        errorSurveyDelete: action.payload,
      }
    case types.SURVEY_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const answerReducer = (state = { loadingAnswer: false, errorAnswer: false, successAnswer: false }, action) => {
  switch (action.type) {
    case types.ANSWER_REQUEST:
      return { loadingAnswer: true }
    case types.ANSWER_SUCCESS:
      return {
        loadingAnswer: false,
        successAnswer: true,
        answers: action.payload,
      }
    case types.ANSWER_FAIL:
      return {
        loadingAnswer: false,
        errorAnswer: action.payload,
      }
    case types.ANSWER_RESET:
      return {}
    default:
      return state
  }
}

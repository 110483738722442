import { useEffect, useState } from 'react'
import axios from 'axios'
import { Grid, makeStyles, Badge, Fab, FormControlLabel, Switch, TextField, CardMedia } from '@material-ui/core'
import { Close, Add, DeleteForeverOutlined } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import Loader from '../../../../components/Loader/Loader'
import CustomModal from '../../../../components/Modal/CustomModal'
import Message from '../../../../components/Message/Message'
import { dataURItoBlob } from '../../../../utils/getDataUriToBlob'
import { useDispatch, useSelector } from 'react-redux'
import { updateMiniBlog, deleteMiniBlogById } from '../../../../redux/actions/miniBlogActions'
import styles from '../styles/blogItemListCardFormStyles'

const useStyles = makeStyles(styles)

const BlogListCardForm = ({ item }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [imageUrl, setImageUrl] = useState('')
	const [videoUrl, setVideoUrl] = useState('')
	const [videoBadge, setVideoBadge] = useState(false)
	const [fileName, setFileName] = useState('')
	const [text, setText] = useState('')
	const [title, setTitle] = useState('')
	const [active, setActive] = useState(false)
	const [applicationFile, setApplicationFile] = useState([])
	const [openDelete, setOpenDelete] = useState(false)
	const [successUpdated, setSuccessUpdate] = useState(false)

	const { loadingMiniBlogUpdate, successMiniBlogUpdate } = useSelector((state) => state.miniBlogUpdate)
	const { errorMiniBlogDelete, loadingMiniBlogDelete } = useSelector((state) => state.miniBlogDelete)

	useEffect(() => {
		if (item) {
			setImageUrl(item.image)
			setVideoUrl(item.video)
			setApplicationFile(item.file)
			if (item.file.length > 20) {
				const newFileName = item.file.replace('public/assets/files/miniblog/', '')
				setFileName(newFileName)
			}
			setTitle(item.title || '')
			setText(item.text || '')
			setActive(item.isActive)
			setVideoBadge(false)
		}
	}, [item])
	useEffect(() => {
		if (successMiniBlogUpdate) {
			setSuccessUpdate(true)
			setTimeout(() => {
				setSuccessUpdate(false)
			}, 2000)
		}
	}, [successMiniBlogUpdate])

	const submitEditFormHandler = (e) => {
		e.preventDefault()
		const updateItem = {
			_id: item._id,
			blogImage: imageUrl,
			blogVideo: videoUrl,
			title: title,
			text: text,
			isActive: active,
			blog: applicationFile,
		}
		dispatch(updateMiniBlog(updateItem))
	}
	const deleteConfirmHandler = () => {
		dispatch(deleteMiniBlogById(item._id))
		setOpenDelete(false)
	}
	const handleFileChange = (e) => {
		setApplicationFile([...e.target.files])
		setFileName(e.target.files[0].name)
	}
	const handleFileRemove = () => {
		setApplicationFile('')
		setFileName('')
	}
	const handleImageChange = (e) => {
		const coverImgFile = e.target.files[0]
		if (coverImgFile) {
			setImageUrl(null)
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const reader = new FileReader()
			const img = new Image()
			img.onload = () => {
				let maxWidth = 450
				let maxHeight = Math.round((img.height *= maxWidth / img.width))
				ctx.canvas.width = maxWidth
				ctx.canvas.height = maxHeight
				ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
				const imgData = canvas.toDataURL('image/jpeg', 0.7)
				const imgBlob = dataURItoBlob(imgData)
				new File([imgBlob], coverImgFile.name, {
					type: 'image/jpeg',
				})
				setImageUrl(e.target.files)
				const previewImg = document.querySelector('#preview-magazine-cover img')
				previewImg.src = imgData
			}
			reader.onloadend = () => {
				img.src = reader.result
			}
			reader.readAsDataURL(coverImgFile)
		}
	}
	const handleVideoChange = (e) => {
		e.preventDefault()

		const videoFile = e.target.files

		setVideoBadge(true)
		setVideoUrl(videoFile)
	}

	return (
		<>
			<Card className={classes.maxHeight50VH}>
				<CardBody>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12} xs={12}>
							<form autoComplete='off' onSubmit={submitEditFormHandler}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<CustomInput
											labelText='title'
											id={'title' + title}
											formControlProps={{
												fullWidth: true,
												className: classes.inputItem,
											}}
											inputProps={{
												type: 'title',
												value: title,
												placeholder: 'Enter the title to be seen in the blog',
												onChange: (e) => {
													setTitle(e.target.value)
												},
											}}
										/>
									</Grid>
									<Grid item md={12} sm={12} xs={12}>
										<TextField
											className={classes.areaField}
											id='outlined-textarea'
											label='Text'
											placeholder='Enter the text to be seen in the blog'
											multiline
											rows={3}
											value={text}
											onChange={(e) => setText(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} md={4} className={classes.switchItem}>
										<FormControlLabel
											control={
												<Switch
													checked={active}
													onChange={(e) => setActive(e.target.checked)}
													name='isActive'
													value='active'
													color='primary'
												/>
											}
											label='Is Active'
										/>
									</Grid>
									{imageUrl && (
										<Grid item xs={12} sm={4} md={12}>
											<div className={classes.imgContainer} id='preview-magazine-cover'>
												<img
													src={`${axios.defaults.baseURL}/${imageUrl}`}
													alt='Img not Found'
													className={classes.imgSize}
												/>
											</div>
										</Grid>
									)}
									{!videoUrl && (
										<Grid item xs={12} className={classes.btnResourcePair}>
											<div>
												<p>Add New Image</p>
												<label htmlFor='image-input'>
													<input
														type='file'
														name='imageInput'
														capture='camera'
														hidden
														id='image-input'
														accept='.png, .jpg, .jpeg'
														onChange={(e) => handleImageChange(e)}
													/>
													<Fab color='primary' size='small' component='span' aria-label='add'>
														<Add />
													</Fab>
												</label>
											</div>
											{imageUrl && (
												<div>
													<Fab
														color='dangerColor'
														size='small'
														component='span'
														aria-label='remove'
														onClick={() => setImageUrl('')}
													>
														<DeleteForeverOutlined />
													</Fab>
												</div>
											)}
										</Grid>
									)}
									{videoUrl && !videoBadge && (
										<Grid item xs={12} sm={4} md={12}>
											<div className={classes.imgContainer} id='preview-magazine-cover'>
												<CardMedia
													component='video'
													className={classes.cardMedia}
													src={`${axios.defaults.baseURL}/${videoUrl}`}
													controls
													style={{ height: '100%', width: '100%', maxHeight: '60vh' }}
													alt={item.title}
												/>
											</div>
										</Grid>
									)}
									{videoBadge && (
										<Grid item xs={12} sm={4} md={12}>
											<Badge className={classes.fileBadge}>
												<div className={classes.fileDiv}>{videoUrl.length > 0 && videoUrl[0].name}</div>
											</Badge>
										</Grid>
									)}
									{!imageUrl && (
										<Grid item xs={12} className={classes.btnResourcePair}>
											<div>
												<p>Add New Video</p>
												<label htmlFor='video-input'>
													<input
														type='file'
														name='videoInput'
														capture='camera'
														hidden
														id='video-input'
														accept='.mp4'
														onChange={(e) => handleVideoChange(e)}
													/>
													<Fab color='primary' size='small' component='span' aria-label='add'>
														<Add />
													</Fab>
												</label>
											</div>
											{videoUrl && (
												<div>
													<Fab
														color='dangerColor'
														size='small'
														component='span'
														aria-label='remove'
														onClick={() => {
															setVideoBadge(false)
															setVideoUrl('')
														}}
													>
														<DeleteForeverOutlined />
													</Fab>
												</div>
											)}
										</Grid>
									)}
									{applicationFile.length > 0 && (
										<Grid item xs={12} md={12}>
											<Badge
												badgeContent={
													<Button size='sm' color='danger' onClick={handleFileRemove} round justIcon>
														<Close />
													</Button>
												}
												className={classes.fileBadge}
											>
												<div className={classes.fileDiv}>
													{fileName.length > 20
														? fileName.replace('public/assets/files/miniblog/g', '') &&
														  fileName.slice(0, 20) + '...' + fileName.slice(fileName.length - 6, fileName.length)
														: fileName}
												</div>
											</Badge>
										</Grid>
									)}
									{(!applicationFile || applicationFile.length < 1) && (
										<Grid item xs={12} className={classes.btnResource}>
											<p>Add File</p>
											<label htmlFor='file-input'>
												<input
													type='file'
													name='fileInput'
													capture='camera'
													hidden
													id='file-input'
													accept='.pdf, .ppt, .pptm, .pptx'
													onChange={handleFileChange}
												/>
												<Fab color='primary' size='small' component='span' aria-label='add'>
													<Add />
												</Fab>
											</label>
										</Grid>
									)}
									{errorMiniBlogDelete && (
										<Grid item xs={12}>
											<Message message={<span>{errorMiniBlogDelete}</span>} color='info' />
										</Grid>
									)}
									<Grid item xs={8} sm={6} md={6}>
										<Button type='submit' color={successUpdated ? 'success' : 'primary'} block>
											{loadingMiniBlogUpdate ? (
												<Loader size={'sm'} />
											) : successUpdated ? (
												'Updated Successfully'
											) : (
												'Update Item'
											)}
										</Button>
									</Grid>
									<Grid item xs={4} sm={6} md={6}>
										<Button color='danger' block onClick={() => setOpenDelete(true)}>
											{loadingMiniBlogDelete ? <Loader size={'sm'} /> : 'Delete'}
										</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Grid>
				</CardBody>
			</Card>
			<CustomModal title='Delete Item' open={openDelete} setOpen={setOpenDelete} actions={false}>
				<Grid container>
					<Grid item xs={12}>
						<p>Are you sure you want to delete this Item?</p>
					</Grid>
					<Grid item xs={6}>
						<Button color='primary' block onClick={() => setOpenDelete(false)}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button color='danger' block onClick={deleteConfirmHandler}>
							{loadingMiniBlogDelete ? <Loader size='sm' /> : 'Delete Item'}
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
		</>
	)
}

export default BlogListCardForm

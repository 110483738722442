import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { getActiveMiniBlogs } from '../../../../redux/actions/miniBlogActions'
import MiniBlogItemList from './MiniBlogItemList'
import styles from '../styles/homeScreenStyles'

const useStyles = makeStyles(styles)

const MiniBlog = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [articles, setArticles] = useState([])

	const { successActiveMiniBlogList, activeMiniBlogs } = useSelector((state) => state.activeMiniBlogList)

	useEffect(() => {
		dispatch(getActiveMiniBlogs)
	}, [dispatch])
	useEffect(() => {
		if (successActiveMiniBlogList) {
			setArticles(activeMiniBlogs)
		}
	}, [successActiveMiniBlogList, activeMiniBlogs])

	return (
		<>
			{articles.length > 0 && (
				<Grid container className={classes.sectionMapBlog}>
					<Grid item xs={12}>
						<h3>Planet Fraternity Blog</h3>
					</Grid>
					<Grid item xs={12} sm={10} md={8} className={classes.sectionBlog}>
						<MiniBlogItemList articles={articles} />
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default MiniBlog

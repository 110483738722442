import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid, makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import Button from '../../../../../components/Buttons/Button'
import Loader from '../../../../../components/Loader/Loader'
import Message from '../../../../../components/Message/Message'
import CustomInput from '../../../../../components/CustomInput/CustomInput'
import { dataURItoBlob } from '../../../../../utils/getDataUriToBlob'
import { TOPIC_REGISTER_RESET } from '../../../../../redux/constants/topicConstants'
import { registerTopicPanel } from '../../../../../redux/actions/topicActions'
import styles from '../../styles/topicRegisterFormModalStyles'

const useStyles = makeStyles(styles)

const TopicRegisterFormModal = ({ open, setOpen }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [name, setName] = useState('')
	const [order, setOrder] = useState('')
	const [imageUrlModal, setImageUrlModal] = useState(null)

	const { errorTopicRegister, loadingTopicRegister, successTopicRegister } = useSelector((state) => state.topicRegister)

	useEffect(() => {
		if (successTopicRegister) {
			setOpen(false)
			setImageUrlModal('')
			setName('')
			setOrder('')
			dispatch({ type: TOPIC_REGISTER_RESET })
		}
	}, [dispatch, setOpen, successTopicRegister])

	useEffect(() => {
		setImageUrlModal('')
		setName('')
		setOrder('')
		dispatch({ type: TOPIC_REGISTER_RESET })
	}, [open, dispatch])

	const handleImageModalChange = (e) => {
		const coverImgFile = e.target.files[0]
		if (coverImgFile) {
			setImageUrlModal(null)
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const reader = new FileReader()
			const img = new Image()
			img.onload = () => {
				let maxWidth = 450
				let maxHeight = Math.round((img.height *= maxWidth / img.width))
				ctx.canvas.width = maxWidth
				ctx.canvas.height = maxHeight
				ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
				const imgData = canvas.toDataURL('image/jpeg', 0.7)
				const imgBlob = dataURItoBlob(imgData)
				const outputfile = new File([imgBlob], coverImgFile.name, {
					type: 'image/jpeg',
				})
				setImageUrlModal(outputfile)
				const previewImg = document.querySelector('.preview-image-modal img')
				previewImg.src = imgData
			}
			reader.onloadend = () => {
				img.src = reader.result
			}
			reader.readAsDataURL(coverImgFile)
		}
	}

	const submitFormHandler = (e) => {
		e.preventDefault()
		const topicSubmit = {
			name,
			order,
			imageUrlModal,
		}
		dispatch(registerTopicPanel(topicSubmit))
	}

	return (
		<Grid container>
			<Grid item md={12}>
				<form onSubmit={submitFormHandler} autoComplete='off'>
					<CustomInput
						labelText={errorTopicRegister ? 'Topic name already exists.' : 'Name'}
						formControlProps={{
							fullWidth: true,
							className: classes.formControl,
						}}
						error={errorTopicRegister === 'Topic name already exists.'}
						inputProps={{
							name: 'name',
							type: 'text',
							value: name,
							placeholder: 'Enter topic name',
							onChange: (e) => setName(e.target.value),
							required: true,
						}}
					/>
					<CustomInput
						labelText={errorTopicRegister ? 'Topic order already exists.' : 'Order'}
						error={errorTopicRegister === 'Topic order already exists.'}
						formControlProps={{
							fullWidth: true,
							className: classes.formControl,
						}}
						inputProps={{
							name: 'order',
							type: 'number',
							value: order,
							placeholder: 'Enter topic order',
							onChange: (e) => {
								setOrder(e.target.value)
							},
							required: true,
						}}
					/>
					<Grid item xs={12} sm={12}>
						{imageUrlModal !== '' && (
							<div className='preview-image-modal'>
								<img src='' alt='Img not Found' className={classes.imgSize} />
							</div>
						)}
						<Grid item xs={12} md={12} sm={12} className={classes.btnResourceStart}>
							<p>Add New Image</p>
							<label htmlFor='image-input-modal'>
								<input
									type='file'
									name='imageInputModal'
									capture='camera'
									hidden
									id='image-input-modal'
									accept='.png, .jpg, .jpeg'
									onChange={(e) => handleImageModalChange(e)}
								/>
								<Fab color='primary' size='small' component='span' aria-label='add'>
									<Add />
								</Fab>
							</label>
						</Grid>
					</Grid>
					{errorTopicRegister && <Message message={<span>{errorTopicRegister}</span>} color='info' />}
					<Button disabled={!imageUrlModal || !order || !name} type='submit' color='primary' block>
						{loadingTopicRegister ? <Loader size={'sm'} /> : 'Submit'}
					</Button>
				</form>
			</Grid>
		</Grid>
	)
}

export default TopicRegisterFormModal

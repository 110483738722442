import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/writingContestButtonStyles'

const useStyles = makeStyles(styles)

const WritingContestButton = ({ keyword, setOpen }) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <>
      {keyword && (
        <Button color='info' className={classes.btnResetSearch} onClick={() => history.push('/admin/writing-contest')}>
          Reset Search
        </Button>
      )}
      <Button color='success' onClick={() => setOpen(true)}>
        + Create Writing Contest
      </Button>
    </>
  )
}

export default WritingContestButton

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { makeStyles } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import Button from '../Buttons/Button'
import SearchBox from '../SearchBox/SearchBox'
import CustomDropdown from '../CustomDropdown/CustomDropdown'
import { logout } from '../../redux/actions/userActions'
import styles from './styles/cpHeaderStyles'

const useStyles = makeStyles(styles)

const CPHeader = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()

    const { loadingUserInfo, userInfo } = useSelector((state) => state.userLogin)

    const toggleSidebarHandler = () => {
        document.getElementById('wrapper').classList.toggle('toggled')
    }
    const logoutHandler = () => {
        dispatch(logout())
    }

    return (
        <header className='topbar-nav'>
            <nav className='navbar navbar-expand fixed-top justify-content-between'>
                <ul className='navbar-nav mr-auto align-items-center'>
                    <li className='nav-item'>
                        <div className='nav-link toggle-menu' onClick={toggleSidebarHandler}>
                            <i className='menu-icon'>
                                <Button justIcon round simple color='github'>
                                    <Menu />
                                </Button>
                            </i>
                        </div>
                    </li>
                    <li className='nav-item'>
                        <SearchBox />
                    </li>
                </ul>

                <ul className='navbar-nav align-items-center'>
                    <li className='nav-item'>
                        <CustomDropdown
                            className={classes.cardHeaderRoot}
                            left
                            caret={false}
                            buttonText={
                                !loadingUserInfo && userInfo ? (
                                    <img src={`${axios.defaults.baseURL}${userInfo.avatar}`} className={classes.img} alt='Profile Avatar' />
                                ) : (
                                    <img src='/assets/img/avatar/avatar.jpg' className={classes.img} alt='Profile Avatar' />
                                )
                            }
                            buttonProps={{
                                className: classes.navLink + ' ' + classes.imageDropdownButton,
                                color: 'transparent',
                            }}
                            dropdownList={[
                                // <Link to='/teacher/dashboard'>
                                //     My Dashboard
                                // </Link>,
                                <div onClick={() => history.push('/teacher/profile')}>My Profile</div>,
                                <div onClick={logoutHandler}>Logout</div>,
                            ]}
                        />
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default CPHeader

const styles = {
  itemRoot: {
    width: '100%',
    marginTop: '20px',
  },
  mainRoot: {
    display: 'flex',
    justifyContent: 'center',
    '& video': {
      maxWidth: '100%',
    },
  },
  lightBooxRoot: {
    position: 'fixed',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    cursor: 'pointer',
    zIndex: '1',
  },
  voteProject: {
    color: 'red',
    cursor: 'pointer',
  },
  actionLike: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    '& p': {
      margin: 'auto 0',
      paddingLeft: '5px',
    },
  },
  notVoteProject: {
    color: 'gray',
    cursor: 'pointer',
  },
  sectionImages: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    '& img': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      cursor: 'pointer',
      maxWidth: '90%',
      maxHeight: '90%',
    },
  },
  flipCard: {
    padding: 0,
    boxShadow: 'none',
    '& h2': {
      textAlign: 'center',
      padding: '10px 0px 5px 0px',
    },
    '& p': {
      fontSize: '12px',
    },
  },
}

export default styles

import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'
import { Message, PermMedia } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import styles from '../styles/talentRegistrationModalStyles'
import { registerTalent } from '../../../../redux/actions/shareTalentsActions'
import { createThumbnail } from '../../../../utils/createThumbnail'

const useStyles = makeStyles(styles)

const TalentRegistrationModal = ({ addObject, close }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [title, setTitle] = useState('')
	const [body, setBody] = useState('')
	const [selectedVideo, setSelectedVideo] = useState([])

	const { loadingTalentCreate, errorCreateTalents, success } = useSelector((s) => s.talentCreate)

	const submitPost = useCallback(async () => {
		const payload = {
			body,
			selectedVideo,
			title,
			videoThumbnail: selectedVideo.length ? await createThumbnail(selectedVideo[0]) : '',
		}
		const response = await dispatch(registerTalent(payload))
		if (response?.status === 201) {
			setBody('')
			setTitle('')
			setSelectedVideo([])
			close(response.data)
		}
	}, [body, selectedVideo])

	const onChangeInputFile = (e) => {
		const files = [...e.target.files]
		setSelectedVideo(files)
	}

	return (
		<Grid container className={classes.modalContainer}>
			<Grid item xs={12}>
				<Grid item xs={12}>
					<TextField
						className={classes.areaField}
						id='outlined-textarea-title'
						label='Title'
						placeholder='title'
						multiline
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.areaField}
						id='outlined-textarea'
						label='Description'
						placeholder='Write a description of the talent'
						multiline
						rows={4}
						value={body}
						onChange={(e) => setBody(e.target.value)}
					/>
				</Grid>
				<Grid container xs={12} className={classes.messageAndImageSelector}>
					<Grid item xs={6}>
						{errorCreateTalents && <Typography className={classes.error}>{errorCreateTalents}</Typography>}
					</Grid>
					<Grid item xs={6}>
						<input
							type='file'
							hidden
							id='cover-image'
							name='cover-image'
							onChange={onChangeInputFile}
							accept='video/mp4'
						/>
						<Button round clickRef={'cover-image'} className={classes.button} type='button' icon color='primary' block>
							{selectedVideo.length > 0 ? selectedVideo.length + ' Selected video' : 'Select video to upload'}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<Button type='button' color={success ? 'success' : 'primary'} block onClick={submitPost}>
					{loadingTalentCreate ? <Loader size='sm' /> : success ? 'UPLOADED SUCCESSFULLY' : 'Submit'}
				</Button>
			</Grid>
		</Grid>
	)
}

export default TalentRegistrationModal

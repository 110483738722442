import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { Group } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import Message from '../../../components/Message/Message'
import CustomTabs from '../../../components/CustomTabs/CustomTabs'
import CustomHeaderCard from '../../../components/CustomHeaderCard/CustomHeaderCard'
import GroupListCard from './components/GroupListCard'
import StudentList from './components/StudentList'
import { getTeacherGroups } from '../../../redux/actions/teacherActions'
import { GROUP_DELETE_RESET, GROUP_UPDATE_RESET } from '../../../redux/constants/groupConstants'
import { STUDENT_BY_GROUP_RESET } from '../../../redux/constants/studentConstants'
import styles from './styles/stundetListScreenStyles'

const useStyles = makeStyles(styles)

const StudentListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [notCenter, setNotCenter] = useState(false)
  const [mainGroups, setMainGroups] = useState([])
  const [otherGroups, setOtherGroups] = useState([])
  const [groupInfo, setGroupInfo] = useState('')
  const [studentsQty, setStudentsQty] = useState(null)
  const [viewGroup, setViewGroup] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { loadingTeacherGroups, groups } = useSelector((state) => state.teacherGroups)
  const { successGroupDelete } = useSelector((state) => state.groupDelete)
  const { successGroupUpdate, group } = useSelector((state) => state.groupUpdate)
  const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)

  useEffect(() => {
    if (userInfo) {
      if (userInfo.center) {
        if (groups && groups.length > 0) {
          const mainArray = groups.filter(({ teachers }) => {
            return teachers.some((teacher) => teacher._id === userInfo._id && teacher.main === true)
          })
          const otherArray = groups.filter(({ teachers }) => {
            return teachers.some(
              (teacher) => (teacher._id === userInfo._id && teacher.main === false) || teacher.main === undefined
            )
          })
          setMainGroups(mainArray)
          setOtherGroups(otherArray)
        } else {
          setMainGroups([])
          setOtherGroups([])
        }
      } else {
        setNotCenter(true)
      }
    } else {
      history.push('/auth/login')
    }
  }, [groups, userInfo, history])
  useEffect(() => {
    dispatch(getTeacherGroups())
  }, [dispatch, successGroupTeacherDelete])
  useEffect(() => {
    if (successGroupDelete || successGroupUpdate || successGroupTeacherDelete) {
      if (successGroupUpdate && viewGroup !== null) {
        setViewGroup(group)
      }
      if (successGroupUpdate && groupInfo !== '') {
        setGroupInfo(group)
      }
      if (successGroupTeacherDelete && viewGroup !== null) {
        setViewGroup(group)
      }
      if (successGroupTeacherDelete && groupInfo !== '') {
        setGroupInfo(group)
      }
      dispatch({ type: GROUP_DELETE_RESET })
      dispatch({ type: GROUP_UPDATE_RESET })
      dispatch(getTeacherGroups())
    }
  }, [dispatch, successGroupDelete, successGroupUpdate, group, viewGroup, groupInfo, successGroupTeacherDelete])
  useEffect(() => {
    if (viewGroup) {
      setStudentsQty(viewGroup.students.length)
      setGroupInfo(viewGroup)
    }
  }, [viewGroup])
  useEffect(() => {
    return () => {
      dispatch({ type: STUDENT_BY_GROUP_RESET })
    }
  }, [dispatch])

  return (
    <>
      <Meta title={'Student List - Planet Fraternity'} />
      {notCenter ? (
        <Message message={<span>You are not from a registered center</span>} color='info' />
      ) : (
        <Grid container spacing={5} className={classes.root}>
          <Grid item xs={12} md={7}>
            <CustomHeaderCard
              title={groupInfo ? 'Group ' + groupInfo.name : 'Group'}
              subtitle={studentsQty !== null && studentsQty + ' Students'}
              headerColor='primary'
              className={classes.cardHeader}
            >
              <StudentList
                match={match}
                viewGroup={viewGroup}
                setGroupInfo={setGroupInfo}
                setStudentsQty={setStudentsQty}
              />
            </CustomHeaderCard>
          </Grid>
          <Grid item xs={12} md={5}>
            <CustomTabs
              headerColor='danger'
              className={classes.customtab}
              tabs={[
                {
                  tabName: 'Main Groups',
                  tabIcon: Group,
                  tabContent: (
                    <>
                      {loadingTeacherGroups ? (
                        <Loader />
                      ) : userInfo && mainGroups && mainGroups.length > 0 ? (
                        mainGroups.map((group) => (
                          <GroupListCard
                            key={group._id}
                            group={group}
                            userInfo={userInfo}
                            setViewGroup={setViewGroup}
                          />
                        ))
                      ) : (
                        <h2 className={classes.groupNotFound}>You don't have any group assigned</h2>
                      )}
                    </>
                  ),
                },
                {
                  tabName: 'Other Groups',
                  tabIcon: Group,
                  tabContent: (
                    <>
                      {loadingTeacherGroups ? (
                        <Loader />
                      ) : userInfo && otherGroups && otherGroups.length > 0 ? (
                        otherGroups.map((group) => (
                          <GroupListCard
                            key={group._id}
                            group={group}
                            setViewGroup={setViewGroup}
                            userInfo={userInfo}
                          />
                        ))
                      ) : (
                        <h2 className={classes.groupNotFound}>You don't have any other group assigned</h2>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default StudentListScreen

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import OwlCarousel from 'react-owl-carousel'
import classNames from 'classnames'
import {
	Accordion,
	AccordionDetails,
	Card,
	CircularProgress,
	FormControlLabel,
	Grid,
	Switch,
	Typography,
	makeStyles,
} from '@material-ui/core'
import Loader from '../Loader/Loader'
import { deleteCountryPost, updateCountryPost } from '../../redux/actions/countryPostsActions'
import Button from '../../components/Buttons/Button'
import CustomModal from '../Modal/CustomModal'
import styles from './styles/countryPostComponentStyles'

const useStyles = makeStyles(styles)

const CountryPostComponent = ({ previewMode, updateSelection, countryPost, deleteObject, updateObject }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation('global')
	const {
		loadingCountryPostUpdate,
		errorUpdateCountryPosts,
		success: successUpdated,
	} = useSelector((state) => state.countryPostUpdate)
	const { loadingCountryPostDelete, errorDeleteCountryPosts } = useSelector((state) => state.countryPostDelete)

	const [options, setOptions] = useState({})
	const [activePost, setActivePost] = useState(undefined)
	const [viewImage, setViewImage] = useState(false)
	const [showMore, setShowMore] = useState(false)
	const [open, setOpen] = useState(false)
	const [deletePost, setDeletePost] = useState(false)

	useEffect(() => {
		if (countryPost?.images.length > 0) {
			setOptions({
				loop: true,
				center: true,
				items: 3,
				margin: 0,
				autoplay: true,
				dots: true,
				autoplayTimeout: 8000,
				smartSpeed: 800,
				nav: false,
				responsive: {
					0: {
						items: 1,
					},
					200: {
						items: 1,
					},
					500: {
						items: 1,
					},
					700: {
						items: 1,
					},
					1000: {
						items: 1,
					},
				},
			})
		}
		setActivePost(undefined)
	}, [countryPost])

	const afterDispatch = useCallback(() => {
		if (!errorUpdateCountryPosts || !errorDeleteCountryPosts) {
			setOpen(false)
			updateSelection(null)
			deleteObject(countryPost._id)
		}
	}, [errorUpdateCountryPosts, errorDeleteCountryPosts])

	const togglePost = async () => {
		const response = await dispatch(updateCountryPost(countryPost._id, { active: activePost }))
		updateObject(response.data.countryPostUpdated)
	}

	const dispatchDeletePost = async () => {
		setDeletePost(true)
		const response = await dispatch(deleteCountryPost(countryPost._id))
		setDeletePost(false)
		afterDispatch(response)
	}

	const handleDeleteCountryPost = () => {
		setOpen(true)
	}

	return (
		<>
			<CustomModal
				title={t('countries.countryPosts.sureDelete')}
				open={open}
				setOpen={(e) => setOpen(e)}
				actions={true}
				acceptHandler={dispatchDeletePost}
				loadingAccept={loadingCountryPostDelete}
			/>
			<Card elevation={2} className={classes.root}>
				<CustomModal open={viewImage} imageMode setOpen={setViewImage} actions={false}>
					<img src={viewImage} alt={'zoomed-image'} className={classes.zoomedImage} />
				</CustomModal>
				<Typography className={classes.title}>{countryPost?.title}</Typography>
				<Accordion expanded={showMore} onChange={() => {}} className={classes.accordion}>
					<AccordionDetails>
						<Typography className={classes.text}>
							{countryPost?.body.length > 200 ? (
								<>
									{showMore ? countryPost?.body : countryPost?.body.substring(0, 200)}
									{showMore ? '' : '...'}
									<b className={classes.showMoreButton} onClick={() => setShowMore(!showMore)}>
										{t(showMore ? 'countries.countryPosts.showLess' : 'countries.countryPosts.showMore')}
									</b>
								</>
							) : (
								countryPost?.body
							)}
						</Typography>
					</AccordionDetails>
				</Accordion>

				{countryPost?.images.length === 1 ? (
					<img
						src={`${axios.defaults.baseURL}/${countryPost?.images[0]?.url}`}
						alt={countryPost?.country?.name}
						className={classes.carouselImages}
						onClick={() => setViewImage(`${axios.defaults.baseURL}/${countryPost?.images[0]?.url}`)}
					/>
				) : (
					<OwlCarousel
						id='post-images'
						className={classNames(classes.customerCarrousel, 'owl-carousel owl-theme')}
						{...options}
					>
						{countryPost?.images?.map((item) => (
							<img
								src={`${axios.defaults.baseURL}/${item.url}`}
								alt={countryPost?.country?.name}
								className={classes.carouselImages}
								onClick={() => setViewImage(`${axios.defaults.baseURL}/${item.url}`)}
							/>
						))}
					</OwlCarousel>
				)}

				<Grid container xs={12} justifyContent={'flex-end'} className={classes.labelOwner}>
					<Grid item>
						<span>
							By <b>{countryPost.student.name}</b> from <b>{countryPost.student.center}</b>
						</span>
					</Grid>
				</Grid>
				{previewMode && (
					<Grid container xs={12} spacing={2} justifyContent='space-around' className={classes.previewOptions}>
						<Grid item xs={12}>
							<FormControlLabel
								className={classes.formControlLabel}
								color='secondary'
								control={
									<Switch
										checked={activePost ?? countryPost.active}
										onChange={(e) => setActivePost(e.target.checked)}
										name='activatePost'
										color='primary'
									/>
								}
								label='Active status'
							/>
						</Grid>
						<Grid item xs={9}>
							<Button color={successUpdated ? 'success' : 'primary'} block onClick={togglePost}>
								{loadingCountryPostUpdate ? <Loader size={'sm'} /> : successUpdated ? 'UPDATED POST' : 'Update Post'}
							</Button>
						</Grid>
						<Grid item xs={3} sm={3}>
							<Button onClick={handleDeleteCountryPost} color={'danger'} block>
								{deletePost ? <Loader size={'sm'} /> : 'Delete'}
							</Button>
						</Grid>
					</Grid>
				)}
			</Card>
		</>
	)
}

export default CountryPostComponent

const styles = {
  notificationRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0px',
    marginBottom: '5px',
    '& button': {
      paddingLeft: 10,
      textAlign: 'start',
    },
  },
  cardContent: {
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
}

export default styles

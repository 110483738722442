import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { format } from 'date-fns'
import { Grid, Divider, CardContent, makeStyles } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import Message from '../../../components/Message/Message'
import Button from '../../../components/Buttons/Button'
import Card from '../../../components/Card/Card'
import CardAvatar from '../../../components/Card/CardAvatar'
import CardBody from '../../../components/Card/CardBody'
import UserListCard from '../../../components/Users/UserListCard/UserListCard'
import { getMyTeamPanel } from '../../../redux/actions/teamsActions'
import { getCentersPartners } from '../../../redux/actions/centerActions'
import { getMyTeamGroup } from '../../../redux/actions/teamGroupActions'
import styles from './styles/studentDashboardScreenStyles'

const useStyles = makeStyles(styles)

const StudentDashboardScreen = ({ history }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingMyTeamPanel, myTeamPanelInfo } = useSelector((state) => state.teamMyDetails)
	const { loadingPartners, errorPartners, centersPartners } = useSelector((state) => state.centerPartners)
	const { loadingTeamGroupMyDetails, errorTeamGroupMyDetails, myteamgroup } = useSelector(
		(state) => state.teamGroupMyDetails,
	)
	useEffect(() => {
		if (userInfo) {
			if (userInfo.center) {
				dispatch(getMyTeamPanel(userInfo.center))
				dispatch(getMyTeamGroup(userInfo._id))
			}
		} else {
			history.push('/auth/login')
		}
	}, [dispatch, history, userInfo])
	useEffect(() => {
		if (myTeamPanelInfo) {
			const partnersId = myTeamPanelInfo[0].partners.map((item) => item?._id?._id)
			dispatch(
				getCentersPartners({
					partners: partnersId,
				}),
			)
		}
	}, [dispatch, myTeamPanelInfo])

	const goToMeetingRoom = () => {
		window.open(myteamgroup.teamgroup.hangoutLink, '_blank', 'noopener,noreferrer')
	}
	return (
		<>
			<Meta title={'Dashboard - Planet Fraternity'} />
			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={8}>
						<Card className={classes.cardRoot}>
							<CardContent>
								<Grid container>
									<Grid item xs={8}>
										{userInfo && (
											<>
												<h1>Hi, {userInfo.name}</h1>
												<p className={classes.textMuted}>
													<AccountCircle /> {userInfo.email}
												</p>
											</>
										)}
									</Grid>
									<Grid item xs={4} className={classes.editBtnRoot}>
										<Button color='warning' onClick={() => history.push('/student/profile')}>
											Edit Name
										</Button>
									</Grid>
								</Grid>
								<Divider className={classes.divider} />
								<Grid container>
									<Grid item xs={6}>
										<h2>Stars</h2>
										<p className={classes.textMuted}>0</p>
									</Grid>
									<Grid item xs={6}>
										<h2>Writing Contest Points</h2>
										<p className={classes.textMuted}>0</p>
									</Grid>
								</Grid>
								<Divider className={classes.divider} />
								<Grid container>
									<Grid item xs={6}>
										<h2>Total articles read</h2>
										<p className={classes.textMuted}>0</p>
									</Grid>
									<Grid item xs={6}>
										<h2>Total grammar exercises</h2>
										<p className={classes.textMuted}>0</p>
									</Grid>
								</Grid>
								<Divider className={classes.divider} />
								{loadingMyTeamPanel ? (
									<Loader />
								) : myTeamPanelInfo ? (
									myTeamPanelInfo.map((myTeamInfo, index) => (
										<div key={index}>
											<div className={classes.partnersBox}>
												<Grid key={myTeamInfo._id} container spacing={3} className={classes.partnersRoot}>
													<Grid item xs={12}>
														<h2 className={classes.cardTitle}>{myTeamInfo.panelName}</h2>
														<h4 className={classes.cardTitle}>
															Start Date · {format(new Date(myTeamInfo.partnersStartDate), 'dd-MM-yyyy')} - End Date ·{' '}
															{format(new Date(myTeamInfo.partnersEndDate), 'dd-MM-yyyy')}
														</h4>
														<div className={classes.topicInfo}>
															<img
																src={`${axios.defaults.baseURL}${myTeamInfo.topic.image}`}
																alt={myTeamInfo.topic.name}
															/>
															<h5>{myTeamInfo.topic.name}</h5>
														</div>
													</Grid>
													{loadingPartners ? (
														<Loader />
													) : errorPartners ? (
														<Grid item xs={12}>
															<Message message={<span>{errorPartners}</span>} color='info' />
														</Grid>
													) : (
														<>
															{centersPartners &&
																centersPartners.map((partner, index) => (
																	<Grid item xs={12} md={6} className={classes.partnersCards} key={index}>
																		<Card>
																			<CardAvatar className={classes.centerLogo} center>
																				<img src={`${axios.defaults.baseURL}${partner.logo}`} alt={partner.name} />
																			</CardAvatar>
																			<CardBody profile>
																				<Grid container spacing={3}>
																					<Grid item xs={12}>
																						<h5 className={classes.cardTitle}>{partner.name}</h5>
																					</Grid>
																					<Grid item xs={12}>
																						<p>
																							Email · <strong>{partner.email}</strong>
																						</p>
																						<p>
																							Phone · <strong>{partner.phone}</strong>
																						</p>
																						<span>
																							{partner.country.name} ·{' '}
																							<img
																								src={`${axios.defaults.baseURL}${partner.country.flag}`}
																								className={classes.countryFlagProfile}
																								alt={'Flag of ' + partner.country.name}
																							/>
																						</span>
																					</Grid>
																				</Grid>
																			</CardBody>
																		</Card>
																	</Grid>
																))}
															{myTeamInfo.meetRoom && (
																<Grid item xs={4}>
																	<Button color='success' onClick={() => window.open(myTeamInfo.meetRoom)}>
																		Go to Team Meet Room
																	</Button>
																</Grid>
															)}
														</>
													)}
												</Grid>
											</div>
											<Divider className={classes.divider} />
										</div>
									))
								) : (
									<Grid container spacing={3} className={classes.partnersRoot}>
										<Grid item xs={12}>
											<h2>PlanetFraternity teams start soon.</h2>
										</Grid>
									</Grid>
								)}
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={4}>
						{loadingTeamGroupMyDetails ? (
							<Loader />
						) : (
							<Card className={classes.cardRoot}>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<h2>My Planet Fraternity Group</h2>
										</Grid>
										{myteamgroup && centersPartners && (
											<>
												<Grid item xs={12}>
													<h3>{myteamgroup.teamgroup.name}</h3>
												</Grid>
												<Grid item xs={12}>
													<div className={classes.studentCardsArea}>
														{myteamgroup.users.map((student, index) => (
															<UserListCard key={index} user={student} />
														))}
													</div>
												</Grid>
												<Grid item xs={12}>
													<Button color='primary' block onClick={goToMeetingRoom}>
														Go to Meeting Room
													</Button>
												</Grid>
											</>
										)}
										{errorTeamGroupMyDetails && (
											<Grid item xs={12}>
												<h2>PlanetFraternity teams start soon.</h2>
											</Grid>
										)}
									</Grid>
								</CardContent>
							</Card>
						)}
						{/* <Card className={classes.cardRoot}>
                            <CardContent>
                                <h3>Pending grammar exercises</h3>
                                <ul>
                                    <li>Ejercicio 1</li>
                                    <li>Ejercicio 2</li>
                                    <li>Ejercicio 3</li>
                                </ul>
                            </CardContent>
                        </Card>
                        <Card className={classes.cardRoot}>
                            <CardContent>
                                <h3>Pending reading exercises</h3>
                                <ul>
                                    <li>Ejercicio 1</li>
                                    <li>Ejercicio 2</li>
                                    <li>Ejercicio 3</li>
                                </ul>
                            </CardContent>
                        </Card> */}
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export default StudentDashboardScreen

const styles = {
	root: {
		width: '100%',
		maxWidth: '340px',
		padding: 10,
	},
	thumbnail: {
		width: '100%',
		height: '210px',
		backgroundColor: '#000',
		borderRadius: '10px',
		position: 'relative',
	},
	img: {
		width: '100%',
		height: '210px',
		backgroundColor: '#f8f8f8',
		borderRadius: '10px',
		objectFit: 'cover',
	},
	playButtonContainer: {
		width: '100%',
		height: '210px',
		maxWidth: '340px',
		padding: 10,
		position: 'absolute',
		top: '0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		borderRadius: '10px',
		justifyContent: 'center',
		'&:hover': {
			background: '#0000007a',
			cursor: 'pointer',
		},
		'&:active': {
			background: '#7a7a7a7a',
			cursor: 'pointer',
		},
	},
	playButton: {
		width: 0,
		height: 0,
		borderTop: '15px solid transparent',
		borderLeft: '30px solid #fff',
		borderBottom: '15px solid transparent',
		position: 'relative',
		marginRight: '10px',
	},
	descRoot: {
		padding: '7px 5px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		'& h4': {
			color: '#1f7a2f',
			fontSize: 15,
			margin: 0,
			padding: '0px 5px',
			textTransform: 'capitalize',
		},
		'& p': {
			color: '#7a7a7a',
			fontSize: 12,
			margin: 0,
		},
	},
	accordion: {
		background: 'transparent',
		padding: '0',
		width: '100%',
		marginTop: '7px',
		boxShadow: 'none',
		borderTop: 'none',
		'::before': {
			display: 'none',
		},
	},
}

export default styles

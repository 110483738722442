import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import OwlCarousel from 'react-owl-carousel-rtl'
import classNames from 'classnames'
import { Grid, makeStyles } from '@material-ui/core'
import TestimonialsDetails from './components/TestimonialsDetails'
import { activeTestimonials } from '../../redux/actions/testimonialActions'
import styles from './styles/sliderStyles'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import '../../assets/css/owl-style.css'

const useStyles = makeStyles(styles)

const TestimonialsCarousel = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [options, setOptions] = useState({})

	const { testimonials } = useSelector((state) => state.activeTestimonials)

	useEffect(() => {
		dispatch(activeTestimonials())
	}, [dispatch])
	useEffect(() => {
		if (testimonials) {
			setOptions({
				loop: true,
				center: true,
				items: 3,
				margin: 0,
				autoplay: true,
				dots: true,
				autoplayTimeout: 8000,
				smartSpeed: 800,
				nav: false,
				responsive: {
					0: {
						items: 1,
					},
					200: {
						items: 1,
					},
					500: {
						items: 1,
					},
					700: {
						items: 2,
					},
					1000: {
						items: 2,
					},
				},
			})
		}
	}, [testimonials])

	return (
		<>
			{testimonials && testimonials.length > 0 && (
				<div className={classes.sliderRoot}>
					<h4>Testimonials</h4>
					<section id='testimonial' className='testimonials pt-70 pb-70'>
						<div className='container'>
							<Grid container>
								<Grid item md={12} xs={12}>
									{testimonials && (
										<OwlCarousel
											id='customer-testimonoals'
											className={classNames(classes.customerTestimonoals, 'owl-carousel owl-theme')}
											{...options}
										>
											{testimonials?.map((testimonial) => {
												return <TestimonialsDetails key={testimonial._id} testimonial={testimonial} />
											})}
										</OwlCarousel>
									)}
								</Grid>
							</Grid>
						</div>
					</section>
				</div>
			)}
		</>
	)
}

export default TestimonialsCarousel

import ComingNextMonthScreen from '../screens/control-panel/ComingNextMonthScreen/ComingNextMonthScreen'
import DashboardScreen from '../screens/control-panel/DashboardScreen/DashboardScreen'
import ProfileScreen from '../screens/control-panel/ProfileScreen/ProfileScreen'
import SchoolProfileScreen from '../screens/control-panel/SchoolProfileScreen/SchoolProfileScreen'
import SchoolTeamsScreen from '../screens/control-panel/SchoolTeamsScreen/SchoolTeamsScreen'
import StudentListScreen from '../screens/control-panel/StudentListScreen/StudentListScreen'
import TeacherInformationScreen from '../screens/control-panel/TeacherInformationScreen/TeacherInformationScreen'
import TeacherSurveysScreen from '../screens/control-panel/TeacherSurveysScreen/TeacherSurveysScreen'

const teachersRoutes = [
  {
    name: 'Coming Next Month',
    path: '/coming-next-month',
    exact: true,
    comp: ComingNextMonthScreen,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    exact: true,
    comp: DashboardScreen,
  },
  {
    name: 'Profile',
    path: '/profile',
    exact: true,
    comp: ProfileScreen,
  },
  {
    name: 'Survey',
    path: '/profile/survey/:id',
    exact: true,
    comp: TeacherSurveysScreen,
  },
  {
    name: 'School Teams',
    path: '/school-teams',
    exact: true,
    comp: SchoolTeamsScreen,
  },
  {
    name: 'Student List',
    path: '/student-list',
    exact: true,
    comp: StudentListScreen,
  },
  {
    name: 'Informacion',
    path: '/information',
    exact: true,
    comp: TeacherInformationScreen,
  },
  {
    name: 'School Profile',
    path: '/school/profile',
    exact: true,
    comp: SchoolProfileScreen,
  },
]

export default teachersRoutes

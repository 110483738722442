import { Grid, Card, CardContent, makeStyles } from '@material-ui/core'
import { EmojiPeople } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import Parallax from '../../../components/Parallax/Parallax'
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import MethodologyPhases from '../../../components/MethodologyPhases/MethodologyPhases'
import { useTranslation } from 'react-i18next'
import styles from './styles/imagineScreenStyles'

const useStyles = makeStyles(styles)

const ImagineScreen = ({ history }) => {
    const classes = useStyles()
    const { t } = useTranslation('global')

    return (
        <>
            <Meta title={'Imagine - Planet Fraternity'} />
            <Parallax image={'/assets/img/public-site/bg-imagine.png'}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                                <h1 className={classes.titleBig}>{t('layout.t_imagine')}</h1>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.sections}>
                <Grid container className={classes.sectionRoot}>
                    <Grid item xs={12} md={6} className={classes.imgRoot}>
                        <img src='/assets/img/public-site/img-imagine.png' alt='Imagine' width='100%' />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.onTopCardRoot}>
                        <Card className={classes.onTopCardRight}>
                            <CardContent>
                                <div className={classes.descRoot}>
                                    <EmojiPeople className={classes.iCanIcon} />
                                    <p>{t('page.t_imagine_text_one')}</p>
                                    <ul>
                                        <li>{t('page.t_imagine_text_two')}</li>
                                        <li>{t('page.t_imagine_text_three')}</li>
                                        <li>{t('page.t_imagine_text_four')}</li>
                                        <li>{t('page.t_imagine_text_five')}</li>
                                        <li>{t('page.t_imagine_text_six')}</li>
                                        <li>{t('page.t_imagine_text_seven')}</li>
                                    </ul>

                                    <div className={classes.descActions}>
                                        <Button link onClick={() => history.push('/contact')}>
                                            {t('layout.buttons.t_contact-us')}
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <MethodologyPhases />
        </>
    )
}

export default ImagineScreen

export const owlOptions = {
	loop: true,
	center: true,
	items: 3,
	margin: 0,
	autoplay: true,
	dots: true,
	smartSpeed: 800,
	nav: false,
	responsive: {
		0: {
			items: 1,
		},
		200: {
			items: 1,
		},
		500: {
			items: 1,
		},
		700: {
			items: 1,
		},
		1000: {
			items: 2,
		},
	},
}

import { Card, CardHeader, makeStyles, Tooltip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/teamListCardStyles'

const useStyles = makeStyles(styles)

const TeamListCard = ({ panel, setPanelDetail }) => {
	const classes = useStyles()

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.cardHeaderRoot}
				action={
					<CustomDropdown
						left
						caret={false}
						buttonText={
							<div className={classes.btnwrapper}>
								<MoreVert />
							</div>
						}
						buttonProps={{
							className: classes.navLink + ' ' + classes.imageDropdownButton,
							color: 'transparent',
						}}
						dropdownList={[<div onClick={() => setPanelDetail(panel)}>Manage</div>]}
					/>
				}
				titleTypographyProps={{ variant: 'subtitle2' }}
				subheaderTypographyProps={{ variant: 'caption' }}
				title={
					<Tooltip placement='left-start' title={panel.panelName}>
						<span>{panel.panelName.length > 40 ? panel.panelName.slice(0, 40) + '...' : panel.panelName}</span>
					</Tooltip>
				}
				subheader={panel?.topic?.name ?? 'Little PlanetFraternity'}
			/>
		</Card>
	)
}

export default TeamListCard

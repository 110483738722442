import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import TopicList from './components/TopicList'
import { getTopics } from '../../../redux/actions/topicActions'
import styles from './styles/finalProjectsScreenStyle'

const useStyles = makeStyles(styles)

const FinalProjectsScreen = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const { topics } = useSelector((state) => state.topicList)

  useEffect(() => {
    dispatch(getTopics())
  }, [dispatch])

  return (
    <>
      <Meta title={'Final Projects - Planet Fraternity'} />
      <div className={classes.rootContent}>
        <h2>{t('page.t_final-projects-text-1')}</h2>
        {
          <>
            <Grid container className={classes.rootContainer}>
              {topics && <TopicList history={history} topics={topics} />}
            </Grid>
          </>
        }
      </div>
    </>
  )
}

export default FinalProjectsScreen

import { container } from '../../../../assets/jss/material-ui-styles'

const styles = {
    container,
    titleBig: {
        width: '100%',
        fontSize: '120px',
        fontWeight: '600',
        lineHeight: '1.1',
        display: 'inline-block',
        position: 'relative',
        color: '#f64ebd',
        textShadow: '7px 10px 15px rgb(7 44 25)',
    },
    sections: {
        maxWidth: '1440px',
        margin: 'auto',
    },
    sectionRoot: {
        padding: 60,
    },
    imgRoot: {
        position: 'relative',
        '& img': {
            borderRadius: '5px',
            boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        },
    },
    onTopCardRoot: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    onTopCardRight: {
        position: 'absolute',
        left: '-50px',
        boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    },
    descRoot: {
        padding: 40,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            padding: '10px 0',
            margin: 0,
        },
        '& ul': {
            textAlign: 'left',
        },
    },
    iCanIcon: {
        fontSize: 60,
        color: '#8080ff',
    },
    descActions: {
        width: '100%',
        textAlign: 'right',
    },
}

export default styles

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, IconButton, Hidden, Drawer } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import styles from './styles/studentHeaderStyles'

const useStyles = makeStyles(styles)

const StudentHeader = ({ changeColorOnScroll, color, rightLinks, leftLinks, brand, fixed, absolute }) => {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = useState(false)

    useEffect(() => {
        if (changeColorOnScroll) {
            window.addEventListener('scroll', headerColorChange)
        }
        return function cleanup() {
            if (changeColorOnScroll) {
                window.removeEventListener('scroll', headerColorChange)
            }
        }
    })

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    const headerColorChange = () => {
        const headerTag = document.body.getElementsByTagName('header')[0]
        if (window.pageYOffset > changeColorOnScroll.height) {
            headerTag?.classList.remove(classes[color])
            headerTag?.classList.add(classes[changeColorOnScroll.color])
        } else {
            headerTag?.classList.add(classes[color])
            headerTag?.classList.remove(classes[changeColorOnScroll.color])
        }
    }

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed,
    })

    const brandComponent = (
        <IconButton size='small' className={classes.logo}>
            <Link to='/student/dashboard'>
                <img src='/assets/img/logo-planetfraternity100.png' alt={'logo ' + brand} width='60' />
            </Link>
        </IconButton>
    )

    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.container}>
                {leftLinks !== undefined ? brandComponent : null}
                <div className={classes.flex}>{brandComponent}</div>
                <Hidden smDown implementation='css'>
                    {rightLinks}
                </Hidden>
                <Hidden mdUp>
                    <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Hidden mdUp implementation='js'>
                <Drawer
                    variant='temporary'
                    anchor={'right'}
                    open={mobileOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={handleDrawerToggle}
                >
                    <div className={classes.appResponsive}>
                        {leftLinks}
                        {rightLinks}
                    </div>
                </Drawer>
            </Hidden>
        </AppBar>
    )
}

StudentHeader.defaultProp = {
    color: 'white',
}

StudentHeader.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            'primary',
            'info',
            'success',
            'warning',
            'danger',
            'transparent',
            'white',
            'rose',
            'dark',
        ]).isRequired,
    }),
}

export default StudentHeader

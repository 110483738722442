import * as types from '../constants/topicConstants'

export const topicRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TOPIC_REGISTER_REQUEST:
      return { loadingTopicRegister: true }
    case types.TOPIC_REGISTER_SUCCESS:
      return {
        loadingTopicRegister: false,
        successTopicRegister: true,
        topic: action.payload,
      }
    case types.TOPIC_REGISTER_FAIL:
      return {
        loadingTopicRegister: false,
        errorTopicRegister: action.payload,
      }
    case types.TOPIC_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const topicListReducer = (state = { topics: [] }, action) => {
  switch (action.type) {
    case types.TOPIC_LIST_REQUEST:
      return { loadingTopicList: true }
    case types.TOPIC_LIST_SUCCESS:
      return {
        loadingTopicList: false,
        topics: action.payload,
        thatsIt: action.payload.length === 0 ? true : false,
      }
    case types.TOPIC_LIST_FAIL:
      return {
        loadingTopicList: false,
        errorTopicList: action.payload,
      }
    case types.TOPIC_LIST_RESET:
      return { topics: [] }
    default:
      return state
  }
}

export const topicDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TOPIC_DETAILS_REQUEST:
      return { loadingTopicDetails: true }
    case types.TOPIC_DETAILS_SUCCESS:
      return {
        loadingTopicDetails: false,
        topic: action.payload,
      }
    case types.TOPIC_DETAILS_FAIL:
      return {
        loadingTopicDetails: false,
        errorTopicDetails: action.payload,
      }
    case types.TOPIC_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const topicUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TOPIC_UPDATE_REQUEST:
      return { loadingTopicUpdate: true }
    case types.TOPIC_UPDATE_SUCCESS:
      return {
        loadingTopicUpdate: false,
        successTopicUpdate: true,
        topicUpdated: action.payload,
      }
    case types.TOPIC_UPDATE_FAIL:
      return {
        loadingTopicUpdate: false,
        errorTopicUpdate: action.payload,
      }
    case types.TOPIC_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const topicDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TOPIC_DELETE_REQUEST:
      return { loadingTopicDelete: true }
    case types.TOPIC_DELETE_SUCCESS:
      return {
        loadingTopicDelete: false,
        successTopicDelete: true,
      }
    case types.TOPIC_DELETE_FAIL:
      return {
        loadingTopicDelete: false,
        errorTopicDelete: action.payload,
      }
    case types.TOPIC_DELETE_RESET:
      return {}
    default:
      return state
  }
}

import Meta from '../../../components/Meta/Meta'

const ComingNextMonthScreen = () => {
    return (
        <>
            <Meta title={'Coming Next Month - Planet Fraternity'} />
            <h1>Coming Next Month</h1>
        </>
    )
}

export default ComingNextMonthScreen

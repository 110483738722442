const styles = {
	descRoot: {
		position: 'relative',
		padding: '10px 30px 30px',
	},
	formSections: {
		border: '3px solid #d6d6d6',
		borderRadius: '10px',
		padding: '20px 20px 40px',
		margin: '15px 0 0',
		'& h2': {
			textAlign: 'center',
			margin: 0,
		},
	},
	inputItem: {
		margin: 0,
		paddingTop: '12px',
		'& input': {
			padding: '22px 0 0',
		},
		'& .MuiSelect-root': {
			padding: '5px 0',
		},
	},
	formControl: {
		marginTop: '11px',
		minWidth: 120,
		width: '100%',
		textAlign: 'left',
	},
	selectLabel: {
		color: '#AAAAAA !important',
		fontWeight: '400',
		fontSize: '14px',
		letterSpacing: 'unset',
	},
	selectInput: {
		marginTop: '10px !important',
		'&:before': {
			borderBottom: '1px solid rgb(210 210 210)',
		},
	},
	btnMarginTop: {
		marginTop: '35px',
	},
	checkedIcon: {
		width: '20px',
		height: '20px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
	uncheckedIcon: {
		width: '0px',
		height: '0px',
		padding: '9px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
	checked: {
		color: '#9c27b0 !important',
	},
	checkRoot: {
		padding: '12px',
		'&:hover': {
			backgroundColor: 'unset',
		},
	},
	label: {
		cursor: 'pointer',
		paddingLeft: '0',
		color: 'rgba(0, 0, 0, 0.26)',
		fontSize: '14px',
		lineHeight: '1.428571429',
		fontWeight: '400',
		display: 'inline-flex',
		transition: '0.3s ease all',
		letterSpacing: 'unset',
	},
	labelRoot: {
		marginLeft: '-14px',
		marginBottom: 0,
	},
	btnLink: {
		fontSize: 12,
		fontWeight: '500',
		color: '#483d3d',
		margin: '15px 0',
		padding: 0,
		textTransform: 'none',
		'& span': {
			fontSize: 14,
		},
	},
	centerInfoDiv: {
		border: '1px solid #d2d0d0',
		borderRadius: '6px',
		paddingTop: '6px',
		marginBottom: '7px',
		boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		'& h5': {
			textAlign: 'center',
			margin: 0,
			color: '#1f7a2e',
		},
		'& p': {
			margin: 0,
		},
	},
	donationDiv: {
		padding: '10px',
		textAlign: 'center',
		margin: '5px 0',
		border: '1px solid #d2d0d0',
		borderRadius: '6px',
		'& h4': {
			padding: '5px 10px',
			borderRadius: '6px',
			backgroundColor: '#f3d5d5',
		},
	},
	alertText: {
		'& h5': {
			textAlign: 'center',
			color: '#ff5722',
		},
	},
	'@media screen and (max-width: 1200px)': {
		descRoot: {
			padding: 0,
		},
	},
	'@media screen and (max-width: 980px)': {},
	'@media screen and (max-width: 580px)': {
		descRoot: {
			padding: 5,
		},
	},
	btnContainer: {
		marginLeft: 'auto',
	},
	groupSection: {
		margin: '0 0 35px',
		'& h2': {
			textAlign: 'center',
		},
	},
	tableHead: {
		backgroundColor: '#ececec',
	},
}

export default styles

import { Grid, makeStyles } from '@material-ui/core'
import styles from './styles/howToSubscribeScreenStyles'

const useStyles = makeStyles(styles)

const HowToSubscribeScreen = () => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12} sm={9} className={classes.videoRoot}>
        <video poster='/assets/img/public-site/register-center-tutorial-poster.png' controls>
          <source src='/assets/video/how-to-register-center-tutorial.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </Grid>
    </Grid>
  )
}

export default HowToSubscribeScreen

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import CustomInput from '../../CustomInput/CustomInput'
import Button from '../../Buttons/Button'
import Loader from '../../Loader/Loader'
import Message from '../../Message/Message'
import { deleteGroup } from '../../../redux/actions/groupActions'
import styles from './styles/groupDeleteModalStyles'

const useStyles = makeStyles(styles)

const GroupDeleteModal = ({ setOpenDelete, group, setGroupId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(true)
  const [phrase, setPhrase] = useState('')

  const groupDelete = useSelector((state) => state.groupDelete)
  const { loadingGroupDelete, errorGroupDelete, successGroupDelete } = groupDelete

  useEffect(() => {
    if (phrase === 'absolutely sure') {
      setDeleteBtnDisabled(false)
    } else {
      setDeleteBtnDisabled(true)
    }
  }, [phrase])

  useEffect(() => {
    if (successGroupDelete) {
      setOpenDelete(false)
    }
  }, [successGroupDelete, setOpenDelete])

  const deleteHandler = () => {
    if (setGroupId) {
      setGroupId(group._id)
    }
    dispatch(deleteGroup(group._id))
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <p>
          Are you sure you want to delete this group? <strong>{group.name}</strong>
        </p>
        <p>You are about to permanently delete this group</p>
        <p>
          Once a group is permanently deleted, it cannot be recovered. Permanently deleting this group will immediately
          delete its students (if there are any) and all related resources.
        </p>
        <p>Please type the phrase below to enable delete button.</p>
        <p className={classes.deletePhrase}>absolutely sure</p>
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          id={'absolutelysure' + group.name}
          inputProps={{
            autoFocus: true,
            placeholder: 'Type the phrase here',
            type: 'text',
            autoComplete: 'off',
            onChange: (e) => setPhrase(e.target.value),
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Button color='primary' block onClick={() => setOpenDelete(false)}>
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button disabled={deleteBtnDisabled} color='danger' block onClick={deleteHandler}>
          {loadingGroupDelete ? <Loader size='sm' /> : 'Delete'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {errorGroupDelete && <Message>{errorGroupDelete}</Message>}
      </Grid>
    </Grid>
  )
}

export default GroupDeleteModal

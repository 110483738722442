import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { Edit, Person } from '@material-ui/icons'
import { format } from 'date-fns'
import axios from 'axios'
import classNames from 'classnames'
import Loader from '../../../../components/Loader/Loader'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CustomTabs from '../../../../components/CustomTabs/CustomTabs'
import UserCardForm from './UserCardForm'
// import MessagesTab from './MessagesTab'
import { getCenterById } from '../../../../redux/actions/centerActions'
import { getTeachersByCenter } from '../../../../redux/actions/teacherActions'
import { getStudentsByCenter } from '../../../../redux/actions/studentActions'
import { getGroupsByCenter } from '../../../../redux/actions/groupActions'
import styles from '../styles/userCardstyles'

const useStyles = makeStyles(styles)

const UserCard = ({ user }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { loadingCenterDetails, center } = useSelector((state) => state.centerDetails)
  const { loadingTeachersByCenter, teachersByCenterQty } = useSelector((state) => state.teachersByCenter)
  const { loadingStudentsByCenter, studentsByCenterQty } = useSelector((state) => state.studentsByCenter)
  const { loadingGroupListByCenter, groupListByCenterQty } = useSelector((state) => state.groupListByCenter)

  useEffect(() => {
    if (user.center) {
      dispatch(getCenterById(user.center))
      dispatch(getTeachersByCenter(user.center))
      dispatch(getStudentsByCenter(null, null, null, user.center))
      dispatch(getGroupsByCenter(user.center))
    }
  }, [dispatch, user])

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Card className={classNames(classes.main, classes.mainRaised, classes.cardRoot)}>
          <CardAvatar profile>
            <img src={`${axios.defaults.baseURL}${user.avatar}`} alt={user.name} />
          </CardAvatar>
          <CardBody profile className={classes.cardBodyRoot}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h4 className={classes.cardTitle}>{user.name}</h4>
              </Grid>
              <Grid item xs={12}>
                <CustomTabs
                  headerColor='danger'
                  className={classes.customTabsRoot}
                  tabs={[
                    {
                      tabName: 'Profile',
                      tabIcon: Person,
                      tabContent: (
                        <Grid container spacing={3} className={classes.infoRoot}>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.userInfoSection}>
                              <h5>Basic Info.</h5>
                              <div>
                                <span>Google Email · {user.googleMail}</span>
                              </div>
                              <div>
                                <span className={classes.subtitleCapital}>Role · {user.role}</span>
                              </div>
                              <span>
                                Country · {user.country.name} ·{' '}
                                <img
                                  className={classes.countryFlag}
                                  src={`${axios.defaults.baseURL}${user.country.flag}`}
                                  alt={user.country.name}
                                />
                              </span>
                              <div>
                                <span>Registered since · {format(new Date(user.createdAt), 'MMMM yyyy')}</span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.userInfoSection}>
                              <h5>Contact Info.</h5>
                              <div>
                                <span>Email · {user.email ? user.email : 'Not Registered'}</span>
                              </div>
                              <div>
                                <span>Phone · {user.phone ? user.phone : 'Not Registered'}</span>
                              </div>
                              <div>
                                <span>Address · {user.address ? user.address : 'Not Registered'}</span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.userInfoSection}>
                              <h5>Center Basics</h5>
                              {loadingCenterDetails ? (
                                <Loader />
                              ) : center?.name ? (
                                <>
                                  <div>
                                    <span>{center.name}</span>
                                  </div>
                                  <div>
                                    <span>Email · {center.email ? center.email : 'Not Registered'}</span>
                                  </div>
                                  <div>
                                    <span>Address · {center.address ? center.address : 'Not Registered'}</span>
                                  </div>
                                  <div>
                                    <span>
                                      Country · {center.country.name} ·{' '}
                                      <img
                                        src={`${axios.defaults.baseURL}${center.country.flag}`}
                                        className={classes.countryFlagProfile}
                                        alt={'Flag of ' + center.country.name}
                                      />
                                    </span>
                                  </div>
                                </>
                              ) : (
                                'Not registered in a Center'
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.userInfoSection}>
                              <h5>Center People</h5>
                              {loadingCenterDetails ? (
                                <Loader />
                              ) : center?.name ? (
                                <>
                                  <div>
                                    <span>Students Qty · </span>
                                    {loadingStudentsByCenter ? <Loader /> : <span>{studentsByCenterQty}</span>}
                                  </div>
                                  <div>
                                    <span>Groups Qty · </span>
                                    {loadingGroupListByCenter ? <Loader /> : <span>{groupListByCenterQty}</span>}
                                  </div>
                                  <div>
                                    <span>Teachers Qty · </span>
                                    {loadingTeachersByCenter ? (
                                      <Loader />
                                    ) : (
                                      teachersByCenterQty && <span>{teachersByCenterQty}</span>
                                    )}
                                  </div>
                                  <div>
                                    <span>Center Principal · {center.schoolPrincipal.name}</span>
                                  </div>
                                </>
                              ) : (
                                'Not registered in a Center'
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      tabName: 'Edit Profile',
                      tabIcon: Edit,
                      tabContent: <UserCardForm user={user} />,
                    },
                    // {
                    //     tabName: 'Messages',
                    //     tabIcon: Email,
                    //     tabContent: <MessagesTab />,
                    // },
                  ]}
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default UserCard

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import CustomModal from '../../../components/Modal/CustomModal'
import SurveyCard from './components/SurveyCard'
import AnswerCard from './components/AnswerCard'
import SurveyListCard from '../../../components/Surveys/SurveyListCard/SurveyListCard'
import SurveyRegisterFormModal from './components/SurveyRegisterFormModal'
import SurveyButton from './components/SurveyButton'
import { getSurveys } from '../../../redux/actions/surveyActions'
import {
  ANSWER_SURVEY_RESET,
  SURVEY_DELETE_RESET,
  SURVEY_LIST_RESET,
  SURVEY_REGISTER_RESET,
  SURVEY_UPDATE_RESET,
} from '../../../redux/constants/surveyConstants'
import styles from './styles/surveyListScreenStyles'

const useStyles = makeStyles(styles)

const SurveyListScreen = ({ match }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [surveyDetails, setSurveyDetails] = useState(null)
  const [answerDetails, setAnswerDetails] = useState(null)
  const [matchKeyword, setMatchKeyword] = useState('')
  const [surveyArray, setSurveyArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { surveys, thatsIt, errorSurveyList } = useSelector((state) => state.surveyList)
  const { successAnswerSurvey, answersSurvey } = useSelector((state) => state.answerSurvey)
  const { successSurveyUpdate } = useSelector((state) => state.surveyUpdate)
  const { successSurveyDelete } = useSelector((state) => state.surveyDelete)
  const { successSurveyRegister } = useSelector((state) => state.surveyRegister)

  useEffect(() => {
    if (userInfo) {
      if (successSurveyDelete || successSurveyRegister || successSurveyUpdate) {
        if (successSurveyDelete) {
          dispatch({ type: SURVEY_DELETE_RESET })
          setSurveyDetails(null)
        } else if (successSurveyRegister) {
          dispatch({ type: SURVEY_REGISTER_RESET })
          setSurveyDetails(null)
        } else if (successSurveyUpdate) {
          dispatch({ type: SURVEY_UPDATE_RESET })
        }
        setSurveyArray([])
        setStart(1)
        setHasMore(true)
        setInitialHasMore(true)
        return true
      } else {
        if (keyword !== undefined) {
          if (keyword !== matchKeyword) {
            setSurveyArray([])
            setInitialHasMore(true)
            setMatchKeyword(keyword)
            setStart(1)
          } else {
            if (initalHasMore) {
              setInitialHasMore(false)
              dispatch(getSurveys(keyword, count, 0))
            } else if (surveys && surveys.length > 0) {
              setSurveyArray([...surveys])
            }
          }
        } else {
          if (initalHasMore) {
            dispatch(getSurveys(keyword, count, 0))
            setInitialHasMore(false)
          } else if (surveys && surveys.length > 0) {
            setSurveyArray([...surveys])
          }
        }
      }
    }
  }, [
    successSurveyDelete,
    successSurveyRegister,
    successSurveyUpdate,
    dispatch,
    userInfo,
    keyword,
    matchKeyword,
    surveys,
    initalHasMore,
    count,
  ])

  useEffect(() => {
    if (thatsIt || errorSurveyList) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorSurveyList])

  useEffect(() => {
    return () => {
      dispatch({ type: SURVEY_LIST_RESET })
      dispatch({ type: ANSWER_SURVEY_RESET })
    }
  }, [dispatch])

  useEffect(() => {
    if (answersSurvey) {
      setAnswerDetails(answersSurvey)
    }
  }, [answersSurvey])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getSurveys(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Survey List - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h1>Survey List</h1>
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <SurveyButton setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfiniteScroll
            dataLength={surveyArray.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            height={'60vh'}
            className={classes.infiniteScroll}
            endMessage={
              <p className={classes.contentLine}>
                <b>This is the last line.</b>
              </p>
            }
          >
            {surveyArray.map((survey) => (
              <SurveyListCard
                key={survey._id}
                survey={survey}
                setSurveyDetails={setSurveyDetails}
                setAnswerDetails={setAnswerDetails}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} md={8}>
          {answerDetails !== null && successAnswerSurvey && (
            <AnswerCard answers={answerDetails} setAnswerDetails={setAnswerDetails} />
          )}
          {surveyDetails !== null && <SurveyCard survey={surveyDetails} />}
          {answerDetails === null && surveyDetails === null && (
            <div className={classes.rightSection}>
              <Assignment className={classes.peoplesIcon} />
              <h2>Select surveys's names to preview their survey.</h2>
            </div>
          )}
        </Grid>
      </Grid>
      <CustomModal title='Register Survey' open={open} setOpen={setOpen} actions={false}>
        <SurveyRegisterFormModal setOpen={setOpen} open={open} />
      </CustomModal>
    </div>
  )
}

export default SurveyListScreen

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import styles from '../styles/sliderStyles'
import { FormatQuote } from '@material-ui/icons'
import axios from 'axios'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)

const TestimonialsDetails = ({ testimonial }) => {
	const classes = useStyles()

	return (
		<div className={classNames(classes.testimonialItem, 'item')}>
			<div className={classes.shadowEffect}>
				<div className={classes.infoQuote}>
					<FormatQuote />
				</div>
				<p>{testimonial.testimonial}</p>
			</div>
			<div className={classes.testimonialName}>
				<h5>{testimonial.teacherName || testimonial.teacher?.name}</h5>
				<div className={classes.testimonialSection}>
					<small>{testimonial.center || testimonial.teacher?.center?.name}</small>
					<div className={classes.mainImgSection}>
						<img
							className={classes.countryFlag}
							alt={testimonial.country}
							src={`${axios.defaults.baseURL}${
								testimonial?.country?.flag || testimonial?.teacher?.center?.country.flag
							}`}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TestimonialsDetails

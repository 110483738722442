import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'
const styles = {
  contentItemsEnd,
  infiniteScroll,
  root: {
    padding: '30px',
    '& h1': {
      lineHeight: 'normal',
    },
  },
  btnResetSearch: {
    marginRight: '15px',
  },

  lastLine: {
    textAlign: 'center',
  },
  areaField: {
    width: '100%',
  },
  switchItem: {
    textAlign: 'left',
  },
}
export default styles

const styles = {
	inputItem: {
		margin: 0,
		paddingTop: '20px',
		'& input': {
			padding: '5px 0',
		},
		'& .MuiSelect-root': {
			padding: '5px 0',
		},
	},
	formControl: {
		marginTop: '11px',
		minWidth: 120,
		width: '100%',
		textAlign: 'left',
	},
	formControlCategory: {
		marginTop: '20px',
		minWidth: 120,
		width: '100%',
		textAlign: 'left',
	},
	selectLabel: {
		color: '#AAAAAA !important',
		fontWeight: '400',
		fontSize: '14px',
		letterSpacing: 'unset',
	},
	selectInput: {
		marginTop: '6px !important',
		'&:before': {
			borderBottom: '1px solid rgb(210 210 210)',
		},
	},
	btnMarginTop: {
		marginTop: '35px',
	},
	errorMessage: {
		padding: '20px 20px',
		width: '100%',
		color: '#fff',
		boxShadow:
			'0 12px 20px -10px rgb(0 188 212 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(0 188 212 / 20%)',
		backgroundColor: '#00d3ee',
	},
}

export default styles

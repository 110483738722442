export const CENTER_REGISTER_REQUEST = 'CENTER_REGISTER_REQUEST'
export const CENTER_REGISTER_SUCCESS = 'CENTER_REGISTER_SUCCESS'
export const CENTER_REGISTER_FAIL = 'CENTER_REGISTER_FAIL'
export const CENTER_REGISTER_RESET = 'CENTER_REGISTER_RESET'

export const CENTER_GROUPS_REQUEST = 'CENTER_GROUPS_REQUEST'
export const CENTER_GROUPS_SUCCESS = 'CENTER_GROUPS_SUCCESS'
export const CENTER_GROUPS_FAIL = 'CENTER_GROUPS_FAIL'
export const CENTER_GROUPS_RESET = 'CENTER_GROUPS_RESET'

export const CENTER_ACTIVATE_REQUEST = 'CENTER_ACTIVATE_REQUEST'
export const CENTER_ACTIVATE_SUCCESS = 'CENTER_ACTIVATE_SUCCESS'
export const CENTER_ACTIVATE_FAIL = 'CENTER_ACTIVATE_FAIL'
export const CENTER_ACTIVATE_RESET = 'CENTER_ACTIVATE_RESET'

export const CENTER_LIST_REQUEST = 'CENTER_LIST_REQUEST'
export const CENTER_LIST_SUCCESS = 'CENTER_LIST_SUCCESS'
export const CENTER_LIST_FAIL = 'CENTER_LIST_FAIL'

export const CENTER_NOTPARTNERED_REQUEST = 'CENTER_NOTPARTNERED_REQUEST'
export const CENTER_NOTPARTNERED_SUCCESS = 'CENTER_NOTPARTNERED_SUCCESS'
export const CENTER_NOTPARTNERED_FAIL = 'CENTER_NOTPARTNERED_FAIL'
export const CENTER_NOTPARTNERED_RESET = 'CENTER_NOTPARTNERED_RESET'

export const CENTER_PARTNERS_REQUEST = 'CENTER_PARTNERS_REQUEST'
export const CENTER_PARTNERS_SUCCESS = 'CENTER_PARTNERS_SUCCESS'
export const CENTER_PARTNERS_FAIL = 'CENTER_PARTNERS_FAIL'
export const CENTER_PARTNERS_RESET = 'CENTER_PARTNERS_RESET'

export const CENTER_BY_COUNTRY_REQUEST = 'CENTER_BY_COUNTRY_REQUEST'
export const CENTER_BY_COUNTRY_SUCCESS = 'CENTER_BY_COUNTRY_SUCCESS'
export const CENTER_BY_COUNTRY_FAIL = 'CENTER_BY_COUNTRY_FAIL'
export const CENTER_BY_COUNTRY_RESET = 'CENTER_BY_COUNTRY_RESET'

export const CENTER_UPDATE_REQUEST = 'CENTER_UPDATE_REQUEST'
export const CENTER_UPDATE_SUCCESS = 'CENTER_UPDATE_SUCCESS'
export const CENTER_UPDATE_FAIL = 'CENTER_UPDATE_FAIL'
export const CENTER_UPDATE_RESET = 'CENTER_UPDATE_RESET'

export const CENTER_DETAILS_REQUEST = 'CENTER_DETAILS_REQUEST'
export const CENTER_DETAILS_SUCCESS = 'CENTER_DETAILS_SUCCESS'
export const CENTER_DETAILS_FAIL = 'CENTER_DETAILS_FAIL'
export const CENTER_DETAILS_RESET = 'CENTER_DETAILS_RESET'

export const CENTER_DELETE_REQUEST = 'CENTER_DELETE_REQUEST'
export const CENTER_DELETE_SUCCESS = 'CENTER_DELETE_SUCCESS'
export const CENTER_DELETE_FAIL = 'CENTER_DELETE_FAIL'
export const CENTER_DELETE_RESET = 'CENTER_DELETE_RESET'

export const CENTER_SEND_FRIENDREQ_REQUEST = 'CENTER_SEND_FRIENDREQ_REQUEST'
export const CENTER_SEND_FRIENDREQ_SUCCESS = 'CENTER_SEND_FRIENDREQ_SUCCESS'
export const CENTER_SEND_FRIENDREQ_FAIL = 'CENTER_SEND_FRIENDREQ_FAIL'
export const CENTER_SEND_FRIENDREQ_RESET = 'CENTER_SEND_FRIENDREQ_RESET'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerUser } from '../../../../redux/actions/userActions'
import styles from '../styles/userRegisterFormModalStyles'

const useStyles = makeStyles(styles)

const UserRegisterFormModal = ({ setOpen }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')

    const userRegister = useSelector((state) => state.userRegister)
    const { loadingUserRegister, errorUserRegister, successUserRegister } = userRegister

    useEffect(() => {
        if (successUserRegister) {
            setOpen(false)
            setName('')
            setUsername('')
            setEmail('')
            setPhone('')
            setAddress('')
        }
    }, [successUserRegister, setOpen])

    const submitFormHandler = (e) => {
        e.preventDefault()
        const createUser = {
            name,
            username,
            email,
            password: '123456',
            phone,
            address,
            isCollaborator: true,
        }
        dispatch(registerUser(createUser))
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <form onSubmit={submitFormHandler} autoComplete='off'>
                    <CustomInput
                        labelText='Name'
                        id='name'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputItem,
                        }}
                        inputProps={{
                            type: 'text',
                            value: name,
                            placeholder: 'Enter complete name',
                            onChange: (e) => setName(e.target.value),
                            required: true,
                        }}
                    />
                    <CustomInput
                        labelText='Username'
                        id='username'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputItem,
                        }}
                        inputProps={{
                            type: 'text',
                            value: username,
                            placeholder: 'example: jdoe',
                            onChange: (e) => setUsername(e.target.value),
                            required: true,
                        }}
                    />
                    <CustomInput
                        labelText='Email'
                        id='email'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputItem,
                        }}
                        inputProps={{
                            type: 'email',
                            value: email,
                            placeholder: 'Enter a valid email',
                            onChange: (e) => setEmail(e.target.value),
                        }}
                    />
                    <CustomInput
                        labelText='Phone'
                        id='phone'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputItem,
                        }}
                        inputProps={{
                            type: 'text',
                            value: phone,
                            placeholder: 'E.g. +34 000 000000',
                            onChange: (e) => setPhone(e.target.value),
                        }}
                    />
                    <CustomInput
                        labelText='Address'
                        id='address'
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputItem,
                        }}
                        inputProps={{
                            type: 'text',
                            value: address,
                            placeholder: 'Enter address (Optional)',
                            onChange: (e) => setAddress(e.target.value),
                        }}
                    />
                    <p className={classes.passMessage}>The default password is going to be 1 through 6</p>
                    {errorUserRegister && <Message message={<span>{errorUserRegister}</span>} color='info' />}
                    <Button type='submit' color='primary' block>
                        {loadingUserRegister ? <Loader size={'sm'} /> : 'Submit'}
                    </Button>
                </form>
            </Grid>
        </Grid>
    )
}

export default UserRegisterFormModal

const styles = {
    formControl: {
        margin: 0,
    },
    header: {
        position: 'relative',
        '& h4': {
            margin: '10px 0 15px 0 !important',
        },
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: '#f9f0f0',
    },
}
export default styles

const styles = {
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& h2': {
      textAlign: 'center',
    },
  },
  magazineIcon: {
    fontSize: 70,
    color: '#00acc1',
  },
}

export default styles

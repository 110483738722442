const styles = {
	areaField: {
		width: '100%',
	},
	modalRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '10px',
	},
	'@media screen and (max-width: 780px)': {
		modalRoot: {
			width: '400px',
		},
	},
	'@media screen and (max-width: 580px)': {
		modalRoot: {
			width: '250px',
		},
	},
	messageAndImageSelector: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	error: {
		color: 'red',
	},
	success: {
		color: 'green',
	},
}
export default styles

import { makeStyles } from '@material-ui/core'
import { LocationCity } from '@material-ui/icons'
import styles from '../styles/pendingSubsRightSectionStyles.js'

const useStyles = makeStyles(styles)

const PendingSubsRightSection = () => {
  const classes = useStyles()

  return (
    <div className={classes.rightSection}>
      <LocationCity className={classes.schoolIcon} />
      <h2>Select center's names to manage subscription.</h2>
    </div>
  )
}

export default PendingSubsRightSection

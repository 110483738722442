import { Group } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import styles from '../styles/userRightSectionStyles'

const useStyles = makeStyles(styles)

const UserRightSection = () => {
  const classes = useStyles()

  return (
    <div className={classes.rightSection}>
      <Group className={classes.peoplesIcon} />
      <h2>Select people's names to preview their profile.</h2>
    </div>
  )
}

export default UserRightSection

const styles = {
  areaField: {
    width: '100%',
  },
  modalRoot: {
    width: '600px',
  },
  '@media screen and (max-width: 780px)': {
    modalRoot: {
      width: '400px',
    },
  },
  '@media screen and (max-width: 580px)': {
    modalRoot: {
      width: '250px',
    },
  },
}
export default styles

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { VectorMap } from '@south-paw/react-vector-maps'
import ReactTooltip from 'react-tooltip'
import axios from 'axios'
import {
	Avatar,
	Card,
	CardHeader,
	Grid,
	makeStyles,
	Tooltip,
	Button as CustomButton,
	Typography,
	Box,
} from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import CustomModal from '../../../../components/Modal/CustomModal'
import Button from '../../../../components/Buttons/Button'
import world from '../../../../assets/jss/world.json'
import { getAllCountries } from '../../../../redux/actions/countryActions'
import { getCentersByCountry } from '../../../../redux/actions/centerActions'
import { getCountryPostsByStatusAndCountry } from '../../../../redux/actions/countryPostsActions'
import CountryPostComponent from '../../../../components/CountryPostComponent/CountryPostComponent'
import styles from '../styles/mapStyles'
import { COUNTRY_POST_ACTIVE_LIST_RESET } from '../../../../redux/constants/countryPostConstants'
import { useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

const useStyles = makeStyles(styles)

const Map = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation('global')

	const [hovered, setHovered] = useState('')
	const [flag, setFlag] = useState('')
	const [open, setOpen] = useState(false)
	const [centersByCountry, setCentersByCountry] = useState([])
	const [noRepeatCenters, setNoRepeatCenters] = useState([])
	const [countryPostsMode, setCountryPostsMode] = useState(false)

	const { allCountries } = useSelector((state) => state.countryAll)
	const { loadingCentersByCountry, centers } = useSelector((state) => state.centersByCountry)
	const activeCountryPosts = useSelector((state) => state.countryPostActiveList?.countryPosts ?? [])
	const thatsIt = useSelector((state) => state.countryPostActiveList?.thatsIt ?? false)
	const [countryPostsData, setCountryPostsData] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [start, setStart] = useState(1)
	const count = 3

	useEffect(() => {
		dispatch(getAllCountries())
		dispatch(getCentersByCountry())
	}, [dispatch])
	useEffect(() => {
		if (centers) {
			setNoRepeatCenters(
				Object.values(centers.reduce((acc, cur) => Object.assign(acc, { [cur.country._id.toString()]: cur }), {})),
			)
		}
	}, [centers])
	useEffect(() => {
		const data = document.querySelectorAll('path')

		if (centers) {
			centers.forEach((center) => {
				data.forEach((value) => {
					if (center.country.name === value.ariaLabel) {
						value.setAttribute('style', 'fill: #1f7a2e; cursor: pointer;')
					}
				})
			})
		}
	}, [centers])
	useEffect(() => {
		if (activeCountryPosts.length) {
			setCountryPostsData([...activeCountryPosts])
		}
	}, [activeCountryPosts])
	useEffect(() => {
		if (!open) {
			setCountryPostsData([])
			setStart(1)
			setHasMore(true)
			dispatch({ type: COUNTRY_POST_ACTIVE_LIST_RESET })
		}
	}, [open])
	useEffect(() => {
		if (thatsIt) {
			setHasMore(false)
		} else {
			setHasMore(true)
		}
	}, [thatsIt])

	const openModal = (name) => {
		const centerCountry = []
		if (centers) {
			centers.forEach((center) => {
				if (center.country.name === name && !center.isForTest) {
					centerCountry.push(center)
				}
			})
		}
		setCentersByCountry(centerCountry)
		if (centerCountry.length) dispatch(getCountryPostsByStatusAndCountry(true, centerCountry[0].country._id, count, 0))
		setOpen(name)
	}

	const layerProps = {
		onMouseEnter: (e) => {
			setHovered(e.target.attributes.name.value)
			if (allCountries) {
				allCountries.forEach((countrie) => {
					if (countrie.name === e.target.attributes.name.value) {
						setFlag(countrie.flag)
					}
				})
			}
		},
		onMouseLeave: ({ target }) => {
			setHovered('')
			setFlag('')
		},
		onClick: ({ target }) => {
			openModal(target.attributes.name.value)
		},
	}

	const handleViewCountry = (name) => {
		openModal(name)
	}

	const countryPostFetchData = useCallback(() => {
		if (hasMore) {
			setStart(start + 1)
			const centerCountry = []
			if (centers) {
				centers.forEach((center) => {
					if (center.country.name === open && !center.isForTest) {
						centerCountry.push(center)
					}
				})
			}
			dispatch(getCountryPostsByStatusAndCountry(true, centerCountry[0].country._id, count, start))
		}
	}, [hasMore, centers, open, start])

	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={3} md={3} className={classes.sectionCountries}>
					<Grid container>
						<Grid item md={12} xs={12}>
							<h2>{t('layout.t_home_countryByCenter')}</h2>
						</Grid>
						{loadingCentersByCountry ? (
							<Loader />
						) : (
							<>
								{noRepeatCenters &&
									noRepeatCenters.map((center) => (
										<CustomButton key={center._id} className={classes.btnCountries}>
											<Grid
												onClick={() => handleViewCountry(center.country?.name)}
												item
												md={12}
												sm={12}
												xs={12}
												className={classes.countriesCenters}
											>
												<img src={`${axios.defaults.baseURL}${center.country.flag}`} alt={center._id} />
												<p>{center.country.name}</p>
											</Grid>
										</CustomButton>
									))}
							</>
						)}
					</Grid>
				</Grid>

				<Grid item xs={12} sm={9} md={9}>
					<h2 className={classes.mapTitle}>{t('layout.t_home_map')}</h2>
					<ReactTooltip id='sadFace'>
						{hovered.length > 2 ? (
							<div>
								{hovered}
								{flag.length > 2 && (
									<img className={classes.countryFlag} src={`${axios.defaults.baseURL}${flag}`} alt='countrie' />
								)}
							</div>
						) : (
							<p className={classes.countrySelect}>{t('layout.t_home_countryMap')}</p>
						)}
					</ReactTooltip>
					<VectorMap data-tip data-for='sadFace' className={classes.mapStyle} {...world} layerProps={layerProps} />
				</Grid>
			</Grid>

			{centersByCountry && centersByCountry.length > 0 && (
				<CustomModal
					title={
						countryPostsMode ? (
							<p>
								{t('countries.countryPosts.subtitle', { country: centersByCountry[0].country.name })}
								<img
									src={`${axios.defaults.baseURL}${centersByCountry[0].country?.flag}`}
									width='30px'
									alt={centersByCountry[0].country?.name}
									className={classes.flagInTitle}
								/>
							</p>
						) : (
							'Views Centers'
						)
					}
					open={open}
					setOpen={(e) => {
						setOpen(e)
						if (!e) setCountryPostsMode(false)
					}}
					actions={false}
				>
					<Grid container>
						{!countryPostsMode ? (
							<>
								<Grid item md={12} sm={12} xs={12} className={classes.sectionCountry}>
									<div className={classes.contentSection}>
										<img
											src={`${axios.defaults.baseURL}${centersByCountry[0].country.flag}`}
											alt={centersByCountry[0]._id}
										/>
										<div className={countryPostsData.length === 0 ? classes.countryDataFull : classes.countryData}>
											<h2>{centersByCountry[0].country.name}</h2>
											<div className={classes.countryDataKeys}>
												<p>
													{t('countries.keys.t_capital')}:{' '}
													{t(`countries.data.${centersByCountry[0].country.name.toLowerCase()}.t_capital`)}
												</p>
												<p>
													{t('countries.keys.t_language')}:{' '}
													{t(`countries.data.${centersByCountry[0].country.name.toLowerCase()}.t_language`)}
												</p>
											</div>
											<div className={classes.countryDataKeys}>
												<p>
													{t('countries.keys.t_demonym')}:{' '}
													{t(`countries.data.${centersByCountry[0].country.name.toLowerCase()}.t_demonym`)}
												</p>
												<p>
													{t('countries.keys.t_area')}:{' '}
													{t(`countries.data.${centersByCountry[0].country.name.toLowerCase()}.t_area`)}
												</p>
											</div>
										</div>
										{countryPostsData.length > 0 && (
											<Button color='primary' onClick={() => setCountryPostsMode(true)}>
												{t('countries.countryPosts.modalTitle')}
											</Button>
										)}
									</div>
									<div className={classes.contentSection}>
										<div className={classes.countryDescription}>
											<h2>{t('countries.keys.t_description')}</h2>
											<p>{t(`countries.data.${centersByCountry[0].country.name.toLowerCase()}.t_description`)}</p>
										</div>
									</div>
								</Grid>
								<Grid item md={12} sm={12} xs={12} className={classes.sectionCenters}>
									{centersByCountry &&
										centersByCountry.map((center) => (
											<Grid container key={center._id}>
												<Grid item md={12} sm={12} xs={12}>
													<Card className={classes.cardRoot}>
														<CardHeader
															className={classes.cardHeaderRoot}
															avatar={
																<Avatar aria-label='recipe' src={`${axios.defaults.baseURL}${center.logo}`}></Avatar>
															}
															title={
																<Tooltip placement='left-start' title={`${center.name}`}>
																	<span>{`${center.name}`}</span>
																</Tooltip>
															}
														/>
													</Card>
												</Grid>
											</Grid>
										))}
								</Grid>
							</>
						) : (
							<>
								<Grid container>
									<Grid item md={12} sm={12} xs={12} className={classes.sectionPosts}>
										<Grid container>
											<Grid item xs={12}>
												{countryPostsData.length > 0 && (
													<InfiniteScroll
														dataLength={countryPostsData.length}
														next={countryPostFetchData}
														hasMore={hasMore}
														loader={<Loader />}
														className={classes.infiniteScroll}
														height={'500px'}
														endMessage={
															<p className={classes.contentLine}>
																<b>{t('countries.countryPosts.noResults')}</b>
															</p>
														}
													>
														<Grid container>
															{countryPostsData.map((countryPost) => (
																<Grid key={countryPost._id} item md={12} sm={12} xs={12}>
																	<CountryPostComponent countryPost={countryPost} />
																</Grid>
															))}
														</Grid>
													</InfiniteScroll>
												)}
											</Grid>
											{countryPostsData.length === 0 && (
												<Grid item md={12} sm={12} xs={12}>
													<Typography variant='h5'>{t('countries.countryPosts.noResults')}</Typography>
												</Grid>
											)}
										</Grid>
									</Grid>
								</Grid>
							</>
						)}
						<Grid item md={12} sm={12} xs={12} className={classes.btnModal}>
							<Box
								width={'100%'}
								display='flex'
								px={3}
								flexDirection={'row'}
								justifyContent={countryPostsMode ? 'space-between' : 'flex-end'}
							>
								{countryPostsMode && (
									<Button color='danger' onClick={() => setCountryPostsMode(false)}>
										Back
									</Button>
								)}
								<Button
									color='primary'
									onClick={() => {
										setCountryPostsMode(false)
										setOpen(false)
									}}
								>
									Close
								</Button>
							</Box>
						</Grid>
					</Grid>
				</CustomModal>
			)}
		</>
	)
}

export default Map

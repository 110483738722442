import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'
const styles = {
  contentItemsEnd,
  infiniteScroll,
  root: {
    padding: '30px',
    '& h1': {
      lineHeight: 'normal',
    },
  },
  contentLine: {
    textAlign: 'center',
  },
  '@media screen and (max-width: 580px)': {
    root: {
      padding: '10px',
    },
  },
}
export default styles

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import { registerTestimonial } from '../../../../redux/actions/testimonialActions'
import styles from '../styles/testimonialModalStyles'

const useStyles = makeStyles(styles)

const TestimonialModal = ({ setOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [testimonialText, setTestimonialText] = useState('')

  const { userInfo } = useSelector((state) => state.userLogin)
  const { successTestimonialRegister } = useSelector((state) => state.testimonialRegister)

  useEffect(() => {
    if (successTestimonialRegister) {
      setOpen(false)
    }
  }, [successTestimonialRegister, setOpen])

  const submitTestimonial = () => {
    dispatch(registerTestimonial({ teacher: userInfo._id, testimonial: testimonialText }))
  }

  return (
    <>
      <Grid container spacing={2} className={classes.modalRoot}>
        <p>
          We would like you to tell us about your experiences within the Planet Fraternity platform!
          <strong> In the following text box, you can describe how you have felt working within the platform.</strong>
        </p>
        <p>The testimonial will be visible to all the people who visit our website, cheer up!</p>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            className={classes.areaField}
            id='outlined-textarea'
            label='Testimonial'
            placeholder='Testimonial'
            multiline
            value={testimonialText}
            onChange={(e) => setTestimonialText(e.target.value)}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Button type='button' color='success' block onClick={submitTestimonial}>
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default TestimonialModal

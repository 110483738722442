import Button from '../../../../components/Buttons/Button'

const TestimonialButton = ({ setOpen }) => {
  return (
    <Button color='success' onClick={() => setOpen(true)}>
      + Create testimonial
    </Button>
  )
}

export default TestimonialButton

import { useState } from 'react'
import axios from 'axios'
import { Grid, Avatar, Card, CardHeader, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import CustomModal from '../../../../components/Modal/CustomModal'
import styles from '../styles/schoolTeamGroupsStyle'

const useStyles = makeStyles(styles)

const SchoolTeamGroups = ({ teamGroups }) => {
	const classes = useStyles()
	const [infoModal, setInfoModal] = useState(false)
	const [group, setGroup] = useState(null)

	const handleStudentsInfo = (group) => {
		setGroup(group)
	}

	return (
		<>
			{teamGroups &&
				teamGroups.map((group, index) => (
					<Card key={group._id} className={classes.cardRoot}>
						<CardHeader
							action={
								<div className={classes.panelRoot}>
									<CustomDropdown
										left
										caret={false}
										buttonText={
											<div className={classes.btnwrapper}>
												<MoreVert />
											</div>
										}
										buttonProps={{
											className: classes.navLink + ' ' + classes.imageDropdownButton,
											color: 'transparent',
										}}
										dropdownList={[
											<div onClick={() => [handleStudentsInfo(group), setInfoModal(true)]}>View Members</div>,
											<div onClick={() => window.open(group.hangoutLink, '_blank', 'noopener,noreferrer')}>
												Go to Meeting Room
											</div>,
										]}
									/>
								</div>
							}
							titleTypographyProps={{ variant: 'h6' }}
							title={`${group.name}`}
						/>
					</Card>
				))}
			<CustomModal title={group && `Team ${group.name}`} open={infoModal} setOpen={setInfoModal} actions={false}>
				<Grid container>
					{group &&
						group.students &&
						group.students.map((student) => (
							<Grid item xs={12} key={student._id}>
								<Card className={classes.cardRoot}>
									<CardHeader
										className={classes.cardHeaderRoot}
										avatar={
											<Avatar aria-label='recipe' src={`${axios.defaults.baseURL}${student._id?.avatar}`}></Avatar>
										}
										title={student._id?.name || 'Deleted Student'}
										subheader={student._id?.username}
									/>
								</Card>
							</Grid>
						))}
				</Grid>
			</CustomModal>
		</>
	)
}

export default SchoolTeamGroups

import * as types from '../constants/talentsConstants'

export const talentListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_LIST_REQUEST:
			return { ...state, loadingTalentList: true }
		case types.TALENT_LIST_SUCCESS:
			return {
				loadingTalentList: false,
				thatsIt: action.payload.thatsIt === 0 ? true : false,
				talents: action.payload.data,
			}
		case types.TALENT_LIST_FAIL:
			return { loadingTalentList: false, errorTalents: action.payload }
		case types.TALENT_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const talentActiveListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_ACTIVE_LIST_REQUEST:
			return { ...state, loadingTalentActiveList: true }
		case types.TALENT_ACTIVE_LIST_SUCCESS:
			return {
				loadingTalentActiveList: false,
				thatsIt: action.payload.thatsIt === 0 ? true : false,
				talents: action.payload.data,
			}
		case types.TALENT_ACTIVE_LIST_FAIL:
			return { loadingTalentActiveList: false, errorTalents: action.payload }
		case types.TALENT_ACTIVE_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const talentInactiveListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_INACTIVE_LIST_REQUEST:
			return { loadingTalentInactiveList: true }
		case types.TALENT_INACTIVE_LIST_SUCCESS:
			return { loadingTalentInactiveList: false, talents: action.payload }
		case types.TALENT_INACTIVE_LIST_FAIL:
			return { loadingTalentInactiveList: false, errorTalents: action.payload }
		case types.TALENT_INACTIVE_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const talentDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_DELETE_REQUEST:
			return { loadingTalentDelete: true }
		case types.TALENT_DELETE_SUCCESS:
			return { loadingTalentDelete: false, success: action.payload }
		case types.TALENT_DELETE_FAIL:
			return { loadingTalentDelete: false, errorDeleteTalents: action.payload }
		case types.TALENT_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const talentCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_CREATE_REQUEST:
			return { loadingTalentCreate: true }
		case types.TALENT_CREATE_SUCCESS:
			return { loadingTalentCreate: false, success: action.payload }
		case types.TALENT_CREATE_FAIL:
			return { loadingTalentCreate: false, errorCreateTalents: action.payload }
		case types.TALENT_CREATE_RESET:
			return {}
		default:
			return state
	}
}

export const talentUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_UPDATE_REQUEST:
			return { loadingTalentUpdate: true }
		case types.TALENT_UPDATE_SUCCESS:
			return { loadingTalentUpdate: false, success: action.payload }
		case types.TALENT_UPDATE_FAIL:
			return { loadingTalentUpdate: false, errorUpdateTalents: action.payload }
		case types.TALENT_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const talentGetSinglePostReducer = (state = {}, action) => {
	switch (action.type) {
		case types.TALENT_SINGLE_REQUEST:
			return { loadingTalentSingle: true }
		case types.TALENT_SINGLE_SUCCESS:
			return { loadingTalentSingle: false, talent: action.payload, success: true }
		case types.TALENT_SINGLE_FAIL:
			return { loadingTalentSingle: false, errorSingleTalents: action.payload }
		case types.TALENT_SINGLE_RESET:
			return {}
		default:
			return state
	}
}

const styles = {
    sections: {
        maxWidth: '1440px',
        margin: 'auto',
    },
    sectionRoot: {
        padding: 60,
        '& ol': {
            padding: 0,
        },
    },
    sectionRootReverse: {
        padding: 60,
    },
    sectionRootWReverse: {
        padding: 60,
    },
    imgRoot: {
        position: 'relative',
        '& img': {
            borderRadius: '5px',
            boxShadow:
                '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        },
    },
    methodologyImg: {
        textAlign: 'center',
        padding: '20px',
    },
    onTopCardRoot: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    onTopCardRight: {
        position: 'absolute',
        left: '-50px',
        marginBottom: 0,
        boxShadow:
            '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    },
    descRoot: {
        padding: 40,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            padding: '10px 0',
            margin: 0,
        },
    },
    descMethodology: {
        padding: '10px 40px',
        '& h2': {
            textAlign: 'center',
            color: '#1f7a2f',
        },
        '& h3': {
            color: '#1f7a2f',
            cursor: 'pointer',
        },
        '& svg': {
            fontSize: '38px',
            marginRight: '5px',
        },
    },
    imagineIcon: {
        color: 'yellow',
    },
    actIcon: {
        color: 'green',
    },
    descActions: {
        width: '100%',
        textAlign: 'right',
    },
    nbtcIcon: {
        fontSize: 40,
        color: '#91b993',
    },
    btcIcon: {
        fontSize: 60,
        color: '#1ad424',
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        '& span': {
            fontSize: '20px',
        },
        '& svg': {
            fontSize: '60px',
            color: '#1f7a2e',
        },
    },
    parallaxBTC: {
        backgroundImage: 'url(/assets/img/public-site/parallax-be-the-change.jpg)',
        padding: '20px',
        minHeight: '500px',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    cardItem: {
        margin: 'auto',
        '& h3': {
            color: '#3ba04c',
            fontSize: '19px',
        },
    },

    onTopCardLeft: {
        position: 'absolute',
        right: '-10px',
        zIndex: 100,
        boxShadow:
            '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    },
    onTopCardLeftTwo: {
        right: 0,
        zIndex: 100,
        boxShadow:
            '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    },

    '@media screen and (max-width: 1200px)': {
        descRoot: {
            padding: 0,
        },
    },
    '@media screen and (max-width: 980px)': {
        onTopCardRight: {
            marginTop: 30,
            position: 'inherit',
            left: 0,
        },
        onTopCardLeft: {
            marginTop: 30,
            position: 'inherit',
            right: 0,
        },
    },
    '@media screen and (max-width: 580px)': {
        sectionRoot: {
            padding: '20px 10px',
        },
        sectionRootReverse: {
            padding: '20px 10px',
            flexDirection: 'column-reverse',
        },
        descRoot: {
            padding: '40px 30px',
        },
        descMethodology: {
            padding: '10px',
        },
    },
}
export default styles

import { Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/videoPFSongStyles'

const useStyles = makeStyles(styles)

const VideoPFSong = () => {
	const classes = useStyles()

	return (
		<Grid container>
			<Grid item xs={12} sm={9} className={classes.videoRoot}>
				<video poster='/assets/img/public-site/portada-vdo.png' controls>
					<source src={'/assets/video/video-portada-2024-pf.mp4'} type='video/mp4' />
					Your browser does not support the video tag.
				</video>
			</Grid>
		</Grid>
	)
}

export default VideoPFSong

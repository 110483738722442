const styles = {
  sections: {
    maxWidth: '1440px',
    margin: 'auto',
  },
  sectionRoot: {
    padding: 60,
  },

  imgRoot: {
    position: 'relative',
    '& img': {
      borderRadius: '5px',
      boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    },
  },
  onTopCardRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  onTopCardRight: {
    position: 'absolute',
    left: '-50px',
    boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
  },
  descRoot: {
    padding: 40,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      padding: '10px 0',
      margin: 0,
    },
  },
  iCanIcon: {
    fontSize: 60,
    color: '#8080ff',
  },
  descActions: {
    width: '100%',
    textAlign: 'right',
  },
  '@media screen and (max-width: 980px)': {
    onTopCardRight: {
      position: 'inherit',
      left: 0,
      marginTop: 5,
    },
  },
  '@media screen and (max-width: 580px)': {
    sectionRoot: {
      padding: '20px 10px',
    },
  },
}

export default styles

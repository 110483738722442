import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, TextField, Select, Grid, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import { getTestimonials, registerTestimonial } from '../../../../redux/actions/testimonialActions'
import {
	TESTIMONIAL_DELETE_RESET,
	TESTIMONIAL_REGISTER_RESET,
	TESTIMONIAL_UPDATE_RESET,
} from '../../../../redux/constants/testimonialConstants'
import { TEACHERS_LIST_RESET } from '../../../../redux/constants/teacherConstants'
import styles from '../styles/testimonialRegisterFormModalStyles'

const useStyles = makeStyles(styles)

const TestimonialRegisterFormModal = ({
	setTestimonialArray,
	setTestimonialDetails,
	keyword,
	setOpen,
	setStart,
	setHasMore,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [testimonialText, setTestimonialText] = useState('')
	const [initalHasMore, setInitialHasMore] = useState(true)
	const [teacher, setTeacher] = useState('')
	const [teacherName, setTeacherName] = useState('')
	const [country, setCountry] = useState('')
	const [center, setCenter] = useState('')
	const [matchKeyword, setMatchKeyword] = useState('')
	const [count] = useState(15)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { teachers } = useSelector((state) => state.teacherALlList)
	const { testimonials } = useSelector((state) => state.testimonialList)
	const { successTestimonialDelete } = useSelector((state) => state.testimonialDelete)
	const { successTestimonialUpdate } = useSelector((state) => state.testimonialUpdate)
	const { successTestimonialRegister } = useSelector((state) => state.testimonialRegister)

	useEffect(() => {
		if (teacher !== '') {
			const teacherInfo = teachers.find((item) => item._id === teacher)
			if (teacherInfo) {
				setTeacherName(teacherInfo.name)
				setCenter(teacherInfo.center.name)
				setCountry(teacherInfo.country._id)
			}
		}
	}, [teacher])

	useEffect(() => {
		if (userInfo) {
			if (successTestimonialDelete || successTestimonialRegister || successTestimonialUpdate) {
				if (successTestimonialRegister) {
					dispatch({ type: TESTIMONIAL_REGISTER_RESET })
					dispatch({ type: TEACHERS_LIST_RESET })
					setTestimonialDetails(null)
					setTeacher('')
					setTestimonialText('')
					setOpen(false)
				} else if (successTestimonialDelete) {
					dispatch({ type: TESTIMONIAL_DELETE_RESET })
					setTestimonialDetails(null)
				} else if (successTestimonialUpdate) {
					dispatch({ type: TESTIMONIAL_UPDATE_RESET })
				}
				setTestimonialArray([])
				setStart(1)
				setHasMore(true)
				setInitialHasMore(true)
				return true
			} else {
				if (keyword !== undefined) {
					if (keyword !== matchKeyword) {
						setTestimonialArray([])
						setInitialHasMore(true)
						setMatchKeyword(keyword)
						setStart(1)
					} else {
						if (initalHasMore) {
							setInitialHasMore(false)
							dispatch(getTestimonials(keyword, count, 0))
						} else if (testimonials && testimonials.length > 0) {
							setTestimonialArray((prev) => [...prev, ...testimonials])
						}
					}
				} else {
					if (initalHasMore) {
						dispatch(getTestimonials(keyword, count, 0))
						setInitialHasMore(false)
					} else if (testimonials && testimonials.length > 0) {
						setTestimonialArray((prev) => [...prev, ...testimonials])
					}
				}
			}
		}
	}, [
		successTestimonialRegister,
		successTestimonialDelete,
		successTestimonialUpdate,
		dispatch,
		count,
		initalHasMore,
		keyword,
		testimonials,
		matchKeyword,
		userInfo,
		setHasMore,
		setOpen,
		setStart,
		setTestimonialArray,
		setTestimonialDetails,
	])

	const submitTestimonial = () => {
		if (teacher !== '' || teacher !== undefined) {
			dispatch(registerTestimonial({ teacher, testimonial: testimonialText, teacherName, country, center }))
		}
	}
	return (
		<Grid container spacing={2}>
			<Grid item md={12} sm={12} xs={12}>
				{teachers && (
					<FormControl className={classes.formControl}>
						<InputLabel id='ages-range' className={classes.selectLabel}>
							Teacher
						</InputLabel>
						<Select
							labelId='ages-range'
							id='agesrange'
							value={teacher}
							onChange={(e) => setTeacher(e.target.value)}
							inputProps={{ required: true }}
							className={classes.selectInput}
						>
							<MenuItem value='' disabled>
								Select a Teacher
							</MenuItem>

							{teachers.map((teacher) => (
								<MenuItem key={teacher._id} value={teacher._id}>
									{teacher.name} {teacher.center && `- ${teacher.center.name}`}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<TextField
					className={classes.areaField}
					id='outlined-textarea'
					label='Testimonial'
					placeholder='Testimonial'
					multiline
					value={testimonialText}
					onChange={(e) => setTestimonialText(e.target.value)}
				/>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<Button type='button' color='success' block onClick={submitTestimonial}>
					SUBMIT
				</Button>
			</Grid>
		</Grid>
	)
}

export default TestimonialRegisterFormModal

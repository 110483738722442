const styles = {
    cardRoot: {
        position: 'relative',
        paddingTop: '60px',
        margin: '110px auto',
        maxWidth: '300px',
        overflow: 'unset',
        display: 'flex',
        justifyContent: 'center',
    },
    imgMask: {
        position: 'absolute',
        top: '-100px',
        width: '80%',
        height: '150px',
        borderRadius: '4px',
        boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        overflow: 'hidden',
        zIndex: '1',
        '& img': {
            width: '100%',
        },
    },
}
export default styles

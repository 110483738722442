import { format } from 'date-fns'

const TeamsManageHeader = ({ team, totalStudents }) => {
  return (
    <>
      <h1>{team.panelName}</h1>
      <h2>
        {team.topic.name} - Start Date {format(new Date(team.partnersStartDate), 'dd-MM-yyyy')} - End Date{' '}
        {format(new Date(team.partnersEndDate), 'dd-MM-yyyy')}
      </h2>
      {totalStudents !== null && <p>The total number of students between the two schools is {totalStudents}.</p>}
    </>
  )
}

export default TeamsManageHeader

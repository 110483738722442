import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import classNames from 'classnames'
import Masony from 'react-masonry-component'
import { Grid, makeStyles } from '@material-ui/core'
import { masonryOptions } from '../config/mansory-config'
import { getImages } from '../../../../redux/actions/galleryActions'
import styles from '../styles/galleryMasonryStyles'
import '../../../../assets/css/masonry-style.css'

const useStyles = makeStyles(styles)

const GalleryMasonry = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { t } = useTranslation('global')

	const [imagesData, setImagesData] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [imageModal, setImageModal] = useState({
		showModal: false,
		modalSrc: null,
		imageIndex: null,
		currentSectionLength: null,
	})
	const [imageFilter, setImageFilter] = useState('')

	const { images } = useSelector((state) => state.imageGalleryList)

	useEffect(() => {
		dispatch(getImages())
	}, [dispatch])
	useEffect(() => {
		if (images) {
			setImagesData([...images])
		}
	}, [images])

	const onSet = (type) => {
		if (type === 'prev') {
			if (imageModal.imageIndex !== 0) {
				let data = imagesData[imageModal.imageIndex - 1]

				setImageModal((modal) => ({
					...modal,
					imageIndex: imageModal.imageIndex - 1,
					modalSrc: `${axios.defaults.baseURL}/${data.image}`,
				}))
			} else {
				setIsOpen(!isOpen)
			}
		} else if (type === 'next') {
			if (imageModal.imageIndex + 1 >= imagesData.length) {
				setIsOpen(!isOpen)
			} else {
				let data = imagesData[imageModal.imageIndex + 1]

				setImageModal((modal) => ({
					...modal,
					imageIndex: imageModal.imageIndex + 1,
					modalSrc: `${axios.defaults.baseURL}/${data.image}`,
				}))
			}
		}
	}
	const toggleIsOpen = (e) => {
		if (e.target.src) setImageFilter(e.target.src)
		setIsOpen(!isOpen)
	}

	return (
		<>
			{images && images.length > 0 && (
				<>
					<Grid container>
						<Grid item md={12} sm={12} xs={12}>
							<h4 className={classes.galleryTitle}>{t('layout.t_home_gallery')}</h4>
						</Grid>
					</Grid>
					<div className={classes.root}>
						<Masony
							className={'photo-list'}
							elementType={'ul'}
							options={masonryOptions}
							disableImagesLoaded={false}
							updateOnEachImageLoad={false}
						>
							{images &&
								images.map((photo, index) => (
									<li key={photo._id} className={'photo-item'} onClick={toggleIsOpen}>
										<img
											src={`${axios.defaults.baseURL}/${photo.image}`}
											alt=''
											onClick={() => {
												setImageModal({
													showModal: true,
													modalSrc: `${axios.defaults.baseURL}/${photo.image}`,
													imageIndex: index,
													currentSectionLength: imagesData.length,
												})
											}}
										/>
									</li>
								))}
						</Masony>
						{isOpen && imageFilter ? (
							<div className={classNames(classes.lightBooxRoot, 'wow animate__animated animate__fadeIn')}>
								<img
									onClick={toggleIsOpen}
									className={classes.imageFilter}
									src={imageModal.modalSrc}
									alt={imageFilter}
								/>
								<span className='prev' onClick={() => onSet('prev')}>
									&#10094;
								</span>
								<span className='next' onClick={() => onSet('next')}>
									&#10095;
								</span>
							</div>
						) : null}
					</div>
				</>
			)}
		</>
	)
}

export default GalleryMasonry

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Link, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Card from '../../../components/Card/Card'
import CarBody from '../../../components/Card/CardBody'
import Button from '../../../components/Buttons/Button'
import CustomInput from '../../../components/CustomInput/CustomInput'
import { getCurrentWritingContest, registerWritingCParticipation } from '../../../redux/actions/writingContestActions'
import { getCenterById } from '../../../redux/actions/centerActions'
import styles from './styles/writingContestScreenStyles'

const useStyles = makeStyles(styles)

const WritingContestScreen = ({ history }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [participationText, setParticipationText] = useState('')

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingCenterDetails, center } = useSelector((state) => state.centerDetails)
	const { loadingWritingCCurrent, writingContest } = useSelector((state) => state.writingCCurrent)
	const { loadingWritingParticipationRegister, successWritingParticipationRegister } = useSelector(
		(state) => state.writingParticipationRegister,
	)

	useEffect(() => {
		if (successWritingParticipationRegister) {
			setParticipationText('')
			handleToggle(21)
		}
	}, [successWritingParticipationRegister])

	useEffect(() => {
		if (!userInfo) {
			history.push('/auth/login')
		} else {
			dispatch(getCenterById(userInfo.center))
		}
	}, [dispatch, userInfo, history])

	useEffect(() => {
		dispatch(getCurrentWritingContest())
	}, [dispatch])

	const handleToggle = (e) => {}

	const submitParticipationHandler = (e) => {
		e.preventDefault()
		const createParticipation = {
			user: {
				_id: userInfo._id,
				center: userInfo.center,
			},
			participationText,
		}
		dispatch(registerWritingCParticipation(createParticipation))
	}

	return (
		<>
			<Meta title={'Writing Contest Participate - Planet Fraternity'} />
			<div className={classes.root}>
				<Card className={classes.cardRoot}>
					<CarBody>
						{loadingWritingCCurrent
							? 'Loading Writing Contest...'
							: writingContest && (
									<form onSubmit={submitParticipationHandler}>
										<Grid container spacing={3}>
											<Grid item xs={12} className={classes.writingHeader}>
												<h1>{writingContest.title}</h1>
												<h2>{writingContest.question}</h2>
											</Grid>
											<Grid item xs={12}>
												{userInfo && (
													<>
														<h3>Name: {userInfo.name}</h3>
														<h3>Grade: {userInfo.group.name}</h3>
														<h3>School: {loadingCenterDetails ? 'Loading Center info...' : center.name}</h3>
													</>
												)}
											</Grid>
											<Grid item xs={12}>
												<CustomInput
													labelText='Participation'
													id='participation'
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														type: 'textarea',
														rows: '10',
														multiline: true,
														value: participationText,
														placeholder: 'Write here your participation',
														onChange: (e) => setParticipationText(e.target.value),
														required: true,
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															tabIndex={-1}
															onClick={() => handleToggle(21)}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot,
															}}
															inputProps={{
																required: true,
															}}
														/>
													}
													classes={{ label: classes.label, root: classes.labelRoot }}
												/>
												<span>
													<Button link className={classes.btnLink}>
														<Link href={'/terms-conditions'} target='_blank' rel='noopener noreferrer' underline='none'>
															<span>I have read the conditions of the competitions*</span>
														</Link>
													</Button>
												</span>
											</Grid>
											<Grid item xs={12}>
												<Button type='submit' color='primary' block disabled={false}>
													{loadingWritingParticipationRegister ? 'Submiting your participation' : 'Send Participation'}
												</Button>
											</Grid>
										</Grid>
									</form>
							  )}
					</CarBody>
				</Card>
			</div>
		</>
	)
}

export default WritingContestScreen

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../../components/Headers/Header'
import HeaderLinks from '../../components/Headers/HeaderLinks'
import Footer from '../../components/Footers/Footer'
import AcceptCookies from '../../components/AcceptCookies/AcceptCookies'
import { refreshToken } from '../../redux/actions/userActions'

const WebSiteLayout = ({ children }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { userInfo } = useSelector((state) => state.userLogin)

	const [barColor, setBarColor] = useState('transparent')
	const [acceptCookies, setAcceptCookies] = useState(false)

	useEffect(() => {
		if (userInfo) {
			dispatch(refreshToken())
		}
	}, [dispatch, userInfo])

	useEffect(() => {
		if (window.location.pathname === '/final-projects') {
			if (userInfo) {
				history.push('/final-projects')
			} else {
				history.push('/auth/login')
			}
		}
	}, [userInfo, history])

	useEffect(() => {
		const currPath = window.location.pathname
		setBarColor(
			currPath === '/' || currPath === '/how-it-works' || currPath === '/the-magazine' ? 'transparent' : 'white',
		)
	}, [])

	useEffect(() => {
		if (!acceptCookies) {
			const checkCookie = () => {
				let cookies = getCookie('cookie-concent')
				if (cookies !== '') {
					return true
				} else {
					return false
				}
			}
			setAcceptCookies(checkCookie())
		}
	}, [acceptCookies])

	const getCookie = (cname) => {
		let name = cname + '='
		let ca = document.cookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') {
				c = c.substring(1)
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length)
			}
		}
		return ''
	}

	return (
		<>
			<Header
				brand='Planet Fraternity'
				rightLinks={<HeaderLinks />}
				fixed
				color={barColor}
				changeColorOnScroll={{
					height: 100,
					color: 'white',
				}}
			/>
			<main>{children}</main>
			<Footer />
			{!acceptCookies && <AcceptCookies setAcceptCookies={setAcceptCookies} />}
		</>
	)
}

export default WebSiteLayout

import { combineReducers } from 'redux'
import {
	userLoginReducer,
	userRegisterReducer,
	userDetailsReducer,
	userDeleteReducer,
	userUpdateReducer,
	userListReducer,
	refreshTokenReducer,
} from './userReducers'
import {
	logRegisterReducer,
	logListReducer,
	logDetailsReducer,
	logDeleteReducer,
	logDeleteManyReducer,
	logListTeamReducer,
} from './logReducers'
import { sendEMailReducer } from './sendEMailReducers'
import {
	teacherAllListReducer,
	teacherDeleteReducer,
	teacherDetailsReducer,
	teacherGroupsReducer,
	teacherListReducer,
	teachersByCenterReducer,
	teachersByEmailReducer,
} from './teacherReducers'
import {
	studentRegisterReducer,
	studentListReducer,
	studentRegisterMultipleReducer,
	studentsByCenterReducer,
	studentsByGroupReducer,
	studentUpdateReducer,
	studentDeleteReducer,
} from './studentReducers'
import {
	centerRegisterReducer,
	centerGroupsListReducer,
	centerActivateReducer,
	centerListReducer,
	centerNotPartneredReducer,
	centerPartnersReducer,
	centerDetailsReducer,
	centerUpdateReducer,
	centerDeleteReducer,
	centerSendFriendReqReducer,
	centersByCountryReducer,
} from './centerReducers'
import {
	groupRegisterReducer,
	groupListByCenterReducer,
	groupDetailsReducer,
	groupUpdateReducer,
	groupDeleteReducer,
	groupTeacherDeleteReducer,
} from './groupReducers'
import { countryListReducer, countryAllReducer, countryDetailsReducer } from './countryReducers'

import {
	teamRegisterReducer,
	teamListReducer,
	teamDetailsReducer,
	teamMyDetailsReducer,
	teamUpdateReducer,
	teamDeleteReducer,
	teamFinalProjectReducer,
	teamDeleteFinalProjectReducer,
	teamUpdateFinalProjectReducer,
	teamsFinalProjectsReducer,
	teamUpdateFinalProjectVoteReducer,
} from './teamReducers'
import {
	topicRegisterReducer,
	topicListReducer,
	topicDetailsReducer,
	topicUpdateReducer,
	topicDeleteReducer,
} from './topicReducers'
import {
	pricePerCountryDetailsReducer,
	pricePerCountryListReducer,
	pricePerCountryUpdateReducer,
} from './pricePerCountryReducers'
import {
	teamGroupRegisterReducer,
	teamGroupAutoRegisterReducer,
	teamGroupListReducer,
	teamGroupDetailsReducer,
	teamGroupMyDetailsReducer,
	teamGroupUpdateReducer,
	teamGroupDeleteReducer,
	teamsByAgeReducer,
	teamsByAgeNinTopicReducer,
	teamsByAgePrevReducer,
	teamsGroupsAutomationReducer,
	teamsActuallyReducer,
	teamsByAgeStudentsLittleMergeReducer,
} from './teamGroupReducers'
import {
	writingCRegisterReducer,
	writingCListReducer,
	writingCCurrentReducer,
	writingCDetailsReducer,
	writingCDeleteReducer,
	writingCUpdateReducer,
	writingParticipationRegisterReducer,
} from './writingContestReducers'
import {
	surveyDeleteReducer,
	surveyDetailsReducer,
	surveyListReducer,
	surveyRegisterReducer,
	surveyUpdateReducer,
	surveyAnswerReducer,
	answerReducer,
	answerSurveyReducer,
} from './surveyReducer'
import { activeSurveyReducer } from './activeSurveyReducers'
import {
	activeTestimonialsReducer,
	testimonialDeleteReducer,
	testimonialListReducer,
	testimonialRegisterReducer,
	testimonialTeacherReducer,
	testimonialUpdateReducer,
} from './testimonialReducers'
import { informationDeleteReducer, informationListReducer, informationRegisterReducer } from './informationReducers'
import {
	activeMiniBlogListReducer,
	miniBlogDeleteReducer,
	miniBlogListReducer,
	miniBlogRegisterReducer,
	miniBlogUpdateReducer,
} from './miniBlogReducers'
import {
	imageGalleryDeleteReducer,
	imageGalleryRegisterReducer,
	imageGalleryUpdateReducer,
	imagesListReducer,
} from './galleryReducers'
import {
	countryPostListReducer,
	countryPostActiveListReducer,
	countryPostInactiveListReducer,
	countryPostUpdateReducer,
	countryPostCreateReducer,
	countryPostDeleteReducer,
	countryPostVerifyPostedReducer,
} from './countryPostReducers'
import {
	talentActiveListReducer,
	talentCreateReducer,
	talentDeleteReducer,
	talentGetSinglePostReducer,
	talentInactiveListReducer,
	talentListReducer,
	talentUpdateReducer,
} from './talentsReducers'

export default combineReducers({
	activeSurvey: activeSurveyReducer,
	activeTestimonials: activeTestimonialsReducer,
	answers: answerReducer,
	answerSurvey: answerSurveyReducer,
	centerActivate: centerActivateReducer,
	centerDelete: centerDeleteReducer,
	centerDetails: centerDetailsReducer,
	centerGroupsList: centerGroupsListReducer,
	centerList: centerListReducer,
	centerNotPartnered: centerNotPartneredReducer,
	centerPartners: centerPartnersReducer,
	centerRegister: centerRegisterReducer,
	centersByCountry: centersByCountryReducer,
	centerSendFriendReq: centerSendFriendReqReducer,
	centerUpdate: centerUpdateReducer,
	countryAll: countryAllReducer,
	countryDetails: countryDetailsReducer,
	countryList: countryListReducer,
	countryPostActiveList: countryPostActiveListReducer,
	countryPostCreate: countryPostCreateReducer,
	countryPostDelete: countryPostDeleteReducer,
	countryPostInactiveList: countryPostInactiveListReducer,
	countryPostList: countryPostListReducer,
	countryPostUpdate: countryPostUpdateReducer,
	countryPostVerifyPosted: countryPostVerifyPostedReducer,
	groupDelete: groupDeleteReducer,
	groupDetails: groupDetailsReducer,
	groupListByCenter: groupListByCenterReducer,
	groupRegister: groupRegisterReducer,
	groupTeacherDelete: groupTeacherDeleteReducer,
	groupUpdate: groupUpdateReducer,
	imageGalleryDelete: imageGalleryDeleteReducer,
	imageGalleryList: imagesListReducer,
	imageGalleryRegister: imageGalleryRegisterReducer,
	imageGalleryUpdate: imageGalleryUpdateReducer,
	informationDelete: informationDeleteReducer,
	informationList: informationListReducer,
	informationRegister: informationRegisterReducer,
	logDelete: logDeleteReducer,
	logDeleteMany: logDeleteManyReducer,
	logDetails: logDetailsReducer,
	logList: logListReducer,
	logListTeam: logListTeamReducer,
	logRegister: logRegisterReducer,
	miniBlogDelete: miniBlogDeleteReducer,
	miniBlogList: miniBlogListReducer,
	activeMiniBlogList: activeMiniBlogListReducer,
	miniBlogRegister: miniBlogRegisterReducer,
	miniBlogUpdate: miniBlogUpdateReducer,
	pricePerCountryDetails: pricePerCountryDetailsReducer,
	pricePerCountryList: pricePerCountryListReducer,
	pricePerCountryUpdate: pricePerCountryUpdateReducer,
	refreshToken: refreshTokenReducer,
	sendEMail: sendEMailReducer,
	studentDelete: studentDeleteReducer,
	studentList: studentListReducer,
	studentRegister: studentRegisterReducer,
	studentRegisterMultiple: studentRegisterMultipleReducer,
	studentsByCenter: studentsByCenterReducer,
	studentsByGroup: studentsByGroupReducer,
	studentUpdate: studentUpdateReducer,
	surveyAnswer: surveyAnswerReducer,
	surveyDelete: surveyDeleteReducer,
	surveyDetails: surveyDetailsReducer,
	surveyList: surveyListReducer,
	surveyRegister: surveyRegisterReducer,
	surveyUpdate: surveyUpdateReducer,
	talentActiveList: talentActiveListReducer,
	talentCreate: talentCreateReducer,
	talentDelete: talentDeleteReducer,
	talentInactiveList: talentInactiveListReducer,
	talentList: talentListReducer,
	talentSingle: talentGetSinglePostReducer,
	talentUpdate: talentUpdateReducer,
	teacherALlList: teacherAllListReducer,
	teacherDelete: teacherDeleteReducer,
	teacherDetails: teacherDetailsReducer,
	teacherGroups: teacherGroupsReducer,
	teacherList: teacherListReducer,
	teachersByCenter: teachersByCenterReducer,
	teachersByEmail: teachersByEmailReducer,
	teamDelete: teamDeleteReducer,
	teamDeleteProject: teamDeleteFinalProjectReducer,
	teamDetails: teamDetailsReducer,
	teamFinalProjects: teamsFinalProjectsReducer,
	teamGroupAutoRegister: teamGroupAutoRegisterReducer,
	teamGroupDelete: teamGroupDeleteReducer,
	teamGroupDetails: teamGroupDetailsReducer,
	teamGroupList: teamGroupListReducer,
	teamGroupMyDetails: teamGroupMyDetailsReducer,
	teamGroupRegister: teamGroupRegisterReducer,
	teamGroupUpdate: teamGroupUpdateReducer,
	teamList: teamListReducer,
	teamMyDetails: teamMyDetailsReducer,
	teamProject: teamFinalProjectReducer,
	teamRegister: teamRegisterReducer,
	teamsActually: teamsActuallyReducer,
	teamsByAge: teamsByAgeReducer,
	teamsByAgeNinTopic: teamsByAgeNinTopicReducer,
	teamsByAgeLittleMerge: teamsByAgeStudentsLittleMergeReducer,
	teamsByAgePrev: teamsByAgePrevReducer,
	teamsGroupsAutomation: teamsGroupsAutomationReducer,
	teamUpdate: teamUpdateReducer,
	teamUpdateProject: teamUpdateFinalProjectReducer,
	teamUpdateVoteProject: teamUpdateFinalProjectVoteReducer,
	testimonialDelete: testimonialDeleteReducer,
	testimonialList: testimonialListReducer,
	testimonialRegister: testimonialRegisterReducer,
	testimonialTeacherList: testimonialTeacherReducer,
	testimonialUpdate: testimonialUpdateReducer,
	topicDelete: topicDeleteReducer,
	topicDetails: topicDetailsReducer,
	topicList: topicListReducer,
	topicRegister: topicRegisterReducer,
	topicUpdate: topicUpdateReducer,
	userDelete: userDeleteReducer,
	userDetails: userDetailsReducer,
	userList: userListReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userUpdate: userUpdateReducer,
	writingCCurrent: writingCCurrentReducer,
	writingCDelete: writingCDeleteReducer,
	writingCDetails: writingCDetailsReducer,
	writingCList: writingCListReducer,
	writingCRegister: writingCRegisterReducer,
	writingCUpdate: writingCUpdateReducer,
	writingParticipationRegister: writingParticipationRegisterReducer,
})

const ShowTeachersInfo = ({ panel, centerId }) => {
  const groups = panel.groups.filter((group) => group?._id?.center === centerId)
  const teachers = groups?.map((group) => group._id?.teachers)[0]

  return (
    <div>
      {teachers?.map((teacher, key) => (
        <div key={key}>
          <hr style={{ margin: '10px 0' }} />
          <p>
            Name · <strong>{teacher._id.name}</strong>
          </p>
          <p>
            Email · <strong>{teacher._id.email}</strong>
          </p>
          {teacher._id.phone && (
            <p>
              Phone · <strong>{teacher._id.phone}</strong>
            </p>
          )}
        </div>
      ))}
    </div>
  )
}

export default ShowTeachersInfo

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Parallax from '../../../components/Parallax/Parallax'
import UserCard from './components/UserCard'
import NotificationCard from './components/NotificationCard'
import { getTestimonialByTeacher } from '../../../redux/actions/testimonialActions'
import { activeSurvey } from '../../../redux/actions/activeSurveyActions'
import { refreshToken } from '../../../redux/actions/userActions'
import { CENTER_DETAILS_RESET } from '../../../redux/constants/centerConstants'
import { TEACHERS_BY_CENTER_RESET } from '../../../redux/constants/teacherConstants'
import { STUDENTS_BY_CENTER_RESET } from '../../../redux/constants/studentConstants'
import { GROUP_LIST_BY_CENTER_RESET } from '../../../redux/constants/groupConstants'
import styles from './styles/profileScreenStyles'
import { TESTIMONIAL_REGISTER_RESET } from '../../../redux/constants/testimonialConstants'
import { ACTIVE_SURVEY_RESET } from '../../../redux/constants/activeSurveyConstants'

const useStyles = makeStyles(styles)

const ProfileScreen = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [isTeacher, setIsTeacher] = useState(false)
  const [isTestimonial, setIsTestimonial] = useState(false)
  const [isActiveSurveys, setIsActiveSurveys] = useState(false)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { testimonial } = useSelector((state) => state.testimonialTeacherList)
  const { activeSurveys, successActiveSurvey } = useSelector((state) => state.activeSurvey)
  const { successTestimonialRegister } = useSelector((state) => state.testimonialRegister)

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch])

  useEffect(() => {
    if (userInfo) {
      dispatch(getTestimonialByTeacher(userInfo._id))
      dispatch(activeSurvey())
    }
  }, [dispatch, userInfo])

  useEffect(() => {
    if (userInfo) {
      const isTestimonial = testimonial === undefined ? true : false
      const isActiveSurveys = activeSurveys && activeSurveys.length > 0
      const isTeacher = userInfo.role === 'teacher' && !(userInfo.isAdmin && userInfo.isSuper)
      setIsTestimonial(isTestimonial)
      setIsActiveSurveys(isActiveSurveys)
      setIsTeacher(isTeacher)
    }
  }, [userInfo, activeSurveys, testimonial])

  useEffect(() => {
    if (userInfo) {
      if (successTestimonialRegister) {
        dispatch(getTestimonialByTeacher(userInfo._id))
      }
    }
  }, [userInfo, dispatch, successTestimonialRegister])

  useEffect(() => {
    if (!userInfo) {
      history.push('/auth/login')
    }
    return () => {
      dispatch({ type: CENTER_DETAILS_RESET })
      dispatch({ type: TEACHERS_BY_CENTER_RESET })
      dispatch({ type: STUDENTS_BY_CENTER_RESET })
      dispatch({ type: GROUP_LIST_BY_CENTER_RESET })
      dispatch({ type: TESTIMONIAL_REGISTER_RESET })
      dispatch({ type: ACTIVE_SURVEY_RESET })
    }
  }, [userInfo, history, dispatch])

  return (
    <>
      <Meta title={'Profile - Planet Fraternity'} />
      <Parallax image={'/assets/img/education-01.png'} xsmall />
      <Grid container spacing={4}>
        <Grid
          item
          xs={isTeacher && (isTestimonial || isActiveSurveys) ? 10 : 11}
          sm={isTeacher && (isTestimonial || isActiveSurveys) ? 11 : 9}
          md={isTeacher && (isTestimonial || isActiveSurveys) ? 7 : 9}
          className={classes.userCardRoot}
        >
          {userInfo && <UserCard user={userInfo} />}
        </Grid>
        <>
          {userInfo && successActiveSurvey && isTeacher && (isTestimonial || isActiveSurveys) && (
            <Grid item xs={10} sm={11} md={4} className={classes.userCardRoot}>
              <NotificationCard
                user={userInfo}
                history={history}
                activeSurveys={activeSurveys}
                isActiveSurveys={isActiveSurveys}
                testimonial={testimonial}
                isTestimonial={isTestimonial}
              />
            </Grid>
          )}
        </>
      </Grid>
    </>
  )
}

export default ProfileScreen

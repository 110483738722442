const styles = {
    root: {
        '& h4': {
            textAlign: 'center'
        },
    },
    panelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img': {
            height: '50px',
            width: '50px',
            marginTop: '10px'
        }
    },
    btnwrapper: {
        padding: 10,
        '& svg': {
            margin: 0,
        },
    },
    navLink: {
        color: '#757575 !important',
        borderRadius: '33px',
        lineHeight: '20px',
        textDecoration: 'none',
        margin: '10px 10px 0px 10px',
        display: 'inline-flex',
        '& svg': {
            width: 22,
            height: 22,
        },
        '&:hover,&:focus': {
            color: 'inherit',
            background: 'rgba(200, 200, 200, 0.2)',
        },
    },
    imageDropdownButton: {
        padding: '0px',
        borderRadius: '50%',
        backgroundColor: '#f3f3f3',
    },
    sectionResources: {
        '& h2': {
            textAlign: 'center',
            fontSize: '16px'
        },
        '& video': {
            maxWidth: '100%',
        }
    },
    lightBooxRoot: {
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.2)',
        cursor: 'pointer',
        zIndex: '1',
    },
    sectionImages: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
        '& img': {
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            cursor: 'pointer',
            maxWidth: '90%',
            maxHeight: '90%',
        }
    },
    titleImages: {
        margin: '30px 0px'
    }
}

export default styles
import { useLocation } from 'react-router'
import { Grid, makeStyles } from '@material-ui/core'
import CardMedia from '../CardMedia/CardMedia'
import { useTranslation } from 'react-i18next'
import styles from './styles/methodologyPhasesStyles'

const useStyles = makeStyles(styles)

const MethodologyPhases = () => {
    const location = useLocation()
    const classes = useStyles()
    const { t } = useTranslation('global')

    const phases = [
        {
            srcImg: '/assets/img/public-site/img-feel.png',
            title: '1. Feel',
            text: t('layout.t_feel-desc'),
            link: '/feel',
        },
        {
            srcImg: '/assets/img/public-site/img-imagine.png',
            title: '2. Imagine',
            text: t('layout.t_imagine-desc'),
            link: '/imagine',
        },
        {
            srcImg: '/assets/img/public-site/img-act.png',
            title: '3. Act',
            text: t('layout.t_act-desc'),
            link: '/act',
        },
        {
            srcImg: '/assets/img/public-site/img-share.png',
            title: '4. Share',
            text: t('layout.t_share-desc'),
            link: '/share',
        },
    ]

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {phases.map(
                    (phase, index) =>
                        location.pathname !== phase.link && (
                            <Grid item xs={12} md={4} key={index}>
                                <CardMedia
                                    title={phase.title}
                                    srcImg={phase.srcImg}
                                    altImg={phase.title}
                                    cardLink={phase.link}
                                    btnText={t('layout.buttons.t_more-info')}
                                >
                                    <p>{phase.text}</p>
                                </CardMedia>
                            </Grid>
                        )
                )}
            </Grid>
        </div>
    )
}

export default MethodologyPhases

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import format from 'date-fns/format'
import { Grid, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Loader from '../../../../components/Loader/Loader'
import Button from '../../../../components/Buttons/Button'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import { teamPanelUpdateInfo, deleteTeamPanel } from '../../../../redux/actions/teamsActions'
import styles from '../styles/teamCardFormStyles'

const useStyles = makeStyles(styles)

const TeamCardForm = ({ panel }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [panelName, setPanelName] = useState('')
	const [partnersStartDate, setPartnersStartDate] = useState(null)
	const [partnersEndDate, setPartnersEndDate] = useState(null)
	const [openDelete, setOpenDelete] = useState(false)
	const [successUpdated, setSuccessUpdate] = useState(false)
	const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(true)
	const [phrase, setPhrase] = useState('')

	const { loadingTeamUpdate, errorTeamUpdate, successTeamUpdate } = useSelector((state) => state.teamUpdate)
	const { loadingTeamDelete, errorTeamDelete, successTeamDelete } = useSelector((state) => state.teamDelete)

	// Update PartnerPanel
	useEffect(() => {
		if (panel) {
			setPanelName(panel.panelName)
			setPartnersStartDate(format(new Date(panel.partnersStartDate), 'yyyy-MM-dd'))
			setPartnersEndDate(format(new Date(panel.partnersEndDate), 'yyyy-MM-dd'))
		}
	}, [panel])
	useEffect(() => {
		if (successTeamUpdate) {
			setSuccessUpdate(true)
			setTimeout(() => {
				setSuccessUpdate(false)
			}, 2000)
		}
	}, [successTeamUpdate])
	const submitEditFormHandler = (e) => {
		e.preventDefault()
		const updatePanel = {
			_id: panel._id,
			panelName,
			partnersStartDate,
			partnersEndDate,
		}
		dispatch(teamPanelUpdateInfo(updatePanel))
	}

	// Delete Partner Panel
	useEffect(() => {
		if (successTeamDelete) {
			setOpenDelete(false)
		}
	}, [successTeamDelete])
	useEffect(() => {
		if (phrase === 'absolutely sure') {
			setDeleteBtnDisabled(false)
		} else {
			setDeleteBtnDisabled(true)
		}
	}, [phrase])
	const deletePanelHandler = () => {
		dispatch(deleteTeamPanel(panel._id))
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<div className={classes.topicInfo}>
					<h4>{panel.panelName}</h4>
					{panel.topic && (
						<>
							<img src={`${axios.defaults.baseURL}${panel.topic.image}`} alt={panel.topic.name} />
							<h5>{panel.topic.name}</h5>
						</>
					)}
				</div>
			</Grid>
			<Grid item xs={12}>
				<form onSubmit={submitEditFormHandler} autoComplete='off'>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<CustomInput
								labelText='Panel Name'
								id={'panel-name' + panel.panelName}
								formControlProps={{
									fullWidth: true,
									className: classes.formControlItem,
								}}
								inputProps={{
									type: 'text',
									value: panelName,
									placeholder: 'Enter panel name',
									onChange: (e) => setPanelName(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker
								label='Partners Start Date'
								value={partnersStartDate}
								onChange={setPartnersStartDate}
								format='dd/MM/yyyy'
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
									className: classes.selectLabel,
								}}
								InputProps={{
									classes: {
										input: classes.resize,
									},
									required: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DatePicker
								label='Partners End Date'
								value={partnersEndDate}
								onChange={setPartnersEndDate}
								format='dd/MM/yyyy'
								minDate={partnersStartDate}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
									className: classes.selectLabel,
								}}
								InputProps={{
									classes: {
										input: classes.resize,
									},
									required: true,
								}}
							/>
						</Grid>
						{errorTeamUpdate && (
							<Grid item xs={12}>
								<Message message={<span>{errorTeamUpdate}</span>} color='info' />
							</Grid>
						)}
						<Grid item xs={8} sm={9}>
							<Button
								type='submit'
								color={successUpdated ? 'success' : 'primary'}
								block
								disabled={successUpdated && true}
							>
								{loadingTeamUpdate ? <Loader size={'sm'} /> : successUpdated ? 'Updated Successfully' : 'Update Panel'}
							</Button>
						</Grid>
						<Grid item xs={4} sm={3}>
							<Button color='danger' block onClick={() => setOpenDelete(true)}>
								{loadingTeamDelete ? <Loader size={'sm'} /> : 'Delete'}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
			<CustomModal title='Danger Zone' open={openDelete} setOpen={setOpenDelete} actions={false}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<p>
							Are you sure you want to delete this panel? <strong>{panel.panelName}</strong>
						</p>
						<p>You are about to permanently delete this panel</p>
						<p>
							Once a panel is permanently deleted, it cannot be recovered. Permanently deleting this panel will
							immediately <strong>delete its related centers and resources</strong> (if there are any).
						</p>
						<p>Please type the phrase below to enable delete button.</p>
						<p className={classes.deletePhrase}>absolutely sure</p>
					</Grid>
					<Grid item xs={12}>
						<CustomInput
							id={'absolutelysure' + panel.panelName}
							inputProps={{
								autoFocus: true,
								placeholder: 'Type the phrase here',
								type: 'text',
								autoComplete: 'off',
								onChange: (e) => setPhrase(e.target.value),
							}}
							formControlProps={{
								fullWidth: true,
							}}
						/>
					</Grid>
					{errorTeamDelete && (
						<Grid item xs={12}>
							<Message message={<span>{errorTeamDelete}</span>} color='info' />
						</Grid>
					)}
					<Grid item xs={6}>
						<Button color='primary' block onClick={() => setOpenDelete(false)}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button disabled={deleteBtnDisabled} color='danger' block onClick={deletePanelHandler}>
							{loadingTeamDelete ? <Loader size='sm' /> : 'Delete Panel'}
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
		</Grid>
	)
}

export default TeamCardForm

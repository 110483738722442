import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardHeader from '../Card/CardHeader'

import styles from './styles/customHeaderCardStyle'

const useStyles = makeStyles(styles)

const CustomHeaderCard = ({
    headerColor,
    plainHeader,
    title,
    subtitle,
    children,
    rtlActive,
    className,
}) => {
    const classes = useStyles()

    const cardTitle = classNames({
        [classes.cardTitle]: true,
        [classes.cardTitleRTL]: rtlActive,
    })
    return (
        <Card plain={plainHeader} className={className}>
            <CardHeader color={headerColor} plain={plainHeader}>
                <div className={cardTitle}>{title}</div>
                {subtitle && <p className={classes.cardSubTitle}>{subtitle}</p>}
            </CardHeader>
            <CardBody className='pt-3'>{children}</CardBody>
        </Card>
    )
}

CustomHeaderCard.propTypes = {
    headerColor: PropTypes.oneOf([
        'warning',
        'success',
        'danger',
        'info',
        'primary',
        'rose',
    ]),
    title: PropTypes.string.isRequired,
    rtlActive: PropTypes.bool,
    plainHeader: PropTypes.bool,
}

export default CustomHeaderCard

const styles = {
  rootContent: {
    padding: '150px 20px',
    width: '100%',
    textAlign: 'center',
    '& h2': {
      fontSize: '36px',
      marginBottom: '40px',
      lineHeight: '50px',
    },
  },
  sectionRoot: {
    marginTop: '20px',
  },
  formControl: {
    marginTop: '15px',
    minWidth: 120,
    width: '100%',
  },
  root: {
    '& h5': {
      textAlign: 'center',
    },
  },
}
export default styles

const styles = {
    btnUpdate: {
        marginTop: '20px !important',
    },
    switchItem: {
        textAlign: 'left',
    },
    formControl: {
        margin: 0,
    },
}
export default styles

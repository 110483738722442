import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { GroupAdd } from '@material-ui/icons'
import GroupListCard from '../Centers/GroupListCard/GroupListCard'
import Loader from '../Loader/Loader'
import styles from './styles/customTabGroupStyles'

const useStyles = makeStyles(styles)

const CustomTabGroup = ({ teachers, center }) => {
  const classes = useStyles()

  const [groupId, setGroupId] = useState('')
  const [infoGroups, setInfoGroups] = useState([])

  const { loadingGroupListByCenter, errorGroupListByCenter, groups } = useSelector((state) => state.groupListByCenter)

  useEffect(() => {
    if (groups) {
      setInfoGroups(groups)
    }
  }, [groups])
  return (
    <>
      {errorGroupListByCenter ? (
        <span>{errorGroupListByCenter}</span>
      ) : groups && groups.length === 0 ? (
        <div className={classes.emptyMsg}>
          <h2>There are no groups in this center yet.</h2>
          <GroupAdd />
          <p>Please add the first group.</p>
        </div>
      ) : (
        <>
          <p className={classes.cardTopMsg}>
            Drag and drop a teacher onto the gray teachers area to assign them to the group or you can click on group's
            menu icon.
          </p>
          {infoGroups &&
            infoGroups.map((group) =>
              loadingGroupListByCenter && groupId === group._id ? (
                <Loader key={group._id} />
              ) : (
                <GroupListCard
                  key={group._id}
                  group={group}
                  center={center}
                  teachers={teachers}
                  setGroupId={setGroupId}
                />
              )
            )}
        </>
      )}
    </>
  )
}

export default CustomTabGroup

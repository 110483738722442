const styles = {
  groupCard: {
    padding: 5,
    margin: 5,
    '& p': {
      margin: 0,
    },
  },
  countryFlagProfile: {
    width: '25px',
    borderRadius: '5px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

export default styles

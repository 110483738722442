const styles = {
  panelRoot: {
    marginTop: '10px',
  },
  cardTitle: {
    marginBottom: 0,
    textAlign: 'center',
  },
  flexAlignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  textMuted: {
    color: '#5f6368',
  },
  centerLogo: {
    width: '70px',
    height: '70px',
    '& img': {
      width: '100%',
    },
  },
  partnersCards: {
    marginTop: '50px',
  },
  formControl: {
    marginTop: '11px',
    minWidth: 120,
    width: '100%',
    textAlign: 'left',
  },
  formControlItem: {
    margin: 0,
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  countryFlagProfile: {
    width: '35px',
    borderRadius: '5px',
  },
  topicInfo: {
    marginTop: '10px',
    textAlign: 'center',
    '& h5': {
      color: '#79a94d',
    },
    '& img': {
      width: '100px',
      borderRadius: '6px',
    },
  },
  cardInfo: {
    textAlign: 'left'
  }
}

export default styles

import { useEffect, useRef } from 'react'
//import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
//import CardAvatar from '../../../../components/Card/CardAvatar'
import SurveyCardForm from './SurveyCardForm'
import styles from '../styles/surveyCardstyles'

const useStyles = makeStyles(styles)

const SurveyCard = ({ survey }) => {
  const classes = useStyles()
  const editSurveyform = useRef(null)

  const scrollToRef = () => {
    editSurveyform.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToRef()
  }, [survey])

  return (
    <Grid container ref={editSurveyform}>
      <Grid item xs={12} sm={12} md={12}>
        <Card profile>
          {/* <CardAvatar profile>
            <img src={`${axios.defaults.baseURL}${survey.avatar}`} alt={survey.title} />
          </CardAvatar> */}
          <CardBody profile>
            <h4 className={classes.cardTitle}>{survey.title}</h4>
            <SurveyCardForm survey={survey} />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SurveyCard

const styles = {
	infiniteScroll: {
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	contentLine: {
		width: '100%',
		textAlign: 'center',
	},
	mapStyle: {
		maxHeight: '80%',
		width: '100%',
		margin: '1rem auto',
		stroke: '#fff',
		'& path': {
			fill: 'rgb(139, 186, 238)',
			outline: 'none',
		},
	},
	mainTitle: {
		margin: 'auto',
	},
	mapTitle: {
		color: '#483d3d',
		fontSize: '22px',
	},
	cardRoot: {
		width: '100%',
		marginBottom: 10,
		textAlign: 'start',
	},
	cardHeaderRoot: {
		padding: 10,
		'& .MuiCardHeader-avatar': {
			marginRight: 10,
		},
		'& .MuiCardHeader-content': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-action': {
			margin: 0,
		},
	},
	countryFlag: {
		height: '25px',
		width: '25px',
		borderRadius: '10px',
		margin: '0px 5px',
	},
	countrySelect: {
		color: '#fff',
		margin: '0',
	},
	sectionCountry: {
		padding: '30px',
		paddingBottom: '0',
		textAlign: 'center',
		width: '1200px',
	},
	sectionCountryPosts: {
		padding: '0 20px',
		paddingTop: '10px',
		textAlign: 'center',
		width: '1200px',
	},
	contentSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap-reverse',
		marginBottom: 20,
		'& img': {
			width: '135px',
			height: '100px',
			borderRadius: '5px',
		},
	},
	flagInTitle: {
		borderRadius: '4px',
		marginLeft: '10px',
	},
	countryData: {
		width: '50%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		'& h2': {
			fontSize: '24px',
			paddingRight: '20px',
			marginBottom: 5,
		},
		'& p': {
			fontSize: '12px',
			marginBottom: 2,
		},
	},
	countryDataFull: {
		width: '75%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		'& h2': {
			fontSize: '24px',
			paddingRight: '20px',
			marginBottom: 5,
		},
		'& p': {
			fontSize: '12px',
			marginBottom: 2,
		},
	},
	countryDescription: {
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		'& h2': {
			fontSize: '18px',
			paddingRight: '20px',
			marginBottom: 10,
		},
		'& p': {
			fontSize: '12px',
			textAlign: 'left',
		},
	},
	countryPostHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: 20,
		'& h2': {
			fontSize: '18px',
			paddingRight: '20px',
			marginBottom: 10,
		},
	},
	countryDataKeys: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
	sectionCenters: {
		marginTop: '20px',
		padding: '30px',
	},
	btnModal: {
		textAlign: 'end',
	},
	sectionCountries: {
		overflowY: 'scroll',
		height: '700px',
		boxShadow: '0px  0px 15px #b5b5b5 inset',
		backgroundColor: '#fff',
		border: '1px solid #fff',
		padding: 10,
		'& img': {
			width: '25px',
			margin: '10px 15px',
			borderRadius: '3px',
		},
		'& p': {
			margin: 0,
		},
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: 'gray',
			borderRadius: '5px',
		},
	},

	countriesCenters: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
	},

	btnCountries: {
		textTransform: 'capitalize',
		width: '100%',
		textAlign: 'start',
	},
	'@media screen and (max-width: 980px)': {
		sectionCountries: {
			height: '500px',
		},
	},
	'@media screen and (max-width: 600px)': {
		sectionCountries: {
			height: '400px',
			marginBottom: '30px',
		},
	},
	sectionPosts: {
		width: '100%',
		padding: '0 20px',
	},
	backButton: {
		padding: ' 0 20px',
	},
}

export default styles

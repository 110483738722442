const styles = {
  root: {
    padding: '50px 25px 25px',
  },
  customTabsRoot: {
    minHeight: '500px',
    height: '100% !important',
  },
  cardTextCenter: {
    textAlign: 'center',
    paddingTop: 10,
  },
  centerInfoCard: {
    margin: 'auto',
  },
  cardTopMsg: {
    margin: '15px 0',
  },
}

export default styles

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { PermMedia } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import { registerImage } from '../../../../redux/actions/galleryActions'
import styles from '../styles/imageRegisterModalStyles'

const useStyles = makeStyles(styles)

const ImageRegisterModal = ({ setOpen }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [coverImageFile, setCoverImageFile] = useState(null)

	const { loadingImageRegister, successImageRegister, errorImageRegister } = useSelector(
		(state) => state.imageGalleryRegister,
	)

	useEffect(() => {
		if (successImageRegister) {
			setOpen(false)
			setCoverImageFile(null)
			const previewImg = document.querySelector('.preview-cover-image img')
			previewImg.src = '/assets/img/magazines/cover-preview.png'
		}
	}, [dispatch, setOpen, successImageRegister])

	const submitHandler = (e) => {
		e.preventDefault()
		if (coverImageFile === null) {
			alert('You have to select a valid image!')
		} else {
			const galleryCreate = {
				image: coverImageFile,
			}
			dispatch(registerImage(galleryCreate))
		}
	}
	const previewImageHandler = (e) => {
		let dataURItoBlob = (dataURI) => {
			let byteString
			if (dataURI.split(',')[0].indexOf('base64') >= 0) {
				byteString = atob(dataURI.split(',')[1])
			} else {
				byteString = unescape(dataURI.split(',')[1])
			}
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
			const ia = new Uint8Array(byteString.length)
			for (let index = 0; index < byteString.length; index++) {
				ia[index] = byteString.charCodeAt(index)
			}
			return new Blob([ia], { type: mimeString })
		}
		const coverImgFile = e.target.files[0]
		if (coverImgFile) {
			setCoverImageFile(null)
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const reader = new FileReader()
			const img = new Image()
			img.onload = () => {
				let maxWidth = 450
				let maxHeight = Math.round((img.height *= maxWidth / img.width))
				ctx.canvas.width = maxWidth
				ctx.canvas.height = maxHeight
				ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
				const imgData = canvas.toDataURL('image/jpeg', 0.7)
				const imgBlob = dataURItoBlob(imgData)
				const outputfile = new File([imgBlob], coverImgFile.name, {
					type: 'image/jpeg',
				})
				setCoverImageFile(outputfile)
				const previewImg = document.querySelector('.preview-cover-image img')
				previewImg.src = imgData
			}
			reader.onloadend = () => {
				img.src = reader.result
			}
			reader.readAsDataURL(coverImgFile)
		}
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} sm={6}>
				<form autoComplete='off' className={classes.formRoot}>
					<label htmlFor='cover-image' className={classes.coverlabel}>
						Add Image
						<span>
							<PermMedia />
						</span>
					</label>
					<input
						type='file'
						hidden
						id='cover-image'
						name='cover-image'
						onChange={previewImageHandler}
						accept='image/png, image/jpeg'
					/>
					{errorImageRegister && <Message message={<span>{errorImageRegister}</span>} color='info' />}
				</form>
			</Grid>
			<Grid item xs={12} sm={6} className={classes.magazineCol}>
				<div className='preview-cover-image'>
					<img src='/assets/img/magazines/cover-preview.png' alt='Cover Preview' className={classes.magazineCover} />
				</div>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<Button onClick={(e) => submitHandler(e)} color='primary' block>
					{loadingImageRegister ? <Loader size={'sm'} /> : 'Submit'}
				</Button>
			</Grid>
		</Grid>
	)
}

export default ImageRegisterModal

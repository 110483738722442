const styles = {
  root: {
    padding: '50px 0px 25px',
    height: '100%',
    margin: 'auto',
    '& h1': {
      textAlign: 'center',
      marginBottom: '5px',
    },
    '& hr': {
      margin: '5px 0px 10px 0px',
      padding: 0,
    },
  },
  sectionRoot: {
    '& label': {
      width: '100%',
    },
  },
  centerInfoCard: {
    margin: 'auto',
  },
  emptyMsg: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 10,
    '& svg': {
      fontSize: '120px',
      color: '#43a047',
    },
  },
  cardTextCenter: {
    textAlign: 'center',
    paddingTop: 10,
  },
  cardTopMsg: {
    margin: '15px 0',
  },
  '@media screen and (max-width: 480px)': {
    root: {
      padding: '50px 5px 25px',
    },
  },
}

export default styles

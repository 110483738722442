import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	makeStyles,
	Radio,
	TextField,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerSurvey } from '../../../../redux/actions/surveyActions'
import styles from '../styles/surveyRegisterFormModalStyles'

const useStyles = makeStyles(styles)

const SurveyRegisterFormModal = ({ setOpen, open }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [title, setTitle] = useState('')
	const [error, setError] = useState('')
	const [questions, setQuestions] = useState([])

	const { loadingSurveyRegister, errorSurveyRegister, successSurveyRegister } = useSelector(
		(state) => state.surveyRegister,
	)

	useEffect(() => {
		if (successSurveyRegister) {
			setOpen(false)
			setTitle('')
			setQuestions([])
			setError('')
		}
		if (!open) {
			setTitle('')
			setQuestions([])
			setError('')
		}
	}, [successSurveyRegister, setOpen, dispatch, open])

	const submitFormHandler = (e) => {
		e.preventDefault()
		const surveyQuestions = questions.map((question) => {
			return {
				question: question.question,
				type: question.type,
				checkboxQuestion: question.checkboxQuestion || [],
				radioQuestion: question.radioQuestion || [],
			}
		})
		if (!surveyQuestions.length) {
			return false
		}
		const createSurvey = {
			title,
			questions: surveyQuestions,
		}
		const checkboxQuestion = surveyQuestions.some(
			(data) => data.type === 'selectCheckbox' && data.checkboxQuestion.length < 1,
		)
		const options = surveyQuestions.some((data) => data.type === 'selectRadio' && data.radioQuestion.length < 1)

		if (checkboxQuestion) {
			setError('Oops! Add an option')
		} else if (options) {
			setError('Oops! Add an option')
		} else {
			dispatch(registerSurvey(createSurvey))
		}
	}
	const handleQuestion = (e, question) => {
		const arr = questions.map((el) => {
			if (el._id === question._id) {
				el.question = e.target.value
			}
			return el
		})
		setQuestions(arr)
	}

	const handleCheckboxQuestion = (e, checkQuestion, question) => {
		const arr = questions.map((el) => {
			if (el._id === question._id) {
				el.checkboxQuestion.forEach((select) => {
					if (checkQuestion._id === select._id) {
						select.question = e.target.value
					}
				})
			}
			return el
		})
		setQuestions(arr)
	}

	const handleRadioQuestion = (e, radioQuestion, question) => {
		const arr = questions.map((el) => {
			if (el._id === question._id) {
				el.radioQuestion.forEach((select) => {
					if (radioQuestion._id === select._id) {
						select.question = e.target.value
					}
				})
			}
			return el
		})
		setQuestions(arr)
	}

	// const addQuestion = () => {
	//   setOpenQuestion(true)
	//   // const id = Date.now().toString()
	//   // setQuestions(
	//   //   questions.concat([
	//   //     {
	//   //       _id: id,
	//   //       question: '',
	//   //     },
	//   //   ])
	//   // )
	// }

	const addCheckboxQuestion = (e, question) => {
		const id = Date.now().toString()
		const arr = questions.map((el) => {
			if (el._id === question._id) {
				el.checkboxQuestion.push({
					_id: id,
					question: '',
				})
			}
			return el
		})
		setQuestions(arr)
		setError('')
	}

	const addRadioQuestion = (e, question) => {
		const id = Date.now().toString()
		const arr = questions.map((el) => {
			if (el._id === question._id) {
				el.radioQuestion.push({
					_id: id,
					question: '',
				})
			}
			return el
		})
		setQuestions(arr)
		setError('')
	}
	const handleCurrentInput = (value) => {
		const id = Date.now().toString()
		if (value === 'paragraph') {
			setQuestions(
				questions.concat([
					{
						_id: id,
						question: '',
						type: value,
					},
				]),
			)
		} else if (value === 'selectCheckbox') {
			setQuestions(
				questions.concat([
					{
						_id: id,
						question: '',
						checkboxQuestion: [],
						type: value,
					},
				]),
			)
		} else if (value === 'selectRadio') {
			setQuestions(
				questions.concat([
					{
						_id: id,
						question: '',
						radioQuestion: [],
						type: value,
					},
				]),
			)
		}
	}
	const deleteQuestion = (id) => {
		setQuestions((prev) => {
			return prev.filter((el) => el._id !== id)
		})
	}

	// const handleRadioChange = (event) => {
	//   setValue(event.target.value)
	// }

	const deleteCheckboxQuestion = (id) => {
		const arr = questions.map((el) => {
			if (el?.checkboxQuestion?.length > 0) {
				const data = el.checkboxQuestion.filter((select) => select._id !== id)
				el.checkboxQuestion = data
			}
			return el
		})
		setQuestions(arr)
	}

	const deleteRadioQuestion = (id) => {
		const arr = questions.map((el) => {
			if (el?.radioQuestion?.length > 0) {
				const data = el.radioQuestion.filter((select) => select._id !== id)
				el.radioQuestion = data
			}
			return el
		})
		setQuestions(arr)
	}

	return (
		<Grid container className={classes.root}>
			<Grid item md={12}>
				<form onSubmit={submitFormHandler} autoComplete='off'>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<CustomInput
								labelText={'Enter Survey Title'}
								id={Date.now().toString()}
								formControlProps={{
									fullWidth: true,
									className: classes.formControl,
								}}
								inputProps={{
									type: 'text',
									value: title,
									placeholder: 'Enter Survey Title',
									onChange: (e) => setTitle(e.target.value),
									required: true,
								}}
							/>
						</Grid>
						{questions?.map((question, i) => {
							const index = i + 1
							return (
								<div className={classes.sectionRoot} key={question._id}>
									<Grid item xs={12} md={12} key={question._id}>
										<div className={classes.rootQuestion}>
											<CustomInput
												labelText={`${index}.- ${question.question}`}
												id={question._id}
												formControlProps={{
													fullWidth: true,
													className: classes.formControl,
												}}
												inputProps={{
													type: 'text',
													value: question.question,
													placeholder: 'Enter question',
													onChange: (e) => handleQuestion(e, question),
													required: true,
												}}
											/>
											<IconButton
												aria-label='close'
												className={classes.closeIcon}
												onClick={() => deleteQuestion(question._id)}
											>
												<Close fontSize='small' />
											</IconButton>
										</div>
									</Grid>
									{question.type === 'selectCheckbox' && (
										<>
											<Grid item md={12}>
												<FormGroup>
													{question.checkboxQuestion &&
														question.checkboxQuestion?.map((select, i) => {
															return (
																<div key={select._id}>
																	<FormControlLabel control={<Checkbox />} disabled />
																	<TextField
																		id={Date.now().toString()}
																		required
																		value={select.question}
																		onChange={(e) => handleCheckboxQuestion(e, select, question)}
																	/>
																	<IconButton
																		aria-label='close'
																		className={classes.closeIcon}
																		onClick={() => deleteCheckboxQuestion(select._id)}
																	>
																		<Close fontSize='small' />
																	</IconButton>
																</div>
															)
														})}
												</FormGroup>
											</Grid>
											<Grid item md={6} sm={6}>
												<Button
													className={classes.sectionButton}
													color='primary'
													block
													onClick={(e) => addCheckboxQuestion(e, question)}
												>
													+ Add a selection
												</Button>
											</Grid>
										</>
									)}
									{question.type === 'selectRadio' && (
										<>
											<FormControl className={classes.sectionSelects}>
												{question.radioQuestion &&
													question.radioQuestion?.map((value) => {
														return (
															<Grid item md={12} key={value._id}>
																<FormControlLabel control={<Radio />} disabled />
																<TextField
																	required
																	id={Date.now().toString()}
																	placeholder='Enter Question'
																	value={value.question}
																	onChange={(e) => handleRadioQuestion(e, value, question)}
																/>
																<IconButton
																	aria-label='close'
																	className={classes.closeIcon}
																	onClick={() => deleteRadioQuestion(value._id)}
																>
																	<Close fontSize='small' />
																</IconButton>
															</Grid>
														)
													})}
											</FormControl>
											<Grid item md={6} sm={6}>
												<Button
													className={classes.sectionButton}
													color='primary'
													block
													onClick={(e) => addRadioQuestion(e, question)}
												>
													+ Add an option
												</Button>
											</Grid>
										</>
									)}
								</div>
							)
						})}
						{errorSurveyRegister && (
							<Grid item md={12}>
								<Message message={<span>{errorSurveyRegister}</span>} color='info' />
							</Grid>
						)}
						{error && (
							<Grid item md={12}>
								<Message message={<span>{error}</span>} color='danger' />
							</Grid>
						)}
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4} md={4}>
								<Button block color={'warning'} onClick={() => handleCurrentInput('paragraph')}>
									+ Paragraph
								</Button>
							</Grid>
							<Grid item xs={6} sm={4} md={4}>
								<Button block color={'success'} onClick={() => handleCurrentInput('selectRadio')}>
									+ Options
								</Button>
							</Grid>
							<Grid item xs={6} sm={4} md={4}>
								<Button block color={'info'} onClick={() => handleCurrentInput('selectCheckbox')}>
									+ Multiple Choice
								</Button>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								{questions.length > 0 && (
									<Button type='submit' color='primary' block>
										{loadingSurveyRegister ? <Loader size={'sm'} /> : 'Submit'}
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	)
}

export default SurveyRegisterFormModal

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Fab, Grid, makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import CustomModal from '../../../../components/Modal/CustomModal'
import { dataURItoBlob } from '../../../../utils/getDataUriToBlob'
import { deleteImage, updateImage } from '../../../../redux/actions/galleryActions'
import styles from '../styles/imageCardFormStyles'

const useStyles = makeStyles(styles)
const ImageCardForm = ({ imageItemDetail }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [imageUrl, setImageUrl] = useState(null)
	const [openDelete, setOpenDelete] = useState(false)

	const { loadingImageUpdate } = useSelector((state) => state.imageGalleryUpdate)
	const { loadingImageDelete } = useSelector((state) => state.imageGalleryDelete)

	useEffect(() => {
		setImageUrl(imageItemDetail.image)
	}, [imageItemDetail])

	const submitEditFormHandler = () => {
		const updateItem = {
			_id: imageItemDetail._id,
			image: imageUrl,
		}
		dispatch(updateImage(updateItem))
	}

	const handleImageChange = (e) => {
		const coverImgFile = e.target.files[0]
		if (coverImgFile) {
			setImageUrl(null)
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const reader = new FileReader()
			const img = new Image()
			img.onload = () => {
				let maxWidth = 450
				let maxHeight = Math.round((img.height *= maxWidth / img.width))
				ctx.canvas.width = maxWidth
				ctx.canvas.height = maxHeight
				ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
				const imgData = canvas.toDataURL('image/jpeg', 0.7)
				const imgBlob = dataURItoBlob(imgData)
				const outputfile = new File([imgBlob], coverImgFile.name, {
					type: 'image/jpeg',
				})
				setImageUrl(outputfile)
				const previewImg = document.querySelector('#preview-magazine-cover img')
				previewImg.src = imgData
			}
			reader.onloadend = () => {
				img.src = reader.result
			}
			reader.readAsDataURL(coverImgFile)
		}
	}
	const deleteConfirmHandler = () => {
		dispatch(deleteImage(imageItemDetail._id))
		setOpenDelete(false)
	}

	return (
		<>
			<Card className={classes.maxHeight50VH}>
				<CardBody>
					<Grid container spacing={2}>
						<Grid item md={12} sm={12} xs={12}>
							<form autoComplete='off'>
								<Grid container spacing={2}>
									{false && (
										<Grid item xs={12}>
											<Message message={<span>{'errorMiniBlogDelete'}</span>} color='info' />
										</Grid>
									)}
								</Grid>
							</form>
						</Grid>
						<Grid item xs={12} sm={12}>
							{imageItemDetail && (
								<div className={classes.imgContainer} id='preview-magazine-cover'>
									<img
										src={`${axios.defaults.baseURL}/${imageItemDetail.image}`}
										alt='Img not Found'
										className={classes.imgSize}
									/>
								</div>
							)}
							<Grid item xs={12} md={12} sm={12} className={classes.btnResourceStart}>
								<p>Add New Image</p>
								<label htmlFor='image-input'>
									<input
										type='file'
										name='imageInput'
										capture='camera'
										hidden
										id='image-input'
										accept='.png, .jpg, .jpeg'
										onChange={(e) => handleImageChange(e)}
									/>
									<Fab color='primary' size='small' component='span' aria-label='add'>
										<Add />
									</Fab>
								</label>
							</Grid>
						</Grid>
						<Grid item xs={8} sm={6} md={6}>
							<Button onClick={() => submitEditFormHandler()} color={false ? 'success' : 'primary'} block>
								{loadingImageUpdate ? <Loader size={'sm'} /> : false ? 'Updated Successfully' : 'Update Item'}
							</Button>
						</Grid>
						<Grid item xs={4} sm={6} md={6}>
							<Button color='danger' onClick={() => setOpenDelete(true)} block>
								{loadingImageDelete ? <Loader size={'sm'} /> : 'Delete'}
							</Button>
						</Grid>
					</Grid>
				</CardBody>
			</Card>
			<CustomModal title='Delete Item' open={openDelete} setOpen={setOpenDelete} actions={false}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<p>Are you sure you want to delete this Item?</p>
					</Grid>
					<Grid item xs={6}>
						<Button color='primary' block onClick={() => setOpenDelete(false)}>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button color='danger' block onClick={deleteConfirmHandler}>
							{false ? <Loader size='sm' /> : 'Delete Item'}
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
		</>
	)
}

export default ImageCardForm

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles/cardBodyStyle'

const useStyles = makeStyles(styles)

const CardBody = ({ className, children, plain, profile, ...rest }) => {
    const classes = useStyles()

    const cardBodyClasses = classNames({
        [classes.cardBody]: true,
        [classes.cardBodyPlain]: plain,
        [classes.cardBodyProfile]: profile,
        [className]: className !== undefined,
    })
    return (
        <div className={cardBodyClasses} {...rest}>
            {children}
        </div>
    )
}

CardBody.propTypes = {
    className: PropTypes.string,
    plain: PropTypes.bool,
    profile: PropTypes.bool,
    children: PropTypes.node,
}

export default CardBody

import Meta from '../../../components/Meta/Meta'

const DashboardScreen = () => {
    return (
        <>
            <Meta title={'Dashboard - Planet Fraternity'} />
            <h1>Dashboard</h1>
        </>
    )
}

export default DashboardScreen

const styles = {
  panelRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& img': {
      height: '50px',
      width: '50px',
      marginTop: '10px',
    },
  },
  btnwrapper: {
    padding: 10,
    '& svg': {
      margin: 0,
    },
  },
  navLink: {
    color: '#757575 !important',
    borderRadius: '33px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '10px 10px 0px 10px',
    display: 'inline-flex',
    '& svg': {
      width: 22,
      height: 22,
    },
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.2)',
    },
  },
  imageDropdownButton: {
    padding: '0px',
    borderRadius: '50%',
    backgroundColor: '#f3f3f3',
  },
}

export default styles

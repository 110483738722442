import { GET_MEDIA_CONSTRAINTS } from '../config/webRTCConfig'

/**
 * @returns {Object} My own stream object
 */
export const getMyVideo = async () => {
  return navigator.mediaDevices.getUserMedia(GET_MEDIA_CONSTRAINTS)
}

/**
 * @param {Object} videoTagRef HTML video tag reference
 * @param {Object} stream My own stream object
 * @returns {Object} The object returned attach the stream to html video tag
 */
export const setMyStreamToHTML = (videoTagRef, stream) => {
  if ('srcObject' in videoTagRef) {
    videoTagRef.srcObject = stream
  } else {
    videoTagRef.src = window.URL.createObjectURL(stream)
  }
}

/**
 * @returns {Object}
 */
export const getMyScreen = async () => {
  return navigator.mediaDevices.getDisplayMedia({ cursor: true })
}

/**
 * @param {Object} myStream
 * @param {Boolean} camEnabled
 * @param {CallableFunction} setCamEnabled
 */
export const camHandler = (myStream, camEnabled, setCamEnabled) => {
  const myTrack = myStream.getVideoTracks()[0]
  myTrack.enabled = !camEnabled
  setCamEnabled(!camEnabled)
}

/**
 * @param {Object} myStream
 * @param {Boolean} micEnabled
 * @param {CallableFunction} setMicEnabled
 */
export const micHandler = (myStream, micEnabled, setMicEnabled) => {
  const myTrack = myStream.getAudioTracks()[0]
  myTrack.enabled = !micEnabled
  setMicEnabled(!micEnabled)
}

/**
 * @param {String} action
 * @param {Array} senders
 * @param {Object} myStream
 */
export const replaceVideoTrack = (action, senders, myStream) => {
  const stream = action === 'start' ? myStream : myStream.getVideoTracks()[0]
  senders.forEach((sender) => sender.track.kind === 'video' && sender.replaceTrack(stream))
}

/**
 * @param {Boolean} isSharing
 * @param {CallableFunction} setIsSharing
 * @param {Object} userSharingStream
 * @param {Object} myVideoTag
 * @param {Array} sendersRef
 * @param {Object} myStream
 */
export const screenShareHandler = async (
  isSharing,
  setIsSharing,
  userSharingStream,
  setUserSharingStream,
  myVideoTag,
  sendersRef,
  myStream
) => {
  if (!isSharing) {
    const displayStream = await getMyScreen()
    setUserSharingStream(displayStream.getTracks()[0])
    setMyStreamToHTML(myVideoTag, displayStream)
    replaceVideoTrack('start', sendersRef, displayStream.getTracks()[0])
    displayStream.getTracks()[0].onended = () => {
      setMyStreamToHTML(myVideoTag, myStream)
      replaceVideoTrack('ended', sendersRef, myStream)
      setIsSharing(false)
    }
    setIsSharing(true)
  } else {
    setMyStreamToHTML(myVideoTag, myStream)
    replaceVideoTrack('ended', sendersRef, myStream)
    userSharingStream.stop()
    setIsSharing(false)
  }
}

/**
 * @param {number} value 0 | 1 | 2
 * @param {*} sidebarValue Current sidebar value
 * @param {*} setSidebarValue Callback to change sidebarValue
 */
export const handleSidebar = (value, sidebarValue, setSidebarValue) => {
  if (value === sidebarValue) {
    setSidebarValue(0)
  } else {
    setSidebarValue(value)
  }
}

/**
 * @param {Object} e
 * @param {String} newMessage
 * @param {Object} userInfo
 * @param {String} roomID
 * @param {Object} socket
 * @param {CallableFunction} setNewMessage
 */
export const handleMessages = (e, newMessage, userInfo, roomID, socket, setNewMessage) => {
  if (e.key === 'Enter' && newMessage !== '') {
    const msgObj = {
      _id: userInfo._id,
      username: userInfo.username,
      name: userInfo.name,
      avatar: userInfo.avatar,
      message: newMessage,
      roomID,
    }
    socket.emit('new-message', msgObj)
    setNewMessage('')
  }
}

/**
 * @param {String} role
 */
export const handleEndCall = (role, isAdmin = null) => {
  if (role === 'teacher' && !isAdmin) {
    window.location = '/teacher/school-teams'
  } else if (role === 'student') {
    window.location = '/student/dashboard'
  } else if (role === 'teacher' && isAdmin) {
    window.location = '/admin/teams'
  }
}

/**
 * @param {Object} userDisconnected
 * @param {Object} usersConnected
 * @param {Object} myPeers
 * @param {CallableFunction} setPeers
 * @param {CallableFunction} setPeersInChat
 */
export const handleDisconnect = ({ userDisconnected, usersConnected }, myPeers, setPeers, setPeersInChat) => {
  delete myPeers[userDisconnected.socketId]
  document.getElementById(userDisconnected.socketId)?.remove()
  setPeers((users) => {
    const newArray = users.filter((item) => item.user.socketId !== userDisconnected.socketId)

    return [...newArray]
  })
  setPeersInChat(usersConnected)
}

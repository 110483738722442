import * as types from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case types.USER_LOGIN_REQUEST:
			return { loadingUserInfo: true }
		case types.USER_LOGIN_SUCCESS:
			return { loadingUserInfo: false, userInfo: action.payload }
		case types.USER_LOGIN_FAIL:
			return { loadingUserInfo: false, errorUserInfo: action.payload }
		case types.USER_LOGIN_FAIL_CLEAR:
			return { loadingUserInfo: false, errorUserInfo: null }
		case types.USER_LOGOUT:
			return {}
		default:
			return state
	}
}

export const userRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.USER_REGISTER_REQUEST:
			return { loadingUserRegister: true }
		case types.USER_REGISTER_SUCCESS:
			return {
				loadingUserRegister: false,
				successUserRegister: true,
				user: action.payload,
			}
		case types.USER_REGISTER_FAIL:
			return {
				loadingUserRegister: false,
				errorUserRegister: action.payload,
			}
		case types.USER_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const userListReducer = (state = { users: [] }, action) => {
	switch (action.type) {
		case types.USER_LIST_REQUEST:
			return { loading: true }
		case types.USER_LIST_SUCCESS:
			return {
				loading: false,
				thatsIt: action.payload.length === 0 ? true : false,
				users: action.payload,
			}
		case types.USER_LIST_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const userDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.USER_DETAILS_REQUEST:
			return { loadingUserDetails: true }
		case types.USER_DETAILS_SUCCESS:
			return {
				loadingUserDetails: false,
				successUserDetails: true,
				user: action.payload,
			}
		case types.USER_DETAILS_FAIL:
			return {
				loadingUserDetails: false,
				errorUserDetails: action.payload,
			}
		case types.USER_DETAILS_RESET:
			return {}
		default:
			return state
	}
}

export const userDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.USER_DELETE_REQUEST:
			return { loadingUserDelete: true }
		case types.USER_DELETE_SUCCESS:
			return { loadingUserDelete: false, successUserDelete: true }
		case types.USER_DELETE_FAIL:
			return { loadingUserDelete: false, errorUserDelete: action.payload }
		case types.USER_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const userUpdateReducer = (state = { userUpdated: {} }, action) => {
	switch (action.type) {
		case types.USER_UPDATE_REQUEST:
			return { loadingUserUpdate: true }
		case types.USER_UPDATE_SUCCESS:
			return {
				loadingUserUpdate: false,
				successUserUpdate: true,
				userUpdated: action.payload,
			}
		case types.USER_UPDATE_FAIL:
			return { loadingUserUpdate: false, errorUserUpdate: action.payload }
		case types.USER_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const refreshTokenReducer = (state = {}, action) => {
	switch (action.type) {
		case types.USER_REFRESH_TOKEN_REQUEST:
			return { loadingRefreshToken: true }
		case types.USER_REFRESH_TOKEN_SUCCESS:
			return {
				loadingRefreshToken: false,
				successRefreshToken: true,
			}
		case types.USER_REFRESH_TOKEN_FAIL:
			return {
				loadingRefreshToken: false,
				errorRefreshToken: action.payload,
			}
		case types.USER_REFRESH_TOKEN_RESET:
			return {}
		default:
			return state
	}
}

import axios from 'axios'
import { format } from 'date-fns'
import { Grid, makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CardBody from '../../../../components/Card/CardBody'
import Card from '../../../../components/Card/Card'
import ShowTeachersInfo from '../components/ShowTeacherInfo'
import styles from '../styles/teamsInfoCardStyles'

const useStyles = makeStyles(styles)

const TeamsInfoCard = ({ panel, handleAdd }) => {
	const classes = useStyles()

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<h1>{panel.panelName}</h1>
				<Button color='success' onClick={() => handleAdd(panel)}>
					+ Add Final Project
				</Button>
				{panel.meetRoom && (
					<Button color='primary' style={{ margin: '0 5px' }} onClick={() => window.open(panel.meetRoom)}>
						Go to Team Meet Room
					</Button>
				)}

				{panel.topic && (
					<Grid item className={classes.infoTopic}>
						<h4>
							Start Date {format(new Date(panel.partnersStartDate), 'dd-MM-yyyy')} - End Date{' '}
							{format(new Date(panel.partnersEndDate), 'dd-MM-yyyy')}
						</h4>
						<img alt={panel.panelName} src={`${axios.defaults.baseURL}${panel.topic.image}`} />
						<h2>{panel.topic.name}</h2>
					</Grid>
				)}
			</Grid>
			{panel.partners.map((partner, index) => (
				<Grid item xs={12} md={4} className={classes.partnersCards} key={index}>
					<Card>
						<CardAvatar className={classes.centerLogo} center>
							<img src={`${axios.defaults.baseURL}${partner?._id?.logo}`} alt={partner?._id?.name} />
						</CardAvatar>
						<CardBody profile>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<h4 className={classes.cardTitle}>{partner?._id?.name}</h4>
									<p>Email · {partner?._id?.email}</p>
									<p>Phone · {partner?._id?.phone}</p>
									<span>
										{partner?._id?.country?.name} ·{' '}
										<img
											src={`${axios.defaults.baseURL}${partner?._id?.country?.flag}`}
											className={classes.countryFlagProfile}
											alt={'Flag of ' + partner?._id?.country?.name}
										/>
									</span>
								</Grid>
								<Grid item xs={12}>
									<h5 className={classes.cardTitle}>Teacher Info.</h5>
									<ShowTeachersInfo panel={panel} centerId={partner?._id?._id} />
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</Grid>
			))}
		</Grid>
	)
}

export default TeamsInfoCard

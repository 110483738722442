import axios from 'axios'
import PropTypes from 'prop-types'
import {
  Card,
  CardHeader,
  // Badge,
  Avatar,
  makeStyles,
  Tooltip,
  // withStyles
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import styles from './styles/userListCardStyles'

const useStyles = makeStyles(styles)

// const StyledBadge = withStyles((theme) => ({
//     badge: {
//         backgroundColor: '#44b700',
//         color: '#44b700',
//         boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//         '&::after': {
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             borderRadius: '50%',
//             animation: '$ripple 1.2s infinite ease-in-out',
//             border: '1px solid currentColor',
//             content: '""',
//         },
//     },
//     '@keyframes ripple': {
//         '0%': {
//             transform: 'scale(.8)',
//             opacity: 1,
//         },
//         '100%': {
//             transform: 'scale(2.4)',
//             opacity: 0,
//         },
//     },
// }))(Badge)

const UserListCard = ({ user, email, dropdownList, draggable }) => {
  const classes = useStyles()

  const dragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(id))
  }

  return (
    <div className='user-card' draggable={draggable} onDragStart={(e) => dragStart(e, user)}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeaderRoot}
          avatar={
            // <StyledBadge
            //     overlap='circular'
            //     anchorOrigin={{
            //         vertical: 'bottom',
            //         horizontal: 'right',
            //     }}
            //     variant={user.username === 'carlos' ? 'standard' : 'dot'}
            // >
            <Avatar aria-label='recipe' src={`${axios.defaults.baseURL}${user.avatar}`}></Avatar>
            // </StyledBadge>
          }
          action={
            dropdownList ? (
              <CustomDropdown
                left
                caret={false}
                buttonText={
                  <div className={classes.btnwrapper}>
                    <MoreVert />
                  </div>
                }
                buttonProps={{
                  className: classes.navLink + ' ' + classes.imageDropdownButton,
                  color: 'transparent',
                }}
                dropdownList={[...dropdownList]}
              />
            ) : null
          }
          title={user.name}
          subheader={
            <Tooltip placement='left-start' title={user.googleMail}>
              <span>
                {email
                  ? user.email.length > 21
                    ? user.email.slice(0, 21) + '...'
                    : user.email
                  : user.googleMail?.length > 21
                  ? user.googleMail?.slice(0, 21) + '...'
                  : user.googleMail}
              </span>
            </Tooltip>
          }
        />
      </Card>
    </div>
  )
}

UserListCard.defaultProps = {
  draggable: false,
  email: false,
}

UserListCard.propTypes = {
  user: PropTypes.object.isRequired,
  dropdownList: PropTypes.array,
  draggable: PropTypes.bool,
  email: PropTypes.bool,
}

export default UserListCard

import { makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/userButtonsStyles'

const useStyles = makeStyles(styles)

const UserButtons = ({ keyword, history, setOpen }) => {
  const classes = useStyles()

  return (
    <>
      {keyword && (
        <Button color='info' className={classes.btnResetSearch} onClick={() => history.push('/admin/users')}>
          Reset Search
        </Button>
      )}
      <Button color='success' onClick={() => setOpen(true)}>
        + Create User
      </Button>
    </>
  )
}

export default UserButtons

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AppBar, Toolbar, IconButton, Hidden, Drawer, makeStyles } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import styles from './styles/headerStyle'

const useStyles = makeStyles(styles)

const Header = ({ changeColorOnScroll, color, rightLinks, leftLinks, brand, fixed, absolute }) => {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)

  useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange)
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange)
      }
    }
  })

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const headerTag = document.body.getElementsByTagName('header')[0]
    if (window.pageYOffset > changeColorOnScroll.height) {
      headerTag.classList.remove(classes[color])
      headerTag.classList.add(classes[changeColorOnScroll.color])
    } else {
      headerTag.classList.add(classes[color])
      headerTag.classList.remove(classes[changeColorOnScroll.color])
    }
  }

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  })

  const brandComponent = (
    <>
      <IconButton className={classes.title}>
        <Link to='/'>
          <img
            src='/assets/img/logo-pactoeducativoglobal100t.png'
            alt='Logo Planet Fraternity'
            className={classes.logo}
          />
        </Link>
      </IconButton>
      <IconButton className={classes.title}>
        <Link to='/'>
          <img src='/assets/img/oiec100t.png' alt='Logo OIEC' className={classes.logo} />
        </Link>
      </IconButton>
      <IconButton className={classes.title}>
        <Link to='/'>
          <img src='/assets/img/logo-planetfraternity100t.png' alt='Logo Planet Fraternity' className={classes.logo} />
        </Link>
      </IconButton>
    </>
  )

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden mdDown implementation='css'>
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden mdDown implementation='css'>
          {rightLinks}
        </Hidden>
        <Hidden lgUp>
          <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
            <Menu className={classes.iconMenu} />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden lgUp implementation='js'>
        <Drawer
          variant='temporary'
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  )
}

Header.defaultProp = {
  color: 'white',
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
      .isRequired,
  }),
}

export default Header

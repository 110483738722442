const styles = (theme) => ({
	root: {
		width: '100%',
		marginBottom: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	cardHeaderRoot: {
		width: '100%',
		padding: 10,
		paddingLeft: 0,
		'& .MuiCardHeader-avatar': {
			marginRight: 10,
		},
		'& .MuiCardHeader-content': {
			overflow: 'hidden',
		},
		'& .MuiCardHeader-action': {
			margin: 0,
		},
	},
	btnwrapper: {
		padding: 10,
		'& svg': {
			margin: 0,
		},
	},
	navLink: {
		color: '#757575 !important',
		borderRadius: '33px',
		lineHeight: '20px',
		textDecoration: 'none',
		margin: 0,
		display: 'inline-flex',
		'& svg': {
			width: 22,
			height: 22,
		},
		'&:hover,&:focus': {
			color: 'inherit',
			background: 'rgba(200, 200, 200, 0.2)',
		},
	},
	imageDropdownButton: {
		padding: '0px',
		borderRadius: '50%',
		backgroundColor: '#f3f3f3',
	},
	title: {
		maxHeight: '30px',
		overflow: 'hidden',
		fontSize: '14px',
		fontWeight: 'bold',
		marginBottom: '2.5px !important',
	},
	desc: {
		maxHeight: '18px',
		fontSize: '14px',
		overflow: 'hidden',
		margin: '0 !important',
	},
	imagePreview: {
		minWidth: '45px',
		width: '45px',
		height: '45px',
		background: '#f1f1f1',
		margin: '0 10px',
		borderRadius: '50%',
		backgroundSize: '',
		overflow: 'hidden',
	},
	img: {
		width: '45px',
		objectFit: 'contain',
		height: '100%',
	},
})

export default styles

import axios from 'axios'
import { makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import GridItem from '../../../../components/Grid/GridItem'
import styles from '../styles/topicListStyles'

const useStyles = makeStyles(styles)

const TopicList = ({ topics, history }) => {
  const classes = useStyles()

  return topics.map((item) => (
    <GridItem xs={12} sm={6} md={4} lg={3} xl={2} key={item._id}>
      <Button block link onClick={() => history.push(`/final-projects/${item._id}`)}>
        <img
          src={`${axios.defaults.baseURL}${item.image}`}
          className={classes.topicsIcons}
          alt='Planet Fraternity Icon'
          loading='lazy'
        />
      </Button>
    </GridItem>
  ))
}

export default TopicList

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL'
export const COUNTRY_LIST_RESET = 'COUNTRY_LIST_RESET'

export const COUNTRY_ALL_REQUEST = 'COUNTRY_ALL_REQUEST'
export const COUNTRY_ALL_SUCCESS = 'COUNTRY_ALL_SUCCESS'
export const COUNTRY_ALL_FAIL = 'COUNTRY_ALL_FAIL'
export const COUNTRY_ALL_RESET = 'COUNTRY_ALL_RESET'

export const COUNTRY_DETAILS_REQUEST = 'COUNTRY_DETAILS_REQUEST'
export const COUNTRY_DETAILS_SUCCESS = 'COUNTRY_DETAILS_SUCCESS'
export const COUNTRY_DETAILS_FAIL = 'COUNTRY_DETAILS_FAIL'
export const COUNTRY_DETAILS_RESET = 'COUNTRY_DETAILS_RESET'

import { useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { BrokenImage } from '@material-ui/icons'
import Loader from '../../../../components/Loader/Loader'
import Button from '../../../../components/Buttons/Button'
import TeamCard from './TeamCard'
import styles from '../styles/teamsByAgesRightSideStyles'

const useStyles = makeStyles(styles)

const TeamsByAgesRightSide = ({ groups, setGroups, setOpenConfirm }) => {
  const classes = useStyles()

  const { loadingTeamsByAgePrev, teamsPrev } = useSelector((state) => state.teamsByAgePrev)

  return (
    <>
      {loadingTeamsByAgePrev ? (
        <Loader />
      ) : !teamsPrev ? (
        <div className={classes.rightSection}>
          <BrokenImage className={classes.schoolIcon} />
          <h2>Get a team preview to join groups.</h2>
        </div>
      ) : (
        teamsPrev && (
          <Grid container spacing={2} className={classes.teamsRoot}>
            <Grid item xs={12}>
              <h2>Groups with age range {teamsPrev.ageRange}</h2>
              <p>This is the most accurate teams preview found.</p>
              <p>
                If there is a Team with a single center, you must move that center by dragging and dropping into other
                team.
              </p>
            </Grid>
            {groups.map((team, teamIndex) => (
              <TeamCard key={teamIndex} team={team} groups={groups} setGroups={setGroups} />
            ))}
            <Grid item xs={12}>
              <Button
                color='primary'
                onClick={() => setOpenConfirm(true)}
                disabled={teamsPrev.teams[0].groups.length === 1}
              >
                Teams Automation
              </Button>
            </Grid>
          </Grid>
        )
      )}
    </>
  )
}

export default TeamsByAgesRightSide

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import classNames from 'classnames'
import Flippy, { FrontSide, BackSide } from 'react-flippy'
import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { Favorite } from '@material-ui/icons'
import CustomModal from '../../../../components/Modal/CustomModal'
import CustomButton from '../../../../components/Buttons/Button'
import { teamUpdateWinnerFinalProject } from '../../../../redux/actions/teamsActions'
import styles from '../styles/finalProjectCardStyles'

const useStyles = makeStyles(styles)

const FinalProjectCard = ({ team, userInfo }) => {
	const classes = useStyles()
	const { t } = useTranslation('global')
	const dispatch = useDispatch()

	const [video, setVideo] = useState('')
	const [imageFilter, setImageFilter] = useState('')
	const [open, setOpen] = useState(false)
	const [openVideo, setOpenVideo] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [images, setImages] = useState([])
	const [dataTeam, setDataTeam] = useState(null)

	const { successTeamWinnerProjectUpdate, teamUpdateVoteFinalProject } = useSelector(
		(state) => state.teamUpdateVoteProject,
	)
	useEffect(() => {
		if (team) {
			team.projects.map((project) => {
				if (project.vote) {
					const voteId = project.vote.map((vote) => vote._id)
					const data = voteId.includes(userInfo._id)
					if (data) {
						project.voteByUser = true
					} else {
						project.voteByUser = false
					}
				}
				return project
			})
			const teamData = {
				...team,
				projects: team.projects,
			}
			setDataTeam(teamData)
		}
	}, [team, userInfo])

	useEffect(() => {
		if (successTeamWinnerProjectUpdate) {
			setDataTeam(teamUpdateVoteFinalProject)
			teamUpdateVoteFinalProject.projects.map((project) => {
				if (project.vote) {
					const voteId = project.vote.map((vote) => vote._id)
					const data = voteId.includes(userInfo._id)
					if (data) {
						project.voteByUser = true
					} else {
						project.voteByUser = false
					}
				}
				return project
			})
		}
	}, [successTeamWinnerProjectUpdate, teamUpdateVoteFinalProject, userInfo])

	useEffect(() => {
		if (!openVideo) {
			setVideo('')
		}
	}, [openVideo, setVideo])

	const toggleIsOpen = (e) => {
		if (e.target.src) setImageFilter(e.target.src)
		setIsOpen(!isOpen)
	}

	const handleVoteProject = (project, teamId) => {
		dispatch(teamUpdateWinnerFinalProject(teamId, project._id))
	}

	return (
		<>
			<h3>Final Projects of {team.panelName}</h3>
			<Grid container spacing={3}>
				{dataTeam &&
					dataTeam.projects.length > 0 &&
					dataTeam.projects.map((project) => (
						<Grid item md={3} sm={6} key={project._id} className={classNames(classes.itemRoot)}>
							<Card>
								<Flippy
									flipOnHover={false} // default false
									flipOnClick={true} // default false
									flipDirection='horizontal' // horizontal or vertical
								>
									<CardActionArea>
										<FrontSide className={classes.flipCard}>
											<CardMedia
												component='img'
												height='200'
												image={
													project.images[0]?.url
														? `${axios.defaults.baseURL}/${project.images[0]?.url}`
														: '/assets/img/public-site/register-center-tutorial-poster.png'
												}
												alt='planetfraternity'
											/>
											<CardContent>
												<Typography gutterBottom variant='h5' component='div'>
													{project.group.name.length > 20
														? project.group.name.slice(0, 20) + '...'
														: project.group.name}
												</Typography>
												<hr />
												<Typography variant='body2' color='textSecondary'>
													{team.panelName}
												</Typography>
											</CardContent>
										</FrontSide>
										<BackSide className={classes.flipCard}>
											<Grid container>
												<Grid item md={12} sm={12} xs={12}>
													<h2>Students Group</h2>
												</Grid>
												{project?.group?._id?.students?.map((student, index) => (
													<Grid key={index} item md={4} sm={4} xs={4}>
														<p>{student.name}</p>
													</Grid>
												))}
											</Grid>
										</BackSide>
									</CardActionArea>
								</Flippy>
								<CardActions>
									<Grid container>
										<Grid item md={4} sm={4} xs={4}>
											{project?.images?.length > 0 && (
												<Button size='small' color='primary' onClick={() => [setOpen(true), setImages(project.images)]}>
													{t('layout.buttons.t_view-images')}
												</Button>
											)}
										</Grid>
										<Grid item md={4} sm={4} xs={4}>
											{project?.video?.url && project.video.url.length > 5 && (
												<Button
													size='small'
													color='primary'
													onClick={() => [setOpenVideo(true), setVideo(project.video?.url)]}
												>
													{t('layout.buttons.t_view-video')}
												</Button>
											)}
										</Grid>
										<Grid item md={4} sm={4} xs={4} className={classes.actionLike}>
											<Favorite
												onClick={() => handleVoteProject(project, team._id)}
												className={project.voteByUser ? classes.voteProject : classes.notVoteProject}
											/>
											<p>{project.vote ? project.vote.length : 0} Like</p>
										</Grid>
										{project?.file && project.file.url.length > 5 && (
											<Grid item md={12}>
												<hr />
												<CustomButton
													block
													color='primary'
													onClick={() => window.open(`${axios.defaults.baseURL}/${project?.file?.url}`)}
												>
													View Resource
												</CustomButton>
											</Grid>
										)}
									</Grid>
								</CardActions>
							</Card>
						</Grid>
					))}
			</Grid>
			<CustomModal title='Project images' open={open} setOpen={setOpen} actions={false}>
				<Grid container className={classes.mainRoot}>
					{images && images.length > 0 ? (
						images.map((image) => (
							<Grid item xs={12} md={6} key={image._id}>
								<div onClick={toggleIsOpen} className={classes.sectionImages}>
									<img src={`${axios.defaults.baseURL}/${image.url}`} alt={image.url} />
									{isOpen && imageFilter ? (
										<div className={classes.lightBooxRoot} onClick={toggleIsOpen}>
											<img className={classes.imageFilter} src={imageFilter} alt={imageFilter} />
										</div>
									) : null}
								</div>
							</Grid>
						))
					) : (
						<h3>Not Images Sources</h3>
					)}
				</Grid>
			</CustomModal>
			<CustomModal title='Video Final' open={openVideo} setOpen={setOpenVideo} actions={false}>
				<Grid container className={classes.mainRoot}>
					{video && (
						<Grid item xs={12} ms={12} md={12}>
							<video controls>
								<source src={`${axios.defaults.baseURL}/${video}`} type='video/mp4' />
							</video>
						</Grid>
					)}
				</Grid>
			</CustomModal>
		</>
	)
}

export default FinalProjectCard

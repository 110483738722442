import { Helmet } from 'react-helmet'

const Meta = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title} - Planet Fraternity</title>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta name='description' content={description} />
    </Helmet>
  )
}
Meta.defaultProps = {
  title: 'Planet Fraternity',
  description:
    'The best way to learn how to create lasting bonds and friendships with students from all over the world.',
}

export default Meta

import Meta from '../../../components/Meta/Meta'

const WritingScreen = () => {
	return (
		<div>
			<Meta title={'Writing Participate - Planet Fraternity'} />
			<h1>WritingScreen</h1>
		</div>
	)
}

export default WritingScreen

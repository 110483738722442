import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'

const TeamsManageAutomateGroupModal = ({ automateGroupsHandler, setGroupsQty, groupsQty }) => {
  const { loadingTeamGroupAutoRegister, errorTeamGroupAutoRegister } = useSelector(
    (state) => state.teamGroupAutoRegister
  )

  return (
    <>
      <p>You can only automate the groups creation once.</p>
      <p>Please only automate suggested groups qty</p>
      <CustomInput
        id='groups-qty'
        inputProps={{
          autoFocus: true,
          type: 'number',
          value: groupsQty,
          placeholder: 'Write numbers only',
          autoComplete: 'off',
          onChange: (e) => setGroupsQty(e.target.value),
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      {errorTeamGroupAutoRegister && <Message message={<span>{errorTeamGroupAutoRegister}</span>} color='info' />}
      <Button color='primary' block onClick={automateGroupsHandler}>
        {loadingTeamGroupAutoRegister ? <Loader /> : 'Automate Groups'}
      </Button>
    </>
  )
}

export default TeamsManageAutomateGroupModal

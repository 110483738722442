import Meta from '../../../components/Meta/Meta'

const ReadingScreen = () => {
	return (
		<div>
			<Meta title={'Reading - Planet Fraternity'} />
			<h1>ReadingScreen</h1>
		</div>
	)
}

export default ReadingScreen

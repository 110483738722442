const styles = {
  partnersCards: {
    marginTop: '50px',
  },
  centerLogo: {
    width: '70px',
    height: '70px',
    '& img': {
      width: '100%',
    },
  },
  cardTitle: {
    marginBottom: 15,
    textAlign: 'center',
  },
  countryFlagProfile: {
    width: '28px',
    borderRadius: '5px',
  },
  partnerGroupId: {
    marginBottom: '5px',
  },
  partnerGroup: {
    backgroundColor: '#eaeaea',
  },
  studentCardsArea: {
    height: '50vh',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
      borderRadius: '10px',
    },
  },
}

export default styles

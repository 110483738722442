import axios from 'axios'
import {
	CENTER_REGISTER_REQUEST,
	CENTER_REGISTER_SUCCESS,
	CENTER_REGISTER_FAIL,
	CENTER_DETAILS_REQUEST,
	CENTER_DETAILS_SUCCESS,
	CENTER_DETAILS_FAIL,
	CENTER_LIST_REQUEST,
	CENTER_LIST_SUCCESS,
	CENTER_LIST_FAIL,
	CENTER_NOTPARTNERED_REQUEST,
	CENTER_NOTPARTNERED_SUCCESS,
	CENTER_NOTPARTNERED_FAIL,
	CENTER_SEND_FRIENDREQ_REQUEST,
	CENTER_SEND_FRIENDREQ_SUCCESS,
	CENTER_SEND_FRIENDREQ_FAIL,
	CENTER_DELETE_REQUEST,
	CENTER_DELETE_SUCCESS,
	CENTER_DELETE_FAIL,
	CENTER_UPDATE_REQUEST,
	CENTER_UPDATE_SUCCESS,
	CENTER_UPDATE_FAIL,
	CENTER_PARTNERS_REQUEST,
	CENTER_PARTNERS_SUCCESS,
	CENTER_PARTNERS_FAIL,
	CENTER_ACTIVATE_REQUEST,
	CENTER_ACTIVATE_SUCCESS,
	CENTER_ACTIVATE_FAIL,
	CENTER_GROUPS_REQUEST,
	CENTER_GROUPS_SUCCESS,
	CENTER_GROUPS_FAIL,
	CENTER_BY_COUNTRY_REQUEST,
	CENTER_BY_COUNTRY_SUCCESS,
	CENTER_BY_COUNTRY_FAIL,
} from '../constants/centerConstants'
import { logout } from './userActions'

export const registerCenter = (center) => async (dispatch) => {
	try {
		dispatch({ type: CENTER_REGISTER_REQUEST })

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post('/api/centers', center, config)

		dispatch({ type: CENTER_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: CENTER_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getCenterGroups = (centerId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CENTER_GROUPS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/centers/get-groups/${centerId}`, config)

		dispatch({ type: CENTER_GROUPS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: CENTER_GROUPS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const activateCenterSubscription = (centerId, centerGroupsList) => async (dispatch, getState) => {
	try {
		dispatch({ type: CENTER_ACTIVATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(`/api/centers/activate/${centerId}`, centerGroupsList, config)

		dispatch({ type: CENTER_ACTIVATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: CENTER_ACTIVATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getCentersByCountry = () => async (dispatch) => {
	try {
		dispatch({ type: CENTER_BY_COUNTRY_REQUEST })

		const { data } = await axios.get('/api/centers/country')

		dispatch({ type: CENTER_BY_COUNTRY_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: CENTER_BY_COUNTRY_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getCenterById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: CENTER_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/centers/${id}`, config)

		dispatch({ type: CENTER_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
			window.location = '/auth/login'
		}
		dispatch({
			type: CENTER_DETAILS_FAIL,
			payload: message,
		})
	}
}

export const getCenters =
	(keyword = '', count, start, pending = '') =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: CENTER_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(
					`/api/centers?count=${count}&start=${start}&keyword=${keyword}&pending=${pending}`,
					config,
				)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/centers?count=${count}&start=${start}&pending=${pending}`, config)
				dataResponse = data
			}

			dispatch({ type: CENTER_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: CENTER_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getCentersNotPartnered =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: CENTER_NOTPARTNERED_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(
					`/api/centers/notpartnered?count=${count}&start=${start}&keyword=${keyword}`,
					config,
				)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/centers/notpartnered?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: CENTER_NOTPARTNERED_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: CENTER_NOTPARTNERED_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getCentersPartners = (partners) => async (dispatch, getState) => {
	try {
		dispatch({ type: CENTER_PARTNERS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/centers/partners', partners, config)

		dispatch({ type: CENTER_PARTNERS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: CENTER_PARTNERS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const centerUpdateInfo = (center) => async (dispatch, getState) => {
	try {
		dispatch({ type: CENTER_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/centers/${center._id}`, center, config)

		dispatch({ type: CENTER_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: CENTER_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteCenter = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: CENTER_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/centers/${id}`, config)

		dispatch({ type: CENTER_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: CENTER_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const centerSendFriendRequest =
	(receiver, senderId, senderName, senderCountry) => async (dispatch, getState) => {
		try {
			dispatch({ type: CENTER_SEND_FRIENDREQ_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.put(
				'/api/centers/send-friend-request',
				{ receiver, senderId, senderName, senderCountry },
				config,
			)

			dispatch({ type: CENTER_SEND_FRIENDREQ_SUCCESS, payload: data })
		} catch (error) {
			dispatch({
				type: CENTER_SEND_FRIENDREQ_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/teamsButtonStyles'

const useStyles = makeStyles(styles)

const TeamsButton = ({ keyword }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      {keyword && (
        <Button color='info' className={classes.btnResetSearch} onClick={() => history.push('/admin/teams')}>
          Reset Search
        </Button>
      )}
      {/* <Button color='success' onClick={() => setOpen(true)}>
            + Create Team
        </Button> */}
    </>
  )
}

export default TeamsButton

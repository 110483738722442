import { main, mainRaised } from '../../../../assets/jss/material-ui-styles'

const styles = {
  main,
  mainRaised,
  cardBodyRoot: {
    marginTop: '10px',
  },
  cardTitle: {
    textAlign: 'center',
    margin: '0 0 25px',
  },
  cardSubTitle: {
    color: '#65676b',
    fontSize: 17,
  },
  subtitleCapital: {
    textTransform: 'capitalize',
  },
  customTabsRoot: {
    height: '100% !important',
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%) !important',
  },
  infoRoot: {
    paddingTop: '10px',
  },
  countryFlagProfile: {
    width: '35px',
    borderRadius: '5px',
  },
  basicInfo: {
    border: '2px solid #e5e5e5',
    borderRadius: '16px',
    width: '100%',
    height: '100%',
    padding: '20px',
    marginBottom: '10px',
    color: '#65676b',
    fontSize: 16,
    '& h5': {
      textAlign: 'center',
    },
    '& div': {
      padding: '5px 0px',
    },
  },
  surveySection: {
    border: '2px solid #e5e5e5',
    borderRadius: '16px',
    width: '100%',
    height: '100%',
    padding: '20px',
    color: '#65676b',
    fontSize: 16,
    '& h5': {
      textAlign: 'center',
      paddingBottom: '10px',
    },
  },
  countryFlag: {
    width: '30px',
    borderRadius: '3px',
  },
  titleQuestion: {
    fontSize: '12px',
  },
  '@media screen and (max-width: 480px)': {
    cardRoot: {
      padding: '20px 0',
    },
    cardBodyRoot: {
      padding: 0,
    },
    customTabsRoot: {
      boxShadow: 'none !important',
    },
  },
}

export default styles

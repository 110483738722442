const styles = {
  teamCard: {
    padding: 5,
    marginBottom: 10,
    '& h4': {
      fontSize: 19,
      textAlign: 'center',
    },
  },
  groupCard: {
    padding: 5,
    margin: 5,
    textAlign: 'center',
  },
  groupDanger: {
    color: 'red',
  },
  dropArea: {
    borderRadius: '5px',
    border: '2px solid #e6e8e6',
    backgroundColor: '#e6e8e6',
    padding: '0 6px',
    textAlign: 'center',
  },
  existMatch: {
    border: '2px solid red'
  }
}

export default styles

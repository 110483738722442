const styles = {
  root: {
    padding: 10,
    '& h1': {
      textAlign: 'center',
      fontSize: '26px',
      marginBottom: '60px',
    },
  },
  rootContainer: {
    margin: 0,
  },
  spanMessage: {
    margin: 'auto',
  },
}

export default styles

const styles = {
	root: {
		justifyContent: 'center',
		width: '100%',
		height: '500px',
	},
	sectionTitle: {
		padding: '10px 0px',
		color: '#483d3d',
		fontSize: '22px',
	},
	articleItem: {
		margin: '25px 0px',
		minWidth: '90%',
	},
	cardMedia: {
		objectFit: 'cover',
		minHeight: '400px',
	},
	textContent: {
		whiteSpace: 'pre-line',
		textAlign: 'start',
	},
}

export default styles

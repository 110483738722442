import { useSelector } from 'react-redux'
import axios from 'axios'
import { Card, Grid, makeStyles, Tooltip, Link, IconButton } from '@material-ui/core'
import { Close, PlayCircleFilled } from '@material-ui/icons'
import CardBody from '../../../../components/Card/CardBody'
import styles from '../styles/resourceCardStyles'

const useStyles = makeStyles(styles)

const ResourceCard = ({ informations, category, setResource, setOpenDelete }) => {
  const classes = useStyles()

  const { userInfo } = useSelector((state) => state.userLogin)

  return (
    <>
      <Card className={classes.cardContent}>
        <CardBody>
          <h2 style={{ textAlign: 'center' }}>{category}</h2>
          <Grid container>
            <Grid item xs={12}>
              {informations &&
                informations.map((resource) => (
                  <div key={resource._id}>
                    {resource.category === category && (
                      <div key={resource._id} className={classes.notificationRoot}>
                        <PlayCircleFilled />
                        <Tooltip title={resource.title} placement='right'>
                          <Link
                            component='button'
                            variant='body2'
                            underline='always'
                            onClick={() => window.open(`${axios.defaults.baseURL}/${resource.link}`)}
                          >
                            {resource.title.length > 45 ? resource.title.slice(0, 45) + '...' : resource.title}
                          </Link>
                        </Tooltip>
                        {userInfo && userInfo.role === 'teacher' && (userInfo.isAdmin || userInfo.isSuper) && (
                          <IconButton
                            aria-label='close'
                            className={classes.closeIcon}
                            onClick={() => [setOpenDelete(true), setResource(resource)]}
                          >
                            <Close fontSize='small' />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </>
  )
}

export default ResourceCard

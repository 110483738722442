import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '@material-ui/core'

import buttonStyle from './styles/buttonStyles'
import { useCallback } from 'react'

const makeComponentStyles = makeStyles(buttonStyle)

const RegularButton = forwardRef((props, ref) => {
	const {
		color,
		round,
		children,
		fullWidth,
		disabled,
		simple,
		size,
		block,
		link,
		justIcon,
		clickRef,
		className,
		...rest
	} = props

	const classes = makeComponentStyles()

	const clickRefFunction = useCallback(() => {
		const element = document.getElementById(clickRef)
		element.click()
	}, [clickRef])

	const btnClasses = classNames({
		[classes.button]: true,
		[classes[size]]: size,
		[classes[color]]: color,
		[classes.round]: round,
		[classes.fullWidth]: fullWidth,
		[classes.disabled]: disabled,
		[classes.simple]: simple,
		[classes.block]: block,
		[classes.link]: link,
		[classes.justIcon]: justIcon,
		[className]: className,
	})
	return (
		<Button onClick={clickRef ? clickRefFunction : props.onClick} {...rest} ref={ref} className={btnClasses}>
			{children}
		</Button>
	)
})

RegularButton.propTypes = {
	color: PropTypes.oneOf([
		'primary',
		'info',
		'light',
		'success',
		'warning',
		'danger',
		'rose',
		'white',
		'facebook',
		'twitter',
		'google',
		'github',
		'transparent',
	]),
	size: PropTypes.oneOf(['sm', 'lg']),
	simple: PropTypes.bool,
	round: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	block: PropTypes.bool,
	link: PropTypes.bool,
	justIcon: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
}

export default RegularButton

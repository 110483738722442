const styles = {
  sectionRoot: {
    marginTop: '20px',
  },
  infoSection: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    '& img': {
      height: '50px',
      width: '50px',
    },
  },
}

export default styles

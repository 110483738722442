import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerWritingContest } from '../../../../redux/actions/writingContestActions'
import styles from '../styles/writingContestRegisterFormStyles'

const useStyles = makeStyles(styles)

const WritingContestRegisterForm = ({ setOpen, setWritingDetails }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [question, setQuestion] = useState('')
  const [contestDate, setContestDate] = useState(new Date())

  const { loadingWritingCRegister, successWritingCRegister, errorWritingCRegister, writingContest } = useSelector(
    (state) => state.writingCRegister
  )

  useEffect(() => {
    if (successWritingCRegister) {
      setWritingDetails(writingContest)
      setOpen(false)
      setTitle('')
      setQuestion('')
      setContestDate(new Date())
    }
  }, [successWritingCRegister, setOpen, writingContest, setWritingDetails])

  const submitHandler = (e) => {
    e.preventDefault()

    const writingContestCreate = {
      title,
      question,
      contestDate,
    }
    dispatch(registerWritingContest(writingContestCreate))
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <form autoComplete='off' onSubmit={submitHandler}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomInput
                labelText='Title'
                id='title'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  value: title,
                  placeholder: 'Enter Writing Contest title',
                  onChange: (e) => setTitle(e.target.value),
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                labelText='Question'
                id='question'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  value: question,
                  placeholder: 'Enter Writing Contest Question',
                  onChange: (e) => setQuestion(e.target.value),
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                openTo='month'
                views={['year', 'month']}
                label='Contest Date'
                format='MMMM yyyy'
                value={contestDate}
                onChange={setContestDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' color='primary' block>
                {loadingWritingCRegister ? <Loader size={'sm'} /> : 'Submit'}
              </Button>
            </Grid>
            {errorWritingCRegister && (
              <Grid item xs={12}>
                <Message message={<span>{errorWritingCRegister}</span>} color='info' />
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default WritingContestRegisterForm

const styles = {
    cardRoot: {
        paddingTop: '40px !important',
    },
    tabsRoot: {
        height: '100% !important',
    },
}

export default styles

import axios from 'axios'
import { Grid, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardAvatar from '../../../../components/Card/CardAvatar'
import styles from '../styles/teamsManageCenterParnertsCardsStyles.js'
import CardBody from '../../../../components/Card/CardBody'
import { ExpandMore } from '@material-ui/icons'
import UserListCard from '../../../../components/Users/UserListCard/UserListCard'

const useStyles = makeStyles(styles)

const TeamsManageCenterParnertsCards = ({ teamgroups, index, partner, getUserName }) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} md={6} className={classes.partnersCards} key={index}>
        <Card>
          <CardAvatar className={classes.centerLogo} center>
            <img src={`${axios.defaults.baseURL}${partner.logo}`} alt={partner.name} />
          </CardAvatar>
          <CardBody profile>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h4 className={classes.cardTitle}>{partner.name}</h4>
                <p>
                  Email · <strong>{partner.email}</strong>
                </p>
                <p>
                  Phone · <strong>{partner.phone}</strong>
                </p>
                <span>
                  {partner.country.name} ·{' '}
                  <img
                    src={`${axios.defaults.baseURL}${partner.country.flag}`}
                    className={classes.countryFlagProfile}
                    alt={'Flag of ' + partner.country.name}
                  />
                </span>
              </Grid>
              <Grid item xs={12}>
                <h5 className={classes.cardTitle}>Teacher Info.</h5>
                <p>
                  Email · <strong>{partner?.teachers[0]?.email}</strong>
                </p>
                <p>
                  Phone · <strong>{partner?.teachers[0]?.phone}</strong>
                </p>
              </Grid>
              <Grid item xs={12}>
                {partner.groups.map((group) => (
                  <div key={group._id} className={classes.partnerGroupId}>
                    <Accordion className={classes.partnerGroup}>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
                        <h5>
                          {group.name} - Students: {group.students.length} - Ages: {group.ageRange}
                        </h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12}>
                            <div className={classes.studentCardsArea}>
                              {group.students.map((user) => {
                                if (teamgroups && teamgroups.length > 0) {
                                  let newArray = teamgroups.map((group) => {
                                    return group.students.some((student) => student._id === user._id)
                                  })

                                  if (!newArray.some((el) => el === true)) {
                                    return (
                                      <UserListCard
                                        key={user._id}
                                        user={getUserName(user._id)}
                                        draggable
                                        dropdownList={[<div>Some Action</div>]}
                                      />
                                    )
                                  }
                                } else {
                                  return (
                                    <UserListCard
                                      key={user._id}
                                      user={getUserName(user._id)}
                                      draggable
                                      dropdownList={[<div>Some Action</div>]}
                                    />
                                  )
                                }
                                return true
                              })}
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </>
  )
}

export default TeamsManageCenterParnertsCards

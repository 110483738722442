const styles = (theme) => ({
	root: {
		width: '100%',
		marginBottom: 10,
	},
	contentRoot: {
		padding: '0 16px 11px !important',
		'& p': {
			margin: 0,
			color: '#65676b',
			border: '2px solid transparent',
		},
	},
	teacherDropArea: {
		borderRadius: '5px',
		backgroundColor: '#e6e8e6',
		padding: '0 6px',
		textAlign: 'center',
	},
	noTeacherAssigned: {
		margin: '10px 0',
	},
	btnwrapper: {
		padding: 10,
		'& svg': {
			margin: 0,
		},
	},
	navLink: {
		color: '#62848c !important',
		position: 'relative',
		padding: '0.9375rem',
		fontWeight: '600',
		fontSize: '14px',
		textTransform: 'uppercase',
		borderRadius: '33px',
		lineHeight: '20px',
		textDecoration: 'none',
		margin: '0 7px',
		display: 'inline-flex',
		'& svg': {
			width: 22,
			height: 22,
		},
		'&:hover,&:focus': {
			color: 'inherit',
			background: 'rgba(200, 200, 200, 0.2)',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100% - 30px)',
			marginLeft: '15px',
			marginBottom: '8px',
			marginTop: '8px',
			textAlign: 'left',
			'& > span:first-child': {
				justifyContent: 'flex-start',
			},
		},
	},
	imageDropdownButton: {
		backgroundColor: '#f3f3ff',
		padding: '0px',
		borderRadius: '50%',
		marginLeft: '25px',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: 'white',
		border: '1px solid grey',
		borderRadius: '5px',
		boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
		padding: '20px',
	},
	contentInput: {
		minWidth: '300px',
	},
	subtitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	badgeNew: {
		backgroundColor: '#3ab938',
		padding: '0.25em 0.4em',
		fontWeight: '700',
		borderRadius: '0.25rem',
		color: '#fff',
		lineHeight: 1,
		boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%)',
		marginLeft: 10,
	},
})

export default styles

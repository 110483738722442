import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { IconButton, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import styles from './styles/searchBoxStyles'

const useStyles = makeStyles(styles)

const SearchBox = () => {
  const { keyword } = useParams()
  const history = useHistory()
  const currPath = window.location.pathname
  const classes = useStyles()

  const [searchBoxDisabled, setSearchBoxDisabled] = useState(true)
  const [urlKeyword, setUrlKeyword] = useState('')

  useEffect(() => {
    if (keyword) {
      setUrlKeyword(keyword)
    }
    if (
      currPath === '/admin/users' ||
      currPath.startsWith('/admin/users/') ||
      currPath === '/admin/teachers' ||
      currPath.startsWith('/admin/teachers/') ||
      currPath === '/admin/students' ||
      currPath.startsWith('/admin/students/') ||
      currPath === '/admin/centers' ||
      currPath.startsWith('/admin/centers/') ||
      currPath === '/admin/magazine' ||
      currPath.startsWith('/admin/magazine/') ||
      currPath === '/admin/teams' ||
      currPath.startsWith('/admin/teams/')
    ) {
      setSearchBoxDisabled(false)
    } else {
      setSearchBoxDisabled(true)
    }
  }, [currPath, keyword])

  const submitHandler = (e) => {
    e.preventDefault()
    if (currPath === '/admin/users' || currPath.startsWith('/admin/users/')) {
      history.push(`/admin/users/${urlKeyword.trim()}`)
    } else if (currPath === '/admin/teachers' || currPath.startsWith('/admin/teachers/')) {
      history.push(`/admin/teachers/${urlKeyword.trim()}`)
    } else if (currPath === '/admin/students' || currPath.startsWith('/admin/students/')) {
      history.push(`/admin/students/${urlKeyword.trim()}`)
    } else if (currPath === '/admin/centers' || currPath.startsWith('/admin/centers/')) {
      history.push(`/admin/centers/${urlKeyword.trim()}`)
    } else if (currPath === '/admin/magazine' || currPath.startsWith('/admin/magazine/')) {
      history.push(`/admin/magazine/${urlKeyword.trim()}`)
    } else if (currPath === '/admin/teams' || currPath.startsWith('/admin/teams/')) {
      history.push(`/admin/teams/${urlKeyword.trim()}`)
    } else {
      alert('Error on Search Box')
    }
  }

  return (
    <form onSubmit={submitHandler} className={classes.formRoot}>
      <input
        type='text'
        value={urlKeyword}
        onChange={(e) => setUrlKeyword(e.target.value)}
        placeholder={searchBoxDisabled ? 'Searchbox disabled for this screen' : 'Enter keywords'}
        disabled={searchBoxDisabled}
        required
      />
      <div className={classes.searchIcon}>
        <IconButton type='submit' size='small' disabled={searchBoxDisabled}>
          <Search />
        </IconButton>
      </div>
    </form>
  )
}

export default SearchBox

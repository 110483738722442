const styles = {
  root: {
    padding: 20,
  },
  teamsRoot: {
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& h2': {
      textAlign: 'center',
    },
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& h2': {
      textAlign: 'center',
    },
    '& img': {
      width: '150px',
      borderRadius: '6px',
    },
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  textField: {
    marginTop: '15px',
    width: '100%',
  },
  resize: {
    fontSize: 14,
  },
}

export default styles

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Badge } from '@material-ui/core'
import { PermMedia, VideoLibrary, Close, FileCopy } from '@material-ui/icons'
import { dataURItoBlob } from '../../../../utils/getDataUriToBlob'
import Button from '../../../../components/Buttons/Button'
import Message from '../../../../components/Message/Message'
import { teamCreateFinalProject } from '../../../../redux/actions/teamsActions'
import { TEAM_PROJECT_RESET } from '../../../../redux/constants/teamConstants'
import styles from '../styles/createFinalProjectStyles'

const useStyles = makeStyles(styles)

const CreateFinalProject = ({ project, group, setGroup, setOpen, teamGroups }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [error, setError] = useState('')
	const [videoFile, setVideoFile] = useState(null)
	const [applicationFile, setApplicationFile] = useState(null)
	const [imagesFile, setImagesFile] = useState([])
	const [imagesUrl, setImagesUrl] = useState([])

	const { successTeamProject, errorTeamProject, loadingTeamProject } = useSelector((state) => state.teamProject)
	const { userInfo } = useSelector((state) => state.userLogin)

	const videoButtonClasses = classNames({
		[classes.infoLabel]: classes.infoLabel,
		[classes.infoLabel2]: videoFile && classes.infoLabel2,
	})
	const imageButtonClasses = classNames({
		[classes.infoLabel]: classes.infoLabel,
		[classes.infoLabel2]: imagesFile.length >= 10 && classes.infoLabel2,
	})
	const fileButtonClasses = classNames({
		[classes.infoLabel]: classes.infoLabel,
		[classes.infoLabel2]: applicationFile && classes.infoLabel2,
	})

	useEffect(() => {
		if (userInfo) {
			if (successTeamProject) {
				setGroup('')
				setImagesFile([])
				setVideoFile(null)
				setApplicationFile(null)
				setImagesUrl([])
				setError('')
				dispatch({ type: TEAM_PROJECT_RESET })
				setOpen(false)
			}
		}
	}, [successTeamProject, userInfo, setGroup, setOpen, dispatch])

	const handleVideoChange = (e) => {
		setVideoFile(e.target.files[0])
		setError('')
	}
	const handleFileChange = (e) => {
		setApplicationFile(e.target.files[0])
		setError('')
	}
	const handleFileRemove = (e) => {
		setApplicationFile(null)
	}
	const handleImageChange = (e) => {
		const fileNameExist = imagesFile.find((item) => item.name === [...e.target.files][0].name)
		if (fileNameExist) {
			setError('You cannot upload the same file twice.')
			return false
		} else {
			setError('')
		}
		const data = [...e.target.files]
		data.map((image) => {
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const reader = new FileReader()
			const img = new Image()
			img.onload = () => {
				let maxWidth = 1280
				let maxHeight = Math.round((img.height *= maxWidth / img.width))
				ctx.canvas.width = img.width > maxWidth ? maxWidth : img.width
				ctx.canvas.height = img.width > maxWidth ? maxHeight : img.height
				ctx.fillStyle = 'white'
				ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
				ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height)
				const imgData = canvas.toDataURL('image/jpeg', 0.7)
				const imgBlob = dataURItoBlob(imgData)
				const outputfile = new File([imgBlob], image.name, {
					type: 'image/jpeg',
				})
				setImagesFile((prev) => [...prev, outputfile])
			}
			reader.onloadend = () => {
				img.src = reader.result
			}
			reader.readAsDataURL(image)
			return image
		})

		const imagesURLs = [...e.target.files].map((item) => {
			return {
				name: item.name,
				url: URL.createObjectURL(item),
			}
		})
		setImagesUrl((prev) => [...prev, ...imagesURLs])
		setError('')
	}
	const handleRemoveImage = (imageFile) => {
		let newImages = imagesFile.filter((item) => item.name !== imageFile.name)
		let newImagesUrl = imagesUrl.filter((item) => item.url !== imageFile.url)
		setImagesFile(newImages)
		setImagesUrl(newImagesUrl)
	}
	const handleVideoRemove = () => {
		setVideoFile(null)
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (project) {
			let imageSize
			imagesFile.forEach((image, index) => {
				if (image.size > 512000) {
					imageSize = {
						imageSize: image.size,
						imageIndex: index + 1,
					}
				}
			})
			if (
				(videoFile === null && applicationFile === null && imagesFile.length <= 0) ||
				(videoFile === null && applicationFile === null && imagesFile.length <= 0)
			) {
				setError('You have to select a valid, video, file "PDF or PowerPoint" or image!')
			} else if (videoFile?.size > 25600000) {
				setError('Video size cannot exceed more than 25MB')
			} else if (applicationFile?.size > 25600000) {
				setError('File size cannot exceed more than 25MB')
			} else if (imageSize?.imageSize > 512000) {
				setError(`Image ${imageSize?.imageIndex} size cannot exceed more than 500 KB`)
			} else {
				const id = project._id
				const newProject = {
					group,
					images: imagesFile,
					video: videoFile,
					file: applicationFile,
				}
				dispatch(teamCreateFinalProject(id, newProject))
			}
		}
	}

	return (
		<form onSubmit={handleSubmit} autoComplete='off' className={classes.formRoot}>
			<FormControl className={classes.formControl}>
				<InputLabel className={classes.selectLabel}>Team Group</InputLabel>
				<Select
					labelId='group'
					id='group'
					value={group || ''}
					onChange={(e) => setGroup(e.target.value)}
					inputProps={{ required: true }}
					className={classes.selectInput}
				>
					<MenuItem value='' disabled>
						Select a Group
					</MenuItem>
					{teamGroups &&
						teamGroups.map((teamGroup, index) => (
							<MenuItem key={index} value={teamGroup ? teamGroup._id : ''}>
								{teamGroup.name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
			<Grid container spacing={3}>
				<Grid item xs={6} sm={4} md={4}>
					<label htmlFor='images' className={imageButtonClasses}>
						Images
						<span className={classes.SubInfoLabel}>(Up to 10 images.)</span>
						<span>
							<PermMedia />
						</span>
					</label>
					<input
						disabled={imagesFile.length >= 10}
						type='file'
						multiple
						onChange={handleImageChange}
						hidden
						id='images'
						name='images'
						value=''
						accept='image/png, image/jpeg'
					/>
				</Grid>
				<Grid item xs={6} sm={4} md={4}>
					<label htmlFor='video' className={videoButtonClasses}>
						Video
						<span className={classes.SubInfoLabel}>(Only a video.)</span>
						<span>
							<VideoLibrary />
						</span>
					</label>
					<input
						disabled={videoFile}
						type='file'
						value=''
						onChange={handleVideoChange}
						hidden
						id='video'
						name='video'
						accept='video/mp4'
					/>
				</Grid>
				<Grid item xs={6} sm={4} md={4}>
					<label htmlFor='files' className={fileButtonClasses}>
						File
						<span className={classes.SubInfoLabel}>(Only a file.)</span>
						<span>
							<FileCopy />
						</span>
					</label>
					<input
						disabled={applicationFile}
						type='file'
						onChange={handleFileChange}
						hidden
						id='files'
						name='files'
						value=''
						accept='.pdf, .ppt, .pptm, .pptx'
					/>
				</Grid>
				{applicationFile && (
					<Grid item xs={12} md={12}>
						<div className={classes.previewRoot}>
							<Badge
								badgeContent={
									<Button size='sm' color='danger' onClick={handleFileRemove} round justIcon>
										<Close />
									</Button>
								}
								className={classes.fileBadge}
							>
								<div className={classes.previewContainer}>
									<FileCopy />
									<div className={classes.fileDiv}>
										{applicationFile.name.length > 20
											? applicationFile.name.slice(0, 20) +
											  '...' +
											  applicationFile.name.slice(applicationFile.name.length - 6, applicationFile.name.length)
											: applicationFile.name}
									</div>
								</div>
							</Badge>
						</div>
					</Grid>
				)}
				{videoFile && (
					<Grid item xs={12} className={classes.fileRoot}>
						<div className={classes.previewRoot}>
							<Badge
								badgeContent={
									<Button size='sm' color='danger' round justIcon onClick={() => handleVideoRemove()}>
										<Close />
									</Button>
								}
								className={classes.fileBadge}
							>
								<div className={classes.previewContainer}>
									<VideoLibrary />
									<div className={classes.videoDiv}>
										{videoFile.name.length > 20
											? videoFile.name.slice(0, 20) +
											  '...' +
											  videoFile.name.slice(videoFile.name.length - 6, videoFile.name.length)
											: videoFile.name}
									</div>
								</div>
							</Badge>
						</div>
					</Grid>
				)}
				{imagesUrl.length > 0 && (
					<Grid item xs={12} sm={12}>
						<div className={classes.infoGrid}>
							{imagesUrl.map((item, i) => (
								<div key={i}>
									<Badge
										badgeContent={
											<Button size='sm' color='danger' round justIcon onClick={() => handleRemoveImage(item)}>
												<Close />
											</Button>
										}
									>
										<img src={item.url} alt={item.url} />
									</Badge>
								</div>
							))}
						</div>
					</Grid>
				)}
			</Grid>
			{error && <Message message={<span>{error}</span>} color='danger' />}
			{errorTeamProject && <Message message={<span>{errorTeamProject}</span>} color='info' />}
			<Button
				disabled={(!videoFile && !applicationFile && imagesFile < 1) || loadingTeamProject}
				block
				color={'primary'}
				type='submit'
			>
				{loadingTeamProject ? 'Cargando' : 'Submit'}
			</Button>
		</form>
	)
}

export default CreateFinalProject

import * as types from '../constants/teamConstants'

export const teamRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_REGISTER_REQUEST:
      return { loadingTeamRegister: true }
    case types.TEAM_REGISTER_SUCCESS:
      return {
        loadingTeamRegister: false,
        successTeamRegister: true,
        team: action.payload,
      }
    case types.TEAM_REGISTER_FAIL:
      return {
        loadingTeamRegister: false,
        errorTeamRegister: action.payload,
      }
    case types.TEAM_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const teamListReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case types.TEAM_LIST_REQUEST:
      return { loadingTeamList: true }
    case types.TEAM_LIST_SUCCESS:
      return {
        loadingTeamList: false,
        thatsIt: action.payload.length === 0 ? true : false,
        teams: action.payload,
      }
    case types.TEAM_LIST_FAIL:
      return {
        loadingTeamList: false,
        errorTeamList: action.payload,
      }
    case types.TEAM_LIST_RESET:
      return { teams: [] }
    default:
      return state
  }
}

export const teamDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_DETAILS_REQUEST:
      return { loadingTeamDetails: true }
    case types.TEAM_DETAILS_SUCCESS:
      return {
        loadingTeamDetails: false,
        team: action.payload,
      }
    case types.TEAM_DETAILS_FAIL:
      return {
        loadingTeamDetails: false,
        errorTeamDetails: action.payload,
      }
    case types.TEAM_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const teamMyDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_MY_DETAILS_REQUEST:
      return { loadingMyTeamPanel: true }
    case types.TEAM_MY_DETAILS_SUCCESS:
      return {
        loadingMyTeamPanel: false,
        myTeamPanelInfo: action.payload,
      }
    case types.TEAM_MY_DETAILS_FAIL:
      return {
        loadingMyTeamPanel: false,
        errorMyTeamPanel: action.payload,
      }
    case types.TEAM_MY_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const teamUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_UPDATE_REQUEST:
      return { loadingTeamUpdate: true }
    case types.TEAM_UPDATE_SUCCESS:
      return {
        loadingTeamUpdate: false,
        successTeamUpdate: true,
        teamUpdated: action.payload,
      }
    case types.TEAM_UPDATE_FAIL:
      return {
        loadingTeamUpdate: false,
        errorTeamUpdate: action.payload,
      }
    case types.TEAM_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const teamFinalProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_PROJECT_REQUEST:
      return { loadingTeamProject: true }
    case types.TEAM_PROJECT_SUCCESS:
      return {
        loadingTeamProject: false,
        successTeamProject: true,
        teamProject: action.payload,
      }
    case types.TEAM_PROJECT_FAIL:
      return {
        loadingTeamProject: false,
        errorTeamProject: action.payload,
      }
    case types.TEAM_PROJECT_RESET:
      return {}
    default:
      return state
  }
}

export const teamUpdateFinalProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_PROJECT_UPDATE_REQUEST:
      return { loadingTeamProjectUpdate: true }
    case types.TEAM_PROJECT_UPDATE_SUCCESS:
      return {
        loadingTeamProjectUpdate: false,
        successTeamProjectUpdate: true,
        teamUpdateProject: action.payload,
      }
    case types.TEAM_PROJECT_UPDATE_FAIL:
      return {
        loadingTeamProjectUpdate: false,
        errorTeamProjectUpdate: action.payload,
      }
    case types.TEAM_PROJECT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const teamUpdateFinalProjectVoteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_PROJECT_UPDATE_WINNER_REQUEST:
      return { loadingTeamWinnerProjectUpdate: true }
    case types.TEAM_PROJECT_UPDATE_WINNER_SUCCESS:
      return {
        loadingTeamWinnerProjectUpdate: false,
        successTeamWinnerProjectUpdate: true,
        teamUpdateVoteFinalProject: action.payload,
      }
    case types.TEAM_PROJECT_UPDATE_WINNER_FAIL:
      return {
        loadingTeamWinnerProjectUpdate: false,
        errorTeamWinnerProjectUpdate: action.payload,
      }
    case types.TEAM_PROJECT_UPDATE_WINNER_RESET:
      return {}
    default:
      return state
  }
}

export const teamDeleteFinalProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_PROJECT_DELETE_REQUEST:
      return { loadingTeamProjectDelete: true }
    case types.TEAM_PROJECT_DELETE_SUCCESS:
      return {
        loadingTeamProjectDelete: false,
        successTeamProjectDelete: true,
      }
    case types.TEAM_PROJECT_DELETE_FAIL:
      return {
        loadingTeamProjectDelete: false,
        errorTeamProjectDelete: action.payload,
      }
    case types.TEAM_PROJECT_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const teamsFinalProjectsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_PROJECT_WINNER_REQUEST:
      return { loadingTeamFinalProjects: true }
    case types.TEAM_PROJECT_WINNER_SUCCESS:
      return {
        loadingTeamFinalProjects: false,
        teamFinalProjects: action.payload,
      }
    case types.TEAM_PROJECT_WINNER_FAIL:
      return {
        loadingTeamFinalProjects: false,
        errorTeamFinalProjects: action.payload,
      }
    case types.TEAM_PROJECT_WINNER_RESET:
      return {}
    default:
      return state
  }
}

export const teamDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_DELETE_REQUEST:
      return { loadingTeamDelete: true }
    case types.TEAM_DELETE_SUCCESS:
      return {
        loadingTeamDelete: false,
        successTeamDelete: true,
      }
    case types.TEAM_DELETE_FAIL:
      return {
        loadingTeamDelete: false,
        errorTeamDelete: action.payload,
      }
    case types.TEAM_DELETE_RESET:
      return {}
    default:
      return state
  }
}

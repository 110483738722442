const styles = {
  root: {
    margin: '40px 0px',
    height: '700px',
    overflowY: 'auto',
    boxShadow: '0px  0px 15px #b5b5b5 inset',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'gray',
      borderRadius: '5px',
    },
  },
  itemRoot: {
    width: '100%',
    marginTop: '20px',
  },
  mainRoot: {
    display: 'flex',
    justifyContent: 'center',
    '& video': {
      maxWidth: '100%',
    },
  },
  lightBooxRoot: {
    position: 'fixed',
    marginTop: '50px',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    cursor: 'pointer',
    zIndex: '1000',
  },
  sectionImages: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    '& img': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      cursor: 'pointer',
      maxWidth: '60%',
      maxHeight: '90%',
    },
  },
  imageFilter: {
    maxWidth: '60%',
    maxHeight: '80%',
  },
  galleryTitle: {
    fontSize: '38px',
    margin: '40px 0px 20px',
    textAlign: 'center',
  },
}

export default styles

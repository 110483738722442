import { Checkbox, FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import styles from '../styles/teacherSurveysQuestionsStyles'

const useStyles = makeStyles(styles)

const TeacherSurveysQuestions = ({ questions, handleAnswer, checked }) => {
  const classes = useStyles()

  return (
    <>
      {questions?.map((question, i) => (
        <div className={classes.mainSection} key={question._id}>
          {question.type === 'paragraph' && (
            <Grid key={question._id} md={12} sm={12} item>
              <label>{question.question}</label>
              <CustomInput
                key={question._id}
                id={Date.now().toString()}
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControl,
                }}
                inputProps={{
                  type: 'text',
                  value: question?.answer || '',
                  placeholder: 'Answer question',
                  onChange: (e) => handleAnswer(e, question),
                  required: true,
                }}
              />
            </Grid>
          )}
          {question.type === 'selectRadio' && (
            <Grid key={question._id} md={12} sm={12} item>
              <label>{question.question}</label>
              <hr />
              <FormControl>
                <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='radio-buttons-group'>
                  {question?.radioQuestion &&
                    question.radioQuestion.map((radio, index) => (
                      <FormControlLabel
                        key={index}
                        className={classes.sectionSelects}
                        control={
                          <Radio
                            onChange={(e) => handleAnswer(e, question)}
                            value={radio.question}
                            required
                            id={Date.now().toString()}
                          />
                        }
                        label={radio.question}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {question.type === 'selectCheckbox' && (
            <Grid key={question._id} md={12} sm={12} item>
              <label>{question.question}</label>
              <hr />
              <FormControl>
                <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='radio-buttons-group'>
                  {question?.checkboxQuestion &&
                    question.checkboxQuestion.map((select, i) => {
                      return (
                        <FormControlLabel
                          key={select._id}
                          className={classes.sectionSelects}
                          control={
                            <Checkbox
                              id={question._id}
                              required={checked.length < 1 ? true : false}
                              checked={checked[select.question]}
                              onChange={(e) => handleAnswer(e, question, select)}
                            />
                          }
                          label={select.question}
                        />
                      )
                    })}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </div>
      ))}
    </>
  )
}

export default TeacherSurveysQuestions

import ForgotPasswordScreen from '../screens/platform-access/ForgotPasswordScreen/ForgotPasswordScreen'
import LoginScreen from '../screens/platform-access/LoginScreen/LoginScreen'

const routesAuth = [
  {
    path: '/forgot-password',
    exact: true,
    comp: ForgotPasswordScreen,
  },
  {
    path: '/login',
    exact: true,
    comp: LoginScreen,
  },
]

export default routesAuth

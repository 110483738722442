import { useHistory } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/homeScreenStyles'

const useStyles = makeStyles(styles)

const BecomeSponsor = () => {
	const history = useHistory()
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<Grid container className={classes.becomeASponsor}>
			<Grid item md={12} sm={12} xs={12}>
				<h4>{t('layout.t_become-a-sponsor')}</h4>
			</Grid>
			<Grid item xs={12} md={8} sm={10}>
				<p>
					<strong>{t('layout.t_become-a-sponsor-strong')}</strong> "{t('layout.t_become-a-sponsor-paragraph')}"
				</p>
				<Button color='primary' onClick={() => history.push('/become-a-sponsor')}>
					{t('layout.t_become-a-sponsor')}
				</Button>
			</Grid>
		</Grid>
	)
}

export default BecomeSponsor

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import {
  AccountBalance,
  PersonAdd,
  // Enable en case you need the Add Group  functionality
  // GroupAdd,
  People,
} from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import Message from '../../../components/Message/Message'
import CustomTabs from '../../../components/CustomTabs/CustomTabs'
import CenterInfo from '../../../components/Centers/CenterInfo/CenterInfo'
import UserListCard from '../../../components/Users/UserListCard/UserListCard'
// Enable en case you need the Add Group  functionality
// import GroupRegisterForm from '../../../components/Centers/GroupRegisterForm/GroupRegisterForm'
import CustomModal from '../../../components/Modal/CustomModal'
import CustomTabGroup from '../../../components/CustomTabGroup/CustomTabGroup'
import TeacherRegisterForm from './components/TeacherRegisterForm'
import ViewTeacherModal from './components/ViewTeacherModal'
import { getCenterById } from '../../../redux/actions/centerActions'
import { getTeachersByCenter } from '../../../redux/actions/teacherActions'
import { getGroupsByCenter } from '../../../redux/actions/groupActions'
import {
  GROUP_DELETE_RESET,
  GROUP_LIST_BY_CENTER_RESET,
  GROUP_TEACHER_DELETE_RESET,
  GROUP_UPDATE_RESET,
} from '../../../redux/constants/groupConstants'
import { CENTER_DETAILS_RESET } from '../../../redux/constants/centerConstants'
import { TEACHERS_BY_CENTER_RESET } from '../../../redux/constants/teacherConstants'
import { STUDENTS_BY_CENTER_RESET } from '../../../redux/constants/studentConstants'
import styles from './styles/schoolProfileScreenStyles'

const useStyles = makeStyles(styles)

const SchoolProfileScreen = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [centerId, setCenterId] = useState(null)
  const [notCenter, setNotCenter] = useState(false)
  const [viewTeacherInfo, setViewTeacherInfo] = useState(false)
  const [teacherInfo, setTeacherInfo] = useState({})

  const { userInfo } = useSelector((state) => state.userLogin)
  const { loadingCenterDetails, errorCenterDetails, center } = useSelector((state) => state.centerDetails)
  const { loadingTeachersByCenter, errorTeacherByCenter, teachers } = useSelector((state) => state.teachersByCenter)
  const { successGroupUpdate } = useSelector((state) => state.groupUpdate)
  const { successGroupDelete } = useSelector((state) => state.groupDelete)
  const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)

  useEffect(() => {
    if (userInfo) {
      if (userInfo.center) {
        setCenterId(userInfo.center)
        dispatch(getCenterById(userInfo.center))
        dispatch(getTeachersByCenter(userInfo.center))
        dispatch(getGroupsByCenter(userInfo.center))
      } else {
        setNotCenter(true)
      }
    } else {
      history.push('/auth/login')
    }
  }, [userInfo, dispatch, history])
  useEffect(() => {
    if (successGroupUpdate) {
      dispatch({ type: GROUP_UPDATE_RESET })
      dispatch(getGroupsByCenter(centerId))
    }
    if (successGroupDelete) {
      dispatch({ type: GROUP_DELETE_RESET })
      dispatch(getGroupsByCenter(centerId))
    }
    if (successGroupTeacherDelete) {
      dispatch({ type: GROUP_TEACHER_DELETE_RESET })
      dispatch(getGroupsByCenter(centerId))
    }
  }, [dispatch, centerId, successGroupUpdate, successGroupDelete, successGroupTeacherDelete])
  useEffect(() => {
    return () => {
      dispatch({ type: CENTER_DETAILS_RESET })
      dispatch({ type: TEACHERS_BY_CENTER_RESET })
      dispatch({ type: STUDENTS_BY_CENTER_RESET })
      dispatch({ type: GROUP_LIST_BY_CENTER_RESET })
    }
  }, [dispatch])

  const viewTeacherHandler = (teacher) => {
    setViewTeacherInfo(true)
    setTeacherInfo(teacher)
  }
  const closeViewTeacherHandler = () => {
    setViewTeacherInfo(false)
    setTeacherInfo({})
  }

  return (
    <>
      <Meta title={'School Profile - Planet Fraternity'} />
      {notCenter ? (
        <Message message={<span>You are not from a registered center</span>} color='info' />
      ) : loadingCenterDetails ? (
        <Loader />
      ) : errorCenterDetails ? (
        <Message message={<span>{errorCenterDetails}</span>} color='info' />
      ) : (
        center._id && (
          <>
            <Grid container spacing={8} className={classes.root}>
              <Grid item xs={12} sm={12} md={8} className={classes.centerInfoCard}>
                {center && (
                  <CustomTabs
                    headerColor='danger'
                    className={classes.customTabsRoot}
                    tabs={[
                      {
                        tabName: 'Center Info',
                        tabIcon: AccountBalance,
                        tabContent: <CenterInfo center={center} teachers={teachers} />,
                      },
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                {center && (
                  <CustomTabs
                    headerColor='primary'
                    className={classes.customTabsRoot}
                    tabs={[
                      {
                        tabName: 'Teachers',
                        tabIcon: People,
                        tabContent: (
                          <div>
                            {loadingTeachersByCenter ? (
                              <Loader />
                            ) : errorTeacherByCenter ? (
                              <span>{errorTeacherByCenter}</span>
                            ) : (
                              teachers &&
                              teachers.map((teacher) => (
                                <UserListCard
                                  key={teacher._id}
                                  user={teacher}
                                  draggable
                                  email
                                  dropdownList={[
                                    <div onClick={() => viewTeacherHandler(teacher)}>View Teacher's Info</div>,
                                  ]}
                                />
                              ))
                            )}
                          </div>
                        ),
                      },
                      {
                        tabName: 'Add Teacher',
                        tabIcon: PersonAdd,
                        tabContent: <TeacherRegisterForm center={center} />,
                      },
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                {center && (
                  <CustomTabs
                    headerColor='success'
                    className={classes.customTabsRoot}
                    tabs={[
                      {
                        tabName: 'Groups',
                        tabIcon: People,
                        tabContent: <CustomTabGroup center={center} teachers={teachers} />,
                      },
                    ]}
                  />
                )}
              </Grid>
            </Grid>
            <CustomModal
              title={teacherInfo.name}
              open={viewTeacherInfo}
              setOpen={closeViewTeacherHandler}
              actions={false}
            >
              <ViewTeacherModal teacherInfo={teacherInfo} closeViewTeacherHandler={closeViewTeacherHandler} />
            </CustomModal>
          </>
        )
      )}
    </>
  )
}

export default SchoolProfileScreen

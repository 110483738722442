import format from 'date-fns/format'
import { Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/magazineInfoStyles'

const useStyles = makeStyles(styles)

const MagazineInfo = ({ writing }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.magazineInfoSection}>
          <h1>Title · {writing.title}</h1>
          <h3>Question · {writing.question}</h3>
          <div>
            <span>Contest date · </span>
            <span>{format(new Date(writing.contestDate), 'MMMM yyyy')}</span>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default MagazineInfo

const styles = {
  topicsIcons: {
    width: '100%',
    marginBottom: '10px',
    borderRadius: '5px',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
    },
  },
}

export default styles

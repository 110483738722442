import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Card, Grid, makeStyles, Tooltip } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import CardBody from '../../../../components/Card/CardBody'
import styles from '../styles/teamCardStyles'

const useStyles = makeStyles(styles)

const TeamCard = ({ data, history }) => {
  const { t } = useTranslation('global')
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {data && data.length > 0 ? (
        <Grid container spacing={3}>
          {data.map((project) => (
            <Grid key={project._id} item md={4} sm={6}>
              <Card className={classes.sectionRoot}>
                <CardBody>
                  <Grid container className={classes.infoSection}>
                    <Grid item md={12} className={classes.topicRoot}>
                      <Grid item md={12}>
                        <Tooltip title={project.panelName}>
                          <h5>
                            Centers -{' '}
                            {project.panelName.length > 30 ? project.panelName.slice(0, 30) + '...' : project.panelName}
                          </h5>
                        </Tooltip>
                      </Grid>
                      <h2>{`Topic - ${project.topic.name}`}</h2>
                      <img src={`${axios.defaults.baseURL}${project.topic.image}`} alt={project.teamId} />
                    </Grid>
                    <Grid item md={12}>
                      <h4>
                        Start Date · {format(new Date(project.partnersStartDate), 'dd-MM-yyyy')} - End Date ·{' '}
                        {format(new Date(project.partnersEndDate), 'dd-MM-yyyy')}
                      </h4>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Button
                        color='primary'
                        onClick={() => history.push(`${history.location.pathname}?team=${project._id}`)}
                      >
                        {t('layout.buttons.t_view-projects')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <h5>Final Projects Not Found</h5>
      )}
    </div>
  )
}

export default TeamCard

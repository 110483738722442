import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Meta from '../../../../components/Meta/Meta'
import Loader from '../../../../components/Loader/Loader'
import Button from '../../../../components/Buttons/Button'
import CustomModal from '../../../../components/Modal/CustomModal'
import TeamCard from './TeamCard'
import AgeAccordionCard from './AgeAccordionCard'
import {
  getTeamsByAgeNinTopic,
  prevTeamsByAge,
  automateTeamGroups,
  teamsActually,
} from '../../../../redux/actions/teamGroupActions'
import { registerLog } from '../../../../redux/actions/logActions'
import { TEAM_GROUP_BYAGE_PREV_RESET } from '../../../../redux/constants/teamGroupConstants'
import styles from '../styles/selectedTopicStyles'

const useStyles = makeStyles(styles)

const SelectedTopic = ({ topic }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [teamsArray, setTeamsArray] = useState([])
  const [groups, setGroups] = useState([])
  const [partnersStartDate, setPartnersStartDate] = useState(null)
  const [startDateError, setStartDateError] = useState(false)
  const [partnersEndDate, setPartnersEndDate] = useState(null)
  const [data, setaData] = useState(null)
  const [endDateError, setEndDateError] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { loadingTeamsByAgeNinTopic, teams } = useSelector((state) => state.teamsByAgeNinTopic)
  const { loadingTeamsByAgePrev, teamsPrev } = useSelector((state) => state.teamsByAgePrev)
  const { teamActually } = useSelector((state) => state.teamsActually)
  const { loadingTeamsGroupsAutomation, successTeamsGroupsAutomation } = useSelector(
    (state) => state.teamsGroupsAutomation
  )

  // Call unpartnered centers by age
  useEffect(() => {
    dispatch(getTeamsByAgeNinTopic(topic._id))
    return () => {
      dispatch({ type: TEAM_GROUP_BYAGE_PREV_RESET })
    }
  }, [dispatch, topic])
  // Teams to state to handle the out group
  useEffect(() => {
    if (teams) {
      setTeamsArray(teams)
    }
  }, [teams])

  useEffect(() => {
    if (successTeamsGroupsAutomation) {
      setOpenConfirm(false)
      dispatch(getTeamsByAgeNinTopic(topic._id))
      dispatch({ type: TEAM_GROUP_BYAGE_PREV_RESET })
    }
  }, [dispatch, successTeamsGroupsAutomation, topic])

  // Assing the teams preview to groups in state
  useEffect(() => {
    if (teamsPrev) {
      setGroups(teamsPrev.teams)
    }
  }, [teamsPrev])

  useEffect(() => {
    if (groups) {
      if (teamsPrev) {
        dispatch(teamsActually(groups, teamsPrev?.ageRange))
      }
    }
  }, [dispatch, groups, teamsPrev])

  useEffect(() => {
    if (teamActually) {
      setaData(teamActually)
    }
  }, [teamActually, setaData])

  // Get the teams preview
  const getTeamsPreviewHandler = (ageRangeGroups) => {
    dispatch(prevTeamsByAge(ageRangeGroups))
  }

  // Confirm group automation
  const confirmGroupAutomation = () => {
    setStartDateError(false)
    setEndDateError(false)
    if (partnersStartDate === null) {
      setStartDateError(true)
      setOpenConfirm(false)
    } else if (partnersEndDate === null) {
      setEndDateError(true)
      setOpenConfirm(false)
    } else {
      let groupsDataObj = {
        ageRange: teamsPrev?.ageRange,
        groups,
        topic,
        partnersStartDate,
        partnersEndDate,
      }
      groups.forEach((group) => {
        let dataLog = {
          category: 'mergeCenters',
          groupName: group?.groupName,
          ageRange: teamsPrev?.ageRange,
          user: {
            id: userInfo._id,
            username: userInfo.username,
            role: userInfo.role,
          },
          topic,
          centers: group.groups,
          partnersStartDate,
          partnersEndDate,
        }
        if (dataLog.centers.length > 1) {
          dispatch(registerLog(dataLog))
        }
      })
      dispatch(automateTeamGroups(groupsDataObj))
    }
  }

  return (
    <>
      <Meta title={'Merge Centers - Planet Fraternity'} />
      <Grid container spacing={3} className={classes.root}>
        {/* Page Title */}
        <Grid item xs={12}>
          <h1>Unpartnered Groups in topic "{topic.name}"</h1>
        </Grid>
        {/* Accordion Cards */}
        <Grid item xs={12} md={4} className={classes.accordionRoot}>
          {loadingTeamsByAgeNinTopic ? (
            <Loader />
          ) : teamsArray.length < 1 ? (
            <p>Unpartnered groups not found.</p>
          ) : (
            teamsArray?.map((team, index) => (
              <AgeAccordionCard key={index} team={team} getTeamsPreviewHandler={getTeamsPreviewHandler} />
            ))
          )}
        </Grid>
        {/* Right side with groups preview and group title */}
        <Grid item xs={12} md={8}>
          {loadingTeamsByAgePrev ? (
            <Loader />
          ) : !teamsPrev ? (
            <div className={classes.rightSection}>
              <img src={`${axios.defaults.baseURL}${topic?.image}`} alt={topic?.name} />
              <h2>Get a team preview to merge groups.</h2>
            </div>
          ) : (
            teamsPrev && (
              <Grid container spacing={2} className={classes.teamsRoot}>
                <Grid item xs={12}>
                  <h2>Groups with age range {teamsPrev.ageRange}</h2>
                  <p>This is the most accurate teams preview found.</p>
                  <p>
                    If there is a Team with a single center, you must move that center by dragging and dropping into
                    other team or delete it from this panel.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <h3>Please set the start and end dates.</h3>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label='Partners Start Date'
                    id='partners-start-date'
                    value={partnersStartDate}
                    onChange={setPartnersStartDate}
                    format='dd/MM/yyyy'
                    className={classes.textField}
                    minDate={new Date()}
                    error={startDateError && partnersStartDate === null}
                    helperText='Click to select start Date'
                    InputLabelProps={{
                      shrink: true,
                      className: classes.selectLabel,
                    }}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label='Partners End Date'
                    id='partners-end-date'
                    value={partnersEndDate}
                    onChange={setPartnersEndDate}
                    format='dd/MM/yyyy'
                    className={classes.textField}
                    minDate={partnersStartDate}
                    error={endDateError && partnersEndDate === null}
                    helperText='Click to select start Date'
                    InputLabelProps={{
                      shrink: true,
                      className: classes.selectLabel,
                    }}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                </Grid>
                {data &&
                  data.teams.map((team, teamIndex) => (
                    <TeamCard key={teamIndex} team={team} groups={groups} setGroups={setGroups} />
                  ))}
                <Grid item xs={12}>
                  <Button
                    color='primary'
                    block
                    onClick={() => setOpenConfirm(true)}
                    disabled={teamsPrev.teams[0].groups.length === 1}
                  >
                    Teams Automation
                  </Button>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
      <CustomModal title='Automate Teams and Groups' open={openConfirm} setOpen={setOpenConfirm} actions={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>You are about to automate the creation of teams and groups.</p>
            <p>Click confirm to proceed.</p>
          </Grid>
          <Grid item xs={5}>
            <Button color='info' block onClick={() => setOpenConfirm(false)}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Button color='primary' block onClick={confirmGroupAutomation}>
              {loadingTeamsGroupsAutomation ? <Loader size={'sm'} /> : 'Confirm'}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  )
}

export default SelectedTopic

import { useHistory } from 'react-router-dom'
import { Grid, Divider, makeStyles } from '@material-ui/core'
import { Facebook, Instagram, LinkedIn, YouTube } from '@material-ui/icons'
import Button from '../Buttons/Button'
import styles from './styles/footerStyles'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles(styles)

const Footer = () => {
	const history = useHistory()
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<div className={classes.root}>
			<Grid container className={classes.container}>
				<Grid item xs={12} className={classes.footerItem}>
					<Grid container>
						<Grid item xs={6} md={3} className={classes.logoDiv}>
							<img
								src='/assets/img/pacto-educativo-global200.png'
								alt='Logo Pacto Educativo Footer'
								className={classes.logoFooter}
							/>
						</Grid>
						<Grid item xs={6} md={3} className={classes.logoDiv}>
							<img
								src='/assets/img/logo-planetfraternity200.png'
								alt='Logo Planet Fraternity Footer'
								className={classes.logoFooter}
							/>
						</Grid>
						<Grid item xs={6} md={3} className={classes.logoDiv}>
							<img
								src='/assets/img/logo-theimprovingco200.png'
								alt='Logo The Improving Co. Footer'
								className={classes.logoFooter}
							/>
						</Grid>
						<Grid item xs={6} md={3} className={classes.logoDiv}>
							<img src='/assets/img/oiec200.png' alt='Logo OIEC Footer' className={classes.logoFooter} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} className={classes.footerItem}>
					<Grid container>
						<Grid item xs={12} sm={6} md={3}>
							<span className={classes.listTitle}>Planet Fraternity</span>
							<ul className={classes.footerLinksList}>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/how-it-works')}>
										{t('layout.t_how-it-works')}
									</Button>
								</li>
								{/* <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/the-magazine')}>
                                        The Magazine
                                    </Button>
                                </li> */}
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/i-can')}>
										{t('layout.t_i-can')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/pastoral')}>
										{t('layout.t_pastoral')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/contact')}>
										{t('layout.t_contact')}
									</Button>
								</li>
							</ul>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<span className={classes.listTitle}>{t('layout.t_our-methodology')}</span>
							<ul className={classes.footerLinksList}>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/feel')}>
										{t('layout.t_feel')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/imagine')}>
										{t('layout.t_imagine')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/act')}>
										{t('layout.t_act')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/share')}>
										{t('layout.t_share')}
									</Button>
								</li>
							</ul>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<span className={classes.listTitle}>{t('layout.t_platform')}</span>
							<ul className={classes.footerLinksList}>
								{/* <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/auth/login')}>
                                        {t('layout.t_login')}
                                    </Button>
                                </li>
                                <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/auth/forgot-password')}>
                                        {t('layout.t_recover_pass')}
                                    </Button>
                                </li> */}
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/center-register')}>
										{t('layout.t_register-center')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/terms-conditions')}>
										{t('layout.t_terms-conditions')}
									</Button>
								</li>
								<li>
									<Button link className={classes.footerLink} onClick={() => history.push('/cookies-policy')}>
										{t('layout.t_cookies-policy')}
									</Button>
								</li>
								{/* <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/support')}>
                                        {t('layout.t_support')}
                                    </Button>
                                </li> */}
							</ul>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<span className={classes.listTitle}>{t('layout.t_social-networks')}</span>
							<ul className={classes.footerLinksList}>
								<li>
									<Button
										link
										className={classes.footerLink}
										onClick={() => window.open('https://www.facebook.com/planetfraternity/')}
									>
										<Facebook className={classes.footerIcon} />
										Facebook
									</Button>
								</li>
								<li>
									<Button
										link
										className={classes.footerLink}
										onClick={() => window.open('https://www.instagram.com/planetfraternity/')}
									>
										<Instagram className={classes.footerIcon} />
										Instagram
									</Button>
								</li>
								<li>
									<Button
										link
										className={classes.footerLink}
										onClick={() => window.open('https://www.linkedin.com/company/planet-fraternity/')}
									>
										<LinkedIn className={classes.footerIcon} />
										LinkedIn
									</Button>
								</li>
								<li>
									<Button
										link
										className={classes.footerLink}
										onClick={() => window.open('https://www.youtube.com/channel/UCp0OByfzxidehvmF8NHueTw/')}
									>
										<YouTube className={classes.footerIconYoutube} />
										YouTube
									</Button>
								</li>
								{/* <li>
                    <Button link className={classes.footerLink} onClick={() => history.push('/support')}>
                        {t('layout.t_support')}
                    </Button>
                </li> */}
							</ul>
						</Grid>
						<Grid item xs={12}>
							<Divider className={classes.divider} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} className={classes.footerItem}>
					contacto@planetfraternity.com 🌍 www.planetfraternity.com
				</Grid>
				<Grid item xs={12} className={classes.footerItem}>
					Copyright &copy; | Planet Fraternity | {1900 + new Date().getYear()}
				</Grid>
			</Grid>
		</div>
	)
}

export default Footer

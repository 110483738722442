import axios from 'axios'
import { useEffect, useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardAvatar from '../../../../components/Card/CardAvatar'
import CardBody from '../../../../components/Card/CardBody'
import CenterCardForm from './CenterCardForm'
import styles from '../styles/centerCardStyles'

const useStyles = makeStyles(styles)

const CenterCard = ({ center, setCenterDetail }) => {
    const classes = useStyles()
    const editcenterform = useRef(null)

    const scrollToRef = () => {
        editcenterform.current?.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {
        scrollToRef()
    }, [center])

    return (
        <Grid container ref={editcenterform}>
            <Grid item xs={12} sm={12} md={12}>
                <Card profile>
                    <CardAvatar center>
                        <img src={`${axios.defaults.baseURL}${center.logo}`} alt={center.name} />
                    </CardAvatar>
                    <CardBody profile>
                        <h4 className={classes.cardTitle}>{center.name}</h4>
                        <CenterCardForm center={center} setCenterDetail={setCenterDetail} />
                    </CardBody>
                </Card>
            </Grid>
        </Grid>
    )
}

export default CenterCard

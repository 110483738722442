import { useState } from 'react'
import { Grid, IconButton, Drawer, makeStyles } from '@material-ui/core'
import { ChatBubble, Group, Mic, MicOff, Notifications, ScreenShare, Videocam, VideocamOff } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import styles from './styles/classroomScreenStyles'

const useStyles = makeStyles(styles)

const ClassroomScreen = ({ match }) => {
	const groupClassId = match.params.id
	const classes = useStyles()

	const [micEnabled, setMicEnabled] = useState(false)
	const [camEnabled, setCamEnabled] = useState(false)
	const [mobileOpen, setMobileOpen] = useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	return (
		<>
			<Meta title={'Classroom - Planet Fraternity'} />
			<Grid container>
				<Grid item xs={12} className={classes.roomHeader}>
					<Grid container>
						<Grid item xs={6}>
							<IconButton
								color='primary'
								aria-label='upload picture'
								component='span'
								onClick={() => setMicEnabled(!micEnabled)}
							>
								{micEnabled ? <Mic /> : <MicOff />}
							</IconButton>

							<IconButton
								color='primary'
								aria-label='upload picture'
								component='span'
								onClick={() => setCamEnabled(!camEnabled)}
							>
								{camEnabled ? <Videocam /> : <VideocamOff />}
							</IconButton>
							<IconButton color='primary' aria-label='upload picture' component='span'>
								<ScreenShare />
							</IconButton>
						</Grid>
						<Grid item xs={6}>
							<IconButton color='primary' aria-label='upload picture' component='span'>
								<Notifications />
							</IconButton>
							<IconButton color='primary' aria-label='upload picture' component='span'>
								<ChatBubble />
							</IconButton>
							<IconButton color='primary' component='span' aria-label='open drawer' onClick={handleDrawerToggle}>
								<Group />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<Grid container className={classes.roomRoot}>
						<Grid item xs={12}>
							<div className={classes.roomSection}></div>
						</Grid>
						<Grid item xs={12}></Grid>
					</Grid>
				</Grid>
				<Grid item xs={9} className='d-flex justify-content-center align-items-center'>
					<h1>Board</h1>
				</Grid>
				<Drawer
					variant='persistent'
					anchor={'right'}
					open={mobileOpen}
					classes={{
						paper: classes.drawerPaper,
					}}
					onClose={handleDrawerToggle}
				>
					<div className={classes.appResponsive}>Listado de estudiantes</div>
				</Drawer>
			</Grid>
		</>
	)
}

export default ClassroomScreen

const styles = {
    root: {
        padding: 20,
    },
    accordionRoot: {
        minHeight: '80vh',
    },
    teamsRoot: {
        padding: 5,
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        '& h2': {
            textAlign: 'center',
        },
    },
    rightSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& h2': {
            textAlign: 'center',
        },
        '& svg': {
            fontSize: 70,
            color: '#9c27b0',
        },
    },
}

export default styles

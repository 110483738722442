import { Spinner } from 'react-bootstrap'

const Loader = ({ width, height, size, color }) => {
  return (
    <Spinner
      animation='border'
      role='status'
      size={size}
      style={{
        width: { width },
        height: { height },
        margin: 'auto',
        display: 'block',
        color: `${color}`,
      }}
    ></Spinner>
  )
}

Loader.defaultProps = {
  width: '100px',
  height: '100px',
  size: 'lg',
  color: 'black',
}

export default Loader

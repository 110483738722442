const styles = {
	root: {
		marginTop: 20,
	},
	areaField: {
		width: '100%',
	},
	cardCategoryWhite: {
		color: 'rgba(255,255,255,.62)',
		margin: '0',
		fontSize: '14px',
		marginTop: '0',
		marginBottom: '0',
	},
	cardTitleWhite: {
		color: '#FFFFFF',
		marginTop: '0px',
		minHeight: 'auto',
		fontWeight: '300',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		marginBottom: '3px',
		textDecoration: 'none',
	},
	btnResourceStart: {
		textAlign: 'start',
		'& p': {
			fontSize: '0.75rem',
			color: '#aaaaaa',
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontWeight: 600,
			marginTop: '10px',
		},
	},
	// maxHeight50VH: {
	//   minHeight: '50vh',
	// },
	imgContainer: {
		textAlign: 'center',
	},
	imgSize: {
		maxWidth: '200px',
		maxHeight: '200px',
	},
	imageBorder: {
		border: '1px solid rgb(218 218 218)',
	},
	formControl: {
		marginTop: '11px',
		marginBottom: '30px',
		minWidth: 120,
		width: '100%',
		textAlign: 'left',
	},
	selectLabel: {
		color: '#AAAAAA !important',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '1.42857',
		letterSpacing: 'unset',
		marginButton: '10px',
	},
	selectInput: {
		marginTop: '7px',
	},
	categorySection: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	btnSection: {
		marginTop: '20px',
	},
	btnResourcePair: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		textAlign: 'end',
		'& p': {
			fontSize: '0.75rem',
			color: '#aaaaaa',
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontWeight: 600,
		},
	},
	btnResource: {
		textAlign: 'end',
		'& p': {
			fontSize: '0.75rem',
			color: '#aaaaaa',
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontWeight: 600,
		},
	},
	closeIcon: {
		padding: 5,
		marginTop: '30px',
	},
	fileBadge: {
		margin: '20px 0',
		width: '100%',
	},
	fileDiv: {
		textAlign: 'center',
		backgroundColor: '#3f51b5',
		color: '#fff',
		borderRadius: '5px',
		padding: '5px',
		width: '100%',
	},
	textHelper: {
		cursor: 'pointer',
		textDecoration: 'none',
	},
}

export default styles

const home = {
    title: 'Home - Planet Fraternity Home',
    description: 'The best place ever',
}
const beTheChange = {
    title: 'How It Works - Planet Fraternity',
    description: 'The place ever',
}

export { home, beTheChange }

const styles = {
    nested: {
        padding: '13px',
        paddingLeft: '32px',
        '& span': {
            fontSize: '15px',
        },
    },
    brand: {
        width: '100%',
        padding: '13px 0',
        margin: 0,
        '& h5': {
            fontWeight: '400',
            fontSize: '15px',
            margin: '0 0 0 10px',
        },
    },
    logoIcon: {
        width: '35px',
        marginRight: '5px',
    },
    sidebarMenu: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        backgroundColor: 'transparent',
        '& li': {
            borderBottom: '1px solid #f7f4f4',
        },
    },
    sidebarMenuHeader: {
        backgroundColor: '#dddfdf',
        padding: '10px 25px 10px 15px',
        fontSize: '12px',
        fontWeight: '500',
        color: '#483d3d',
    },
    badgeNew: {
        backgroundColor: '#3ab938',
        padding: '0.25em 0.4em',
        fontWeight: '700',
        borderRadius: '0.25rem',
        color: '#fff',
        lineHeight: 1,
        boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%)',
    },
    likeLink: {
        padding: '13px 5px 13px 15px',
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        color: '#615a5a',
        fontSize: '15px',
        textTransform: 'none',
        textAlign: 'left',
        borderLeft: '3px solid transparent',
        borderRadius: 'unset',
        '&:hover': {
            borderLeft: '3px solid #483d3d',
            color: '#615a5a',
        },
        '& span': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& svg': {
            width: '20px !important',
            height: '20px !important',
            marginRight: 7,
        },
    },
    subLink: {
        marginLeft: 15,
    },
}

export default styles

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import CustomModal from '../../../../components/Modal/CustomModal'
import { updateWritingContest, deleteWritingC } from '../../../../redux/actions/writingContestActions'
import styles from '../styles/writingContestEditFormStyles'

const useStyles = makeStyles(styles)

const WritingContestEditForm = ({ writing }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [question, setQuestion] = useState('')
  const [contestDate, setContestDate] = useState(new Date())
  const [successUpdate, setSuccessUpdate] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const { loadingWritingCUpdate, errorWritingCUpdate, successWritingCUpdate } = useSelector(
    (state) => state.writingCUpdate
  )

  const { loadingWritingCDelete, successWritingCDelete, errorWritingCDelete } = useSelector(
    (state) => state.writingCDelete
  )

  useEffect(() => {
    setTitle(writing.title)
    setQuestion(writing.question)
    setContestDate(writing.contestDate)
  }, [writing])

  useEffect(() => {
    if (successWritingCUpdate) {
      setSuccessUpdate(true)
      setTimeout(() => {
        setSuccessUpdate(false)
      }, 2000)
    }
  }, [successWritingCUpdate])
  useEffect(() => {
    if (successWritingCDelete) {
      setOpenDelete(false)
    }
  }, [successWritingCDelete])

  const submitHandler = (e) => {
    e.preventDefault()

    const writingContestEdit = {
      _id: writing._id,
      title,
      question,
      contestDate,
    }
    dispatch(updateWritingContest(writingContestEdit))
  }

  const deleteWritingContestHandler = () => {
    dispatch(deleteWritingC(writing._id))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <form autoComplete='off' className={classes.formRoot} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Edit Writing Contest</h2>
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                labelText='Title'
                id='title'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  value: title,
                  placeholder: 'Enter Writing Contest title',
                  onChange: (e) => setTitle(e.target.value),
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                labelText='Question'
                id='question'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  value: question,
                  placeholder: 'Enter Writing Contest Question',
                  onChange: (e) => setQuestion(e.target.value),
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label='Contest Date'
                openTo='month'
                views={['year', 'month']}
                value={contestDate}
                onChange={setContestDate}
                format='MMMM yyyy'
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
            </Grid>

            {errorWritingCUpdate && (
              <Grid item xs={12}>
                <Message message={<span>{errorWritingCUpdate}</span>} color='info' />
              </Grid>
            )}

            <Grid item xs={12} md={9}>
              <Button type='submit' color={successUpdate ? 'success' : 'primary'} block>
                {loadingWritingCUpdate ? <Loader size={'sm'} /> : successUpdate ? 'Updated Successfully' : 'Update'}
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button type='button' color='danger' block onClick={() => setOpenDelete(true)}>
                {loadingWritingCDelete ? <Loader /> : 'Delete Contest'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <CustomModal
        title='Delete Writing Contest?'
        open={openDelete}
        setOpen={setOpenDelete}
        acceptText='Delete'
        acceptHandler={deleteWritingContestHandler}
      >
        Are you sure you want to delete this Writing Contest?
        {errorWritingCDelete && <Message message={<span>{errorWritingCDelete}</span>} color='info' />}
      </CustomModal>
    </Grid>
  )
}

export default WritingContestEditForm

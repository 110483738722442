import { container } from '../../../../assets/jss/material-ui-styles'

const styles = (theme) => ({
	container,
	brand: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	titleBig: {
		width: '100%',
		textAlign: 'center',
		fontSize: '120px',
		fontWeight: '600',
		lineHeight: '1.1',
		display: 'inline-block',
		position: 'relative',
		color: '#f64ebd',
		textShadow: '7px 10px 15px rgb(7 44 25)',
	},
	root: {
		width: '100%',
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	rootVideo: {
		width: '100%',
		height: 'fit-content',
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	videoContainer: {
		paddingTop: '100px',
		width: '100%',
		maxWidth: '1200px',
		height: 'fit-content',
	},
	videoItem: {
		width: '100%',
		paddingTop: '10px',
		height: 'fit-content',
	},
	sections: {
		maxWidth: '1440px',
		margin: 'auto',
	},
	sectionPosts: {
		width: '100%',
		padding: '0 20px',
		paddingTop: '30px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	infiniteScroll: {
		width: '100% !important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	infiniteScrollContainer: {
		width: '100%',
		maxWidth: '1200px',
		display: 'flex',
		paddingTop: '30px',
		justifyContent: 'space-between',
		'& > div:first-child': {
			width: '100% !important',
		},
		[theme.breakpoints.down('md')]: {
			padding: '30px 20px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '30px 10px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '30px 0px',
			justifyContent: 'center',
		},
	},
	infiniteScrollSecondaryChild: {
		width: '100%',
		maxWidth: '1200px',
	},
	infiniteScrollItems: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 !important',
	},
	contentLine: {
		textAlign: 'center',
	},
	moreVideos: {
		color: '#1f7a2f',
		fontSize: 18,
		textTransform: 'capitalize',
		padding: '0 10px',
	},
	'@media screen and (max-width: 580px)': {
		titleBig: {
			textAlign: 'center',
			fontSize: '80px',
		},
	},
	rightSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '20px 0',
		height: '100%',
		'& h2': {
			textAlign: 'center',
		},
	},
	schoolIcon: {
		fontSize: 70,
		color: '#9c27b0',
	},
	contentLine: {
		textAlign: 'center',
	},
})

export default styles

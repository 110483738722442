import { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles/parallaxStyle'

const useStyles = makeStyles(styles)

const Parallax = ({ filter, className, children, style, image, xsmall, small, medium }) => {
	let windowScrollTop
	if (window.innerWidth >= 768) {
		windowScrollTop = window.pageYOffset / 2
	} else {
		windowScrollTop = 0
	}
	const [transform, setTransform] = useState('translate3d(0,' + windowScrollTop + 'px,0)')
	useEffect(() => {
		if (window.innerWidth >= 768) {
			window.addEventListener('scroll', resetTransform)
		}
		return function cleanup() {
			if (window.innerWidth >= 768) {
				window.removeEventListener('scroll', resetTransform)
			}
		}
	})
	const resetTransform = () => {
		const windowScrollTop = window.pageYOffset / 2
		setTransform('translate3d(0,' + windowScrollTop + 'px,0)')
	}

	const classes = useStyles()
	const parallaxClasses = classNames({
		[classes.parallax]: true,
		[classes.filter]: filter,
		[classes.xsmall]: xsmall,
		[classes.small]: small,
		[classes.medium]: medium,
		[className]: className !== undefined,
	})
	return (
		<div
			className={parallaxClasses}
			style={{
				...style,
				backgroundImage: 'url(' + image + ')',
				transform: transform,
			}}
		>
			{children}
		</div>
	)
}

Parallax.propTypes = {
	className: PropTypes.string,
	filter: PropTypes.bool,
	children: PropTypes.node,
	style: PropTypes.string,
	image: PropTypes.string,
	small: PropTypes.bool,
}

export default Parallax

const styles = {
  centerName: {
    textAlign: 'center',
  },
  centerLogo: {
    width: '90px',
    height: '90px',
    boxShadow: '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
  },
  centerInfoSection: {
    border: '2px solid #e5e5e5',
    borderRadius: '16px',
    minHeight: '150px',
    height: '100%',
    padding: '20px',
    color: '#65676b',
    fontSize: 17,
  },
  planText: {
    textTransform: 'capitalize',
  },
  countryFlagProfile: {
    width: '35px',
    borderRadius: '5px',
  },

  '@media screen and (max-width: 960px)': {
    centerLogoRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}

export default styles

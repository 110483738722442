import { Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/sponsorsStyles'

const useStyles = makeStyles(styles)

const Sponsors = () => {
	const classes = useStyles()

	return (
		<Grid container className={classes.sponsorsSection}>
			<Grid item xs={12}>
				<h4>Our Sponsors</h4>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={3} justifyContent='center'>
					{/* <Grid item xs={4} sm={3} md={2}>
            <img src='/assets/img/sponsors/sponsor-1.png' alt='Sponsor 1' className={classes.imageSponsor} />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <img src='/assets/img/sponsors/sponsor-2.png' alt='Sponsor 2' className={classes.imageSponsor} />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <img src='/assets/img/sponsors/sponsor-3.png' alt='Sponsor 3' className={classes.imageSponsor} />
          </Grid> */}
					<Grid item xs={4} sm={3} md={2}>
						<img src='/assets/img/sponsors/sponsor-4.png' alt='Sponsor 4' className={classes.imageSponsor} />
					</Grid>
					<Grid item xs={4} sm={3} md={2}>
						<img src='/assets/img/sponsors/sponsor-5.png' alt='Sponsor 5' className={classes.imageSponsor} />
					</Grid>
					{/* <Grid item xs={4} sm={3} md={2}>
            <img src='/assets/img/sponsors/sponsor-6.png' alt='Sponsor 6' className={classes.imageSponsor} />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <img src='/assets/img/sponsors/sponsor-7.png' alt='Sponsor 7' className={classes.imageSponsor} />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <img src='/assets/img/sponsors/sponsor-8.png' alt='Sponsor 8' className={classes.imageSponsor} />
          </Grid> */}
					<Grid item xs={4} sm={3} md={2}>
						<img src='/assets/img/sponsors/sponsor-9.png' alt='Sponsor 9' className={classes.imageSponsor} />
					</Grid>
					<Grid item xs={4} sm={3} md={2}>
						<img src='/assets/img/sponsors/sponsor-10.png' alt='Sponsor 10' className={classes.imageSponsor} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Sponsors

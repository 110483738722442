import { useState } from 'react'
import { Grid, InputAdornment, makeStyles } from '@material-ui/core'
import { MailOutline } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import CustomInput from '../../../components/CustomInput/CustomInput'
import styles from './styles/forgotPasswordScreenStyles'

const useStyles = makeStyles(styles)

const ForgotPasswordScreen = ({ history }) => {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const resetPasswordEmailHandler = (e) => {
    e.preventDefault()
    setEmailSent(true)
  }

  return (
    <>
      <Meta title={'Forgot Password - Planet Fraternity'} />
      <Grid container className={classes.root}>
        <Grid item sm={6} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img src='/assets/img/logo-planetfraternity100.png' alt='logo Planet Fraternity' loading='lazy' />
            </Grid>
            <Grid item xs={12}>
              {emailSent ? (
                <p>
                  We have sent an email to your account <strong>{email}</strong> with a link to recover your password.
                </p>
              ) : (
                <>
                  <p>Please enter your email address. You will receive a link to create a new password.</p>
                  <form onSubmit={resetPasswordEmailHandler}>
                    <CustomInput
                      labelText='Email Address'
                      id='emailaddress'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'email',
                        value: email,
                        placeholder: 'Enter a valid email address',
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <MailOutline />
                          </InputAdornment>
                        ),
                        required: true,
                      }}
                    />
                    <Button type='submit' color='primary' block>
                      Reset Password
                    </Button>
                  </form>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <p>
                Return to the{' '}
                <Button link className={classes.btnLink} onClick={() => history.push('/auth/login')}>
                  <span>Sign In</span>
                </Button>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPasswordScreen

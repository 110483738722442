import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, Collapse, makeStyles } from '@material-ui/core'
import {
	Accessibility,
	AccountBalance,
	AccountBox,
	Announcement,
	Collections,
	Compare,
	Euro,
	ExitToApp,
	ExpandLess,
	ExpandMore,
	FormatListNumbered,
	Group,
	ImportContacts,
	LocalActivity,
	LocationCity,
	Notes,
	People,
	PeopleSharp,
	Public,
} from '@material-ui/icons'
import Button from '../Buttons/Button'
import { logout } from '../../redux/actions/userActions'
import styles from './styles/cpsidebarStyles'

const useStyles = makeStyles(styles)

const CPSidebar = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const history = useHistory()

	const [openMagazines, setOpenMagazines] = useState(false)

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<div id='sidebar-wrapper'>
			<Button block link className={classes.brand} onClick={() => history.push('/teacher/dashboard')}>
				<img
					src='/assets/img/logo-planetfraternity100.png'
					className={classes.logoIcon}
					alt='Planet Fraternity Icon'
					loading='lazy'
				/>
			</Button>
			<ul className={classes.sidebarMenu}>
				<li className={classes.sidebarMenuHeader}>MAIN NAVIGATION</li>
				<li>
					<Button block link className={classes.likeLink} onClick={() => history.push('/teacher/profile')}>
						<span>
							<AccountBox color='primary' />
							Profile
						</span>
					</Button>
				</li>
				<li>
					<Button block link className={classes.likeLink} onClick={() => history.push('/teacher/school/profile')}>
						<span>
							<LocationCity color='error' />
							School Profile
						</span>
					</Button>
				</li>
				<li>
					<Button block link className={classes.likeLink} onClick={() => history.push('/teacher/student-list')}>
						<span>
							<FormatListNumbered color='secondary' />
							Student List
						</span>
					</Button>
				</li>
				<li>
					<Button block link className={classes.likeLink} onClick={() => history.push('/teacher/school-teams')}>
						<span>
							<People color='error' />
							School Teams
						</span>
					</Button>
				</li>
				<li>
					<Button block link className={classes.likeLink} onClick={() => history.push('/')}>
						<span>
							<Public color='primary' />
							Go to Website
						</span>
					</Button>
				</li>
				<li>
					<Button block link className={classes.likeLink} onClick={() => history.push('/teacher/information')}>
						<span>
							<ImportContacts color='secondary' />
							Resources
						</span>
					</Button>
				</li>
				{userInfo && userInfo.isAdmin && (
					<>
						<li className={classes.sidebarMenuHeader}>ADMIN NAVIGATION</li>
						<li>
							<Button
								block
								link
								className={classes.likeLink}
								onClick={() => history.push('/admin/centers-pending-subscriptions')}
							>
								<span>
									<AccountBalance color='error' />
									Pending Subscriptions
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/centers')}>
								<span>
									<AccountBalance color='secondary' />
									Centers
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/teachers')}>
								<span>
									<People color='primary' />
									Teachers
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/students')}>
								<span>
									<People color='error' />
									Students
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/merge-centers')}>
								<span>
									<Group color='primary' />
									Merge Centers
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/merge-little-pf')}>
								<span>
									<Group color='primary' />
									Merge Little PF Centers
								</span>
								<small className={classes.badgeNew}>New</small>
							</Button>
						</li>
						{/* <li>
              <Button
                block
                link
                className={classes.likeLink}
                onClick={() => history.push('/admin/unpartnered-centers')}
              >
                <span>
                  <AccountBalance color='error' />
                  Unpartnered Centers
                </span>
              </Button>
            </li> */}
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/teams')}>
								<span>
									<People color='secondary' />
									Teams Admin
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/price-per-country')}>
								<span>
									<Euro color='error' />
									Price per Country
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/surveys')}>
								<span>
									<Notes color='primary' />
									Surveys
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/testimonials')}>
								<span>
									<Announcement color='secondary' />
									Testimonials
								</span>
							</Button>
						</li>

						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/miniblog')}>
								<span>
									<Compare color='error' />
									Blog
								</span>
							</Button>
						</li>

						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/solidarity-gallery')}>
								<span>
									<Collections color='primary' />
									Gallery Solidarity
								</span>
							</Button>
						</li>
						<li>
							<Button
								block
								link
								className={classes.likeLink}
								onClick={() => history.push('/admin/students-country-posts')}
							>
								<span>
									<PeopleSharp color='secondary' />
									Students Country Posts
								</span>
							</Button>
						</li>
						<li>
							<Button block link className={classes.likeLink} onClick={() => history.push('/admin/lets-share-talents')}>
								<span>
									<Accessibility color='secondary' />
									Let's Share Talents
								</span>
							</Button>
						</li>

						{userInfo.isSuper && (
							<>
								<li className={classes.sidebarMenuHeader}>SUPER ADMIN NAVIGATION</li>
								<li>
									<Button block link className={classes.likeLink} onClick={() => history.push('/admin/users')}>
										<span>
											<People color='error' />
											Users
										</span>
									</Button>
								</li>
								<li>
									<Button block link className={classes.likeLink} onClick={() => history.push('/admin/topics')}>
										<span>
											<ImportContacts color='primary' />
											Topics
										</span>
									</Button>
								</li>
								<li>
									<Button block link className={classes.likeLink} onClick={() => setOpenMagazines(!openMagazines)}>
										<span>
											<ImportContacts color='secondary' />
											The Magazine
										</span>

										{openMagazines ? <ExpandLess /> : <ExpandMore />}
									</Button>
									<Collapse in={openMagazines} timeout='auto' unmountOnExit>
										<List component='div'>
											<Button
												block
												link
												className={classes.likeLink}
												onClick={() => history.push('/admin/writing-winners')}
											>
												<span>
													<LocalActivity className={classes.subLink} color='primary' />
													Writing Winners
												</span>
											</Button>
											<Button
												block
												link
												className={classes.likeLink}
												onClick={() => history.push('/admin/writing-contest')}
											>
												<span>
													<LocalActivity className={classes.subLink} color='primary' />
													Writing Contest
												</span>
											</Button>
										</List>
									</Collapse>
								</li>
							</>
						)}
					</>
				)}
				<li>
					<Button block link className={classes.likeLink} onClick={() => dispatch(logout())}>
						<span>
							<ExitToApp color='secondary' />
							Logout
						</span>
					</Button>
				</li>
			</ul>
		</div>
	)
}

export default CPSidebar

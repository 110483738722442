import { makeStyles } from '@material-ui/core'
import { PhotoSizeSelectActual } from '@material-ui/icons'
import styles from '../styles/gallerySolidaryRightSectionStyles.js'

const useStyles = makeStyles(styles)

const GallerySolidaryRightSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.rightSection}>
      <PhotoSizeSelectActual className={classes.magazineIcon} />
      <h2>Select image to preview the info.</h2>
    </div>
  )
}

export default GallerySolidaryRightSection

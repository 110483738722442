import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import CustomModal from '../../../components/Modal/CustomModal'
import AgeAccordionCard from './components/AgeAccordionCard'
import TeamsByAgesAutomateModal from './components/TeamsByAgesAutomateModal'
import TeamsByAgesRightSide from './components/TeamsByAgesRightSide'
import { getTeamsByAge, prevTeamsByAge, automateTeamGroups } from '../../../redux/actions/teamGroupActions'
import { TEAM_GROUP_BYAGE_PREV_RESET } from '../../../redux/constants/teamGroupConstants'
import styles from './styles/teamsByAgesScreenStyles'

const useStyles = makeStyles(styles)

const TeamsByAgesScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [teamsArray, setTeamsArray] = useState([])
  const [groups, setGroups] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)

  const { loadingTeamsByAge, teams } = useSelector((state) => state.teamsByAge)
  const { teamsPrev } = useSelector((state) => state.teamsByAgePrev)
  const { loadingTeamsGroupsAutomation, successTeamsGroupsAutomation } = useSelector(
    (state) => state.teamsGroupsAutomation
  )

  // Call unpartnered centers by age
  useEffect(() => {
    dispatch(getTeamsByAge())
    return () => {
      dispatch({ type: TEAM_GROUP_BYAGE_PREV_RESET })
    }
  }, [dispatch])
  // Teams to state to handle the out group
  useEffect(() => {
    if (teams) {
      setTeamsArray(teams)
    }
  }, [teams])
  useEffect(() => {
    if (successTeamsGroupsAutomation) {
      setOpenConfirm(false)
      dispatch(getTeamsByAge())
      dispatch({ type: TEAM_GROUP_BYAGE_PREV_RESET })
    }
  }, [dispatch, successTeamsGroupsAutomation])

  // Assing the teams preview to groups in state
  useEffect(() => {
    if (teamsPrev) {
      setGroups(teamsPrev.teams)
    }
  }, [teamsPrev])

  // Get the teams preview
  const getTeamsPreviewHandler = (ageRangeGroups) => {
    dispatch(prevTeamsByAge(ageRangeGroups))
  }

  // Confirm group automation
  const confirmGroupAutomation = () => {
    let groupsDataObj = {
      ageRange: teamsPrev?.ageRange,
      groups,
    }
    dispatch(automateTeamGroups(groupsDataObj))
  }

  return (
    <>
      <Meta title={'Teams By Age - Planet Fraternity'} />
      <Grid container spacing={3} className={classes.root}>
        {/* Page Title */}
        <Grid item xs={12}>
          <h1>Unpartnered Groups By Age</h1>
        </Grid>
        {/* Accordion Cards */}
        <Grid item xs={12} md={4} className={classes.accordionRoot}>
          {loadingTeamsByAge ? (
            <Loader />
          ) : teamsArray.length < 1 ? (
            <p>Unpartnered groups not found.</p>
          ) : (
            teamsArray?.map((team, index) => (
              <AgeAccordionCard key={index} team={team} getTeamsPreviewHandler={getTeamsPreviewHandler} />
            ))
          )}
        </Grid>
        {/* Right side with groups preview and group title */}
        <Grid item xs={12} md={8}>
          <TeamsByAgesRightSide groups={groups} setGroups={setGroups} setOpenConfirm={setOpenConfirm} />
        </Grid>
      </Grid>
      <CustomModal title='Automate Teams and Groups' open={openConfirm} setOpen={setOpenConfirm} actions={false}>
        <TeamsByAgesAutomateModal
          setOpenConfirm={setOpenConfirm}
          confirmGroupAutomation={confirmGroupAutomation}
          loadingTeamsGroupsAutomation={loadingTeamsGroupsAutomation}
        />
      </CustomModal>
    </>
  )
}

export default TeamsByAgesScreen

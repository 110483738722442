import { Grid } from '@material-ui/core'
import { Route, Switch } from 'react-router-dom'
import meetPanelRoutes from '../../routes/meet-panel-routes'
import routesAuth from '../../routes/platform-access-routes'

const SimpleLayout = () => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Switch>
					{meetPanelRoutes.map((route, i) => (
						<Route key={i} path={`/meet${route.path}`} component={route.comp} exact={route.exact} />
					))}
					{routesAuth.map((route, i) => (
						<Route
							key={i}
							path={`/auth${route.path}`}
							layout={route.layout}
							component={route.comp}
							exact={route.exact}
						/>
					))}
				</Switch>
			</Grid>
		</Grid>
	)
}

export default SimpleLayout

import * as types from '../constants/logConstants'

export const logRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOG_REGISTER_REQUEST:
      return { loading: true }
    case types.LOG_REGISTER_SUCCESS:
      return { loading: false, success: true }
    case types.LOG_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    case types.LOG_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const logListReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOG_LIST_REQUEST:
      return { loading: true }
    case types.LOG_LIST_SUCCESS:
      return { loading: false, logs: action.payload }
    case types.LOG_LIST_FAIL:
      return { loading: false, error: action.payload }
    case types.LOG_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const logListTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOG_LIST_TEAMS_REQUEST:
      return { loading: true }
    case types.LOG_LIST_TEAMS_SUCCESS:
      return { loading: false, logsTeam: action.payload }
    case types.LOG_LIST_TEAMS_FAIL:
      return { loading: false, error: action.payload }
    case types.LOG_LIST_TEAMS_RESET:
      return {}
    default:
      return state
  }
}

export const logDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOG_DETAILS_REQUEST:
      return { loading: true }
    case types.LOG_DETAILS_SUCCESS:
      return { loading: false, log: action.payload }
    case types.LOG_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case types.LOG_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const logDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOG_DELETE_REQUEST:
      return { loading: true }
    case types.LOG_DELETE_SUCCESS:
      return { loading: false, success: true }
    case types.LOG_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case types.LOG_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const logDeleteManyReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOG_DELETE_MANY_REQUEST:
      return { loading: true }
    case types.LOG_DELETE_MANY_SUCCESS:
      return { loading: false, success: true }
    case types.LOG_DELETE_MANY_FAIL:
      return { loading: false, error: action.payload }
    case types.LOG_DELETE_MANY_RESET:
      return {}
    default:
      return state
  }
}

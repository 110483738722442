export const WRITING_C_REGISTER_REQUEST = 'WRITING_C_REGISTER_REQUEST'
export const WRITING_C_REGISTER_SUCCESS = 'WRITING_C_REGISTER_SUCCESS'
export const WRITING_C_REGISTER_FAIL = 'WRITING_C_REGISTER_FAIL'
export const WRITING_C_REGISTER_RESET = 'WRITING_C_REGISTER_RESET'

export const WRITING_C_LIST_REQUEST = 'WRITING_C_LIST_REQUEST'
export const WRITING_C_LIST_SUCCESS = 'WRITING_C_LIST_SUCCESS'
export const WRITING_C_LIST_FAIL = 'WRITING_C_LIST_FAIL'
export const WRITING_C_LIST_RESET = 'WRITING_C_LIST_RESET'

export const WRITING_C_DETAILS_REQUEST = 'WRITING_C_DETAILS_REQUEST'
export const WRITING_C_DETAILS_SUCCESS = 'WRITING_C_DETAILS_SUCCESS'
export const WRITING_C_DETAILS_FAIL = 'WRITING_C_DETAILS_FAIL'
export const WRITING_C_DETAILS_RESET = 'WRITING_C_DETAILS_RESET'

export const WRITING_C_CURRENT_REQUEST = 'WRITING_C_CURRENT_REQUEST'
export const WRITING_C_CURRENT_SUCCESS = 'WRITING_C_CURRENT_SUCCESS'
export const WRITING_C_CURRENT_FAIL = 'WRITING_C_CURRENT_FAIL'
export const WRITING_C_CURRENT_RESET = 'WRITING_C_CURRENT_RESET'

export const WRITING_C_UPDATE_REQUEST = 'WRITING_C_UPDATE_REQUEST'
export const WRITING_C_UPDATE_SUCCESS = 'WRITING_C_UPDATE_SUCCESS'
export const WRITING_C_UPDATE_FAIL = 'WRITING_C_UPDATE_FAIL'
export const WRITING_C_UPDATE_RESET = 'WRITING_C_UPDATE_RESET'

export const WRITING_C_DELETE_REQUEST = 'WRITING_C_DELETE_REQUEST'
export const WRITING_C_DELETE_SUCCESS = 'WRITING_C_DELETE_SUCCESS'
export const WRITING_C_DELETE_FAIL = 'WRITING_C_DELETE_FAIL'
export const WRITING_C_DELETE_RESET = 'WRITING_C_DELETE_RESET'

export const WRITING_PARTICIPATION_REGISTER_REQUEST = 'WRITING_PARTICIPATION_REGISTER_REQUEST'
export const WRITING_PARTICIPATION_REGISTER_SUCCESS = 'WRITING_PARTICIPATION_REGISTER_SUCCESS'
export const WRITING_PARTICIPATION_REGISTER_FAIL = 'WRITING_PARTICIPATION_REGISTER_FAIL'
export const WRITING_PARTICIPATION_REGISTER_RESET = 'WRITING_PARTICIPATION_REGISTER_RESET'

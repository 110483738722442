import { Card, CardHeader, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/testimonialListCardStyles'

const useStyles = makeStyles(styles)

const TestimonialListCard = ({ setTestimonialDetails, testimonial, index }) => {
  const classes = useStyles()

  return (
    <div className='user-card'>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeaderRoot}
          action={
            <CustomDropdown
              left
              caret={false}
              buttonText={
                <div className={classes.btnwrapper}>
                  <MoreVert />
                </div>
              }
              buttonProps={{
                className: classes.navLink + ' ' + classes.imageDropdownButton,
                color: 'transparent',
              }}
              dropdownList={[<div onClick={() => setTestimonialDetails(testimonial)}>View Testimonial</div>]}
            />
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheaderTypographyProps={{ variant: 'caption' }}
          title={`Testimonial ${index + 1}`}
          subheader={`Teacher: ${testimonial?.teacher?.name}`}
        />
      </Card>
    </div>
  )
}

export default TestimonialListCard

const styles = {
  notResources: {
    witdth: '100%',
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px',
    marginBottom: '13rem',
    '& h3': {
      fontSize: '20px',
    },
    '& h4': {
      fontSize: '18px',
    },
  },
}

export default styles

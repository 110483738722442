import * as types from '../constants/activeSurveyConstants'

export const activeSurveyReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ACTIVE_SURVEY_REQUEST:
      return { loadingActiveSurvey: true }
    case types.ACTIVE_SURVEY_SUCCESS:
      return {
        loadingActiveSurvey: false,
        successActiveSurvey: true,
        activeSurveys: action.payload,
      }
    case types.ACTIVE_SURVEY_FAIL:
      return {
        loadingActiveSurvey: false,
        errorActiveSurvey: action.payload,
      }
    case types.ACTIVE_SURVEY_RESET:
      return {}
    default:
      return state
  }
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControl, Select, InputLabel, MenuItem, makeStyles } from '@material-ui/core'
import Button from '../../Buttons/Button'
import Loader from '../../Loader/Loader'
import Message from '../../Message/Message'
import CustomInput from '../../CustomInput/CustomInput'
import { groupAgesRanges } from '../../../utils/groupAgesRanges'
import { registerGroup } from '../../../redux/actions/groupActions'
import { getGroupsByCenter } from '../../../redux/actions/groupActions'
import { GROUP_REGISTER_RESET, groupCategories, littlePFAgesRanges } from '../../../redux/constants/groupConstants'
import styles from './styles/groupRegisterFormStyles'

const useStyles = makeStyles(styles)

const GroupRegisterForm = ({ center }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [name, setName] = useState('')
	const [ageRange, setAgeRange] = useState('')
	const [category, setCategory] = useState('common')

	const groupRegister = useSelector((state) => state.groupRegister)
	const { loadingGroupRegister, errorGroupRegister, successGroupRegister } = groupRegister

	useEffect(() => {
		if (!littlePFAgesRanges.includes(ageRange)) {
			setCategory('common')
		}
	}, [ageRange])

	const submitFormHandler = useCallback(
		(e) => {
			e.preventDefault()
			const createGroup = {
				name,
				center: center._id,
				ageRange,
				isInternal: center.isInternal,
				isLittleCategory: category === 'little',
			}
			dispatch(registerGroup(createGroup))
		},
		[category, name, ageRange],
	)

	const createdSuccessHandler = () => {
		dispatch(getGroupsByCenter(center._id))
		dispatch({ type: GROUP_REGISTER_RESET })
		setName('')
		setAgeRange('')
	}

	return (
		<Grid container className={classes.root}>
			{successGroupRegister ? (
				<>
					<Grid item xs={12}>
						<h2 className={classes.formTitle}>Group created successfully</h2>
						<p className={classes.formSubtitle}>
							Remember that you can rename the groups on the <strong>Student List</strong> screen.
						</p>
						<p className={classes.formSubtitle}>
							Now click "OK" and go to <b>"GROUPS"</b> Tab.
						</p>
					</Grid>
					<Grid item xs={12}>
						<Button color='primary' block onClick={createdSuccessHandler}>
							OK
						</Button>
					</Grid>
				</>
			) : (
				<Grid item xs={12} md={12}>
					<h2 className={classes.formTitle}>New Group</h2>
					<p className={classes.formSubtitle}>
						This name have to be <strong>unique</strong> in your center.
					</p>
					<form onSubmit={submitFormHandler} autoComplete='off'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<CustomInput
									labelText='Group Name'
									id='name-student'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: 'text',
										value: name,
										placeholder: 'Example 1A or 1 ESO',
										onChange: (e) => setName(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id='ages-range' className={classes.selectLabel}>
										Ages Range
									</InputLabel>
									<Select
										labelId='ages-range'
										id='agesrange'
										value={ageRange}
										onChange={(e) => setAgeRange(e.target.value)}
										inputProps={{ required: true }}
										className={classes.selectInput}
									>
										<MenuItem value='' disabled>
											Select Age Range
										</MenuItem>

										{groupAgesRanges.map((age) => (
											<MenuItem key={age} value={age}>
												{age}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							{littlePFAgesRanges.includes(ageRange) && (
								<Grid item xs={12}>
									<FormControl className={classes.formControl}>
										<InputLabel id='group-category' className={classes.selectLabel}>
											Group category
										</InputLabel>
										<Select
											labelId='group-category'
											id='groupcategory'
											value={category}
											onChange={(e) => setCategory(e.target.value)}
											inputProps={{ required: true }}
											className={classes.selectInput}
										>
											<MenuItem value='' disabled>
												Select group category
											</MenuItem>

											{groupCategories.map((v) => (
												<MenuItem value={v.value}>{v.label}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							)}
							<Grid item xs={12}>
								<Button type='submit' color='primary' block>
									{loadingGroupRegister ? <Loader size={'sm'} /> : 'Create Group'}
								</Button>
							</Grid>
							{errorGroupRegister && (
								<Grid item xs={12}>
									<Message message={<span>{errorGroupRegister}</span>} />
								</Grid>
							)}
						</Grid>
					</form>
				</Grid>
			)}
		</Grid>
	)
}

export default GroupRegisterForm

import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'
const styles = {
  contentItemsEnd,
  infiniteScroll,
  root: {
    padding: '30px',
    '& h1': {
      lineHeight: 'normal',
    },
  },
  lastLine: {
    textAlign: 'center',
  },
}
export default styles

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import Loader from '../../../../components/Loader/Loader'
import { userUpdateMyInfo } from '../../../../redux/actions/userActions'
import { USER_UPDATE_RESET } from '../../../../redux/constants/userConstants'
import styles from '../styles/userCardFormStyle'

const useStyles = makeStyles(styles)

const UserCardForm = ({ user }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [successUpdated, setSuccessUpdate] = useState(false)

    const userUpdate = useSelector((state) => state.userUpdate)
    const { loadingUserUpdate, successUserUpdate } = userUpdate

    useEffect(() => {
        if (user) {
            setName(user.name)
        }
    }, [user])
    useEffect(() => {
        if (successUserUpdate) {
            setSuccessUpdate(true)
            dispatch({ type: USER_UPDATE_RESET })
            setTimeout(() => {
                setSuccessUpdate(false)
            }, 2000)
        }
    }, [successUserUpdate, dispatch])

    const submitEditFormHandler = (e) => {
        e.preventDefault()
        const updateUser = {
            _id: user._id,
            name,
            password,
        }
        dispatch(userUpdateMyInfo(updateUser))
        if (password) {
            setPassword('')
        }
    }

    return (
        <>
            <form onSubmit={submitEditFormHandler} autoComplete='off'>
                <CustomInput
                    labelText='Name'
                    id='newname'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'text',
                        value: name,
                        placeholder: 'Enter complete name',
                        onChange: (e) => setName(e.target.value),
                        required: true,
                    }}
                />
                <CustomInput
                    labelText='Password'
                    id='password'
                    formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                    }}
                    inputProps={{
                        type: 'password',
                        value: password,
                        placeholder: 'Enter password',
                        onChange: (e) => setPassword(e.target.value),
                    }}
                />
                <Button
                    type='submit'
                    block
                    color={successUpdated ? 'success' : 'primary'}
                    disabled={successUpdated && true}
                    className={classes.btnUpdate}
                >
                    {loadingUserUpdate ? (
                        <Loader size={'sm'} />
                    ) : successUpdated ? (
                        'Updated Successfully'
                    ) : (
                        'Update User'
                    )}
                </Button>
            </form>
        </>
    )
}

export default UserCardForm

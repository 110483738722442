const styles = {
    sections: {
        maxWidth: '1440px',
        margin: '90px auto 0',
        padding: 30,
    },
    cardRegister: {
        boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        '& h1': {
            textAlign: 'center',
            padding: '10px 0 5px',
        },
        '& p': {
            textAlign: 'center',
            color: '#aba8a8',
            margin: 0,
        },
    },
    emailSent: {
        height: '75vh',
        textAlign: 'center',
        '& img': {
            width: '200px',
            paddingTop: '30px',
        },
    },
}

export default styles

import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Accessibility } from '@material-ui/icons'
import Parallax from '../../../components/Parallax/Parallax'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import TalentVideoCard from './components/TalentVideoCard'
import SharedTalentComponent from '../../../components/SharedTalentComponent/SharedTalentComponent'
import { TALENT_ACTIVE_LIST_RESET, TALENT_SINGLE_RESET } from '../../../redux/constants/talentsConstants'
import { getSingleTalent, getTalentsByStatus } from '../../../redux/actions/shareTalentsActions'
import styles from './styles/letsShareTalentsScreenStyles'

const useStyles = makeStyles(styles)

const LetsShareTalentsScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const history = useHistory()
	const { t } = useTranslation('global')

	const activeTalents = useSelector((state) => state.talentActiveList?.talents ?? [])
	const thatsIt = useSelector((state) => state.talentActiveList?.thatsIt ?? false)
	const loadingTalentActiveList = useSelector((state) => state.talentActiveList?.loadingTalentActiveList)
	const { talent } = useSelector((state) => state.talentSingle)

	const count = 7

	const [data, setData] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [start, setStart] = useState(1)
	const [PostSelected, setPostSelected] = useState(null)
	const substring = window.location.pathname.substring(
		window.location.pathname.lastIndexOf('/') + 1,
		window.location.pathname.length,
	)

	const getVideo = async (newId) => {
		setPostSelected(newId)
		let response = await dispatch(getSingleTalent(newId))
		if (response === 200) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		} else {
			setPostSelected(null)
			history.push('/lets-share-talents')
		}
	}

	useEffect(() => {
		const newId = substring !== 'lets-share-talents' ? substring : null
		if (!newId) {
			setPostSelected(null)
			dispatch({ type: TALENT_SINGLE_RESET })
		}
	}, [substring])
	useEffect(() => {
		dispatch(getTalentsByStatus(true, count, 0))
		const newId = substring !== 'lets-share-talents' ? substring : null
		if (newId) {
			getVideo(newId)
		}

		return () => {
			dispatch({ type: TALENT_ACTIVE_LIST_RESET })
		}
	}, [])
	useEffect(() => {
		if (activeTalents.length) {
			setData([...activeTalents])
		}
	}, [activeTalents])
	useEffect(() => {
		if (thatsIt) {
			setHasMore(false)
		} else {
			setHasMore(true)
		}
	}, [thatsIt])

	const refetch = useCallback(() => {
		if (hasMore) {
			setStart(start + 1)
			dispatch(getTalentsByStatus(true, count, start))
		}
	}, [start, hasMore])

	if (PostSelected) {
		const dataToRender = data.filter((d) => d?._id !== PostSelected)

		return (
			<>
				<Meta title={'Let \'s Share Talents - Planet Fraternity'} />
				<div className={classes.rootVideo}>
					<GridContainer className={classes.videoContainer}>
						<GridItem xs={12} md={dataToRender.length === 0 ? 12 : 8} className={classes.videoItem}>
							<SharedTalentComponent talent={talent} />
						</GridItem>
						{dataToRender.length > 0 && (
							<GridItem xs={12} md={4}>
								<GridItem className={classes.moreVideos} xs={12}>
									<h1>More videos</h1>
								</GridItem>

								{loadingTalentActiveList ? (
									<Loader />
								) : data.length > 0 ? (
									<InfiniteScroll
										dataLength={data.length}
										next={refetch}
										hasMore={hasMore}
										height={data.length === 1 ? 'fit-content' : '700px'}
										className={classes.infiniteScroll}
									>
										<GridContainer className={classes.infiniteScrollContainerList}>
											{dataToRender.map((talent) => (
												<GridItem key={talent._id} className={classes.infiniteScrollItems} xs={12}>
													<TalentVideoCard talent={talent} getVideo={getVideo} />
												</GridItem>
											))}
										</GridContainer>
									</InfiniteScroll>
								) : (
									<p>no results</p>
								)}
								{dataToRender.length === 0 && (
									<Grid item md={12} sm={12} xs={12}>
										<Typography variant='h5'>{t('countries.countryPosts.noResults')}</Typography>
									</Grid>
								)}
							</GridItem>
						)}
					</GridContainer>
				</div>
			</>
		)
	}

	return (
		<>
			<Meta title={'Let \'s Share Talents - Planet Fraternity'} />
			<Parallax image={'/assets/img/public-site/bg-i-can.png'}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem>
							<div className={classes.brand}>
								<h1 className={classes.titleBig}>Let's Share Talents</h1>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classes.root}>
				{data.length > 0 ? (
					<GridContainer className={classes.infiniteScrollContainer}>
						<InfiniteScroll
							dataLength={data.length}
							next={refetch}
							height={'750px'}
							hasMore={hasMore}
							className={classes.infiniteScroll}
						>
							<GridContainer className={classes.infiniteScrollSecondaryChild}>
								{data.map((talent) => (
									<GridItem key={talent._id} className={classes.infiniteScrollItems} item md={4} sm={6} xs={12}>
										<TalentVideoCard talent={talent} getVideo={getVideo} />
									</GridItem>
								))}
							</GridContainer>
						</InfiniteScroll>
					</GridContainer>
				) : (
					<div className={classes.rightSection}>
						<Accessibility className={classes.schoolIcon} />
						<h2>{t('countries.countryPosts.noResults')}</h2>
					</div>
				)}
			</div>
		</>
	)
}

export default LetsShareTalentsScreen

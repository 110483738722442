import axios from 'axios'
import {
	STUDENTS_BY_CENTER_REQUEST,
	STUDENTS_BY_CENTER_SUCCESS,
	STUDENTS_BY_CENTER_FAIL,
	STUDENT_LIST_REQUEST,
	STUDENT_LIST_SUCCESS,
	STUDENT_LIST_FAIL,
	STUDENTS_REGISTER_MULTIPLE_REQUEST,
	STUDENTS_REGISTER_MULTIPLE_SUCCESS,
	STUDENTS_REGISTER_MULTIPLE_FAIL,
	STUDENT_BY_GROUP_REQUEST,
	STUDENT_BY_GROUP_SUCCESS,
	STUDENT_BY_GROUP_FAIL,
	STUDENT_UPDATE_REQUEST,
	STUDENT_UPDATE_SUCCESS,
	STUDENT_UPDATE_FAIL,
	STUDENT_DELETE_REQUEST,
	STUDENT_DELETE_SUCCESS,
	STUDENT_DELETE_FAIL,
	STUDENT_REGISTER_REQUEST,
	STUDENT_REGISTER_SUCCESS,
	STUDENT_REGISTER_FAIL,
} from '../constants/studentConstants'

export const registerStudent = (student) => async (dispatch, getState) => {
	try {
		dispatch({ type: STUDENT_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/students', student, config)

		dispatch({ type: STUDENT_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: STUDENT_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getStudents =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: STUDENT_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(`/api/students?count=${count}&start=${start}&keyword=${keyword}`, config)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/students?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: STUDENT_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: STUDENT_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const registerMultipleStudents = (multipleOptions) => async (dispatch, getState) => {
	try {
		dispatch({ type: STUDENTS_REGISTER_MULTIPLE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/students/multiple', multipleOptions, config)

		dispatch({ type: STUDENTS_REGISTER_MULTIPLE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: STUDENTS_REGISTER_MULTIPLE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getStudentsByCenter =
	(keyword = '', count, start, centerId) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: STUDENTS_BY_CENTER_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(
					`/api/students/center/${centerId}/?count=${count}&start=${start}&keyword=${keyword}`,
					config,
				)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/students/center/${centerId}/?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: STUDENTS_BY_CENTER_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: STUDENTS_BY_CENTER_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getStudentsByGroup =
	(keyword = '', count, start, groupId) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: STUDENT_BY_GROUP_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(
					`/api/students/group/${groupId}/?count=${count}&start=${start}&keyword=${keyword}`,
					config,
				)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/students/group/${groupId}/?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: STUDENT_BY_GROUP_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: STUDENT_BY_GROUP_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const updateStudent = (student) => async (dispatch, getState) => {
	try {
		dispatch({ type: STUDENT_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.put(`/api/students/${student._id}`, student, config)

		dispatch({ type: STUDENT_UPDATE_SUCCESS })
	} catch (error) {
		dispatch({
			type: STUDENT_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteStudent = (student, group) => async (dispatch, getState) => {
	try {
		dispatch({ type: STUDENT_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/students/${student}/${group}`, config)

		dispatch({ type: STUDENT_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: STUDENT_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

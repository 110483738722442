const styles = {
    cardSubTitle: {
        padding: 10,
        fontSize: '1rem',
        color: '#585858',
    },
    countryFlag: {
        width: '30px',
        borderRadius: '3px',
    },
}

export default styles

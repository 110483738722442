const styles = {
  emptyMsg: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 10,
    '& svg': {
      fontSize: '120px',
      color: '#43a047',
    },
  },
}

export default styles

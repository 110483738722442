import { textCenter } from '../../../../assets/jss/material-ui-styles'

const styles = {
  textCenter,
  formTitle: {
    margin: 0,
    padding: '10px 0 0 0',
    textAlign: 'center',
  },
  formControl: {
    margin: 0,
  },
  defPassMsg: {
    padding: '20px 0',
    margin: 0,
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  formControlSelect: {
    marginTop: '15px',
    minWidth: 120,
    width: '100%',
  },
}
export default styles

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { updateCountryPrice } from '../../../../redux/actions/pricePerCountryActions'

const UpdatePricePerCountryModal = ({ priceForUpdate, setPriceForUpdate }) => {
  const dispatch = useDispatch()

  const { loadingPricePerCountryUpdate } = useSelector((state) => state.pricePerCountryUpdate)

  const updatePriceHandler = () => {
    dispatch(updateCountryPrice(priceForUpdate))
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {priceForUpdate.name}
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          id='country-price'
          inputProps={{
            autoFocus: true,
            value: priceForUpdate.price,
            placeholder: 'Enter a Price',
            type: 'number',
            autoComplete: 'off',
            onChange: (e) => setPriceForUpdate({ ...priceForUpdate, price: e.target.value }),
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button block color='primary' onClick={updatePriceHandler}>
          {loadingPricePerCountryUpdate ? <Loader size='sm' /> : 'Update Price'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default UpdatePricePerCountryModal

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, FormControl, Select, InputLabel, MenuItem, makeStyles } from '@material-ui/core'
import Button from '../../Buttons/Button'
import Loader from '../../Loader/Loader'
import Message from '../../Message/Message'
import CustomInput from '../../CustomInput/CustomInput'
import { groupAgesRanges } from '../../../utils/groupAgesRanges'
import { upadteGroupInfo } from '../../../redux/actions/groupActions'
import styles from './styles/groupEditModalStyles'

const useStyles = makeStyles(styles)

const GroupEditModal = ({ setOpenEdit, group }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [name, setName] = useState('')
	const [ageRange, setAgesRange] = useState('')

	const groupUpdate = useSelector((state) => state.groupUpdate)
	const { loadingGroupUpdate, errorGroupUpdate, successGroupUpdate } = groupUpdate

	useEffect(() => {
		if (group) {
			setName(group.name)
			setAgesRange(group.ageRange)
		}
	}, [group])
	useEffect(() => {
		if (successGroupUpdate) {
			setOpenEdit(false)
		}
	}, [successGroupUpdate, setOpenEdit])

	const editGroupInfoHandler = (e) => {
		e.preventDefault()
		const groupEditInfo = {
			_id: group._id,
			name,
			ageRange,
		}
		dispatch(upadteGroupInfo(groupEditInfo))
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<p>
					Remember that this group name have to be <strong>unique</strong> in your center.
				</p>
				<form onSubmit={editGroupInfoHandler}>
					<CustomInput
						labelText={'Enter group name'}
						id={'group' + group._id}
						inputProps={{
							value: name,
							autoFocus: true,
							type: 'text',
							autoComplete: 'off',
							onChange: (e) => setName(e.target.value),
						}}
						formControlProps={{
							fullWidth: true,
						}}
					/>
					<FormControl className={classes.formControl}>
						<InputLabel id='ages-range' className={classes.selectLabel}>
							Age Range
						</InputLabel>
						<Select
							labelId='age-range'
							id='agerange'
							value={ageRange}
							onChange={(e) => setAgesRange(e.target.value)}
							inputProps={{ required: true }}
							className={classes.selectInput}
						>
							<MenuItem value='' disabled>
								Select Age Range
							</MenuItem>

							{groupAgesRanges.map((age) => (
								<MenuItem key={age} value={age}>
									{age}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Button type='submit' color='primary' className={classes.submitButton} block>
						{loadingGroupUpdate ? <Loader /> : 'Submit'}
					</Button>
				</form>
			</Grid>
			<Grid item xs={12}>
				{errorGroupUpdate && <Message message={<span>{errorGroupUpdate}</span>} color='info' />}
			</Grid>
		</Grid>
	)
}

export default GroupEditModal

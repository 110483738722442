import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, Box } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Message from '../../../components/Message/Message'
import CustomModal from '../../../components/Modal/CustomModal'
import BlogItemList from './components/BlogItemList'
import BlogItemRegisterFormModal from './components/BlogItemRegisterFormModal'
import BlogListCardForm from './components/BlogListCardForm'
import MiniBlogRightSection from './components/MiniBlogRightSection'
import MiniBlogButton from './components/MiniBlogButton'
import {
	MINIBLOG_DELETE_RESET,
	MINIBLOG_REGISTER_RESET,
	MINIBLOG_UPDATE_RESET,
} from '../../../redux/constants/miniBlogConstans'
import { getAllMiniBlogs } from '../../../redux/actions/miniBlogActions'
import styles from './styles/miniBlogListScreenStyles'

const useStyles = makeStyles(styles)

const MiniBlogListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [miniBlogItemArray, setMiniBlogItemArray] = useState([])
	const [miniBlogItemDetail, setMiniBlogItemDetail] = useState(null)
	const [open, setOpen] = useState(false)

	const { successMiniBlogRegister, miniBlog } = useSelector((state) => state.miniBlogRegister)
	const { successMiniBlogDelete } = useSelector((state) => state.miniBlogDelete)
	const { successMiniBlogUpdate, miniBlogUpdated } = useSelector((state) => state.miniBlogUpdate)
	const { miniBlogs, errorMiniBlogList } = useSelector((state) => state.miniBlogList)

	useEffect(() => {
		dispatch(getAllMiniBlogs)
	}, [successMiniBlogUpdate, successMiniBlogDelete, successMiniBlogRegister])
	useEffect(() => {
		if (miniBlogs) {
			setMiniBlogItemArray(miniBlogs)
		}
	}, [miniBlogs])
	useEffect(() => {
		if (successMiniBlogRegister || successMiniBlogDelete || successMiniBlogUpdate) {
			if (successMiniBlogRegister) {
				dispatch({ type: MINIBLOG_REGISTER_RESET })
				setMiniBlogItemDetail(null)
			} else if (successMiniBlogDelete) {
				let auxArray = miniBlogItemArray.map((item) => {
					if (item?._id === miniBlogItemDetail?._id) {
						return null
					}
					return item
				})
				setMiniBlogItemArray(auxArray)
				setMiniBlogItemDetail(null)
				dispatch({ type: MINIBLOG_DELETE_RESET })
			} else if (successMiniBlogUpdate) {
				let auxArray = miniBlogItemArray.map((item) => {
					if (item?._id === miniBlogItemDetail?._id) {
						return { ...miniBlogUpdated }
					}
					return item
				})
				setMiniBlogItemArray(auxArray)
				dispatch({ type: MINIBLOG_UPDATE_RESET })
			}
			setMiniBlogItemArray((prev) => [...prev, miniBlog])
			return true
		}
	}, [
		miniBlogItemDetail,
		miniBlogItemArray,
		miniBlog,
		miniBlogUpdated,
		successMiniBlogRegister,
		successMiniBlogDelete,
		successMiniBlogUpdate,
		dispatch,
	])
	useEffect(() => {
		if (successMiniBlogRegister) {
			setMiniBlogItemDetail(miniBlog)
		}
	}, [successMiniBlogRegister, miniBlogs])

	return (
		<div className={classes.root}>
			<Meta title={'MiniBlog List - Planet Fraternity'} />
			<Grid container spacing={3}>
				<Grid item xs={12} md={3}>
					<h1>MiniBlog List</h1>
				</Grid>
				<Grid item xs={12} md={9} className={classes.contentItemsEnd}>
					<MiniBlogButton setOpen={setOpen} />
				</Grid>
				<Grid item xs={12} md={4}>
					<Box className={classes.containerItems}>
						{miniBlogItemArray?.length > 0 ? (
							miniBlogItemArray.map((item, index) => (
								<div key={index}>
									<BlogItemList
										key={item?._id}
										numberItem={index}
										item={item}
										setMiniBlogItemDetail={setMiniBlogItemDetail}
									/>
								</div>
							))
						) : errorMiniBlogList === 'MiniBlogsPost not Found.' ? (
							<p className={classes.contentLine}>
								<b>Thats it.</b>
							</p>
						) : (
							errorMiniBlogList && (
								<Message color='danger' message={<p className={classes.contentLine}>{errorMiniBlogList}</p>} />
							)
						)}
					</Box>
				</Grid>
				<Grid item xs={12} md={8}>
					{miniBlogItemDetail !== null && !open ? (
						<BlogListCardForm item={miniBlogItemDetail} setCarrouselItem={setMiniBlogItemDetail} />
					) : (
						<MiniBlogRightSection />
					)}
				</Grid>
			</Grid>
			<CustomModal title='Post Something to Blog' open={open} setOpen={setOpen} actions={false}>
				<BlogItemRegisterFormModal setOpen={setOpen} />
			</CustomModal>
		</div>
	)
}

export default MiniBlogListScreen

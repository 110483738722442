import axios from 'axios'
import {
	WRITING_C_REGISTER_REQUEST,
	WRITING_C_REGISTER_SUCCESS,
	WRITING_C_REGISTER_FAIL,
	WRITING_C_LIST_REQUEST,
	WRITING_C_LIST_SUCCESS,
	WRITING_C_LIST_FAIL,
	WRITING_C_DETAILS_REQUEST,
	WRITING_C_DETAILS_SUCCESS,
	WRITING_C_DETAILS_FAIL,
	WRITING_C_UPDATE_REQUEST,
	WRITING_C_UPDATE_SUCCESS,
	WRITING_C_UPDATE_FAIL,
	WRITING_C_DELETE_REQUEST,
	WRITING_C_DELETE_SUCCESS,
	WRITING_C_DELETE_FAIL,
	WRITING_C_CURRENT_REQUEST,
	WRITING_C_CURRENT_SUCCESS,
	WRITING_C_CURRENT_FAIL,
	WRITING_PARTICIPATION_REGISTER_REQUEST,
	WRITING_PARTICIPATION_REGISTER_SUCCESS,
	WRITING_PARTICIPATION_REGISTER_FAIL,
} from '../constants/writingContestConstants'

export const registerWritingContest = (writingContest) => async (dispatch, getState) => {
	try {
		dispatch({ type: WRITING_C_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/writingcontests', writingContest, config)
		dispatch({ type: WRITING_C_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: WRITING_C_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getWritingContests =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: WRITING_C_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(
					`/api/writingcontests?count=${count}&start=${start}&keyword=${keyword}`,
					config,
				)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/writingcontests?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: WRITING_C_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: WRITING_C_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getCurrentWritingContest = () => async (dispatch) => {
	try {
		dispatch({ type: WRITING_C_CURRENT_REQUEST })

		const { data } = await axios.get('/api/writingcontests/current')

		dispatch({ type: WRITING_C_CURRENT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: WRITING_C_CURRENT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerWritingCParticipation = (writingCParticipation) => async (dispatch, getState) => {
	try {
		dispatch({ type: WRITING_PARTICIPATION_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/writingcontests/participation', writingCParticipation, config)
		dispatch({ type: WRITING_PARTICIPATION_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: WRITING_PARTICIPATION_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getWritingContestById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: WRITING_C_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/writingcontests/${id}`, config)
		dispatch({ type: WRITING_C_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: WRITING_C_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateWritingContest = (writingContest) => async (dispatch, getState) => {
	try {
		dispatch({ type: WRITING_C_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/writingcontests/${writingContest._id}`, writingContest, config)
		dispatch({ type: WRITING_C_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: WRITING_C_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteWritingC = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: WRITING_C_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/writingcontests/${id}`, config)
		dispatch({ type: WRITING_C_DELETE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: WRITING_C_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

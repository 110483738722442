const headerLinksStyle = {
    cardHeaderRoot: {
        padding: 10,
        '& .MuiCardHeader-avatar': {
            marginRight: 10,
        },
        '& .MuiCardHeader-content': {
            overflow: 'hidden',
        },
        '& .MuiCardHeader-action': {
            margin: 0,
        },
    },
    img: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    },
    navLink: {
        color: '#757575 !important',
        borderRadius: '33px',
        lineHeight: '20px',
        textDecoration: 'none',
        margin: 0,
        display: 'inline-flex',
        '& svg': {
            width: 22,
            height: 22,
        },
        '&:hover,&:focus': {
            color: 'inherit',
            background: 'rgba(200, 200, 200, 0.2)',
        },
    },
    imageDropdownButton: {
        padding: '0px',
        borderRadius: '50%',
        backgroundColor: '#f3f3f3',
    },
}

export default headerLinksStyle

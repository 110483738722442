import { useState } from 'react'
import axios from 'axios'
import { CardHeader, Grid, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Card from '../../../../components/Card/Card'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import CustomModal from '../../../../components/Modal/CustomModal'
import Button from '../../../../components/Buttons/Button'
import styles from '../styles/teamCardProjectsStyles'

const useStyles = makeStyles(styles)

const TeamCardProjects = ({ panel }) => {
  const classes = useStyles()

  const [winner, setWinner] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [openResources, setOpenResources] = useState(false)
  const [imageFilter, setImageFilter] = useState('')

  const toggleIsOpen = (e) => {
    if (e.target.src) setImageFilter(e.target.src)
    setIsOpen(!isOpen)
  }

  return (
    <div className={classes.root}>
      {panel.projects.length > 1 && <h4>{panel.panelName}</h4>}
      {panel && panel.projects.length ? (
        panel.projects.map((project, index) => (
          <Card key={index}>
            <CardHeader
              action={
                <div className={classes.panelRoot}>
                  <CustomDropdown
                    left
                    caret={false}
                    buttonText={
                      <div className={classes.btnwrapper}>
                        <MoreVert />
                      </div>
                    }
                    buttonProps={{
                      className: classes.navLink + ' ' + classes.imageDropdownButton,
                      color: 'transparent',
                    }}
                    dropdownList={[
                      <div onClick={() => [setOpenResources(true), setWinner(project)]}> View Resources </div>,
                    ]}
                  />
                </div>
              }
              titleTypographyProps={{ variant: 'h6' }}
              subheaderTypographyProps={{ variant: 'caption' }}
              title={`Final Project - ${project.group.name}`}
            />
          </Card>
        ))
      ) : (
        <h4> No final projects found for this team</h4>
      )}
      <CustomModal title='View Resources Final Project' open={openResources} setOpen={setOpenResources} actions={false}>
        <Grid container spacing={3}>
          {winner && winner?.video?.url && winner.video.url.length > 5 && (
            <Grid item xs={12} className={classes.sectionResources}>
              <h2>View Video</h2>
              <video controls>
                <source src={`${axios.defaults.baseURL}/${winner.video?.url}`} type='video/mp4' />
              </video>
            </Grid>
          )}
          <Grid item className={classes.sectionResources}>
            {winner && winner.images.length > 0 && (
              <>
                <h2 className={classes.titleImages}>View Images</h2>
                <Grid container>
                  {winner.images.map((image) => (
                    <Grid key={image._id} item xs={12} md={6}>
                      <div onClick={toggleIsOpen} className={classes.sectionImages}>
                        <img src={`${axios.defaults.baseURL}/${image.url}`} alt={image.url} />
                        {isOpen && imageFilter ? (
                          <div className={classes.lightBooxRoot} onClick={toggleIsOpen}>
                            <img className={classes.imageFilter} src={imageFilter} alt={imageFilter} />
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
          {winner && winner?.file?.url && winner.file.url.length > 5 && (
            <Grid item xs={12} className={classes.sectionResources}>
              <h2>View File</h2>
              <Button block color='primary' onClick={() => window.open(`${axios.defaults.baseURL}/${winner.file.url}`)}>
                View Resource
              </Button>
            </Grid>
          )}
        </Grid>
      </CustomModal>
    </div>
  )
}

export default TeamCardProjects

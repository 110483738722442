import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Grid,
	Link,
	FormControl,
	FormControlLabel,
	Checkbox,
	Select,
	InputLabel,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	makeStyles,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import months from '../../../../assets/data/months'
import CustomModal from '../../../../components/Modal/CustomModal'
import GroupInfoFormModal from '../components/GroupInfoFormModal'
import { getCountriesList } from '../../../../redux/actions/countryActions'
import { CENTER_REGISTER_RESET } from '../../../../redux/constants/centerConstants'
import { registerCenter } from '../../../../redux/actions/centerActions'
import { getPricePerCountryId } from '../../../../redux/actions/pricePerCountryActions'
import { useTranslation } from 'react-i18next'
import styles from '../styles/centerInfoFormStyles'
import { groupCategories } from '../../../../redux/constants/groupConstants'

const useStyles = makeStyles(styles)

const CenterInfoForm = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { t } = useTranslation('global')

	const [name, setName] = useState('')
	const [schoolYearStart, setSchoolYearStart] = useState('')
	const [schoolYearFinish, setSchoolYearFinish] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [principalName, setPrincipalName] = useState('')
	const [principalEmail, setPrincipalEmail] = useState('')
	const [principalPhone, setPrincipalPhone] = useState('')
	const [studentsQty, setStudentsQty] = useState('')
	const [countryId, setCountryId] = useState('')
	const [countryName, setCountryName] = useState('')
	const [countryFlag, setCountryFlag] = useState('')
	const [groupsInfo, setGroupInfo] = useState([])
	const [openAddGroupModal, setOpenAddGroupModal] = useState(false)
	const [openReviewInfoModal, setOpenReviewInfoModal] = useState(false)
	const [openSubmitModal, setOpenSubmitModal] = useState(false)
	const [countryPrice, setCountryPrice] = useState(0)
	const [totalDonation, setTotalDonation] = useState(0)

	const { loadingCenterRegister, errorCenterRegister, successCenterRegister } = useSelector(
		(state) => state.centerRegister,
	)
	const { loadingCountryList, errorCountryList, countries } = useSelector((state) => state.countryList)
	const { successPricePerCountryDetails, pricePerCountry } = useSelector((state) => state.pricePerCountryDetails)

	useEffect(() => {
		if (successCenterRegister) {
			setName('')
			setSchoolYearStart('')
			setSchoolYearFinish('')
			setEmail('')
			setPhone('')
			setAddress('')
			setCity('')
			setPostalCode('')
			setPrincipalName('')
			setPrincipalEmail('')
			setPrincipalPhone('')
			setStudentsQty('')
			setCountryId('')
			setCountryName('')
			setCountryFlag('')
			setGroupInfo([])
			setCountryPrice(0)
			setTotalDonation(0)
			dispatch({ type: CENTER_REGISTER_RESET })
		} else if (countries?.length < 1) {
			dispatch(getCountriesList())
		}
	}, [
		dispatch,
		successCenterRegister,
		countries,
		name,
		email,
		phone,
		address,
		countryName,
		principalEmail,
		totalDonation,
	])
	useEffect(() => {
		if (successPricePerCountryDetails) {
			if (pricePerCountry) {
				setCountryPrice(pricePerCountry?.price)
			} else {
				// For missing pricesPerCountry temp
				setCountryPrice(3)
			}
		}
	}, [successPricePerCountryDetails, pricePerCountry])

	const reviewInfoHandler = (e) => {
		e.preventDefault()
		let totalStudents = 0
		groupsInfo.forEach((group) => {
			totalStudents = totalStudents + parseInt(group.noOfStudents)
		})
		let totalDonationSuggested = totalStudents * countryPrice
		setTotalDonation(totalDonationSuggested)
		setOpenReviewInfoModal(true)
	}

	const submitFormHandler = () => {
		setOpenReviewInfoModal(false)
		setOpenSubmitModal(true)
	}
	const submitCenterHandler = () => {
		dispatch(
			registerCenter({
				name,
				schoolYear: {
					start: schoolYearStart,
					finish: schoolYearFinish,
				},
				email,
				phone,
				address: address + ', ' + city + ', ' + postalCode,
				schoolPrincipal: {
					name: principalName,
					email: principalEmail,
					phone: principalPhone,
				},
				license: {
					studentsQty,
				},
				country: {
					_id: countryId,
					name: countryName,
					flag: countryFlag,
				},
				isInternal: false,
				groupsInfo,
				totalDonation,
			}),
		)
		setOpenSubmitModal(false)
	}
	const removeGroupHandler = (index) => {
		setGroupInfo(groupsInfo.filter((group) => groupsInfo.indexOf(group) !== index))
	}
	const countryHandler = (id) => {
		setCountryId(id)
		const thisCountry = countries.find((country) => country._id === id)
		setCountryName(thisCountry.name)
		setCountryFlag(thisCountry.flag)
		dispatch(getPricePerCountryId(thisCountry._id))
	}

	const handleToggle = (e) => {}
	return (
		<div className={classes.descRoot}>
			<h1>{t('page.t_center_register_title')}</h1>
			<p>{t('page.t_center_register_p')}</p>
			<p>{t('page.t_center_register_p2')}</p>
			<form onSubmit={reviewInfoHandler}>
				<Grid container>
					<Grid item xs={12}>
						<Grid container spacing={1} className={classes.formSections}>
							<Grid item xs={12}>
								<h2>{t('layout.t_center-info')}</h2>
							</Grid>
							<Grid item xs={12}>
								<CustomInput
									labelText={t('layout.t_center-name')}
									id='centername'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: name,
										placeholder: 'Enter center name',
										onChange: (e) => setName(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl className={classes.formControl}>
									<InputLabel id='country' className={classes.selectLabel}>
										{t('layout.t_school-year-start')}
									</InputLabel>
									<Select
										labelId='country'
										id='centercountry'
										value={schoolYearStart}
										onChange={(e) => setSchoolYearStart(e.target.value)}
										inputProps={{ required: true }}
										className={classes.selectInput}
									>
										<MenuItem value='' disabled>
											{t('layout.t_select-month')}
										</MenuItem>
										{months.map((month) => (
											<MenuItem key={month._id} value={month.name}>
												{t(month.i18nText)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl className={classes.formControl}>
									<InputLabel id='country' className={classes.selectLabel}>
										{t('layout.t_school-year-finish')}
									</InputLabel>
									<Select
										labelId='country'
										id='centercountry'
										value={schoolYearFinish}
										onChange={(e) => setSchoolYearFinish(e.target.value)}
										inputProps={{ required: true }}
										className={classes.selectInput}
									>
										<MenuItem value='' disabled>
											{t('layout.t_select-month')}
										</MenuItem>
										{months.map((month) => (
											<MenuItem key={month._id} value={month.name}>
												{t(month.i18nText)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<CustomInput
									labelText={t('layout.t_total-students')}
									id='students-qty'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'number',
										value: studentsQty,
										placeholder: 'License students qty',
										onChange: (e) => setStudentsQty(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<CustomInput
									labelText={t('layout.t_email')}
									id='centeremail'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'email',
										value: email,
										placeholder: 'Enter a valid email',
										onChange: (e) => setEmail(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<CustomInput
									labelText={t('layout.t_phone')}
									id='centerphone'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: phone,
										placeholder: 'E.g. +00 000 000000',
										onChange: (e) => setPhone(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<CustomInput
									labelText={t('layout.t_address')}
									id='centeraddress'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: address,
										placeholder: 'Enter center full address',
										onChange: (e) => setAddress(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<CustomInput
									labelText={t('layout.t_city')}
									id='city'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: city,
										placeholder: 'Enter City',
										onChange: (e) => setCity(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<CustomInput
									labelText={t('layout.t_postal-code')}
									id='postal-code'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: postalCode,
										placeholder: 'Enter Postal Code',
										onChange: (e) => setPostalCode(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								{loadingCountryList ? (
									<Loader />
								) : errorCountryList ? (
									<Message variant='danger'>{errorCountryList}</Message>
								) : (
									<FormControl className={classes.formControl}>
										<InputLabel id='country' className={classes.selectLabel}>
											{t('layout.t_country')}
										</InputLabel>
										<Select
											labelId='country'
											id='centercountry'
											value={countryId}
											onChange={(e) => countryHandler(e.target.value)}
											inputProps={{ required: true }}
											className={classes.selectInput}
										>
											<MenuItem value='' disabled>
												Select a Country
											</MenuItem>
											{!loadingCountryList &&
												countries.map((country) => (
													<MenuItem key={country._id} value={country._id}>
														{country.name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								)}
							</Grid>
						</Grid>
						<Grid container spacing={1} className={classes.formSections}>
							<Grid item xs={12}>
								<h2>{t('layout.t_school-principal-info')}</h2>
							</Grid>
							<Grid item xs={12} md={4}>
								<CustomInput
									labelText={t('layout.t_name')}
									id='principal-name'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: principalName,
										placeholder: 'Complete principal name',
										onChange: (e) => setPrincipalName(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<CustomInput
									labelText={t('layout.t_email')}
									id='principal-email'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'email',
										value: principalEmail,
										placeholder: 'Enter valid Email',
										onChange: (e) => setPrincipalEmail(e.target.value),
										required: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<CustomInput
									labelText={t('layout.t_phone')}
									id='principal-phone'
									formControlProps={{
										fullWidth: true,
										className: classes.inputItem,
									}}
									inputProps={{
										type: 'text',
										value: principalPhone,
										placeholder: 'E.g. +34 000 000000',
										onChange: (e) => setPrincipalPhone(e.target.value),
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={6} sm={4} className={classes.btnContainer}>
								<Button
									type='button'
									className={classes.btnMarginTop}
									color='warning'
									block
									onClick={() => setOpenAddGroupModal(true)}
								>
									+ {t('layout.buttons.t_add-group')}
								</Button>
							</Grid>
							<Grid item xs={12} className={classes.groupSection}>
								{groupsInfo.length > 0 ? (
									<TableContainer component={Paper}>
										<Table size='small' aria-label='a dense table'>
											<TableHead className={classes.tableHead}>
												<TableRow>
													<TableCell>{t('layout.t_grade-name')}</TableCell>
													<TableCell>{t('layout.t_no-students')}</TableCell>
													<TableCell>{t('layout.t_ages-range')}</TableCell>
													<TableCell>{t('layout.t_teacher-name')}</TableCell>
													<TableCell>{t('layout.t_teacher-email')}</TableCell>
													<TableCell>{t('layout.t_group_category')}</TableCell>
													<TableCell>{t('layout.t_teacher-phone')}</TableCell>
													<TableCell></TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{groupsInfo.map((group, index) => (
													<TableRow key={index}>
														<TableCell component='th' scope='row'>
															{group.groupName}
														</TableCell>
														<TableCell>{group.noOfStudents}</TableCell>
														<TableCell>{group.agesRange}</TableCell>
														<TableCell>{group.teacherName}</TableCell>
														<TableCell>{group.teacherEmail}</TableCell>
														<TableCell>{groupCategories.find((d) => d.value === group?.category)?.label}</TableCell>
														<TableCell>{group.teacherPhone}</TableCell>
														<TableCell>
															<IconButton aria-label='delete' onClick={() => removeGroupHandler(index)}>
																<DeleteIcon fontSize='small' />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								) : (
									<h2>{t('layout.t_add-one-group')}</h2>
								)}
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											tabIndex={-1}
											onClick={() => handleToggle(21)}
											checkedIcon={<Check className={classes.checkedIcon} />}
											icon={<Check className={classes.uncheckedIcon} />}
											classes={{
												checked: classes.checked,
												root: classes.checkRoot,
											}}
											inputProps={{
												required: true,
											}}
										/>
									}
									classes={{ label: classes.label, root: classes.labelRoot }}
								/>
								<span>
									<Button link className={classes.btnLink}>
										<Link href={'/terms-conditions'} target='_blank' rel='noopener noreferrer' underline='none'>
											<span>{t('layout.t_accept-terms')}</span>
										</Link>
									</Button>
								</span>
							</Grid>
							{errorCenterRegister && (
								<Grid item xs={12}>
									<Message message={<span>{errorCenterRegister}</span>} color='info' />
								</Grid>
							)}
							<Grid item xs={12}>
								<Button type='submit' color='primary' block disabled={groupsInfo.length < 1}>
									{loadingCenterRegister ? <Loader size={'sm'} /> : t('layout.buttons.t_submit')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
			{openAddGroupModal && (
				<CustomModal
					title={t('layout.t_group-info')}
					open={openAddGroupModal}
					setOpen={setOpenAddGroupModal}
					actions={false}
				>
					<GroupInfoFormModal setOpenModal={setOpenAddGroupModal} setGroupInfo={setGroupInfo} groupsInfo={groupsInfo} />
				</CustomModal>
			)}
			<CustomModal
				title={t('layout.t_review-info')}
				open={openReviewInfoModal}
				setOpen={setOpenReviewInfoModal}
				acceptText={t('layout.buttons.t_ok')}
				acceptHandler={submitFormHandler}
			>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={2} className={classes.centerInfoDiv}>
							<Grid item xs={12}>
								<h5>{t('layout.t_center-info')}</h5>
							</Grid>
							<Grid item xs={12}>
								<p>
									{t('layout.t_name')}: <strong>{name}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_school-year-start')}: <strong>{schoolYearStart}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_school-year-finish')}: <strong>{schoolYearFinish}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_total-students')}: <strong>{studentsQty}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_email')}: <strong>{email}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_phone')}: <strong>{phone}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_address')}: <strong>{address}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_city')}: <strong>{city}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_postal-code')}: <strong>{postalCode}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_country')}: <strong>{countryName}</strong>
								</p>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} className={classes.centerInfoDiv}>
							<Grid item xs={12}>
								<h5>{t('layout.t_school-principal-info')}</h5>
							</Grid>
							<Grid item xs={12}>
								<p>
									{t('layout.t_name')}: <strong>{principalName}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_email')}: <strong>{principalEmail}</strong>
								</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<p>
									{t('layout.t_phone')}: <strong>{principalPhone}</strong>
								</p>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TableContainer component={Paper}>
							<Table size='small' aria-label='a dense table'>
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell>{t('layout.t_grade-name')}</TableCell>
										<TableCell>{t('layout.t_no-students')}</TableCell>
										<TableCell>{t('layout.t_ages-range')}</TableCell>
										<TableCell>{t('layout.t_teacher-name')}</TableCell>
										<TableCell>{t('layout.t_teacher-email')}</TableCell>
										<TableCell>{t('layout.t_group_category')}</TableCell>
										<TableCell>{t('layout.t_teacher-phone')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{groupsInfo.map((group, index) => (
										<TableRow key={index}>
											<TableCell component='th' scope='row'>
												{group.groupName}
											</TableCell>
											<TableCell>{group.noOfStudents}</TableCell>
											<TableCell>{group.agesRange}</TableCell>
											<TableCell>{group.teacherName}</TableCell>
											<TableCell>{group.teacherEmail}</TableCell>
											<TableCell>{groupCategories.find((d) => d.value === group.category).label}</TableCell>
											<TableCell>{group.teacherPhone}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12} sm={6}>
						<p>
							{t('layout.t_donation-suggested')} {totalDonation}€
						</p>
					</Grid>
					<Grid item xs={12} className={classes.alertText}>
						<h5>
							<i>{t('layout.t_check-info-text-1')}</i>
						</h5>
					</Grid>
				</Grid>
			</CustomModal>
			<CustomModal title='Suggested Donation' open={openSubmitModal} setOpen={setOpenSubmitModal} actions={false}>
				<Grid container>
					<Grid item xs={12}>
						<p>{t('layout.t_donation-text-one')}</p>
					</Grid>
					<Grid item xs={12}>
						<p>{t('layout.t_donation-text-two')}</p>
					</Grid>
					<Grid item xs={12}>
						<h3>
							{t('layout.t_donation-suggested')} {totalDonation}€
						</h3>
					</Grid>
				</Grid>
				<Grid container spacing={2} className={classes.donationDiv}>
					<Grid item xs={12}>
						<p
							dangerouslySetInnerHTML={{
								__html: t('layout.t_donation-text-4'),
							}}
						/>
						<h4>Bank branch: CREDIT AGRICOLE de CHAMPAGNE BOURGOGNE</h4>
						<h4>Holder: O.I.E.C. PLANET FRATERNITY</h4>
						<h4>Address: CHEZ SEGEC 277 RUE SAINT JACQUES 75005 PARIS</h4>
						<h4>Postal code: C AFF IMMOBILIER INSTITUTIO.21Bank code: 11006</h4>
						<h4>Counter Code: 21052</h4>
						<h4>Account number: 24582508280</h4>
						<h4>RIB key: 01</h4>
						<h4>IBAN (International Bank Account Number): FR76 1100 6210 5224 5825 0828 001</h4>
						<h4>BIC code (Bank Identification Code) - SWIFT code : AGRIFRPP810</h4>
					</Grid>
				</Grid>
				<Grid container spacing={2} className={classes.donationDiv}>
					<Grid item xs={12}>
						<p>
							<strong>{t('layout.t_donation-text-5')}</strong>
						</p>
						<h4>planetfraternity@oiecinternational.com</h4>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Button color='warning' onClick={submitCenterHandler} block>
							{t('layout.buttons.t_confirm-subs')}
						</Button>
					</Grid>
				</Grid>
			</CustomModal>
		</div>
	)
}

export default CenterInfoForm

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import global_es from './es/global.json'
import global_en from './en/global.json'
import global_fr from './fr/global.json'
import global_it from './it/global.json'

i18n.use(initReactI18next).init({
    lng: localStorage.getItem('lang'),
    fallbackLng: 'en',
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
        fr: {
            global: global_fr,
        },
        it: {
            global: global_it,
        },
    },
})

import { Route } from 'react-router-dom'

const AppRoute = ({ component: Components, layout: Layout, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Components {...props} />
                </Layout>
            )}
        />
    )
}

export default AppRoute

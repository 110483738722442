import { Grid } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'

const ViewTeacherModal = ({ teacherInfo, closeViewTeacherHandler }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <p>Username · {teacherInfo.username}</p>
        <p>Email · {teacherInfo.email}</p>
        <p>Phone · {teacherInfo.phone ? teacherInfo.phone : 'Not registered'}</p>
      </Grid>
      <Grid item xs={12}>
        <Button color='primary' block onClick={closeViewTeacherHandler}>
          OK
        </Button>
      </Grid>
    </Grid>
  )
}

export default ViewTeacherModal

import CalendarScreen from '../screens/control-panel/CalendarScreen/CalendarScreen'
import CenterListScreen from '../screens/control-panel/CenterListScreen/CenterListScreen'
import CenterManageScreen from '../screens/control-panel/CenterManageScreen/CenterManageScreen'
import ClassroomScreen from '../screens/control-panel/ClassroomScreen/ClassroomScreen'
import MergeCentersScreen from '../screens/control-panel/MergeCentersScreen/MergeCentersScreen'
import PendingSubsListScreen from '../screens/control-panel/PendingSubsListScreen/PendingSubsListScreen'
import PricePerCountryScreen from '../screens/control-panel/PricePerCountryScreen/PricePerCountryScreen'
import StudentAllScreen from '../screens/control-panel/StudentAllScreen/StudentAllScreen'
import TeacherListScreen from '../screens/control-panel/TeacherListScreen/TeacherListScreen'
import TeacherManageScreen from '../screens/control-panel/TeacherManageScreen/TeacherManageScreen'
import TestimonialsListScreen from '../screens/control-panel/TestimonialsListScreen/TestimonialsListScreen'
import TeamsByAgesScreen from '../screens/control-panel/TeamsByAgesScreen/TeamsByAgesScreen'
import TeamsManageScreen from '../screens/control-panel/TeamsManageScreen/TeamsManageScreen'
import TeamsScreen from '../screens/control-panel/TeamsScreen/TeamsScreen'
import UserListScreen from '../screens/control-panel/UserListScreen/UserListScreen'
import WritingContestScreen from '../screens/control-panel/WritingContestScreen/WritingContestScreen'
import WritingWinnersScreen from '../screens/control-panel/WritingWinnersScreen/WritingWinnersScreen'
import TopicListScreen from '../screens/control-panel/TopicListScreen/TopicListScreen'
import SurveyListScreen from '../screens/control-panel/SurveyListScreen/SurveyListScreen'
import MiniBlogListScreen from '../screens/control-panel/MiniBlogListScreen/MiniBlogListScreen'
import GallerySolidarityScreen from '../screens/control-panel/GallerySolidarityScreen/GallerySolidarityScreen'
import StudentsCountryPostsScreen from '../screens/control-panel/StudentsCountryPostsScreen/StudentsCountryPostsScreen'
import ShareTalentsScreen from '../screens/control-panel/ShareTalentsScreen/ShareTalentsScreen'
import LittleMergeCentersScreen from '../screens/control-panel/LittleMergeCentersScreen/LittleMergeCentersScreen'

const routesControlPanel = [
	{
		name: 'Pending Subscriptions',
		path: '/centers-pending-subscriptions',
		exact: true,
		comp: PendingSubsListScreen,
	},
	{
		name: 'Centers List',
		path: '/centers',
		exact: true,
		comp: CenterListScreen,
	},
	{
		name: 'Center List',
		path: '/centers/:keyword',
		exact: true,
		comp: CenterListScreen,
	},
	{
		name: 'Center Manage',
		path: '/centers/manage/:id',
		exact: true,
		comp: CenterManageScreen,
	},
	{
		name: 'Merge Centers',
		path: '/merge-centers',
		exact: true,
		comp: MergeCentersScreen,
	},
	{
		name: 'Merge Little PlanetFraternity',
		path: '/merge-little-pf',
		exact: true,
		comp: LittleMergeCentersScreen,
	},
	{
		name: 'Students',
		path: '/students',
		exact: true,
		comp: StudentAllScreen,
	},
	{
		name: 'Student List',
		path: '/students/:keyword',
		exact: true,
		comp: StudentAllScreen,
	},
	{
		name: 'Teachers',
		path: '/teachers',
		exact: true,
		comp: TeacherListScreen,
	},
	{
		name: 'Teachers List',
		path: '/teachers/:keyword',
		exact: true,
		comp: TeacherListScreen,
	},
	{
		name: 'Teacher Manage',
		path: '/teachers/manage/:id',
		exact: true,
		comp: TeacherManageScreen,
	},
	{
		name: 'Unpartnered Centers',
		path: '/unpartnered-centers',
		exact: true,
		comp: TeamsByAgesScreen,
	},
	{
		name: 'Teams',
		path: '/teams',
		exact: true,
		comp: TeamsScreen,
	},
	{
		name: 'Testimonials',
		path: '/testimonials',
		exact: true,
		comp: TestimonialsListScreen,
	},
	{
		name: 'Miniblog',
		path: '/miniblog',
		exact: true,
		comp: MiniBlogListScreen,
	},
	{
		name: 'Solidarity Gallery',
		path: '/solidarity-gallery',
		exact: true,
		comp: GallerySolidarityScreen,
	},
	{
		name: 'Teams List',
		path: '/teams/:keyword',
		exact: true,
		comp: TeamsScreen,
	},
	{
		name: 'Teams Manage',
		path: '/teams/manage/:id',
		exact: true,
		comp: TeamsManageScreen,
	},
	{
		name: 'Topics Manage',
		path: '/topics',
		exact: true,
		comp: TopicListScreen,
	},
	{
		name: 'Users',
		path: '/users',
		exact: true,
		comp: UserListScreen,
	},
	{
		name: 'Surveys',
		path: '/surveys',
		exact: true,
		comp: SurveyListScreen,
	},
	{
		name: 'Users List',
		path: '/users/:keyword',
		exact: true,
		comp: UserListScreen,
	},
	{
		name: 'Writing Contest',
		path: '/writing-contest',
		exact: true,
		comp: WritingContestScreen,
	},
	{
		name: 'Writing Winners',
		path: '/writing-winners',
		exact: true,
		comp: WritingWinnersScreen,
	},
	{
		name: 'Price Per Country',
		path: '/price-per-country',
		exact: true,
		comp: PricePerCountryScreen,
	},
	{
		name: 'Calendar',
		path: '/calendar',
		exact: true,
		comp: CalendarScreen,
	},
	{
		name: 'Classroom',
		path: '/classroom/:id',
		exact: true,
		comp: ClassroomScreen,
	},
	{
		name: 'Students Country Posts',
		path: '/students-country-posts',
		exact: true,
		comp: StudentsCountryPostsScreen,
	},
	{
		name: 'Let\'s Share Talents Posts',
		path: '/lets-share-talents',
		exact: true,
		comp: ShareTalentsScreen,
	},
]

export default routesControlPanel

import * as types from '../constants/countryPostConstants'

export const countryPostListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_LIST_REQUEST:
			return { loadingCountryPostList: true }
		case types.COUNTRY_POST_LIST_SUCCESS:
			return { loadingCountryPostList: false, countryPosts: action.payload }
		case types.COUNTRY_POST_LIST_FAIL:
			return { loadingCountryPostList: false, errorCountryPosts: action.payload }
		case types.COUNTRY_POST_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const countryPostActiveListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_ACTIVE_LIST_REQUEST:
			return { ...state, loadingCountryPostActiveList: true }
		case types.COUNTRY_POST_ACTIVE_LIST_SUCCESS:
			return {
				loadingCountryPostActiveList: false,
				thatsIt: action.payload.thatsIt,
				countryPosts: action.payload.data,
			}
		case types.COUNTRY_POST_ACTIVE_LIST_FAIL:
			return { loadingCountryPostActiveList: false, errorCountryPosts: action.payload }
		case types.COUNTRY_POST_ACTIVE_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const countryPostInactiveListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_INACTIVE_LIST_REQUEST:
			return { loadingCountryPostInactiveList: true }
		case types.COUNTRY_POST_INACTIVE_LIST_SUCCESS:
			return { loadingCountryPostInactiveList: false, countryPosts: action.payload }
		case types.COUNTRY_POST_INACTIVE_LIST_FAIL:
			return { loadingCountryPostInactiveList: false, errorCountryPosts: action.payload }
		case types.COUNTRY_POST_INACTIVE_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const countryPostDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_DELETE_REQUEST:
			return { loadingCountryPostDelete: true }
		case types.COUNTRY_POST_DELETE_SUCCESS:
			return { loadingCountryPostDelete: false, success: action.payload }
		case types.COUNTRY_POST_DELETE_FAIL:
			return { loadingCountryPostDelete: false, errorDeleteCountryPosts: action.payload }
		case types.COUNTRY_POST_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const countryPostCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_CREATE_REQUEST:
			return { loadingCountryPostCreate: true }
		case types.COUNTRY_POST_CREATE_SUCCESS:
			return { loadingCountryPostCreate: false, success: action.payload }
		case types.COUNTRY_POST_CREATE_FAIL:
			return { loadingCountryPostCreate: false, errorCreateCountryPosts: action.payload }
		case types.COUNTRY_POST_CREATE_RESET:
			return {}
		default:
			return state
	}
}

export const countryPostUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_UPDATE_REQUEST:
			return { loadingCountryPostUpdate: true }
		case types.COUNTRY_POST_UPDATE_SUCCESS:
			return { loadingCountryPostUpdate: false, success: action.payload }
		case types.COUNTRY_POST_UPDATE_FAIL:
			return { loadingCountryPostUpdate: false, errorUpdateCountryPosts: action.payload }
		case types.COUNTRY_POST_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const countryPostVerifyPostedReducer = (state = { posted: true }, action) => {
	switch (action.type) {
		case types.COUNTRY_POST_VERIFY_POSTED_REQUEST:
			return { loadingCountryPostVerifyPosted: true }
		case types.COUNTRY_POST_VERIFY_POSTED_SUCCESS:
			return { loadingCountryPostVerifyPosted: false, posted: action.payload }
		case types.COUNTRY_POST_VERIFY_POSTED_FAIL:
			return { loadingCountryPostVerifyPosted: false, errorVerifyCountryPosts: action.payload }
		case types.COUNTRY_POST_VERIFY_POSTED_RESET:
			return { posted: true }
		default:
			return state
	}
}

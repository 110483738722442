const styles = {
  root: {
    marginTop: 15,
    marginBottom: 15,
  },
  switchItem: {
    textAlign: 'left',
  },
  formControl: {
    marginTop: 0,
    paddingTop: '10px',
    width: '100%',
    textAlign: 'left',
    '& label': {
      display: 'contents',
    },
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  closeIcon: {
    backgroundColor: '#f9f0f0',
    height: '40px',
    width: '40px',
  },
  rootQuestion: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionSelects: {
    textAlign: 'start',
    paddingLeft: '20px',
  },
  mainSection: {
    border: '2px solid #e5e5e5',
    padding: '20px',
    marginBottom: '10px',
    borderRadius: '16px',
  },
}
export default styles

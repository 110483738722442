export const TESTIMONIAL_REGISTER_REQUEST = 'TESTIMONIAL_REGISTER_REQUEST'
export const TESTIMONIAL_REGISTER_SUCCESS = 'TESTIMONIAL_REGISTER_SUCCESS'
export const TESTIMONIAL_REGISTER_FAIL = 'TESTIMONIAL_REGISTER_FAIL'
export const TESTIMONIAL_REGISTER_RESET = 'TESTIMONIAL_REGISTER_RESET'

export const TESTIMONIAL_LIST_REQUEST = 'TESTIMONIAL_LIST_REQUEST'
export const TESTIMONIAL_LIST_SUCCESS = 'TESTIMONIAL_LIST_SUCCESS'
export const TESTIMONIAL_LIST_FAIL = 'TESTIMONIAL_LIST_FAIL'
export const TESTIMONIAL_LIST_RESET = 'TESTIMONIAL_LIST_RESET'

export const TESTIMONIAL_DETAILS_REQUEST = 'TESTIMONIAL_DETAILS_REQUEST'
export const TESTIMONIAL_DETAILS_SUCCESS = 'TESTIMONIAL_DETAILS_SUCCESS'
export const TESTIMONIAL_DETAILS_FAIL = 'TESTIMONIAL_DETAILS_FAIL'
export const TESTIMONIAL_DETAILS_RESET = 'TESTIMONIAL_DETAILS_RESET'

export const ACTIVE_TESTIMONIAL_REQUEST = 'ACTIVE_TESTIMONIAL_REQUEST'
export const ACTIVE_TESTIMONIAL_SUCCESS = 'ACTIVE_TESTIMONIAL_SUCCESS'
export const ACTIVE_TESTIMONIAL_FAIL = 'ACTIVE_TESTIMONIAL_FAIL'
export const ACTIVE_TESTIMONIAL_RESET = 'ACTIVE_TESTIMONIAL_RESET'

export const TESTIMONIAL_BY_TEACHER_REQUEST = 'TESTIMONIAL_BY_TEACHER_REQUEST'
export const TESTIMONIAL_BY_TEACHER_SUCCESS = 'TESTIMONIAL_BY_TEACHER_SUCCESS'
export const TESTIMONIAL_BY_TEACHER_FAIL = 'TESTIMONIAL_BY_TEACHER_FAIL'
export const TESTIMONIAL_BY_TEACHER_RESET = 'TESTIMONIAL_BY_TEACHER_RESET'

export const TESTIMONIAL_UPDATE_REQUEST = 'TESTIMONIAL_UPDATE_REQUEST'
export const TESTIMONIAL_UPDATE_SUCCESS = 'TESTIMONIAL_UPDATE_SUCCESS'
export const TESTIMONIAL_UPDATE_FAIL = 'TESTIMONIAL_UPDATE_FAIL'
export const TESTIMONIAL_UPDATE_RESET = 'TESTIMONIAL_UPDATE_RESET'

export const TESTIMONIAL_DELETE_REQUEST = 'TESTIMONIAL_DELETE_REQUEST'
export const TESTIMONIAL_DELETE_SUCCESS = 'TESTIMONIAL_DELETE_SUCCESS'
export const TESTIMONIAL_DELETE_FAIL = 'TESTIMONIAL_DELETE_FAIL'
export const TESTIMONIAL_DELETE_RESET = 'TESTIMONIAL_DELETE_RESET'

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Card, CardHeader, Avatar, makeStyles, Grid } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Loader from '../../../../components/Loader/Loader'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import CustomModal from '../../../../components/Modal/CustomModal'
import { deleteStudent, getStudentsByCenter } from '../../../../redux/actions/studentActions'
import { getUserById } from '../../../../redux/actions/userActions'
import { STUDENTS_BY_CENTER_RESET, STUDENT_DELETE_RESET } from '../../../../redux/constants/studentConstants'
import styles from '../styles/studentListCardStyles'

const useStyles = makeStyles(styles)

const StudentListCard = ({ center, groups }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [userArray, setUserArray] = useState([])
	const [count] = useState(15)
	const [start, setStart] = useState(1)
	const [hasMore, setHasMore] = useState(true)
	const [initalHasMore, setInitialHasMore] = useState(true)
	const [open, setOpen] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [userDelete, setUserDelete] = useState({})

	const { thatsIt, students, errorStudentsByCenter } = useSelector((state) => state.studentsByCenter)
	const { successRegisterMultiple } = useSelector((state) => state.studentRegisterMultiple)
	const { successUserDetails, user } = useSelector((state) => state.userDetails)
	const { loadingStudentDelete, successStudentDelete } = useSelector((state) => state.studentDelete)

	useEffect(() => {
		if (successRegisterMultiple) {
			dispatch({ type: STUDENTS_BY_CENTER_RESET })

			setUserArray([])
			setStart(1)
			setInitialHasMore(true)
			setHasMore(true)
			return true
		}
		if (successStudentDelete) {
			setTimeout(() => {
				setOpenDelete(false)
				dispatch({ type: STUDENT_DELETE_RESET })
			}, 2000)

			setUserDelete({})
			setUserArray([])
			setStart(1)
			setInitialHasMore(true)
			setHasMore(true)
			return true
		}
		if (initalHasMore) {
			dispatch(getStudentsByCenter(undefined, count, 0, center._id))
			setInitialHasMore(false)
		} else if (students && students.users && students.users.length > 0) {
			setUserArray((prev) => [...prev, ...students.users])
		}
	}, [dispatch, center, students, count, initalHasMore, successRegisterMultiple, successStudentDelete])
	useEffect(() => {
		if (thatsIt || errorStudentsByCenter === 'Students not found.') {
			setHasMore(false)
		} else {
			setHasMore(true)
		}
	}, [thatsIt, errorStudentsByCenter])

	const manageStudentHandler = (id) => {
		dispatch(getUserById(id))
		setOpen(true)
	}
	const fetchData = () => {
		if (hasMore) {
			setStart(start + 1)
			dispatch(getStudentsByCenter(undefined, count, start, center._id))
		}
	}
	const deleteHandler = () => {
		const group = groups.find((group) => group.students.find((student) => student._id === userDelete._id))

		if (userDelete) {
			dispatch(deleteStudent(userDelete._id, group?._id))
		}
	}
	return (
		<div className='student-card'>
			{students?.userLength === undefined && start === 1 ? (
				<div className={classes.emptyStudentCard}>
					<h4>There are no students registered yet.</h4>
					<p>You must automate students in a group.</p>
				</div>
			) : (
				<InfiniteScroll
					dataLength={userArray.length}
					next={fetchData}
					hasMore={hasMore}
					loader={<Loader />}
					height={'60vh'}
					className={classes.infiniteScroll}
					endMessage={
						<p className={classes.contentLine}>
							<b>This is the last line.</b>
						</p>
					}
				>
					{userArray.map((user) => (
						<Card className={classes.root} key={user._id}>
							<CardHeader
								avatar={<Avatar aria-label='recipe' src={`${axios.defaults.baseURL}${user.avatar}`}></Avatar>}
								action={
									<CustomDropdown
										left
										caret={false}
										buttonText={
											<div className={classes.btnwrapper}>
												<MoreVert />
											</div>
										}
										buttonProps={{
											className: classes.navLink + ' ' + classes.imageDropdownButton,
											color: 'transparent',
										}}
										dropdownList={[
											<div onClick={() => manageStudentHandler(user._id)}>Manage</div>,
											<div onClick={() => [setOpenDelete(true), setUserDelete(user)]}>Delete Student</div>,
										]}
									/>
								}
								title={user.name}
								subheader={user.googleMail}
							/>
						</Card>
					))}
				</InfiniteScroll>
			)}
			{successUserDetails && (
				<CustomModal title='Review Info' open={open} setOpen={setOpen} actions={false}>
					<Grid container spacing={2} className={classes.studentInfoDiv}>
						<Grid item xs={12}>
							<h5>Student Info</h5>
						</Grid>
						<Grid item xs={12}>
							<p>
								Name: <strong>{user.name}</strong>
							</p>
						</Grid>
						<Grid item xs={12}>
							<p>
								Google Email : <strong>{user.googleMail}</strong>
							</p>
						</Grid>
						<Grid item xs={12}>
							<p>
								Group: <strong>{user.group.name ? user.group.name : 'Unassigned group name'}</strong>
							</p>
						</Grid>
						<Grid item xs={12}>
							<p>
								Age Range: <strong>{user.group.ageRange ? user.group.ageRange : 'Unassigned group age range'}</strong>
							</p>
						</Grid>
					</Grid>
				</CustomModal>
			)}
			<CustomModal
				title='Delete Student?'
				open={openDelete}
				setOpen={setOpenDelete}
				loadingAccept={loadingStudentDelete}
				successAccept={successStudentDelete}
				acceptText={successStudentDelete ? 'Student Deleted!' : 'Delete'}
				acceptHandler={deleteHandler}
			>
				<Grid container>
					<Grid item xs={12}>
						<p>Are you sure you want to delete this student?</p>
					</Grid>
					<Grid item xs={12}>
						This action will remove all processes performed by the student.
					</Grid>
				</Grid>
			</CustomModal>
		</div>
	)
}

export default StudentListCard

import { forwardRef } from 'react'
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../Buttons/Button'
import styles from './styles/customModalStyles'
import Loader from '../Loader/Loader'

const useStyles = makeStyles(styles)

const Transition = forwardRef((props, ref) => {
	return <Slide direction='down' ref={ref} {...props} />
})
Transition.displayName = 'Transition'

const CustomModal = ({
	title,
	setOpen,
	open,
	imageMode,
	loadingAccept,
	successAccept,
	children,
	actions,
	acceptText,
	acceptHandler,
}) => {
	const classes = useStyles()

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => setOpen(false)}
			aria-labelledby='general-modal-title'
			aria-describedby='general-modal-description'
			PaperProps={{
				className: imageMode ? classes.transparent : classes.dialogRoot,
			}}
		>
			{!imageMode && (
				<DialogTitle id='general-modal-title' disableTypography className={classes.header}>
					<h4>{title}</h4>
					<Button justIcon round color='light' onClick={() => setOpen(false)}>
						<Close />
					</Button>
				</DialogTitle>
			)}
			<DialogContent
				id='general-modal-description'
				className={imageMode ? classes.transparentContent : classes.content}
			>
				{children}
			</DialogContent>
			{actions && !imageMode && (
				<DialogActions className={classes.actions}>
					<Button color='info' disabled={loadingAccept || successAccept} onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button
						color={successAccept ? 'success' : 'primary'}
						disabled={loadingAccept || successAccept}
						onClick={acceptHandler}
					>
						{loadingAccept ? <Loader size={'sm'} /> : acceptText}
					</Button>
				</DialogActions>
			)}
		</Dialog>
	)
}
CustomModal.defaultProps = {
	actions: true,
	acceptText: 'OK',
	successAccept: false,
	loadingAccept: false,
}

export default CustomModal

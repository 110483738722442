const styles = {
    root: {
        padding: '10px',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#fff',
        zIndex: 100,
        boxShadow: '0 -10px 10px 0px rgb(0 0 0 / 23%)',
        '& h1': {
            lineHeight: 1,
            marginBottom: 10,
            fontSize: '1.2rem'
        },
        '& span': {
            fontSize: 11,
        },
    },
    cookiesActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnLink: {
        fontSize: 12,
        fontWeight: '500',
        color: '#483d3d',
        margin: '5px 0',
        padding: 0,
        textTransform: 'none',
    },
}

export default styles

import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Button from '../../../components/Buttons/Button'
import UserLogin from './components/UserLogin'
import CustomInput from '../../../components/CustomInput/CustomInput'
import Loader from '../../../components/Loader/Loader'
import { login } from '../../../redux/actions/userActions'
import { registerLog } from '../../../redux/actions/logActions'
import styles from './styles/loginScreenStyles'

const useStyles = makeStyles(styles)

const LoginScreen = ({ location, history }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { t } = useTranslation('global')

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState()

	const { userInfo, loadingUserInfo, errorUserInfo } = useSelector((state) => state.userLogin)

	const redirectCP = location.search ? location.search.split('=')[1] : '/teacher/profile'
	const redirectS = location.search ? location.search.split('=')[1] : '/student/profile'

	useEffect(() => {
		if (userInfo) {
			dispatch(
				registerLog({
					category: 'login',
					user: {
						id: userInfo._id,
						username: userInfo.username,
						role: userInfo.role,
					},
				}),
			)
			if (userInfo.role === 'student') {
				history.push(redirectS)
			} else {
				history.push(redirectCP)
			}
		}
	}, [history, userInfo, redirectCP, redirectS, dispatch])

	const handlerSubmit = useCallback(
		(e) => {
			if (process.env.REACT_APP_ENV === 'development') {
				e.preventDefault()
			}

			const credentials =
				process.env.REACT_APP_ENV === 'development'
					? {
							email,
							password,
					  }
					: null
			dispatch(login(e, credentials))
		},
		[email, password],
	)

	return (
		<>
			<Meta title={'Login - Planet Fraternity'} />
			<Grid container className={classes.root}>
				<Grid item xs={12} sm={12} md={6} className={classes.colLogo}>
					<img src='/assets/img/logo-planetfraternity500x500.png' alt='logo Planet Fraternity' loading='lazy' />
					<h1>{t('layout.t_best-way-to-learn')}</h1>
				</Grid>
				<Grid item xs={12} sm={12} md={6} className={classes.colLoginForm}>
					<div className={classes.containerUserLogin}>
						{process.env.REACT_APP_ENV !== 'development' ? (
							<UserLogin onSubmit={handlerSubmit} />
						) : (
							<form onSubmit={handlerSubmit} autoComplete='off'>
								<CustomInput
									labelText='Email'
									id='email'
									formControlProps={{
										fullWidth: true,
										className: classes.formControl,
									}}
									inputProps={{
										type: 'text',
										value: email,
										placeholder: 'Enter complete email',
										required: true,
										onChange: (e) => setEmail(e.target.value),
									}}
								/>
								<CustomInput
									labelText='Password'
									id='password'
									formControlProps={{
										fullWidth: true,
										className: classes.formControl,
									}}
									inputProps={{
										type: 'password',
										value: password,
										placeholder: 'Enter password',
										required: true,
										label: 'Password',
										onChange: (e) => setPassword(e.target.value),
									}}
								/>
								<p className={classes.errorMessage}>{errorUserInfo}</p>
								<Button color='primary' block type='submit'>
									{loadingUserInfo ? <Loader size={'sm'} /> : t('layout.t_login')}
								</Button>
							</form>
						)}
						<p className={classes.contactUs}>
							{t('layout.t_no-account')}{' '}
							<Button link className={classes.btnLink} onClick={() => history.push('/contact')}>
								<span>{t('layout.buttons.t_contact-us')}.</span>
							</Button>
						</p>
					</div>
				</Grid>
			</Grid>
		</>
	)
}

export default LoginScreen

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { AccountCircle, People } from '@material-ui/icons'
import Meta from '../../../components/Meta/Meta'
import Message from '../../../components/Message/Message'
import Loader from '../../../components/Loader/Loader'
import CustomTabs from '../../../components/CustomTabs/CustomTabs'
import TeacherInfoFrom from './components/TeacherInfoFrom'
import GroupListCard from './components/GroupListCard'
import { getTeachersById } from '../../../redux/actions/teacherActions'
import { getGroupsByCenter } from '../../../redux/actions/groupActions'
import styles from './styles/teacherManageScreenStyles'

const useStyles = makeStyles(styles)

const TeacherManageScreen = ({ match }) => {
  const teacherId = match.params.id
  const dispatch = useDispatch()
  const classes = useStyles()

  const [mainGroups, setMainGroups] = useState([])
  const [otherGroups, setOtherGroups] = useState([])
  const [loadingGroups, setLoadingGroups] = useState(false)

  const {
    loading: loadingTeacherDetails,
    error: errorTeacherDetails,
    teacher,
  } = useSelector((state) => state.teacherDetails)
  const { success: successUserUpdate } = useSelector((state) => state.userUpdate)
  const { groups } = useSelector((state) => state.groupListByCenter)
  const { successGroupTeacherDelete } = useSelector((state) => state.groupTeacherDelete)

  useEffect(() => {
    if (!successUserUpdate) {
      dispatch(getTeachersById(teacherId))
    }
  }, [dispatch, teacherId, successUserUpdate])
  useEffect(() => {
    if (teacher && teacher._id) {
      dispatch(getGroupsByCenter(teacher.center))
    }
  }, [dispatch, teacher, successGroupTeacherDelete])
  useEffect(() => {
    if (groups && groups.length > 0 && teacher && teacher._id) {
      setLoadingGroups(true)
      const groupsMain = groups.filter((group) => {
        let groupArray = group.teachers.filter((profesor) => profesor._id === teacher._id && profesor.main === true)
        if (groupArray.length > 0) {
          return true
        } else {
          return false
        }
      })
      const groupOther = groups.filter((group) => {
        let groupArray = group.teachers.filter((profesor) => profesor._id === teacher._id && profesor.main === false)
        if (groupArray.length > 0) {
          return true
        } else {
          return false
        }
      })
      setMainGroups(groupsMain)
      setOtherGroups(groupOther)
      setLoadingGroups(false)
    }
  }, [groups, teacher])

  return (
    <>
      <Meta title={teacher ? teacher.name + ' - Planet Fraternity' : 'Planet Fraternity'} />
      <Grid container spacing={8} className={classes.teacherRoot}>
        <Grid item xs={12} md={8} className='m-auto pt-5'>
          <CustomTabs
            headerColor='primary'
            className='h-100'
            tabs={[
              {
                tabName: 'Teacher Info',
                tabIcon: AccountCircle,
                tabContent: (
                  <>
                    {loadingTeacherDetails ? (
                      <Loader />
                    ) : errorTeacherDetails ? (
                      <Message>{errorTeacherDetails}</Message>
                    ) : (
                      teacher && <TeacherInfoFrom teacher={teacher} />
                    )}
                  </>
                ),
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={6} className='pt-5'>
          <CustomTabs
            headerColor='success'
            className='h-100'
            tabs={[
              {
                tabName: 'Main Groups',
                tabIcon: People,
                tabContent: (
                  <div className={classes.tabSection}>
                    {loadingGroups ? (
                      <Loader />
                    ) : teacher && mainGroups.length > 0 ? (
                      mainGroups.map((group) => <GroupListCard key={group._id} group={group} teacher={teacher} />)
                    ) : (
                      <h4>This Teacher has no main groups.</h4>
                    )}
                  </div>
                ),
              },
              {
                tabName: 'Other Groups',
                tabIcon: People,
                tabContent: (
                  <div className={classes.tabSection}>
                    {loadingGroups ? (
                      <Loader />
                    ) : teacher && otherGroups.length > 0 ? (
                      otherGroups.map((group) => <GroupListCard key={group._id} group={group} teacher={teacher} />)
                    ) : (
                      <h4>This teacher has no other groups assigned</h4>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TeacherManageScreen

import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { Provider } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import store from './redux/store'
import './index.css'
import App from './App'
import './assets/translations/i18n-config'

axios.defaults.baseURL = process.env.REACT_APP_API

ReactDOM.render(
	<Provider store={store}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<App />
		</MuiPickersUtilsProvider>
	</Provider>,
	document.getElementById('root'),
)

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Grid, makeStyles } from '@material-ui/core'
import TeamCard from './TeamCard'
import FinalProjectCard from './FinalProjectCard'
import { getTeamsFinalProjects } from '../../../../redux/actions/teamsActions'
import { getTopics } from '../../../../redux/actions/topicActions'
import styles from '../styles/teamsByTopicStyles'

const useStyles = makeStyles(styles)

const TeamsByTopic = ({ match, history }) => {
  const params = match.params.topic
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const search = useLocation().search
  const idQuery = new URLSearchParams(search).get('team')

  const [team, setTeam] = useState([])
  const [topic, setTopic] = useState({})

  const { teamFinalProjects } = useSelector((state) => state.teamFinalProjects)
  const { topics } = useSelector((state) => state.topicList)
  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    dispatch(getTopics())
  }, [dispatch])

  useEffect(() => {
    dispatch(getTeamsFinalProjects(params))
  }, [dispatch, params])

  useEffect(() => {
    if (idQuery && teamFinalProjects) {
      const team = teamFinalProjects.find((item) => item._id === idQuery)
      setTeam(team)
    } else {
      setTeam([])
    }
  }, [idQuery, teamFinalProjects])

  useEffect(() => {
    if (topics) {
      const topic = topics.find((topic) => topic._id === params)
      setTopic(topic)
    }
  }, [topics, params])

  return (
    <Grid container className={classes.root}>
      {teamFinalProjects && Object.keys(team).length > 0 ? (
        <div className={classes.finalCard}>
          <FinalProjectCard team={team} userInfo={userInfo} />
        </div>
      ) : (
        <div className={classNames(classes.rootCard, 'wow animate__animated animate__fadeInLeftBig')}>
          {teamFinalProjects && teamFinalProjects.length > 0 ? (
            <>
              <h3>{t('page.t_final-projects-text-2')}</h3>
              <TeamCard history={history} data={teamFinalProjects} />
            </>
          ) : (
            <>
              <h3>{t('page.t_final-projects-text-3')}</h3>
              <h5>{t('page.t_final-projects-text-4')}</h5>
              {topic && (
                <img className={classes.cardImage} src={`${axios.defaults.baseURL}${topic.image}`} alt={topic.name} />
              )}
            </>
          )}
        </div>
      )}
    </Grid>
  )
}

export default TeamsByTopic

const TeacherListHeader = ({ teachersLength }) => {
  return (
    <>
      <h1>Teacher List</h1>
      <p>Total teachers {teachersLength !== null && '- ' + teachersLength}</p>
    </>
  )
}

export default TeacherListHeader

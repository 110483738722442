import { container } from '../../../../assets/jss/material-ui-styles'

const styles = {
  container,
  titleBig: {
    textAlign: 'center',
    fontSize: '80px',
    fontWeight: '600',
    lineHeight: '1.1',
    display: 'inline-block',
    position: 'relative',
    color: '#f64ebd',
    textShadow: '7px 10px 15px rgb(7 44 25)',
  },
  '@media screen and (max-width: 980px)': {
    titleBig: {
      textAlign: 'center',
      display: 'block',
    },
  },
}

export default styles

import { main, mainRaised } from '../../../../assets/jss/material-ui-styles'

const styles = {
  main,
  mainRaised,
  userCardRoot: {
    margin: '0 auto',
  },
  '@media screen and (max-width: 960px)': {
    userCardRoot: {
      marginBottom: '80px',
    },
  },
}

export default styles

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Grid, Card, CardHeader, CardContent, makeStyles } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Loader from '../../Loader/Loader'
import Message from '../../Message/Message'
import Button from '../../Buttons/Button'
import CustomModal from '../../Modal/CustomModal'
import CustomDropdown from '../../CustomDropdown/CustomDropdown'
import UserListCard from '../../Users/UserListCard/UserListCard'
import { teamGroupUpdateInfo } from '../../../redux/actions/teamGroupActions'
import styles from './styles/teamGroupCardStyles'

const useStyles = makeStyles(styles)

const TeamGroupCard = ({ group, draggable, droppable }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [studentInfo, setStudentInfo] = useState('')
  const [openAssign, setOpenAssign] = useState(false)
  const [openManage, setOpenManage] = useState(false)
  const [message, setMessage] = useState('')

  const { loadingPartners, centersPartners } = useSelector((state) => state.centerPartners)
  const { successTeamGroupUpdate } = useSelector((state) => state.teamGroupUpdate)

  useEffect(() => {
    if (successTeamGroupUpdate) {
      setOpenAssign(false)
    }
  }, [successTeamGroupUpdate])

  const dragOver = (e) => {
    e.preventDefault()
  }
  const dragEnter = (e) => {
    e.preventDefault()
    if (droppable) {
      e.target.className += ' fillover'
    }
  }
  const onDragLeave = (e) => {
    e.target.classList.remove('fillover')
  }
  const onDrop = (e) => {
    if (droppable) {
      setMessage('')
      e.target.classList.remove('fillover')
      setStudentInfo(JSON.parse(e.dataTransfer.getData('text')))
      setOpenAssign(true)
    }
  }

  const getUserName = (id) => {
    let studentName = {}
    if (centersPartners) {
      centersPartners.map((center) =>
        center.students.map((student) => {
          if (student._id === id) {
            studentName = student
          }
          return true
        })
      )
    }
    return studentName
  }
  const assignStudentHandler = () => {
    setMessage('')
    if (group.students.length >= 13) {
      setMessage('This group already has 13 students')
    } else {
      const alreadyAssigned = group.students.find((student) => student._id === studentInfo._id)
      if (!alreadyAssigned) {
        const newGroupInfo = {
          _id: group._id,
          student: {
            _id: studentInfo._id,
            name: studentInfo.name,
            username: studentInfo.username,
            email: studentInfo.email,
            center: studentInfo.center,
          },
        }
        dispatch(teamGroupUpdateInfo(newGroupInfo))
      } else {
        setMessage('Student already assigned to this group.')
      }
    }
  }

  const deleteStudentFromGroupHandler = (studentId) => {
    const newGroupInfo = {
      _id: group._id,
      studentRemove: studentId,
    }
    dispatch(teamGroupUpdateInfo(newGroupInfo))
  }

  const cardClasses = classNames({
    [classes.studentDropArea]: droppable,
  })

  return (
    <Card className={classes.root} draggable={draggable}>
      <CardHeader
        className={classes.headerRoot}
        disableTypography={true}
        action={
          <CustomDropdown
            left
            caret={false}
            buttonText={
              <div className={classes.btnwrapper}>
                <MoreVert />
              </div>
            }
            buttonProps={{
              className: classes.navLink + ' ' + classes.imageDropdownButton,
              color: 'transparent',
            }}
            dropdownList={[
              <div disabled={group.students.length > 0 ? false : true} onClick={() => setOpenManage(true)}>
                Manage
              </div>,
              <div onClick={() => window.open(group.hangoutLink, '_blank', 'noopener,noreferrer')}>
                Go to Meeting Room
              </div>,
            ]}
          />
        }
        title={group.name}
      />
      <CardContent className={classes.contentRoot}>
        <Grid container>
          <Grid item xs={12}>
            <p
              className={cardClasses}
              onDragOver={(e) => dragOver(e)}
              onDragEnter={(e) => dragEnter(e)}
              onDrop={(e) => onDrop(e)}
              onDragLeave={(e) => onDragLeave(e)}
            >
              Students: {group.students.length}
            </p>
          </Grid>
        </Grid>
      </CardContent>

      <CustomModal
        title='Assign Student?'
        open={openAssign}
        setOpen={setOpenAssign}
        acceptText='Assign'
        acceptHandler={assignStudentHandler}
      >
        <p>The student will be assign to this group.</p>
        {message && <Message message={<span>{message}</span>} color='info' />}
      </CustomModal>

      <CustomModal title={`Team ${group.name}`} open={openManage} setOpen={setOpenManage} actions={false}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {loadingPartners ? (
              <Loader />
            ) : (
              group &&
              group.students.map((student) => (
                <UserListCard
                  key={student._id}
                  user={getUserName(student._id)}
                  dropdownList={[
                    <div onClick={() => deleteStudentFromGroupHandler(student._id)}>Remove from group</div>,
                  ]}
                />
              ))
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button color='warning' block>
              Send Message
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              color='primary'
              block
              onClick={() => {
                setOpenManage(false)
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button color='info' block onClick={() => setOpenManage(false)}>
              Done
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Card>
  )
}

TeamGroupCard.defaultProps = {
  draggable: false,
  droppable: false,
}

TeamGroupCard.propTypes = {
  draggable: PropTypes.bool,
  droppable: PropTypes.bool,
}

export default TeamGroupCard

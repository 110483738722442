const styles = {
	root: {
		paddingTop: 40,
		maxWidth: 1200,
		margin: 'auto',
	},
	textMuted: {
		color: '#a099aa',
	},
	editBtnRoot: {
		textAlign: 'right',
	},
	divider: {
		height: '3px !important',
		margin: '20px 0',
		backgroundColor: 'red',
	},
	cardRoot: {
		padding: '10px',
	},
	partnersRoot: {
		padding: 5,
		// borderRadius: '6px',
		// border: '4px solid #ffc0c0',
	},
	partnersBox: {
		margin: '50px 0',
	},
	partnersCards: {
		marginTop: '50px',
	},
	centerLogo: {
		width: '70px',
		height: '70px',
		'& img': {
			width: '100%',
		},
	},
	cardTitle: {
		marginBottom: 0,
		textAlign: 'center',
	},
	countryFlagProfile: {
		width: '35px',
		borderRadius: '5px',
	},
	topicInfo: {
		marginTop: '10px',
		textAlign: 'center',
		'& h5': {
			color: '#79a94d',
		},
		'& img': {
			width: '100px',
			borderRadius: '6px',
		},
	},
}

export default styles

import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'

const styles = {
	contentItemsEnd,
	infiniteScroll,
	root: {
		padding: 30,
		'& h1': {
			padding: 0,
			margin: 0,
		},
	},
	btnResetSearch: {
		marginRight: '15px',
	},

	contentLine: {
		textAlign: 'center',
	},
	rightSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		'& h2': {
			textAlign: 'center',
		},
	},
	schoolIcon: {
		fontSize: 70,
		color: '#9c27b0',
	},
	contentLine: {
		textAlign: 'center',
	},
}

export default styles

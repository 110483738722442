import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Card, CardContent, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import Message from '../../../components/Message/Message'
import Button from '../../../components/Buttons/Button'
import Parallax from '../../../components/Parallax/Parallax'
import CustomInput from '../../../components/CustomInput/CustomInput'
import { sendBecomeASponsorForm, sendContactForm } from '../../../redux/actions/sendMailActions'
import { SEND_MAIL_RESET } from '../../../redux/constants/sendMailConstants'
import { useTranslation } from 'react-i18next'
import styles from './styles/contactScreenStyles'

const useStyles = makeStyles(styles)

const ContactScreen = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('global')

  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const { loadingSendMail, errorSendMail, successSendMail } = useSelector((state) => state.sendEMail)

  useEffect(() => {
    return () => {
      dispatch({ type: SEND_MAIL_RESET })
    }
  }, [dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    const emailData = {
      name,
      email,
      company,
      phone,
      subject,
      message,
    }
    if (history.location.pathname === '/become-a-sponsor') {
      dispatch(sendBecomeASponsorForm(emailData))
    } else {
      dispatch(sendContactForm(emailData))
    }
  }

  return (
    <>
      <Meta title={'Contact - Planet Fraternity'} />
      <Parallax image={'/assets/img/public-site/bg-contact.png'} />
      {loadingSendMail ? (
        <Loader />
      ) : errorSendMail ? (
        <Message>{errorSendMail}</Message>
      ) : successSendMail ? (
        <div className={classes.root}>
          <div className={classes.sections}>
            <Grid container className={classes.section}>
              <Grid item xs={12} className={classes.messageHeader}>
                <img
                  src='/assets/img/logo-planetfraternity500x500.png'
                  width='70%'
                  alt='logo Planet Fraternity'
                  loading='lazy'
                />
                <h1>¡Message Sent!</h1>
              </Grid>
              <Grid item xs={12}>
                <p>We just recieve your messege, we will contact you for further information.</p>
                <p>
                  Learn more about Planet Fraternity in our website{' '}
                  <Button color='primary' onClick={() => history.push('/')}>
                    Home
                  </Button>
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={classes.sections}>
          <Grid container className={classes.sectionRootReverse}>
            <Grid item xs={12} md={6} className={classes.onTopCardRoot}>
              <Card className={classes.onTopCardLeft}>
                <CardContent>
                  <div className={classes.descRoot}>
                    <form onSubmit={submitHandler}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={t('layout.t_name')}
                            id='name'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: name,
                              onChange: (e) => setName(e.target.value),
                            }}
                          />
                        </Grid>
                        {history.location.pathname === '/become-a-sponsor' && (
                          <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText={t('layout.t_company')}
                              id='name'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: company,
                                onChange: (e) => setCompany(e.target.value),
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={t('layout.t_email')}
                            id='email'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: 'email',
                              value: email,
                              onChange: (e) => setEmail(e.target.value),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={t('layout.t_phone')}
                            id='phone'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: phone,
                              onChange: (e) => setPhone(e.target.value),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={t('layout.t_subject')}
                            id='subject'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: subject,
                              onChange: (e) => setSubject(e.target.value),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText={t('layout.t_message')}
                            id='message'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              multiline: true,
                              rows: 5,
                              value: message,
                              onChange: (e) => setMessage(e.target.value),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <Button type='submit' color='primary'>
                            {t('layout.t_send')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} className={classes.contactLogo}>
              <img
                src='/assets/img/logo-planetfraternity500x500.png'
                width='70%'
                alt='logo Planet Fraternity'
                loading='lazy'
              />
              <h1>{t('layout.t_best-way-to-learn')}</h1>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default ContactScreen

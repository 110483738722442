import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = {
    grid: {
        marginRight: '-15px',
        marginLeft: '-15px',
        width: '100%',
    },
}

const useStyles = makeStyles(styles)

const GridContainer = ({ children, className, ...rest }) => {
    const classes = useStyles()
    return (
        <Grid container {...rest} className={classes.grid + ' ' + className}>
            {children}
        </Grid>
    )
}

GridContainer.defaultProps = {
    className: '',
}

GridContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default GridContainer

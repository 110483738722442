const styles = {
	button: {
		width: '100%',
		boxShadow:
			'0 2px 2px 0 rgb(156 39 176 / 14%), 0 3px 1px -2px rgb(156 39 176 / 20%), 0 1px 5px 0 rgb(156 39 176 / 12%)',
		backgroundColor: '#9c27b0',
		minWidth: 'auto',
		minHeight: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		border: 'none',
		cursor: 'pointer',
		margin: ' 0.3125rem 1px',
		padding: '12px 30px',
		position: 'relative',
		fontSize: '12px',
		textAlign: 'center',
		transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
		fontWeight: 400,
		lineHeight: 1.42857143,
		whiteSpace: 'nowrap',
		willChange: 'box-shadow, transform',
		touchAction: 'manipulation',
		borderRadius: '3px',
		letterSpacing: 0,
		textTransform: 'uppercase',
		verticalAlign: 'middle',
		'&:hover,&:focus': {
			boxShadow: '0px 10px 20px -2px #9c27b1',
			backgroundColor: '#9c27b0',
		},
	},
}
export default styles

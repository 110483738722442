const styles = {
	'@keyframes bgmovement': {
		from: {
			backgroundPosition: 'bottom left',
		},
		to: {
			backgroundPosition: 'top right',
		},
	},
	bgMove: {
		width: '100%',
		height: '100vh',
		backgroundImage: 'url(/assets/img/public-site/bg-sky.png)',
		backgroundSize: '150%',
		backgroundRepeat: 'no-repeat',
		animation: '$bgmovement 20s ease-in-out infinite alternate',
		position: 'relative',
	},
	bgHands: {
		width: '100%',
		height: '100%',
		backgroundImage: 'url(/assets/img/public-site/bg-hands.png)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		position: 'absolute',
	},
	root: {
		height: '100%',
	},
	titleRoot: {
		height: '100%',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		'& h1': {
			padding: '0 20px',
			textAlign: 'center',
			fontSize: '120px',
			fontWeight: '600',
			lineHeight: '1.1',
			display: 'inline-block',
			position: 'relative',
			color: '#f64ebd',
			textShadow: '7px 10px 15px rgb(7 44 25)',
			'-webkit-text-stroke': '1px #ffbee9',
		},
		'& h2': {
			width: '70%',
			padding: '0 20px',
			textAlign: 'center',
			letterSpacing: '25px',
			fontSize: '50px',
			fontWeight: '600',
			lineHeight: '1.1',
			display: 'inline-block',
			position: 'relative',
			color: '#ec0ca0',
			textShadow: '7px 10px 15px rgb(7 44 25)',
			'-webkit-text-stroke': '1px #ffbee9',
		},
	},

	'@media screen and (max-width: 980px)': {
		bgMove: {
			height: '80vh',
			backgroundSize: '200% 130%',
		},
	},
	'@media screen and (max-width: 580px)': {
		titleRoot: {
			'& h1': {
				fontSize: '60px',
				textShadow: '3px 3px 15px rgb(7 44 25)',
			},
			'& h2': {
				fontSize: '30px',
				textShadow: '3px 3px 15px rgb(7 44 25)',
			},
		},
	},
}

export default styles

import axios from 'axios'
import * as types from '../constants/teamConstants'

export const registerTeamPanel = (panelInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/teams', panelInfo, config)

		dispatch({ type: types.TEAM_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeamPanels =
	(keyword = '', count, start) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: types.TEAM_LIST_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			let dataResponse = []

			if (keyword !== '') {
				const { data } = await axios.get(`/api/teams?count=${count}&start=${start}&keyword=${keyword}`, config)
				dataResponse = data
			} else {
				const { data } = await axios.get(`/api/teams?count=${count}&start=${start}`, config)
				dataResponse = data
			}

			dispatch({ type: types.TEAM_LIST_SUCCESS, payload: dataResponse })
		} catch (error) {
			dispatch({
				type: types.TEAM_LIST_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getTeamPanelById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teams/${id}`, config)

		dispatch({ type: types.TEAM_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getMyTeamPanel = (centerId) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_MY_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teams/my/${centerId}`, config)

		dispatch({ type: types.TEAM_MY_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_MY_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const teamPanelUpdateInfo = (panel) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/teams/${panel._id}`, panel, config)

		dispatch({ type: types.TEAM_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const teamCreateFinalProject = (id, project) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_PROJECT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const formData = new FormData()
		formData.append('rate', project.rate)
		formData.append('group', project.group)
		project.images.forEach((elm) => {
			formData.append('images', elm)
		})
		formData.append('video', project.video)
		formData.append('application', project.file)
		const { data } = await axios.post(`/api/teams/project/${id}`, formData, config)
		dispatch({ type: types.TEAM_PROJECT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_PROJECT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const teamUpdateFinalProject = (id, idProject, project) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_PROJECT_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const formData = new FormData()

		formData.append('group', project.group)
		formData.append('rate', project.rate)
		formData.append('winner', project.winner)
		formData.append('application', project.file)
		project.images?.forEach((elm) => {
			formData.append('images', elm)
		})
		formData.append('video', project.video)

		const { data } = await axios.put(`/api/teams/${id}/project/${idProject}`, formData, config)

		dispatch({ type: types.TEAM_PROJECT_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_PROJECT_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const teamUpdateWinnerFinalProject = (id, idProject, project) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_PROJECT_UPDATE_WINNER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/teams/${id}/vote/${idProject}`, project, config)

		dispatch({ type: types.TEAM_PROJECT_UPDATE_WINNER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_PROJECT_UPDATE_WINNER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const teamDeleteFinalProject = (id, idProject) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_PROJECT_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/teams/${id}/project/${idProject}`, config)

		dispatch({ type: types.TEAM_PROJECT_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: types.TEAM_PROJECT_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getTeamsFinalProjects = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_PROJECT_WINNER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/teams/projects/${id}`, config)

		dispatch({ type: types.TEAM_PROJECT_WINNER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.TEAM_PROJECT_WINNER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteTeamPanel = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.TEAM_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/teams/${id}`, config)

		dispatch({ type: types.TEAM_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: types.TEAM_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

const styles = {
  root: {
    maxWidth: '600px',
  },
  inputItem: {
    margin: 0,
  },
  passMessage: {
    padding: '15px 0',
  },
  formControl: {
    marginTop: 0,
    width: '100%',
    textAlign: 'left',
  },
  rootQuestion: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  sectionRoot: {
    width: '100%',
  },
  sectionButton: {
    marginBottom: '20px',
  },
  sectionSelects: {
    marginTop: '20px',
  },
}
export default styles

const styles = {
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  formControl: {
    marginTop: '15px',
    minWidth: 120,
    width: '100%',
  },
  fileBadge: {
    margin: '20px 0',
    width: '100%',
  },
  selectLabel: {
    color: '#AAAAAA !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    marginButton: '10px',
  },
  selectInput: {
    marginTop: '7px',
  },
  previewRoot: {
    border: '2px solid #e5e5e5',
    borderRadius: '16px',
    color: '#65676b',
    fontSize: 17,
  },
  infoLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#aaaaaa !important',
    fontSize: '16px !important',
    padding: '14px',
    fontWeight: '800',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    margin: '30px 0px 10px 0px',
    '& span': {
      marginLeft: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        color: '#3f51b5',
        fontSize: '80px',
        margin: '0',
      },
    },
  },
  infoLabel2: {
    cursor: 'not-allowed ',
    opacity: '0.5',
  },
  SubInfoLabel: {
    fontSize: '0.6rem',
  },
  fileDiv: {
    textAlign: 'center',
    backgroundColor: '#3f51b5',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    width: '95%',
  },
  videoDiv: {
    textAlign: 'center',
    backgroundColor: '#3f51b5',
    color: '#fff',
    borderRadius: '5px',
    padding: '5px',
    width: '95%',
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '30px',
    width: '100%',
    '& svg': {
      color: '#3f51b5',
    },
  },
  infoGrid: {
    border: '2px solid #e5e5e5',
    borderRadius: '16px',
    color: '#65676b',
    fontSize: 17,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& div': {
      padding: '20px',
    },
    '& img': {
      objectFit: 'contain',
      width: '90px',
      height: '80px',
    },
  },
}

export default styles

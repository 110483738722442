import { Grid } from '@material-ui/core'
import Button from '../../../../components/Buttons/Button'
import Loader from '../../../../components/Loader/Loader'

const TeamsByAgesAutomateModal = ({ setOpenConfirm, confirmGroupAutomation, loadingTeamsGroupsAutomation }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <p>You are about to automate the creation of teams and groups.</p>
        <p>Click confirm to proceed.</p>
      </Grid>
      <Grid item xs={5}>
        <Button color='info' block onClick={() => setOpenConfirm(false)}>
          Cancel
        </Button>
      </Grid>
      <Grid item xs={7}>
        <Button color='primary' block onClick={confirmGroupAutomation}>
          {loadingTeamsGroupsAutomation ? <Loader size={'sm'} /> : 'Confirm'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default TeamsByAgesAutomateModal

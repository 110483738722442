export const INFORMATION_REGISTER_REQUEST = 'INFORMATION_REGISTER_REQUEST'
export const INFORMATION_REGISTER_SUCCESS = 'INFORMATION_REGISTER_SUCCESS'
export const INFORMATION_REGISTER_FAIL = 'INFORMATION_REGISTER_FAIL'
export const INFORMATION_REGISTER_RESET = 'INFORMATION_REGISTER_RESET'

export const INFORMATION_LIST_REQUEST = 'INFORMATION_LIST_REQUEST'
export const INFORMATION_LIST_SUCCESS = 'INFORMATION_LIST_SUCCESS'
export const INFORMATION_LIST_FAIL = 'INFORMATION_LIST_FAIL'
export const INFORMATION_LIST_RESET = 'INFORMATION_LIST_RESET'

export const INFORMATION_DELETE_REQUEST = 'INFORMATION_DELETE_REQUEST'
export const INFORMATION_DELETE_SUCCESS = 'INFORMATION_DELETE_SUCCESS'
export const INFORMATION_DELETE_FAIL = 'INFORMATION_DELETE_FAIL'
export const INFORMATION_DELETE_RESET = 'INFORMATION_DELETE_RESET'

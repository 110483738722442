import classNames from 'classnames'
import { Grid, Card, makeStyles } from '@material-ui/core'
import CenterCard from './CenterCard'
import styles from '../styles/teamCardStyles'

const useStyles = makeStyles(styles)

const TeamCard = ({ team, groups, setGroups }) => {
  const classes = useStyles()

  const dragOver = (e) => {
    e.preventDefault()
  }
  const dragEnter = (e) => {
    e.preventDefault()
    e.target.className += ' fillover'
  }
  const onDragLeave = (e) => {
    e.target.classList.remove('fillover')
  }
  const onDrop = (e) => {
    e.target.classList.remove('fillover')
    let theGroup = JSON.parse(e.dataTransfer.getData('text'))

    let updatedGroup = groups.map((team) => {
      let groupOut = team.groups.find(
        (group) => group._id === theGroup._id && group.groups[0].ageRange === theGroup.groups[0].ageRange
      )
      if (groupOut) {
        team.groups = team.groups.filter((group) => group._id !== theGroup._id)
      }
      let teamIn = team.groupName.toLowerCase()
      if (teamIn === e.target.id) {
        team.groups.push(theGroup)
      }
      return team
    })
    setGroups(updatedGroup)
  }

  const deleteCenterFromTeam = (theGroup) => {
    let updatedGroup = groups.map((team) => {
      let groupOut = team.groups.find(
        (group) => group._id === theGroup._id && group.groups[0].ageRange === theGroup.groups[0].ageRange
      )
      if (groupOut) {
        team.groups = team.groups.filter((group) => group._id !== theGroup._id)
      }

      return team
    })
    setGroups(updatedGroup)
  }

  return (
    <Grid item xs={12} sm={4} md={4} >
      <Card className={classNames(classes.teamCard, team.works ? classes.existMatch : '')}>
        <h4>{team.groupName}</h4>
        <div
          id={team.groupName.toLowerCase()}
          onDragOver={(e) => dragOver(e)}
          onDragEnter={(e) => dragEnter(e)}
          onDrop={(e) => onDrop(e)}
          onDragLeave={(e) => onDragLeave(e)}
          className={classes.dropArea}
        >
          You can drop here more centers
        </div>
        {team.groups.map((group, groupIndex) => (
          <CenterCard key={groupIndex} group={group} deleteCenterFromTeam={deleteCenterFromTeam} />
        ))}
        {team.groups.length === 1 && (
          <div className={classes.groupCard}>
            <p className={classes.groupDanger}>
              You must move this center to another team or delete it from this panel and wait for another subscription.
            </p>
          </div>
        )}
      </Card>
    </Grid>
  )
}

export default TeamCard

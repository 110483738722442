const styles = {
  root: {
    paddingTop: 40,
    maxWidth: 1200,
    margin: 'auto',
  },
  cardRoot: {
    padding: '10px',
  },
  writingHeader: {
    textAlign: 'center',
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  checked: {
    color: '#9c27b0 !important',
  },
  checkRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: 'rgba(0, 0, 0, 0.26)',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  labelRoot: {
    marginLeft: '-14px',
    marginBottom: 0,
  },
  btnLink: {
    fontSize: 12,
    fontWeight: '500',
    color: '#483d3d',
    margin: '15px 0',
    padding: 0,
    textTransform: 'none',
    '& span': {
      fontSize: 14,
    },
  },
}

export default styles

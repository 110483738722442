const styles = {
  panelRoot: {
    padding: '20px',
  },
  flexAlignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  textMuted: {
    color: '#5f6368',
  },
}

export default styles

const styles = {
	sectionRoot: {
		padding: 60,
	},
	sectionRootWReverse: {
		padding: 60,
	},
	imgRoot: {
		position: 'relative',
		'& img': {
			borderRadius: '5px',
			boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
		},
	},
	imgText: {
		position: 'absolute',
		top: 0,
		padding: 10,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: '#fff !important',
		'& h2': {
			color: '#fff !important',
		},
		'& p': {
			fontSize: 18,
		},
	},
	descRoot: {
		padding: 40,
		width: '100%',
		height: '100%',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'& p': {
			padding: '10px 0',
			margin: 0,
		},
	},
	descIcon: {
		fontSize: 60,
		color: '#4caf50',
	},
	descActions: {
		width: '100%',
		textAlign: 'right',
	},
	nbtcIcon: {
		fontSize: 40,
		color: '#91b993',
	},
	btcIcon: {
		fontSize: 60,
		color: '#1ad424',
	},
	iCanIcon: {
		fontSize: 60,
		color: '#8080ff',
	},
	onTopCardRoot: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
	},
	onTopCardRight: {
		position: 'absolute',
		left: '-50px',
		boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
	},
	onTopCardLeft: {
		position: 'absolute',
		right: '-50px',
		zIndex: 100,
		boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
	},
	socialSection: {
		textAlign: 'center',
		padding: '0px 0px 120px 0px',
		'& h4': {
			fontSize: '38px',
			padding: '40px 0px',
			marginBottom: '30px',
			textAlign: 'center',
		},
	},
	iconNetworks: {
		cursor: 'pointer',
		borderRadius: '70px',
		boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
		padding: 0,
		margin: 0,
		transition: '.5s',
		'&:hover': {
			transform: 'translateY(-40px) ',
			boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
		},
		'& img': {
			height: '160px',
			width: '160px',
		},
	},
	becomeASponsor: {
		padding: '50px',
		textAlign: 'center',
		justifyContent: 'center',
		'& h4': {
			fontSize: '38px',
			marginBottom: '40px',
		},
		'& p': {
			fontSize: '18px',
			textAlign: 'center',
		},
		'& button': {
			marginRight: '10px',
		},
	},
	customerCarousels: {
		marginTop: '4%',
	},
	carouselItem: {
		textAlign: 'center',
		paddingBottom: '14px',
		marginBottom: '0px',
		opacity: '0.2',
		transform: 'scale3d(0.8, 0.8, 1)',
		transition: 'all 0.3s ease-in-out',
	},
	testimonialSection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	shadowEffectCongress: {
		background: '#fff',
		borderRadius: '4px',
		textAlign: 'start',
		border: '1px solid #ececec',
		position: 'relative',
		display: 'inline-block',
		'& p': {
			fontSize: '19px',
			lineHeight: '1.5',
			margin: '0 0 17px 0',
			fontWeight: 300,
		},
		'& img': {
			// width: '1080px !important',
		},
	},
	shadowEffect: {
		background: '#fff',
		borderRadius: '4px',
		textAlign: 'start',
		border: '1px solid #ececec',
		position: 'relative',
		display: 'inline-block',
		'& p': {
			fontSize: '19px',
			lineHeight: '1.5',
			margin: '0 0 17px 0',
			fontWeight: 300,
		},
		'& img': {
			height: '206px',
			width: '275px',
		},
	},
	sliderRoot: {
		padding: 7,
		paddingTop: 0,
		'& h4': {
			marginTop: '45px',
			textAlign: 'center',
			fontSize: '25px',
			color: '#1f7a2e',
		},
	},
	mainCarouselSection: {
		padding: '50px',
	},
	carouselSectionOne: {
		background: '#ffb6af',
		minHeight: '300px',
		// padding: '70px',
		border: '1px solid #eeeeee ',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& h4': {
			fontSize: '32px',
		},
		'& p': {
			fontSize: '16px',
		},
	},
	carouselSectionTwo: {
		minHeight: '300px',
		// padding: '70px',
		background: '#8ac755',
		border: '1px solid #eeeeee ',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& h4': {
			fontSize: '32px',
		},
		'& p': {
			fontSize: '16px',
		},
	},
	carouselSectionThree: {
		minHeight: '300px',
		background: '#0093d5',
		border: '1px solid #eeeeee ',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& h4': {
			fontSize: '32px',
		},
		'& p': {
			fontSize: '16px',
		},
	},
	carouselSectionFour: {
		minHeight: '300px',
		background: '#fff078',
		border: '1px solid #eeeeee ',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& h4': {
			fontSize: '32px',
		},
		'& p': {
			fontSize: '16px',
		},
	},
	paddingMax: {
		padding: '70px',
	},
	sectionMapBlog: {
		padding: '10px',
		textAlign: 'center',
		'& h3': {
			margin: '45px 0px',
			fontSize: '25px',
		},
	},
	sectionMap: {
		textAlign: 'center',
		padding: 10,
	},
	marginMapAuto: {
		margin: 'auto',
		height: '100%',
	},
	sectionBlog: {
		height: '85vh',
		margin: 'auto',
		boxShadow: '0px  0px 15px #b5b5b5 inset',
		backgroundColor: '#f1f1f1',
		border: '1px solid #fff',
		padding: 10,
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: 'gray',
			borderRadius: '5px',
		},
	},
	'@media screen and (max-width: 1200px)': {
		descRoot: {
			padding: 0,
		},
	},
	'@media screen and (max-width: 980px)': {
		onTopCardRight: {
			marginTop: 30,
			position: 'inherit',
			left: 0,
		},
		onTopCardLeft: {
			marginTop: 30,
			position: 'inherit',
			right: 0,
		},
		mainCarouselSection: {
			padding: '40px',
		},
		paddingMax: {
			padding: '50px',
		},
	},
	'@media screen and (max-width: 960px)': {
		iconNetworks: {
			marginBottom: '70px',
		},
	},
	'@media screen and (max-width: 580px)': {
		carouselSection: {
			paddingTop: '0px',
		},
		sectionRoot: {
			padding: '20px 10px',
		},
		sectionRootWReverse: {
			padding: '20px 10px',
			flexDirection: 'column-reverse',
		},
		descRoot: {
			padding: '40px 30px',
		},
		iconNetworks: {
			height: '140px',
			width: '150px',
		},
		mainCarouselSection: {
			padding: '15px',
		},
		paddingMax: {
			padding: '50px',
		},
		sectionMapBlog: {
			padding: '30px',
		},
	},
}

export default styles

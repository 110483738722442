import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'

const TeamsManageCreateTeamGroupModal = ({ groupName, setGroupName, createGroupHandler }) => {
  const { loadingTeamGroupRegister, errorTeamGroupRegister } = useSelector((state) => state.teamGroupRegister)
  return (
    <>
      <p>Make sure to sequence the group names</p>
      <CustomInput
        id='group-name'
        inputProps={{
          autoFocus: true,
          placeholder: 'Example: Group 1',
          type: 'text',
          value: groupName,
          autoComplete: 'off',
          onChange: (e) => setGroupName(e.target.value),
        }}
        formControlProps={{
          fullWidth: true,
        }}
      />
      {errorTeamGroupRegister && <Message message={<span>{errorTeamGroupRegister}</span>} color='info' />}
      <Button color='primary' block onClick={createGroupHandler}>
        {loadingTeamGroupRegister ? <Loader /> : 'Create Group'}
      </Button>
    </>
  )
}

export default TeamsManageCreateTeamGroupModal

const styles = {
    deletePhrase: {
        backgroundColor: '#e4e1e1',
        width: 'fit-content',
        padding: '3px 5px',
        borderRadius: '5px',
    },
}

export default styles

import axios from 'axios'
import * as types from '../constants/countryPostConstants'

export const registerCountryPost = (payload) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.COUNTRY_POST_CREATE_REQUEST })

		const {
			userLogin: { userInfo },
			centerDetails,
		} = getState()

		const configs = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const epPayload = {
			...payload,
			studentId: userInfo._id,
			studentName: userInfo?.name,
			studentCenter: centerDetails?.center?.name,
			country: userInfo.country._id,
		}

		const fieldsFiles = ['selectedFiles']

		let formData = new FormData()

		for (let key in epPayload) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, epPayload[key])
			}
		}

		if (epPayload.selectedFiles.length > 0) {
			epPayload.selectedFiles.forEach((e) => {
				formData.append('images', e)
			})
		}

		await axios.post('/api/country-post', formData, configs)

		dispatch({ type: types.COUNTRY_POST_CREATE_SUCCESS, payload: true })
		setTimeout(() => {
			dispatch({ type: types.COUNTRY_POST_CREATE_RESET })
		}, 4000)
	} catch (error) {
		dispatch({
			type: types.COUNTRY_POST_CREATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getCountryPosts = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.COUNTRY_POST_LIST_REQUEST })

		const { data } = await axios.get('/api/country-post/')

		dispatch({ type: types.COUNTRY_POST_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.COUNTRY_POST_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getCountryPostsByStatusAndCountry = (status, country, count, start) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.statusFilterInterface(status)?.request })

		const { countryPosts } = getState()[status ? 'countryPostActiveList' : 'countryPostInactiveList']

		const { data } = await axios.get(
			`/api/country-post/filter?status=${status}&country=${country}&count=${count}&start=${start}`,
		)

		dispatch({
			type: types.statusFilterInterface(status)?.success,
			payload: {
				data: (countryPosts ?? []).concat(data),
				thatsIt: data.length === 0 ? true : false,
			},
		})
	} catch (error) {
		dispatch({
			type: types.statusFilterInterface(status)?.fail,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateCountryPost = (id, newData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.COUNTRY_POST_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
			countryPostList: { countryPosts },
		} = getState()

		const headers = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const response = await axios.put('/api/country-post/' + id, newData, headers)

		const { countryPostUpdated } = response.data

		const currentData = [...countryPosts]
		const indexOfNew = currentData.findIndex((d) => d._id === countryPostUpdated._id)
		currentData.splice(indexOfNew, 1, countryPostUpdated)

		dispatch({
			type: types.COUNTRY_POST_LIST_SUCCESS,
			payload: currentData,
		})
		dispatch({
			type: types.COUNTRY_POST_UPDATE_SUCCESS,
			payload: true,
		})
		setTimeout(() => {
			dispatch({
				type: types.COUNTRY_POST_UPDATE_RESET,
			})
		}, 3000)

		return response
	} catch (error) {
		dispatch({
			type: types.COUNTRY_POST_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const findCountryPostByUser = (student) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.COUNTRY_POST_VERIFY_POSTED_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const headers = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/country-post/find-country-post-by-user/' + student, headers)

		dispatch({ type: types.COUNTRY_POST_VERIFY_POSTED_SUCCESS, payload: Boolean(data?._id) })

		return Boolean(data?._id)
	} catch (error) {
		dispatch({
			type: types.COUNTRY_POST_VERIFY_POSTED_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
		return false
	}
}

export const deleteCountryPost = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.COUNTRY_POST_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const headers = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete('/api/country-post/' + id, headers)

		dispatch({
			type: types.COUNTRY_POST_DELETE_SUCCESS,
			payload: true,
		})
		setTimeout(() => {
			dispatch({
				type: types.COUNTRY_POST_DELETE_RESET,
			})
		}, 3000)
	} catch (error) {
		dispatch({
			type: types.COUNTRY_POST_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

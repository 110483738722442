import axios from 'axios'
import { Card, CardHeader, Avatar, makeStyles, Tooltip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import styles from '../styles/blogItemListStyles'

const useStyles = makeStyles(styles)

const BlogItemList = ({ item, setMiniBlogItemDetail, numberItem }) => {
	const classes = useStyles()

	return item ? (
		<Card className={classes.root}>
			<CardHeader
				className={classes.cardHeaderRoot}
				avatar={<Avatar aria-label='recipe' src={`${axios.defaults.baseURL}/${item.image}`}></Avatar>}
				action={
					<CustomDropdown
						left
						caret={false}
						buttonText={
							<div className={classes.btnwrapper}>
								<MoreVert />
							</div>
						}
						buttonProps={{
							className: classes.navLink + ' ' + classes.imageDropdownButton,
							color: 'transparent',
						}}
						dropdownList={[<div onClick={() => setMiniBlogItemDetail(item)}>View Item</div>]}
					/>
				}
				title={
					<Tooltip placement='left-start' title={item?.title}>
						<span>{item?.title.length > 21 ? item?.title.slice(0, 21) + '...' : item?.title}</span>
					</Tooltip>
				}
				subheader={
					item?.title ? (
						<Tooltip placement='left-start' title={item?.title}>
							<span>{item?.title.length > 21 ? item?.title.slice(0, 21) + '...' : item?.title}</span>
						</Tooltip>
					) : null
				}
			/>
		</Card>
	) : null
}

export default BlogItemList

import * as types from '../constants/pricePerCountryConstants'

export const pricePerCountryListReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PRICE_PER_COUNTRY_LIST_REQUEST:
      return { loadingPricePerCountryList: true }
    case types.PRICE_PER_COUNTRY_LIST_SUCCESS:
      return {
        loadingPricePerCountryList: false,
        successPricePerCountryList: true,
        pricePerCountryList: action.payload,
      }
    case types.PRICE_PER_COUNTRY_LIST_FAIL:
      return {
        loadingPricePerCountryList: false,
        errorPricePerCountryList: action.payload,
      }
    case types.PRICE_PER_COUNTRY_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const pricePerCountryDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PRICE_PER_COUNTRY_DETAILS_REQUEST:
      return { loadingPricePerCountryDetails: true }
    case types.PRICE_PER_COUNTRY_DETAILS_SUCCESS:
      return {
        loadingPricePerCountryDetails: false,
        successPricePerCountryDetails: true,
        pricePerCountry: action.payload,
      }
    case types.PRICE_PER_COUNTRY_DETAILS_FAIL:
      return {
        loadingPricePerCountryDetails: false,
        errorPricePerCountryDetails: action.payload,
      }
    case types.PRICE_PER_COUNTRY_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const pricePerCountryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PRICE_PER_COUNTRY_UPDATE_REQUEST:
      return { loadingPricePerCountryUpdate: true }
    case types.PRICE_PER_COUNTRY_UPDATE_SUCCESS:
      return {
        loadingPricePerCountryUpdate: false,
        successPricePerCountryUpdate: true,
        pricePerCountry: action.payload,
      }
    case types.PRICE_PER_COUNTRY_UPDATE_FAIL:
      return {
        loadingPricePerCountryUpdate: false,
        errorPricePerCountryUpdate: action.payload,
      }
    case types.PRICE_PER_COUNTRY_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import CPHeader from '../../components/Headers/CPHeader'
import CPSidebar from '../../components/Sidebars/CPSidebar'
import CPFooter from '../../components/Footers/CPFooter'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'
import teachersRoutes from '../../routes/teacher-panel-routes'

const TeacherPanelLayout = () => {
  const history = useHistory()

  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    if (userInfo && userInfo.role !== 'teacher') {
      history.push('/')
    } else if (!userInfo) {
      history.push('/auth/login')
    }
  }, [userInfo, history])

  const menuToggleHandler = () => {
    document.getElementById('wrapper').classList.toggle('toggled')
  }

  return (
    <main id='wrapper'>
      <CPSidebar />
      <CPHeader />
      <div className='content-wrapper'>
        <Switch>
          {teachersRoutes.map((route, i) => (
            <Route key={i} path={`/teacher${route.path}`} component={route.comp} exact={route.exact} />
          ))}
        </Switch>
        <div className='overlay' onClick={menuToggleHandler}></div>
      </div>
      <CPFooter />
    </main>
  )
}

export default TeacherPanelLayout

import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import CardBody from '../../../../components/Card/CardBody'
import CardAvatar from '../../../../components/Card/CardAvatar'
import TeacherCardForm from './TeacherCardForm'
import { getCenterById } from '../../../../redux/actions/centerActions'
import styles from '../styles/teacherCardstyles'

const useStyles = makeStyles(styles)

const TeacherCard = ({ teacher, setTeacherDetails }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const editteacherform = useRef(null)

  const { center } = useSelector((state) => state.centerDetails)

  const scrollToRef = () => {
    editteacherform.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToRef()
  }, [teacher])
  useEffect(() => {
    dispatch(getCenterById(teacher.center))
  }, [dispatch, teacher])

  return (
    <Grid container ref={editteacherform}>
      <Grid item xs={12} sm={12} md={12}>
        <Card profile>
          <CardAvatar profile>
            <img src={`${axios.defaults.baseURL}${teacher.avatar}`} alt={teacher.name} />
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{teacher.name}</h4>
            <span className={classes.cardSubTitle}>Center · {center && center.name}</span> -
            <span className={classes.cardSubTitle}>
              Country · {teacher && teacher.country.name} ·{' '}
              <img
                className={classes.countryFlag}
                src={`${axios.defaults.baseURL}${teacher.country.flag}`}
                alt={teacher.country.name}
              />
            </span>
            <TeacherCardForm teacher={teacher} setTeacherDetails={setTeacherDetails} />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TeacherCard

import { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, SnackbarContent, IconButton, Icon } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styles from './styles/messageStyles'

const useStyles = makeStyles(styles)

const Message = (props) => {
	const { message, color, close, icon } = props
	const classes = useStyles()
	let action = []
	const closeAlert = () => {
		setAlert(null)
	}
	if (close !== undefined) {
		action = [
			<IconButton className={classes.iconButton} key='close' aria-label='Close' color='inherit' onClick={closeAlert}>
				<Close className={classes.close} />
			</IconButton>,
		]
	}
	let snackIcon = null
	switch (typeof icon) {
		case 'object':
			snackIcon = <props.icon className={classes.icon} />
			break
		case 'string':
			snackIcon = <Icon className={classes.icon}>{props.icon}</Icon>
			break
		default:
			snackIcon = null
			break
	}
	const [alert, setAlert] = useState(
		<SnackbarContent
			message={
				<div>
					{snackIcon}
					{message}
					{close !== undefined ? action : null}
				</div>
			}
			classes={{
				root: classes.root + ' ' + classes[color],
				message: classes.message + ' ' + classes.container,
			}}
		/>,
	)
	return alert
}

Message.propTypes = {
	message: PropTypes.node.isRequired,
	color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
	close: PropTypes.bool,
	icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Message

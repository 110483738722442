const styles = {
    roomHeader: {
        padding: 5,
        backgroundColor: '#3e3e3e',
        '& svg': {
            color: 'white',
        },
    },
    roomRoot: {
        padding: 10,
    },
    roomSection: {
        backgroundColor: 'black',
        width: '100%',
        height: '200px'
    }
}
export default styles

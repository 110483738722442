import { contentItemsEnd, infiniteScroll } from '../../../../assets/jss/material-ui-styles'
const styles = {
  contentItemsEnd,
  infiniteScroll,

  mainSection: {
    height: '100%',
  },

  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    margin: 'auto 0',
    '& h2': {
      textAlign: 'center',
    },
  },
  peoplesIcon: {
    fontSize: 70,
    color: '#4caf50',
  },
}
export default styles

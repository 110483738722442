import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Avatar, CardHeader, Grid, makeStyles } from '@material-ui/core'
import Card from '../../../../components/Card/Card'
import { Assignment, MoreVert } from '@material-ui/icons'
import CustomDropdown from '../../../../components/CustomDropdown/CustomDropdown'
import CustomModal from '../../../../components/Modal/CustomModal'
import AnswersTeacherModal from './AnswersTeacherModal'
import styles from '../styles/answerCardStyles'

const useStyles = makeStyles(styles)

const AnswerCard = ({ answers }) => {
  const classes = useStyles()
  const viewTeachers = useRef(null)

  const [openModal, setOpenModal] = useState(false)
  const [infoTeacher, setInfoTeacher] = useState([])
  const [teacher, setTeacher] = useState('')

  useEffect(() => {
    scrollToRef()
  }, [answers])

  const scrollToRef = () => {
    viewTeachers.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleAnswersModal = (info) => {
    setOpenModal(true)
    setInfoTeacher(info)
    setTeacher(info?.teacher?.name)
  }

  return (
    <>
      <Grid className={classes.mainSection} container ref={viewTeachers}>
        <Grid item xs={12}>
          {answers.length !== 0 ? (
            answers.map((info) => {
              return (
                <div className='user-card' key={info._id}>
                  <Card className={classes.root}>
                    <CardHeader
                      className={classes.cardHeaderRoot}
                      avatar={
                        <Avatar aria-label='recipe' src={`${axios.defaults.baseURL}${info.teacher.avatar}`}></Avatar>
                      }
                      action={
                        <CustomDropdown
                          left
                          caret={false}
                          buttonText={
                            <div className={classes.btnwrapper}>
                              <MoreVert />
                            </div>
                          }
                          buttonProps={{
                            className: classes.navLink + ' ' + classes.imageDropdownButton,
                            color: 'transparent',
                          }}
                          dropdownList={[<div onClick={() => handleAnswersModal(info)}>View Answers</div>]}
                        />
                      }
                      title={info.teacher.name}
                      subheader={info.teacher.email}
                    />
                  </Card>
                </div>
              )
            })
          ) : (
            <div className={classes.rightSection}>
              <Assignment className={classes.peoplesIcon} />
              <h2>No Answers found for this survey.</h2>
            </div>
          )}
        </Grid>
      </Grid>
      <CustomModal title={`Answers from ${teacher}`} open={openModal} setOpen={setOpenModal} actions={false}>
        <AnswersTeacherModal infoTeacher={infoTeacher} />
      </CustomModal>
    </>
  )
}

export default AnswerCard

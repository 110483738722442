export const MINIBLOG_REGISTER_REQUEST = 'MINIBLOG_REGISTER_REQUEST'
export const MINIBLOG_REGISTER_SUCCESS = 'MINIBLOG_REGISTER_SUCCESS'
export const MINIBLOG_REGISTER_FAIL = 'MINIBLOG_REGISTER_FAIL'
export const MINIBLOG_REGISTER_RESET = 'MINIBLOG_REGISTER_RESET'

export const MINIBLOG_LIST_REQUEST = 'MINIBLOG_LIST_REQUEST'
export const MINIBLOG_LIST_SUCCESS = 'MINIBLOG_LIST_SUCCESS'
export const MINIBLOG_LIST_FAIL = 'MINIBLOG_LIST_FAIL'
export const MINIBLOG_LIST_RESET = 'MINIBLOG_LIST_RESET'

export const MINIBLOG_ACTIVE_LIST_REQUEST = 'MINIBLOG_ACTIVE_LIST_REQUEST'
export const MINIBLOG_ACTIVE_LIST_SUCCESS = 'MINIBLOG_ACTIVE_LIST_SUCCESS'
export const MINIBLOG_ACTIVE_LIST_FAIL = 'MINIBLOG_ACTIVE_LIST_FAIL'
export const MINIBLOG_ACTIVE_LIST_RESET = 'MINIBLOG_ACTIVE_LIST_RESET'

export const MINIBLOG_DELETE_REQUEST = 'MINIBLOG_DELETE_REQUEST'
export const MINIBLOG_DELETE_SUCCESS = 'MINIBLOG_DELETE_SUCCESS'
export const MINIBLOG_DELETE_FAIL = 'MINIBLOG_DELETE_FAIL'
export const MINIBLOG_DELETE_RESET = 'MINIBLOG_DELETE_RESET'

export const MINIBLOG_UPDATE_REQUEST = 'MINIBLOG_UPDATE_REQUEST'
export const MINIBLOG_UPDATE_SUCCESS = 'MINIBLOG_UPDATE_SUCCESS'
export const MINIBLOG_UPDATE_FAIL = 'MINIBLOG_UPDATE_FAIL'
export const MINIBLOG_UPDATE_RESET = 'MINIBLOG_UPDATE_RESET'

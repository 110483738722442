import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Hidden, List, ListItem, makeStyles } from '@material-ui/core'
import {
  // Create, Hearing, LocalLibrary, RecordVoiceOver,
  VpnKey,
} from '@material-ui/icons'
import Button from '../Buttons/Button'
import CustomDropdown from '../CustomDropdown/CustomDropdown'
import { logout } from '../../redux/actions/userActions'
import styles from './styles/studentHeaderLinksStyles'

const useStyles = makeStyles(styles)

const StudentHeaderLinks = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const { userInfo } = useSelector((state) => state.userLogin)

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
                <Button component={Link} to='/student/reading' color='transparent' className={classes.navLink}>
                    <LocalLibrary style={{ color: '#1cb0f6' }} />
                    Reading
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button component={Link} to='/student/listening' color='transparent' className={classes.navLink}>
                    <Hearing style={{ color: '#fec701' }} />
                    Listening
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button component={Link} to='/student/speaking' color='transparent' className={classes.navLink}>
                    <RecordVoiceOver style={{ color: '#ff4b4b' }} />
                    Speaking
                </Button>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Button component={Link} to='/student/writing' color='transparent' className={classes.navLink}>
                    <Create style={{ color: '#ff9600' }} />
                    Writing
                </Button>
            </ListItem> */}
      <Hidden only={['md', 'lg', 'xl']}>
        <ListItem className={classes.listItem}>
          <Button component={Link} to='/student/dashboard' color='transparent' className={classes.navLink}>
            My Dashboard
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button component={Link} to='/student/profile' color='transparent' className={classes.navLink}>
            My Profile
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button onClick={logoutHandler} color='transparent' className={classes.navLink}>
            Logout
          </Button>
        </ListItem>
      </Hidden>
      <Hidden only={['xs', 'sm']}>
        <ListItem className={classes.listItem}>
          {userInfo ? (
            <CustomDropdown
              className={classes.cardHeaderRoot}
              left
              caret={false}
              buttonText={
                <img src={`${axios.defaults.baseURL}${userInfo.avatar}`} className={classes.img} alt='profile' />
              }
              buttonProps={{
                className: classes.navLink + ' ' + classes.imageDropdownButton,
                color: 'transparent',
              }}
              dropdownList={[
                <div onClick={() => history.push('/student/dashboard')}>My Dashboard</div>,
                <div onClick={() => history.push('/student/profile')}>My Profile</div>,
                // <div onClick={() => history.push('/student/writing-contest')}>Writing Contest</div>,
                <div onClick={logoutHandler}>Logout</div>,
              ]}
            />
          ) : (
            <Button component={Link} to='/auth/login' color='transparent' className={classes.navLink}>
              <VpnKey />
              Sing In
            </Button>
          )}
        </ListItem>
      </Hidden>
    </List>
  )
}

export default StudentHeaderLinks

import { useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Meta from '../../../components/Meta/Meta'
import Parallax from '../../../components/Parallax/Parallax'
import UserCard from './components/UserCard'
import styles from './styles/stundetProfileStyles.js'

const useStyles = makeStyles(styles)

const StudentProfileScreen = ({ history }) => {
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<>
			<Meta title={'Profile - Planet Fraternity'} />
			<Parallax image={'/assets/img/education-01.png'} xsmall />
			<Grid container>
				<Grid item xs={11} sm={9} xl={7} className={classes.userCardRoot}>
					{userInfo && <UserCard user={userInfo} />}
				</Grid>
			</Grid>
		</>
	)
}

export default StudentProfileScreen

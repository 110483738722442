export const TOPIC_REGISTER_REQUEST = 'TOPIC_REGISTER_REQUEST'
export const TOPIC_REGISTER_SUCCESS = 'TOPIC_REGISTER_SUCCESS'
export const TOPIC_REGISTER_FAIL = 'TOPIC_REGISTER_FAIL'
export const TOPIC_REGISTER_RESET = 'TOPIC_REGISTER_RESET'

export const TOPIC_LIST_REQUEST = 'TOPIC_LIST_REQUEST'
export const TOPIC_LIST_SUCCESS = 'TOPIC_LIST_SUCCESS'
export const TOPIC_LIST_FAIL = 'TOPIC_LIST_FAIL'
export const TOPIC_LIST_RESET = 'TOPIC_LIST_RESET'

export const TOPIC_DETAILS_REQUEST = 'TOPIC_DETAILS_REQUEST'
export const TOPIC_DETAILS_SUCCESS = 'TOPIC_DETAILS_SUCCESS'
export const TOPIC_DETAILS_FAIL = 'TOPIC_DETAILS_FAIL'
export const TOPIC_DETAILS_RESET = 'TOPIC_DETAILS_RESET'

export const TOPIC_UPDATE_REQUEST = 'TOPIC_UPDATE_REQUEST'
export const TOPIC_UPDATE_SUCCESS = 'TOPIC_UPDATE_SUCCESS'
export const TOPIC_UPDATE_FAIL = 'TOPIC_UPDATE_FAIL'
export const TOPIC_UPDATE_RESET = 'TOPIC_UPDATE_RESET'

export const TOPIC_DELETE_REQUEST = 'TOPIC_DELETE_REQUEST'
export const TOPIC_DELETE_SUCCESS = 'TOPIC_DELETE_SUCCESS'
export const TOPIC_DELETE_FAIL = 'TOPIC_DELETE_FAIL'
export const TOPIC_DELETE_RESET = 'TOPIC_DELETE_RESET'

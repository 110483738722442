import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import Loader from '../../../components/Loader/Loader'
import Message from '../../../components/Message/Message'
import Meta from '../../../components/Meta/Meta'
import CustomModal from '../../../components/Modal/CustomModal'
import TeamsManageHeader from './components/TeamsManageHeader'
import TeamsManageButton from './components/TeamsManageButton'
import TeamsManageRightGroupArea from './components/TeamsManageRightGroupArea'
import TeamsManageAutomateGroupModal from './components/TeamsManageAutomateGroupModal'
import TeamsManageCreateTeamGroupModal from './components/TeamsManageCreateTeamGroupModal'
import TeamsManageCenterParnertsCards from './components/TeamsManageCenterParnertsCards'
import { getTeamPanelById } from '../../../redux/actions/teamsActions'
import { getCentersPartners } from '../../../redux/actions/centerActions'
import {
	getGroupsByTeamId,
	registerTeamGroup,
	automateRegisterTeamGroups,
} from '../../../redux/actions/teamGroupActions'
import {
	TEAM_GROUP_AUTOREGISTER_RESET,
	TEAM_GROUP_DELETE_RESET,
	TEAM_GROUP_REGISTER_RESET,
	TEAM_GROUP_UPDATE_RESET,
} from '../../../redux/constants/teamGroupConstants'
import styles from './styles/teamsManageScreenStyles'

const useStyles = makeStyles(styles)

const TeamsManageScreen = ({ match }) => {
	const panelId = match.params.id
	const dispatch = useDispatch()
	const classes = useStyles()

	const [totalStudents, setTotalStudents] = useState(null)
	const [openAutomateGroup, setOpenAutomateGroup] = useState(false)
	const [openCreateGroup, setOpenCreateGroup] = useState(false)
	const [groupsQty, setGroupsQty] = useState('')
	const [groupName, setGroupName] = useState('')

	// Get Team
	const { loadingTeamDetails, errorTeamDetails, team } = useSelector((state) => state.teamDetails)

	// if Team, get centers Partners in team
	const { loadingPartners, errorPartners, centersPartners } = useSelector((state) => state.centerPartners)

	// if team get groups of its partners
	const { teamgroups } = useSelector((state) => state.teamGroupList)

	// Register one group
	const { successTeamGroupRegister } = useSelector((state) => state.teamGroupRegister)

	// Register multiple groups
	const { successTeamGroupAutoRegister } = useSelector((state) => state.teamGroupAutoRegister)

	// On team group delete, call groups again
	const { successTeamGroupDelete } = useSelector((state) => state.teamGroupDelete)

	// On team group update info, call groups again
	const { successTeamGroupUpdate } = useSelector((state) => state.teamGroupUpdate)

	// Get Team
	useEffect(() => {
		dispatch(getTeamPanelById(panelId))
	}, [dispatch, panelId])

	// if Team, get centers Partners in team
	useEffect(() => {
		if (team) {
			dispatch(getCentersPartners({ partners: team.partners }))
		}
	}, [dispatch, team])

	// if CenterPartners, organize students
	useEffect(() => {
		if (centersPartners) {
			let totalStudents = 0
			centersPartners.forEach((center) => (totalStudents += center.students.length))
			setTotalStudents(totalStudents)
		}
	}, [centersPartners])

	// if team get groups of its partners
	useEffect(() => {
		if (successTeamGroupRegister) {
			setGroupName('')
			setOpenCreateGroup(false)
			dispatch({ type: TEAM_GROUP_REGISTER_RESET })
		} else if (successTeamGroupDelete) {
			dispatch({ type: TEAM_GROUP_DELETE_RESET })
		} else if (successTeamGroupAutoRegister) {
			setGroupsQty('')
			setOpenAutomateGroup(false)
			dispatch({ type: TEAM_GROUP_AUTOREGISTER_RESET })
		} else if (successTeamGroupUpdate) {
			dispatch({ type: TEAM_GROUP_UPDATE_RESET })
		} else {
			dispatch(getGroupsByTeamId(panelId))
		}
	}, [
		dispatch,
		successTeamGroupRegister,
		successTeamGroupAutoRegister,
		successTeamGroupUpdate,
		successTeamGroupDelete,
		panelId,
	])

	const getUserName = (id) => {
		let studentName = {}
		centersPartners.forEach((center) =>
			center.students.forEach((student) => (student._id === id ? (studentName = student) : true)),
		)
		return studentName
	}
	const automateGroupsHandler = () => {
		const teamGroups = {
			teamId: team._id,
			qty: groupsQty,
			partnersEndDate: team.partnersEndDate,
		}
		dispatch(automateRegisterTeamGroups(teamGroups))
	}
	const createGroupHandler = () => {
		const teamGroupInfo = {
			name: groupName,
			teamId: team._id,
			partnersStartDate: team.partnersStartDate,
			partnersEndDate: team.partnersEndDate,
		}
		dispatch(registerTeamGroup(teamGroupInfo))
	}

	return (
		<>
			<Meta title={'Team - Planet Fraternity'} />
			<Grid container spacing={3} className={classes.panelRoot}>
				{loadingTeamDetails ? (
					<Loader />
				) : errorTeamDetails ? (
					<Grid item xs={12}>
						<Message message={<span>{errorTeamDetails}</span>} color='info' />
					</Grid>
				) : (
					team && (
						<>
							{/* Title and Buttons */}
							<Grid item xs={12} md={9}>
								<TeamsManageHeader team={team} totalStudents={totalStudents} />
							</Grid>
							<Grid container justifyContent='flex-end' alignItems='flex-end' item xs={12} md={3}>
								<TeamsManageButton
									teamgroups={teamgroups}
									setOpenAutomateGroup={setOpenAutomateGroup}
									setOpenCreateGroup={setOpenCreateGroup}
								/>
							</Grid>
							{loadingPartners ? (
								<Loader />
							) : errorPartners ? (
								<Message message={<span>{errorPartners}</span>} color='info' />
							) : (
								<>
									<Grid item xs={12} md={8}>
										<Grid container spacing={2}>
											{centersPartners &&
												centersPartners.map((partner, index) => (
													<TeamsManageCenterParnertsCards
														key={index}
														teamgroups={teamgroups}
														partner={partner}
														index={index}
														getUserName={getUserName}
													/>
												))}
										</Grid>
									</Grid>
									{/* Right Groups Area */}
									<Grid item xs={12} md={4} className={classes.partnersCards}>
										<TeamsManageRightGroupArea />
									</Grid>
								</>
							)}

							<CustomModal
								title='Automate Group Creation'
								open={openAutomateGroup}
								setOpen={setOpenAutomateGroup}
								actions={false}
							>
								<TeamsManageAutomateGroupModal
									automateGroupsHandler={automateGroupsHandler}
									setGroupsQty={setGroupsQty}
									groupsQty={groupsQty}
								/>
							</CustomModal>
							<CustomModal
								title='Create Team Group'
								open={openCreateGroup}
								setOpen={setOpenCreateGroup}
								actions={false}
							>
								<TeamsManageCreateTeamGroupModal
									groupName={groupName}
									setGroupName={setGroupName}
									createGroupHandler={createGroupHandler}
								/>
							</CustomModal>
						</>
					)
				)}
			</Grid>
		</>
	)
}

export default TeamsManageScreen

import { makeStyles } from '@material-ui/core'
import { Dashboard } from '@material-ui/icons'
import styles from '../styles/teamsRightSectionStyles'

const useStyles = makeStyles(styles)

const TeamsRightSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.rightSection}>
      <Dashboard className={classes.schoolIcon} />
      <h2>Create a Team Panel to Link Centers</h2>
    </div>
  )
}

export default TeamsRightSection

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import CustomModal from '../../../components/Modal/CustomModal'
import TeamsButton from './components/TeamsButton'
import TeamsRightSection from './components/TeamsRightSection'
import TeamTabs from './components/TeamTabs'
import TeamListCard from './components/TeamListCard'
import TeamRegisterModal from './components/TeamRegisterModal'
import { getTeamPanels } from '../../../redux/actions/teamsActions'
import { TEAM_REGISTER_RESET, TEAM_UPDATE_RESET, TEAM_DELETE_RESET } from '../../../redux/constants/teamConstants'
import styles from './styles/teamsScreenStyles'

const useStyles = makeStyles(styles)

const TeamsScreen = ({ match, history }) => {
  const keyword = match.params.keyword
  const dispatch = useDispatch()
  const classes = useStyles()

  const [matchKeyword, setMatchKeyword] = useState('')
  const [panelArray, setPanelArray] = useState([])
  const [count] = useState(15)
  const [start, setStart] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [initalHasMore, setInitialHasMore] = useState(true)
  const [open, setOpen] = useState(false)
  const [panelDetail, setPanelDetail] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { successTeamRegister } = useSelector((state) => state.teamRegister)
  const { successTeamUpdate, teamUpdated } = useSelector((state) => state.teamUpdate)
  const { successTeamDelete } = useSelector((state) => state.teamDelete)
  const { thatsIt, teams, errorTeamList } = useSelector((state) => state.teamList)

  useEffect(() => {
    if (userInfo) {
      if (successTeamDelete || successTeamRegister || successTeamUpdate) {
        if (successTeamDelete) {
          dispatch({ type: TEAM_DELETE_RESET })
          setPanelDetail(null)
        } else if (successTeamRegister) {
          dispatch({ type: TEAM_REGISTER_RESET })
        } else if (successTeamUpdate) {
          dispatch({ type: TEAM_UPDATE_RESET })
          setPanelDetail(teamUpdated)
        }
        setPanelArray([])
        setStart(1)
        setHasMore(true)
        setInitialHasMore(true)
        return true
      } else {
        if (keyword !== undefined) {
          if (keyword !== matchKeyword) {
            setPanelArray([])
            setInitialHasMore(true)
            setMatchKeyword(keyword)
            setStart(1)
          } else {
            if (initalHasMore) {
              setInitialHasMore(false)
              dispatch(getTeamPanels(keyword, count, 0))
            } else if (teams && teams.length > 0) {
              setPanelArray((prev) => [...prev, ...teams])
            }
          }
        } else {
          if (initalHasMore) {
            dispatch(getTeamPanels(keyword, count, 0))
            setInitialHasMore(false)
          } else if (teams && teams.length > 0) {
            setPanelArray((prev) => [...prev, ...teams])
          }
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [
    userInfo,
    history,
    dispatch,
    keyword,
    count,
    teams,
    teamUpdated,
    initalHasMore,
    matchKeyword,
    successTeamDelete,
    successTeamRegister,
    successTeamUpdate,
  ])
  useEffect(() => {
    if (thatsIt || errorTeamList === 'Teams not found.') {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [thatsIt, errorTeamList])
  useEffect(() => {
    return () => {
      setPanelDetail(null)
    }
  }, [dispatch])

  const fetchData = () => {
    if (hasMore) {
      setStart(start + 1)
      dispatch(getTeamPanels(keyword, count, start))
    }
  }

  return (
    <div className={classes.root}>
      <Meta title={'Teams List - Planet Fraternity'} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h1>Teams List</h1>
        </Grid>
        <Grid item xs={12} md={9} className={classes.contentItemsEnd}>
          <TeamsButton keyword={keyword} />
        </Grid>

        <Grid item xs={12} md={4}>
          {panelArray.length < 1 && start === 1 ? (
            <div className={classes.infiniteScroll}>
              <h4 className={classes.lastLine}>Thats it..</h4>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={panelArray.length}
              next={fetchData}
              hasMore={hasMore}
              loader={<Loader />}
              height={'60vh'}
              className={classes.infiniteScroll}
              endMessage={
                <p className={classes.lastLine}>
                  <b>This is the last line.</b>
                </p>
              }
            >
              {panelArray.map((item) => (
                <TeamListCard key={item._id} panel={item} setPanelDetail={setPanelDetail} />
              ))}
            </InfiniteScroll>
          )}
        </Grid>
        <Grid item xs={12} md={8} className={classes.teamTabs}>
          {panelDetail !== null ? <TeamTabs panel={panelDetail} /> : <TeamsRightSection />}
        </Grid>
      </Grid>
      <CustomModal title='Create Team' open={open} setOpen={setOpen} actions={false}>
        <TeamRegisterModal setOpen={setOpen} setPanelDetail={setPanelDetail} />
      </CustomModal>
    </div>
  )
}

export default TeamsScreen

const styles = {
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#040404',
    display: 'flex',
    flexDirection: 'column',
  },
  screenRoot: {
    flexGrow: '1',
    padding: 10,
    display: 'flex',
  },
  screen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  screenShared: {
    width: 'calc(100% - 360px)',
    height: '100%',
    paddingRight: '10px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  ownVideoAlone: {
    maxWidth: '1200px',
    width: '100%',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '5px',
    '& video': {
      objectFit: 'cover',
      position: 'absolute',
    },
  },
  ownVideo: {
    width: 'auto',
    height: '115px',
    margin: 'auto',
    overflow: 'hidden',
    borderRadius: '5px',
    position: 'absolute',
    right: 10,
    zIndex: 10,
  },
  peersRoot: {
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxHeight: '100%',
  },
  peersRootFull: {
    width: '100%',
    height: '100%',
  },
  peersRootSide: {
    width: 'calc(100vw - 400px)',
  },
  peerScreen: {
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      margin: '3px',
      backgroundColor: '#3e3939',
      overflow: 'hidden',
      position: 'relative',
      '& span': {
        position: 'absolute',
        bottom: 5,
        color: '#e5e5e5',
        padding: '3px 10px',
        borderRadius: '10px',
        backgroundColor: '#202124',
      },
      '& video': {
        width: '100%',
        height: '100%',
      },
    },
  },
  peerScreenOne: {
    '& div': {
      width: '70%',
      maxHeight: '80vh',
    },
  },
  peerScreenTwo: {
    '& div': {
      width: 'calc(50% - 40px)',
      height: '400px',
    },
  },
  peerScreenFour: {
    '& div': {
      maxWidth: '580px',
      width: 'calc(50% - 100px)',
      height: '300px',
    },
  },
  peerScreenSix: {
    '& div': {
      width: 'calc((100% - 20px) / 3)',
      minHeight: '250px',
    },
  },
  peerScreenEight: {
    '& div': {
      width: 'calc((100% - 80px) / 4)',
      minHeight: '240px',
    },
  },
  peerScreenTen: {
    '& div': {
      width: 'calc((100% - 100px) / 5)',
      height: '200px',
    },
  },
  sidebarRoot: {
    width: '360px',
    height: '100%',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  sidebarChatRoot: {
    height: 'calc(100vh - 120px)',
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sidebarMsgArea: {
    padding: '5px',
    flexGrow: '1',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
      borderRadius: '10px',
    },
  },
  sidebarMsgBox: {
    width: '100%',
  },
  sidebarChatInput: {
    zIndex: 1,
    '& input[type=text]': {
      width: '100%',
      padding: '10px 13px',
      boxShadow: '0 1px 4px 0 rgb(0 0 0 / 19%)',
      borderRadius: '5px',
      border: 'none',
      color: '#fff',
      backgroundColor: '#3e3939',
      '&::placeholder': {
        color: '#fff !important',
      },
    },
  },
  peersBubble: {
    width: 'auto',
    maxWidth: '70%',
    marginBottom: '10px',
    wordBreak: 'break-word',
    padding: '7px',
    borderRadius: '5px',
    backgroundColor: '#f3f3f3',
    boxShadow: '0 1px 1px 0 rgb(0 0 0 / 19%)',
    float: 'left',
  },
  myBubble: {
    width: 'auto',
    maxWidth: '70%',
    wordBreak: 'break-word',
    marginBottom: '10px',
    padding: '7px',
    borderRadius: '5px',
    backgroundColor: '#f3f3f3',
    boxShadow: '0 1px 1px 0 rgb(0 0 0 / 19%)',
    float: 'right',
  },
  peerName: {
    color: '#1fcc1f',
    fontWeight: '600',
  },
  sidebarTitle: {
    fontSize: '18px',
  },
  peerActive: {
    backgroundColor: '#fbfcff',
    color: '#5f5f5f',
    borderRadius: '30px',
    padding: '10px',
    marginBottom: '5px',
    '& img': {
      width: '35px',
      height: '35px',
      borderRadius: '50%',
      marginRight: 10,
    },
  },
  footer: {
    padding: 10,
    width: '100%',
  },
  footerSection: {
    textAlign: 'center',
  },
  btnControls: {
    margin: '5px',
    backgroundColor: '#3e3939',
    '& svg': {
      color: '#fff',
    },
  },
  notSupportedBrowser: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    '& a': {
      color: 'blue',
    },
  },
  '@media screen and (max-width: 1200px)': {
    peerScreenSix: {
      height: '250px !important',
    },
    peerScreenEight: {
      width: 'calc((100% - 30px) / 3)',
      height: '240px !important',
    },
    peerScreenTen: {
      width: 'calc((100% - 30px) / 3)',
      height: '170px !important',
    },
  },
  '@media screen and (max-width: 800px)': {
    screenShared: {
      display: 'none',
    },
    sidebarRoot: {
      width: '100%',
      padding: '10px',
    },
    peerScreenTwo: {
      width: 'calc(50% - 10px)',
    },
    peerScreenFour: {
      width: 'calc(50% - 40px)',
    },
  },
  '@media screen and (max-width: 600px)': {
    screenRoot: {
      padding: 3,
    },
    sidebarChatRoot: {
      height: 'calc(100vh - 220px)',
    },
    peersRootFull: {
      maxHeight: '80vh',
    },
    peerScreen: {
      '& div': {
        '& span': {
          display: 'none',
        },
      },
    },
    peerScreenOne: {
      '& div': {
        width: '100%',
        height: '100%',
      },
    },
    peerScreenFour: {
      '& div': {
        width: 'calc(50% - 10px)',
        height: '240px',
      },
    },
    footer: {
      padding: '7px 7px 65px',
      '& svg': {
        fontSize: 19,
      },
    },
  },
}

export default styles

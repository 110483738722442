const styles = {
    inputItem: {
        margin: 0,
        paddingTop: '20px',
        '& input': {
            padding: '5px 0',
        },
        '& .MuiSelect-root': {
            padding: '5px 0',
        },
    },
    formControl: {
        marginTop: '11px',
        minWidth: 120,
        width: '100%',
        textAlign: 'left',
    },
    selectLabel: {
        color: '#AAAAAA !important',
        fontWeight: '400',
        fontSize: '14px',
        letterSpacing: 'unset',
    },
    selectInput: {
        marginTop: '6px !important',
        '&:before': {
            borderBottom: '1px solid rgb(210 210 210)',
        },
    },
    btnMarginTop: {
        marginTop: '35px',
    },
}

export default styles

import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styles from '../styles/parallaxContactStyles'

const useStyles = makeStyles(styles)

const ParallaxContact = () => {
	const classes = useStyles()
	const { t } = useTranslation('global')

	return (
		<Grid container className={classes.parallaxContact}>
			<Grid item xs={12} className={classes.parallaxContent}>
				<h3>{t('layout.t_contact-us-more-info')}</h3>
			</Grid>
		</Grid>
	)
}

export default ParallaxContact

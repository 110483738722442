import MeetRoomScreen from '../screens/student-panel/MeetRoomScreen/MeetRoomScreen'
import ClassRoomScreen from '../screens/student-panel/MeetRoomScreen/ClassRoomScreen'

const meetPanelRoutes = [
	{
		path: '/meetroom/:id',
		exact: true,
		comp: MeetRoomScreen,
	},
	{
		path: '/classroom/:id',
		exact: true,
		comp: ClassRoomScreen,
	},
]

export default meetPanelRoutes

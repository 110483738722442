import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, Tooltip } from '@material-ui/core'
import Loader from '../../../../components/Loader/Loader'
import Message from '../../../../components/Message/Message'
import Button from '../../../../components/Buttons/Button'
import CustomInput from '../../../../components/CustomInput/CustomInput'
import { registerStudent } from '../../../../redux/actions/studentActions'
import { STUDENT_REGISTER_RESET } from '../../../../redux/constants/studentConstants'
import styles from '../styles/studentRegisterFormStyles'
import { Info } from '@material-ui/icons'

const useStyles = makeStyles(styles)

const StudentRegisterForm = ({ center, groups }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [name, setName] = useState('')
	const [username, setUsername] = useState('')
	const [usernameError, setUsernameError] = useState('')
	const [email, setEmail] = useState('')
	const [group, setGroup] = useState('')
	const [confirmationSent, setConfirmationSent] = useState(false)
	const [confirmationError, setConfirmationError] = useState(false)

	const studentRegister = useSelector((state) => state.studentRegister)
	const { loadingStudentRegister, errorStudentRegister, successStudentRegister } = studentRegister

	useEffect(() => {
		if (username.endsWith('planetfraternity') || username.endsWith('.com') || username.search('@') !== -1) {
			setUsernameError('Do not use @planetfraternity.com')
		} else {
			setUsernameError('')
		}
	}, [username])

	const submitFormHandler = (e) => {
		e.preventDefault()
		const createStudent = {
			name,
			username,
			password: '123456',
			country: center.country,
			groupData: group,
		}
		dispatch(registerStudent(createStudent))
	}

	const createdSuccessHandler = () => {
		dispatch({ type: STUDENT_REGISTER_RESET })
		setName('')
		setUsername('')
		setEmail('')
		setGroup('')
		setConfirmationSent(false)
		setConfirmationError(false)
	}

	return (
		<Grid container>
			{successStudentRegister ? (
				<>
					<Grid item xs={12} className={classes.textCenter}>
						<h2>¡Great!</h2>
						<p>Student created successfully.</p>
						{confirmationSent && (
							<p>
								Confirmation email sent to <strong>{email}</strong>
							</p>
						)}
						{confirmationError && (
							<p>
								User created success but the system couldn't sent the email confirmation.
								<strong>{email}</strong>
							</p>
						)}
					</Grid>
					<Grid item xs={12}>
						<Button color='primary' block onClick={createdSuccessHandler}>
							OK
						</Button>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<h2 className={classes.formTitle}>
						New Student{' '}
						<Tooltip title='The username should be Eg. centername1a-1, remember that we will use it to create the planetfraternity google account Eg. centername1a-1@planetfraternity.com'>
							<Info />
						</Tooltip>{' '}
					</h2>
					<form onSubmit={submitFormHandler} autoComplete='off'>
						<CustomInput
							labelText='Name and Lastname'
							id='newname'
							formControlProps={{
								fullWidth: true,
								className: classes.formControl,
							}}
							inputProps={{
								type: 'text',
								value: name,
								placeholder: 'Enter complete name',
								onChange: (e) => setName(e.target.value),
								required: true,
							}}
						/>
						<CustomInput
							labelText={'Username' + ' ' + usernameError}
							id='newusername'
							error={usernameError.length > 1}
							formControlProps={{
								fullWidth: true,
								className: classes.formControl,
							}}
							inputProps={{
								type: 'text',
								value: username,
								placeholder: 'Eg. centername1a-1 without @planetfraternity.com',
								onChange: (e) => setUsername(e.target.value),
								required: true,
							}}
						/>
						<FormControl className={classes.formControlSelect}>
							<InputLabel className={classes.selectLabel}>Group Center</InputLabel>
							<Select
								labelId='group'
								id='group'
								value={group || ''}
								onChange={(e) => setGroup(e.target.value)}
								inputProps={{ required: true }}
								className={classes.selectInput}
							>
								<MenuItem value='' disabled>
									Select a Group
								</MenuItem>
								{groups &&
									groups.map((group, index) => (
										<MenuItem key={index} value={group ? group : ''}>
											{group.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<Grid container>
							<Grid item xs={12}>
								<p className={classes.defPassMsg}>The default password is going to be 1 through 8</p>
							</Grid>
							<Grid item xs={12}>
								<Button
									disabled={loadingStudentRegister || usernameError.length > 1}
									type='submit'
									color='primary'
									block
								>
									{loadingStudentRegister ? <Loader size={'sm'} /> : 'Submit'}
								</Button>
							</Grid>
						</Grid>
						{errorStudentRegister && <Message message={<span>{errorStudentRegister}</span>} color='info' />}
					</form>
				</Grid>
			)}
		</Grid>
	)
}

export default StudentRegisterForm

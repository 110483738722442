import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { QuestionAnswer } from '@material-ui/icons'
import CustomTabs from '../../../components/CustomTabs/CustomTabs'
import Button from '../../../components/Buttons/Button'
import Message from '../../../components/Message/Message'
import Loader from '../../../components/Loader/Loader'
import Meta from '../../../components/Meta/Meta'
import TeacherSurveysQuestions from './components/TeacherSurveysQuestions'
import { answerSurvey } from '../../../redux/actions/surveyActions'
import { activeSurvey } from '../../../redux/actions/activeSurveyActions'
import { ACTIVE_SURVEY_RESET } from '../../../redux/constants/activeSurveyConstants'
import { SURVEY_ANSWER_RESET } from '../../../redux/constants/surveyConstants'
import styles from './styles/teacherSurveysScreenStyles'

const useStyles = makeStyles(styles)

const TeacherSurveysScreen = ({ match, history }) => {
  const { params } = match

  const classes = useStyles()
  const dispatch = useDispatch()

  const { successActiveSurvey, activeSurveys } = useSelector((state) => state.activeSurvey)
  const { loadingSurveyAnswer, successSurveyAnswer, errorSurveyAnswer } = useSelector((state) => state.surveyAnswer)

  const [surveys, setSurveys] = useState([])
  const [answers, setAnswers] = useState([])
  const [questions, setQuestions] = useState([])
  const [survey, setSurvey] = useState(null)
  const [succesResponse, setSuccesResponse] = useState(false)
  const [checked, setChecked] = useState([])

  useEffect(() => {
    dispatch(activeSurvey())
  }, [dispatch])

  useEffect(() => {
    if (successActiveSurvey) {
      setSurveys(activeSurveys)
    }
  }, [successActiveSurvey, activeSurveys, history])

  useEffect(() => {
    if (survey) {
      setQuestions(survey.questions)
    }
  }, [survey])

  useEffect(() => {
    const data = surveys.find((survey) => survey._id === params.id)
    setSurvey(data)
  }, [surveys, setSurvey, params])

  useEffect(() => {
    if (successSurveyAnswer) {
      setSuccesResponse(true)
      dispatch({ type: SURVEY_ANSWER_RESET })
      dispatch({ type: ACTIVE_SURVEY_RESET })
      setTimeout(() => {
        setSuccesResponse(false)
        history.push('/teacher/profile')
      }, 2000)
    }
  }, [successSurveyAnswer, dispatch, history])

  const submitHandler = (e) => {
    e.preventDefault()
    if (answers) {
      dispatch(answerSurvey({ _id: survey._id, answers }))
    }
  }

  const handleAnswer = (e, question, value) => {
    const arr = questions.map((el) => {
      if (el._id === question._id) {
        if (question.type === 'paragraph') {
          el.answer = e.target.value
        } else if (question.type === 'selectRadio') {
          el.radioAnwser = e.target.value
        } else if (question.type === 'selectCheckbox') {
          let data
          if (e.target.checked) {
            data = {
              _id: question._id,
              answer: value.question,
            }
            setChecked([...checked, data])
            checked.push({ _id: question._id, answer: value.question })
          } else {
            const data = checked.filter((c) => c.answer !== value.answer)
            checked.pop({ _id: question._id, answer: value.answer })
            setChecked(data)
          }
          const checkedFilter = checked.filter((select) => select._id === question._id)
          const response = checkedFilter.map((select) => {
            return {
              answer: select.answer,
            }
          })
          el.answersCheckbox = response
        }
      }
      return el
    })
    setAnswers(arr)
  }

  return (
    <>
      <Meta title={'Teacher Surveys - Planet Fraternity'} />
      <Grid container>
        <Grid xs={10} sm={10} md={10} item className={classes.root}>
          <CustomTabs
            headerColor='success'
            className={classes.customTabsRoot}
            tabs={[
              {
                tabName: `${survey?.title}`,
                tabIcon: QuestionAnswer,
                tabContent: (
                  <Grid item className={classes.sectionRoot}>
                    <form onSubmit={submitHandler} autoComplete='off'>
                      <h1>Answer the survey</h1>
                      <TeacherSurveysQuestions checked={checked} questions={questions} handleAnswer={handleAnswer} />
                      <Button
                        type='submit'
                        block
                        color={succesResponse ? 'success' : 'primary'}
                        disabled={succesResponse && true}
                      >
                        {loadingSurveyAnswer ? (
                          <Loader size={'sm'} />
                        ) : succesResponse ? (
                          'Survey answered successfully'
                        ) : (
                          'SUBMIT'
                        )}
                      </Button>
                      {errorSurveyAnswer && <Message message={<span>{errorSurveyAnswer}</span>} color='info' />}
                    </form>
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TeacherSurveysScreen

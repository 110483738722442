import * as types from '../constants/miniBlogConstans'

export const miniBlogRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.MINIBLOG_REGISTER_REQUEST:
			return { loadingMiniBlogRegister: true }
		case types.MINIBLOG_REGISTER_SUCCESS:
			return { loadingMiniBlogRegister: false, successMiniBlogRegister: true, miniBlog: action.payload }
		case types.MINIBLOG_REGISTER_FAIL:
			return { loadingMiniBlogRegister: false, errorMiniBlogRegister: action.payload }
		case types.MINIBLOG_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const miniBlogListReducer = (state = { miniBlogs: [] }, action) => {
	switch (action.type) {
		case types.MINIBLOG_LIST_REQUEST:
			return { loadingMiniBlogList: true }
		case types.MINIBLOG_LIST_SUCCESS:
			return {
				loadingMiniBlogList: false,
				successMiniBlogList: true,
				thatsIt: action.payload.length === 0 ? true : false,
				miniBlogs: action.payload,
			}
		case types.MINIBLOG_LIST_FAIL:
			return { loadingMiniBlogList: false, errorMiniBlogList: action.payload }
		case types.MINIBLOG_LIST_RESET:
			return { miniBlogs: [] }
		default:
			return state
	}
}

export const activeMiniBlogListReducer = (state = { miniBlogs: [] }, action) => {
	switch (action.type) {
		case types.MINIBLOG_ACTIVE_LIST_REQUEST:
			return { loadingActiveMiniBlogList: true }
		case types.MINIBLOG_ACTIVE_LIST_SUCCESS:
			return {
				loadingActiveMiniBlogList: false,
				successActiveMiniBlogList: true,
				thatsIt: action.payload.length === 0 ? true : false,
				activeMiniBlogs: action.payload,
			}
		case types.MINIBLOG_ACTIVE_LIST_FAIL:
			return { loadingActiveMiniBlogList: false, errorActiveMiniBlogList: action.payload }
		case types.MINIBLOG_ACTIVE_LIST_RESET:
			return { activeMiniBlogs: [] }
		default:
			return state
	}
}

export const miniBlogDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.MINIBLOG_DELETE_REQUEST:
			return { loadingMiniBlogDelete: true }
		case types.MINIBLOG_DELETE_SUCCESS:
			return { loadingMiniBlogDelete: false, successMiniBlogDelete: true }
		case types.MINIBLOG_DELETE_FAIL:
			return {
				loadingMiniBlogDelete: false,
				errorMiniBlogDelete: action.payload,
			}
		case types.MINIBLOG_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const miniBlogUpdateReducer = (state = { miniBlogUpdated: {} }, action) => {
	switch (action.type) {
		case types.MINIBLOG_UPDATE_REQUEST:
			return { loadingMiniBlogUpdate: true }
		case types.MINIBLOG_UPDATE_SUCCESS:
			return {
				loadingMiniBlogUpdate: false,
				successMiniBlogUpdate: true,
				miniBlogUpdated: action.payload,
			}
		case types.MINIBLOG_UPDATE_FAIL:
			return {
				loadingMiniBlogUpdate: false,
				errorMiniBlogUpdate: action.payload,
			}
		case types.MINIBLOG_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

import { Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/skyHandsStyles'

const useStyles = makeStyles(styles)

const SkyHands = () => {
	const classes = useStyles()

	return (
		<div className={classes.bgMove}>
			<div className={classes.bgHands}></div>
			<Grid container className={classes.root}>
				<Grid item xs={12} className={classes.titleRoot}>
					<h1>Planet Fraternity</h1>
					<h2>
						Connecting Minds, Uniting Hearts: The platform that breaks down borders to bring the world's children and
						youth together
					</h2>
				</Grid>
			</Grid>
		</div>
	)
}

export default SkyHands

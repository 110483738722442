const styles = {
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    formControl: {
        marginTop: '15px',
        minWidth: 120,
        width: '100%',
    },
    magazineCol: {
        display: 'flex',
        justifyContent: 'center',
    },
    magazineCover: {
        width: '100%',
        maxWidth: '300px',
        boxShadow:
            '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
    },
    selectLabel: {
        color: '#AAAAAA !important',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '1.42857',
        letterSpacing: 'unset',
    },
    textField: {
        marginTop: '15px',
        width: '100%',
    },
    resize: {
        fontSize: 14,
    },
    coverlabel: {
        cursor: 'pointer',
        color: '#aaaaaa !important',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1.42857',
        letterSpacing: 'unset',
        margin: '40px 0',
        '& span': {
            marginLeft: '10px',
            '& svg': {
                color: '#3f51b5',
                fontSize: '40px',
            },
        },
    },
}

export default styles

import axios from 'axios'
import {
	GROUP_REGISTER_REQUEST,
	GROUP_REGISTER_SUCCESS,
	GROUP_REGISTER_FAIL,
	GROUP_LIST_BY_CENTER_REQUEST,
	GROUP_LIST_BY_CENTER_FAIL,
	GROUP_LIST_BY_CENTER_SUCCESS,
	GROUP_UPDATE_REQUEST,
	GROUP_UPDATE_SUCCESS,
	GROUP_UPDATE_FAIL,
	GROUP_DETAILS_REQUEST,
	GROUP_DETAILS_SUCCESS,
	GROUP_DETAILS_FAIL,
	GROUP_DELETE_REQUEST,
	GROUP_DELETE_SUCCESS,
	GROUP_DELETE_FAIL,
	GROUP_TEACHER_DELETE_REQUEST,
	GROUP_TEACHER_DELETE_SUCCESS,
	GROUP_TEACHER_DELETE_FAIL,
} from '../constants/groupConstants'

export const registerGroup = (group) => async (dispatch, getState) => {
	try {
		dispatch({ type: GROUP_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/groups', group, config)

		dispatch({ type: GROUP_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: GROUP_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getGroupsByCenter = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: GROUP_LIST_BY_CENTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/groups/center/${id}`, config)

		dispatch({ type: GROUP_LIST_BY_CENTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: GROUP_LIST_BY_CENTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getGroupById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: GROUP_DETAILS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/groups/${id}`, config)

		dispatch({ type: GROUP_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: GROUP_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const upadteGroupInfo = (group) => async (dispatch, getState) => {
	try {
		dispatch({ type: GROUP_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/groups/${group._id}`, group, config)

		dispatch({ type: GROUP_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: GROUP_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteTeacherGroup = (id, teacherId) => async (dispatch, getState) => {
	try {
		dispatch({ type: GROUP_TEACHER_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/groups/${id}/teacher/${teacherId}`, config)

		dispatch({ type: GROUP_TEACHER_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: GROUP_TEACHER_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteGroup = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: GROUP_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(`/api/groups/${id}`, config)

		dispatch({ type: GROUP_DELETE_SUCCESS })
	} catch (error) {
		dispatch({
			type: GROUP_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

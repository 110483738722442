import axios from 'axios'
import {
  TOPIC_REGISTER_REQUEST,
  TOPIC_REGISTER_SUCCESS,
  TOPIC_REGISTER_FAIL,
  TOPIC_LIST_REQUEST,
  TOPIC_LIST_SUCCESS,
  TOPIC_LIST_FAIL,
  TOPIC_DETAILS_REQUEST,
  TOPIC_DETAILS_SUCCESS,
  TOPIC_DETAILS_FAIL,
  TOPIC_UPDATE_REQUEST,
  TOPIC_UPDATE_SUCCESS,
  TOPIC_UPDATE_FAIL,
  TOPIC_DELETE_REQUEST,
  TOPIC_DELETE_SUCCESS,
  TOPIC_DELETE_FAIL,
} from '../constants/topicConstants'

export const registerTopicPanel = (panelInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPIC_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const formData = new FormData()
    formData.append('name', panelInfo.name)
    formData.append('TopicImage', panelInfo.imageUrlModal)
    formData.append('order', panelInfo.order)

    const { data } = await axios.post('/api/topics', formData, config)

    dispatch({ type: TOPIC_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TOPIC_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getTopics = (count, start) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPIC_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/topics?count=${count}&start=${start}`, config)

    dispatch({ type: TOPIC_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TOPIC_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getTopicPanelById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPIC_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/topics/${id}`, config)

    dispatch({ type: TOPIC_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TOPIC_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const topicPanelUpdateInfo = (panel) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPIC_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const formData = new FormData()
    formData.append('name', panel.name)
    formData.append('TopicImage', panel.imageUrl)
    formData.append('order', panel.order)

    const { data } = await axios.put(`/api/topics/${panel._id}`, formData, config)

    dispatch({ type: TOPIC_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TOPIC_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteTopicPanel = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPIC_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/topics/${id}`, config)

    dispatch({ type: TOPIC_DELETE_SUCCESS })
  } catch (error) {
    dispatch({
      type: TOPIC_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

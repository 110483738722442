import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import Meta from '../../../components/Meta/Meta'
import Loader from '../../../components/Loader/Loader'
import CenterCard from './components/CenterCard'
import CenterListCard from './components/CenterListCard'
import PendingSubsRightSection from './components/PendingSubsRightSection'
import PendingSubsButton from './components/PendingSubsButton'
import { getCenters } from '../../../redux/actions/centerActions'
import {
	CENTER_DETAILS_RESET,
	CENTER_REGISTER_RESET,
	CENTER_UPDATE_RESET,
} from '../../../redux/constants/centerConstants'
import styles from './styles/pendingSubsListScreenStyles'

const useStyles = makeStyles(styles)

const PendingSubsListScreen = ({ match, history }) => {
	const keyword = match.params.keyword
	const dispatch = useDispatch()
	const classes = useStyles()

	const [matchKeyword, setMatchKeyword] = useState('')
	const [centerArray, setCenterArray] = useState([])
	const [count] = useState(15)
	const [start, setStart] = useState(1)
	const [hasMore, setHasMore] = useState(true)
	const [initalHasMore, setInitialHasMore] = useState(true)
	const [centerDetail, setCenterDetail] = useState(null)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { successCenterRegister } = useSelector((state) => state.centerRegister)
	const { successCenterUpdate, centerUpdated } = useSelector((state) => state.centerUpdate)
	const { thatsIt, centers, error } = useSelector((state) => state.centerList)

	useEffect(() => {
		if (successCenterUpdate) {
			setCenterDetail(centerUpdated)
		}
	}, [successCenterUpdate, centerUpdated])
	useEffect(() => {
		if (userInfo) {
			if (successCenterRegister || successCenterUpdate) {
				if (successCenterRegister) {
					dispatch({ type: CENTER_REGISTER_RESET })
					setCenterDetail(null)
				} else if (successCenterUpdate) {
					dispatch({ type: CENTER_UPDATE_RESET })
				}
				setCenterArray([])
				setStart(1)
				setHasMore(true)
				setInitialHasMore(true)
				return true
			} else {
				if (keyword !== undefined) {
					if (keyword !== matchKeyword) {
						setCenterArray([])
						setInitialHasMore(true)
						setMatchKeyword(keyword)
						setStart(1)
					} else {
						if (initalHasMore) {
							setInitialHasMore(false)
							dispatch(getCenters(keyword, count, 0, 'pending'))
						} else if (centers && centers.length > 0) {
							setCenterArray((prev) => [...prev, ...centers])
						}
					}
				} else {
					if (initalHasMore) {
						dispatch(getCenters(keyword, count, 0, 'pending'))
						setInitialHasMore(false)
					} else if (centers && centers.length > 0) {
						setCenterArray((prev) => [...prev, ...centers])
					}
				}
			}
		} else {
			history.push('/auth/login')
		}
	}, [
		userInfo,
		history,
		dispatch,
		keyword,
		count,
		centers,
		initalHasMore,
		matchKeyword,
		successCenterRegister,
		successCenterUpdate,
	])
	useEffect(() => {
		if (thatsIt || error === 'Centers not found.') {
			setHasMore(false)
		} else {
			setHasMore(true)
		}
	}, [thatsIt, error])
	useEffect(() => {
		return () => {
			dispatch({ type: CENTER_DETAILS_RESET })
		}
	}, [dispatch])

	const fetchData = () => {
		if (hasMore) {
			setStart(start + 1)
			dispatch(getCenters(keyword, count, start, 'pending'))
		}
	}

	return (
		<div className={classes.root}>
			<Meta title={'Pending Subscriptions - Planet Fraternity'} />
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<h1>Pending Subscriptions List</h1>
				</Grid>
				<Grid item xs={12} md={8} className={classes.contentItemsEnd}>
					<PendingSubsButton keyword={keyword} />
				</Grid>
				<Grid item xs={12} md={4}>
					<InfiniteScroll
						dataLength={centerArray.length}
						next={fetchData}
						hasMore={hasMore}
						loader={<Loader />}
						height={'60vh'}
						className={classes.infiniteScroll}
						endMessage={
							<p className={classes.contentLine}>
								<b>Thats it.</b>
							</p>
						}
					>
						{centerArray.map((center) => (
							<CenterListCard key={center._id} center={center} setCenterDetail={setCenterDetail} />
						))}
					</InfiniteScroll>
				</Grid>
				<Grid item xs={12} md={8}>
					{centerDetail !== null ? (
						<CenterCard center={centerDetail} setCenterDetail={setCenterDetail} />
					) : (
						<PendingSubsRightSection />
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default PendingSubsListScreen

const styles = {
  infoTopic: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    '& h4': {
      color: '#483d3d',
    },
    '& h2': {
      marginTop: '5px',
      fontSize: '18px',
      color: '#79a94d',
    },
    '& img': {
      objectFit: 'contain',
      width: '90px',
      height: '80px',
    },
  },
  partnersCards: {
    margin: '50px auto',
  },
  centerLogo: {
    width: '70px',
    height: '70px',
    '& img': {
      width: '100%',
    },
  },
  cardTitle: {
    marginBottom: 15,
    textAlign: 'center',
  },
  countryFlagProfile: {
    width: '35px',
    borderRadius: '5px',
  },
}

export default styles

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
	Accordion,
	AccordionDetails,
	Card,
	FormControlLabel,
	Grid,
	Switch,
	Typography,
	makeStyles,
} from '@material-ui/core'
import Loader from '../Loader/Loader'
import { deleteTalent, updateTalent } from '../../redux/actions/shareTalentsActions'
import Button from '../../components/Buttons/Button'
import CustomModal from '../Modal/CustomModal'
import styles from './styles/sharedTalentComponentStyles'

const useStyles = makeStyles(styles)

const SharedTalentComponent = ({ previewMode, updateSelection, updateState, talent }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation('global')
	const {
		loadingTalentUpdate,
		errorupdateTalents,
		success: successUpdated,
	} = useSelector((state) => state.talentUpdate)
	const { loadingTalentDelete, errordeleteTalents } = useSelector((state) => state.talentDelete)

	const [activePost, setActivePost] = useState(undefined)
	const [showMore, setShowMore] = useState(false)
	const [open, setOpen] = useState(false)
	const [deletePost, setDeletePost] = useState(false)

	useEffect(() => {
		setActivePost(undefined)
	}, [talent])

	const afterDispatch = useCallback(() => {
		if (!errorupdateTalents || !errordeleteTalents) {
			setOpen(false)
			updateSelection(null)
		}
	}, [errorupdateTalents, errordeleteTalents])

	const togglePost = async () => {
		const response = await dispatch(updateTalent(talent._id, { active: activePost }))

		if (response.status === 201) {
			updateState((prev) => {
				const copy = [...prev]
				copy.splice(
					copy.findIndex((d) => d._id === talent._id),
					1,
					{ ...talent, active: activePost },
				)
				return copy
			})
		}
	}

	const dispatchDeletePost = async () => {
		setDeletePost(true)
		const response = await dispatch(deleteTalent(talent._id))
		setDeletePost(false)
		afterDispatch(response)
	}

	const handledeleteTalent = () => {
		setOpen(true)
	}

	if (!previewMode) {
		return (
			<>
				{talent?.video && (
					<video
						src={`${axios.defaults.baseURL}/${talent?.video}`}
						alt={'shared-talent-video'}
						className={classes.publicVideo}
						controls
						autoPlay
					/>
				)}
				<div className={classes.descRoot}>
					<h4>{talent?.title}</h4>
					<Accordion expanded={showMore} onChange={() => {}} className={classes.accordion}>
						<AccordionDetails>
							<p className={classes.text}>
								{talent?.body?.length > 200 ? (
									<>
										{showMore ? talent?.body : talent?.body.substring(0, 200)}
										{showMore ? '' : '...'}
										<b className={classes.showMoreButton} onClick={() => setShowMore(!showMore)}>
											{t(showMore ? 'countries.countryPosts.showLess' : 'countries.countryPosts.showMore')}
										</b>
									</>
								) : (
									talent?.body
								)}
							</p>
						</AccordionDetails>
					</Accordion>
				</div>
			</>
		)
	}

	return (
		<>
			<CustomModal
				title={t('countries.countryPosts.sureDelete')}
				open={open}
				setOpen={(e) => setOpen(e)}
				actions={true}
				acceptHandler={dispatchDeletePost}
				loadingAccept={loadingTalentDelete}
			/>
			<Card elevation={2} className={classes.root}>
				<Typography className={classes.title}>{talent?.title}</Typography>
				<Accordion expanded={showMore} onChange={() => {}} className={classes.accordion}>
					<AccordionDetails>
						<Typography className={classes.text}>
							{talent?.body?.length > 200 ? (
								<>
									{showMore ? talent?.body : talent?.body.substring(0, 200)}
									{showMore ? '' : '...'}
									<b className={classes.showMoreButton} onClick={() => setShowMore(!showMore)}>
										{t(showMore ? 'countries.countryPosts.showLess' : 'countries.countryPosts.showMore')}
									</b>
								</>
							) : (
								talent?.body
							)}
						</Typography>
					</AccordionDetails>
				</Accordion>

				{talent?.video && (
					<video
						src={`${axios.defaults.baseURL}/${talent?.video}`}
						alt={'shared-talent-video'}
						className={classes.carouselImages}
						controls
					/>
				)}
				{previewMode && (
					<Grid container xs={12} spacing={2} justifyContent='space-around' className={classes.previewOptions}>
						<Grid item xs={12}>
							<FormControlLabel
								className={classes.formControlLabel}
								color='secondary'
								control={
									<Switch
										checked={activePost ?? talent.active}
										onChange={(e) => setActivePost(e.target.checked)}
										name='activatePost'
										color='primary'
									/>
								}
								label='Active status'
							/>
						</Grid>
						<Grid item xs={9}>
							<Button color={successUpdated ? 'success' : 'primary'} block onClick={togglePost}>
								{loadingTalentUpdate ? <Loader size={'sm'} /> : successUpdated ? 'UPDATED POST' : 'Update Post'}
							</Button>
						</Grid>
						<Grid item xs={3} sm={3}>
							<Button onClick={handledeleteTalent} color={'danger'} block>
								{deletePost ? <Loader size={'sm'} /> : 'Delete'}
							</Button>
						</Grid>
					</Grid>
				)}
			</Card>
		</>
	)
}

export default SharedTalentComponent

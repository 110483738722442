const styles = {
  sponsorsSection: {
    padding: '100px 20px',
    textAlign: 'center',
    '& h4': {
      fontSize: '38px',
      marginBottom: '80px',
    },
  },
  imageSponsor: {
    width: '70%',
    boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
}

export default styles
